import { createAction } from "./base";

const GetMenu = createAction("GetMenu");
const UpdateMenu = createAction("UpdateMenu");
const UpdateMenuData = createAction("UpdateMenuData");
const GetMenuElementIds = createAction("GetMenuElementIds");
const GetMenuElementSortConfigurations = createAction(
  "GetMenuElementSortConfigurations"
);
const UpdateMenuElementSortConfigurations = createAction(
  "UpdateMenuElementSortConfigurations"
);

function getMenu(
  type,
  categoryOnly = false,
  addDefaultUrl = false,
  lang = "PL"
) {
  return {
    type: GetMenu.Request,
    payload: { type, categoryOnly, addDefaultUrl, lang },
  };
}

function updateMenu(type, event, isoCode) {
  return {
    type: UpdateMenu.Request,
    payload: { type, event, lang: isoCode },
  };
}

function updateMenuData(menu) {
  return {
    type: UpdateMenuData.Success,
    payload: { menu },
  };
}

function getMenuElementIds() {
  return {
    type: GetMenuElementIds.Request,
  };
}

function getMenuElementSortConfigurations(elementId) {
  return {
    type: GetMenuElementSortConfigurations.Request,
    payload: elementId,
  };
}

function updateMenuElementSortConfigurations(elementId, values) {
  return {
    type: UpdateMenuElementSortConfigurations.Request,
    payload: { elementId, values },
  };
}

export const MenuActionCreators = {
  getMenu,
  updateMenu,
  updateMenuData,
  getMenuElementIds,
  getMenuElementSortConfigurations,
  updateMenuElementSortConfigurations,
};

export const MenuActions = {
  GetMenu,
  UpdateMenu,
  UpdateMenuData,
  GetMenuElementIds,
  GetMenuElementSortConfigurations,
  UpdateMenuElementSortConfigurations,
};
