import { createSelector } from 'reselect';
import { ArticleType } from '../helpers/enums';
import filter from 'lodash.filter';
import isEmpty from 'lodash.isempty';
import map from 'lodash.map';

const stateArticles = (state) => state.article.articles || [];
const stateArticle = (state) => state.article.article;
const stateAuthExcludeCategories = state => state.auth.excludeCategories || [];

export const hideNoContentPage = createSelector([ stateArticles ], (articles) => {
	return filter(
		articles,
		(article) =>
			![
				ArticleType.ChangeLog.value,
				ArticleType.Stats.value,
				ArticleType.Search.value,
				ArticleType.Sitemap.value
			].includes(article.baseArticleType)
	);
});

export const isArticleEditableForUser = createSelector([stateArticle, stateAuthExcludeCategories], (article, excludeCategories) => {
	if (article && !isEmpty(article.categories)) {
		const articleCategoryIds = map(article.categories, item => item.menuElementId);

		return !articleCategoryIds.every(id => excludeCategories.includes(id));
	}
	return true;
})
