import axios from "axios";

import { getUri } from "../../helpers/api";

export const fetchData = ({
  url,
  tableParams,
  extraParams,
  onSuccess,
  onFinally,
}) =>
  axios
    .get(
      `${getUri("")}${url}?${
        extraParams ? `${tableParams}&${extraParams}` : `${tableParams}`
      }`
    )
    .then((data) => onSuccess(data))
    .catch(() => {
      throw new Error("Data Loading Error");
    })
    .finally(() => {
      onFinally && onFinally();
    });
