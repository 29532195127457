import { createAction } from './base';
import { DefaultLanguage } from '../helpers/enums';

const GetApplicationParameters = createAction('GetApplicationParameters');
const GetAppParamLanguages = createAction('GetAppParamLanguages');
const GetAppParamDictionaryElements = createAction('GetAppParamDictionaryElements');
const ChangeApplicationLanguage = createAction('ChangeApplicationLanguage');
const LastResetSessionTime = createAction('LastResetSessionTime');

function getApplicationParameters(lang = DefaultLanguage.isoCode) {
	return {
		type: GetApplicationParameters.Request,
		payload: { lang }
	};
}
function getAppParamDictionaryElements(lang = DefaultLanguage.isoCode) {
	return {
		type: GetAppParamDictionaryElements.Request,
		payload: { lang }
	};
}
function getAppParamLanguages() {
	return {
		type: GetAppParamLanguages.Request
	};
}

function changeApplicationLanguage(language) {
	return {
		type: ChangeApplicationLanguage.Request,
		payload: language
	};
}

export const ApplicationActionCreators = {
	getApplicationParameters,
	getAppParamLanguages,
	getAppParamDictionaryElements,
	changeApplicationLanguage
};

export const ApplicationActions = {
	GetApplicationParameters,
	GetAppParamLanguages,
	GetAppParamDictionaryElements,
	ChangeApplicationLanguage,
	LastResetSessionTime
};
