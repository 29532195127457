import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import SystemParameterList from "./List";

const SystemParameters = ({ match }) => (
  <Fragment>
    <Route path="*" component={SystemParameterList} />
  </Fragment>
);

export default SystemParameters;
