import React, { Component, Fragment } from 'react';
import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames';

import {
	FormGroup,
	FormFeedback,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	ListGroup,
	Label,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	Button
} from 'reactstrap';
import { Formik } from 'formik';
import RSC from 'react-scrollbars-custom';

import FooterInformation from '../../../../components/Modals/FooterInformation';
import FooterLinkCardItem from '../../../../components/Cards/FooterLinkCardItem';

import { FooterValidationSchema } from '../../../../validators';

import { CONSTANTS } from '@constants';
import { DefaultFormikConfig } from '../../../../helpers/enums';

const {
	CONFIRM,
	CANCEL,
	COMMON_INFORMATION,
	DISPLAY_NAME,
	SHOW_IN_FOOTER,
	SOURCES,
	YES,
	NO,
	EDIT_FOOTER_ELEMENT
} = CONSTANTS;

export default class InformationCard extends Component {
	state = {
		showDetailsModal: false
	};

	onToggle = () => {
		this.setState({
			showDetailsModal: !this.state.showDetailsModal
		});
	};
	render() {
		const {
			isOpen = false,
			footerElement = {},
			updateFooterElement = (element) => element,
			cancel = () => {}
		} = this.props;
		const { showDetailsModal } = this.state;

		return (
			<Formik
				{...DefaultFormikConfig}
				initialValues={footerElement}
				validationSchema={FooterValidationSchema}
				onSubmit={(values) => {
					updateFooterElement(values);
					cancel();
				}}
				onReset={cancel}
			>
				{({ errors, values, handleChange, setFieldValue, isValid, dirty, handleSubmit, handleReset }) => (
					<Modal isOpen={isOpen} backdrop size="xl" toggle={handleReset}>
						<ModalHeader>
							<div className="d-flex justify-content-between">
								<div>{EDIT_FOOTER_ELEMENT}</div>
								<div>
									<Button
										className="btn-icon mr-2"
										color="alternate"
										type="button"
										onClick={handleSubmit}
										disabled={!isValid || !dirty}
									>
										<i className="pe-7s-check btn-icon-wrapper" />
										{CONFIRM}
									</Button>
									<Button
										className="btn-icon mr-2"
										color="secondary"
										type="button"
										onClick={handleReset}
									>
										<i className="pe-7s-back btn-icon-wrapper" />
										{CANCEL}
									</Button>
								</div>
							</div>
						</ModalHeader>
						<ModalBody>
							<Fragment>
								<FooterInformation
									items={values.content.links}
									isOpen={showDetailsModal}
									onSave={(links) => setFieldValue('content.links', links)}
									onToggle={this.onToggle}
								/>
								<Card className="mb-2">
									<CardHeader>
										<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
										{COMMON_INFORMATION}
									</CardHeader>
									<CardBody>
										<Row>
											<Col>
												<FormGroup>
													<Label for="displayText">{DISPLAY_NAME}</Label>
													<Input
														type="text"
														id="displayText"
														name="displayText"
														placeholder="Wpisz nazwę"
														value={values.displayText}
														onChange={handleChange}
														invalid={!!errors.displayText}
													/>
													<FormFeedback>{errors.displayText}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup className="d-flex align-items-center">
													<Label for="isActive" className="mr-2">
														{SHOW_IN_FOOTER}
													</Label>
													<div
														className="switch has-switch mb-2 mr-2"
														data-on-label="Tak"
														data-off-label="Nie"
														onClick={(e) => setFieldValue(`isActive`, !values.isActive)}
														invalid={!!errors.isActive}
													>
														<div
															className={classnames('switch-animate', {
																'switch-on': values.isActive,
																'switch-off': !values.isActive
															})}
														>
															<input type="checkbox" />
															<span className="switch-left">{YES}</span>
															<label>&nbsp;</label>
															<span className="switch-right">{NO}</span>
														</div>
													</div>
													<FormFeedback>{errors.isActive}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card className="mb-2">
									<CardHeader>
										<div className="d-flex justify-content-center">
											<div className="d-flex align-items-center justify-content-center">
												<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
												{SOURCES}
											</div>
											<Button
												type="button"
												className="mr-2 border-0 btn-transition"
												outline
												color="success"
												onClick={this.onToggle}
											>
												<i className="pe-7s-plus btn-icon-wrapper" />
											</Button>
										</div>
									</CardHeader>
									<CardBody>
										<div
											className={classnames({
												'scroll-area-md': !isEmpty(values.content.links)
											})}
										>
											<RSC>
												<ListGroup flush>
													{map(values.content.links, (link, linkIndex) => (
														<FooterLinkCardItem
															errors={
																errors &&
																errors.content &&
																errors.content.links &&
																errors.content.links[linkIndex] ? (
																	errors.content.links[linkIndex]
																) : null
															}
															link={{ ...link, index: linkIndex }}
															key={`link-category-item-${linkIndex}`}
															onChange={(index, link) =>
																setFieldValue(
																	'content.links',
																	values.content.links
																		.slice(0, index)
																		.concat(link)
																		.concat(values.content.links.slice(index + 1))
																)}
															onRemove={(index) => {
																setFieldValue(
																	'content.links',
																	values.content.links
																		.slice(0, index)
																		.concat(values.content.links.slice(index + 1))
																);
															}}
														/>
													))}
												</ListGroup>
											</RSC>
										</div>
									</CardBody>
								</Card>
							</Fragment>
						</ModalBody>
					</Modal>
				)}
			</Formik>
		);
	}
}
