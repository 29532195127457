import { createAction } from '../base';

const LoadTiles = createAction('LoadTiles');
const GetTile = createAction('GetTile');
const UpdateTile = createAction('UpdateTile');

function loadTiles(lang = '', page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: LoadTiles.Request,
		payload: { lang, page, size, filters, sortings }
	};
}
function getTile(id) {
	return {
		type: GetTile.Request,
		payload: id
	};
}

function updateTile(tile) {
	return {
		type: UpdateTile.Request,
		payload: tile
	};
}

export const TileActions = {
	LoadTiles,
	GetTile,
	UpdateTile
};

export const TileActionCreators = {
	loadTiles,
	getTile,
	updateTile
};
