import { takeEvery, fork, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import axios from "axios";
import qs from "qs";

import { MenuActions } from "../actions/menu";
import { MenuType } from "../helpers/enums";
import { createErrorToast } from "../helpers/error-message";

const MENU_URL = "/api/menus";

function* getMenu(action) {
  try {
    const menu = yield call(
      getMenuRequest,
      action.payload.type,
      action.payload.categoryOnly,
      action.payload.addDefaultUrl,
      action.payload.lang
    );
    yield put({
      type: MenuActions.GetMenu.Success,
      payload: menu,
    });
  } catch (err) {
    yield put({
      type: MenuActions.GetMenu.Failure,
    });
    createErrorToast(
      err,
      "Wystąpił niespodziewany problem podczas pobierania menu"
    );
  }
}

function getMenuRequest(
  type = MenuType.Horizontal,
  categoryOnly = false,
  addDefaultUrl = false,
  lang = "PL"
) {
  const filterQuery = qs.stringify(
    { categoryOnly, addDefaultUrl, lang },
    { addQueryPrefix: true, allowDots: true }
  );

  return axios
    .get(`${MENU_URL}/${type}${filterQuery}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

function* updateMenu(action) {
  try {
    const menu = yield call(
      updateMenuRequest,
      action.payload.type,
      action.payload.event,
      action.payload.lang
    );

    yield put({
      type: MenuActions.UpdateMenu.Success,
      payload: menu,
    });
    toast.success("Menu zostało zaktualizowane");
  } catch (err) {
    yield put({
      type: MenuActions.UpdateMenu.Failure,
    });
    createErrorToast(
      err,
      "Wystąpił niespodziewany problem podczas aktualizacji menu"
    );
  }
}

function updateMenuRequest(
  type = MenuType.Horizontal,
  event = {},
  lang = "PL"
) {
  const filterQuery = qs.stringify(
    { lang },
    { addQueryPrefix: true, allowDots: true }
  );

  return axios
    .put(`${MENU_URL}/${type}${filterQuery}`, event)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

function* getMenuElementIds(action) {
  try {
    const menuElementIds = yield call(getMenuElementIdsRequest);
    yield put({
      type: MenuActions.GetMenuElementIds.Success,
      payload: menuElementIds,
    });
  } catch (err) {
    yield put({
      type: MenuActions.GetMenuElementIds.Failure,
    });
  }
}

function getMenuElementIdsRequest() {
  return axios
    .get(`${MENU_URL}/id`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

function* getMenuElementSortConfigurations(action) {
  try {
    const menuElementSortConfiguration = yield call(
      getMenuElementSortConfigurationsRequest,
      action.payload
    );
    yield put({
      type: MenuActions.GetMenuElementSortConfigurations.Success,
      payload: menuElementSortConfiguration,
    });
  } catch (err) {
    yield put({
      type: MenuActions.GetMenuElementSortConfigurations.Failure,
    });
  }
}

function getMenuElementSortConfigurationsRequest(elementId) {
  return axios
    .get(`${MENU_URL}/${elementId}/sort-configurations`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

function* updateMenuElementSortConfigurations(action) {
  try {
    yield call(
      updateMenuElementSortConfigurationsRequest,
      action.payload.elementId,
      action.payload.values
    );
    yield put({
      type: MenuActions.UpdateMenuElementSortConfigurations.Success,
    });
  } catch (err) {
    yield put({
      type: MenuActions.UpdateMenuElementSortConfigurations.Failure,
    });
  }
}

function updateMenuElementSortConfigurationsRequest(elementId, data) {
  return axios
    .put(`${MENU_URL}/${elementId}/sort-configurations`, data)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

function* watchGetMenu() {
  yield takeEvery(MenuActions.GetMenu.Request, getMenu);
}

function* watchUpdateMenu() {
  yield takeEvery(MenuActions.UpdateMenu.Request, updateMenu);
}

function* watchGetMenuElementIds() {
  yield takeEvery(MenuActions.GetMenuElementIds.Request, getMenuElementIds);
}

function* watchGetMenuElementSortConfigurations() {
  yield takeEvery(
    MenuActions.GetMenuElementSortConfigurations.Request,
    getMenuElementSortConfigurations
  );
}

function* watchUpdateMenuElementSortConfigurations() {
  yield takeEvery(
    MenuActions.UpdateMenuElementSortConfigurations.Request,
    updateMenuElementSortConfigurations
  );
}

export const MenuSaga = [
  fork(watchGetMenu),
  fork(watchUpdateMenu),
  fork(watchGetMenuElementIds),
  fork(watchGetMenuElementSortConfigurations),
  fork(watchUpdateMenuElementSortConfigurations),
];
