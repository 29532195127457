export const DefaultLanguage = {
  id: 1,
  isoCode: "PL",
  name: "polski",
  isoCodeFile: null,
  isSystem: true,
};

export const Languages = {
  PL: {
    id: 1,
    isoCode: "PL",
    name: "polski",
  },
  ENG: {
    id: 10,
    isoCode: "GB",
    name: "angielski",
  },
};

export const MenuConfiguration = {
  Infinity: -1, // nieograniczona, nieskończona itp.
};
export const MenuType = {
  Horizontal: 10,
  Vertical: 20,
};
export const ArticleType = {
  NotSetted: { value: -1, name: "Wybierz" },
  Common: { value: 10, name: "Zwykły" },
  Instruction: { value: 20, name: "Instrukcja" },
  RSS: { value: 30, name: "RSS" },
  PersonalDataSecurityTerms: { value: 40, name: "Ochrona danych osobowych" },
  ReusableInformationTerms: { value: 50, name: "Ponowne wykorzystanie" },
  SharingInformationTerms: {
    value: 60,
    name: "Udostępnienie informacji publicznej",
  },
  ChangeLog: { value: 70, name: "Rejestr zmian" },
  Stats: { value: 80, name: "Statystyki" },
  Search: { value: 90, name: "Wyszukiwanie" },
  Sitemap: { value: 100, name: "Mapa strony" },
  ClientData: { value: 110, name: "Dane klienta" },
  DigitalAvailability: { value: 120, name: "Dostępność cyfrowa" },
  TransmissionFromRadniInfo: { value: 130, name: "Transmisje z posiedzeń" },
};
export const ArticleTemplate = {
  NotSetted: { value: -1, name: "Wybierz" },
  Text: { value: 10, name: "Tekst" },
  Tender: { value: 20, name: "Przetarg" },
};
export const ArticleStatus = {
  Draft: { value: 10, name: "Roboczy" },
  Confirmed: { value: 20, name: "Zatwierdzony" },
  ToPublish: { value: 30, name: "Do publikacji" },
  Published: { value: 40, name: "Opublikowany" },
  Withdrawed: { value: 50, name: "Wycofany" },
  Archived: { value: 60, name: "Zarchiwizowany" },
};

export const ArticleImage = {
  Draft: "rst__moveHandle--draft",
  Confirmed: "rst__moveHandle--confirmed",
  ToPublish: "rst__moveHandle--toPublish",
  Published: "rst__moveHandle--published",
  Withdrawed: "rst__moveHandle--withdrawed",
  Archived: "rst__moveHandle--archived",
};

export const DragDropType = {
  Unknown: "UNKNOWN",
  Category: "CATEGORY",
  Article: "ARTICLE",
  Bookmark: "BOOKMARK",
  FooterLink: "FOOTER_LINK",
};
export const LanguageStatus = {
  Inactive: 0,
  Active: 1,
};

export const LoaderType = "line-scale-pulse-out-rapid";

export const LinkType = {
  Category: { value: 10, name: "Kategoria" },
  Article: { value: 20, name: "Artykuł" },
  External: { value: 30, name: "Źródło zewnętrzne" },
};

export const LinkTargetType = {
  NotSetted: { value: -1, name: "Wybierz" },
  Self: { value: 10, name: "Ta sama zakładka" },
  NewTab: { value: 20, name: "Nowa zakładka" },
};

export const ElementGroupType = {
  Button: { value: 10, name: "Przycisk" },
  Link: { value: 20, name: "Link" },
  Label: { value: 30, name: "Etykieta" },
  Communicate: { value: 40, name: "Komunikaty" },
};

export const ConfigurationEditorType = {
  Input: 10,
  Textarea: 20,
  TinyMCE: 30,
  Checkbox: 40,
  ToggleCheckbox: 50,
  ListBox: 60,
  MenuConfiguration: 70,
  NumberRange: 80,
  ThemeColorPicker: 90,
  SessionTimeout: 100,
  TileMode: 110,
  ArchiveSitemapSshConfiguration: 120,
  ArchiveBipV1ServerSshConfigurations: 130,
  IntegrationWithRadniInfo: 140,
  CookieConfiguration: 170,
  Instruction: 200,
};

export const ConfigurationId = {
  TileMode: 10,
};

export const ConfigurationKeyNames = {
  CommonKeyNames: {
    DefaultMethodOpenLinks: "DefaultMethodOpenLinks",
  },
  HorizontalMenuKeyNames: {
    DefaultHorizontalMenuSettings: "DefaultHorizontalMenuSettings",
  },
  VerticalMenuKeyNames: {
    DefaultVerticalMenuSettings: "DefaultVerticalMenuSettings",
  },
};

export const TileIconNames = {
  Act: {
    value: "act",
    name: "Akt prawny",
  },
  Document: {
    value: "document_form",
    name: "Formularz",
  },
  Comment: {
    value: "comment",
    name: "Komentarz",
  },
  Time: {
    value: "time",
    name: "Zegar",
  },
};

export const INFINITY = -1;

export const FooterType = {
  Logo: { value: 10, name: "Logo" },
  EditorialOffice: { value: 20, name: "Redakcja" },
  EditorialOfficeAddress: { value: 30, name: "Adres redakcji" },
  Information: { value: 40, name: "Informacje" },
  Updates: { value: 50, name: "Aktualizacje" },
};
export const FooterLinkType = {
  Unknown: { value: 0, name: "Nieokreślony" },
  HorizontalMenu: { value: 10, name: "Element menu poziomego" },
  VerticalMenu: { value: 20, name: "Element menu pionowego" },
  Article: { value: 30, name: "Artykuł" },
  External: { value: 40, name: "Źródło zewnętrzne" },
};

export const FILE_PARAM_NAME = "files";
export const UPLOAD_URL = "/api/files";
export const UPLOAD_OFFICE_CONTACT_PHOTO_URL =
  "/api/files/office-contact-photos";

export const SizeUnit = {
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
};

export const DefaultAttachmentSize = 5; // do przeniesienia do parametrów systemowych

export const ImageMimeType = {
  SVG: "image/svg+xml",
  BMP: "image/bmp",
  PNG: "image/png",
  JPG: "image/jpeg,image/jpg",
  GIF: "image/gif",
};

export const FileType = {
  Image: 0,
  Document: 1,
};

export const DocumentMimeType = {
  WORD: "application/msword",
  EXCEL: "application/vnd.ms-excel",
  POWERPOINT: "application/vnd.ms-powerpoint",
  PLAINTEXT: "text/plain",
  PDF: "application/pdf",
  ZIP: "application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip",
  JSON: "application/json",
};

export const UploadFileType = {
  IMAGE: "image/*",
  DOC: `${DocumentMimeType.WORD}, ${DocumentMimeType.EXCEL}, ${DocumentMimeType.POWERPOINT}, ${DocumentMimeType.PLAINTEXT}, ${DocumentMimeType.PDF}, ${DocumentMimeType.ZIP}, ${DocumentMimeType.JSON}`,
  DOC_TEST: "application/*",
};

export const FileExtensionTypes = {
  WORD: ".doc, .docx",
  EXCEL: ".xls, .xlsx",
  POWERPOINT: ".ppt, .pptx",
  PLAINTEXT: ".txt",
  PDF: ".pdf",
  ZIP: ".zip, .tar, .7z",
  JSON: ".json",
  SVG: ".svg",
  BMP: ".bmp",
  PNG: ".png",
  JPG: ".jpg",
  JPEG: ".jpeg",
  GIF: ".gif",
};

export const ApplicationImageCardType = {
  Single: 10,
  Multi: 20,
};

export const Sex = {
  Unknown: { value: 0, name: "Nieokreślona" },
  Male: { value: 10, name: "Mężczyzna" },
  Female: { value: 20, name: "Kobieta" },
};

export const IdentificationDocumentType = {
  PESEL: { value: -1, name: "PESEL" },
  Unknown: { value: 0, name: "Brak" },
  ID: { value: 1, name: "Dowód osobisty" },
  Passport: { value: 2, name: "Paszport" },
  PoleCard: { value: 3, name: "Karta Polaka" },
  MilitaryBook: { value: 4, name: "Książeczka wojskowa" },
  SeamansBook: { value: 5, name: "Książeczka żeglarska" },
};

export const PageSize = {
  All: 0,
  Rows20: 20,
  Rows50: 50,
  Rows100: 100,
  Rows200: 200,
};
export const PopoverPlacement = {
  Top: "top",
  Bottom: "bottom",
  Left: "left",
  Right: "right",
};

export const CropperSizes = {
  smallLogo: { width: 200, height: 200 },
  bigLogo: { width: 200, height: 200 },
  background: { width: 800, height: 175 },
  euCollectionImages: { width: 800, height: 175 },
};

export const ImageData = {
  smallLogo: { id: 10, width: 74, height: 74 },
  bigLogo: { id: 20, width: 70, height: 80 },
  background: { id: 30, width: 1366, height: 240 },
  euCollectionImages: { id: 40, width: 1240, height: 45 },
};
export const DateFormats = [
  "dd-MM-yyyy",
  "dd.MM.yyyy",
  "dd/MM/yyyy",
  "yyyy-MM-dd",
  "yyyy.MM.dd",
  "yyyy/MM/dd",
  "MM-dd-yyyy",
  "MM.dd.yyyy",
  "MM/dd/yyyy",
];

export const DefaultFormikConfig = {
  enableReinitialize: true,
  // isInitialValid: true,
  validateOnMount: true,
  validateOnBlur: false,
};

export const ArticleSortOnCategoryType = {
  User: { value: 0, name: "Użytkownika" },
  PublishDateAsceding: { value: 10, name: "Rosnąco wg daty publikacji" },
  PublishDateDescending: { value: 20, name: "Malejąco wg daty publikacji" },
  Alphabetically: { value: 30, name: "Alfabetycznie (A-Z)" },
};

export const MultimediaType = {
  Unknown: { value: 0, name: "Nieokreślony" },
  Video: { value: 10, name: "Wideo" },
  Audio: { value: 20, name: "Audio" },
};
export const DayOfWeeks = {
  Monday: { value: 1, name: "Poniedziałek" },
  Tuesday: { value: 2, name: "Wtorek" },
  Wednesday: { value: 3, name: "Środa" },
  Thursday: { value: 4, name: "Czwartek" },
  Friday: { value: 5, name: "Piątek" },
  Saturday: { value: 6, name: "Sobota" },
  Sunday: { value: 7, name: "Niedziela" },
};
