import { createAction } from '../base';

const LoadRepositoryConfigs = createAction('LoadRepositoryConfigs');
const GetRepositoryConfig = createAction('GetRepositoryConfig');
const UpdateRepositoryConfig = createAction('UpdateRepositoryConfig');
const CreateRepositoryConfig = createAction('CreateRepositoryConfig');
const RemoveRepositoryConfigs = createAction('RemoveRepositoryConfigs');

function loadRepositoryConfigs(page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: LoadRepositoryConfigs.Request,
		payload: { page, size, filters, sortings }
	};
}
function getRepositoryConfig(id) {
	return {
		type: GetRepositoryConfig.Request,
		payload: id
	};
}

function updateRepositoryConfig(repositoryConfig) {
	return {
		type: UpdateRepositoryConfig.Request,
		payload: repositoryConfig
	};
}

function createRepositoryConfig(repositoryConfig) {
	return {
		type: CreateRepositoryConfig.Request,
		payload: repositoryConfig
	};
}

function removeRepositoryConfigs(ids) {
	return {
		type: RemoveRepositoryConfigs.Request,
		payload: ids
	};
}

export const RepositoryConfigActions = {
	LoadRepositoryConfigs,
	GetRepositoryConfig,
	UpdateRepositoryConfig,
	CreateRepositoryConfig,
	RemoveRepositoryConfigs
};

export const RepositoryConfigActionCreators = {
	loadRepositoryConfigs,
	getRepositoryConfig,
	updateRepositoryConfig,
	createRepositoryConfig,
	removeRepositoryConfigs
};
