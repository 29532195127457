import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popover, Button, InputGroupAddon } from 'reactstrap';

import { getMessage, Icon } from './utils';

export class FilterSelector extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			test: false
		};
		this.handleMenuItemClick = nextValue => {
			const { onChange } = this.props;
			onChange(nextValue);
		};
	}

	render() {
		const {
			value,
			availableValues,
			disabled,
			className,
			isOpen,
			isPopoverClicked,
			targetId,
			...restProps
		} = this.props;
		const { test } = this.state;

		const buttonClickHandler = () => {
			this.setState({
				test: !test
			});
		};

		if (isPopoverClicked) {
			targetId !== `${restProps.id}-toggle`
				? this.setState({
						test: false
					})
				: this.setState({
						test: true
					});
		} else {
			this.setState({
				test: false
			});
		}

		return (
			<InputGroupAddon addonType="prepend">
				<Button
					outline
					color="secondary"
					id={`${restProps.id}-toggle`}
					disabled={disabled || availableValues.length === 1}
					onClick={buttonClickHandler}
				>
					<Icon type={value} />
				</Button>
				<Popover
					target={`${restProps.id}-toggle`}
					placement="bottom"
					disabled={disabled || availableValues.length === 1}
					isOpen={isOpen && test}
				>
					<div className="py-2">
						{availableValues.map(valueItem => (
							<button
								type="button"
								key={valueItem}
								className={classNames({
									'dropdown-item d-flex align-items-center': true,
									'dx-g-bs4-cursor-pointer dx-g-bs4-filter-selector-item': true,
									active: valueItem === value
								})}
								disabled={disabled || availableValues.length === 1}
								onClick={_ => {
									this.handleMenuItemClick(valueItem);
									buttonClickHandler();
								}}
							>
								<Icon type={valueItem} />
								<span className="dx-g-bs4-filter-selector-item-text">{getMessage(valueItem)}</span>
							</button>
						))}
					</div>
				</Popover>
			</InputGroupAddon>
		);
	}
}

FilterSelector.propTypes = {
	value: PropTypes.string,
	availableValues: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string
};

FilterSelector.defaultProps = {
	value: undefined,
	availableValues: [],
	onChange: () => {},
	disabled: false,
	className: undefined
};
