import React, { Fragment, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";

import { MenuActionCreators } from "../../../actions/menu";
import { SearchActionCreators } from "../../../actions/search";
import { UserActionCreators } from "../../../actions/auth/user";
import { ArticleActionCreators } from "../../../actions/article";

import { MenuTable } from "../MenuTable/MenuTable";
import LanguageDropdown from "../../../components/LanguageDropdown";

import PageTitle from "../../../Layout/AppMain/PageTitle";

import {
  MenuType,
  LoaderType,
  MenuConfiguration,
  DefaultLanguage,
} from "../../../helpers/enums";

const VerticalMenu = (props) => {
  const {
    menuItems,
    languages,
    updateMenu,
    menuCardBlocking,
    excludeCategories,
    getMenu,
    getUserExcludeCategories,
  } = props;

  const [currentLang, setCurrentLang] = useState(DefaultLanguage);

  return (
    <Fragment>
      <PageTitle
        heading="Tworzenie menu pionowego"
        icon="pe-7s-menu icon-gradient bg-tempting-azure"
      >
        <LanguageDropdown
          language={currentLang}
          languages={languages}
          onChangeLanguage={({ id, isoCode, name, isoCodeFile, isSystem }) => {
            setCurrentLang({
              id,
              isoCode,
              name,
              isoCodeFile,
              isSystem,
            });
          }}
        />
      </PageTitle>
      <BlockUi
        tag="div"
        blocking={menuCardBlocking}
        loader={<Loader active type={LoaderType} />}
      >
        <MenuTable
          {...{
            menuItems,
            updateMenu,
            getMenu,
            getUserExcludeCategories,
            languages,
            type: MenuType.Vertical,
            excludeCategories,
            currentLang,
          }}
        />
      </BlockUi>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    excludeCategories: state.auth.excludeCategories || [],
    verticalMenuConfiguration: state.application.menuConfiguration.vertical || {
      maxElements: 0,
      maxLevels: MenuConfiguration.Infinity,
    },
    newTabOpenLinks: state.application.newTabOpenLinks || false,
    menuItems: state.menu.menu || [],
    languages: state.application.languages,
    menuCardBlocking: state.uiBlockState.menuCardBlocking || false,
    searchExecuted: state.menu.searchExecuted || false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...bindActionCreators(
      {
        ...MenuActionCreators,
        ...SearchActionCreators,
        ...UserActionCreators,
        ...ArticleActionCreators,
      },
      dispatch
    ),
    cancel: () => dispatch(goBack()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerticalMenu);
