import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import Immutable from 'seamless-immutable';
import map from 'lodash.map';
import filter from 'lodash.filter';
import isEqual from 'lodash.isequal';

import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import Sticky from 'react-stickynode';
import { Card, CardHeader, CardBody, Row, Col, Button, Form } from 'reactstrap';
import { FieldArray, Formik } from 'formik';
import Flag from 'react-flagkit';

import PageTitle from '../../../../Layout/AppMain/PageTitle';
import FooterElementCard from './FooterElementCard';

import { LoaderType, DefaultLanguage, DefaultFormikConfig } from '../../../../helpers/enums';
import { ApplicationActionCreators } from '../../../../actions/application';
import { SettingActionCreators } from '../../../../actions/settings';
import { AVAILABLE_ISO_CODE } from '../../../../helpers/isoCode';

import { CONSTANTS } from '@constants';

const { SAVE, CANCEL, SPECIFIC_INFOMATION } = CONSTANTS;

class FooterCard extends Component {
	componentDidMount() {
		const { activeLanguage, getFooter } = this.props;
		getFooter(activeLanguage.isoCode);
	}
	componentDidUpdate(prevProps) {
		const { activeLanguage, getFooter } = this.props;
		if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
			getFooter(activeLanguage.isoCode);
		}
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}

	createTranslationFlagButtons = () => {
		const { languages, changeApplicationLanguage, activeLanguage } = this.props;

		return (
			<div className="header-dots ml-0">
				{map(
					filter(languages, (language) => language.isoCode !== activeLanguage.isoCode),
					(language, langIndex) => (
						<div
							key={`lang-ix-${langIndex}`}
							className="p-0 mr-2"
							onClick={(_) => changeApplicationLanguage(language)}
						>
							<div className="icon-wrapper icon-wrapper-alt rounded-circle mr-2">
								<div className="icon-wrapper-bg bg-focus" />
								<div className="language-icon">
									{AVAILABLE_ISO_CODE.includes(language.isoCode) && (
										<Flag className="mr-3 opacity-8" country={language.isoCode} size="40" />
									)}
									{!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
									language.isoCode !== '' &&
									language.isoCodeFile !== null && (
										<img
											className={`mr-2 opacity-8`}
											src={language.isoCodeFile.path}
											alt={`Grafika ${language.isoCodeFile.fileName}`}
										/>
									)}
								</div>
							</div>
						</div>
					)
				)}
			</div>
		);
	};

	render() {
		const { footer, activeLanguage, footerCardBlocking, updateFooter } = this.props;
		const newElements = Immutable(footer).asMutable({ deep: true });
		return (
			<Formik
				{...DefaultFormikConfig}
				initialValues={newElements}
				onSubmit={(values) => updateFooter(activeLanguage.isoCode, values)}
			>
				{({ errors, values, isValid, dirty, handleSubmit: onSubmit, handleReset: onReset }) => (
					<Fragment>
						<PageTitle
							heading="Ustawienia stopki"
							icon="pe-7s-settings icon-gradient bg-tempting-azure"
							buttons={this.createTranslationFlagButtons(values)}
						/>
						<BlockUi tag="div" blocking={footerCardBlocking} loader={<Loader active type={LoaderType} />}>
							<Form noValidate method="post" {...{ onSubmit, onReset }}>
								<Sticky
									enabled={true}
									top=".app-header"
									innerZ="101"
									activeClass="sticky-active-class"
									className="mb-2"
								>
									<div className="d-flex justify-content-end">
										<Button
											className="btn-icon mr-2"
											color="primary"
											type="submit"
											disabled={!isValid || !dirty}
										>
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{SAVE}
										</Button>
										<Button className="btn-icon mr-2" color="secondary" type="reset">
											<i className="pe-7s-back btn-icon-wrapper" />
											{CANCEL}
										</Button>
									</div>
								</Sticky>
								<Card className="mb-3">
									<CardHeader>{SPECIFIC_INFOMATION}</CardHeader>
									<CardBody>
										<Row>
											<FieldArray id="footerElements" name="footerElements">
												{(props) =>
													map(values.footerElements, (footerElement, footerElementIndex) => (
														<Col md={4}>
															<FooterElementCard
																key={`foo-element-card-${footerElementIndex}`}
																index={footerElementIndex}
																footerElement={footerElement}
																onChange={props.replace}
															/>
														</Col>
													))}
											</FieldArray>
										</Row>
									</CardBody>
								</Card>
							</Form>
						</BlockUi>
					</Fragment>
				)}
			</Formik>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		footer: state.setting.footer || [],
		footerCardBlocking: state.uiBlockState.footerCardBlocking || false,
		activeLanguage: state.application.activeLanguage,
		languages: state.application.languages || [ DefaultLanguage ] //getActiveLanguagesSelector(state) || [ DefaultLanguage ]
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators({ ...SettingActionCreators, ...ApplicationActionCreators }, dispatch),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterCard);
