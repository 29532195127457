import React from "react";
import { FormGroup, Input, Label, FormFeedback } from "reactstrap";

export const InputText = ({
  label,
  type,
  name,
  value,
  error,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Input
        {...{
          id: name,
          type,
          name,
          value,
          onBlur: handleBlur,
          onChange: handleChange,
          invalid: !!touched && !!error,
        }}
      />
      {error && touched ? <FormFeedback>{error}</FormFeedback> : null}
    </FormGroup>
  );
};
