import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import DatePicker from "react-datepicker";

import {
  format as dateFnsFormat,
  startOfDay,
  endOfDay,
  isValid as dateFnsIsValid,
  addMinutes,
  isToday,
  setSeconds,
} from "date-fns";
import { pl } from "date-fns/locale";

import { ArticleStatus, ArticleType } from "../../../../../helpers/enums";
import { CONSTANTS } from "@constants";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "./../utils";
import { TimeInput } from "../../../../../components/DatepickerTimeInput";

const {
  PUBLICATION,
  DATA_OF_PUBLICATION_FROM,
  DATA_FROM_PUBLICATION_TO,
  ARCHIVE_FROM_DATA,
} = CONSTANTS;

export const PublicationCard = ({
  isArticleEditable = false,

  isValid = false,
  dirty = false,
  values = {},
  errors = {},
  handleChange = Function.prototype,
  setFieldValue = Function.prototype,
}) => {
  const {
    status,
    publishStart,
    publishEnd,
    archiveAfterPublishEnd,
    baseArticleType,
  } = values;

  const {
    publishStart: ePublishStart,
    publishEnd: ePublishEnd,
    archiveAfterPublishEnd: eArchiveAfterPublishEnd,
  } = errors;
  const articleOnPublishedStatus =
    status >= ArticleStatus.ToPublish.value || !isArticleEditable;

  return (
    <Card className="mb-2">
      <CardHeader>
        <i className="header-icon pe-7s-timer icon-gradient bg-malibu-beach" />
        {PUBLICATION}
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <FormGroup>
              <Label for="publishStart">{DATA_OF_PUBLICATION_FROM}</Label>
              <div className="d-flex">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                      <i className="pe-7s-date" />
                    </div>
                  </InputGroupAddon>
                  <DatePicker
                    className="form-control"
                    id="publishStart-date"
                    name="publishStart-date"
                    selected={publishStart ? new Date(publishStart) : undefined}
                    onChange={(date) => {
                      if (dateFnsIsValid(date)) {
                        isToday(date)
                          ? setFieldValue(
                              "publishStart",
                              dateFnsFormat(new Date(), DATE_TIME_FORMAT)
                            )
                          : setFieldValue(
                              "publishStart",
                              dateFnsFormat(startOfDay(date), DATE_TIME_FORMAT)
                            );
                      } else {
                        setFieldValue("publishStart", undefined);
                      }
                    }}
                    minDate={new Date()}
                    locale={pl}
                    dateFormat={DATE_FORMAT}
                    invalid={!!ePublishStart}
                    autoComplete="off"
                    disabled={articleOnPublishedStatus}
                    clearButtonTitle="Wyczyść"
                    isClearable
                  />
                </InputGroup>
                <InputGroup className="ml-2">
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                      <i className="pe-7s-timer" />
                    </div>
                  </InputGroupAddon>
                  <DatePicker
                    className="form-control"
                    id="publishStart-time"
                    name="publishStart-time"
                    selected={publishStart ? new Date(publishStart) : undefined}
                    timeFormat={TIME_FORMAT}
                    dateFormat={TIME_FORMAT}
                    invalid={!!ePublishStart}
                    autoComplete="off"
                    disabled={articleOnPublishedStatus}
                    customInput={
                      <TimeInput
                        invalid={!!ePublishStart}
                        date={publishStart ? new Date(publishStart) : undefined}
                        minTime={
                          isToday(new Date(publishStart))
                            ? new Date()
                            : startOfDay(new Date(publishStart))
                        }
                        maxTime={endOfDay(new Date())}
                        onChangeDate={(date) => {
                          dateFnsIsValid(date)
                            ? setFieldValue(
                                "publishStart",
                                dateFnsFormat(
                                  setSeconds(date, 0),
                                  DATE_TIME_FORMAT
                                )
                              )
                            : setFieldValue("publishStart", undefined);
                        }}
                      />
                    }
                  />
                </InputGroup>
              </div>
              {ePublishStart && (
                <FormFeedback style={{ display: "block" }}>
                  {ePublishStart}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="publishEnd">{DATA_FROM_PUBLICATION_TO}</Label>
              <div className="d-flex">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                      <i className="pe-7s-date" />
                    </div>
                  </InputGroupAddon>
                  <DatePicker
                    className="form-control"
                    id="publishEnd-date"
                    name="publishEnd-date"
                    selected={publishEnd ? new Date(publishEnd) : undefined}
                    onChange={(date) => {
                      dateFnsIsValid(date)
                        ? setFieldValue(
                            "publishEnd",
                            dateFnsFormat(endOfDay(date), DATE_TIME_FORMAT)
                          )
                        : setFieldValue("publishEnd", undefined);
                    }}
                    locale={pl}
                    minDate={publishStart}
                    dateFormat={DATE_FORMAT}
                    invalid={!!ePublishEnd}
                    autoComplete="off"
                    disabled={articleOnPublishedStatus}
                    clearButtonTitle="Wyczyść"
                    isClearable
                  />
                </InputGroup>
                <InputGroup className="ml-2">
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                      <i className="pe-7s-timer" />
                    </div>
                  </InputGroupAddon>
                  <DatePicker
                    className="form-control"
                    id="publishEnd-time"
                    name="publishEnd-time"
                    selected={publishEnd ? new Date(publishEnd) : undefined}
                    timeFormat={TIME_FORMAT}
                    dateFormat={TIME_FORMAT}
                    invalid={!!ePublishEnd}
                    autoComplete="off"
                    disabled={articleOnPublishedStatus}
                    customInput={
                      <TimeInput
                        invalid={!!ePublishEnd}
                        date={publishEnd ? new Date(publishEnd) : undefined}
                        minTime={
                          publishStart &&
                          startOfDay(publishStart) === startOfDay(publishEnd)
                            ? addMinutes(new Date(publishStart), 1)
                            : isToday(new Date(publishEnd))
                            ? addMinutes(new Date(), -1)
                            : startOfDay(publishEnd)
                        }
                        maxTime={endOfDay(new Date(publishEnd))}
                        onChangeDate={(date) => {
                          dateFnsIsValid(date)
                            ? setFieldValue(
                                "publishEnd",
                                dateFnsFormat(
                                  setSeconds(date, 59),
                                  DATE_TIME_FORMAT
                                )
                              )
                            : setFieldValue("publishEnd", undefined);
                        }}
                      />
                    }
                  />
                </InputGroup>
              </div>
              {ePublishEnd && (
                <FormFeedback style={{ display: "block" }}>
                  {ePublishEnd}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        {baseArticleType === ArticleType.Common.value && (
          <Row>
            <Col>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="archiveAfterPublishEnd"
                    name="archiveAfterPublishEnd"
                    checked={archiveAfterPublishEnd}
                    onChange={() =>
                      setFieldValue(
                        "archiveAfterPublishEnd",
                        !archiveAfterPublishEnd
                      )
                    }
                    invalid={!!eArchiveAfterPublishEnd}
                    disabled={articleOnPublishedStatus}
                  />
                  {ARCHIVE_FROM_DATA}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};
