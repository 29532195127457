import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { format as dateFnsFormat } from "date-fns";
import { pl } from "date-fns/locale";
import { CONSTANTS } from "@constants";
import { DATE_TIME_FORMAT } from "./../utils";

const {
  DATE_ACCEPTED,
  DATE_CREATED,
  DATE_PUBLISHED,
  DATE_OF_WITHDRAWL,
  DATE_OF_ARCHIVE,
  DETAILS,
  VERSION,
} = CONSTANTS;

export const DetailsCard = ({ values = {} }) => {
  const {
    createdDate,
    publishedDate,
    withdrawedDate,
    archivedDate,
    confirmedDate,
    currentVersion,
  } = values;

  return (
    <Card className="mb-2">
      <CardHeader>
        <i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach" />
        {DETAILS}
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <FormGroup row>
              <Label md={12} lg={4}>
                {DATE_CREATED}
              </Label>
              <Col md={12} lg={8}>
                <Input
                  className="opacity-6"
                  plaintext
                  disabled
                  value={
                    createdDate
                      ? dateFnsFormat(new Date(createdDate), DATE_TIME_FORMAT, {
                          locale: pl,
                        })
                      : "-"
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={12} lg={4}>
                {DATE_ACCEPTED}
              </Label>
              <Col md={12} lg={8}>
                <Input
                  className="opacity-6"
                  plaintext
                  disabled
                  value={
                    confirmedDate
                      ? dateFnsFormat(
                          new Date(confirmedDate),
                          DATE_TIME_FORMAT,
                          { locale: pl }
                        )
                      : "-"
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={12} lg={4}>
                {DATE_PUBLISHED}
              </Label>
              <Col md={12} lg={8}>
                <Input
                  className="opacity-6"
                  plaintext
                  disabled
                  value={
                    publishedDate
                      ? dateFnsFormat(
                          new Date(publishedDate),
                          DATE_TIME_FORMAT,
                          { locale: pl }
                        )
                      : "-"
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={12} lg={4}>
                {DATE_OF_WITHDRAWL}
              </Label>
              <Col md={12} lg={8}>
                <Input
                  className="opacity-6"
                  plaintext
                  disabled
                  value={
                    withdrawedDate
                      ? dateFnsFormat(
                          new Date(withdrawedDate),
                          DATE_TIME_FORMAT,
                          { locale: pl }
                        )
                      : "-"
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={12} lg={4}>
                {DATE_OF_ARCHIVE}
              </Label>
              <Col md={12} lg={8}>
                <Input
                  className="opacity-6"
                  plaintext
                  disabled
                  value={
                    archivedDate
                      ? dateFnsFormat(
                          new Date(archivedDate),
                          DATE_TIME_FORMAT,
                          { locale: pl }
                        )
                      : "-"
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={12} lg={4}>
                {VERSION}
              </Label>
              <Col md={12} lg={8}>
                <Input
                  className="opacity-6"
                  plaintext
                  disabled
                  value={currentVersion ? currentVersion.version : 1}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
