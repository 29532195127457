import { MenuConfiguration, DefaultLanguage } from '../helpers/enums';
import {SESSION_TIMEOUT} from '../helpers/units';

export const Application = {
	version: '1.0.0.0',
	menuConfiguration: {
		horizontal: {
			maxElements: 6,
			maxLevels: 1
		},
		vertical: {
			maxElements: MenuConfiguration.Infinity,
			maxLevels: 4
		}
	},
	activeLanguage: DefaultLanguage,
	newTabOpenLinks: false,
	maxFileSize: 10,
	languages: [],
	tags: [],
	serverIdentity: {
		address: '',
		client_id: '',
		scope: ''
	},
	dictionaryElements: [],
	helpPageData: { url: '', login: '', password: '', redirectTo: '' },
	permissions: [],
	systemRoles: [],
	sessionTimeout: SESSION_TIMEOUT,
	sessionEnabled: false,
	lastResetSessionTime: 0
};
