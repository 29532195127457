import React from 'react';
import { FormGroup, Input, Label, FormFeedback, Button, Form } from 'reactstrap';
import cx from 'classnames';

import { LinkTargetType, FooterLinkType, DefaultFormikConfig } from '../../helpers/enums';
import { Formik } from 'formik';
import uuid from 'uuid';
import { FooterLinkValidationSchema } from '../../validators';

export default ({
	values = {
		key: uuid(),
		parentKey: null,
		type: FooterLinkType.External.value,
		url: '',
		slug: '',
		displayText: '',
		title: '',
		target: LinkTargetType.Self.value
	},
	onAdd = (link) => {},
	cancel = () => {}
}) => (
	<Formik
		{...DefaultFormikConfig}
		initialValues={values}
		validationSchema={FooterLinkValidationSchema}
		onSubmit={(values) => onAdd(values)}
		onReset={cancel}
	>
		{({ errors, values, handleChange, isValid, dirty, setFieldValue, handleSubmit, handleReset }) => (
			<Form noValidate method="post" onSubmit={handleSubmit} onReset={handleReset}>
				<FormGroup>
					<Label for="url">URL</Label>
					<Input
						type="text"
						id="url"
						name="url"
						value={values.url}
						onChange={handleChange}
						invalid={!!errors.url}
					/>
					<FormFeedback>{errors.url}</FormFeedback>
				</FormGroup>
				<FormGroup>
					<Label for="displayText">Nazwa do wyświetlenia</Label>
					<Input
						type="text"
						id="displayText"
						name="displayText"
						value={values.displayText}
						onChange={handleChange}
						invalid={!!errors.displayText}
					/>
					<FormFeedback>{errors.displayText}</FormFeedback>
				</FormGroup>
				<FormGroup>
					<Label for="title">Tekst pod czytniki dla osób słabowidzących</Label>
					<Input
						type="text"
						id="title"
						name="title"
						value={values.title}
						onChange={handleChange}
						invalid={!!errors.title}
					/>
					<FormFeedback>{errors.title}</FormFeedback>
				</FormGroup>

				<FormGroup className="d-flex align-items-center">
					<Label for="target" className="mr-2">
						Otwórz odnośnik w nowym oknie
					</Label>
					<div
						className="switch has-switch mb-2 mr-2"
						data-on-label="Tak"
						data-off-label="Nie"
						onClick={(e) => {
							const val =
								values.target === LinkTargetType.NewTab.value
									? LinkTargetType.Self.value
									: LinkTargetType.NewTab.value;
							setFieldValue(`target`, val);
						}}
						invalid={!!errors.target}
					>
						<div
							className={cx('switch-animate', {
								'switch-on': values.target === LinkTargetType.NewTab.value,
								'switch-off': values.target === LinkTargetType.Self.value
							})}
						>
							<input type="checkbox" />
							<span className="switch-left">Tak</span>
							<label>&nbsp;</label>
							<span className="switch-right">Nie</span>
						</div>
					</div>
					<FormFeedback>{errors.target}</FormFeedback>
				</FormGroup>
				<Button className="btn-icon mr-2" color="primary" type="submit">
					<i className="pe-7s-diskette btn-icon-wrapper" />Zapisz
				</Button>
			</Form>
		)}
	</Formik>
);
