import React, { Component, Fragment } from 'react';
import { Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import map from 'lodash.map';

import SingleImageCard from '../Modals/SingleImage/SingleImageCard';
import MultiImageCard from '../Modals/MultiImage/MultiImageCard';

import { ApplicationImageCardType, ImageData } from '../../../../helpers/enums';

export default class ApplicationImageCard extends Component {
	state = {
		showDetails: false
	};
	toggle = () => this.setState({ showDetails: !this.state.showDetails });

	render() {
		const {
			element = {
				id: 0,
				fileName: '',
				randomName: '',
				contentType: '',
				path: '',
				size: 0,
				cropData: {
					unit: 'px',
					x: 0,
					y: 0,
					width: 10,
					height: 10
				}
			},
			imageType = 0,
			maxFileSize = 10,
			recommendedWidth = 256,
			recommendedHeight = 144,
			cropperSizes = { height: recommendedHeight, width: recommendedHeight },
			cardType = ApplicationImageCardType.Single,
			name = '',
			title = 'Edycja grafiki',
			tooltip = 'Edytuj grafikę',
			showUseImage = false,
			useImage = false,
			useGradient = false,
			disabled = false,
			onChange = (element) => {},
			changeUseImage = (element) => {},
			changeUseGradient = (element) => {}
		} = this.props;
		const { showDetails } = this.state;

		return (
			<Fragment>
				{cardType === ApplicationImageCardType.Single && (
					<SingleImageCard
						title={title}
						isOpen={showDetails}
						image={element}
						updateImage={onChange}
						cancel={this.toggle}
						changeUseImage={changeUseImage}
						changeUseGradient={changeUseGradient}
						{...{
							imageType,
							maxFileSize,
							recommendedWidth,
							recommendedHeight,
							cropperSizes,
							useImage: imageType !== ImageData.background.id || useImage,
							useGradient,
							showUseImage
						}}
					/>
				)}
				{cardType === ApplicationImageCardType.Multi && (
					<MultiImageCard
						title={title}
						isOpen={showDetails}
						images={element}
						updateImages={onChange}
						cancel={this.toggle}
						{...{
							imageType,
							maxFileSize,
							recommendedWidth,
							recommendedHeight,
							cropperSizes,
							useImage,
							useGradient,
							showUseImage
						}}
					/>
				)}
				<Card
					className={`mb-3 card-hover-shadow card-border application-image-card`}
					outline
				>
					<CardHeader>
						<i className="header-icon pe-7s-photo icon-gradient bg-malibu-beach" />
						{name}
						<div className="btn-actions-pane-right actions-icon-btn">
							<Button
								className="mr-2 btn-icon btn-icon-only"
								color="link"
								outline
								title={tooltip}
								type="button"
								disabled={disabled}
								onClick={this.toggle}
							>
								<i className="lnr-pencil btn-icon-wrapper"> </i>
							</Button>
						</div>
					</CardHeader>
					<CardBody>
						<ListGroup flush>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-wrapper justify-content-center align-items-end">
										<div className="d-flex he-100 flex-wrap">
											{element &&
											cardType === ApplicationImageCardType.Single && (
												<div className="font-icon-wrapper font-icon-lg">
													<i className="pe-7s-photo" />
												</div>
											)}
											{element &&
											element.length <= 3 &&
											cardType === ApplicationImageCardType.Multi && (
												<Fragment>
													{map(element, (item, index) => (
														<div className="font-icon-wrapper font-icon-lg mr-1">
															<i className="pe-7s-photo" />
														</div>
													))}
												</Fragment>
											)}

											{element &&
											element.length > 3 &&
											cardType === ApplicationImageCardType.Multi && (
												<Fragment>
													<div className="font-icon-wrapper font-icon-lg mr-1">
														<i className="pe-7s-photo" />
													</div>
													<div className="font-icon-wrapper font-icon-lg mr-1">
														<i className="pe-7s-photo mr-1" />
													</div>
													<div className="font-icon-wrapper font-icon-lg mr-1">
														<i className="pe-7s-photo mr-1" />
													</div>
													{element.length > 3 && (
														<div className="font-icon-wrapper font-icon-lg mr-1">
															<i className="pe-7s-more mr-1" />
														</div>
													)}
												</Fragment>
											)}
										</div>
									</div>
								</div>
							</ListGroupItem>
						</ListGroup>
					</CardBody>
				</Card>
			</Fragment>
		);
	}
}
