import { ArticleActions } from '../actions/article';
import { MenuActions } from '../actions/menu';
import { Footer } from '../stores';
import Immutable from 'seamless-immutable';
import map from 'lodash.map';
import {FooterLinkType, LinkTargetType} from '../helpers/enums';

const initialState = Immutable(Footer);

export function footer(state = initialState, action) {
	switch (action.type) {
		case MenuActions.GetMenu.Success:
		case MenuActions.GetMenu.Failure: {
			return state.merge({
                availableLinks: map(action.payload, (item) => ({
                    type: item.type,
                    key: item.elementId,
                    parentKey: item.parentElementId,
                    displayText: item.displayText,
                    title: item.title,
                    url: item.url,
                    slug: item.slug,
                    target: item.target
				}))
			});
		}
		case ArticleActions.GetAllArticles.Success:
		case ArticleActions.GetAllArticles.Failure: {
			return state.merge({
				availableLinks: map(action.payload.items, (item) => ({
                    type: FooterLinkType.Article.value,
                    key: item.baseArticleId,
                    parentKey: null,
                    displayText: item.title,
                    title: item.title,
                    url: item.url || '',
                    slug: item.slug,
                    target: LinkTargetType.Self.value
				}))
			});
		}
		default: {
			return state;
		}
	}
}
