import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Card, CardHeader, CardBody, Col, Row, Input } from 'reactstrap';

export const IntegrationWithRadniInfo = ({ clientId = 0, title, onChange, ...props }) => {
	const [ radniInfoClientId, setRadniInfoClientId ] = useState(clientId || 0);

	useEffect(
		() => {
			setRadniInfoClientId(clientId);
		},
		[ clientId ]
	);

	const handleValueChange = (e) => {
		const clientId = parseInt(e.target.value) || 0;

		if (onChange && typeof onChange === 'function') {
			onChange('value.clientId', clientId);
			setRadniInfoClientId(clientId);
		}
	};

	return (
		<Card className="mb-2">
			<CardHeader>
				<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
				{props.title}
			</CardHeader>
			<CardBody>
				<Row>
					<Col>
						<FormGroup>
							<Label for="paramValue">{title}</Label>
							<Input
								type="number"
								id="paramValue"
								name="paramValue"
								min="0"
								step="1.0"
								value={radniInfoClientId}
								onChange={handleValueChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};
