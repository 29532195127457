import React, { Component, Fragment } from 'react';
import map from 'lodash.map';
import filter from 'lodash.filter';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import classnames from 'classnames';
import { FieldArray } from 'formik';

import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Button,
	ListGroup,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Collapse
} from 'reactstrap';
import RSC from 'react-scrollbars-custom';

import LinkCardItem from './LinkCardItem';
import ChooseCategory from '../Modals/ChooseCategory';
import ChooseBookmark from '../Modals/ChooseBookmark';

import { getSourcesNumber, getSourceNumber } from '@utils/rss-channel-details';
import { LinkType, MenuType, DefaultLanguage } from '@helpers/enums';

const RssTabType = {
	HorizontalMenu: 10,
	VerticalMenu: 20,
	Bookmark: 30
};

export default class RssChannelDetailsCard extends Component {
	static defaultProps = {
		activeLanguage: DefaultLanguage,
		horizontalMenuElements: [],
		verticalMenuElements: [],
		bookmarksElements: [],
		onSave: elements => {}
	};

	constructor(props) {
		super(props);

		this.state = {
			cardCollapse: true,
			horizontalMenuModalOpen: false,
			verticalMenuModalOpen: false,
			bookmarkModalOpen: false,
			activeTab: RssTabType.HorizontalMenu,
			horizontalMenuElements: props.horizontalMenuElements || [],
			verticalMenuElements: props.verticalMenuElements || [],
			bookmarksElements: props.bookmarksElements || [],
			activeLinks: filter(props.links, {
				type: LinkType.Category.value
			})
		};
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.horizontalMenuElements, this.props.horizontalMenuElements)) {
			this.setState({
				horizontalMenuElements: this.props.horizontalMenuElements
			});
		}
		if (!isEqual(prevProps.verticalMenuElements, this.props.verticalMenuElements)) {
			this.setState({
				verticalMenuElements: this.props.verticalMenuElements
			});
		}
		if (!isEqual(prevProps.bookmarksElements, this.props.bookmarksElements)) {
			this.setState({
				bookmarksElements: this.props.bookmarksElements
			});
		}
	}

	toggleCollapse = () => {
		this.setState({ cardCollapse: !this.state.cardCollapse });
	};

	toggleModalOpen = (name, event) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.setState({ [name]: !this.state[name] });
	};

	changeLinkActiveTab = activeTab => this.setState({ activeTab });

	handleSaveHorizontalMenu = selectedLinks => {
		const { onSave } = this.props;

		if (onSave && typeof onSave === 'function') {
			const newLinks = map(selectedLinks, link => ({
				id: link.menuElementId,
				type: link.menuElementType,
				title: link.menuElementTitle,
				displayText: link.menuElementDisplayText
			}));
			onSave('horizontalMenuElements', newLinks);
		}
	};

	handleSaveVerticalMenu = selectedLinks => {
		const { onSave } = this.props;

		if (onSave && typeof onSave === 'function') {
			const newLinks = map(selectedLinks, link => ({
				id: link.menuElementId,
				type: link.menuElementType,
				title: link.menuElementTitle,
				displayText: link.menuElementDisplayText
			}));
			onSave('verticalMenuElements', newLinks);
		}
	};

	handleSaveBookmark = selectedLinks => {
		const { onSave } = this.props;

		if (onSave && typeof onSave === 'function') {
			const newLinks = map(selectedLinks, link => ({
				id: link.bookmarkId,
				displayText: link.displayText
			}));
			onSave('bookmarksElements', newLinks);
		}
	};

	render() {
		const {
			cardCollapse,
			activeTab,
			horizontalMenuModalOpen,
			horizontalMenuElements,
			verticalMenuModalOpen,
			verticalMenuElements,
			bookmarkModalOpen,
			bookmarksElements
		} = this.state;

		const { activeLanguage, disabled = false, allSources = false } = this.props;

		return (
			<Fragment>
				{horizontalMenuModalOpen && (
					<ChooseCategory
						isOpen={horizontalMenuModalOpen}
						categories={map(horizontalMenuElements, link => ({
							id: link.id,
							menuElementId: link.id,
							menuElementType: link.type,
							menuElementTitle: link.title,
							menuElementDisplayText: link.displayText,
							menuElementIsHidden: link.isHidden || false,
							...link
						}))}
						menuType={MenuType.Horizontal}
						onToggle={this.toggleModalOpen.bind(this, 'horizontalMenuModalOpen')}
						onSave={this.handleSaveHorizontalMenu}
					/>
				)}
				{verticalMenuModalOpen && (
					<ChooseCategory
						isOpen={verticalMenuModalOpen}
						categories={map(verticalMenuElements, link => ({
							id: link.id,
							menuElementId: link.id,
							menuElementType: link.type,
							menuElementTitle: link.title,
							menuElementDisplayText: link.displayText,
							menuElementIsHidden: link.isHidden || false,
							...link
						}))}
						menuType={MenuType.Vertical}
						onToggle={this.toggleModalOpen.bind(this, 'verticalMenuModalOpen')}
						onSave={this.handleSaveVerticalMenu}
					/>
				)}
				{bookmarkModalOpen && (
					<ChooseBookmark
						isOpen={bookmarkModalOpen}
						bookmarks={map(bookmarksElements, link => ({
							bookmarkId: link.id,
							...link
						}))}
						isoCode={activeLanguage.isoCode}
						onToggle={this.toggleModalOpen.bind(this, 'bookmarkModalOpen')}
						onSave={this.handleSaveBookmark}
					/>
				)}
				<Card className="mb-2">
					<CardHeader onClick={this.toggleCollapse} style={{ cursor: 'pointer' }}>
						<i className="header-icon pe-7s-link icon-gradient bg-malibu-beach" />
						{'Źródła' +
							(getSourcesNumber(horizontalMenuElements, verticalMenuElements, bookmarksElements) > 0
								? '  ' +
									`(${getSourcesNumber(
										horizontalMenuElements,
										verticalMenuElements,
										bookmarksElements
									)})`
								: '')}
						<div className="btn-actions-pane-right btn-actions-cursor--pointer">
							{cardCollapse ? 'Zwiń' : 'Rozwiń'}
							{cardCollapse ? <i className="pe-7s-angle-up" /> : <i className="pe-7s-angle-down" />}
						</div>
					</CardHeader>
					<Collapse isOpen={cardCollapse}>
						<CardBody>
							<CardHeader>
								<Nav justified>
									<NavItem>
										<NavLink
											href="javascript:void(0);"
											className={classnames({
												active: activeTab === RssTabType.HorizontalMenu
											})}
											onClick={() => {
												this.changeLinkActiveTab(RssTabType.HorizontalMenu);
											}}
										>
											<div className="d-flex align-items-baseline justify-content-center">
												<CardTitle className="mb-0">
													Menu poziome{' '}
													{getSourceNumber(
														horizontalMenuElements,
														activeTab,
														RssTabType.HorizontalMenu
													)}
												</CardTitle>
												{!disabled &&
												activeTab === RssTabType.HorizontalMenu && (
													<Button
														type="button"
														className="mr-2 border-0 btn-transition"
														outline
														color="success"
														onClick={this.toggleModalOpen.bind(
															this,
															'horizontalMenuModalOpen'
														)}
														disabled={disabled}
													>
														<i className="pe-7s-plus btn-icon-wrapper" />
													</Button>
												)}
											</div>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											href="javascript:void(0);"
											className={classnames({
												active: activeTab === RssTabType.VerticalMenu
											})}
											onClick={() => {
												this.changeLinkActiveTab(RssTabType.VerticalMenu);
											}}
										>
											<div className="d-flex align-items-baseline justify-content-center">
												<CardTitle className="mb-0">
													Menu pionowe{' '}
													{getSourceNumber(
														verticalMenuElements,
														activeTab,
														RssTabType.VerticalMenu
													)}
												</CardTitle>
												{!disabled &&
												activeTab === RssTabType.VerticalMenu && (
													<Button
														type="button"
														className="mr-2 border-0 btn-transition"
														outline
														color="success"
														onClick={this.toggleModalOpen.bind(
															this,
															'verticalMenuModalOpen'
														)}
														{...{ disabled }}
													>
														<i className="pe-7s-plus btn-icon-wrapper" />
													</Button>
												)}
											</div>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											href="javascript:void(0);"
											className={classnames({
												active: activeTab === RssTabType.Bookmark
											})}
											onClick={() => {
												this.changeLinkActiveTab(RssTabType.Bookmark);
											}}
										>
											<div className="d-flex align-items-baseline justify-content-center">
												<CardTitle className="mb-0">
													Zakładki{' '}
													{getSourceNumber(bookmarksElements, activeTab, RssTabType.Bookmark)}
												</CardTitle>
												{!disabled &&
												activeTab === RssTabType.Bookmark && (
													<Button
														type="button"
														className="mr-2 border-0 btn-transition"
														outline
														color="success"
														onClick={this.toggleModalOpen.bind(this, 'bookmarkModalOpen')}
														disabled={disabled}
													>
														<i className="pe-7s-plus btn-icon-wrapper" />
													</Button>
												)}
											</div>
										</NavLink>
									</NavItem>
								</Nav>
							</CardHeader>
							<TabContent activeTab={activeTab}>
								<TabPane tabId={RssTabType.HorizontalMenu}>
									<div
										className={classnames({
											'scroll-area-md': !isEmpty(horizontalMenuElements) || allSources
										})}
									>
										<RSC>
											<ListGroup flush>
												{!allSources && (
													<FieldArray name="horizontalMenuElements">
														{({ remove, replace: onChange }) =>
															map(horizontalMenuElements, (link, linkIndex) => (
																<LinkCardItem
																	{...{ link, onChange }}
																	editable={false}
																	key={`link-category-item-${linkIndex}`}
																	onRemove={() => remove(linkIndex)}
																/>
															))}
													</FieldArray>
												)}
												{allSources && (
													<LinkCardItem
														link={{ displayText: 'Wszystkie' }}
														editable={false}
														removeable={false}
														key={`link-category-item-1`}
													/>
												)}
											</ListGroup>
										</RSC>
									</div>
								</TabPane>
								<TabPane tabId={RssTabType.VerticalMenu}>
									<div
										className={classnames({
											'scroll-area-md': !isEmpty(verticalMenuElements) || allSources
										})}
									>
										<RSC>
											<ListGroup flush>
												{!allSources && (
													<FieldArray name="verticalMenuElements">
														{({ remove, replace: onChange }) =>
															map(verticalMenuElements, (link, linkIndex) => (
																<LinkCardItem
																	{...{ link, onChange }}
																	editable={false}
																	key={`link-article-item-${linkIndex}`}
																	onRemove={() => remove(linkIndex)}
																/>
															))}
													</FieldArray>
												)}
												{allSources && (
													<LinkCardItem
														link={{ displayText: 'Wszystkie' }}
														editable={false}
														removeable={false}
														key={`link-article-item-1`}
													/>
												)}
											</ListGroup>
										</RSC>
									</div>
								</TabPane>
								<TabPane tabId={RssTabType.Bookmark}>
									<div
										className={classnames({
											'scroll-area-md': !isEmpty(bookmarksElements) || allSources
										})}
									>
										<RSC>
											<ListGroup flush>
												{!allSources && (
													<FieldArray name="bookmarksElements">
														{({ remove, replace: onChange }) =>
															map(bookmarksElements, (link, linkIndex) => (
																<LinkCardItem
																	{...{ link, onChange }}
																	editable={false}
																	key={`link-external-item-${linkIndex}`}
																	onRemove={() => remove(linkIndex)}
																/>
															))}
													</FieldArray>
												)}
												{allSources && (
													<LinkCardItem
														link={{ displayText: 'Wszystkie' }}
														editable={false}
														removeable={false}
														key={`link-external-item-1`}
													/>
												)}
											</ListGroup>
										</RSC>
									</div>
								</TabPane>
							</TabContent>
						</CardBody>
					</Collapse>
				</Card>
			</Fragment>
		);
	}
}
