import * as yup from 'yup';
import { LinkTargetType } from '../helpers/enums';
import isEmpty from 'lodash.isempty';

export const BookmarkValidationSchema = yup.object().shape({
	displayText: yup.string().trim()
		.max(30, 'Maksymalna długość nazwy wyświetlanej nie może przekroczyć 30 znaków')
		.required('Nazwa wyświetlana jest polem wymaganym'),
	title: yup.string().trim().required('Tekst pod czytniki dla osób słabowidzących jest polem wymaganym'),
	target: yup
		.number()
		.oneOf(
			[ LinkTargetType.Self.value, LinkTargetType.NewTab.value ],
			`Otwórz zakładkę w nowym oknie musi zawierać wartość: ${LinkTargetType.Self.name} lub ${LinkTargetType
				.NewTab.name}`
	),
	isSystem: yup.bool(),
	isActive: yup.bool().test('Any array has any item', 'Zakładka nie wskazuje żadnego tagu', function (isActive) {
		if (!isActive || this.parent.isSystem) {
			return true;
		}

		return !isEmpty(this.parent.tags);
	}),
	tags: yup.array()
	// isSystem: yup.bool(),
	// tags: yup.array(),
	// color: yup.string().trim().required('Kolor jest polem wymaganym'),
	// showOnMainPage: yup.bool()
});
