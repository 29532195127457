import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import OfficeContactModalCard from './OfficeContactModalCard';

export default class OfficeElementCard extends Component {
	state = {
		showDetails: false
	};
	toggle = () => this.setState({ showDetails: !this.state.showDetails });

	render() {
		const {
			officeContact = { fullName: '', jobPosition: '', description: '', isSystem: false },
			disabled = false,
			index = 0,
			isInvalid = false,
			maxFileSize = 0,
			onChange = (footerElement) => {},
			onRemove = (footerElement) => {}
		} = this.props;
		const { showDetails } = this.state;

		return (
			<Card
				className={`mb-3 card-hover-shadow card-border ${isInvalid ? ' card-border-danger' : ''}`}
				style={{ minHeight: '220px', maxHeight: '220px' }}
				outline
			>
				<OfficeContactModalCard
					isOpen={showDetails}
					officeContact={officeContact}
					maxFileSize={maxFileSize}
					updateContact={(values) => onChange(index, values)}
					cancel={this.toggle}
				/>
				<CardHeader>
					<i className="header-icon pe-7s-cloud icon-gradient bg-malibu-beach" />
					<span
						style={{
							display: 'block',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}}
					>
						{officeContact.jobPosition}
					</span>
					<div className="btn-actions-pane-right actions-icon-btn">
						{!officeContact.isSystem && (
							<Button
								className="mr-2 btn-icon btn-icon-only"
								color="link"
								outline
								title="Usuwa element"
								type="button"
								disabled={disabled}
								onClick={(_) => onRemove(index)}
							>
								<i className="lnr-trash btn-icon-wrapper"> </i>
							</Button>
						)}
						<Button
							className="mr-2 btn-icon btn-icon-only"
							color="link"
							outline
							title="Edytuj element"
							type="button"
							disabled={disabled}
							onClick={this.toggle}
						>
							<i className="lnr-pencil btn-icon-wrapper"> </i>
						</Button>
					</div>
				</CardHeader>
				<CardBody>
					<ListGroup flush>
						<ListGroupItem>
							<div className="widget-content p-0">
								<div className="widget-content-wrapper">
									<div className="widget-content-left" style={{ width: '100%' }}>
										<div className="widget-heading">Imię i nazwisko</div>
										<div
											className="widget-subheading"
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis'
											}}
										>
											{officeContact.fullName}
										</div>
										<div className="widget-heading">Opis</div>
										<div
											className="widget-subheading"
											style={{ overflow: 'hidden', textOverflow: 'eclipse', height: '50px' }}
											dangerouslySetInnerHTML={{ __html: officeContact.description }}
										/>
									</div>
								</div>
							</div>
						</ListGroupItem>
					</ListGroup>
				</CardBody>
			</Card>
		);
	}
}
