import * as yup from "yup";
import { LinkTargetType, FooterLinkType } from "../helpers/enums";

export const FooterLinkValidationSchema = yup.object().shape({
  type: yup
    .number()
    .oneOf(
      [
        FooterLinkType.HorizontalMenu.value,
        FooterLinkType.VerticalMenu.value,
        FooterLinkType.Article.value,
        FooterLinkType.External.value,
      ],
      `Typ odnośnika musi zawierać wartość: ${FooterLinkType.HorizontalMenu.name}, ${FooterLinkType.VerticalMenu.name}, ${FooterLinkType.Article.name} lub ${FooterLinkType.External.name}`
    ),
  url: yup
    .string()
    .nullable()
    .when(["type"], {
      is: FooterLinkType.External.value,
      then: yup
        .string()
        .url(
          "Adres URL musi zawierać prawidłowy format adresu. Musi składać się protokołu, adresu oraz domeny"
        )
        .required("Adres URL jest wymagany"),
      otherwise: yup.string().nullable(),
    }),
  displayText: yup
    .string()
    .trim()
    .required("Nazwa wyświetlana jest polem wymaganym"),
  title: yup
    .string()
    .trim()
    .required(
      "Tekst pod czytniki dla osób słabowidzących jest polem wymaganym"
    ),
  target: yup
    .number()
    .oneOf(
      [LinkTargetType.Self.value, LinkTargetType.NewTab.value],
      `Otwórz zakładkę w nowym oknie musi zawierać wartość: ${LinkTargetType.Self.name} lub ${LinkTargetType.NewTab.name}`
    ),
});
