import Immutable from "seamless-immutable";
import map from "lodash.map";

import { Menu } from "../stores";
import { MenuActions } from "../actions/menu";
import { SearchActions } from "../actions/search";
import isEmpty from "lodash.isempty";

const initialState = Immutable(Menu);

export function menu(state = initialState, action = {}) {
  switch (action.type) {
    case MenuActions.UpdateMenuData.Success: {
      const { menu } = action.payload;

      return state.merge({
        menu,
      });
    }
    case SearchActions.SearchCategoriesByTitle.Success: {
      return state.merge({
        searchedMenus: action.payload.items,
      });
    }
    case SearchActions.SearchCategories.Request: {
      return state.merge({
        searchExecuted: false,
      });
    }
    case SearchActions.SearchCategories.Success: {
      const items = map(action.payload.categories.items, (item) => ({
        ...item,
        canDelete: !item.hasExternalLinks && !item.hasPublicArticles,
      }));
      return state.merge({
        searchExecuted: !isEmpty(action.payload.title),
        menu: Immutable(items, { deep: true }),
      });
    }
    case MenuActions.GetMenu.Success:
    case MenuActions.UpdateMenu.Success: {
      const items = map(action.payload, (item) => ({
        ...item,
        canDelete: !item.hasExternalLinks && !item.hasPublicArticles,
      }));
      return state.merge({ menu: Immutable(items, { deep: true }) });
    }
    case MenuActions.GetMenuElementIds.Success: {
      return state.setIn(["menuElementIds"], action.payload);
    }
    case MenuActions.GetMenuElementSortConfigurations.Success: {
      return state.merge({
        menuSortConfiguration: {
          articleSortOnCategoryType: action.payload.articleSortOnCategoryType,
          articles: action.payload.articles.items,
        },
      });
    }
    default: {
      return state;
    }
  }
}
