import React from 'react';
import { DragSource } from 'react-dnd';
import { DragDropType } from '@helpers/enums';

import { addBookmarks } from '@components/utils';

const source = {
	beginDrag(props) {
		return { ...props };
	},
	endDrag(props, monitor, component) {
		if (!monitor.didDrop()) {
			return;
		}
	}
};

const collect = (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	};
};

const BookmarkSource = ({ id, displayText, isDragging, connectDragSource, bookmarks, onChangeItems, ...props }) => {
	return connectDragSource(
		<div
			className="ReactVirtualized__Grid__innerScrollContainer"
			role="rowgroup"
			style={{
				width: 'auto',
				height: '80px',
				maxHeight: '80px',
				overflow: 'hidden',
				position: 'relative'
			}}
		>
			<div
				className="rst__node"
				style={{ height: '80px', left: '0px', position: 'absolute', top: '0px', width: '100%' }}
				onDoubleClick={() => addBookmarks(id, displayText, props, bookmarks, onChangeItems)}
			>
				<div className="rst__lineBlock rst__lineHalfHorizontalRight" style={{ width: '44px' }} />
				<div className="rst__nodeContent">
					<div className="" draggable="true" style={{ height: '100%' }}>
						<div className="rst__rowWrapper">
							<div className="rst__row" style={{ opacity: 1 }}>
								<div className={`rst__moveHandle ${isDragging ? 'rst__moveHandle--grabbing' : ''}`} />
								<div className="rst__rowContents">
									<div className="rst__rowLabel">
										<span>{displayText}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default DragSource(DragDropType.Bookmark, source, collect)(BookmarkSource);
