import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import FooterCard from "./Card";

const Footers = ({ match }) => (
  <Fragment>
    <Route path={match.url} component={FooterCard} />
  </Fragment>
);

export default Footers;
