import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import isEqual from "lodash.isequal";

import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import qs from "qs";

import { ArticleList } from "./ArticleList";
import { ArticleActionCreators } from "../../../../actions/article";
import PageTitle from "../../../../Layout/AppMain/PageTitle";

import { RouteUrls } from "../../../../helpers/routeUrls";
import { ApplicationActionCreators } from "../../../../actions/application";
import {
  LoaderType,
  DefaultLanguage,
  PageSize,
  ArticleType,
  ArticleStatus,
} from "../../../../helpers/enums";
import { PermissionName } from "../../../../helpers/permissionNames";
import { FilterActionCreators } from "../../../../actions/filter";

class Articles extends Component {
  constructor(props) {
    super(props);

    const { location = { search: "" } } = props;
    const statusSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
    const status =
      statusSearch && statusSearch.status
        ? parseInt(statusSearch.status)
        : null;

    this.state = {
      statusFilter: status,
      cardName: this.getCardName(status),
    };
  }

  componentDidMount() {
    this.onReload(0, PageSize.Rows20, [], []);
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeLanguage, location = { search: "" } } = this.props;
    const statusSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
    const status =
      statusSearch && statusSearch.status
        ? parseInt(statusSearch.status)
        : null;

    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.setState(
        {
          statusFilter: status,
          cardName: this.getCardName(status),
        },
        () => this.onReload(0, PageSize.Rows20, [], [])
      );
    }

    if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
      this.onReload(0, PageSize.Rows20, [], []);
    }
  }

  onAdd = () => {
    this.props.goTo(RouteUrls.Article.common.create);
  };
  onRowClick = (row) =>
    this.props.goTo(RouteUrls.Article.common.editFunc(row.id));

  onRemove = (articleIds) => this.props.deleteArticles(articleIds);

  onConfirm = (articleIds) => this.props.confirmArticles(articleIds);

  onPublish = (values) => this.props.publishArticles(values);

  onReload = (page, size, filters, sortings) => {
    const {
      activeLanguage,
      getAllArticles,
      articleFilters,
      extraArticleFilters,
    } = this.props;
    const { statusFilter } = this.state;

    getAllArticles(
      activeLanguage.isoCode,
      page,
      size,
      statusFilter ? articleFilters[statusFilter] : articleFilters["all"],
      sortings,
      [ArticleType.Common.value],
      statusFilter
        ? extraArticleFilters[statusFilter].concat({
            columnName: "status",
            operation: "equal",
            value: [statusFilter],
          })
        : extraArticleFilters["all"]
    );
  };

  clearFilters = async () => {
    const { clearFilters } = this.props;
    const { statusFilter } = this.state;
    await clearFilters(
      ["articleFilters", statusFilter ? statusFilter : "all"],
      []
    );
    await clearFilters(
      ["extraArticleFilters", statusFilter ? statusFilter : "all"],
      statusFilter
        ? [
            {
              columnName: "status",
              operation: "contains",
              value: statusFilter,
            },
          ]
        : []
    );
    this.onReload(0, PageSize.Rows20, [], []);
  };

  getCardName = (status) => {
    switch (status) {
      case ArticleStatus.Draft.value: {
        return "Lista artykułów roboczych";
      }
      case ArticleStatus.Confirmed.value: {
        return "Lista artykułów zatwierdzonych";
      }
      case ArticleStatus.ToPublish.value: {
        return "Lista artykułów do publikacji";
      }
      case ArticleStatus.Published.value: {
        return "Lista artykułów opublikowanych";
      }
      case ArticleStatus.Withdrawed.value: {
        return "Lista artykułów wycofanych";
      }
      case ArticleStatus.Archived.value: {
        return "Lista artykułów zarchiwizowanych";
      }
      default: {
        return "Lista artykułów";
      }
    }
  };

  setIsRemovable = () => {
    const { userPermissions } = this.props;
    const { statusFilter } = this.state;

    if (
      !userPermissions.includes(PermissionName.CreateArticles) ||
      +statusFilter > ArticleStatus.Draft.value
    ) {
      return false;
    }

    return true;
  };

  render() {
    const {
      articleFilters,
      extraArticleFilters,
      changeFilters,
      articles,
      totalArticles,
      activeLanguage,
      languages,
      changeApplicationLanguage,
      articleListBlocking,
      userPermissions,
      saveSelectedFilters,
      categorySelected,
    } = this.props;

    const { statusFilter, cardName } = this.state;

    return (
      <Fragment>
        <PageTitle
          heading={cardName}
          icon="pe-7s-copy-file icon-gradient bg-tempting-azure"
        />
        <BlockUi
          tag="div"
          blocking={articleListBlocking}
          loader={<Loader active type={LoaderType} />}
        >
          <ArticleList
            items={articles}
            totalItems={totalArticles}
            onReload={this.onReload}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onRowClick={this.onRowClick}
            internationalizable={true}
            filterable={true}
            drawerable={true}
            language={activeLanguage}
            languages={languages}
            addable={userPermissions.includes(PermissionName.CreateArticles)}
            removeable={this.setIsRemovable()}
            selectableItems={statusFilter < ArticleStatus.ToPublish.value}
            onChangeLanguage={changeApplicationLanguage}
            filters={articleFilters[statusFilter ? statusFilter : "all"]}
            extraFilters={
              extraArticleFilters[statusFilter ? statusFilter : "all"]
            }
            onChangeFilters={(filters) =>
              changeFilters(
                ["articleFilters", statusFilter ? statusFilter : "all"],
                filters
              )
            }
            onClearFilters={this.clearFilters}
            onChangeExtraFilters={(filters) =>
              changeFilters(
                ["extraArticleFilters", statusFilter ? statusFilter : "all"],
                filters
              )
            }
            saveSelectedFilters={(categories) =>
              saveSelectedFilters(
                ["extraArticleCategories", statusFilter ? statusFilter : "all"],
                categories
              )
            }
            categorySelected={categorySelected}
            canChangeStatus={statusFilter === null}
            onConfirm={this.onConfirm}
            onPublish={this.onPublish}
            confirmable={this.state.statusFilter === ArticleStatus.Draft.value}
            publishable={
              this.state.statusFilter === ArticleStatus.Confirmed.value
            }
            status={this.state.statusFilter}
          />
        </BlockUi>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    categorySelected: state.filter.extraArticleCategories || [],
    articleFilters: state.filter.articleFilters || [],
    extraArticleFilters: state.filter.extraArticleFilters || [],
    articles: state.article.articles || [],
    totalArticles: state.article.totalArticles || 0,
    activeLanguage: state.application.activeLanguage,
    languages: state.application.languages || [DefaultLanguage],
    articleListBlocking: state.uiBlockState.articleListBlocking || false,
    userPermissions: state.auth.userPermissions || [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...bindActionCreators(
      {
        ...ArticleActionCreators,
        ...ApplicationActionCreators,
        ...FilterActionCreators,
      },
      dispatch
    ),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
