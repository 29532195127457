import React, { Component, Fragment } from 'react';
import map from 'lodash.map';
import filter from 'lodash.filter';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import classnames from 'classnames';
import { FieldArray } from 'formik';
import RSC from 'react-scrollbars-custom';
import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Button,
	ListGroup,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Collapse
} from 'reactstrap';

import LinkExternal from '../Modals/LinkDetails';
import LinkCategory from '../Modals/LinkCategory';
import LinkArticle from '../Modals/LinkArticle';
import LinkCardItem from './LinkCardItem';

import { getLinks, getLinksCount } from '../../utils/link-card';
import { LinkType, MenuType, LinkTargetType, ArticleStatus, INFINITY, ArticleType } from '../../helpers/enums';
import find from 'lodash.find';

const {
	Category: { value: categoryValue },
	Article: { value: articleValue },
	External: { value: externalValue }
} = LinkType;

const HIDDEN_FOR_TYPES = [ ArticleType.TransmissionFromRadniInfo.value ];
export default class LinkCard extends Component {
	static defaultProps = {
		links: [],
		disabled: false,
		onSave: (links) => {}
	};

	constructor(props) {
		super(props);
		const { links, maxLinks, collapse } = props;

		this.state = {
			maxLinks: maxLinks || INFINITY,
			cardCollapse: collapse,
			addCategoryModalOpen: false,
			addArticleModalOpen: false,
			linkDetailsModalOpen: false,
			linkActiveTab: categoryValue,
			links: links,
			activeLinks: getLinks(links, categoryValue),
			categoryLinks: getLinks(links, categoryValue),
			articleLinks: getLinks(links, articleValue),
			externalLinks: getLinks(links, externalValue)
		};
	}

	componentDidUpdate(prevProps) {
		const { links } = this.props;
		const { linkActiveTab } = this.state;

		if (!isEqual(prevProps.links, links)) {
			this.setState({
				links: links,
				activeLinks: getLinks(links, linkActiveTab),
				categoryLinks: getLinks(links, categoryValue),
				articleLinks: getLinks(links, articleValue),
				externalLinks: getLinks(links, externalValue)
			});
		}
	}

	toggleCollapse = () => {
		this.setState({ cardCollapse: !this.state.cardCollapse });
	};

	toggleModalOpen = (name, event) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.setState({ [name]: !this.state[name] });
	};

	changeLinkActiveTab = (activeTab) => {
		const { links } = this.props;

		this.setState({
			linkActiveTab: activeTab,
			activeLinks: filter(links, {
				type: activeTab
			})
		});
	};

	handleSaveCategory = (selectedLinks) => {
		const { articleTranslationId, links, maxLinks, onSave } = this.props;

		if (onSave && typeof onSave === 'function') {
			const otherLinks = filter(links, ({ type }) => type !== categoryValue);

			if (maxLinks === INFINITY) {
				onSave(
					map(selectedLinks, (link, linkIndex) => ({
						id: link.id,
						articleTranslationId: articleTranslationId,
						linkToMenuElementId: link.menuElementId,
						linkToMenuElementType: link.menuElementType,
						displayText: link.menuElementDisplayText,
						title: link.menuElementTitle,
						url: link.menuElementUrl,
						slug: link.menuElementSlug,
						target: LinkTargetType.NewTab.value,
						type: categoryValue,
						index: linkIndex
					})).concat(otherLinks)
				);
			} else {
				onSave(
					map(selectedLinks, (link, linkIndex) => ({
						id: link.id,
						articleTranslationId: articleTranslationId,
						linkToMenuElementId: link.menuElementId,
						linkToMenuElementType: link.menuElementType,
						displayText: link.menuElementDisplayText,
						title: link.menuElementTitle,
						url: link.menuElementUrl,
						slug: link.menuElementSlug,
						target: LinkTargetType.NewTab.value,
						type: categoryValue,
						index: linkIndex
					}))
						.concat(otherLinks)
						.slice(0, maxLinks)
				);
			}
		}
	};

	handleSaveArticle = (selectedLinks) => {
		const {articleTranslationId, links, onSave, maxLinks} = this.props;

		const newSelectedLinks = map(selectedLinks, (selectedLink) => {
			const link = find(links, l => l.linkToArticleTranslationId === selectedLink.articleId);

			return link ? {...link, articleId: selectedLink.articleId} : {...selectedLink, target: LinkTargetType.NewTab.value};
		})

		if (onSave && typeof onSave === 'function') {
			const otherLinks = filter(links, ({ type }) => type !== articleValue);

			if (maxLinks === INFINITY) {
				onSave(
					map(newSelectedLinks, (link, linkIndex) => ({
						id: link.id,
						articleTranslationId: articleTranslationId,
						linkToArticleTranslationId: link.articleId,
						displayText: link.title,
						title: link.title,
						url: link.url,
						slug: link.slug,
						target: link.target,
						type: articleValue,
						index: linkIndex
					})).concat(otherLinks)
				);
			} else {
				onSave(
					map(newSelectedLinks, (link, linkIndex) => ({
						id: link.id,
						articleTranslationId: articleTranslationId,
						linkToArticleTranslationId: link.articleId,
						displayText: link.title,
						title: link.title,
						url: link.url,
						slug: link.slug,
						target: link.target,
						type: articleValue,
						index: linkIndex
					}))
						.concat(otherLinks)
						.slice(0, maxLinks)
				);
			}
		}
	};

	handleSaveExternal = (link) => {
		const { links, onSave, maxLinks } = this.props;

		if (onSave && typeof onSave === 'function') {
			if (maxLinks === INFINITY) {
				onSave([ link ].concat(links));
			} else {
				onSave([ link ].concat(links).slice(0, maxLinks));
			}
		}
	};

	render() {
		const {
			cardCollapse = true,
			activeLinks,
			linkActiveTab,
			addCategoryModalOpen,
			addArticleModalOpen,
			linkDetailsModalOpen,
			links,
			categoryLinks,
			articleLinks,
			externalLinks
		} = this.state;
		const {
			articleType,
			type,
			articleTranslationId,
			newTabOpenLinks,
			cardTitle = 'Odnośniki',
			maxLinks = INFINITY,
			disabled = false,
			categoryOptions = { collapse: false, editable: true, removeable: true, showTargetMethod: true },
			articleOptions = { collapse: false, editable: true, removeable: true, showTargetMethod: true },
			externalLinkOptions = { collapse: false, editable: true, removeable: true, showTargetMethod: true },
			onlyReplace = false,
			defaultArticleFilter = [
				{
					columnName: 'status',
					operation: 'lessThanOrEqual',
					value: ArticleStatus.Published.value
				}
			]
		} = this.props;

		const categoryOpts = {
			collapse: false,
			editable: true,
			removeable: true,
			showTargetMethod: true,
			...categoryOptions
		};
		const articleOpts = {
			collapse: false,
			editable: true,
			removeable: true,
			showTargetMethod: true,
			...articleOptions
		};
		const externalLinkOpts = {
			collapse: false,
			editable: true,
			removeable: true,
			showTargetMethod: true,
			...externalLinkOptions
		};

		return !HIDDEN_FOR_TYPES.includes(articleType) ? (
			<Fragment>
				{addCategoryModalOpen && (
					<LinkCategory
						maxLinks={maxLinks}
						isOpen={addCategoryModalOpen}
						categories={map(activeLinks, (link) => ({
							id: link.id,
							menuElementId: link.linkToMenuElementId,
							menuElementType: link.linkToMenuElementType,
							menuElementDisplayText: link.displayText,
							menuElementTitle: link.title,
							menuElementTextPath: link.textPath,
							menuElementUrl: link.url,
							menuElementSlug: link.slug
						}))}
						menuType={MenuType.Horizontal}
						onToggle={this.toggleModalOpen.bind(this, 'addCategoryModalOpen')}
						onSave={this.handleSaveCategory}
					/>
				)}
				{addArticleModalOpen && (
					<LinkArticle
						{...{ type }}
						defaultFilter={defaultArticleFilter}
						maxLinks={maxLinks}
						isOpen={addArticleModalOpen}
						articles={map(articleLinks, (link) => ({
							id: link.id,
							articleId: link.linkToArticleTranslationId,
							title: link.displayText
						}))}
						excludeArticles={[ articleTranslationId ]}
						onToggle={this.toggleModalOpen.bind(this, 'addArticleModalOpen')}
						onSave={this.handleSaveArticle}
					/>
				)}
				{linkDetailsModalOpen && (
					<LinkExternal
						maxLinks={maxLinks}
						link={{
							title: '',
							displayText: '',
							target: LinkTargetType.NewTab.value,
							url: '',
							type: externalValue,
							id: 0,
							articleTranslationId: articleTranslationId
						}}
						showTargetMethod={externalLinkOpts.showTargetMethod}
						isOpen={linkDetailsModalOpen}
						newTabOpenLinks={newTabOpenLinks}
						onToggle={this.toggleModalOpen.bind(this, 'linkDetailsModalOpen')}
						onSave={this.handleSaveExternal}
					/>
				)}
				<Card className="mb-2">
					<CardHeader onClick={this.toggleCollapse} style={{ cursor: 'pointer', marginLeft: '5px' }}>
						<i className="header-icon pe-7s-link icon-gradient bg-malibu-beach" />
						{cardTitle + (links.length > 0 ? ` (${links.length})` : '')}
						<div className="btn-actions-pane-right btn-actions-cursor--pointer">
							{cardCollapse ? 'Zwiń' : 'Rozwiń'}
							{cardCollapse ? <i className="pe-7s-angle-down" /> : <i className="pe-7s-angle-up" />}
						</div>
					</CardHeader>
					<Collapse isOpen={cardCollapse}>
						<CardBody>
							<CardHeader>
								<Nav justified>
									<NavItem>
										<NavLink
											href="javascript:void(0);"
											className={classnames({
												active: linkActiveTab === categoryValue
											})}
											onClick={() => {
												this.changeLinkActiveTab(categoryValue);
											}}
										>
											<div className="d-flex align-items-baseline justify-content-center">
												<CardTitle className="mb-0">
													Kategorie
													{getLinksCount(categoryLinks, linkActiveTab, categoryValue)}
												</CardTitle>
												{linkActiveTab === categoryValue &&
												!disabled && (
													<Button
														type="button"
														className="mr-2 border-0 btn-transition"
														outline
														color="success"
														onClick={this.toggleModalOpen.bind(
															this,
															'addCategoryModalOpen'
														)}
													>
														<i className="pe-7s-plus btn-icon-wrapper" />
													</Button>
												)}
											</div>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											href="javascript:void(0);"
											className={classnames({
												active: linkActiveTab === articleValue
											})}
											onClick={() => {
												this.changeLinkActiveTab(articleValue);
											}}
										>
											<div className="d-flex align-items-baseline justify-content-center">
												<CardTitle className="mb-0">
													Artykuły
													{getLinksCount(articleLinks, linkActiveTab, articleValue)}
												</CardTitle>
												{linkActiveTab === articleValue &&
												!disabled && (
													<Button
														type="button"
														className="mr-2 border-0 btn-transition"
														outline
														color="success"
														onClick={this.toggleModalOpen.bind(this, 'addArticleModalOpen')}
													>
														<i className="pe-7s-plus btn-icon-wrapper" />
													</Button>
												)}
											</div>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											href="javascript:void(0);"
											className={classnames({
												active: linkActiveTab === externalValue
											})}
											onClick={() => {
												this.changeLinkActiveTab(externalValue);
											}}
										>
											<div className="d-flex align-items-baseline justify-content-center">
												<CardTitle className="mb-0">
													Zewnętrzne źródła
													{getLinksCount(externalLinks, linkActiveTab, externalValue)}
												</CardTitle>
												{linkActiveTab === externalValue &&
												!disabled && (
													<Button
														type="button"
														className="mr-2 border-0 btn-transition"
														outline
														color="success"
														onClick={this.toggleModalOpen.bind(
															this,
															'linkDetailsModalOpen'
														)}
													>
														<i className="pe-7s-plus btn-icon-wrapper" />
													</Button>
												)}
											</div>
										</NavLink>
									</NavItem>
								</Nav>
							</CardHeader>
							<TabContent activeTab={linkActiveTab}>
								<TabPane tabId={categoryValue}>
									<div
										className={classnames({
											'scroll-area-md': !isEmpty(activeLinks)
										})}
									>
										<RSC>
											<ListGroup flush>
												<FieldArray name="links">
													{(arrayHelper) =>
														map(activeLinks, (link, linkIndex) => (
															<LinkCardItem
																link={link}
																key={`link-category-item-${linkIndex}`}
																editable={categoryOpts.editable && !disabled}
																removeable={categoryOpts.removeable && !disabled}
																showTargetMethod={categoryOpts.showTargetMethod}
																onChange={(index, item) => {
																	arrayHelper.replace(index, item);
																	if (onlyReplace) {
																		this.handleSaveCategory(item);
																	}
																}}
																onRemove={arrayHelper.remove}
															/>
														))}
												</FieldArray>
											</ListGroup>
										</RSC>
									</div>
								</TabPane>
								<TabPane tabId={articleValue}>
									<div
										className={classnames({
											'scroll-area-md': !isEmpty(activeLinks)
										})}
									>
										<RSC>
											<ListGroup flush>
												<FieldArray name="links">
													{(arrayHelper) =>
														map(activeLinks, (link, linkIndex) => (
															<LinkCardItem
																link={link}
																key={`link-article-item-${linkIndex}`}
																editable={articleOpts.editable && !disabled}
																removeable={articleOpts.removeable && !disabled}
																showTargetMethod={articleOpts.showTargetMethod}
																onChange={(index, item) => {
																	arrayHelper.replace(index, item);
																	if (onlyReplace) {
																		this.handleSaveArticle(item);
																	}
																}}
																onRemove={arrayHelper.remove}
															/>
														))}
												</FieldArray>
											</ListGroup>
										</RSC>
									</div>
								</TabPane>
								<TabPane tabId={externalValue}>
									<div
										className={classnames({
											'scroll-area-md': !isEmpty(activeLinks)
										})}
									>
										<RSC>
											<ListGroup flush>
												<FieldArray name="links">
													{(arrayHelper) =>
														map(activeLinks, (link, linkIndex) => (
															<LinkCardItem
																link={link}
																key={`link-external-item-${linkIndex}`}
																editable={externalLinkOpts.editable && !disabled}
																removeable={externalLinkOpts.removeable && !disabled}
																showTargetMethod={externalLinkOpts.showTargetMethod}
																onChange={(index, item) => {
																	arrayHelper.replace(index, item);
																	if (onlyReplace) {
																		this.handleSaveExternal(item);
																	}
																}}
																onRemove={arrayHelper.remove}
															/>
														))}
												</FieldArray>
											</ListGroup>
										</RSC>
									</div>
								</TabPane>
							</TabContent>
						</CardBody>
					</Collapse>
				</Card>
			</Fragment>
		) : (
			<Fragment />
		);
	}
}
