import { ArticleStatus } from "./enums";

const App = {
  main: "/",
  login: "/login",
  logout: "/logout",
  changePassword: "/zmiana-hasla/:identityUserId",
  changePasswordFunc: (userId) => `/zmiana-hasla/${userId}`,
  forceChangePassword: "/wymuszona-zmiana-hasla",
  noAccess: "/brak-dostepu",
};

const Article = {
  main: "/artykuly",
  common: {
    list: "/artykuly/artykuly",
    create: "/artykuly/artykuly/utworz-nowy",
    createFunc: (lang) => `/artykuly/artykuly/utworz-nowy/${lang ? lang : ""}`,
    edit: "/artykuly/artykuly/edytuj",
    editFunc: (id, lang) =>
      `/artykuly/artykuly/edytuj/${id}/${lang ? lang : ""}`,
    preview: "/artykuly/artykuly/podgląd",
    previewFunc: (id, lang) =>
      `/artykuly/artykuly/podgląd/${id}/${lang ? lang : ""}`,
    draft: {
      list: `/artykuly/artykuly-robocze`,
    },
    confirmed: {
      list: `/artykuly/artykuly-zatwierdzone`,
    },
    topublish: {
      list: `/artykuly/artykuly-do-publikacji`,
    },
    published: {
      list: `/artykuly/artykuly-opublikowane`,
    },
    withdrawed: {
      list: `/artykuly/artykuly-wycofane`,
    },
    archived: {
      list: `/artykuly/artykuly-zarchiwizowane`,
    },
  },
  system: {
    list: "/artykuly/systemowe-artykuly",
    edit: "/artykuly/systemowe-artykuly/edytuj",
    editFunc: (id) => `/artykuly/systemowe-artykuly/edytuj/${id}`,
    instruction: "/artykuly/systemowe-artykuly/instrukcja",
    rss: "/artykuly/systemowe-artykuly/rss",
    personalDataSecurityTerms:
      "/artykuly/systemowe-artykuly/ochrona-danych-osobowych",
    reusableInformationTerms:
      "/artykuly/systemowe-artykuly/ponowne-wykorzystanie",
    sharingInformationTerms:
      "/artykuly/systemowe-artykuly/udostepnienie-informacji-publicznej",
    draft: {
      list: `/artykuly/systemowe-artykuly-robocze`,
    },
    confirmed: {
      list: `/artykuly/systemowe-artykuly-zatwierdzone}`,
    },
    topublish: {
      list: `/artykuly/systemowe-artykuly-do-publikacji`,
    },
    published: {
      list: `/artykuly/systemowe-artykuly-opublikowane`,
    },
    withdrawed: {
      list: `/artykuly/systemowe-artykuly-wycofane`,
    },
    archived: {
      list: `/artykuly/systemowe-artykuly-zarchiwizowane`,
    },
  },
};

const Auth = {
  main: "/dostep",
  role: {
    list: "/dostep/role",
    create: "/dostep/role/utworz-nowa",
    edit: "/dostep/role/edytuj",
    editFunc: (id) => `/dostep/role/edytuj/${id}`,
  },
  user: {
    list: "/dostep/uzytkownicy",
    create: "/dostep/uzytkownicy/utworz-nowego",
    edit: "/dostep/uzytkownicy/edytuj",
    editFunc: (id) => `/dostep/uzytkownicy/edytuj/${id}`,
  },
};

const Dictionary = {
  main: "/slowniki",
  tag: {
    list: "/slowniki/tagi",
    create: "/slowniki/tagi/utworz-nowy",
    edit: "/slowniki/tagi/edytuj",
    editFunc: (id) => `/slowniki/tagi/edytuj/${id}`,
  },
  dictionaryEntry: {
    list: "/slowniki/podmiot-udostepniajacy",
    create: "/slowniki/podmiot-udostepniajacy/utworz-nowy",
    edit: "/slowniki/podmiot-udostepniajacy/edytuj",
    editFunc: (id) => `/slowniki/podmiot-udostepniajacy/edytuj/${id}`,
  },
  isoCode: {
    list: "/slowniki/kody-iso",
    create: "/slowniki/kody-iso/utworz-nowy",
    edit: "/slowniki/kody-iso/edytuj",
    editFunc: (id) => `/slowniki/kody-iso/edytuj/${id}`,
  },
};

const Menu = {
  main: "/zarzadzanie-menu",
  horizontal: "/zarzadzanie-menu/poziome",
  vertical: "/zarzadzanie-menu/pionowe",
};

const Setting = {
  main: "/ustawienia",
  parameter: "/ustawienia/parametry-systemowe",
  language: {
    list: "/ustawienia/wersje-jezykowe",
    create: "/ustawienia/wersje-jezykowe/utworz-nowa",
    edit: "/ustawienia/wersje-jezykowe/edytuj",
    editFunc: (id) => `/ustawienia/wersje-jezykowe/edytuj/${id}`,
  },
  banners: {
    list: "/ustawienia/banery",
    create: "/ustawienia/banery/utworz-nowy",
    edit: "/ustawienia/banery/edytuj",
    editFunc: (id) => `/ustawienia/banery/edytuj/${id}`,
  },
  bookmark: {
    list: "/ustawienia/zakladki",
    create: "/ustawienia/zakladki/utworz-nowa",
    edit: "/ustawienia/zakladki/edytuj",
    editFunc: (id) => `/ustawienia/zakladki/edytuj/${id}`,
  },
  rssChannels: {
    list: "/ustawienia/kanaly-rss",
    create: "/ustawienia/kanaly-rss/utworz-nowy",
    edit: "/ustawienia/kanaly-rss/edytuj",
    editFunc: (id) => `/ustawienia/kanaly-rss/edytuj/${id}`,
  },
  tiles: {
    list: "/ustawienia/kafle",
    create: "/ustawienia/kafle/utworz-nowy",
    edit: "/ustawienia/kafle/edytuj",
    editFunc: (id) => `/ustawienia/kafle/edytuj/${id}`,
  },
  offices: {
    main: "/ustawienia/jednostki",
    office: {
      main: "/ustawienia/jednostki/dane-jednostki",
      edit: "/ustawienia/jednostki/dane-jednostki/edytuj",
      editFunc: (id) => `/ustawienia/jednostki/dane-jednostki/edytuj/${id}`,
    },
    officeManagers: {
      list: "/ustawienia/jednostki/kierownictwo-w-jednostce",
      create: "/ustawienia/jednostki/kierownictwo-w-jednostce/utworz-nowy",
      edit: "/ustawienia/jednostki/kierownictwo-w-jednostce/edytuj",
      editFunc: (id) =>
        `/ustawienia/jednostki/kierownictwo-w-jednostce/edytuj/${id}`,
    },
  },
  // articles: {
  // 	list: '/ustawienia/artykuly-systemowe',
  // 	edit: '/ustawienia/artykuly-systemowe/edytuj',
  // 	editFunc: (id) => `/ustawienia/artykuly-systemowe/edytuj/${id}`,
  // 	instruction: '/ustawienia/artykuly-systemowe/instrukcja',
  // 	rss: '/ustawienia/artykuly-systemowe/rss',
  // 	personalDataSecurityTerms: '/ustawienia/artykuly-systemowe/ochrona-danych-osobowych',
  // 	reusableInformationTerms: '/ustawienia/artykuly-systemowe/ponowne-wykorzystanie',
  // 	sharingInformationTerms: '/ustawienia/artykuly-systemowe/udostepnienie-informacji-publicznej'
  // },
  header: {
    list: "/ustawienia/naglowek-systemu",
    editFunc: (isoCode) => `/ustawienia/naglowek-systemu/${isoCode}`,
  },
  footer: {
    list: "/ustawienia/stopka-systemu",
    editFunc: (isoCode) => `/ustawienia/stopka-systemu/${isoCode}`,
  },
  appImages: "/ustawienia/grafiki",
  contact: "/ustawienia/dane-kontaktowe",
  repository: {
    list: "/ustawienia/grupy-plikow",
    create: "/ustawienia/grupy-plikow/utworz-nowy",
    edit: "/ustawienia/grupy-plikow/edytuj",
    editFunc: (id) => `/ustawienia/grupy-plikow/edytuj/${id}`,
  },
};
const ArchiveBIP = {
  main: "/bip-archiwalny",
  manage: "/bip-archiwalny/zarzadzanie",
};

export const RouteUrls = {
  App,
  Article,
  Auth,
  Dictionary,
  Menu,
  Setting,
  ArchiveBIP,
};
