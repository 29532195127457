import axios from "axios";
import { toast } from "react-toastify";

export const useDownloadData = ({ baseUrl, fileName }) => {
  const download = (params) => {
    return axios
      .get(baseUrl, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error("Wystąpił błąd podczas pobierania danych.");
      });
  };

  return [download];
};
