import { ArticleActions } from "../actions/article";
import { DictionaryActions } from "../actions/dictionary";
import { MenuActions } from "../actions/menu";
import { LanguageActions } from "../actions/settings/language";
import { UIBlockingState } from "../stores";
import Immutable from "seamless-immutable";
import { SearchActions } from "../actions/search";
import { SystemParametersActions } from "../actions/settings/system-parameters";
import { SettingActions } from "../actions/settings";
import { UserActions } from "../actions/auth/user";
import { RoleActions } from "../actions/auth/role";
import { ArchiveActions } from "../actions/archive";
import { OfficeActions } from "../actions/office";

const initialState = Immutable(UIBlockingState);

export function uiBlockState(state = initialState, action) {
  switch (action.type) {
    // MENUS
    case MenuActions.GetMenu.Request:
    case MenuActions.UpdateMenu.Request:
    case SearchActions.SearchCategories.Request:
    case SearchActions.SearchCategoriesByTitle.Request: {
      return state.merge({
        menuCardBlocking: true,
        footerLinkListBlocking: true,
      });
    }
    case MenuActions.GetMenu.Success:
    case MenuActions.GetMenu.Failure:
    case MenuActions.UpdateMenu.Success:
    case MenuActions.UpdateMenu.Failure:
    case SearchActions.SearchCategories.Success:
    case SearchActions.SearchCategories.Failure:
    case SearchActions.SearchCategoriesByTitle.Success:
    case SearchActions.SearchCategoriesByTitle.Failure: {
      return state.merge({
        menuCardBlocking: false,
        footerLinkListBlocking: false,
      });
    }
    // LANGUAGES
    case LanguageActions.GetLanguageAction.Request:
    case LanguageActions.CreateLanguage.Request:
    case LanguageActions.LoadStandardElementsAction.Request:
    case LanguageActions.UpdateLanguageAction.Request:
    case LanguageActions.UpdateLanguageStatusAction.Request:
    case LanguageActions.UpdateStandardElementTranslationAction.Request:
    case LanguageActions.LoadMenuElementsAction.Request: {
      return state.setIn(["languageCardBlocking"], true);
    }
    case LanguageActions.GetLanguageAction.Success:
    case LanguageActions.GetLanguageAction.Failure:
    case LanguageActions.CreateLanguage.Success:
    case LanguageActions.CreateLanguage.Failure:
    case LanguageActions.LoadStandardElementsAction.Success:
    case LanguageActions.LoadStandardElementsAction.Failure:
    case LanguageActions.UpdateLanguageAction.Success:
    case LanguageActions.UpdateLanguageAction.Failure:
    case LanguageActions.UpdateLanguageStatusAction.Success:
    case LanguageActions.UpdateLanguageStatusAction.Failure:
    case LanguageActions.UpdateStandardElementTranslationAction.Success:
    case LanguageActions.UpdateStandardElementTranslationAction.Failure:
    case LanguageActions.LoadMenuElementsAction.Success:
    case LanguageActions.LoadMenuElementsAction.Failure: {
      return state.setIn(["languageCardBlocking"], false);
    }
    case LanguageActions.LoadLanguagesAction.Request:
    case LanguageActions.RemoveLanguageAction.Request: {
      return state.setIn(["languageListBlocking"], true);
    }
    case LanguageActions.LoadLanguagesAction.Success:
    case LanguageActions.LoadLanguagesAction.Failure:
    case LanguageActions.RemoveLanguageAction.Success:
    case LanguageActions.RemoveLanguageAction.Failure: {
      return state.setIn(["languageListBlocking"], false);
    }
    // ARTICLES
    case ArticleActions.GetAllArticles.Request:
    case ArticleActions.DeleteArticles.Request: {
      return state.merge({
        articleListBlocking: true,
        footerLinkListBlocking: true,
      });
    }
    case ArticleActions.ConfirmArticles.Request: {
      return state.merge({
        articleListBlocking: true,
        footerLinkListBlocking: true,
      });
    }
    case ArticleActions.ConfirmArticles.Success:
    case ArticleActions.ConfirmArticles.Failure: {
      return state.merge({
        articleListBlocking: false,
        footerLinkListBlocking: false,
      });
    }
    case ArticleActions.PublishArticles.Request: {
      return state.merge({
        articleListBlocking: true,
        footerLinkListBlocking: true,
      });
    }
    case ArticleActions.PublishArticles.Success:
    case ArticleActions.PublishArticles.Failure: {
      return state.merge({
        articleListBlocking: false,
        footerLinkListBlocking: false,
      });
    }
    case ArticleActions.GetAllArticles.Success:
    case ArticleActions.GetAllArticles.Failure:
    case ArticleActions.DeleteArticles.Success:
    case ArticleActions.DeleteArticles.Failure: {
      return state.merge({
        articleListBlocking: false,
        footerLinkListBlocking: false,
      });
    }

    case ArticleActions.GetArticle.Request:
    case ArticleActions.SaveArticle.Request:
    case ArticleActions.UpdateArticle.Request:
    case ArticleActions.ChangeArticleTranslationStatus.Request:
    case ArticleActions.GetArticleHash.Request:
    case ArticleActions.CreateArticleNewVersion.Request: {
      return state.setIn(["articleCardBlocking"], true);
    }
    case ArticleActions.GetArticle.Success:
    case ArticleActions.GetArticle.Failure:
    case ArticleActions.SaveArticle.Success:
    case ArticleActions.SaveArticle.Failure:
    case ArticleActions.UpdateArticle.Success:
    case ArticleActions.UpdateArticle.Failure:
    case ArticleActions.ChangeArticleTranslationStatus.Success:
    case ArticleActions.ChangeArticleTranslationStatus.Failure:
    case ArticleActions.CreateArticleNewVersion.Success:
    case ArticleActions.GetArticleHash.Success:
    case ArticleActions.CreateArticleNewVersion.Failure:
    case ArticleActions.GetArticleHash.Failure: {
      return state.setIn(["articleCardBlocking"], false);
    }

    case DictionaryActions.GetAllTagsAction.Request:
    case DictionaryActions.RemoveTagsAction.Request: {
      return state.setIn(["tagListBlocking"], true);
    }
    case DictionaryActions.GetAllTagsAction.Success:
    case DictionaryActions.GetAllTagsAction.Failure:
    case DictionaryActions.RemoveTagsAction.Success:
    case DictionaryActions.RemoveTagsAction.Failure: {
      return state.setIn(["tagListBlocking"], false);
    }
    case DictionaryActions.CreateTagAction.Request:
    case DictionaryActions.UpdateTagAction.Request: {
      return state.setIn(["tagCardBlocking"], true);
    }
    case DictionaryActions.CreateTagAction.Success:
    case DictionaryActions.CreateTagAction.Failure:
    case DictionaryActions.UpdateTagAction.Success:
    case DictionaryActions.UpdateTagAction.Failure: {
      return state.setIn(["tagCardBlocking"], false);
    }
    case SystemParametersActions.GetAllSystemParameters.Request: {
      return state.setIn(["systemParameterListBlocking"], true);
    }
    case SystemParametersActions.GetAllSystemParameters.Success:
    case SystemParametersActions.GetAllSystemParameters.Failure: {
      return state.setIn(["systemParameterListBlocking"], false);
    }
    case SystemParametersActions.GetSystemParameter.Request: {
      return state.setIn(["systemParameterCardBlocking"], true);
    }
    case SystemParametersActions.GetSystemParameter.Success:
    case SystemParametersActions.GetSystemParameter.Failure: {
      return state.setIn(["systemParameterCardBlocking"], false);
    }
    case SettingActions.LoadBookmarks.Request:
    case SettingActions.DeleteBookmarks.Request: {
      return state.setIn(["bookmarkListBlocking"], true);
    }
    case SettingActions.LoadBookmarks.Success:
    case SettingActions.LoadBookmarks.Failure:
    case SettingActions.DeleteBookmarks.Success:
    case SettingActions.DeleteBookmarks.Failure: {
      return state.setIn(["bookmarkListBlocking"], false);
    }
    case SettingActions.GetBookmark.Request:
    case SettingActions.UpdateBookmark.Request:
    case SettingActions.CreateBookmark.Request: {
      return state.setIn(["bookmarkCardBlocking"], true);
    }
    case SettingActions.GetBookmark.Success:
    case SettingActions.GetBookmark.Failure:
    case SettingActions.UpdateBookmark.Success:
    case SettingActions.UpdateBookmark.Failure:
    case SettingActions.CreateBookmark.Success:
    case SettingActions.CreateBookmark.Failure: {
      return state.setIn(["bookmarkCardBlocking"], false);
    }
    case SettingActions.LoadRssChannels.Request:
    case SettingActions.DeleteRssChannels.Request: {
      return state.setIn(["rssChannelListBlocking"], true);
    }
    case SettingActions.LoadRssChannels.Success:
    case SettingActions.LoadRssChannels.Failure:
    case SettingActions.DeleteRssChannels.Success:
    case SettingActions.DeleteRssChannels.Failure: {
      return state.setIn(["rssChannelListBlocking"], false);
    }
    case SettingActions.GetRssChannel.Request:
    case SettingActions.UpdateRssChannel.Request:
    case SettingActions.CreateRssChannel.Request: {
      return state.setIn(["rssChannelCardBlocking"], true);
    }
    case SettingActions.GetRssChannel.Success:
    case SettingActions.GetRssChannel.Failure:
    case SettingActions.UpdateRssChannel.Success:
    case SettingActions.UpdateRssChannel.Failure:
    case SettingActions.CreateRssChannel.Success:
    case SettingActions.CreateRssChannel.Failure: {
      return state.setIn(["rssChannelCardBlocking"], false);
    }
    case SettingActions.LoadTiles.Request: {
      return state.setIn(["tileListBlocking"], true);
    }
    case SettingActions.LoadTiles.Success:
    case SettingActions.LoadTiles.Failure: {
      return state.setIn(["tileListBlocking"], false);
    }
    case SettingActions.GetTile.Request:
    case SettingActions.UpdateTile.Request: {
      return state.setIn(["tileCardBlocking"], true);
    }
    case SettingActions.GetTile.Success:
    case SettingActions.GetTile.Failure:
    case SettingActions.UpdateTile.Success:
    case SettingActions.UpdateTile.Failure: {
      return state.setIn(["tileCardBlocking"], false);
    }
    case SettingActions.GetFooter.Request:
    case SettingActions.UpdateFooter.Request: {
      return state.setIn(["footerCardBlocking"], true);
    }
    case SettingActions.GetFooter.Success:
    case SettingActions.GetFooter.Failure:
    case SettingActions.UpdateFooter.Success:
    case SettingActions.UpdateFooter.Failure: {
      return state.setIn(["footerCardBlocking"], false);
    }

    case SettingActions.GetHeader.Request:
    case SettingActions.UpdateHeader.Request: {
      return state.setIn(["headerCardBlocking"], true);
    }
    case SettingActions.GetHeader.Success:
    case SettingActions.GetHeader.Failure:
    case SettingActions.UpdateHeader.Success:
    case SettingActions.UpdateHeader.Failure: {
      return state.setIn(["headerCardBlocking"], false);
    }
    case SettingActions.LoadBanners.Request:
    case SettingActions.DeleteBanners.Request: {
      return state.setIn(["bannerListBlocking"], true);
    }
    case SettingActions.LoadBanners.Success:
    case SettingActions.LoadBanners.Failure:
    case SettingActions.DeleteBanners.Success:
    case SettingActions.DeleteBanners.Failure: {
      return state.setIn(["bannerListBlocking"], false);
    }
    case SettingActions.GetBanner.Request:
    case SettingActions.UpdateBanner.Request:
    case SettingActions.CreateBanner.Request: {
      return state.setIn(["bannerCardBlocking"], true);
    }
    case SettingActions.GetBanner.Success:
    case SettingActions.GetBanner.Failure:
    case SettingActions.UpdateBanner.Success:
    case SettingActions.UpdateBanner.Failure:
    case SettingActions.CreateBanner.Success:
    case SettingActions.CreateBanner.Failure: {
      return state.setIn(["bannerCardBlocking"], false);
    }
    case SettingActions.GetAppImages.Request:
    case SettingActions.UpdateCroppedImage.Request:
    case SettingActions.UpdateAppImages.Request: {
      return state.setIn(["appImagesCardBlocking"], true);
    }
    case SettingActions.GetAppImages.Success:
    case SettingActions.GetAppImages.Failure:
    case SettingActions.UpdateCroppedImage.Success:
    case SettingActions.UpdateCroppedImage.Failure:
    case SettingActions.UpdateAppImages.Success:
    case SettingActions.UpdateAppImages.Failure: {
      return state.setIn(["appImagesCardBlocking"], false);
    }
    case UserActions.LoadUsers.Request:
    case UserActions.DeleteUsers.Request: {
      return state.setIn(["userListBlocking"], true);
    }
    case UserActions.LoadUsers.Success:
    case UserActions.LoadUsers.Failure:
    case UserActions.DeleteUsers.Success:
    case UserActions.DeleteUsers.Failure: {
      return state.setIn(["userListBlocking"], false);
    }
    case UserActions.GetUser.Request:
    case UserActions.UpdateUser.Request:
    case UserActions.CreateUser.Request:
    case UserActions.ActiveUser.Request:
    case UserActions.DeactiveUser.Request:
    case UserActions.ResetPassword.Request: {
      return state.setIn(["userCardBlocking"], true);
    }
    case UserActions.GetUser.Success:
    case UserActions.GetUser.Failure:
    case UserActions.UpdateUser.Success:
    case UserActions.UpdateUser.Failure:
    case UserActions.CreateUser.Success:
    case UserActions.CreateUser.Failure:
    case UserActions.ActiveUser.Success:
    case UserActions.ActiveUser.Failure:
    case UserActions.DeactiveUser.Success:
    case UserActions.DeactiveUser.Failure:
    case UserActions.ResetPassword.Success:
    case UserActions.ResetPassword.Failure: {
      return state.setIn(["userCardBlocking"], false);
    }
    case UserActions.ChangePassword.Request: {
      return state.setIn(["changePasswordBlocking"], true);
    }
    case UserActions.ChangePassword.Success:
    case UserActions.ChangePassword.Failure: {
      return state.setIn(["changePasswordBlocking"], false);
    }
    case RoleActions.LoadRoles.Request:
    case RoleActions.DeleteRoles.Request: {
      return state.setIn(["roleListBlocking"], true);
    }
    case RoleActions.LoadRoles.Success:
    case RoleActions.LoadRoles.Failure:
    case RoleActions.DeleteRoles.Success:
    case RoleActions.DeleteRoles.Failure: {
      return state.setIn(["roleListBlocking"], false);
    }
    case RoleActions.GetRole.Request:
    case RoleActions.UpdateRole.Request:
    case RoleActions.CreateRole.Request: {
      return state.setIn(["roleCardBlocking"], true);
    }
    case RoleActions.GetRole.Success:
    case RoleActions.GetRole.Failure:
    case RoleActions.UpdateRole.Success:
    case RoleActions.UpdateRole.Failure:
    case RoleActions.CreateRole.Success:
    case RoleActions.CreateRole.Failure: {
      return state.setIn(["roleCardBlocking"], false);
    }
    case SettingActions.GetOfficeContacts.Request:
    case SettingActions.UpdateOfficeContacts.Request: {
      return state.setIn(["officeContactBlocking"], true);
    }
    case SettingActions.GetOfficeContacts.Success:
    case SettingActions.GetOfficeContacts.Failure:
    case SettingActions.UpdateOfficeContacts.Success:
    case SettingActions.UpdateOfficeContacts.Failure: {
      return state.setIn(["officeContactBlocking"], false);
    }
    case ArchiveActions.GetArchiveBip.Request:
    case ArchiveActions.GetArchiveBipByClientId.Request:
    case ArchiveActions.RemoveArchiveSite.Request: {
      return state.setIn(["archiveBipBlocking"], true);
    }
    case ArchiveActions.GetArchiveBip.Success:
    case ArchiveActions.GetArchiveBip.Failure:
    case ArchiveActions.GetArchiveBipByClientId.Success:
    case ArchiveActions.GetArchiveBipByClientId.Failure:
    case ArchiveActions.RemoveArchiveSite.Success:
    case ArchiveActions.RemoveArchiveSite.Failure: {
      return state.setIn(["archiveBipBlocking"], false);
    }
    case OfficeActions.GetOffice.Request:
    case OfficeActions.UpdateOffice.Request: {
      return state.setIn(["officeCardBlocking"], true);
    }
    case OfficeActions.GetOffice.Success:
    case OfficeActions.GetOffice.Failure:
    case OfficeActions.UpdateOffice.Success:
    case OfficeActions.UpdateOffice.Failure: {
      return state.setIn(["officeCardBlocking"], false);
    }
    case SettingActions.LoadRepositoryConfigs.Request:
    case SettingActions.RemoveRepositoryConfigs.Request: {
      return state.setIn(["repositoryListBlocking"], true);
    }
    case SettingActions.LoadRepositoryConfigs.Success:
    case SettingActions.LoadRepositoryConfigs.Failure:
    case SettingActions.RemoveRepositoryConfigs.Success:
    case SettingActions.RemoveRepositoryConfigs.Failure: {
      return state.setIn(["repositoryListBlocking"], false);
    }
    case SettingActions.GetRepositoryConfig.Request:
    case SettingActions.UpdateRepositoryConfig.Request:
    case SettingActions.CreateRepositoryConfig.Request: {
      return state.setIn(["repositoryCardBlocking"], true);
    }
    case SettingActions.GetRepositoryConfig.Success:
    case SettingActions.GetRepositoryConfig.Failure:
    case SettingActions.UpdateRepositoryConfig.Success:
    case SettingActions.UpdateRepositoryConfig.Failure:
    case SettingActions.CreateRepositoryConfig.Success:
    case SettingActions.CreateRepositoryConfig.Failure: {
      return state.setIn(["repositoryCardBlocking"], false);
    }
    default: {
      return state;
    }
  }
}
