import { createAction } from '../base';
import { DefaultLanguage } from '../../helpers/enums';

const GetOfficeContacts = createAction('GetOfficeContacts');
const UpdateOfficeContacts = createAction('UpdateOfficeContacts');

function getOfficeContacts(lang = DefaultLanguage.isoCode) {
	return {
		type: GetOfficeContacts.Request,
		payload: lang
	};
}

function updateOfficeContacts(lang = DefaultLanguage.isoCode, officeContacts = []) {
	return {
		type: UpdateOfficeContacts.Request,
		payload: { lang, officeContacts }
	};
}

export const OfficeContactsActions = {
	GetOfficeContacts,
	UpdateOfficeContacts
};

export const OfficeContactsActionCreators = {
	getOfficeContacts,
	updateOfficeContacts
};