import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';

import { RoleActions } from '../../actions/auth/role';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import {createErrorToast} from '../../helpers/error-message';

const ROLE_URL = '/api/auth/roles';

function* loadRoles(action) {
	try {
		let roles = yield call(getListRequest, {
			url: ROLE_URL,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: RoleActions.LoadRoles.Success,
			payload: roles
		});
	} catch (err) {
		yield put({
			type: RoleActions.LoadRoles.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy ról');
	}
}

function* getRole(action) {
	try {
		let role = yield call(getRoleRequest, action.payload);

		yield put({
			type: RoleActions.GetRole.Success,
			payload: role
		});
	} catch (err) {
		yield put({
			type: RoleActions.GetRole.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych roli');
	}
}
function getRoleRequest(id) {
	return axios.get(`${ROLE_URL}/${id}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateRole(action) {
	try {
		let role = yield call(updateRoleRequest, action.payload);

		yield put({
			type: RoleActions.UpdateRole.Success,
			payload: role
		});
		toast.success('Rola została zaktualizowana');
	} catch (err) {
		yield put({
			type: RoleActions.UpdateRole.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji danych roli');
	}
}
function updateRoleRequest(role) {
	return axios.put(`${ROLE_URL}/${role.id}`, role).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* createRole(action) {
	try {
		let role = yield call(createRoleRequest, action.payload);

		yield put({
			type: RoleActions.CreateRole.Success,
			payload: role
		});
		yield put(replace(RouteUrls.Auth.role.editFunc(role.id)));
		toast.success('Rola została utworzona');
	} catch (err) {
		yield put({
			type: RoleActions.CreateRole.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas tworzenia roli');
	}
}
function createRoleRequest(role) {
	return axios.post(ROLE_URL, role).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* deleteRoles(action) {
	try {
		yield call(deleteRolesRequest, action.payload);

		yield put({
			type: RoleActions.DeleteRoles.Success,
			payload: action.payload
		});
		toast.success('Role zostały usunięte');
	} catch (err) {
		yield put({
			type: RoleActions.DeleteRoles.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie usuwania roli');
	}
}

function deleteRolesRequest(roleIds) {
	const filterQuery = qs.stringify({ roleIds: roleIds }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${ROLE_URL}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchLoadRoles() {
	yield takeEvery(RoleActions.LoadRoles.Request, loadRoles);
}
function* watchGetRole() {
	yield takeEvery(RoleActions.GetRole.Request, getRole);
}
function* watchCreateRole() {
	yield takeEvery(RoleActions.CreateRole.Request, createRole);
}
function* watchUpdateRole() {
	yield takeEvery(RoleActions.UpdateRole.Request, updateRole);
}
function* watchDeleteRoles() {
	yield takeEvery(RoleActions.DeleteRoles.Request, deleteRoles);
}

export const RoleSagas = [
	fork(watchLoadRoles),
	fork(watchGetRole),
	fork(watchUpdateRole),
	fork(watchCreateRole),
	fork(watchDeleteRoles)
];
