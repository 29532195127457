import React, { Fragment } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from '../AppLogo';
import UserBox from './Components/UserBox';
import HeaderDots from './Components/HeaderDots';
import { getActiveLanguagesSelector } from '../../reselect/language';
import { ApplicationActionCreators } from '../../actions/application';
import { DefaultLanguage } from '../../helpers/enums';
import { HelpPage } from './Components/HelpPage';

class Header extends React.Component {
	render() {
		let {
			headerBackgroundColor,
			enableMobileMenuSmall,
			enableHeaderShadow,
			activeLanguage,
			languages,
			changeApplicationLanguage,
			helpPageData,
			isDemoVersion
		} = this.props;
		return (
			<Fragment>
				<ReactCSSTransitionGroup
					component="div"
					className={cx(
						'app-header',
						headerBackgroundColor,
						{ 'header-shadow': enableHeaderShadow },
						{ 'mt-5': isDemoVersion }
					)}
					transitionName="HeaderAnimation"
					transitionAppear={true}
					transitionAppearTimeout={1500}
					transitionEnter={false}
					transitionLeave={false}
				>
					<HeaderLogo />

					<div className={cx('app-header__content', { 'header-mobile-open': enableMobileMenuSmall })}>
						<div className="app-header-left">
						</div>
						<div className="app-header-right">
							<HelpPage {...helpPageData} />
							<HeaderDots
								activeLanguage={activeLanguage}
								languages={languages}
								changeApplicationLanguage={changeApplicationLanguage}
							/>
							<UserBox />
						</div>
					</div>
				</ReactCSSTransitionGroup>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	isDemoVersion: state.application.isDemoVersion || false,
	enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
	closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
	headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
	activeLanguage: state.application.activeLanguage || DefaultLanguage,
	languages: getActiveLanguagesSelector(state) || [ DefaultLanguage ],
	helpPageData: state.application.helpPageData || { url: '', login: '', password: '', redirectTo: '' }
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...ApplicationActionCreators }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
