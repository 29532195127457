const url =
	window && window.location ? window.location : { protocol: 'https:', hostname: 'bip.identity.pl', origin: '' };

export const OidcConfiguration = {
	// authority: `${url.protocol}//${url.hostname}:5104`,
	// client_id: 'BIP - Backoffice',
	redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port
		? `:${window.location.port}`
		: ''}/authentication/callback`,
	response_type: 'code',
	scope: 'openid profile offline_access',
	post_logout_redirect_uri: url.origin,
	silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port
		? `:${window.location.port}`
		: ''}/authentication/silent_callback`,
	automaticSilentRenew: true,
	loadUserInfo: true,
	revokeAccessTokenOnSignout: true
};
