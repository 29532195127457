import React, { Component } from 'react';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import { FormGroup, Label, Card, CardHeader, CardBody, Col, Input } from 'reactstrap';
import isEqual from 'lodash.isequal';
import cx from 'classnames';

const Handle = Slider.Handle;

const handle = (props) => {
	const { value, dragging, index, ...restProps } = props;
	return (
		<Tooltip prefixCls="rc-slider-tooltip" overlay={value} visible={dragging} placement="top" key={index}>
			<Handle value={value} {...restProps} />
		</Tooltip>
	);
};

export class SessionTimeout extends Component {
	state = {
		value: this.props.value || 0,
		maxValue: this.props.maxValue || 0,
		minValue: this.props.minValue || 0,
		enabled: this.props.enabled || false
	};

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.value, this.props.value)) {
			this.setState({ value: this.props.value });
		}
		if (!isEqual(prevProps.maxValue, this.props.maxValue)) {
			this.setState({ maxValue: this.props.maxValue });
		}
		if (!isEqual(prevProps.minValue, this.props.minValue)) {
			this.setState({ minValue: this.props.minValue });
		}
		if (!isEqual(prevProps.enabled, this.props.enabled)) {
			this.setState({ enabled: this.props.enabled });
		}
	}

	handleValueChange = (value) => {
		const { onChange } = this.props;
		const { minValue, maxValue } = this.state;
		let currentValue = value ? parseInt(value) : 0;
		if (currentValue < minValue) {
			currentValue = minValue;
		}
		if (currentValue > maxValue) {
			currentValue = maxValue;
		}

		this.setState({ value: currentValue }, () => {
			if (onChange && typeof onChange === 'function') {
				onChange('value.value', this.state.value);
			}
		});
	};

	handleEnabledChange = () => {
		const { onChange } = this.props;

		this.setState({ enabled: !this.state.enabled }, () => {
			if (onChange && typeof onChange === 'function') {
				onChange('value.enabled', this.state.enabled);
			}
		});
    };

	render() {
		const { title = '', label = '' } = this.props;
		const { maxValue = 0, minValue = 0, value = 0, enabled = false } = this.state;

		return (
			<Card className="mb-2">
				<CardHeader>
					<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
					{title}
				</CardHeader>
				<CardBody>
					<FormGroup className="d-flex align-items-center">
						<Label className="mr-2">Włącz sesję użytkownika</Label>
						<div
							className="switch has-switch mb-2 mr-2"
							data-on-label="Tak"
							data-off-label="Nie"
							onClick={this.handleEnabledChange}
						>
							<div
								className={cx('switch-animate', {
									'switch-on': enabled,
									'switch-off': !enabled
								})}
							>
								<input type="checkbox" />
								<span className="switch-left">Tak</span>
								<label>&nbsp;</label>
								<span className="switch-right">Nie</span>
							</div>
						</div>
					</FormGroup>
					<FormGroup row>
						<Label for="actualValue" md={4}>
							Aktualna wartość czasu bezczynności użytkownika w minutach
						</Label>
						<Col md={3}>
							<Input
								type="number"
								step={1}
								id="actualValue"
								name="actualValue"
								value={value}
								min={minValue}
                                max={maxValue}
                                disabled={!enabled}
								onChange={(e) => this.handleValueChange(e.target.value)}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label for="paramValue" md={4}>
							{label}
						</Label>
						<Col md={8}>
							<Slider
								id="paramValue"
								name="paramValue"
								min={minValue}
								max={maxValue}
								defaultValue={value}
								value={value}
                                disabled={!enabled}
								className="rc-slider-primary rc-slider-square mb-2"
								onChange={this.handleValueChange}
								handle={handle}
							/>
						</Col>
					</FormGroup>
				</CardBody>
			</Card>
		);
	}
}
