import React, { Fragment } from "react";
import { Formik } from "formik";
import Flag from "react-flagkit";

import { InputText } from "../../../components/Inputs/Text";
import { Toggle } from "../../../components/Inputs/Toggle";

import {
  Form,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import {
  DefaultLanguage,
  DefaultFormikConfig,
  MenuType,
  LinkTargetType,
} from "../../../helpers/enums";

import { CONSTANTS } from "@constants";

import { CheckboxInput } from "../../../components/Inputs/Checkbox";
import { Languages } from "../Languages";

import { MenuElementValidationSchema } from "../../../validators";

const {
  ADD_CATEGORY,
  ADD_SUBCATEGORY,
  EDIT_CATEGORY,
  CONFIRM,
  CANCEL,
  COMMON_INFORMATION,
  NAME,
  TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS,
  OPEN_DESCRIPTION_IN_NEW_WINDOW,
  DESCRIPTION,
  URL,
} = CONSTANTS;

const MenuElementDetails = ({
  isOpen = false,
  category = null,
  languages = [],
  onToggle,
  updateMenu,
  otherLanguages,
  updateOtherLanguages,
  type,
  currentLang,
}) => {
  const isoCodeFile = currentLang
    ? currentLang.isoCodeFile
    : DefaultLanguage.isoCodeFile;

  const handleCancel = (event) => {
    onToggle(event);
  };

  const handleSave = (values) => {
    const type = values?.id ? "edit" : "add";

    if (type === "add") {
      addRecord(values);
    } else {
      editRecord(values);
    }
  };

  const addRecord = (values) => {
    const currentLanguages = [
      {
        languageId: 1,
        languageIsoCode: "PL",
        languageName: "polski",
        translatedDisplayText: values?.displayText || "",
        translatedTitle: values.title || "",
        translatedUrl: values.url || "",
        translatedTarget: 10,
      },
      ...otherLanguages.map(
        ({
          language,
          languageIsoCode,
          languageName,
          translatedDisplayText = "",
          translatedTitle = "",
          translatedUrl = "",
        }) => ({
          languageId: language.id,
          languageIsoCode,
          languageName,
          translatedDisplayText,
          translatedTarget: 10,
          translatedTitle,
          translatedUrl,
        })
      ),
    ];

    updateMenu(type, {
      type: "add",
      element: {
        ...values,

        languages: currentLanguages,
      },
      isoCode: currentLang.isoCode,
    });
    onToggle();
  };

  const editRecord = (values) => {
    const currentRecordLanguages = otherLanguages.map((el) => {
      if (el.languageIsoCode === currentLang.isoCode) {
        return {
          id: typeof el?.id === "number" ? el.id : undefined,
          languageId: el.languageId,
          languageIsoCode: el.languageIsoCode,
          languageName: el.languageName,
          translatedDisplayText: values?.displayText || "",
          translatedTitle: values.title || "",
          translatedUrl: values.url || "",
          translatedTarget: 10,
        };
      }

      return {
        id: typeof el?.id === "number" ? el.id : undefined,
        languageId: el.languageId,
        languageIsoCode: el.languageIsoCode,
        languageName: el.languageName,
        translatedDisplayText: el?.translatedDisplayText,
        translatedTitle: el.translatedTitle || "",
        translatedUrl: el.translatedUrl || "",
        translatedTarget: 10,
      };
    });

    const currentLanguages = otherLanguages.every(
      (el) => el.languageIsoCode !== currentLang.isoCode
    )
      ? [
          ...currentRecordLanguages,
          {
            languageId: currentLang.id,
            languageIsoCode: currentLang.isoCode,
            languageName: currentLang.name,
            translatedDisplayText: values.displayText || "",
            translatedTitle: values.title || "",
            translatedUrl: values.url || "",
            translatedTarget: 10,
          },
        ]
      : currentRecordLanguages;

    updateMenu(
      type,
      {
        type: "edit",
        element: { ...values, languages: currentLanguages },
      },
      currentLang.isoCode
    );
    onToggle();
  };

  return (
    <Formik
      {...DefaultFormikConfig}
      initialValues={category}
      onSubmit={handleSave}
      validationSchema={MenuElementValidationSchema(otherLanguages)}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleReset,
        handleSubmit,
        handleBlur,
        setFieldValue,
      }) => {
        return (
          <Modal isOpen={isOpen} backdrop size="xl">
            <ModalHeader>
              <div className="d-flex justify-content-between">
                <span>
                  {category?.id
                    ? EDIT_CATEGORY
                    : category?.parentElementId
                    ? ADD_SUBCATEGORY
                    : ADD_CATEGORY}
                </span>
                <div className="d-flex">
                  <Button
                    className="btn-icon mr-2"
                    color="alternate"
                    type="submit"
                    form="form-link"
                  >
                    <i className="pe-7s-check btn-icon-wrapper" />
                    {CONFIRM}
                  </Button>
                  <Button
                    className="btn-icon mr-2"
                    color="secondary"
                    type="button"
                    onClick={handleCancel}
                  >
                    <i className="pe-7s-back btn-icon-wrapper" />
                    {CANCEL}
                  </Button>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="d-flex flex-column">
                <Form
                  id="form-link"
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                  className="d-flex menu"
                >
                  <Card className="mb-2 mr-1 menuElement__card">
                    <CardHeader>
                      <div className={`mr-2 opacity-8`}>
                        {isoCodeFile ? (
                          <img
                            src={isoCodeFile.path}
                            alt={`Grafika ${isoCodeFile.fileName}`}
                            height={16}
                            width={24}
                          />
                        ) : (
                          <Flag
                            country={
                              currentLang.isoCode || DefaultLanguage.isoCode
                            }
                          />
                        )}
                      </div>
                      {COMMON_INFORMATION}
                    </CardHeader>
                    <CardBody>
                      <InputText
                        {...{
                          type: "text",
                          label: NAME,
                          name: "displayText",
                          value: values?.displayText,
                          error: errors.displayText,
                          touched: touched.displayText,
                          handleChange,
                          handleBlur,
                        }}
                      />
                      <InputText
                        {...{
                          type: "text",
                          label: TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS,
                          name: "title",
                          value: values.title,
                          error: errors.title,
                          touched: touched.title,
                          handleChange,
                          handleBlur,
                        }}
                      />
                      {!values.hasPublicArticles && (
                        <Fragment>
                          <InputText
                            {...{
                              label: URL,
                              name: "url",
                              value: values.url,
                              error: errors.url,
                              touched: touched.url,
                              handleChange,
                              handleBlur,
                            }}
                          />
                          <Toggle
                            {...{
                              label: OPEN_DESCRIPTION_IN_NEW_WINDOW,
                              name: "target",
                              value:
                                values.target === LinkTargetType.NewTab.value,
                              error: !!errors.target,
                              onClick: () => {
                                setFieldValue(
                                  "target",
                                  values.target === LinkTargetType.NewTab.value
                                    ? LinkTargetType.Self.value
                                    : LinkTargetType.NewTab.value
                                );
                              },
                              disabled: !values.url,
                            }}
                          />
                        </Fragment>
                      )}
                      {!values.parentElementId &&
                        !values.url &&
                        values.type === MenuType.Vertical && (
                          <CheckboxInput
                            {...{
                              label: "Domyślnie rozwinięta",
                              name: "keepExpanded",
                              value: values.keepExpanded,
                              error: errors.keepExpanded,
                              setFieldValue,
                            }}
                          />
                        )}
                      <InputText
                        {...{
                          label: DESCRIPTION,
                          name: "description",
                          type: "textarea",
                          value: values.description,
                          error: errors.description,
                          touched: touched.description,
                          handleChange,
                          handleBlur,
                        }}
                      />
                    </CardBody>
                  </Card>
                </Form>
                {currentLang.isoCode === DefaultLanguage.isoCode ? (
                  <Languages
                    {...{
                      languages,
                      otherLanguages,
                      updateOtherLanguages,
                      url: values.url,
                      hasPublicArticles: values.hasPublicArticles,
                      articles: category.articles,
                    }}
                  />
                ) : null}
              </div>
            </ModalBody>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default MenuElementDetails;
