import React, { useState } from "react";
import Tooltip from "rc-tooltip";

import { Button } from "reactstrap";

export const DetailButton = ({
  title,
  onClick,
  showTooltip = false,
  tooltipMessage = "",
  children,
  disabled,
  btnClassName = "",
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div>
      <Tooltip
        placement="top"
        visible={tooltipVisible && showTooltip}
        overlay={<span>{tooltipMessage}</span>}
        overlayStyle={{ maxWidth: 280 }}
      >
        <div>
          <Button
            className={`${btnClassName}`}
            onClick={onClick}
            onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
            title={title}
            disabled={disabled}
          >
            {children}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

//settings-btn
