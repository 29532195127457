import { takeEvery, fork, call, put, take } from 'redux-saga/effects';
import axios from 'axios';
import { ApplicationActions } from '../actions/application';
import { DefaultLanguage } from '../helpers/enums';
import { FilterActions } from '../actions/filter';

const ApplicationUrl = '/api/application';

function* watchApplicationParameters() {
	yield takeEvery(ApplicationActions.GetApplicationParameters.Request, getApplicationParameters);
}

function* getApplicationParameters(action) {
	try {
		const parameters = yield call(getApplicationParametersRequest, action.payload.lang);
		yield put({
			type: ApplicationActions.GetApplicationParameters.Success,
			payload: parameters
		});
	} catch (err) {
		yield put({
			type: ApplicationActions.GetApplicationParameters.Failure
		});
	}
}

function getApplicationParametersRequest(lang = DefaultLanguage.isoCode) {
	return axios.get(`${ApplicationUrl}/${lang}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchAppParamLanguages() {
	yield takeEvery(ApplicationActions.GetAppParamLanguages.Request, getAppParamLanguages);
}

function* getAppParamLanguages(action) {
	try {
		const parameters = yield call(getAppParamLanguagesRequest);
		yield put({
			type: ApplicationActions.GetAppParamLanguages.Success,
			payload: parameters
		});
	} catch (err) {
		yield put({
			type: ApplicationActions.GetAppParamLanguages.Failure
		});
	}
}

function getAppParamLanguagesRequest() {
	return axios.get(`${ApplicationUrl}/languages`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchAppParamDictionaryElements() {
	yield takeEvery(ApplicationActions.GetAppParamDictionaryElements.Request, getAppParamDictionaryElements);
}

function* getAppParamDictionaryElements(action) {
	try {
		const parameters = yield call(getAppParamDictionaryElementsRequest, action.payload.lang);
		yield put({
			type: ApplicationActions.GetAppParamDictionaryElements.Success,
			payload: parameters
		});
	} catch (err) {
		yield put({
			type: ApplicationActions.GetAppParamDictionaryElements.Failure
		});
	}
}

function getAppParamDictionaryElementsRequest(lang = DefaultLanguage.isoCode) {
	return axios.get(`${ApplicationUrl}/dictionaryElements/${lang}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchChangeApplicationLanguage() {
	yield takeEvery(ApplicationActions.ChangeApplicationLanguage.Request, changeApplicationLanguage);
}

function* changeApplicationLanguage(action) {
	localStorage.setItem('language', `${action.payload.isoCode}`);
	yield put({
		type: ApplicationActions.ChangeApplicationLanguage.Success,
		payload: action.payload
	});
}

function* watchLastResetSessionTime() {
	yield takeEvery('*', lastResetSessionTime);
}

function* lastResetSessionTime() {
	const action = yield take('*');
	if (
		action.type.includes('Success') &&
		![
			ApplicationActions.LastResetSessionTime.Success,
			FilterActions.ChangeFilters.Success,
			FilterActions.ClearFilters.Success,
			FilterActions.SaveSelectedFilters.Success
		].includes(action.type)
	) {
		yield put({
			type: ApplicationActions.LastResetSessionTime.Success
		});
	}
}

export const ApplicationSaga = [
	fork(watchApplicationParameters),
	fork(watchAppParamLanguages),
	fork(watchAppParamDictionaryElements),
	fork(watchChangeApplicationLanguage),
	fork(watchLastResetSessionTime)
];
