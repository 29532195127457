import { removeFilterOperators } from "./helpers";
import { OPERATIONS } from "./utils";

const processSingleFilter = (filter) => {
  //if negation
  if (filter.some((el) => el === "!")) {
    //if multi negation
    if (filter[1].some((el) => el === "or")) {
      return {
        columnName: filter[1][0][0],
        operation: "notIn",
        value: removeFilterOperators(filter[1])
          .map((item) => item.filterValue)
          .join(";"),
      };
    }
    //single negation
    return {
      columnName: filter[1][0],
      operation: "notIn",
      value: filter[1][2],
    };
  }

  //simple filter
  return {
    columnName: filter[0],
    operation: OPERATIONS[filter[1]],
    value: filter[2],
  };
};

const processGroupFilter = (filter) => {
  return {
    columnName: filter[0][0],
    operation: "in",
    value: removeFilterOperators(filter)
      .map((item) => item.filterValue)
      .join(";"),
  };
};

const processMultipleFilters = (filter) => {
  let result = [];

  filter
    .filter((item) => item !== "and")
    .forEach((nestedFilter) => {
      if (nestedFilter.some((item) => item === "and")) {
        const multipleFilters = processMultipleFilters(nestedFilter);
        result.push(...multipleFilters);
      } else if (nestedFilter.some((item) => item === "or")) {
        const groupFilter = processGroupFilter(nestedFilter);
        result.push(groupFilter);
      } else {
        const singleFilter = processSingleFilter(nestedFilter);
        result.push(singleFilter);
      }
    });

  return result;
};

export const processFilters = (filter) => {
  const currentFilters = [];

  if (!filter) {
    return [];
  }

  //single
  if (filter.every((item) => item !== "and")) {
    if (filter.some((item) => item === "or")) {
      //group (in)
      const groupFilter = processGroupFilter(filter);
      currentFilters.push(groupFilter);

      return currentFilters;
    } else {
      //single
      const singleFilter = processSingleFilter(filter);
      currentFilters.push(singleFilter);

      return currentFilters;
    }
  } else {
    const multipleFilters = processMultipleFilters(filter);
    currentFilters.push(...multipleFilters);

    return currentFilters;
  }
};
