import React, { useState } from "react";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Button,
  FormFeedback,
  CardHeader,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

import {
  format as dateFnsFormat,
  startOfDay,
  endOfDay,
  isValid as dateFnsIsValid,
  addMinutes,
  isToday,
  setSeconds,
} from "date-fns";

import { TimeInput } from "../components/DatepickerTimeInput";

import { CONSTANTS } from "@constants";

import * as yup from "yup";

import {
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT,
} from "../Pages/Articles/Common/Card/utils";
import { Formik, Form } from "formik";

const schema = yup.object().shape({
  publishStart: yup
    .date()
    .nullable()
    .min(new Date(), "Data publikacji od musi być większa od daty aktualnej"),
  publishEnd: yup
    .date()
    .nullable()
    .test(
      "Publish end must be greater than publish start",
      "Data publikacji do musi być późniejsza niż data publikacji od",
      function (publishEnd) {
        if (this.parent.publishStart && publishEnd) {
          return publishEnd > this.parent.publishStart;
        }
        return true;
      }
    )
    .test(
      "Publish end must be greater than",
      "Data publikacji do musi być późniejsza niż aktualna",
      function (publishEnd) {
        if (publishEnd) {
          return publishEnd >= new Date();
        }
        return true;
      }
    ),
});

export const PublishedButton = ({ direction, submit, disabled, ...args }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {
    DATA_OF_PUBLICATION_FROM,
    DATA_FROM_PUBLICATION_TO,
    PUBLICATION,
    ARCHIVE_FROM_DATA,
  } = CONSTANTS;

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      direction={direction}
      disabled={disabled}
    >
      <DropdownToggle
        caret
        style={{ height: "38px" }}
        className={"mr-2 mb-2 btn-info"}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faFileExport} color="#fff" className="mr-2" />
        Publikuj
      </DropdownToggle>
      <DropdownMenu {...args} className={"p-3 py-0"} style={{ width: "600px" }}>
        <Formik
          onSubmit={(values) => {
            toggle();
            submit(values);
          }}
          initialValues={{
            publishStart: null,
            publishEnd: null,
            archiveAfterPublishEnd: false,
          }}
          validationSchema={schema}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <CardHeader className={"pl-0"}>
                <i className="header-icon pe-7s-timer icon-gradient bg-malibu-beach" />
                {PUBLICATION}
              </CardHeader>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="publishStart">{DATA_OF_PUBLICATION_FROM}</Label>
                    <div className="d-flex">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <div className="input-group-text">
                            <i className="pe-7s-date" />
                          </div>
                        </InputGroupAddon>
                        <DatePicker
                          className={`${
                            errors.publishStart ? "is-invalid " : ""
                          }form-control`}
                          id="publishStart-date"
                          name="publishStart-date"
                          selected={
                            values.publishStart
                              ? new Date(values.publishStart)
                              : null
                          }
                          onChange={(date) => {
                            if (dateFnsIsValid(date)) {
                              isToday(date)
                                ? setFieldValue(
                                    "publishStart",
                                    dateFnsFormat(new Date(), DATE_TIME_FORMAT)
                                  )
                                : setFieldValue(
                                    "publishStart",
                                    dateFnsFormat(
                                      startOfDay(date),
                                      DATE_TIME_FORMAT
                                    )
                                  );
                            } else {
                              setFieldValue("publishStart", null);
                            }
                          }}
                          minDate={new Date()}
                          dateFormat={DATE_FORMAT}
                          invalid={!!errors.publishStart}
                          autoComplete="off"
                          clearButtonTitle="Wyczyść"
                          isClearable
                        />
                      </InputGroup>
                      <InputGroup className="ml-2">
                        <InputGroupAddon addonType="prepend">
                          <div className="input-group-text">
                            <i className="pe-7s-timer" />
                          </div>
                        </InputGroupAddon>
                        <DatePicker
                          className="form-control"
                          id="publishStart-time"
                          name="publishStart-time"
                          selected={
                            values.publishStart
                              ? new Date(values.publishStart)
                              : null
                          }
                          timeFormat={TIME_FORMAT}
                          dateFormat={TIME_FORMAT}
                          invalid={!!errors.publishStart}
                          autoComplete="off"
                          customInput={
                            <TimeInput
                              invalid={!!errors.publishStart}
                              date={
                                values.publishStart
                                  ? new Date(values.publishStart)
                                  : null
                              }
                              minTime={
                                isToday(new Date(values.publishStart))
                                  ? new Date()
                                  : startOfDay(new Date(values.publishStart))
                              }
                              maxTime={endOfDay(new Date())}
                              onChangeDate={(date) => {
                                dateFnsIsValid(date)
                                  ? setFieldValue(
                                      "publishStart",
                                      dateFnsFormat(
                                        setSeconds(date, 0),
                                        DATE_TIME_FORMAT
                                      )
                                    )
                                  : setFieldValue("publishStart", null);
                              }}
                            />
                          }
                        />
                      </InputGroup>
                    </div>
                    {errors.publishStart && (
                      <FormFeedback style={{ display: "block" }}>
                        {errors.publishStart}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="publishEnd">{DATA_FROM_PUBLICATION_TO}</Label>
                    <div className="d-flex">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <div className="input-group-text">
                            <i className="pe-7s-date" />
                          </div>
                        </InputGroupAddon>
                        <DatePicker
                          className={`${
                            errors.publishEnd ? "is-invalid " : ""
                          }form-control`}
                          id="publishEnd-date"
                          name="publishEnd-date"
                          selected={
                            values.publishEnd
                              ? new Date(values.publishEnd)
                              : null
                          }
                          onChange={(date) => {
                            dateFnsIsValid(date)
                              ? setFieldValue(
                                  "publishEnd",
                                  dateFnsFormat(
                                    endOfDay(date),
                                    DATE_TIME_FORMAT
                                  )
                                )
                              : setFieldValue("publishEnd", null);
                          }}
                          invalid={!!errors.publishEnd}
                          minDate={values.publishStart}
                          dateFormat={DATE_FORMAT}
                          autoComplete="off"
                          clearButtonTitle="Wyczyść"
                          isClearable
                        />
                      </InputGroup>
                      <InputGroup className="ml-2">
                        <InputGroupAddon addonType="prepend">
                          <div className="input-group-text">
                            <i className="pe-7s-timer" />
                          </div>
                        </InputGroupAddon>
                        <DatePicker
                          className="form-control"
                          id="publishEnd-time"
                          name="publishEnd-time"
                          selected={
                            values.publishEnd
                              ? new Date(values.publishEnd)
                              : null
                          }
                          timeFormat={TIME_FORMAT}
                          dateFormat={TIME_FORMAT}
                          invalid={!!errors.publishEnd}
                          autoComplete="off"
                          customInput={
                            <TimeInput
                              invalid={!!errors.publishEnd}
                              date={
                                values.publishEnd
                                  ? new Date(values.publishEnd)
                                  : null
                              }
                              minTime={
                                values.publishStart &&
                                startOfDay(values.publishStart) ===
                                  startOfDay(values.publishEnd)
                                  ? addMinutes(new Date(values.publishStart), 1)
                                  : isToday(new Date(values.publishEnd))
                                  ? addMinutes(new Date(), -1)
                                  : startOfDay(values.publishEnd)
                              }
                              maxTime={endOfDay(new Date(values.publishEnd))}
                              onChangeDate={(date) => {
                                dateFnsIsValid(date)
                                  ? setFieldValue(
                                      "publishEnd",
                                      dateFnsFormat(
                                        setSeconds(date, 59),
                                        DATE_TIME_FORMAT
                                      )
                                    )
                                  : setFieldValue("publishEnd", null);
                              }}
                            />
                          }
                        />
                      </InputGroup>
                    </div>
                    {errors.publishEnd && (
                      <FormFeedback style={{ display: "block" }}>
                        {errors.publishEnd}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="archiveAfterPublishEnd"
                        name="archiveAfterPublishEnd"
                        checked={values.archiveAfterPublishEnd}
                        onChange={() =>
                          setFieldValue(
                            "archiveAfterPublishEnd",
                            !values.archiveAfterPublishEnd
                          )
                        }
                      />
                      {ARCHIVE_FROM_DATA}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <div className="d-flex justify-content-end">
                <Button type="submit">Opublikuj</Button>
              </div>
            </Form>
          )}
        </Formik>
      </DropdownMenu>
    </Dropdown>
  );
};
