import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import ArticleCard from "../Common/Card/Article";
import ArticleList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const SystemArticles = () => (
  <Fragment>
    <Switch>
      <Route
        path={`${RouteUrls.Article.system.edit}/:id`}
        component={ArticleCard}
      />
      <Route path="*" component={ArticleList} />
    </Switch>
  </Fragment>
);

export default SystemArticles;
