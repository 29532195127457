import React, { Fragment } from "react";
import map from "lodash.map";
import orderBy from "lodash.orderby";
import find from "lodash.find";
import ConfirmModal from "../../../../../components/ConfirmModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { ArticleStatus } from "../../../../../helpers/enums";
import filter from "lodash.filter";

export default class ChooseArticleStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStatus: props.actualStatus,
      newStatus: 0,
      navState: this.getNavStates(props.actualStatus),
      isOpenChangeStatusModal: false,
      isOpenInfoModal: false,
      isOpenPublishInfoModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.actualStatus !== this.props.actualStatus) {
      const newNavState = this.getNavStates(this.props.actualStatus);
      this.setState({
        currentStatus: this.props.actualStatus,
        newStatus: 0,
        navState: newNavState,
      });
    }
  }

  getNavStates = (statusValue) => {
    const { statuses, userPermissions } = this.props;
    return map(orderBy(statuses, ["value"]), (status, statusIndex) => ({
      // styles: status.value < statusValue ? 'done' : status.value === statusValue ? 'doing' : 'todo'
      styles:
        status.value <= statusValue
          ? "done"
          : status.availableFor.some(
              (item) =>
                item.status === statusValue &&
                item.permissions.every((permission) =>
                  userPermissions.includes(permission)
                )
            )
          ? "doing"
          : "todo",
    }));
  };

  setNavState = (statusValue, userChangeText) => {
    const { onChange } = this.props;
    if (onChange && typeof onChange === "function") {
      onChange(statusValue, userChangeText);
    }
    this.setState({
      isOpenChangeStatusModal: false,
      newStatus: 0,
    });
  };

  toggleShowModal = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  handleOnClick = (event) => {
    this.setState({
      isOpenChangeStatusModal: true,
      newStatus: event.currentTarget.value,
    });
  };

  getClassName = (className, i) => {
    return className + "-" + this.state.navState[i].styles;
  };

  renderStatuses = () => {
    const {
      statuses,
      userPermissions,
      articleId = 0,
      disabled = false,
      isSystem = false,
    } = this.props;
    const { currentStatus } = this.state;

    const articleStatuses = isSystem
      ? filter(
          statuses,
          (status) => status.value !== ArticleStatus.Archived.value
        )
      : statuses;
    return map(orderBy(articleStatuses, ["value"]), (status, statusIndex) => {
      const availableStatus = status.availableFor.some(
        (item) =>
          item.status === currentStatus &&
          item.permissions.every((permission) =>
            userPermissions.includes(permission)
          )
      );
      return (
        <li
          key={`status-${statusIndex}`}
          className={`${this.getClassName("form-wizard-step", statusIndex)} ${
            status.className
          }`}
          onClick={(e) => {
            if (articleId <= 0 || disabled || !availableStatus) {
              return;
            }
            availableStatus
              ? this.handleOnClick(e)
              : status.value === ArticleStatus.Published.value
              ? this.toggleShowModal("isOpenPublishInfoModal")
              : this.toggleShowModal("isOpenInfoModal");
          }}
          id={`status-${statusIndex}`}
          value={status.value}
        >
          <em>{statusIndex + 1}</em>
          <span>{this.props.statuses[statusIndex].name}</span>
        </li>
      );
    });
  };

  cancel = () => {
    this.setState({
      isOpenChangeStatusModal: false,
      newStatus: 0,
    });
  };

  getErrorText = (newStatus) => {};

  render() {
    const {
      isOpenChangeStatusModal,
      isOpenInfoModal,
      isOpenPublishInfoModal,
      newStatus,
    } = this.state;
    const { statuses, isDirty } = this.props;
    const status = find(statuses, { value: newStatus });
    return (
      <Fragment>
        <SweetAlert
          show={isOpenInfoModal}
          danger
          confirmBtnText="Ok"
          title={"Brak uprawnień"}
          onConfirm={() => this.toggleShowModal("isOpenInfoModal")}
          style={{
            marginLeft: "-15%",
            marginRight: "-15%",
            marginTop: "-15%",
            marginBottom: "-15%",
            position: "fixed",
          }}
        >
          {"Brak uprawnień do zmiany statusu"}
        </SweetAlert>
        <SweetAlert
          show={isOpenPublishInfoModal}
          info
          confirmBtnText="Ok"
          title={""}
          onConfirm={() => this.toggleShowModal("isOpenPublishInfoModal")}
          style={{
            marginLeft: "-15%",
            marginRight: "-15%",
            marginTop: "-15%",
            marginBottom: "-15%",
            position: "fixed",
          }}
        >
          {
            'Artykuły publikowane są automatycznie zgodnie z datą i godziną wskazaną w polu "Opublikowany od"'
          }
        </SweetAlert>
        <ConfirmModal
          open={isOpenChangeStatusModal}
          onSubmit={(userChangeText) =>
            this.setNavState(newStatus, userChangeText)
          }
          onCancel={this.cancel}
          title="Zmiana statusu artykułu"
          content={`Czy zmienić status na ${status ? status.name : "nowy"}?`}
          input={isDirty}
        />

        <ol className="forms-wizard">{this.renderStatuses()}</ol>
      </Fragment>
    );
  }
}
