import React from "react";
import { toast } from "react-toastify";
import { SizeUnit } from "./enums";
import {
  getErrorForToastr,
  isCheckAccessModuleRequest,
} from "../utils/error-adapter";

export function fileUploadError(
  files,
  maxFileSize,
  customErrorExtensionMessage = ""
) {
  files.forEach((file) => {
    if (file && maxFileSize * SizeUnit.MB < file.size) {
      toast.error(
        `Rozmiar pliku przekracza dopuszczalny limit (${maxFileSize} MB)`
      );
    } else {
      toast.error(
        customErrorExtensionMessage ||
          "Błąd przesyłania pliku. Możliwą przyczyną błędu jest nieprawidłowy plik typu"
      );
    }
  });
}

export function createErrorToast(err, alternativeMessage = "") {
  const { message = null } = getErrorForToastr(err);
  const isCheckAccess = isCheckAccessModuleRequest(err.response);

  if (isCheckAccess) {
    return;
  }

  if (
    (err.response.status !== 401 && err.response.status !== 403) ||
    (message && (err.response.status === 401 || err.response.status === 403))
  ) {
    toast.error(
      <div
        dangerouslySetInnerHTML={{
          __html: message || alternativeMessage,
        }}
      />
    );
  }
}
