import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';

import { DictionaryActions } from '../../actions/dictionary';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const tagUrl = '/api/dictionaries/tags';

function* createTag(action) {
	try {
		let tag = yield call(createTagRequest, action.payload.tag);

		yield put({
			type: DictionaryActions.CreateTagAction.Success,
			payload: tag
		});

		if (action.payload.changeUrl) {
			yield put(replace(RouteUrls.Dictionary.tag.editFunc(tag.id)));
		}
		if (action.payload.withNotification) {
			toast.success('Nowy tag został utworzony');
		}
	} catch (err) {
		yield put({
			type: DictionaryActions.CreateTagAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie tworzenia nowego tagu');
	}
}

function createTagRequest(tag) {
	return axios.post(`${tagUrl}`, tag).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* getTag(action) {
	try {
		let tag = yield call(getTagRequest, action.payload);

		yield put({
			type: DictionaryActions.GetTagAction.Success,
			payload: tag
		});
	} catch (err) {
		yield put({
			type: DictionaryActions.GetTagAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie pobierania tagu');
	}
}

function getTagRequest(tagId) {
	return axios.get(`${tagUrl}/${tagId}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* getAllTags(action) {
	try {
		let tags = yield call(getListRequest, {
			url: tagUrl,
			lang: action.payload.lang,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: DictionaryActions.GetAllTagsAction.Success,
			payload: tags
		});
	} catch (err) {
		yield put({
			type: DictionaryActions.GetAllTagsAction.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy tagów');
	}
}

function* updateTag(action) {
	try {
		let tag = yield call(updateTagRequest, action.payload.id, action.payload);

		yield put({
			type: DictionaryActions.UpdateTagAction.Success,
			payload: tag
		});
		toast.success('Tag został zaktualizowany');
	} catch (err) {
		yield put({
			type: DictionaryActions.UpdateTagAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie aktualizacji tagu');
	}
}

function updateTagRequest(id, tag) {
	return axios.put(`${tagUrl}/${id}`, tag).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* removeTags(action) {
	try {
		yield call(removeTagsRequest, action.payload);

		yield put({
			type: DictionaryActions.RemoveTagsAction.Success,
			payload: action.payload
		});
		toast.success('Tagi zostały usunięte');
	} catch (err) {
		yield put({
			type: DictionaryActions.RemoveTagsAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie usuwania tagów');
	}
}

function removeTagsRequest(tagIds) {
	const filterQuery = qs.stringify({ ids: tagIds }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${tagUrl}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchCreateTag() {
	yield takeEvery(DictionaryActions.CreateTagAction.Request, createTag);
}
function* watchGetTag() {
	yield takeEvery(DictionaryActions.GetTagAction.Request, getTag);
}
function* watchGetAllTags() {
	yield takeEvery(DictionaryActions.GetAllTagsAction.Request, getAllTags);
}
function* watchUpdateTag() {
	yield takeEvery(DictionaryActions.UpdateTagAction.Request, updateTag);
}
function* watchRemoveTags() {
	yield takeEvery(DictionaryActions.RemoveTagsAction.Request, removeTags);
}

export const TagSagas = [
	fork(watchCreateTag),
	fork(watchGetTag),
	fork(watchGetAllTags),
	fork(watchUpdateTag),
	fork(watchRemoveTags)
];
