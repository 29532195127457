import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash.isempty';
import lodashFilter from 'lodash.filter';
import find from 'lodash.find';
import map from 'lodash.map';

import { Typeahead } from 'react-bootstrap-typeahead';

import { DictionaryActionCreators } from '../../actions/dictionary';
import { ApplicationActionCreators } from '../../actions/application';
import { FilterField } from './FilterField';

import { CONSTANTS } from '@constants';

const { EMPTY_DATA } = CONSTANTS;

class TagFilter extends Component {
	componentDidMount() {
		const { activeLanguage, getAllTags } = this.props;
		getAllTags(activeLanguage.isoCode);
	}

	render() {
		const {
			columnName,
			availableValues,
			availableTags,
			filters,
			onChangeFilters,
			isOpen,
			isPopoverClicked,
			targetId
		} = this.props;
		const tagValues = map(availableTags, tag => tag.name);
		return (
			<FilterField
				columnName={columnName}
				availableValues={availableValues}
				filters={filters}
				onChangeFilters={onChangeFilters}
				isOpen={isOpen}
				isPopoverClicked={isPopoverClicked}
				targetId={targetId}
			>
				{({ name, placeholder, disabled, filter, onChange }) => {
					const selected = filter
						? map(lodashFilter(filter.value, tagValue => tagValues.includes(tagValue)), tag =>
								find(availableTags, { name: tag })
							)
						: [];
					return (
						<Typeahead
							id={`${name}-filter`}
							name={name}
							labelKey="name"
							options={availableTags}
							selected={selected}
							emptyLabel={EMPTY_DATA}
							clearButton
							multiple={true}
							allowNew={false}
							placeholder={placeholder}
							onChange={tags => {
								if (!isEmpty(tags)) {
									onChange(map(tags, tag => tag.name));
								} else {
									onChange(null);
								}
							}}
							disabled={disabled}
						/>
					);
				}}
			</FilterField>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		availableTags: state.dictionary.tag.tags || [],
		activeLanguage: state.application.activeLanguage
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators({ ...DictionaryActionCreators, ...ApplicationActionCreators }, dispatch)
	};
};

export const TagFilterField = connect(mapStateToProps, mapDispatchToProps)(TagFilter);
