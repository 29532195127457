import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import Immutable from 'seamless-immutable';

import { MenuType } from '@helpers/enums';
import filter from 'lodash.filter';

const target = {
	drop(props, monitor, component) {
		const monitorItem = monitor.getItem();
		const item = {
			id: 0,
			menuElementId: monitorItem.node.id | 0,
			menuElementType: monitorItem.node.type,
			menuElementDisplayText: monitorItem.node.displayText,
			menuElementTitle: monitorItem.node.title,
			menuElementTextPath: monitorItem.node.textPath,
			menuElementUrl: monitorItem.node.url,
			menuElementSlug: monitorItem.node.slug,
			menuElementIsHidden: monitorItem.node.isHidden,
			menuElementIsSystem: monitorItem.node.isSystem,
			isMainMenuElement: monitorItem.node.isMainMenuElement
		};
		const isExists =
			component.state.items.findIndex((element) => element.menuElementId === item.menuElementId) !== -1;

		if (isExists) {
			return {
				...monitorItem
			};
		}

		const newItemList = component.state.items.concat(item);
		component.setState((state) => ({
			items: newItemList
		}));

		if (component.props.onChangeItems && typeof component.props.onChangeItems === 'function') {
			component.props.onChangeItems(newItemList);
		}
		return {
			node: item,
			path: monitorItem.path,
			treeId: monitorItem.treeId,
			treeIndex: monitorItem.treeIndex
		};
	},
	// hover(props, monitor, component) {
	// 	const canDrop = monitor.canDrop();
	// },
	canDrop(props, monitor) {
		if (monitor.didDrop()) {
			// If you want, you can check whether some nested
			// target already handled drop
			return;
		}
		return { moved: true };
	}
};

const collect = (connect, monitor) => {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	};
};

class CategoryTarget extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: Immutable(props.items)
		};
	}

	componentDidUpdate(prevProps) {
		const { items } = this.props;

		if (!isEqual(prevProps.items, items)) {
			this.setState({
				items: [ ...items ]
			});
		}
	}

	clear = (index) => {
		const newItemList = this.state.items.slice(0, index).concat(this.state.items.slice(index + 1));
		this.setState({
			items: newItemList
		});
		if (this.props.onChangeItems && typeof this.props.onChangeItems === 'function') {
			this.props.onDeleteItems(newItemList);
		}
	};

	clearAll = () => {
		const newItemList = filter(this.state.items, { menuElementIsHidden: true });
		this.setState({ items: newItemList });
		if (this.props.onChangeItems && typeof this.props.onChangeItems === 'function') {
			this.props.onDeleteItems(newItemList);
		}
	};

	render() {
		const { connectDropTarget, excludeCategories = [] } = this.props;
		const { items } = this.state;

		return connectDropTarget(
			<div className="has-aux" tabIndex="-1" style={{ position: 'relative' }}>
				<div className="rbt-input">
					<div
						className={`rbt-input-wrapper dropzone-wrapper dropzone-wrapper-lg category-boxtarget ${!isEmpty(
							items
						)
							? 'height--auto'
							: ''}`}
					>
						{isEmpty(items) && (
							<div>
								<div className="dropzone-content">
									<p className="p-1">
										Przeciągnij tutaj lub kliknij dwukrotnie na wybraną kategorię.
									</p>
								</div>
							</div>
						)}
						{map(filter(items, { menuElementIsHidden: false }), (item, itemIndex) => (
							<div
								key={`item-${item.id}-${itemIndex}`}
								tabIndex="0"
								className="rbt-token rbt-token-removeable"
							>
								{item.menuElementType === MenuType.Vertical && <i className="pe-7s-menu rotate-90" />}
								{item.menuElementType === MenuType.Horizontal && <i className="pe-7s-menu" />}
								{item.menuElementDisplayText}

								{!excludeCategories.includes(item.menuElementId) && (
									<button
										tabIndex="-1"
										aria-label="Usuń"
										className="close rbt-close rbt-token-remove-button"
										type="button"
										onClick={this.clear.bind(this, itemIndex)}
									>
										<span aria-hidden="true">×</span>
										<span className="sr-only">Usuń</span>
									</button>
								)}
							</div>
						))}
					</div>
					{!isEmpty(items) &&
					isEmpty(excludeCategories) && (
						<div className="rbt-aux">
							<button
								aria-label="Usuń wszystkie"
								className="close rbt-close"
								type="button"
								onClick={this.clearAll}
							>
								<span aria-hidden="true">×</span>
								<span className="sr-only">Usuń wszystkie</span>
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default DropTarget((props) => props.dropType, target, collect)(CategoryTarget);
