import React, { useMemo, memo } from "react";
import { DxTable } from "../../components/DxGrid/DxGrid";
import SelectBox from "devextreme-react/select-box";
import TextBox from "devextreme-react/text-box";

import Flag from "react-flagkit";
import uuid from "uuid/v4";

import { Card, CardHeader, CardBody } from "reactstrap";

import CustomStore from "devextreme/data/custom_store";

import { ArticleStatus, DefaultLanguage } from "../../helpers/enums";

import { CONSTANTS } from "@constants";

import "./styles.css";

const { TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS, NAME, URL } = CONSTANTS;

const SelectLanguage = ({ props, languageOptions }) => (
  <SelectBox
    dataSource={languageOptions}
    valueExpr="id"
    displayExpr="name"
    deferRendering={true}
    defaultValue={props.data.value?.id}
    onSelectionChanged={(e) => {
      props.data.setValue(e.selectedItem, "language");
    }}
    itemRender={renderListItem}
    fieldRender={renderValue}
    noDataText="Brak opcji"
    showClearButton={true}
  />
);

const renderListItem = (data) => {
  return (
    <div className="d-flex align-items-center">
      {data && data.isoCode ? (
        <div className={`mr-1 opacity-8`}>
          {data?.isoCodeFile ? (
            <img
              src={data.isoCodeFile.path}
              alt={`Grafika ${data.isoCodeFile.fileName}`}
              height={16}
              width={24}
            />
          ) : (
            <Flag country={data.isoCode || "PL"} size="32" />
          )}
        </div>
      ) : null}
      <span>{data.name}</span>
    </div>
  );
};

const renderValue = (data) => {
  return (
    <div className="d-flex align-items-center ">
      {data ? (
        <div className={`mr-1 ml-2 opacity-8`}>
          {data?.isoCodeFile ? (
            <img
              src={data.isoCodeFile.path}
              alt={`Grafika ${data.isoCodeFile.fileName}`}
              height={16}
              width={24}
            />
          ) : (
            <Flag country={data.isoCode || DefaultLanguage.isoCode} size="32" />
          )}
        </div>
      ) : null}
      <TextBox className="product-name" defaultValue={data?.name} />
    </div>
  );
};

export const Languages = memo(
  ({
    languages = [],
    otherLanguages,
    updateOtherLanguages,
    url,
    hasPublicArticles,
    articles,
  }) => {
    const store = useMemo(
      () =>
        new CustomStore({
          key: "id",
          load: () => {
            return otherLanguages.filter(
              ({ language }) => language.isoCode !== DefaultLanguage.isoCode
            );
          },
          insert: (values) => {
            const newData = [
              ...otherLanguages,
              {
                id: uuid(),
                language: {
                  name: values.language?.name,
                  id: values.language?.id,
                  isoCode: values.language?.isoCode,
                  isoCodeFile: values.language?.isoCodeFile,
                  isSystem: values.language?.isSystem,
                },
                languageIsoCode: values.language?.isoCode,
                languageName: values.language?.name,
                languageId: values.language?.id,
                translatedDisplayText: values?.translatedDisplayText || [],
                translatedTarget: 10,
                translatedTitle: values?.translatedTitle || "",
                translatedUrl: values?.translatedUrl || "",
              },
            ];

            updateOtherLanguages(newData);

            return Promise.resolve({});
          },
          update: (key, values) => {
            const { language, ...res } = values;

            const newData = otherLanguages.map((el) => {
              return el.id === key
                ? {
                    ...el,
                    languageIsoCode: values.language.isoCode,
                    languageId: values.language.id,
                    languageName: values.language.name,
                    language: {
                      name: values.language.name,
                      id: values.language.id,
                      isoCode: values.language.isoCode,
                      isoCodeFile: values.language?.isoCodeFile,
                      isSystem: values.language?.isSystem,
                    },
                    translatedTarget: 10,
                    ...res,
                  }
                : el;
            });

            updateOtherLanguages(newData);

            return Promise.resolve({});
          },
          remove: (key) => {
            const newData = otherLanguages.filter(({ id }) => id !== key);
            updateOtherLanguages(newData);

            return Promise.resolve({});
          },
        }),
      [otherLanguages, updateOtherLanguages]
    );

    const columns = [
      {
        dataField: "language",
        caption: "Język",
        dataType: "object",
        allowSorting: true,
        editCellComponent: (props) => {
          const languageOptions = languages.filter(
            ({ isoCode }) =>
              (isoCode !== DefaultLanguage.isoCode &&
                !otherLanguages.find(
                  ({ language }) => language?.isoCode === isoCode
                )) ||
              isoCode === props.data?.value?.isoCode
          );

          return <SelectLanguage {...{ props, languageOptions }} />;
        },
        cellRender: (e) => {
          const { language } = e.data;

          return (
            <>
              {language ? (
                <div className="d-flex align-items-center">
                  <div className={`mr-1 ml-2 opacity-8`}>
                    {language?.isoCodeFile ? (
                      <img
                        src={language.isoCodeFile.path}
                        alt={`Grafika ${language.isoCodeFile.fileName}`}
                        height={16}
                        width={24}
                      />
                    ) : (
                      <Flag
                        country={language?.isoCode || DefaultLanguage.isoCode}
                        size="24"
                      />
                    )}
                  </div>
                  {language.name}
                </div>
              ) : null}
            </>
          );
        },
        validationRules: [{ type: "required", message: "Pole wymagane" }],
      },
      {
        dataField: "translatedDisplayText",
        caption: NAME,
        validationRules: [{ type: "required", message: "Pole wymagane" }],
        dataType: "text",
        allowSorting: true,
      },
      {
        dataField: "translatedTitle",
        caption: TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS,
        visible: false,
        validationRules: [{ type: "required", message: "Pole wymagane" }],
        dataType: "text",
        allowSorting: true,
      },
      {
        dataField: "translatedUrl",
        caption: URL,
        visible: false,
        dataType: "text",
        allowSorting: true,
        validationRules: !!url
          ? [
              { type: "required", message: "Pole wymagane" },
              {
                type: "custom",
                validationCallback: (props) => {
                  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

                  return (
                    props.data?.translatedUrl &&
                    urlRegex.test(props.data?.translatedUrl)
                  );
                },
                message:
                  "Adres URL musi zawierać prawidłowy format adresu. Musi składać się protokołu, adresu oraz domeny",
              },
            ]
          : [],
      },
    ];

    const onCellPrepared = (e) => {
      if (e.rowType === "data" && e.column.command === "edit") {
        e.cellElement.style.display = "flex";

        const deleteLink = e.cellElement.querySelector(".dx-link-delete");
        const editLink = e.cellElement.querySelector(".dx-link-edit");

        deleteLink.classList.add("text-danger");
        editLink.classList.add("text-warning");

        const currentLanguageArticles = (articles || []).filter(
          ({ languageId }) => languageId === e.data?.languageId
        );

        const hasActiveArticle = currentLanguageArticles.some(
          ({ status }) => status === ArticleStatus.Published.value
        );

        if (hasActiveArticle) {
          deleteLink.classList.add("disable-link");

          const wrapper = document.createElement("div");
          wrapper.title =
            "Kategoria posiada opublikowane artykuły w danym języku";
          wrapper.style.cursor = "not-allowed";
          wrapper.appendChild(deleteLink.cloneNode(true));

          deleteLink.parentNode.replaceChild(wrapper, deleteLink);
        }
      }
    };

    return (
      <Card className="mb-2 mr-1 menuElement__card">
        <CardHeader>
          <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
          Tłumaczenia
        </CardHeader>
        <CardBody>
          <DxTable
            {...{
              dataSource: store,
              columns: columns.filter((column) => {
                if (hasPublicArticles) {
                  return column.dataField !== "translatedUrl";
                }

                return column;
              }),
              allowAdding: true,
              allowDeleting: true,
              allowUpdating: true,
              onCellPrepared,
              isFilterRow: false,
              allowSorting: false,
            }}
          />
        </CardBody>
      </Card>
    );
  }
);
