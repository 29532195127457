import ThemeOptions from './ThemeOptions';
import { application } from './application';
import { article } from './article';
import { auth } from './auth';
import { dictionary } from './dictionary';
import { footer } from './footer';
import { filter } from './filter';
import { menu } from './menu';
import { office } from './office';
import { setting } from './setting';
import { uiBlockState } from './ui-block';

export default {
	ThemeOptions,
	application,
	article,
	auth,
	dictionary,
	footer,
	filter,
	menu,
	office,
	setting,
	uiBlockState
};
