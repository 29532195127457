import React, { Fragment } from 'react';
import map from 'lodash.map';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ArticleStatus, ArticleType } from '../../../helpers/enums';
import {
	faPencilAlt,
	faThumbsUp,
	faFileExport,
	faCheckCircle,
	faArchive,
	faArrowCircleLeft
} from '@fortawesome/free-solid-svg-icons';

export const columns = [
	{ name: 'id', title: 'id' },
	{ name: 'title', title: 'Tytuł artykułu' },
	{ name: 'baseArticleType', title: 'Typ' },
	{ name: 'status', title: 'Status' },
	{ name: 'version', title: 'Wersja' },
	{ name: 'createdBy', title: 'Utworzony przez' },
	{ name: 'publishStart', title: 'Data publikacji od' },
	{ name: 'publishEnd', title: 'Data publikacji do' },
	{ name: 'languages', title: 'Wersje językowe' }
];
export const columnExtensions = [
	{
		columnName: 'name',
		wordWrapEnabled: true
	},
	{
		columnName: 'status',
		width: '6%',
		align: 'left'
	},
	{
		columnName: 'baseArticleType',
		width: '6%',
		align: 'left'
	},
	{
		columnName: 'version',
		width: '6%',
		align: 'left'
	},
	{
		columnName: 'createdBy',
		align: 'left'
	},
	{
		columnName: 'publishStart',
		align: 'left'
	},
	{
		columnName: 'publishEnd',
		align: 'left'
	},
	{
		columnName: 'languages',
		align: 'left'
	}
];

export const hiddenColumns = [ 'id' ];
export const textColumns = [ 'title', 'createdBy' ];
export const boolColumns = [];
export const boolFilterOperations = [];
export const intColumns = [ 'version' ];
export const intFilterOperations = [ 'equal', 'notEqual' ];
export const dateColumns = [ 'publishStart', 'publishEnd' ];
export const dateFilterOperations = [ 'date', 'greaterThanOrEqual', 'lessThanOrEqual' ];
export const currentPage = 0;

const TypeDataFormatter = ({ value }) => {
	switch (value) {
		case 10:
			return 'Tekst';
		case 20:
			return 'Przetarg';
		default:
			return '';
	}
};

const StatusDataFormatter = ({ value }) => {
	switch (value) {
		case 10:
			return (
				<div title={ArticleStatus.Draft.name}>
					<FontAwesomeIcon icon={faPencilAlt} color="#83588a" />
				</div>
			);
		case 20:
			return (
				<div title={ArticleStatus.Confirmed.name}>
					<FontAwesomeIcon icon={faThumbsUp} color="#30b1ff" />
				</div>
			);
		case 30:
			return (
				<div title={ArticleStatus.ToPublish.name}>
					<FontAwesomeIcon icon={faFileExport} color="#f7b924" />
				</div>
			);
		case 40:
			return (
				<div title={ArticleStatus.Published.name}>
					<FontAwesomeIcon icon={faCheckCircle} color="#3ac47d" />
				</div>
			);
		case 50:
			return (
				<div title={ArticleStatus.Withdrawed.name}>
					<FontAwesomeIcon icon={faArrowCircleLeft} color="#d92550" />
				</div>
			);
		case 60:
			return (
				<div title={ArticleStatus.Archived.name}>
					<FontAwesomeIcon icon={faArchive} color="#343a40" />
				</div>
			);
		default:
			return '';
	}
};

const BaseArticleTypeDataFormatter = ({ row, value, ...props }) => {
	switch (value) {
		case ArticleType.Common.value: {
			return (
				<Fragment>
					<i className="pe-7s-file" title={ArticleType.Common.name} />
				</Fragment>
			);
		}
		case ArticleType.Instruction.value: {
			return (
				<Fragment>
					<i className="pe-7s-ribbon" title={ArticleType.Instruction.name} />
				</Fragment>
			);
		}
		case ArticleType.RSS.value: {
			return (
				<Fragment>
					<i className="pe-7s-pin" title={ArticleType.RSS.name} />
				</Fragment>
			);
		}
		case ArticleType.PersonalDataSecurityTerms.value: {
			return (
				<Fragment>
					<i className="pe-7s-shield" title={ArticleType.PersonalDataSecurityTerms.name} />
				</Fragment>
			);
		}
		case ArticleType.ReusableInformationTerms.value: {
			return (
				<Fragment>
					<i className="pe-7s-refresh" title={ArticleType.ReusableInformationTerms.name} />
				</Fragment>
			);
		}
		case ArticleType.SharingInformationTerms.value: {
			return (
				<Fragment>
					<i className="pe-7s-refresh-cloud" title={ArticleType.SharingInformationTerms.name} />
				</Fragment>
			);
		}
		case ArticleType.ChangeLog.value: {
			return (
				<Fragment>
					<i className="pe-7s-note2" title={ArticleType.ChangeLog.name} />
				</Fragment>
			);
		}
		case ArticleType.Stats.value: {
			return (
				<Fragment>
					<i className="pe-7s-graph" title={ArticleType.Stats.name} />
				</Fragment>
			);
		}
		case ArticleType.Search.value: {
			return (
				<Fragment>
					<i className="pe-7s-search" title={ArticleType.Search.name} />
				</Fragment>
			);
		}
		case ArticleType.Sitemap.value: {
			return (
				<Fragment>
					<i className="pe-7s-map" title={ArticleType.Sitemap.name} />
				</Fragment>
			);
		}
		case ArticleType.ClientData.value: {
			return (
				<Fragment>
					<i className="pe-7s-user" title={ArticleType.ClientData.name} />
				</Fragment>
			);
		}
		case ArticleType.DigitalAvailability.value: {
			return (
				<Fragment>
					<i className="pe-7s-monitor" title={ArticleType.DigitalAvailability.name} />
				</Fragment>
			);
		}
		case ArticleType.TransmissionFromRadniInfo.value: {
			return (
				<Fragment>
					<i className="pe-7s-film" title={ArticleType.TransmissionFromRadniInfo.name} />
				</Fragment>
			);
		}
		default:
			return (
				<Fragment>
					<i className="pe-7s-file" />
				</Fragment>
			);
	}
};

const LanguagesDataFormater = ({ value: languages }) => {
	if (languages && languages.length <= 3)
		return map(languages, (language, index) => (
			<div key={`lang-${index}`} className="badge badge-focus mr-1">
				{language}
			</div>
		));

	if (languages && languages.length > 3)
		return (
			<Fragment>
				{map(languages.slice(0, 3), (language) => <div className="badge badge-focus mr-1">{language}</div>)}
				<UncontrolledButtonDropdown>
					<DropdownToggle color="focus" className="badge badge-focus br-tr br-br">
						...
					</DropdownToggle>
					<DropdownMenu>
						{map(languages.slice(3), (language, index) => (
							<DropdownItem key={`lang-${index}`}>
								<div className="badge badge-focus mr-1">{language}</div>
							</DropdownItem>
						))}
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</Fragment>
		);

	return null;
};

export const dataTypeProviders = [
	<DataTypeProvider for={[ 'baseArticleTemplate' ]} formatterComponent={TypeDataFormatter} />,
	<DataTypeProvider for={[ 'baseArticleType' ]} formatterComponent={BaseArticleTypeDataFormatter} />,
	<DataTypeProvider for={[ 'status' ]} formatterComponent={StatusDataFormatter} />,
	<DataTypeProvider for={[ 'languages' ]} formatterComponent={LanguagesDataFormater} />
];
