import React from 'react';

export const LoginCallback = () => (
	<div className="loader-container">
		<div className="loader-container-inner">
			<h6 className="mt-3">Proszę poczekać, za chwilę nastąpi przekierowanie do strony aplikacji...</h6>
		</div>
	</div>
);

export const NotAuthorizedPage = () => (
	<div className="loader-container">
		<div className="loader-container-inner">
			<h6 className="mt-3">
				Niestety Twoja tożsamość nie została potwierdzona :-(
				<small>Proszę potwierdzić swoją tożsamość, aby uzyskać dostęp do strony</small>
			</h6>
		</div>
	</div>
);

export const NotAuthenticatedPage = () => (
	<div className="loader-container">
		<div className="loader-container-inner">
			<h6 className="mt-3">
				Proszę poczekać, za chwilę nastąpi przekierowanie do strony logowania...
				<small>Proszę potwierdzić swoją tożsamość, aby uzyskać dostęp do strony</small>
			</h6>
		</div>
	</div>
);

export const SessionLostComponent = () => (
	<div className="loader-container">
		<div className="loader-container-inner">
			<h6 className="mt-3">
				Twoja sesja wygasła. Proszę poczekać, za chwilę nastąpi przekierowanie do strony logowania...
				<small>Proszę potwierdzić swoją tożsamość, aby uzyskać dostęp do strony</small>
			</h6>
		</div>
	</div>
);
