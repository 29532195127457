import { takeEvery, fork, put, call } from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import { toast } from "react-toastify";
import axios from "axios";
import qs from "qs";

import { ArticleActions } from "../actions/article";
import { getListRequest } from "./baseRequest";
import { RouteUrls } from "../helpers/routeUrls";
import { ArticleStatus } from "../helpers/enums";
import { createErrorToast } from "../helpers/error-message";

const ARTICLE_URL = "/api/articles";

export function* getAllArticles(action) {
  try {
    let articles = yield call(getListRequest, {
      url: ARTICLE_URL,
      ...action.payload,
    });

    yield put({
      type: ArticleActions.GetAllArticles.Success,
      payload: articles,
    });
  } catch (err) {
    yield put({
      type: ArticleActions.GetAllArticles.Failure,
      payload: { items: [], totalItems: 0 },
    });
    createErrorToast(
      err,
      "Wystąpił niespodziewany błąd podczas pobierania listy artykułów"
    );
  }
}

export function* getArticle(action) {
  try {
    let article = yield call(getArticleRequest, action.payload);

    yield put({
      type: ArticleActions.GetArticle.Success,
      payload: article,
    });
  } catch (err) {
    yield put({
      type: ArticleActions.GetArticle.Failure,
    });
    createErrorToast(
      err,
      "Wystąpił niespodziewany błąd podczas pobierania bazowego artykułu"
    );
  }
}

function getArticleRequest(id = -1) {
  return axios
    .get(`${ARTICLE_URL}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

export function* updateArticle(action) {
  try {
    let article = yield call(updateArticleRequest, action.payload);

    yield put({
      type: ArticleActions.UpdateArticle.Success,
      payload: article,
    });
    toast.success("Artykuł został zaktualizowany");
  } catch (err) {
    yield put({
      type: ArticleActions.UpdateArticle.Failure,
    });
    createErrorToast(
      err,
      "Wystąpił niespodziewany błąd podczas aktualizowania bazowego artykułu"
    );
  }
}

function updateArticleRequest(article) {
  return axios
    .put(`${ARTICLE_URL}/${article.id}`, article)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

export function* saveArticle(action) {
  try {
    let article = yield call(saveArticleRequest, action.payload);

    yield put({
      type: ArticleActions.SaveArticle.Success,
      payload: article,
    });
    toast.success("Artykuł został zapisany");
    yield put(replace(RouteUrls.Article.common.editFunc(article.id)));
  } catch (err) {
    yield put({
      type: ArticleActions.SaveArticle.Failure,
    });
    createErrorToast(
      err,
      "Wystąpił niespodziewany błąd podczas zapisywania bazowego artykułu"
    );
  }
}

function saveArticleRequest(article) {
  return axios
    .post(ARTICLE_URL, article)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

export function* clearArticle() {
  yield put({
    type: ArticleActions.ClearArticle.Success,
  });
}

export function* copyArticleToNewArticleTranslation(action) {
  yield put({
    type: ArticleActions.CopyArticleToNewArticleTranslation.Success,
    payload: action.payload,
  });

  yield put(
    push(RouteUrls.Article.common.createFunc(action.payload.language.isoCode))
  );
  // window.history.replaceState({}, null, RouteUrls.Article.common.createFunc(action.payload.language.isoCode));
}

export function* changeArticleStatus(action) {
  try {
    let article = yield call(
      changeArticleStatusRequest,
      action.payload.article,
      action.payload.newStatus
    );

    yield put({
      type: ArticleActions.ChangeArticleTranslationStatus.Success,
      payload: article,
    });
    toast.success("Status artykułu został zmieniony");
  } catch (err) {
    yield put({
      type: ArticleActions.ChangeArticleTranslationStatus.Failure,
    });
    createErrorToast(
      err,
      "Wystąpił niespodziewany błąd podczas zmiany statusu artykułu"
    );
  }
}

function changeArticleStatusRequest(article, newStatus) {
  return axios
    .put(`${ARTICLE_URL}/${article.id}/status/${newStatus}`, article)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

export function* createArticleNewVersion(action) {
  try {
    let article = yield call(createArticleNewVersionRequest, action.payload);

    yield put({
      type: ArticleActions.CreateArticleNewVersion.Success,
      payload: article,
    });
    toast.success("Utworzono nową wersję artykułu");
    yield put(replace(RouteUrls.Article.common.editFunc(article.id)));
  } catch (err) {
    yield put({
      type: ArticleActions.CreateArticleNewVersion.Failure,
    });
    createErrorToast(
      err,
      "Wystąpił niespodziewany błąd podczas tworzenia nowej wersji artykułu"
    );
  }
}

function createArticleNewVersionRequest(id) {
  return axios
    .post(`${ARTICLE_URL}/${id}/version`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

function* deleteArticles(action) {
  try {
    yield call(deleteArticlesRequest, action.payload);

    yield put({
      type: ArticleActions.DeleteArticles.Success,
      payload: action.payload,
    });
    toast.success("Artykuły zostały usunięte");
  } catch (err) {
    yield put({
      type: ArticleActions.DeleteArticles.Failure,
    });
    createErrorToast(err, "Wystąpił błąd w trakcie usuwania artykułów");
  }
}

function* confirmArticles(action) {
  try {
    yield call(confirmArticlesRequest, action.payload);

    yield put({
      type: ArticleActions.ConfirmArticles.Success,
      payload: action.payload,
    });
    toast.success("Artykuły zostały potwierdzone");
  } catch (err) {
    yield put({
      type: ArticleActions.ConfirmArticles.Failure,
    });
    createErrorToast(err, "Wystąpił błąd w trakcie potwierdzania artykułów");
  }
}

function* publishArticles(action) {
  try {
    yield call(publishArticlesRequest, action.payload);

    yield put({
      type: ArticleActions.PublishArticles.Success,
      payload: action.payload,
    });
    toast.success("Artykuły zostały opublikowane");
  } catch (err) {
    yield put({
      type: ArticleActions.PublishArticles.Failure,
    });
    createErrorToast(err, "Wystąpił błąd w trakcie publikowania artykułów");
  }
}

function deleteArticlesRequest(articleIds) {
  const filterQuery = qs.stringify(
    { articleIds: articleIds },
    { addQueryPrefix: true, allowDots: true }
  );
  return axios
    .delete(`${ARTICLE_URL}${filterQuery}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

function confirmArticlesRequest(articleIds) {
  return axios
    .put(`${ARTICLE_URL}/status`, {
      articleIds: articleIds,
      newStatus: ArticleStatus.Confirmed.value,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

function publishArticlesRequest(props) {
  return axios
    .put(`${ARTICLE_URL}/status`, {
      articleIds: props.ids || [],
      newStatus: ArticleStatus.ToPublish.value,
      ...props.values,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

function* getArticleHash(action) {
  try {
    const data = yield call(getArticleHashRequest, action.payload);

    yield put({
      type: ArticleActions.GetArticleHash.Success,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ArticleActions.GetArticleHash.Failure,
    });

    createErrorToast(
      err,
      "Wystąpił błąd w trakcie generowania podglądu artykułu"
    );
  }
}

function getArticleHashRequest(payload) {
  const { id, webAppAddress, languageCode } = payload;

  return axios.post(`${ARTICLE_URL}/hash/${id}`).then((res) => {
    const url = `${webAppAddress}/podglad/artykuly/${res.data.hash}/?lang=${languageCode}`;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  });
}

function* watchGetAllArticles() {
  yield takeEvery(ArticleActions.GetAllArticles.Request, getAllArticles);
}

function* watchGetArticle() {
  yield takeEvery(ArticleActions.GetArticle.Request, getArticle);
}

function* watchUpdateArticle() {
  yield takeEvery(ArticleActions.UpdateArticle.Request, updateArticle);
}

function* watchSaveArticle() {
  yield takeEvery(ArticleActions.SaveArticle.Request, saveArticle);
}

function* watchClearArticle() {
  yield takeEvery(ArticleActions.ClearArticle.Request, clearArticle);
}

function* watchCopyArticleToNewArticleTranslation() {
  yield takeEvery(
    ArticleActions.CopyArticleToNewArticleTranslation.Request,
    copyArticleToNewArticleTranslation
  );
}

function* watchChangeArticleStatus() {
  yield takeEvery(
    ArticleActions.ChangeArticleTranslationStatus.Request,
    changeArticleStatus
  );
}

function* watchCreateArticleNewVersion() {
  yield takeEvery(
    ArticleActions.CreateArticleNewVersion.Request,
    createArticleNewVersion
  );
}
function* watchDeleteArticles() {
  yield takeEvery(ArticleActions.DeleteArticles.Request, deleteArticles);
}

function* watchConfirmArticles() {
  yield takeEvery(ArticleActions.ConfirmArticles.Request, confirmArticles);
}

function* watchPublishArticles() {
  yield takeEvery(ArticleActions.PublishArticles.Request, publishArticles);
}

function* watchGetArticleHash() {
  yield takeEvery(ArticleActions.GetArticleHash.Request, getArticleHash);
}

export const ArticleSaga = [
  fork(watchGetAllArticles),
  fork(watchGetArticle),
  fork(watchUpdateArticle),
  fork(watchSaveArticle),
  fork(watchClearArticle),
  fork(watchCopyArticleToNewArticleTranslation),
  fork(watchChangeArticleStatus),
  fork(watchCreateArticleNewVersion),
  fork(watchDeleteArticles),
  fork(watchConfirmArticles),
  fork(watchPublishArticles),
  fork(watchGetArticleHash),
];
