/**
 * skopiowane z https://github.com/frontend-collective/react-sortable-tree z pliku /src/node-renderer-default.js
 **/
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { isDescendant } from "../../utils/tree-data-utils";
import classnames from "../../utils/classnames";
import isEqual from "lodash.isequal";
import map from "lodash.map";
import Immutable from "seamless-immutable";
import filter from "lodash.filter";
import { DefaultLanguage } from "@helpers/enums";

import { addCategories } from "@components/utils";

class CategorySource extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: Immutable(props.items),
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.items, this.props.items)) {
      this.setState({
        items: [...this.props.items],
      });
    }
  }

  toggleNode = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.toggleNode && typeof this.props.toggleNode === "function") {
      this.props.toggleNode(this.props.path);
    }
  };
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      displayText,
      subtitle,
      languages,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      buttons,
      className,
      style,
      didDrop,
      treeId,
      isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      rowDirection,
      addItem,
      max,
      type,
      ...otherProps
    } = this.props;
    const nodeTitle = displayText || node.displayText;
    const nodeLanguages = languages || node.languages;
    const rowDirectionClass = rowDirection === "rtl" ? "rst__rtl" : null;
    const { items } = this.state;

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
    if (rowDirection === "rtl") {
      buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
    }

    const menuElement = (
      <div
        key={`menu-element-${node.id}`}
        style={{ height: "100%" }}
        {...otherProps}
        className={isEqual(otherProps.selectedPath, path) ? "rst--toggle" : ""}
        onClick={this.toggleNode}
      >
        {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === "function") && (
            <div>
              <button
                type="button"
                aria-label={node.expanded ? "Collapse" : "Expand"}
                className={classnames(
                  node.expanded ? "rst__collapseButton" : "rst__expandButton",
                  rowDirectionClass
                )}
                style={buttonStyle}
                onClick={() =>
                  toggleChildrenVisibility({
                    node,
                    path,
                    treeIndex,
                  })
                }
              />

              {node.expanded && !isDragging && (
                <div
                  style={{ width: scaffoldBlockPxWidth }}
                  className={classnames("rst__lineChildren", rowDirectionClass)}
                />
              )}
            </div>
          )}

        <div className={classnames("rst__rowWrapper", rowDirectionClass)}>
          {/* Set the row preview to be used during drag and drop */}
          {connectDragPreview(
            <div
              className={classnames(
                "rst__row",
                isLandingPadActive && "rst__rowLandingPad",
                isLandingPadActive && !canDrop && "rst__rowCancelPad",
                isSearchMatch && "rst__rowSearchMatch",
                isSearchFocus && "rst__rowSearchFocus",
                rowDirectionClass,
                className
              )}
              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
                ...style,
              }}
            >
              <div
                className={classnames(
                  `rst__moveHandle ${
                    isDragging ? "rst__moveHandle--grabbing" : ""
                  }`,
                  !canDrag && "rst__moveHandleDisabled"
                )}
                style={!canDrag ? { cursor: "auto" } : null}
                onDoubleClick={() =>
                  !canDrag
                    ? () => {}
                    : addCategories(type, max, node, items, addItem)
                }
              />

              <div
                className={classnames("rst__rowContents", rowDirectionClass)}
              >
                <div className={classnames("rst__rowLabel", rowDirectionClass)}>
                  <span>
                    {typeof nodeTitle === "function"
                      ? nodeTitle({
                          node,
                          path,
                          treeIndex,
                        })
                      : nodeTitle}
                  </span>
                </div>

                <div className="rst__rowToolbar">
                  <div className="rst__buttons mr-1">
                    {buttons.map((btn, index) => (
                      <div
                        key={`btn-${index}`} // eslint-disable-line react/no-array-index-key
                        className="rst__toolbarButton"
                      >
                        {btn}
                      </div>
                    ))}
                  </div>
                  <div className="rst__languages">
                    {nodeLanguages && (
                      <Fragment>
                        {typeof nodeLanguages === "function" &&
                          nodeLanguages({
                            node,
                            path,
                            treeIndex,
                          })}
                        {nodeLanguages.length <= 2 &&
                          map(nodeLanguages, (language, index) => (
                            <div
                              key={`lang-${index}`}
                              className="badge badge-focus mb-1"
                            >
                              {language.languageIsoCode}
                            </div>
                          ))}
                        {nodeLanguages.length > 2 && (
                          <Fragment>
                            <div className="badge badge-focus mb-1">PL</div>
                            <UncontrolledButtonDropdown>
                              <DropdownToggle
                                color="focus"
                                className="badge badge-focus br-tr br-br"
                              >
                                ...
                              </DropdownToggle>
                              <DropdownMenu>
                                {map(
                                  filter(
                                    nodeLanguages,
                                    (language) =>
                                      language.languageIsoCode !==
                                      DefaultLanguage.isoCode
                                  ),
                                  (language, index) => (
                                    <DropdownItem key={`lang-${index}`}>
                                      <div className="badge badge-focus mb-1">
                                        {language.languageIsoCode}
                                      </div>
                                    </DropdownItem>
                                  )
                                )}
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );

    return canDrag
      ? connectDragSource(menuElement, { dropEffect: "copy" })
      : menuElement;
  }
}

CategorySource.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: "",
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  displayText: null,
  rowDirection: "ltr",
};

CategorySource.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  displayText: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
};

export default CategorySource;
