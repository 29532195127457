import { ContainsIcon, NotContainsIcon, StartsWithIcon, EndsWithIcon, EqualIcon, NotEqualIcon, GreaterThanIcon, GreaterThanOrEqualIcon, LessThanIcon, LessThanOrEqualIcon } from '../../helpers/icon-constants';

export const getMessage = (prop) => {
    const resources = {
        filterPlaceholder: 'Filtruj..',
        contains: 'Zawiera',
        notContains: 'Nie zawiera',
        startsWith: 'Rozpoczyna od',
        endsWith: 'Kończy na',
        equal: 'Równe',
        notEqual: 'Różne',
        greaterThan: 'Większe',
        greaterThanOrEqual: 'Większe lub równe',
        lessThan: 'Mniejsze',
        lessThanOrEqual: 'Mniejsze lub równe',
        date: 'Dnia'
    };

    return resources[prop] || '';
}

export const Icon = ({ type }) => {
    switch (type) {
        case 'contains': {
            return ContainsIcon;
        }
        case 'notContains': {
            return NotContainsIcon;
        }
        case 'startsWith': {
            return StartsWithIcon;
        }
        case 'endsWith': {
            return EndsWithIcon;
        }
        case 'equal': {
            return EqualIcon;
        }
        case 'notEqual': {
            return NotEqualIcon;
        }
        case 'greaterThan': {
            return GreaterThanIcon;
        }
        case 'greaterThanOrEqual': {
            return GreaterThanOrEqualIcon;
        }
        case 'lessThan': {
            return LessThanIcon;
        }
        case 'lessThanOrEqual': {
            return LessThanOrEqualIcon;
        }
        default: {
            return ContainsIcon;
        }
    }
}