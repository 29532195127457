import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RouteUrls } from '../../helpers/routeUrls';

import ApplicationImages from './ApplicationImages';
import Banners from './Banners';
import Bookmarks from './Bookmarks';
import Footers from './Footers';
import Headers from './Headers';
import Languages from './Languages';
import RssChannels from './RssChannels';
import SystemParameters from './SystemParameters';
import Tiles from './Tiles';
import Offices from './Offices';
import Repositories from './Repositories';
import { useSelector } from 'react-redux';
import { getLayoutPermissions } from '../../reselect/permission';

const Settings = ({ match }) => {
	const permissions = useSelector((state) => getLayoutPermissions(state));

	return (
		<Fragment>
			{permissions.accessToSettings ? (
				<Switch>
					<Route path={RouteUrls.Setting.appImages} component={ApplicationImages} />
					<Route path={RouteUrls.Setting.banners.list} component={Banners} />
					<Route path={RouteUrls.Setting.bookmark.list} component={Bookmarks} />
					<Route path={RouteUrls.Setting.footer.list} component={Footers} />
					<Route path={RouteUrls.Setting.header.list} component={Headers} />
					<Route path={RouteUrls.Setting.language.list} component={Languages} />
					<Route path={RouteUrls.Setting.parameter} component={SystemParameters} />
					<Route path={RouteUrls.Setting.rssChannels.list} component={RssChannels} />
					<Route path={RouteUrls.Setting.tiles.list} component={Tiles} />
					<Route path={RouteUrls.Setting.offices.main} component={Offices} />
					<Route path={RouteUrls.Setting.repository.list} component={Repositories} />
				</Switch>
			) : (
				<Redirect to={RouteUrls.App.main} />
			)}
		</Fragment>
	);
};

export default Settings;
