import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { Formik } from 'formik';
import Immutable from 'seamless-immutable';

import { Form, FormGroup, FormFeedback, Row, Col, Card, CardBody, CardHeader, Label, Input, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import Sticky from 'react-stickynode';
import { Typeahead } from 'react-bootstrap-typeahead';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { RoleActionCreators } from '../../../../actions/auth/role';
import { AuthActionCreators } from '../../../../actions/auth';
import { DefaultFormikConfig, LoaderType } from '../../../../helpers/enums';
import { RoleValidationSchema } from '../../../../validators/role';

import { CONSTANTS } from '@constants';
import isEqual from 'lodash.isequal';

const { SAVE, BACK, ROLE_DESCRIPTION, COMMON_INFORMATION, PERMISSION_LIST, ROLE_NAME } = CONSTANTS;

class RoleCard extends Component {
	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getRole(id);
		}
	}

	componentDidUpdate(prevProps) {
		const { id: prevId } = prevProps.match.params;
		const { id } = this.props.match.params;
		if (prevId && id && prevId !== id) {
			this.props.getRole(id);
		}
	}

	shouldComponentUpdate(prevProps) {
		return !isEqual(prevProps.role, this.props.role);
	}

	componentWillUnmount() {
		this.props.unloadAuth();
	}

	render() {
		const { role, createRole, updateRole, roleCardBlocking, availablePermissions, cancel } = this.props;
		const newRole = Immutable(role).asMutable({ deep: true });

		return (
			<Formik
				{...DefaultFormikConfig}
				initialValues={newRole}
				validationSchema={RoleValidationSchema}
				onSubmit={(values) => {
					if (values.id > 0) {
						updateRole(values);
					} else {
						createRole(values);
					}
				}}
				onReset={cancel}
			>
				{({ errors, values, handleChange, isValid, dirty, setFieldValue, handleSubmit, handleReset }) => (
					<Fragment>
						<PageTitle
							heading="Rola"
							subheading="Ustawienia roli"
							icon="pe-7s-plugin icon-gradient bg-tempting-azure"
						/>
						<BlockUi tag="div" blocking={roleCardBlocking} loader={<Loader active type={LoaderType} />}>
							<Form noValidate method="post" onSubmit={handleSubmit} onReset={handleReset}>
								<Sticky
									enabled={true}
									top=".app-header"
									innerZ="101"
									activeClass="sticky-active-class"
									className="mb-2"
								>
									<div className="d-flex justify-content-end">
										<Button
											className="btn-icon mr-2"
											color="primary"
											type="submit"
											disabled={!isValid || !dirty || values.isSystem}
										>
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{SAVE}
										</Button>
										<Button className="btn-icon mr-2" color="secondary" type="reset">
											<i className="pe-7s-back btn-icon-wrapper" />
											{BACK}
										</Button>
									</div>
								</Sticky>
								<Fragment>
									<Card className="mb-3">
										<CardHeader>
											<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
											{COMMON_INFORMATION}
										</CardHeader>
										<CardBody>
											<Row>
												<Col md={6}>
													<FormGroup>
														<Label for="name">{ROLE_NAME}</Label>
														<Input
															className="form-control"
															type="text"
															id="name"
															name="name"
															placeholder="Wpisz nazwę"
															value={values.name}
															onChange={handleChange}
															invalid={!!errors.name}
															disabled={values.isSystem}
														/>
														<FormFeedback>{errors.name}</FormFeedback>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col>
													<FormGroup>
														<Label for="description">{ROLE_DESCRIPTION}</Label>
														<Input
															className="form-control"
															type="textarea"
															id="description"
															name="description"
															placeholder="Podaj opis roli"
															value={values.description}
															onChange={handleChange}
															invalid={!!errors.description}
															disabled={values.isSystem}
														/>
														<FormFeedback>{errors.description}</FormFeedback>
													</FormGroup>
												</Col>
											</Row>
										</CardBody>
									</Card>
									<Card className="mb-3">
										<CardHeader>
											<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
											{PERMISSION_LIST}
										</CardHeader>
										<CardBody>
											<Row>
												<Col>
													<Typeahead
														id="permissions"
														labelKey="displayName"
														options={availablePermissions}
														selected={values.permissions}
														placeholder="Wybierz uprawnienia"
														emptyLabel={'Brak uprawnień'}
														clearButton
														multiple
														onChange={(permissions) =>
															setFieldValue('permissions', permissions)}
													/>
													{errors.permissions && (
														<FormFeedback style={{ display: 'block' }}>
															{errors.permissions}
														</FormFeedback>
													)}
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Fragment>
							</Form>
						</BlockUi>
					</Fragment>
				)}
			</Formik>
		);
	}
}

const mapStateToProps = (state, ownProp) => {
	return {
		role: state.auth.role || {
			id: 0,
			name: '',
			description: '',
			isSystem: false,
			permissions: []
		},
		roleCardBlocking: state.uiBlockState.roleCardBlocking || false,
		availablePermissions: state.application.permissions || []
	};
};

const mapDispatchToProps = (dispatch, ownProp) => {
	return {
		...bindActionCreators({ ...RoleActionCreators, ...AuthActionCreators }, dispatch),
		cancel: () => dispatch(goBack()),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleCard);
