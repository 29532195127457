import isEmpty from 'lodash.isempty';
import { Sex } from '../helpers/enums';

export const getBirthDate = (pesel) => {
	var year = parseInt(pesel.substr(0, 2));
	var month = parseInt(pesel.substr(2, 2)) - 1; // javascript 0 - january
	var day = parseInt(pesel.substr(4, 2));

	if (month >= 81 && month <= 92) {
		return new Date(1800 + year, month - 80, day);
	} else if (month >= 21 && month <= 32) {
		return new Date(2000 + year, month - 20, day);
	} else if (month >= 41 && month <= 52) {
		return new Date(2100 + year, month - 40, day);
	} else if (month >= 61 && month <= 72) {
		return new Date(2200 + year, month - 60, day);
	} else {
		return new Date(1900 + year, month, day);
	}
};

export const getSex = (pesel) => {
	if (!isEmpty(pesel) && pesel.length === 11) {
		return pesel[9] % 2 === 1 ? Sex.Male.value : Sex.Female.value;
	}
	return Sex.Unknown.value;
};
