import React, { Component, Fragment } from "react";

import { MenuList } from "./Menu/AppMenuItems";
import { RouteUrls } from "../../helpers/routeUrls";
import { ArticleStatus } from "../../helpers/enums";

const Nav = ({ permissions }) => {
  const NAV_MENU = [
    {
      id: 1,
      title: "Zarządzanie menu",
      items: [
        [
          {
            id: "menu-1",
            label: "Zarządzanie menu",
            icon: "pe-7s-menu",
            permissions: [permissions.accessToMenus],
            children: [
              {
                id: 1,
                label: "Menu poziome",
                to: RouteUrls.Menu.horizontal,
                permissions: [permissions.accessToMenus],
                children: [],
              },
              {
                id: 1,
                label: "Menu pionowe",
                to: RouteUrls.Menu.vertical,
                permissions: [permissions.accessToMenus],
                children: [],
              },
            ],
          },
        ],
        [],
      ],
    },
    {
      id: 2,
      title: "artykuły",
      items: [
        [
          {
            id: "artykuly-1",
            label: "Artykuły",
            icon: "pe-7s-copy-file",
            permissions: [permissions.accessToArticles],
            children: [
              {
                id: "artykuly-wszystkie",
                label: "Wszystkie",
                to: RouteUrls.Article.common.list,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykuly-robocze",
                label: "Robocze",
                to: `${RouteUrls.Article.common.draft.list}?status=${ArticleStatus.Draft.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykuly-zatwierdzone",
                label: "Zatwierdzone",
                to: `${RouteUrls.Article.common.confirmed.list}?status=${ArticleStatus.Confirmed.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykuly-do-publikacji",
                label: "Do publikacji",
                to: `${RouteUrls.Article.common.topublish.list}?status=${ArticleStatus.ToPublish.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykuly-opublikowane",
                label: "Opublikowane",
                to: `${RouteUrls.Article.common.published.list}?status=${ArticleStatus.Published.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykuly-wycofane",
                label: "Wycofane",
                to: `${RouteUrls.Article.common.withdrawed.list}?status=${ArticleStatus.Withdrawed.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykuly-zarchiwizowane",
                label: "Zarchiwizowane",
                to: `${RouteUrls.Article.common.archived.list}?status=${ArticleStatus.Archived.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
            ],
          },
          {
            id: "artykulysystemowe-1",
            label: "Artykuły systemowe",
            icon: "pe-7s-albums",
            permissions: [permissions.accessToArticles],
            children: [
              {
                id: "artykulysystemowe-wszystkie",
                label: "Wszystkie",
                to: RouteUrls.Article.system.list,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykulysystemowe-robocze",
                label: "Robocze",
                to: `${RouteUrls.Article.system.draft.list}?status=${ArticleStatus.Draft.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykulysystemowe-zatwierdzone",
                label: "Zatwierdzone",
                to: `${RouteUrls.Article.system.confirmed.list}?status=${ArticleStatus.Confirmed.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykulysystemowe-do-publikacji",
                label: "Do publikacji",
                to: `${RouteUrls.Article.system.topublish.list}?status=${ArticleStatus.ToPublish.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykulysystemowe-opublikowane",
                label: "Opublikowane",
                to: `${RouteUrls.Article.system.published.list}?status=${ArticleStatus.Published.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykulysystemowe-wycofane",
                label: "Wycofane",
                to: `${RouteUrls.Article.system.withdrawed.list}?status=${ArticleStatus.Withdrawed.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
              {
                id: "artykulysystemowe-zarchiwizowane",
                label: "Zarchiwizowane",
                to: `${RouteUrls.Article.system.archived.list}?status=${ArticleStatus.Archived.value}`,
                permissions: [permissions.accessToArticles],
                children: [],
              },
            ],
          },
        ],
      ],
    },
    {
      id: 3,
      title: "Zarządzanie użytkownikami",
      items: [
        [
          {
            id: "uzytkownicy-1",
            label: "Użytkownicy",
            icon: "pe-7s-users",
            to: RouteUrls.Auth.user.list,
            permissions: [permissions.accessToUsers],
            children: [],
          },
        ],
        [
          {
            id: "role-1",
            label: "Role",
            icon: "pe-7s-plugin",
            to: RouteUrls.Auth.role.list,
            permissions: [permissions.accessToRoles],
            children: [],
          },
        ],
      ],
    },
    {
      id: 4,
      title: "Słowniki",
      items: [
        [
          {
            id: "słowniki-1",
            icon: "pe-7s-bookmarks",
            label: "Słowniki",
            permissions: [permissions.accessToDictionaries],
            children: [
              {
                id: "slowniki-podmioty-udostepniajace",
                label: "Podmioty udostępniające",
                to: RouteUrls.Dictionary.dictionaryEntry.list,
                permissions: [permissions.accessToDictionaries],
                children: [],
              },
              {
                id: "slowniki-tagi",
                label: "Tagi",
                to: RouteUrls.Dictionary.tag.list,
                permissions: [permissions.accessToDictionaries],
                children: [],
              },
              {
                id: "slowniki-kody-iso-jezyka",
                label: "Kody ISO języka",
                to: RouteUrls.Dictionary.isoCode.list,
                permissions: [permissions.accessToDictionaries],
                children: [],
              },
            ],
          },
        ],
      ],
    },
    {
      id: 5,
      title: "Ustawienia",
      items: [
        [
          {
            id: "ustawienia-1",
            icon: "pe-7s-settings",
            label: "Ustawienia",
            permissions: [permissions.accessToSettings],
            children: [
              {
                id: "ustawienia-jednostki",
                label: "Jednostka",
                permissions: [permissions.accessToSettings],
                children: [
                  {
                    id: "ustawienia-dane-jednostki",
                    label: "Dane jednostki",
                    to: RouteUrls.Setting.offices.office.edit,
                    permissions: [permissions.accessToSettings],
                    children: [],
                  },
                  {
                    id: "ustawienia-kierownictwo-jednostki",
                    label: "Kierownictwo jednostki",
                    to: RouteUrls.Setting.offices.officeManagers.list,
                    permissions: [permissions.accessToSettings],
                    children: [],
                  },
                ],
              },
              {
                id: "ustawienia-wersje-jezykowe",
                label: "Wersje językowe",
                to: RouteUrls.Setting.language.list,
                permissions: [permissions.accessToSettings],
                children: [],
              },
              {
                id: "ustawienia-kafle",
                label: "Kafle",
                to: RouteUrls.Setting.tiles.list,
                permissions: [permissions.accessToSettings],
                children: [],
              },
              {
                id: "ustawienia-zakladki",
                label: "Zakładki",
                to: RouteUrls.Setting.bookmark.list,
                permissions: [permissions.accessToSettings],
                children: [],
              },
              {
                id: "ustawienia-naglowek",
                label: "Nagłówek",
                to: RouteUrls.Setting.header.list,
                permissions: [permissions.accessToSettings],
                children: [],
              },
              {
                id: "ustawienia-stopka",
                label: "Stopka",
                to: RouteUrls.Setting.footer.list,
                permissions: [permissions.accessToSettings],
                children: [],
              },
              {
                id: "ustawienia-banery",
                label: "Banery",
                to: RouteUrls.Setting.banners.list,
                permissions: [permissions.accessToSettings],
                children: [],
              },
              {
                id: "ustawienia-grafiki",
                label: "Grafiki",
                to: RouteUrls.Setting.appImages,
                permissions: [permissions.accessToSettings],
                children: [],
              },
              {
                id: "ustawienia-grupy-plikow",
                label: "Grupy plików",
                to: RouteUrls.Setting.repository.list,
                permissions: [permissions.accessToSettings],
                children: [],
              },
              {
                id: "ustawienia-kanaly-rss",
                label: "Kanały RSS",
                to: RouteUrls.Setting.rssChannels.list,
                permissions: [permissions.accessToSettings],
                children: [],
              },
              {
                id: "ustawienia-parametry-systemowe",
                label: "Parametry systemowe",
                to: RouteUrls.Setting.parameter,
                permissions: [permissions.accessToSettings],
                children: [],
              },
            ],
          },
        ],
      ],
    },
    {
      id: 6,
      title: "Bip - wersja archiwalna",
      items: [
        [
          {
            id: "ustawienia-1",
            icon: "pe-7s-box1",
            label: "BIP - wersja archiwalna",
            permissions: [permissions.accessToSettings],
            children: [
              {
                id: "ustawienia-kafle",
                label: "Zarządzanie",
                to: `${RouteUrls.ArchiveBIP.manage}`,
                permissions: [],
                children: [],
              },
            ],
          },
        ],
      ],
    },
  ];

  return (
    <Fragment>
      {NAV_MENU.map((navGroup) => (
        <Fragment key={navGroup.id}>
          <h5 className="app-sidebar__heading">{navGroup.title}</h5>
          <div className="metismenu vertical-nav-menu">
            {navGroup.items.map((itemGroup, index) => (
              <div key={index}>
                <MenuList
                  {...{ items: itemGroup, isRoot: true, isOpen: true }}
                />
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default Nav;
