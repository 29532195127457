import * as yup from 'yup';
import { LinkTargetType, LinkType } from '../helpers/enums';
import isEmpty from 'lodash.isempty';

export const TileValidationSchema = yup.object().shape({
	displayText: yup.string().trim()
		.max(40, 'Maksymalna długość nazwy wyświetlanej nie może przekroczyć 40 znaków')
		.required('Nazwa wyświetlana jest polem wymaganym'),
	title: yup.string().trim().required('Tekst pod czytniki dla osób słabowidzących jest polem wymaganym'),
	target: yup
		.number()
		.oneOf(
			[ LinkTargetType.Self.value, LinkTargetType.NewTab.value ],
			`Otwórz zakładkę w nowym oknie musi zawierać wartość: ${LinkTargetType.Self.name} lub ${LinkTargetType
				.NewTab.name}`
		),
	isActive: yup.bool().test('Any array has any item', 'Zakładka nie wskazuje żadnego tagu', function(isActive) {
		if (!isActive) {
			return true;
		}

		return !isEmpty(this.parent.tags);
	}),
	url: yup.string().when('linkType', {
		is: (linkType) => [ LinkType.External.value ].includes(linkType),
		then: yup
			.string()
			.trim()
			.required(`Adres URL jest polem wymaganym w przypadku typu źródła ${LinkType.External.name}`)
	}),
	file: yup.object().nullable(),
	iconName: yup.string().when(['file'], {
		is: file => !file,
		then: yup.string().trim().required('Nazwa ikony jest polem wymaganym')
	}),
	linkType: yup
		.number()
		.oneOf(
			[ LinkType.Article.value, LinkType.Category.value, LinkType.External.value ],
			`Typ źródła kafla musi zawierać wartość: ${LinkType.Article.name}, ${LinkType.Category.name} lub ${LinkType
				.External.name}`
		)
});
