import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import TileCard from "./Card";
import TileList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const Bookmarks = ({ match }) => (
  <Fragment>
    <Switch>
      <Route path={RouteUrls.Setting.tiles.create} component={TileCard} />
      <Route
        path={`${RouteUrls.Setting.tiles.edit}/:id`}
        component={TileCard}
      />
      <Route path="*" component={TileList} />
    </Switch>
  </Fragment>
);

export default Bookmarks;
