import { createSelector } from 'reselect';

const repositoryConfigs = (store) => store.setting.repositoryConfigs || [];

export const getUsedExtensions = createSelector(
	[ repositoryConfigs ],
    (repositoryConfigs) => {
        let usedExtensions = [];

        (repositoryConfigs || []).forEach(el => {
            usedExtensions = usedExtensions.concat(el.extensions);
        });

        return usedExtensions;
    }
);
