import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import UserCard from "./Card";
import UserList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const Users = () => (
  <Fragment>
    <Switch>
      <Route path={RouteUrls.Auth.user.create} component={UserCard} />
      <Route path={`${RouteUrls.Auth.user.edit}/:id`} component={UserCard} />
      <Route path="*" component={UserList} />
    </Switch>
  </Fragment>
);

export default Users;
