import React from 'react';
import Grid from '../../../../components/Grid';

const columns = [
	{ name: 'id', title: 'id' },
	{ name: 'name', title: 'Nazwa podmiotu udostępniającego' },
	{ name: 'languageIsoCode', title: 'Kod ISO języka' }
];
const columnExtensions = [ { columnName: 'name', wordWrapEnabled: true } ];
const hiddenColumns = [ 'id' ];
const textColumns = [ 'name' ];

export const DictionaryEntryList = ({ ...props }) => (
	<Grid
		items={props.items}
		totalItems={props.totalItems}
		columns={columns}
		columnExtensions={columnExtensions}
		hiddenColumns={hiddenColumns}
		textColumns={textColumns}
		onReload={props.onReload}
		onRemove={props.onRemove}
		onAdd={props.onAdd}
		onRowClick={props.onRowClick}
		internationalizable={props.internationalizable}
		language={props.language}
		languages={props.languages}
		onChangeLanguage={props.onChangeLanguage}
		filters={props.filters}
		onChangeFilters={props.onChangeFilters}
		onClearFilters={props.onClearFilters}
	/>
);
