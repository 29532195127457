import * as yup from 'yup';
import isEmpty from 'lodash.isempty';
//import { AVAILABLE_ISO_CODE } from '../helpers/isoCode';

export const LanguageValidationSchema = yup.object().shape({
	name: yup.string().required('Nazwa języka jest wymagana').max(50, 'Nazwa może mieć maksymalnie 50 znaków'),
	localName: yup
		.string()
		.required('Nazwa lokalna języka jest wymagana')
		.max(50, 'Nazwa lokala może mieć maksymalnie 50 znaków'),
	dateFormat: yup
		.string()
		.required('Format daty jest wymagany')
		.max(10, 'Format daty może mieć maksymalnie 10 znaków')
		.matches(/^(d{2}|M{2}|y{4})(\/{1}|\.{1}|\-{1})(d{2}|M{2}|y{4})(\/{1}|\.{1}|\-{1})(d{2}|M{2}|y{4})$/, {
			message:
				'Format daty musi zawierać znaki d (dzień), M (miesiąc), y (rok) oraz znak separatora ("/","." lub "-")'
		}),
	isoCode: yup
		.string()
		.required('Kod ISO języka jest wymagany')
		//.oneOf(AVAILABLE_ISO_CODE, 'Nieprawidłowy kod ISO')
		.max(6, 'Kod ISO może mieć maksymalnie 6 znaki'),
	standardElementsTranslations: yup.object().shape({
		items: yup.array().of(
			yup.object().shape({
				isTranslationRequired: yup.bool(),
				isTranslationDescriptionRequired: yup.bool(),
				translation: yup
					.string()
					.when(
						[ 'isTranslationRequired' ],
						(isTranslationRequired) =>
							isTranslationRequired
								? yup
										.string()
										.trim()
										.max(100, 'Nazwa wyświetlana może mieć maksymalnie 100 znaków')
										.required('Nazwa wyświetlana jest wymagana')
								: yup.string().max(100, 'Nazwa wyświetlana może mieć maksymalnie 100 znaków')
					),
				translationDescription: yup.string().when([ 'isTranslationDescriptionRequired' ], {
					is: (isTranslationDescriptionRequired) => isTranslationDescriptionRequired,
					then: yup
						.string()
						.trim()
						.max(100, 'Tekst pod czytniki dla osób słabowidzących może mieć maksymalnie 100 znaków')
						.required('Tekst pod czytniki dla osób słabowidzących jest wymagany'),
					otherwise: yup
						.string()
						.trim()
						.max(100, 'Tekst pod czytniki dla osób słabowidzących może mieć maksymalnie 100 znaków')
				})
			})
		)
	}),
	menuElementsTranslations: yup.object().shape({
		items: yup.array().of(
			yup.object().shape({
				baseUrl: yup.string(),
				translatedDisplayText: yup.string().trim().required('Nazwa wyświetlana jest wymagana'),
				translatedTitle: yup
					.string()
					.trim()
					.required('Tekst pod czytniki dla osób słabowidzących jest wymagany'),
				translatedUrl: yup.string().when([ 'baseUrl' ], {
					is: (baseUrl) => !isEmpty(baseUrl),
					then: yup
						.string()
						.trim()
						.required('Adres URL jest wymagany')
						.url(
							'Adres URL musi zawierać prawidłowy format adresu. Musi składać się protokołu, adresu oraz domeny'
						).nullable(),
					otherwise: yup.string().matches(/''/, 'Adres URL musi być pusty').nullable()
				})
			})
		)
	}),
	standardElementsChanged: yup.array().of(
		yup.object().shape({
			isTranslationRequired: yup.bool(),
			isTranslationDescriptionRequired: yup.bool(),
			translation: yup
				.string()
				.when(
					[ 'isTranslationRequired' ],
					(isTranslationRequired) =>
						isTranslationRequired
							? yup
									.string()
									.trim()
									.max(100, 'Nazwa wyświetlana może mieć maksymalnie 100 znaków')
									.required('Nazwa wyświetlana jest wymagana')
							: yup.string().max(100, 'Nazwa wyświetlana może mieć maksymalnie 100 znaków')
				),
			translationDescription: yup.string().when([ 'isTranslationDescriptionRequired' ], {
				is: (isTranslationDescriptionRequired) => isTranslationDescriptionRequired,
				then: yup
					.string()
					.trim()
					.max(100, 'Tekst pod czytniki dla osób słabowidzących może mieć maksymalnie 100 znaków')
					.required('Tekst pod czytniki dla osób słabowidzących jest wymagany'),
				otherwise: yup
					.string()
					.trim()
					.max(100, 'Tekst pod czytniki dla osób słabowidzących może mieć maksymalnie 100 znaków')
			})
		})
	),
	menuElementsChanged: yup.array().of(
		yup.object().shape({
			baseUrl: yup.string(),
			translatedDisplayText: yup.string().trim().required('Nazwa wyświetlana jest wymagana'),
			translatedTitle: yup.string().trim().required('Tekst pod czytniki dla osób słabowidzących jest wymagany'),
			translatedUrl: yup.string().when([ 'baseUrl' ], {
				is: (baseUrl) => !isEmpty(baseUrl),
				then: yup
					.string()
					.trim()
					.required('Adres URL jest wymagany')
					.url(
						'Adres URL musi zawierać prawidłowy format adresu. Musi składać się protokołu, adresu oraz domeny'
					),
				otherwise: yup.string().matches(/''/, 'Adres URL musi być pusty')
			})
		})
	)
});
