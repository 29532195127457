import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { Formik, Field } from "formik";
import {
  Form,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Label,
  Input,
  InputGroup,
  Button,
} from "reactstrap";
import BlockUi from "react-block-ui";
import Sticky from "react-stickynode";
import { Loader } from "react-loaders";
import { SketchPicker } from "react-color";

import PageTitle from "@layout/AppMain/PageTitle";
import UploadFile from "@components/UploadFile";
import { SettingActionCreators } from "@actions/settings";
import {
  LoaderType,
  DefaultLanguage,
  LinkTargetType,
  ImageMimeType,
} from "@helpers/enums";
import { fileUploadError } from "@helpers/error-message";
import { getActiveLanguagesSelector } from "@reselect/language";
import { BannerValidationSchema } from "@validators";
import { Switch } from "../../Shared/Switch";

import {
  checkedContrast,
  checkedUrl,
  handleImmutable,
  popover,
  cover,
} from "../../utils";
import { CONSTANTS } from "@constants";
import {
  DefaultFormikConfig,
  FileExtensionTypes,
} from "../../../../helpers/enums";
import { CropImageUpload } from "../../../../components/CropImageUpload";
import isEqual from "lodash.isequal";

const {
  SAVE,
  BACK,
  NAME,
  URL,
  TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS,
  OPEN_BANNER_IN_NEW_WINDOW,
  ACTIVE,
  COLOR,
  WCAG_CONTRAST_REQUIRED,
  COMMON_INFORMATION,
  DISPLAY_NAME,
  COMPATIBILITY_WITH_APP_COLOR,
} = CONSTANTS;

const RectangleSize = { width: 300, height: 105 };
const DefaultCropData = {
  unit: "px",
  x: 0,
  y: 0,
  width: RectangleSize.width,
  height: RectangleSize.height,
};

class BannerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadedData: false,
      color: "",
      contrast: 0,
      banner: {},
      cropData: DefaultCropData,
    };
  }

  componentDidMount() {
    const {
      params: { id },
      path,
    } = this.props.match;

    this.handleLoadNewBanner(path);
    this.handleData();

    id && this.props.getBanner(id);
  }

  componentDidUpdate(prevProps) {
    const { id: prevId } = prevProps.match.params;
    const { id } = this.props.match.params;

    prevId && id && prevId !== id && this.props.getBanner(id);
    prevProps.banner.id !== this.props.banner.id && this.handleData();

    if (
      this.props.banner &&
      this.props.banner.file &&
      this.props.banner.file.cropData &&
      (!isEqual(prevProps.banner.file, this.props.banner.file) ||
        !prevProps.banner.file.cropData)
    ) {
      this.setCropData(this.props.banner.file.cropData);
    }
  }

  componentWillUnmount() {
    this.props.unloadSetting();
  }

  handleData = () => {
    const { activeLanguage, banner } = this.props;
    const { newElementData } = handleImmutable(banner, activeLanguage);
    const { contrast } = checkedContrast(
      newElementData.color,
      "#ffffff",
      "#433535"
    );

    this.setState({
      banner: { ...newElementData },
      isLoadedData: true,
      contrast,
    });
  };

  handleLoadNewBanner = (path) => {
    const { isCreate } = checkedUrl(path, "utworz-nowy");

    isCreate && this.setState({ isLoadedData: true });
  };

  handleFile = (file) =>
    this.setState({ banner: { ...this.state.banner, file } });

  handleTitle = (title) =>
    this.setState({ banner: { ...this.state.banner, title } });

  handleUrl = (url) => this.setState({ banner: { ...this.state.banner, url } });

  handleDisplayText = (displayText) =>
    this.setState({ banner: { ...this.state.banner, displayText } });

  handleName = (name) =>
    this.setState({ banner: { ...this.state.banner, name } });

  handleIsActive = (isActive) =>
    this.setState({ banner: { ...this.state.banner, isActive } });
  handleCompatibilityWithAppColor = (compatibilityWithAppColor) =>
    this.setState({
      banner: { ...this.state.banner, compatibilityWithAppColor },
    });

  handleTarget = (target) => {
    const result =
      target === LinkTargetType.NewTab.value
        ? LinkTargetType.Self.value
        : LinkTargetType.NewTab.value;

    this.setState({ banner: { ...this.state.banner, target: result } });
  };

  handleContrast = (color) => {
    const { contrast } = checkedContrast(color, "#ffffff", "#433535");

    this.setState({
      banner: {
        ...this.state.banner,
        color,
        contrast,
      },
      contrast,
    });
  };

  setCropData = (cropData) => {
    this.setState({ cropData });
  };

  render() {
    const {
      maxFileSize,
      createBanner,
      updateBanner,
      bannerCardBlocking,
      cancel,
    } = this.props;

    const { banner, isLoadedData, contrast: sContrast, cropData } = this.state;

    if (!isLoadedData) return <div>Ładowanie danych...</div>;

    return (
      <Formik
        {...DefaultFormikConfig}
        initialValues={banner}
        validationSchema={BannerValidationSchema}
        onSubmit={(values) => {
          if (values.id > 0) {
            updateBanner(values);
          } else {
            createBanner(values);
          }
          this.handleContrast(values.color);
          values.displayColorPicker = false;
        }}
        onReset={cancel}
      >
        {({
          errors,
          values,
          handleChange,
          isValid,
          setFieldValue,
          handleSubmit,
          handleReset,
        }) => (
          <Fragment>
            <PageTitle
              heading="Baner"
              icon="pe-7s-ribbon icon-gradient bg-tempting-azure"
            />
            <BlockUi
              tag="div"
              blocking={bannerCardBlocking}
              loader={<Loader active type={LoaderType} />}
            >
              <Form
                noValidate
                method="post"
                onSubmit={handleSubmit}
                onReset={handleReset}
              >
                <Sticky
                  enabled={true}
                  top=".app-header"
                  innerZ="101"
                  activeClass="sticky-active-class"
                  className="mb-2"
                >
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn-icon mr-2"
                      color="primary"
                      type="submit"
                      disabled={!isValid}
                    >
                      <i className="pe-7s-diskette btn-icon-wrapper" />
                      {SAVE}
                    </Button>
                    <Button
                      className="btn-icon mr-2"
                      color="secondary"
                      type="reset"
                    >
                      <i className="pe-7s-back btn-icon-wrapper" />
                      {BACK}
                    </Button>
                  </div>
                </Sticky>
                <Card className="mb-2">
                  <CardHeader>
                    <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
                    {COMMON_INFORMATION}
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={8}>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="name">{NAME}</Label>
                              <Input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Wpisz nazwę"
                                value={values.name}
                                onBlur={(e) => this.handleName(e.target.value)}
                                onChange={handleChange}
                                invalid={!!errors.name}
                              />
                              <FormFeedback>{errors.name}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="displayText">{DISPLAY_NAME}</Label>
                              <Input
                                type="text"
                                id="displayText"
                                name="displayText"
                                placeholder="Wpisz nazwę"
                                value={values.displayText}
                                onBlur={(e) =>
                                  this.handleDisplayText(e.target.value)
                                }
                                onChange={handleChange}
                                invalid={!!errors.displayText}
                              />
                              <FormFeedback>{errors.displayText}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="url">{URL}</Label>
                              <Input
                                type="text"
                                id="url"
                                name="url"
                                value={values.url}
                                onBlur={(e) => this.handleUrl(e.target.value)}
                                onChange={handleChange}
                                invalid={!!errors.url}
                              />
                              <FormFeedback>{errors.url}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="title">
                                {TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS}
                              </Label>
                              <Input
                                type="text"
                                id="title"
                                name="title"
                                value={values.title}
                                onBlur={(e) => this.handleTitle(e.target.value)}
                                onChange={handleChange}
                                invalid={!!errors.title}
                              />
                              <FormFeedback>{errors.title}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Switch
                              label={OPEN_BANNER_IN_NEW_WINDOW}
                              onChange={handleChange}
                              onClick={() => this.handleTarget(values.target)}
                              on={values.target === LinkTargetType.NewTab.value}
                              off={values.target === LinkTargetType.Self.value}
                            />
                          </Col>
                          <Col>
                            <Switch
                              label={ACTIVE}
                              onChange={handleChange}
                              onClick={() =>
                                this.handleIsActive(!values.isActive)
                              }
                              on={values.isActive}
                              off={!values.isActive}
                            />
                            <FormFeedback
                              style={{
                                display: `${
                                  !!errors.isActive ? "block" : "none"
                                }`,
                              }}
                            >
                              {errors.isActive}
                            </FormFeedback>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Switch
                                label={COMPATIBILITY_WITH_APP_COLOR}
                                onChange={handleChange}
                                onClick={() =>
                                  this.handleCompatibilityWithAppColor(
                                    !values.compatibilityWithAppColor
                                  )
                                }
                                on={values.compatibilityWithAppColor}
                                off={!values.compatibilityWithAppColor}
                              />
                            </FormGroup>
                            {!values.compatibilityWithAppColor && (
                              <FormGroup>
                                <Label for="color">{COLOR}</Label>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    id="color"
                                    name="color"
                                    placeholder="Wpisz nazwę"
                                    value={values.color}
                                    invalid={!!errors.color}
                                    onFocus={(_) =>
                                      setFieldValue("displayColorPicker", true)
                                    }
                                  />
                                  <div
                                    className="ml-2"
                                    style={{
                                      flex: "0 1 5%",
                                      width: "20px",
                                      display: "inline-block",
                                      backgroundColor: values.color,
                                    }}
                                  />
                                </InputGroup>
                                {values.displayColorPicker && (
                                  <div style={popover}>
                                    <div
                                      style={cover}
                                      onClick={(_) => {
                                        setFieldValue(
                                          "displayColorPicker",
                                          false
                                        );
                                        this.handleContrast(values.color);
                                      }}
                                    />
                                    <SketchPicker
                                      color={values.color}
                                      disableAlpha={true}
                                      onChangeComplete={(color) =>
                                        setFieldValue("color", color.hex)
                                      }
                                    />
                                  </div>
                                )}
                                <Field
                                  name="contrast"
                                  type="number"
                                  style={{ display: "none" }}
                                  values={values.contrast}
                                  className={
                                    "form-control" +
                                    (errors.contrast ? " is-invalid" : "")
                                  }
                                  onChange={handleChange}
                                />
                                {(values.contrast < 7.1 && !values.file) ||
                                (sContrast < 7.1 && !values.file) ? (
                                  <div className="is-invalid-contrast">
                                    {WCAG_CONTRAST_REQUIRED}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <FormFeedback>{errors.color}</FormFeedback>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          {!values.file && (
                            <Col md="12">
                              <UploadFile
                                disabled={false}
                                uploadUrl={"/api/files/crop"}
                                maxSize={maxFileSize}
                                acceptFileType={`${ImageMimeType.BMP}, ${ImageMimeType.PNG}, ${ImageMimeType.JPG}, ${ImageMimeType.GIF}`}
                                acceptExtensionFiles={[
                                  FileExtensionTypes.BMP,
                                  FileExtensionTypes.PNG,
                                  FileExtensionTypes.JPG,
                                  FileExtensionTypes.JPEG,
                                  FileExtensionTypes.GIF,
                                ]}
                                additionalInfo={`Zalecany format zdjęcia powinien być w formacie ${RectangleSize.width}x${RectangleSize.height} pikseli`}
                                recommendedWidth={RectangleSize.width}
                                recommendedHeight={RectangleSize.height}
                                onBlur={(e) => this.handleFile(e.target.value)}
                                onDrop={Function.prototype}
                                onDropAccepted={(file) => {
                                  const newFile = {
                                    ...file,
                                    cropData: DefaultCropData,
                                  };
                                  setFieldValue("file", newFile);
                                  this.setCropData(DefaultCropData);
                                  this.handleFile(newFile);
                                }}
                                onDropRejected={(file) =>
                                  fileUploadError(file, maxFileSize)
                                }
                                onCancel={Function.prototype}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row>
                          {values.file && (
                            <Col>
                              <CropImageUpload
                                previewSize={RectangleSize}
                                previewStyle={{
                                  height: `${RectangleSize.height}px`,
                                  width: `${RectangleSize.width}px`,
                                  backgroundColor: "grey",
                                }}
                                normalStyle={{
                                  minHeight: `${RectangleSize.height}px`,
                                  minWidth: `${RectangleSize.width}px`,
                                  backgroundColor: "grey",
                                }}
                                cropData={cropData}
                                path={values.file.path}
                                fileName={values.file.fileName}
                                onRemoveImage={() =>
                                  setFieldValue("file", null)
                                }
                                onCroppedImage={(cropData) => {
                                  this.setCropData(cropData);
                                  setFieldValue("file", {
                                    ...values.file,
                                    cropData,
                                  });
                                }}
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            </BlockUi>
          </Fragment>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    banner: state.setting.banner || {
      id: 0,
      displayText: "",
      title: "",
      url: "",
      target: state.application.newTabOpenLinks
        ? LinkTargetType.NewTab.value
        : LinkTargetType.Self.value,
      isActive: false,
      language: DefaultLanguage,
      color: "#000000",
      file: null,
      compatibilityWithAppColor: true,
    },
    activeLanguage: state.application.activeLanguage || DefaultLanguage,
    languages: getActiveLanguagesSelector(state),
    bannerCardBlocking: state.uiBlockState.bannerCardBlocking || false,
    maxFileSize: state.application.maxFileSize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(SettingActionCreators, dispatch),
    cancel: () => dispatch(goBack()),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerCard);
