import { OPERATIONS } from "./utils";
import { v4 as uuidv4 } from "uuid";

// CUSTOM STORE

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
};

export const removeFilterOperators = (arr) =>
  arr.filter((item) => typeof item !== "string");

export const processOperation = (item) => OPERATIONS[item] ?? item;

const getFilter = (filters, index, last) => {
  //if negation
  if (filters.some((el) => el === "!")) {
    //if multi negation
    if (filters[1].some((el) => el === "or")) {
      return `filters[${index}].columnName=${
        filters[1][0][0]
      }&filters[${index}].operation=notIn&filters[${index}].value=${removeFilterOperators(
        filters[1]
      )
        .map((item) => item[2])
        .join(";")}${last ? "" : "&"}`;
    }

    //single negation
    return `filters[${index}].columnName=${
      filters[1][0]
    }&filters[${index}].operation=notIn&filters[${index}].value=${
      filters[1][2]
    }${last ? "" : "&"}`;
  }

  //simple filter
  return `filters[${index}].columnName=${
    filters[0]
  }&filters[${index}].operation=${filters[1]}&filters[${index}].value=${
    filters[2]
  }${last ? "" : "&"}`;
};

const getGroupFilters = (filters, index, last) => {
  return `filters[${index}].columnName=${
    filters[0][0]
  }&filters[${index}].operation=in&filters[${index}].value=${removeFilterOperators(
    filters
  )
    .map((item) => item.filterValue)
    .join(";")}${last ? "" : "&"}`;
};

export const processFilters = (filters) => {
  if (filters.some((item) => item === "and")) {
    let queryParam = "";

    filters
      .filter((item) => item !== "and")
      .forEach((nestedFilter, index, arr) => {
        queryParam += nestedFilter.some((item) => item === "or")
          ? getGroupFilters(nestedFilter, index, arr.length - 1 === index)
          : getFilter(nestedFilter, index, arr.length - 1 === index);
      });

    return queryParam;
  } else {
    return filters.some((item) => item === "or")
      ? getGroupFilters(filters, 0, true)
      : getFilter(filters, 0, true);
  }
};

export const processSort = (arr) => {
  if (!arr.length) {
    return "";
  }

  return `sortings[0].columnName=${arr[0].selector}&sortings[0].direction=${
    arr[0].desc ? "desc" : "asc"
  }`;
};

export const processSortToArray = (arr) => {
  return [
    { columnName: arr[0].selector, direction: arr[0].desc ? "desc" : "asc" },
  ];
};

export const getTableParams = ({ loadOptions, paramsList }) => {
  let params = "";

  paramsList.forEach((i) => {
    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
      if (i === "filter") {
        params += `${processFilters(loadOptions[i])}&`;
      } else if (i === "sort") {
        params += `${processSort(loadOptions[i])}&`;
      } else {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    }
  });

  return params.replace(/&$/, "");
};

// CREATE STORE

const getFilterGroup = (filters, arr) => {
  arr.push({
    columnName: filters[0][0],
    operation: "in",
    value: filters.reduce((acc, filter, index, arr) => {
      if (filter !== "or") {
        acc += `${filter[2]}${arr.length === index + 1 ? "" : ";"}`;
      }
      return acc;
    }, ""),
  });
};

const getSingleFilter = (filters, arr) => {
  if (filters.some((el) => el === "!")) {
    // multi negation as single filter
    if (filters[1] instanceof Array && filters[1].some((el) => el === "or")) {
      arr.push({
        columnName: filters[1][0][0],
        operation: "notIn",
        value: filters[1].reduce((acc, filter) => {
          if (filter !== "or") {
            acc += `${filter[2]};`;
          }
          return acc;
        }, ""),
      });

      return;
    }

    //single negation
    arr.push({
      columnName: filters[1][0],
      operation: "notIn",
      value: filters[1][2],
    });
    return;
  }

  //single
  arr.push({
    columnName: filters[0],
    operation: processOperation(filters[1]),
    value: filters[2],
  });
};

export const processStoreFilters = (filters) => {
  if (!filters.length) {
    return [];
  }

  let arr = [];

  if (filters.some((item) => item === "and")) {
    // and
    filters.forEach((filter) => {
      //or
      if (filter instanceof Array && filter.some((el) => el === "or")) {
        getFilterGroup(filter, arr);
      } else {
        //single
        if (filter !== "and") {
          getSingleFilter(filter, arr);
        }
      }
    });
  } else {
    //or
    if (filters.some((item) => item === "or")) {
      getFilterGroup(filters, arr);
    } else {
      //single
      getSingleFilter(filters, arr);
    }
  }

  return arr;
};

export const getFilters = (filters, advFilters, count, parentId) => {
  const groupOperator = filters.find(
    (el) => el === "and" || el === "or" || el === "!"
  );
  const uniqueId = uuidv4();
  const operandId = uuidv4();

  if (groupOperator) {
    if (groupOperator === "!") {
      const negationId = uuidv4();

      if (filters[1].some((el) => el === "or")) {
        advFilters.push(
          {
            columnName: "",
            id: operandId,
            parentId,
            isRoot: true,
            operand: "and",
            operation: "",
            value: "",
            level: count,
          },
          {
            columnName: filters[1][0][0],
            id: negationId,
            parentId,
            isRoot: count === 0,
            operand: "",
            operation: "notIn",
            value: `${removeFilterOperators(filters[1])
              .map((item) => item[2])
              .join(";")}`,
            level: count + 1,
          }
        );

        return;
      } else {
        advFilters.push(
          {
            columnName: "",
            id: operandId,
            parentId,
            isRoot: true,
            operand: "and",
            operation: "",
            value: "",
            level: count,
          },
          {
            columnName: filters[1][0],
            id: negationId,
            parentId,
            isRoot: count === 0,
            operand: "",
            operation: "notIn",
            value: filters[1][2],
            level: count + 1,
          }
        );

        return;
      }
    }

    advFilters.push({
      columnName: "",
      id: uniqueId,
      parentId,
      isRoot: count === 0,
      operand: groupOperator,
      operation: "",
      value: "",
      level: count,
    });

    count++;

    filters.forEach((filter) => {
      const filterId = uuidv4();

      if (
        filter instanceof Array &&
        filter.some((item) => item instanceof Array)
      ) {
        getFilters(filter, advFilters, count, uniqueId);
      } else if (filter instanceof Array) {
        advFilters.push({
          columnName: filter[0],
          id: filterId,
          parentId: uniqueId,
          isRoot: count === 0,
          operand: "",
          operation: processOperation(filter[1]),
          value: filter[2],
          level: count,
        });
      }
    });
  } else {
    const filterId = uuidv4();
    const operandId = uuidv4();

    advFilters.push(
      {
        columnName: "",
        id: operandId,
        parentId,
        isRoot: true,
        operand: "and",
        operation: "",
        value: "",
        level: count,
      },
      {
        columnName: filters[0],
        id: filterId,
        parentId: operandId,
        isRoot: false,
        operand: "",
        operation: processOperation(filters[1]),
        value: filters[2],
        level: count + 1,
      }
    );
  }
};

export const processAdvFilters = (filters) => {
  let advFilters = [];
  let count = 0;

  getFilters(filters, advFilters, count, "");

  return advFilters;
};
