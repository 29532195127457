import React from "react";

import { TreeDxGrid } from "../../../components/TreeDxGrid/TreeDxGrid";

export const Grid = ({
  data,
  dataGridRef,
  onReorder,
  onDragChange,
  columns,
  onCellPrepared,
  onEditorPreparing,
  onDragStart,
}) => (
  <TreeDxGrid
    {...{
      keyExpr: "NEW_ID",
      parentIdExpr: "Head_ID",
      dataGridRef,
      data,
      onReorder,
      onDragChange,
      columns,
      onCellPrepared,
      onEditorPreparing,
      onDragStart,
    }}
  />
);
