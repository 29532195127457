import * as AppImages from './app-images';
import * as Banner from './banner';
import * as Bookmark from './bookmark';
import * as Footer from './footers';
import * as Header from './header';
import * as Language from './language';
import * as Repository from './repositories';
import * as RssChannel from './rss-channels';
import * as SystemParameters from './system-parameters';
import * as Tiles from './tiles';
import * as OfficeContacts from './office-contacts';

const UnloadSettingAction = {
	Request: 'UnloadSettingActionRequest',
	Success: 'UnloadSettingActionSuccess'
};

function unloadSetting() {
	return {
		type: UnloadSettingAction.Request
	};
}
export const SettingActions = {
	...AppImages.AppImagesActions,
	...Banner.BannerActions,
	...Bookmark.BookmarkActions,
	...Footer.FooterActions,
	...Header.HeaderActions,
	...Language.LanguageActions,
	...Repository.RepositoryConfigActions,
	...RssChannel.RssChannelActions,
	...SystemParameters.SystemParametersActions,
	...Tiles.TileActions,
	...OfficeContacts.OfficeContactsActions,
	UnloadSettingAction
};

export const SettingActionCreators = {
	...AppImages.AppImagesActionCreators,
	...Banner.BannerActionCreators,
	...Bookmark.BookmarkActionCreators,
	...Footer.FooterActionCreators,
	...Header.HeaderActionCreators,
	...Language.LanguageActionCreators,
	...Repository.RepositoryConfigActionCreators,
	...RssChannel.RssChannelActionCreators,
	...SystemParameters.SystemParametersActionCreators,
	...Tiles.TileActionCreators,
	...OfficeContacts.OfficeContactsActionCreators,
	unloadSetting
};
