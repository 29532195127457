import React from 'react';
import map from 'lodash.map';
import filter from 'lodash.filter';

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Flag from 'react-flagkit';
import city2 from '../assets/utils/images/dropdown-header/city2.jpg';

import { DefaultLanguage } from '../helpers/enums';
import { AVAILABLE_ISO_CODE } from '../helpers/isoCode';

export default ({
	language = {
		id: DefaultLanguage.id,
		isoCode: DefaultLanguage.isoCode,
		isoCodeFile: DefaultLanguage.isoCodeFile,
		name: DefaultLanguage.name
	},
	languages = [],
	onChangeLanguage = (lang) => {},
	...props
}) => (
	<div className="header-dots">
		<UncontrolledDropdown>
			<DropdownToggle className="p-0 mr-2" color="link">
				<div className="icon-wrapper icon-wrapper-alt rounded-circle">
					<div className="icon-wrapper-bg bg-focus" />
					<div className="language-icon">
						{AVAILABLE_ISO_CODE.includes(language.isoCode) && (
							<Flag className="mr-3 opacity-8" country={language.isoCode} size="40" />
						)}
						{!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
						language.isoCode !== '' &&
						language.isoCodeFile && (
							<img
								className={`mr-2 opacity-8`}
								src={language.isoCodeFile.path}
								alt={`Grafika ${language.isoCodeFile.fileName}`}
							/>
						)}
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu right className="rm-pointers">
				<div className="dropdown-menu-header">
					<div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
						<div
							className="menu-header-image opacity-05"
							style={{
								backgroundImage: 'url(' + city2 + ')'
							}}
						/>
						<div className="menu-header-content text-center text-white">
							<h6 className="menu-header-subtitle mt-0">Wybierz język</h6>
						</div>
					</div>
				</div>
				<DropdownItem header>Aktualnie wybrany</DropdownItem>
				<DropdownItem active>
					{AVAILABLE_ISO_CODE.includes(language.isoCode) && (
						<Flag className="mr-3 opacity-8" country={language.isoCode} />
					)}
					{!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
					language.isoCode !== '' &&
					language.isoCodeFile && (
						<img
							className={`mr-2 opacity-8`}
							src={language.isoCodeFile.path}
							alt={`Grafika ${language.isoCodeFile.fileName}`}
						/>
					)}
					{language.name}
				</DropdownItem>
				<DropdownItem divider />
				<DropdownItem header>Dostępne języki</DropdownItem>
				{map(filter(languages, (lang) => lang.id !== language.id), (language, langIndex) => (
					<DropdownItem key={`langIndex-${langIndex}`} onClick={(e) => onChangeLanguage(language)}>
						{AVAILABLE_ISO_CODE.includes(language.isoCode) && (
							<Flag className="mr-3 opacity-8" country={language.isoCode} />
						)}
						{!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
						language.isoCode !== '' &&
						language.isoCodeFile && (
							<img
								className={`mr-2 opacity-8`}
								src={language.isoCodeFile.path}
								alt={`Grafika ${language.isoCodeFile.fileName}`}
							/>
						)}
						{language.name}
					</DropdownItem>
				))}
			</DropdownMenu>
		</UncontrolledDropdown>
	</div>
);
