import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import {
	SortableTreeWithoutDndContext as SortableTree,
	toggleExpandedForAll,
	getTreeFromFlatData
} from 'react-sortable-tree';
import RSC from 'react-scrollbars-custom';

import Immutable from 'seamless-immutable';
import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import uuid from 'uuid/v4';
import { toast } from 'react-toastify';

import { DragDropType, LoaderType, FooterLinkType, DefaultLanguage, ArticleType } from '../../helpers/enums';
import { MenuActionCreators } from '../../actions/menu';
import { ArticleActionCreators } from '../../actions/article';
import { SearchActionCreators } from '../../actions/search';

import FooterLinkTarget from '../DragAndDropTargets/FooterLinkTarget';
import FooterLinkSource from '../DragAndDropSources/FooterLinkSource';
import ExternalLinkCard from '../Cards/ExternalLinkCard';
import ConfirmModal from '../ConfirmModal';

import { CONSTANTS } from '@constants';
const { CONFIRM, CANCEL } = CONSTANTS;

class FooterInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: props.availableLinks || [],
			type: props.listType || FooterLinkType.HorizontalMenu.value,
			expanded: false,
			links: props.items,
			deletingState: false
		};
	}

	componentDidMount() {
		this.getItems();
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.availableLinks, this.props.availableLinks)) {
			const newAvailableLinks = this.state.expanded
				? toggleExpandedForAll({ items: this.props.availableLinks, expanded: this.state.expanded })
				: this.props.availableLinks;
			this.setState({
				items: Immutable(
					getTreeFromFlatData({
						flatData: newAvailableLinks,
						getKey: (element) => element.key,
						getParentKey: (element) => element.parentKey,
						rootKey: null
					}),
					{ deep: true }
				)
			});
		}
		if (!isEqual(prevProps.isOpen, this.props.isOpen) && this.props.isOpen) {
			this.getItems();
		}
		if (!isEqual(prevProps.listType, this.props.listType)) {
			this.setState({ type: this.props.listType });
		}
		if (!isEqual(prevProps.items, this.props.items)) {
			this.setState({ links: this.props.items });
		}
	}

	getItems = () => {
		const { type } = this.state;
		const { getMenu, getAllArticles, activeLanguage } = this.props;
		if ([ FooterLinkType.HorizontalMenu.value, FooterLinkType.VerticalMenu.value ].includes(type)) {
			getMenu(type, false, true);
		} else if (FooterLinkType.Article.value === type) {
			getAllArticles(
				activeLanguage.isoCode || DefaultLanguage.isoCode,
				0,
				0,
				[ { columnName: 'status', operation: 'lessThanOrEqual', value: 40 } ],
				[],
				[
					ArticleType.Stats.value,
					ArticleType.Sitemap.value,
					ArticleType.SharingInformationTerms.value,
					ArticleType.Search.value,
					ArticleType.ReusableInformationTerms.value,
					ArticleType.RSS.value,
					ArticleType.PersonalDataSecurityTerms.value,
					ArticleType.Instruction.value,
					ArticleType.ChangeLog.value,
					ArticleType.ClientData.value,
					ArticleType.DigitalAvailability.value,
					ArticleType.TransmissionFromRadniInfo.value
				],
				[]
			);
		}
	};
	updateItems = (items) =>
		this.setState({
			items: items
		});

	onChangeItems = (items) => {
		this.setState({ items: items });
	};

	onChangeLinks = (links, closeModal = false) => {
		closeModal && this.setState({ deletingState: !this.state.deletingState });
		this.setState({ links: map(links, (item) => ({ key: uuid(), parentKey: null, ...item })) });
	};

	onChangeListType = (listType) => {
		this.setState({ type: listType }, this.getItems);
	};

	onCancel = (event) => {
		const { onToggle } = this.props;

		if (onToggle && typeof onToggle === 'function') {
			this.setState({ expanded: false });
			onToggle(event);
		}
	};

	onSave = (event) => {
		const { onToggle, onSave } = this.props;
		const { links } = this.state;

		if (onSave && typeof onSave === 'function') {
			onSave(links);
		}

		if (onToggle && typeof onToggle === 'function') {
			this.setState({ expanded: false });
			onToggle(event);
		}
	};

	canDrop = ({ node, prevPath, prevParent, prevTreeIndex, nextPath, nextParent, nextTreeIndex }) => {
		return false;
	};

	handleAddExternalLink = (link) => {
		this.setState({
			links: isEmpty(this.state.links)
				? [].concat({ key: uuid(), parentKey: null, ...link })
				: this.state.links.concat({ key: uuid(), parentKey: null, ...link })
		});
		toast.success('Dodano nowe źródło');
	};

	render() {
		const { isOpen, footerLinkListBlocking } = this.props;
		const { items, type, links } = this.state;

		return (
			<Modal isOpen={isOpen} backdrop size="xl">
				<ModalHeader>
					<div className="d-flex justify-content-between">
						<div>Wybierz źródła</div>
						<div>
							<Button className="btn-icon mr-2" color="alternate" type="button" onClick={this.onSave}>
								<i className="pe-7s-check btn-icon-wrapper" />
								{CONFIRM}
							</Button>
							<Button className="btn-icon mr-2" color="secondary" type="button" onClick={this.onCancel}>
								<i className="pe-7s-back btn-icon-wrapper" />
								{CANCEL}
							</Button>
						</div>
					</div>
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col>
							<Card className="mb-2">
								<CardHeader>
									<div className="widget-content p-0 d-flex flex1-1-0">
										<div className="widget-content-wrapper">
											<div className="widget-content-left flex0-0-3">Przełącz</div>
											<div className="widget-content-right ml-3 d-flex flex1-1-0">
												<Button
													className="btn-icon border-0 mr-2 fsize-2"
													active={type === FooterLinkType.HorizontalMenu.value}
													type="button"
													outline={
														type !== FooterLinkType.HorizontalMenu.value ? true : false
													}
													color={
														type === FooterLinkType.HorizontalMenu.value ? 'link' : 'light'
													}
													title="Wyszukaj z menu poziomego"
													onClick={this.onChangeListType.bind(
														this,
														FooterLinkType.HorizontalMenu.value
													)}
												>
													<i className="pe-7s-menu" />
												</Button>
												<Button
													className="btn-icon border-0 mr-2 fsize-2 rotate-90"
													active={type === FooterLinkType.VerticalMenu.value}
													type="button"
													outline={type !== FooterLinkType.VerticalMenu.value ? true : false}
													color={
														type === FooterLinkType.VerticalMenu.value ? 'link' : 'light'
													}
													title="Wyszukaj z menu pionowego"
													onClick={this.onChangeListType.bind(
														this,
														FooterLinkType.VerticalMenu.value
													)}
												>
													<i className="pe-7s-menu" />
												</Button>
												<Button
													className="btn-icon border-0 mr-2 fsize-2"
													active={type === FooterLinkType.Article.value}
													type="button"
													outline={type !== FooterLinkType.Article.value ? true : false}
													color={type === FooterLinkType.Article.value ? 'link' : 'light'}
													title="Wyszukaj z artykułów"
													onClick={this.onChangeListType.bind(
														this,
														FooterLinkType.Article.value
													)}
												>
													<i className="pe-7s-file" />
												</Button>
												<Button
													className="btn-icon border-0 mr-2 fsize-2"
													active={type === FooterLinkType.External.value}
													type="button"
													outline={type !== FooterLinkType.External.value}
													color={type === FooterLinkType.External.value ? 'link' : 'light'}
													title="Dodaj źródło zewnętrzne"
													onClick={this.onChangeListType.bind(
														this,
														FooterLinkType.External.value
													)}
												>
													<i className="pe-7s-link" />
												</Button>
											</div>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<div style={{ overflow: 'auto' }}>
										<BlockUi
											tag="div"
											blocking={footerLinkListBlocking}
											loader={<Loader active type={LoaderType} />}
										>
											<div className="vh-100" style={{ overflow: 'auto' }}>
												<RSC>
													{type !== FooterLinkType.External.value && (
														<SortableTree
															treeData={items}
															onChange={this.onChangeItems}
															rowHeight={80}
															isVirtualized={false}
															dndType={DragDropType.FooterLink}
															shouldCopyOnOutsideDrop={true}
															canDrop={this.canDrop}
															nodeContentRenderer={FooterLinkSource}
														/>
													)}
													{type === FooterLinkType.External.value && (
														<ExternalLinkCard onAdd={this.handleAddExternalLink} />
													)}
												</RSC>
											</div>
										</BlockUi>
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col>
							<Card className="mb-2">
								<CardHeader>
									<div className="widget-content p-0 d-flex flex1-1-0">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">Wybrane źródła</div>
											{!isEmpty(items) && (
												<div className="widget-content-right">
													<ConfirmModal
														open={this.state.deletingState}
														content={'Czy na pewno usunąć wszystkie elementy?'}
														onCancel={() =>
															this.setState({
																deletingState: !this.state.deletingState
															})}
														onSubmit={() => this.onChangeLinks([], true)}
													/>
													<Button
														disabled={isEmpty(links)}
														className="btn-icon mr-2"
														color="danger"
														type="button"
														onClick={() =>
															this.setState({
																deletingState: !this.state.deletingState
															})}
													>
														<i className="pe-7s-trash btn-icon-wrapper" />Usuń wszystkie
													</Button>
												</div>
											)}
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<div className="vh-100" style={{ overflow: 'auto' }}>
										<RSC>
											<FooterLinkTarget
												items={links}
												dropType={DragDropType.FooterLink}
												onChangeItems={this.onChangeLinks}
											/>
										</RSC>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => ({
	activeLanguage: state.application.activeLanguage || DefaultLanguage,
	availableLinks: state.footer.availableLinks || [],
	footerLinkListBlocking: state.uiBlockState.footerLinkListBlocking || false
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ ...MenuActionCreators, ...ArticleActionCreators, ...SearchActionCreators }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FooterInformation);

FooterInformation.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({}))
};
