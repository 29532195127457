import * as yup from 'yup';
import { FooterType, LinkTargetType } from '../helpers/enums';

export const FooterValidationSchema = yup.object().shape({
	type: yup.number(),
	displayText: yup.string().when([ 'type' ], {
		is: (type) => type !== FooterType.Logo.value,
		then: yup.string().trim().required('Nazwa wyświetlana jest polem wymaganym')
	}),
	content: yup
		.object()
		.when([ 'type' ], {
			is: (type) => type === FooterType.Logo.value,
			then: yup.object().shape({
				bip: yup.string().trim().required('Nazwa BIP jest polem wymaganym'),
				office: yup.string().trim().required('Nazwa jednostki jest polem wymaganym')
			})
		})
		.when([ 'type' ], {
			is: (type) => type === FooterType.EditorialOffice.value || type === FooterType.EditorialOfficeAddress.value,
			then: yup.object().shape({
				content: yup.string().trim().required('Treść jest polem wymaganym')
			})
		})
		.when([ 'type' ], {
			is: (type) => type === FooterType.Information.value,
			then: yup.object().shape({
				links: yup.array().of(
					yup.object().shape({
						displayText: yup.string().trim().required('Nazwa wyświetlana jest polem wymaganym'),
						title: yup
							.string()
							.trim()
							.required('Tekst pod czytniki dla osób słabowidzących jest polem wymaganym'),
						target: yup
							.number()
							.oneOf(
								[ LinkTargetType.Self.value, LinkTargetType.NewTab.value ],
								`Otwórz zakładkę w nowym oknie musi zawierać wartość: ${LinkTargetType.Self
									.name} lub ${LinkTargetType.NewTab.name}`
							)
					})
				)
			})
		})
});
