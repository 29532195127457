import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Formik } from "formik";
import Immutable from "seamless-immutable";
import isEqual from "lodash.isequal";

import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import Sticky from "react-stickynode";
import {
  Form,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Button,
  Label,
  FormFeedback,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import PageTitle from "@layout/AppMain/PageTitle";
import { SettingActionCreators } from "@actions/settings";
import { ApplicationActionCreators } from "@actions/application";
import { LoaderType, DefaultLanguage, PopoverPlacement } from "@helpers/enums";
import { TinyMCE } from "@components/TinyMCE";
import { TranslationFlagButtons } from "./TranslationFlagButtons";
import { LanguageFlag } from "./LanguageFlag";

import { CONSTANTS } from "@constants";
import { DefaultFormikConfig } from "../../../../helpers/enums";

const {
  SAVE,
  CANCEL,
  COMMON_INFOMATION,
  NAME_ENTITY,
  INFORMATION,
  SELECT_PIECE_TEXT_AND_FORMAT,
  BOLD,
  ITALICS,
  STRESS,
  CROSS,
  LOADING,
} = CONSTANTS;

class FooterCard extends Component {
  componentDidMount() {
    const {
      getHeader,
      activeLanguage: { isoCode },
    } = this.props;

    getHeader(isoCode);
  }

  componentDidUpdate(prevProps) {
    const { getHeader, activeLanguage } = this.props;

    !isEqual(prevProps.activeLanguage, activeLanguage) &&
      getHeader(activeLanguage.isoCode);
  }

  componentWillUnmount() {
    this.props.unloadSetting();
  }

  render() {
    const {
      header,
      activeLanguage,
      updateHeader,
      languages,
      changeApplicationLanguage,
      cancel: onReset,
      headerCardBlocking: blocking,
    } = this.props;
    const initialValues = Immutable({
      ...header,
      language: { ...activeLanguage },
    }).asMutable({ deep: true });

    if (!initialValues) return <div>{LOADING}</div>;

    return (
      <Formik
        {...DefaultFormikConfig}
        {...{ initialValues, onReset }}
        onSubmit={(values) => updateHeader(values)}
      >
        {({
          values,
          setFieldValue,
          isValid,
          dirty,
          handleSubmit: onSubmit,
          handleReset: onReset,
        }) => (
          <Fragment>
            <PageTitle
              heading="Ustawienia nagłówka"
              icon="pe-7s-copy-file icon-gradient bg-tempting-azure"
              buttons={TranslationFlagButtons(
                values,
                languages,
                changeApplicationLanguage
              )}
            />
            <BlockUi
              tag="div"
              {...{ blocking }}
              loader={<Loader active type={LoaderType} />}
            >
              <Form noValidate method="post" {...{ onSubmit, onReset }}>
                <Sticky
                  enabled={true}
                  top=".app-header"
                  innerZ="15"
                  activeClass="sticky-active-class"
                  className="mb-2"
                >
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn-icon mr-2"
                      color="primary"
                      type="submit"
                      disabled={!isValid || !dirty}
                    >
                      <i className="pe-7s-diskette btn-icon-wrapper" />
                      {SAVE}
                    </Button>
                    <Button
                      className="btn-icon mr-2"
                      color="secondary"
                      type="reset"
                    >
                      <i className="pe-7s-back btn-icon-wrapper" /> {CANCEL}
                    </Button>
                  </div>
                </Sticky>
                <Card className="mb-2">
                  <CardHeader>
                    <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />{" "}
                    {COMMON_INFOMATION}
                    <LanguageFlag {...{ values, languages }} />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="officeName">{NAME_ENTITY}</Label>
                          <Button
                            type="button"
                            color="link"
                            className="btn-icon btn-icon-only"
                            title="Informacje dot. formatowania tekstu nagłówka"
                            id="popover-header-format-info"
                          >
                            <i
                              className="pe-7s-info btn-icon-wrapper"
                              style={{ verticalAlign: "baseline" }}
                            />
                          </Button>
                          <div id="officeName" className="form-control">
                            <div className="form-control-tiny-mce">
                              <TinyMCE
                                config={{
                                  selector: "div#officeName",
                                  plugins: "",
                                  height: 120,
                                  toolbar:
                                    "bold italic underline strikethrough forecolor",
                                  menubar: false,
                                }}
                                content={values.officeName}
                                available_paste={false}
                                multiline={false}
                                onContentChanged={(content) =>
                                  setFieldValue("officeName", content)
                                }
                              />
                            </div>
                          </div>
                          <FormFeedback />
                        </FormGroup>

                        <UncontrolledPopover
                          placement={PopoverPlacement.Right}
                          trigger="legacy"
                          fade={false}
                          target={"popover-header-format-info"}
                        >
                          <PopoverHeader>{INFORMATION}</PopoverHeader>
                          <PopoverBody>
                            {SELECT_PIECE_TEXT_AND_FORMAT}
                            <ul>
                              <li>{BOLD}</li>
                              <li>{ITALICS}</li>
                              <li>{STRESS}</li>
                              <li>{CROSS} </li>
                            </ul>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            </BlockUi>
          </Fragment>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state, ownProp) => {
  return {
    header: state.setting.header || { officeName: "" },
    headerCardBlocking: state.uiBlockState.headerCardBlocking || false,
    activeLanguage: state.application.activeLanguage || DefaultLanguage,
    languages: state.application.languages || [DefaultLanguage],
  };
};

const mapDispatchToProps = (dispatch, ownProp) => {
  return {
    ...bindActionCreators(
      { ...SettingActionCreators, ...ApplicationActionCreators },
      dispatch
    ),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterCard);
