import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { Formik } from "formik";
import Immutable from "seamless-immutable";
import cx from "classnames";

import {
  Form,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Label,
  Input,
  Button,
} from "reactstrap";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import Sticky from "react-stickynode";

import PageTitle from "../../../../Layout/AppMain/PageTitle";
import RssChannelDetailsCard from "../../../../components/Cards/RssChannelDetailsCard";

import { SettingActionCreators } from "../../../../actions/settings";
import {
  LoaderType,
  DefaultLanguage,
  LinkTargetType,
  DefaultFormikConfig,
} from "../../../../helpers/enums";
import { RssChannelValidationSchema } from "../../../../validators";

import { CONSTANTS } from "@constants";

const {
  SAVE,
  BACK,
  COMMON_INFORMATION,
  DISPLAY_NAME,
  TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS,
  CHANNEL_DESCRIPTION,
  OPEN_CHANNEL_IN_NEW_WINDOW,
  YES,
  NO,
  GET_DATA_FROM_ALL_SOURCES,
  ACTIVE_VER,
} = CONSTANTS;

class RssChannelCard extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.getRssChannel(id);
    }
  }

  componentDidUpdate(prevProps) {
    const { id: prevId } = prevProps.match.params;
    const { id } = this.props.match.params;
    if (prevId && id && prevId !== id) {
      this.props.getRssChannel(id);
    }
  }

  componentWillUnmount() {
    this.props.unloadSetting();
  }

  render() {
    const {
      rssChannel,
      activeLanguage,
      createRssChannel,
      updateRssChannel,
      rssChannelCardBlocking,
      cancel,
    } = this.props;

    const newRssChannel = Immutable({
      ...rssChannel,
      language: { ...activeLanguage },
      displayColorPicker: false,
    }).asMutable({ deep: true });

    return (
      <Formik
        {...DefaultFormikConfig}
        initialValues={newRssChannel}
        validationSchema={RssChannelValidationSchema}
        onSubmit={(values) => {
          if (values.id > 0) {
            updateRssChannel(values);
          } else {
            createRssChannel(values);
          }
        }}
        onReset={cancel}
      >
        {({
          errors,
          values,
          handleChange,
          isValid,
          dirty,
          setFieldValue,
          setValues,
          handleSubmit,
          handleReset,
        }) => (
          <Fragment>
            <PageTitle
              heading="Ustawienia kanału RSS2"
              icon="pe-7s-ribbon icon-gradient bg-tempting-azure"
            />
            <BlockUi
              tag="div"
              blocking={rssChannelCardBlocking}
              loader={<Loader active type={LoaderType} />}
            >
              <Form
                noValidate
                method="post"
                onSubmit={handleSubmit}
                onReset={handleReset}
              >
                <Sticky
                  enabled={true}
                  top=".app-header"
                  innerZ="101"
                  activeClass="sticky-active-class"
                  className="mb-2"
                >
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn-icon mr-2"
                      color="primary"
                      type="submit"
                      disabled={!isValid || !dirty}
                    >
                      <i className="pe-7s-diskette btn-icon-wrapper" />
                      {SAVE}
                    </Button>
                    <Button
                      className="btn-icon mr-2"
                      color="secondary"
                      type="reset"
                    >
                      <i className="pe-7s-back btn-icon-wrapper" />
                      {BACK}
                    </Button>
                  </div>
                </Sticky>
                <Card className="mb-2">
                  <CardHeader>
                    <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
                    {COMMON_INFORMATION}
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="displayText">{DISPLAY_NAME}</Label>
                          <Input
                            type="text"
                            id="displayText"
                            name="displayText"
                            placeholder="Wpisz nazwę"
                            value={values.displayText}
                            onChange={handleChange}
                            invalid={!!errors.displayText}
                          />
                          <FormFeedback>{errors.displayText}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="title">
                            {TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS}
                          </Label>
                          <Input
                            type="text"
                            id="title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            invalid={!!errors.title}
                          />
                          <FormFeedback>{errors.title}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="description">{CHANNEL_DESCRIPTION}</Label>
                          <Input
                            type="text"
                            id="description"
                            name="description"
                            placeholder="Wpisz opis"
                            value={values.description}
                            onChange={handleChange}
                            invalid={!!errors.description}
                          />
                          <FormFeedback>{errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="d-flex align-items-center">
                          <Label className="mr-2">
                            {OPEN_CHANNEL_IN_NEW_WINDOW}
                          </Label>
                          <div
                            className="switch has-switch mb-2 mr-2"
                            data-on-label="Tak"
                            data-off-label="Nie"
                            onClick={(_) =>
                              setFieldValue(
                                "target",
                                values.target === LinkTargetType.NewTab.value
                                  ? LinkTargetType.Self.value
                                  : LinkTargetType.NewTab.value
                              )
                            }
                            invalid={!!errors.target}
                          >
                            <div
                              className={cx("switch-animate", {
                                "switch-on":
                                  values.target === LinkTargetType.NewTab.value,
                                "switch-off":
                                  values.target === LinkTargetType.Self.value,
                              })}
                            >
                              <input type="checkbox" />
                              <span className="switch-left">{YES}</span>
                              <label>&nbsp;</label>
                              <span className="switch-right">{NO}</span>
                            </div>
                          </div>
                        </FormGroup>
                        <FormFeedback
                          style={{
                            display: `${!!errors.target ? "block" : "none"}`,
                          }}
                        >
                          {errors.target}
                        </FormFeedback>
                      </Col>
                      <Col>
                        <FormGroup className="d-flex align-items-center">
                          <Label className="mr-2">
                            {GET_DATA_FROM_ALL_SOURCES}
                          </Label>
                          <div
                            className="switch has-switch mb-2 mr-2"
                            data-on-label="Tak"
                            data-off-label="Nie"
                            onClick={(_) =>
                              setFieldValue(
                                "fromAllSources",
                                !values.fromAllSources
                              )
                            }
                          >
                            <div
                              className={cx("switch-animate", {
                                "switch-on": values.fromAllSources,
                                "switch-off": !values.fromAllSources,
                              })}
                            >
                              <input type="checkbox" />
                              <span className="switch-left">{YES}</span>
                              <label>&nbsp;</label>
                              <span className="switch-right">{NO}</span>
                            </div>
                          </div>
                        </FormGroup>
                        <FormFeedback
                          style={{
                            display: `${
                              !!errors.fromAllSources ? "block" : "none"
                            }`,
                          }}
                        >
                          {errors.fromAllSources}
                        </FormFeedback>
                      </Col>
                      <Col>
                        <FormGroup className="d-flex align-items-center">
                          <Label className="mr-2">{ACTIVE_VER}</Label>
                          <div
                            className="switch has-switch mb-2 mr-2"
                            data-on-label="Tak"
                            data-off-label="Nie"
                            onClick={(_) =>
                              setFieldValue("isActive", !values.isActive)
                            }
                          >
                            <div
                              className={cx("switch-animate", {
                                "switch-on": values.isActive,
                                "switch-off": !values.isActive,
                              })}
                            >
                              <input type="checkbox" />
                              <span className="switch-left">{YES}</span>
                              <label>&nbsp;</label>
                              <span className="switch-right">{NO}</span>
                            </div>
                          </div>
                        </FormGroup>
                        <FormFeedback
                          style={{
                            display: `${!!errors.isActive ? "block" : "none"}`,
                          }}
                        >
                          {errors.isActive}
                        </FormFeedback>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <RssChannelDetailsCard
                  horizontalMenuElements={values.horizontalMenuElements}
                  verticalMenuElements={values.verticalMenuElements}
                  bookmarksElements={values.bookmarksElements}
                  onSave={setFieldValue}
                  disabled={values.fromAllSources}
                  allSources={values.fromAllSources}
                />
              </Form>
            </BlockUi>
          </Fragment>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state, ownProp) => {
  return {
    rssChannel: state.setting.rssChannel || {
      id: 0,
      displayText: "",
      title: "",
      description: "",
      target: state.application.newTabOpenLinks
        ? LinkTargetType.NewTab.value
        : LinkTargetType.Self.value,
      isActive: false,
      fromAllSources: false,
      horizontalMenuElements: [],
      verticalMenuElements: [],
      bookmarksElements: [],
      language: DefaultLanguage,
    },
    activeLanguage: state.application.activeLanguage || DefaultLanguage,
    rssChannelCardBlocking: state.uiBlockState.rssChannelCardBlocking,
  };
};

const mapDispatchToProps = (dispatch, ownProp) => {
  return {
    ...bindActionCreators(SettingActionCreators, dispatch),
    cancel: () => dispatch(goBack()),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RssChannelCard);
