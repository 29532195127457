import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Button,
  Col,
  Row,
  InputGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import { FieldArray } from "formik";

import ArticleAttachments from "../Article-attachments";

import { ArticleType, FileType, PageSize } from "../../../../../helpers/enums";
import { CONSTANTS } from "@constants";
import { RepositoryFileList } from "../../../../../components/Modals/RepositoryFileList";
import { SettingActionCreators } from "../../../../../actions/settings";

const { ATTACHMENT } = CONSTANTS;
const HIDDEN_FOR_TYPES = [ArticleType.TransmissionFromRadniInfo.value];

const combineExtensions = (repositoryGroups) => {
  const extensions = (repositoryGroups || []).reduce(
    (prev, curr) => (prev = prev.concat(curr.extensions)),
    []
  );
  const mappedExtensions = extensions.map((extension) => `.${extension}`);
  return mappedExtensions.join(", ");
};

export const AttachmentCard = ({
  articleType = ArticleType.Common.value,
  disabled = false,
  items = [],
  maxFileSize = 0,
  isIntegratedDocRepoWithASW = false,
  onChange = Function.prototype,
  setFieldValue,
  isAllowAddNewAttachments,
  addedAttachments,
  publishedArticle,
  workingArticle,
  newAttachments,
  maxNewAttachmentCount,
  countNewAttachmentsAfterPublish,
}) => {
  const dispatch = useDispatch();
  const [cardCollapse, setCardCollapse] = useState(false);
  const [showRepositoryFileList, setShowRepositoryFileList] = useState(false);
  const repositoryGroups = useSelector(
    (state) => state.setting.repositoryConfigs || []
  );

  const joinedExtensions = combineExtensions(repositoryGroups);

  const onSelectValidation = (files) => {
    if (
      publishedArticle &&
      newAttachments.length + files.length >
        maxNewAttachmentCount - countNewAttachmentsAfterPublish
    ) {
      toast.error(
        `Przekroczono dozwoloną ilość dodatkowych załączników. Dozwolona liczba wynosi: ${
          maxNewAttachmentCount -
          countNewAttachmentsAfterPublish -
          newAttachments.length
        }`
      );

      return false;
    }

    return true;
  };

  useEffect(() => {
    dispatch(
      SettingActionCreators.loadRepositoryConfigs(0, PageSize.All, [], [])
    );
    return () => {
      dispatch(SettingActionCreators.unloadSetting());
    };
  }, []);

  return !HIDDEN_FOR_TYPES.includes(articleType) ? (
    <Card className="mb-2">
      <CardHeader
        onClick={(_) => setCardCollapse(!cardCollapse)}
        style={{ cursor: "pointer" }}
      >
        <i className="header-icon pe-7s-paperclip icon-gradient bg-malibu-beach" />
        {ATTACHMENT}
        <div className="btn-actions-pane-right btn-actions-cursor--pointer">
          {cardCollapse ? "Zwiń" : "Rozwiń"}
          {cardCollapse ? (
            <i className="pe-7s-angle-up" />
          ) : (
            <i className="pe-7s-angle-down" />
          )}
        </div>
      </CardHeader>
      <Collapse isOpen={cardCollapse}>
        <CardBody>
          <Row>
            <Col>
              <InputGroup className="justify-content-end">
                <div className="btn-group">
                  <Button
                    color="success"
                    onClick={() =>
                      setShowRepositoryFileList(!showRepositoryFileList)
                    }
                    disabled={disabled && !isAllowAddNewAttachments}
                  >
                    Wybierz z repozytorium
                  </Button>
                </div>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <RepositoryFileList
                isOpen={showRepositoryFileList}
                repositoryGroups={repositoryGroups}
                onSelectValidation={onSelectValidation}
                onCancel={() =>
                  setShowRepositoryFileList(!showRepositoryFileList)
                }
                onSelect={(files) => {
                  const attachments = (files || []).map((file) => ({
                    id: 0,
                    content: "",
                    contentType: file.contentType,
                    crc32File: "",
                    createdBy: file.createdBy,
                    createdDate: file.createdDate,
                    displayName: file.fileName,
                    documentRepositoryFileId: null,
                    documentRepositoryId: file.id,
                    fileType:
                      (file.contentType || "").indexOf("image/") !== -1
                        ? FileType.Image
                        : FileType.Document,
                    filename: file.fileName,
                    size: file.size,
                  }));
                  setFieldValue("attachments", items.concat(attachments));
                }}
              />
            </Col>
          </Row>
          <FieldArray
            id="attachments"
            name="attachments"
            render={(arrayHelpers) => (
              <Fragment>
                <ArticleAttachments
                  disabled={disabled && !isAllowAddNewAttachments}
                  isAllowAddNewAttachments={isAllowAddNewAttachments}
                  addedAttachments={addedAttachments}
                  publishedArticle={publishedArticle}
                  workingArticle={workingArticle}
                  items={items}
                  multiple={true}
                  maxFileSize={maxFileSize}
                  baseUrl={"/api/files/article"}
                  acceptExtensionFiles={joinedExtensions}
                  onAdd={arrayHelpers.push}
                  onRemove={arrayHelpers.remove}
                  onMove={arrayHelpers.move}
                  onChange={onChange}
                  prefixName={"attachments"}
                  setFieldValue={setFieldValue}
                  customErrorExtensionMessage="Plik nie może być dodany - nie zdefiniowano grupy dla tego rozszerzenia"
                  newAttachments={newAttachments}
                  maxNewAttachmentCount={
                    maxNewAttachmentCount - countNewAttachmentsAfterPublish
                  }
                />
              </Fragment>
            )}
          />
        </CardBody>
      </Collapse>
    </Card>
  ) : (
    <Fragment />
  );
};
