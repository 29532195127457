import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { Formik } from 'formik';
import {
	Card,
	CardHeader,
	CardBody,
	Row,
	Col,
	Button,
	FormGroup,
	Label,
	Input,
	Alert,
	ListGroupItem,
	ListGroup,
	UncontrolledCollapse,
	FormFeedback
} from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import isEmpty from 'lodash.isempty';
import * as yup from 'yup';

import PageTitle from '../../../Layout/AppMain/PageTitle';

import { LoaderType } from '../../../helpers/enums';

import { CONSTANTS } from '@constants';
import { ApplicationActionCreators } from '../../../actions/application';
import CardFooter from 'reactstrap/lib/CardFooter';
import { ArchiveActionCreators } from '../../../actions/archive';

const {
	COMMON_INFORMATION,
	SITENAME,
	REMOVE_SITE,
	CONNECT_WITH_ARCHIVE_BIP,
	OPERATIONS,
	SITENAME_FILE,
	SITEMAP_FILE_LOADED
} = CONSTANTS;

const SitenameSchema = yup.object().shape({
	sitename: yup
		.string()
		.url('Nazwa strony musi być prawidłowym adresem URL zawierającym http(s)://')
		.required('Nazwa strony jest wymagana')
		.nullable()
});

const SitesList = ({ sites = [], subs = [], files = [] }) => (
	<Fragment>
		<ListGroup flush>
			{!isEmpty(sites) && (
				<ListGroupItem id="sites">
					<div className="widget-content p-0">
						<div className="widget-content-wrapper">
							<div className="widget-content-left mr-2">
								<i className={`pe-7s-file`} />
							</div>
							<div className="widget-content-left">
								<div className="widget-heading">Strony ({`${sites.length}`})</div>
							</div>
						</div>
					</div>
					<UncontrolledCollapse toggler="#sites">
						<ListGroup flush>
							{sites.map((site, index) => (
								<ListGroupItem key={`site-${index}`} id={`site-${index}`}>
									<div className="widget-content p-0">
										<div className="widget-content-wrapper">
											<div className="widget-content-left mr-2">
												<i className={`pe-7s-angle-right`} />
											</div>
											<div className="widget-content-left">
												<div className="widget-subheading">{site}</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
							))}
						</ListGroup>
					</UncontrolledCollapse>
				</ListGroupItem>
			)}
			{!isEmpty(subs) && (
				<ListGroupItem id="subsites">
					<div className="widget-content p-0">
						<div className="widget-content-wrapper">
							<div className="widget-content-left mr-2">
								<i className={`pe-7s-albums`} />
							</div>
							<div className="widget-content-left">
								<div className="widget-heading">Podstrony ({`${subs.length}`})</div>
							</div>
						</div>
					</div>
					<UncontrolledCollapse toggler="#subsites">
						<ListGroup flush>
							{subs.map((subsite, index) => (
								<ListGroupItem key={`subsite-${index}`} id={`subsite-${index}`}>
									<div className="widget-content p-0">
										<div className="widget-content-wrapper">
											<div className="widget-content-left mr-2">
												<i className={`pe-7s-angle-right`} />
											</div>
											<div className="widget-content-left">
												<div className="widget-subheading">{subsite}</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
							))}
						</ListGroup>
					</UncontrolledCollapse>
				</ListGroupItem>
			)}
			{!isEmpty(files) && (
				<ListGroupItem id="attachments">
					<div className="widget-content p-0">
						<div className="widget-content-wrapper">
							<div className="widget-content-left mr-2">
								<i className={`pe-7s-box1`} />
							</div>
							<div className="widget-content-left">
								<div className="widget-heading">Załączniki ({`${files.length}`})</div>
							</div>
						</div>
					</div>
					<UncontrolledCollapse toggler="#attachments">
						<ListGroup flush>
							{files.map((attachment, index) => (
								<ListGroupItem key={`attachments-${index}`} id={`attachments-${index}`}>
									<div className="widget-content p-0">
										<div className="widget-content-wrapper">
											<div className="widget-content-left mr-2">
												<i className={`pe-7s-angle-right`} />
											</div>
											<div className="widget-content-left">
												<div className="widget-subheading">{attachment}</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
							))}
						</ListGroup>
					</UncontrolledCollapse>
				</ListGroupItem>
			)}
		</ListGroup>
	</Fragment>
);

class ArchiveBIPManageCard extends Component {
	state = {
		isOpen: false,
		siteType: null,
		sites: [],
		subSites: [],
		siteAttachments: [],
		sitename: '',
		rootSite: '',
		localArchiveBipBlocking: false
	};

	componentDidMount() {
		this.props.getArchiveBip();
	}

	getSiteInfo = async (sitename = '') => {
		try {
			this.setState({ localArchiveBipBlocking: true });
			const response = await axios.get(`/api/archive?sitename=${encodeURIComponent(sitename)}`);
			const { type, sites, subs, files, rootSite } = response.data;
			this.setState({
				isOpen: true,
				siteType: type,
				sites: sites,
				subSites: subs,
				siteAttachments: files,
				sitename: sitename,
				rootSite: rootSite,
				localArchiveBipBlocking: false
			});
		} catch (err) {
			this.setState({ localArchiveBipBlocking: false });
			toast.error('Wskazana strona nie istnieje lub została już usunięta');
		}
	};

	clearState = () =>
		this.setState({
			isOpen: false,
			siteType: null,
			sites: [],
			subSites: [],
			siteAttachments: [],
			sitename: '',
			rootSite: '',
			localArchiveBipBlocking: false
		});

	removeSite = (sitename = '') => {
		this.props.removeArchiveSite(sitename);
		this.clearState();
	};

	render() {
		const {
			isOpen,
			siteType,
			sites,
			subSites,
			siteAttachments,
			sitename,
			rootSite,
			localArchiveBipBlocking
		} = this.state;
		const { archiveBipBlocking, maxFileSize, isSitemapImported, clientId } = this.props;

		return (
			<Fragment>
				<PageTitle
					heading="Zarządzanie wersją archiwalną BIP"
					icon="pe-7s-box1 icon-gradient bg-tempting-azure"
				/>
				<BlockUi
					tag="div"
					blocking={archiveBipBlocking || localArchiveBipBlocking}
					loader={<Loader active type={LoaderType} />}
				>
					<Card className="mb-3">
						<CardHeader>{COMMON_INFORMATION}</CardHeader>
						<CardBody>
							{!isSitemapImported && (
								<Row>
									{
										// <Col>
										// 	<FormGroup>
										// 		<Label for="title">{SITENAME_FILE}</Label>
										// 		<UploadFile
										// 			disabled={isSitemapImported}
										// 			canRemove={false}
										// 			maxFileSize={maxFileSize}
										// 			acceptFileType={`${DocumentMimeType.JSON}`}
										// 			uploadUrl={'/api/files/archive-sitemap'}
										// 			acceptExtensionFiles={[FileExtensionTypes.JSON]}
										// 			additionalInfo={'wymagany format pliku *.json'}
										// 			onDrop={Function.prototype}
										// 			onDropAccepted={() => this.props.getArchiveBip()}
										// 			onDropRejected={(file) => fileUploadError(file, maxFileSize)}
										// 			onCancel={Function.prototype}
										// 			isIsoCode={false}
										// 			fileParamName={'sitemap'}
										// 		/>
										// 	</FormGroup>
										// </Col>
									}
									<Col>
										<Button
											className="btn-icon mr-2"
											color="primary"
											type="button"
											onClick={e => this.props.getArchiveBipByClientId(clientId)}
											disabled={isSitemapImported}
										>
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{CONNECT_WITH_ARCHIVE_BIP}
										</Button>
									</Col>
								</Row>
							)}
							{isSitemapImported && <Alert color="info">{SITEMAP_FILE_LOADED}</Alert>}
						</CardBody>
					</Card>
					<Card className="mb-3">
						<CardHeader>{OPERATIONS}</CardHeader>
						<CardBody>
							<Formik
								enableReinitialize
								initialValues={{ sitename: sitename }}
								onSubmit={(_) => this.getSiteInfo(_.sitename)}
								validationSchema={SitenameSchema}
							>
								{({ values, errors, isValid, handleChange, handleSubmit }) => (
									<Fragment>
										<Row>
											<Col>
												<FormGroup>
													<Label for="title">{SITENAME}</Label>
													<Input
														type="text"
														id="sitename"
														name="sitename"
														value={values.sitename}
														onChange={handleChange}
														invalid={!!errors.sitename}
														disabled={!isSitemapImported}
													/>
													{errors.sitename && (
														<FormFeedback type="invalid">{errors.sitename}</FormFeedback>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<Button
													className="btn-icon mr-2"
													color="danger"
													type="submit"
													onClick={handleSubmit}
													disabled={!isSitemapImported || !isValid}
												>
													<i className="pe-7s-trash btn-icon-wrapper" />
													{REMOVE_SITE}
												</Button>
											</Col>
										</Row>
									</Fragment>
								)}
							</Formik>
						</CardBody>
					</Card>
					{isOpen && (
						<Card className="mb-3">
							<CardHeader>{OPERATIONS}</CardHeader>
							<CardBody>
								<Row>
									<Col>
										{siteType === 0 && (
											<Fragment>
												<p>Czy chcesz usunąć wszystkie te adresy?</p>
												<SitesList
													sitename={sitename}
													sites={sites}
													subs={subSites}
													files={siteAttachments}
												/>
											</Fragment>
										)}
										{siteType === 10 && (
											<div>
												<p>
													Strona {sitename} jest podstroną strony {rootSite}. Nie możemy
													usunąć samej podstrony. Czy chcesz usunąć adres podstrony wraz z
													poniższymi adresami stron?
												</p>
												<SitesList
													sitename={rootSite}
													sites={sites}
													subs={subSites}
													files={siteAttachments}
												/>
											</div>
										)}
										{siteType === 20 && (
											<div>
												<p>Czy chcesz usunąć wybrany załącznik/-i?</p>
												<SitesList
													sitename={sitename}
													sites={sites}
													subs={subSites}
													files={siteAttachments}
												/>
											</div>
										)}
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<Row className="d-flex justify-content-between">
									<Col>
										<Button
											className="btn-icon mr-2"
											color="danger"
											type="button"
											onClick={(_) => this.removeSite(sitename)}
										>
											<i className="pe-7s-trash btn-icon-wrapper" />Tak
										</Button>
										<Button
											className="btn-icon mr-2"
											color="secondary"
											type="button"
											onClick={this.clearState}
										>
											<i className="pe-7s-back btn-icon-wrapper" />Nie
										</Button>
									</Col>
								</Row>
							</CardFooter>
						</Card>
					)}
				</BlockUi>
			</Fragment>
		);
	}
}

const mapStateToProps = (state, ownProp) => {
	return {
		activeLanguage: state.application.activeLanguage,
		archiveHost: state.application.archiveBip.archiveHost || '',
		clientId: state.application.clientId || '',
		isSitemapImported: state.application.archiveBip.isSitemapImported || false,
		archiveBipBlocking: state.uiBlockState.archiveBipBlocking || false,
		maxFileSize: state.application.maxFileSize
	};
};

const mapDispatchToProps = (dispatch, ownProp) => {
	return {
		...bindActionCreators({ ...ApplicationActionCreators, ...ArchiveActionCreators }, dispatch),
		cancel: () => dispatch(goBack())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveBIPManageCard);
