import React from 'react';

import Grid from '../../../../components/Grid';
import { filterCell } from './TileListHelpers';

const columns = [
	{ name: 'id', title: 'id' },
	{ name: 'displayText', title: 'Nazwa wyświetlana kafla' },
	{ name: 'title', title: 'Tekst pod czytniki dla osób słabowidzących' }
];
const columnExtensions = [ { columnName: 'displayText', wordWrapEnabled: true } ];
const hiddenColumns = [ 'id' ];
const textColumns = [ 'displayText' ];
const currentPage = 0;

export const TileList = ({ ...props }) => (
	<Grid
		items={props.items}
		totalItems={props.totalItems}
		columns={columns}
		columnExtensions={columnExtensions}
		hiddenColumns={hiddenColumns}
		textColumns={textColumns}
		currentPage={currentPage}
		filterCell={filterCell}
		onReload={props.onReload}
		removeable={false}
		addable={false}
		filterable={false}
		sortable={false}
		pagination={false}
		onRowClick={props.onRowClick}
		internationalizable={props.internationalizable}
		language={props.language}
		languages={props.languages}
		rightButtons={props.rightButtons}
		onChangeLanguage={props.onChangeLanguage}
		filters={props.filters}
		onChangeFilters={props.onChangeFilters}
		onClearFilters={props.onClearFilters}
	/>
);
