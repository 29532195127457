import { createAction } from '../base';

const LoadUsers = createAction('LoadUsers');
const GetUser = createAction('GetUser');
const CreateUser = createAction('CreateUser');
const UpdateUser = createAction('UpdateUser');
const DeleteUsers = createAction('DeleteUsers');
const ActiveUser = createAction('ActiveUser');
const DeactiveUser = createAction('DeactiveUser');
const SetLoggedUser = createAction('SetLoggedUser');
const ChangePassword = createAction('ChangePassword');
const ResetPassword = createAction('ResetPassword');
const GetPermissions = createAction('GetPermissions');
const GetLoggedUserData = createAction('GetLoggedUserData');
const SetCategoryPermissionUserData = createAction('SetCategoryPermissionUserData');
const GetUserExcludeCategories = createAction('GetUserExcludeCategories');

function loadUsers(page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: LoadUsers.Request,
		payload: { page, size, filters, sortings }
	};
}
function getUser(id) {
	return {
		type: GetUser.Request,
		payload: id
	};
}
function createUser(user) {
	return {
		type: CreateUser.Request,
		payload: user
	};
}
function updateUser(user) {
	return {
		type: UpdateUser.Request,
		payload: user
	};
}
function deleteUsers(userIds = []) {
	return {
		type: DeleteUsers.Request,
		payload: userIds
	};
}
function activeUser(id) {
	return {
		type: ActiveUser.Request,
		payload: id
	};
}
function deactiveUser(id) {
	return {
		type: DeactiveUser.Request,
		payload: id
	};
}
function setLoggedUser(user = {}) {
	return {
		type: SetLoggedUser.Request,
		payload: user
	};
}
function changePassword(identityUserId, changePassword) {
	return {
		type: ChangePassword.Request,
		payload: { identityUserId, changePassword }
	};
}
function getPermissions() {
	return { type: GetPermissions.Request };
}
function getLoggedUserData() {
	return { type: GetLoggedUserData.Request };
}
function setCategoryPermissionUserData(id) {
	return {
		type: SetCategoryPermissionUserData.Success,
		payload: {
			id
		}
	};
}
function resetPassword(identityUserId) {
	return {
		type: ResetPassword.Request,
		payload: identityUserId
	};
}

function getUserExcludeCategories() {
	return { type: GetUserExcludeCategories.Request };
}

export const UserActions = {
	LoadUsers,
	GetUser,
	CreateUser,
	UpdateUser,
	DeleteUsers,
	ActiveUser,
	DeactiveUser,
	SetLoggedUser,
	ChangePassword,
	ResetPassword,
	GetPermissions,
	GetLoggedUserData,
	SetCategoryPermissionUserData,
	GetUserExcludeCategories
};

export const UserActionCreators = {
	loadUsers,
	getUser,
	createUser,
	updateUser,
	deleteUsers,
	activeUser,
	deactiveUser,
	setLoggedUser,
	changePassword,
	resetPassword,
	getPermissions,
	getLoggedUserData,
	setCategoryPermissionUserData,
	getUserExcludeCategories
};
