import Immutable from 'seamless-immutable';
import { Dictionary } from '../stores';
import { DictionaryActions } from '../actions/dictionary';

const initialState = Immutable(Dictionary);

export function dictionary(state = initialState, action = {}) {
	switch (action.type) {
		case DictionaryActions.UnloadDictionaryAction.Success: {
			return state.merge({
				tag: {
					tag: null,
					tags: [],
					totalTags: 0
				},
				dictionaryEntry: {
					dictionaryEntry: null,
					dictionaryEntries: [],
					totalDictionaryEntries: 0
				},
				isoCode: {
					isoCode: null,
					isoCodes: [],
					totalIsoCodes: 0
				}
			});
		}
		
		case DictionaryActions.GetNonDefaultIsoCodesAction.Success:
		case DictionaryActions.GetAllIsoCodesAction.Success: {
			const isoCode = Immutable(state).getIn(['isoCode']);
			const newIsoCode = Immutable(isoCode).merge({
				isoCodes: action.payload.items,
				totalIsoCodes: action.payload.totalItems
			});
			return state.setIn([ 'isoCode' ], newIsoCode);
		}
		case DictionaryActions.GetIsoCodeAction.Success: {
			const isoCode = Immutable(state).getIn(['isoCode']);
			const newIsoCode = Immutable(isoCode).merge({
				isoCode: action.payload
			});
			return state.setIn([ 'isoCode' ], newIsoCode);
		}
		case DictionaryActions.CreateIsoCodeAction.Success: {
			const isoCode = Immutable(state).getIn(['isoCode']);
			const newIsoCode = Immutable(isoCode).merge({
				isoCode: action.payload
			});
			return state.setIn([ 'isoCode' ], newIsoCode);
		}
		case DictionaryActions.RemoveIsoCodesAction.Success: {
			const isoCodes = Immutable(state).getIn(['isoCode', 'isoCodes']);
			const newIsoCodes = [];
			isoCodes.reduce((prev, curr) => {
				if (!action.payload.includes(curr.id)) {
					prev.push(curr);
					return prev;
				}
				return prev;
			}, newIsoCodes);
			return state.setIn([ 'isoCode', 'isoCodes' ], newIsoCodes);
		}
		
		case DictionaryActions.GetTagAction.Success: {
			const tag = Immutable(state).getIn(['tag']);
			const newTag = Immutable(tag).merge({
				tag: action.payload
			});
			return state.setIn([ 'tag' ], newTag);
		}
		case DictionaryActions.GetAllTagsAction.Success: {
			const tag = Immutable(state).getIn(['tag']);
			const newTag = Immutable(tag).merge({
				tags: action.payload.items,
				totalTags: action.payload.totalItems
			});
			return state.setIn([ 'tag' ], newTag);
		}
		case DictionaryActions.CreateTagAction.Success: {
			const tag = Immutable(state).getIn(['tag']);
			const newTag = Immutable(tag).merge({
				tag: action.payload
			});
			return state.setIn([ 'tag' ], newTag);
		}
		case DictionaryActions.RemoveTagsAction.Success: {
			const tags = Immutable(state).getIn(['tag', 'tags']);
			const newTags = [];
			tags.reduce((prev, curr) => {
				if (!action.payload.includes(curr.id)) {
					prev.push(curr);
					return prev;
				}
				return prev;
			}, newTags);
			return state.setIn([ 'tag', 'tags' ], newTags);
		}

		case DictionaryActions.GetDictionaryEntryAction.Success: {
			const dictionaryEntry = Immutable(state).getIn(['dictionaryEntry']);
			const newDictionaryEntry = Immutable(dictionaryEntry).merge({
				dictionaryEntry: action.payload
			});
			return state.setIn([ 'dictionaryEntry' ], newDictionaryEntry);
		}
		case DictionaryActions.GetAllDictionaryEntriesAction.Success: {
			const dictionaryEntry = Immutable(state).getIn(['dictionaryEntry']);
			const newDictionaryEntry = Immutable(dictionaryEntry).merge({
				dictionaryEntries: action.payload.items,
				totalDictionaryEntries: action.payload.totalItems
			});
			return state.setIn([ 'dictionaryEntry' ], newDictionaryEntry);
		}
		case DictionaryActions.CreateDictionaryEntryAction.Success: {
			const dictionaryEntry = Immutable(state).getIn(['dictionaryEntry']);
			const newDictionaryEntry = Immutable(dictionaryEntry).merge({
				dictionaryEntry: action.payload
			});
			return state.setIn([ 'dictionaryEntry' ], newDictionaryEntry);
		}
		case DictionaryActions.RemoveDictionaryEntryAction.Success: {
			const dictionaryEntries = Immutable(state).getIn(['dictionaryEntry', 'dictionaryEntries']);
			const newDictionaryEntries = [];
			dictionaryEntries.reduce((prev, curr) => {
				if (!action.payload.includes(curr.id)) {
					prev.push(curr);
					return prev;
				}
				return prev;
			}, newDictionaryEntries);
			return state.setIn([ 'dictionaryEntry', 'dictionaryEntries' ], newDictionaryEntries);
		}
		default: {
			return state;
		}
	}
}
