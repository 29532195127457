import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";

import { SystemParameterList } from "./SystemParameterList";
import { SettingActionCreators } from "../../../../actions/settings";

import PageTitle from "../../../../Layout/AppMain/PageTitle";

import { LoaderType, PageSize } from "../../../../helpers/enums";
import EditParameterModal from "./EditParameterModal";
import { FilterActionCreators } from "../../../../actions/filter";

class SystemParameters extends Component {
  state = {
    showEditParameterModal: false,
    parameterId: null,
  };
  componentDidMount() {
    const { systemParameterFilters } = this.props;
    this.props.getAllSystemParameters(
      0,
      PageSize.Rows20,
      systemParameterFilters,
      []
    );
  }

  componentWillUnmount() {
    this.props.unloadSetting();
  }
  toggleEditParameterModal = () =>
    this.setState({
      showEditParameterModal: false,
      parameterId: null,
    });
  onRowClick = (row) =>
    this.setState({
      showEditParameterModal: true,
      parameterId: row.id,
    }); //this.props.goTo(RouteUrls.Setting.language.editFunc(row.id));

  onReload = (page, size, filters, sortings) => {
    const { getAllSystemParameters, systemParameterFilters } = this.props;
    getAllSystemParameters(page, size, systemParameterFilters, sortings);
  };

  clearFilters = () => {
    const { getAllSystemParameters, clearFilters } = this.props;
    clearFilters(["systemParameterFilters"], []);
    getAllSystemParameters(0, PageSize.Rows20, [], []);
  };

  render() {
    const {
      systemParameters,
      totalSystemParameters,
      systemParameterListBlocking,
      systemParameterFilters,
      changeFilters,
    } = this.props;

    const { showEditParameterModal, parameterId } = this.state;

    return (
      <Fragment>
        <PageTitle
          heading="Lista parametrów systemowych"
          icon="pe-7s-settings icon-gradient bg-tempting-azure"
        />

        <BlockUi
          tag="div"
          blocking={systemParameterListBlocking}
          loader={<Loader active type={LoaderType} />}
        >
          <EditParameterModal
            isOpen={showEditParameterModal}
            onToggle={this.toggleEditParameterModal}
            parameterId={parameterId}
          />
          <SystemParameterList
            items={systemParameters}
            totalItems={totalSystemParameters}
            onReload={this.onReload}
            onRowClick={this.onRowClick}
            filters={systemParameterFilters}
            onChangeFilters={(filters) =>
              changeFilters(["systemParameterFilters"], filters)
            }
            onClearFilters={this.clearFilters}
          />
        </BlockUi>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    systemParameterFilters: state.filter.systemParameterFilters || [],
    systemParameters: state.setting.systemParameters || [],
    totalSystemParameters: state.setting.totalSystemParameters || 0,
    systemParameterListBlocking:
      state.uiBlockState.systemParameterListBlocking || false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...bindActionCreators(
      { ...SettingActionCreators, ...FilterActionCreators },
      dispatch
    ),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemParameters);
