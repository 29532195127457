import React from "react";
import { FormGroup, Label, FormFeedback } from "reactstrap";

import cx from "classnames";

export const CheckboxInput = ({ label, name, value, error, setFieldValue }) => {
  return (
    <FormGroup className="d-flex align-items-center">
      <Label for="checkbox" className="mr-2">
        {label}
      </Label>
      <div
        className="switch has-switch mb-2 mr-2"
        data-on-label="Tak"
        data-off-label="Nie"
        onClick={() => {
          setFieldValue(name, !value);
        }}
        invalid={!!error}
      >
        <div
          className={cx("switch-animate", {
            "switch-on": value,
            "switch-off": !value,
          })}
        >
          <input type="checkbox" />
          <span className="switch-left">Tak</span>
          <label>&nbsp;</label>
          <span className="switch-right">Nie</span>
        </div>
      </div>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};
