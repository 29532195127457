import * as yup from 'yup';
import { peselValidator } from './standard-validators';
import { IdentificationDocumentType } from '../helpers/enums';

export const UserValidationSchema = yup.object().shape({
	userName: yup
		.string()
		.required('Login jest polem wymaganym')
		.matches(
			/^[a-zA-Z0-9\\@\\_\\\-\\.]+$/,
			'Login może składać się tylko z liter (bez polskich znaków) i liczb oraz znaków specjalnych (@_-.)'
		)
		.min(6, 'Login musi zawierać przynajmniej 6 znaków')
		.max(256, 'Login może zawierać maksymalnie 256 znaków'),
	email: yup
		.string()
		.required('E-mail jest polem wymaganym')
		.max(256, 'E-mail może zawierać maksymalnie 256 znaków')
		.email('Wartość w polu E-mail nie jest prawidłowym formatem adresu e-mail'),
	firstname: yup.string().max(30, 'Imię może zawierać maksymalnie 30 znaków').required('Imię jest polem wymaganym'),
	surname: yup
		.string()
		.max(40, 'Nazwisko może zawierać maksymalnie 40 znaków')
		.required('Nazwisko jest polem wymaganym'),
	phone: yup.string().max(30, 'Telefon może zawierać maksymalnie 30 znaków').nullable(),
	mobilePhone: yup.string().max(30, 'Telefon komórkowy może zawierać maksymalnie 40 znaków').nullable(),
	fax: yup.string().max(50, 'Fax może zawierać maksymalnie 50 znaków').nullable(),
	pesel: yup.string().when('identificationDocumentType', (identificationDocumentType) => {
		if (identificationDocumentType !== IdentificationDocumentType.PESEL.value) {
			return yup
				.string()
				.max(11, 'PESEL może zawierać maksymalnie 11 znaków')
				.test('pesel-validator', 'Nieprawidłowy numer PESEL', (value) => {
					if (value && value !== '') {
						return peselValidator(value);
					}
					return true;
				});
		} else {
			return yup
				.string()
				.required('PESEL jest polem wymaganym')
				.max(11, 'PESEL może zawierać maksymalnie 11 znaków')
				.test('pesel-validator', 'Nieprawidłowy numer PESEL', (value) => {
					if (value && value !== '') {
						return peselValidator(value);
					}
					return true;
				});
		}
	}),
	identificationDocumentType: yup.number().nullable(),
	identificationDocumentNumber: yup.string().when('identificationDocumentType', (identificationDocumentType) => {
		if (identificationDocumentType > IdentificationDocumentType.PESEL.value) {
			return yup
				.string()
				.max(50, 'Identyfikator (Numer i seria dokumentu tożsamości) może zawierać maksymalnie 50 znaków')
				.required('Identyfikator (Numer i seria dokumentu tożsamości) jest polem wymaganym');
		}
		return yup.string();
	}),
	birthDate: yup.date().when('identificationDocumentType', (identificationDocumentType) => {
		if (
			identificationDocumentType !== IdentificationDocumentType.PESEL.value &&
			identificationDocumentType !== IdentificationDocumentType.Unknown.value
		) {
			return yup.date().required('Data urodzenia jest polem wymaganym');
		}
		return yup.date();
	}),
	sex: yup.number().when('identificationDocumentType', (identificationDocumentType) => {
		if (
			identificationDocumentType !== IdentificationDocumentType.PESEL.value &&
			identificationDocumentType !== IdentificationDocumentType.Unknown.value
		) {
			return yup.number().min(10, 'Płeć jest polem wymaganym');
		}
		return yup.number();
	}),
	roles: yup.array().min(1, 'Użytkownik musi mieć przypisaną przynajmniej jedną role')
});

export const ChangePasswordValidationSchema = yup.object().shape({
	currentPassword: yup.string().required('Aktualne hasło jest wymagane'),
	newPassword: yup
		.string()
		.min(6, 'Minimalna długość hasła musi zawierać 6 znaków')
		.matches(/(\d{1,})/, 'Hasło musi zawierać cyfrę')
		.matches(/([A-Z]{1,})/, 'Hasło musi zawierać wielką literę')
		.matches(/([a-z]{1,})/, 'Hasło musi zawierać małą literę'),
	confirmNewPassword: yup
		.string()
		.oneOf([ yup.ref('newPassword') ], 'Nowe hasło różni się od potwierdzonego hasła')
});
