import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';

import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { MultimediaCardItem } from '../../Pages/Articles/Common/Card/Cards/MultimediaCardItem';

const ListHeader = ({ columns }) => (
	<ListGroup className="todo-list-wrapper" flush>
		<ListGroupItem>
			<div className="widget-content p-0">
				<div className="widget-content-wrapper">
					<div className="widget-content-left d-flex width-100">
						{map(columns, (item, index) => <div className={item.class}>{item.name}</div>)}
					</div>
				</div>
			</div>
		</ListGroupItem>
	</ListGroup>
);

const MultimediaSortableItem = SortableElement(
	({
		key = '',
		index = 0,
		editable = true,
		removeable = true,
		multimedia = { title: '' },
		maxFileSize = 10,
		onRemove = (index) => {},
		onMove = (index, multimedia) => {},
		onChange = (index, multimedia) => {}
	}) => (
		<MultimediaCardItem
			{...{
				multimedia,
				key,
				editable,
				removeable,
				maxFileSize,
				onMove,
				onRemove,
				onChange
			}}
		/>
	)
);

const MultimediaSortableContainer = SortableContainer(
	({
		name,
		items,
		disabled = false,
		editable = false,
		removeable = false,
		prefixName,
		grabbing = false,
		maxFileSize = 10,
		onRemove = (index) => {},
		onMove = (index, item) => {},
		onChange = (index, item) => {}
	}) => (
		<ListGroup className="todo-list-wrapper multimedia" style={{ cursor: `${grabbing ? 'grabbing' : 'grab'}` }} flush>
			{map(items, (multimedia, multimediaIndex) => (
				<MultimediaSortableItem
					key={`${prefixName}-${multimediaIndex}`}
					{...{
						disabled,
						multimedia,
						index: multimediaIndex,
						editable,
						removeable,
						maxFileSize,
						onMove,
						onRemove,
						onChange
					}}
				/>
			))}
		</ListGroup>
	)
);

const MultimediaSortable = (props) => {
	const [ grabbing, setGrabbing ] = useState(false);
	const {
		items,
		columns = [],
		editable,
		removeable,
		disabled,
		prefixName,
		maxFileSize,
		name,
		onRemove,
		onMove
	} = props;

	const onSortEnd = ({ newIndex, oldIndex, ...restProps }) => {
		const { onChange, items, name } = props;

		setGrabbing(false);
		const newItems = arrayMove(items, oldIndex, newIndex);
		if (onChange && typeof onChange === 'function') {
			onChange(name, newItems);
		}
	};

	return (
		<Fragment>
			{!isEmpty(items) && !isEmpty(columns) && <ListHeader columns={columns} />}
			<MultimediaSortableContainer
				helperClass="sortableHelper"
				{...{
					name,
					items,
					disabled,
					editable,
					removeable,
					prefixName,
					grabbing,
					maxFileSize,
					onRemove,
					onMove,
					onChange: onMove
				}}
				pressDelay={100}
				onSortEnd={(sortEnd, event) => {
					onSortEnd(sortEnd);
				}}
				onSortStart={(sortStart, event) => {
					setGrabbing(true);
				}}
			/>
		</Fragment>
	);
};

export default MultimediaSortable;

MultimediaSortable.propTypes = {
	items: PropTypes.array.isRequired,
	columns: PropTypes.array,
	editable: PropTypes.bool.isRequired,
	removeable: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	prefixName: PropTypes.string.isRequired,
	maxFileSize: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
};
