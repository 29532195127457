import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";
import map from "lodash.map";
import filter from "lodash.filter";
import { ArticleActionCreators } from "../actions/article";
import ArticleListItem from "./DragAndDropSources/ArticleSource";
import { ArticleStatus, ArticleType } from "../helpers/enums";

import RSC from "react-scrollbars-custom";

class ArticleList extends Component {
  static defaultProps = {
    langIsoCode: "",
    excludeArticles: [],
    types: [ArticleType.Common.value],
    defaultFilter: [
      {
        columnName: "status",
        operation: "lessThanOrEqual",
        value: ArticleStatus.Published.value,
      },
    ],
  };

  render() {
    const {
      articles,
      excludeArticles,
      showButtons = false,
      onChangeItems,
      items,
      type,
      search: searchCategory,
      articlesMenu,
    } = this.props;

    return (
      <RSC>
        <div className="rst__tree" style={{ position: "relative" }}>
          <div style={{ overflow: "visible", height: "0px", width: "0px" }}>
            <div
              aria-label="grid"
              aria-readonly="true"
              className="ReactVirtualized__Grid ReactVirtualized__List rst__virtualScrollOverride"
              role="grid"
              tabindex="0"
              style={{
                boxSizing: "border-box",
                direction: "ltr",
                position: "relative",
                willChange: "transform",
                overflow: "hidden",
              }}
            >
              {map(
                filter(
                  articles,
                  (article) => !excludeArticles.includes(article.id)
                ),
                (article, articleIndex) => (
                  <ArticleListItem
                    {...{
                      searchCategory,
                      type,
                      onChangeItems,
                      articlesMenu,
                      showButtons,
                      items,
                      ...article,
                    }}
                    key={`article-${article.id}-${articleIndex}`}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </RSC>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    articles: state.article.articles || [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...bindActionCreators(ArticleActionCreators, dispatch),
    cancel: () => dispatch(goBack()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);
