import React, { Fragment, useState } from 'react';
import { Button, ListGroupItem } from 'reactstrap';
import { format as dateFnsFormat } from 'date-fns';

import { AddAdmissionHourModal } from '../../../../../components/Modals/AddAdmissionHourModal';
import { DayOfWeeks } from '../../../../../helpers/enums';

const getDayName = (dayOfWeek) => {
	switch (dayOfWeek) {
		case DayOfWeeks.Monday.value:
			return DayOfWeeks.Monday.name;
		case DayOfWeeks.Tuesday.value:
			return DayOfWeeks.Tuesday.name;
		case DayOfWeeks.Wednesday.value:
			return DayOfWeeks.Wednesday.name;
		case DayOfWeeks.Thursday.value:
			return DayOfWeeks.Thursday.name;
		case DayOfWeeks.Friday.value:
			return DayOfWeeks.Friday.name;
		case DayOfWeeks.Saturday.value:
			return DayOfWeeks.Saturday.name;
		case DayOfWeeks.Sunday.value:
			return DayOfWeeks.Sunday.name;
		default:
			return '';
	}
};

export const AdmissionHourItem = ({
	index = 0,
	admissionHour = {
		dayOfWeek: -1,
		timeFrom: '',
		timeTo: ''
	},
	removeable = true,
	editable = true,
	disabled = false,
	isIntegratedWithASW = false,
	onRemove = (index) => console.log(`onRemove index ${index}`),
	onChange = (index, admissionHour) => console.log(`onChange index ${index}`)
}) => {
	const [ isOpen, setIsOpen ] = useState(false);
	const handleSaveLink = (admissionHour) => {
		if (onChange && typeof onChange === 'function') {
			onChange(index, { ...admissionHour, dayOfWeek: parseInt(admissionHour.dayOfWeek) });
		}
	};

	const handleRemoveLink = () => {
		if (onRemove && typeof onRemove === 'function') {
			onRemove(index);
		}
	};
	const hoursText = `${getDayName(admissionHour.dayOfWeek)} (${dateFnsFormat(
		new Date(admissionHour.timeFrom),
		'HH:mm'
	)} - ${dateFnsFormat(new Date(admissionHour.timeTo), 'HH:mm')})`;
	return (
		<Fragment>
			{!isOpen && (
				<ListGroupItem>
					<div className="widget-content p-0">
						<div className="widget-content-wrapper">
							<div title={hoursText} className="widget-content-left flex2">
								{hoursText}
							</div>
							<div className="widget-content-right">
								{editable &&
								!isIntegratedWithASW && (
									<Button
										className="border-0 btn-transition"
										outline
										color="success"
										title="Edytuj link"
										onClick={() => setIsOpen(!isOpen)}
										disabled={disabled}
									>
										<i className="lnr-pencil" />
									</Button>
								)}
								{removeable &&
								!isIntegratedWithASW && (
									<Button
										className="border-0 btn-transition"
										outline
										color="danger"
										title="Usuń link"
										onClick={() => handleRemoveLink()}
										disabled={disabled}
									>
										<i className="lnr-cross" />
									</Button>
								)}
							</div>
						</div>
					</div>
				</ListGroupItem>
			)}
			{isOpen && (
				<AddAdmissionHourModal
					{...{
						admissionHour,
						isOpen,
						key: `admission-hour-${index}`,
						index: index,
						onToggle: (_) => setIsOpen(!isOpen),
						onSave: (multimedia) => handleSaveLink(multimedia)
					}}
				/>
			)}
		</Fragment>
	);
};
