import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'connected-react-router';
import {Formik} from 'formik';

import {Col, Button, Form, FormGroup, Label, Input, FormFeedback} from 'reactstrap';
import BlockUi from 'react-block-ui';
import {Loader} from 'react-loaders';

import { UserActionCreators } from '../../actions/auth/user';
import { ChangePasswordValidationSchema } from '../../validators/user';
import { DefaultFormikConfig, LoaderType } from '../../helpers/enums';
// Layout

class ChangePassword extends Component {
	render() {
		const { changePassword, changePasswordBlocking } = this.props;
		const changePasswordModel = {
			currentPassword: '',
			newPassword: '',
			confirmNewPassword: '',
			from: this.props.from
		};
		const identityUserId = this.props.match.params.identityUserId;

		return (
			<Formik
				{...DefaultFormikConfig}
				initialValues={changePasswordModel}
				validationSchema={ChangePasswordValidationSchema}
			>
				{({values, handleChange, handleBlur, isValid, errors}) => (

					<BlockUi
					tag="div"
					blocking={changePasswordBlocking}
					loader={<Loader active type={LoaderType} />}
				>
					<Form
						noValidate
						method="post"
						onSubmit={(e) => {
							e.preventDefault();
							if (values.userId !== null) {
								changePassword(identityUserId, values);
							}
						}}
					>
						<Fragment>
							<div className="h-100 bg-heavy-rain bg-animation">
								<div className="d-flex h-100 justify-content-center align-items-center">
									<Col md="6" className="mx-auto app-login-box">
										<div className="mx-auto mb-3" />

										<div className="modal-dialog w-100">
											<div className="modal-content">
												<div className="modal-header">
													<div className="h5 modal-title">Zmień hasło</div>
												</div>
												<div className="modal-body">
													<div>
														<FormGroup>
															<Label for="currentPassword">Aktualne hasło</Label>
															<Input
																type="password"
																id="currentPassword"
																name="currentPassword"
																placeholder="Podaj aktualne hasło"
																value={values.currentPassword}
																onChange={handleChange}
																onBlur={handleBlur}
																invalid={!!errors.currentPassword}
															/>
															{errors.currentPassword && (
																<FormFeedback type="invalid">
																	{errors.currentPassword}
																</FormFeedback>
															)}
														</FormGroup>
														<FormGroup>
															<Label for="newPassword">Nowe hasło</Label>
															<Input
																type="password"
																id="newPassword"
																name="newPassword"
																placeholder="Podaj nowe hasło"
																value={values.newPassword}
																onChange={handleChange}
																onBlur={handleBlur}
																invalid={!!errors.newPassword}
															/>
															{errors.newPassword && (
																<FormFeedback type="invalid">
																	{errors.newPassword}
																</FormFeedback>
															)}
														</FormGroup>
														<FormGroup>
															<Label for="confirmNewPassword">Potwierdź nowe hasło</Label>
															<Input
																type="password"
																id="confirmNewPassword"
																name="confirmNewPassword"
																placeholder="Potwierdź nowe hasło"
																value={values.confirmNewPassword}
																onChange={handleChange}
																onBlur={handleBlur}
																invalid={!!errors.confirmNewPassword}
															/>
															{errors.confirmNewPassword && (
																<FormFeedback type="invalid">
																	{errors.confirmNewPassword}
																</FormFeedback>
															)}
														</FormGroup>
													</div>
												</div>

												<div className="modal-footer clearfix">
													<div className="float-right mr-2">
														<Button
															color="primary"
															type="submit"
															size="lg"
															disabled={!isValid}
														>
															Zapisz
														</Button>
													</div>
													<Button
														className="btn-icon"
														type="reset"
														color="secondary"
														onClick={this.props.cancel}
													>
														<i className="pe-7s-back btn-icon-wrapper"> </i>
														Anuluj
													</Button>
												</div>
											</div>
										</div>
									</Col>
								</div>
							</div>
						</Fragment>
						</Form>
						</BlockUi>
				)}
			</Formik>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let { from } = ownProps.location.state || {
		from: { pathname: '/' }
	};
	return {
		from: from.pathname,
		changePasswordBlocking: state.uiBlockState.changePasswordBlocking || false,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return bindActionCreators({ ...UserActionCreators, cancel: goBack }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
