import React, { forwardRef } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  RowDragging,
  FilterRow,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import "./style.scss";

export const DEFAULT_FILTER_OPERATIONS = [
  "contains",
  "notcontains",
  "startswith",
  "endswith",
  "=",
  "<>",
];

export const DxTable = forwardRef(
  ({
    dataSource,
    columns,
    onRowUpdating,
    isRowDragging,
    onEditorPreparing,
    onEditorPrepared,
    allowUpdating = false,
    allowAdding = false,
    allowDeleting = false,
    remoteOperations = false,
    isScrolling = false,
    isFilterRow = true,
    onReorder,
    height,
    dataGridRef,
    onCellPrepared,
    onDragStart,
    allowSorting,
    onOptionChanged,
  }) => {
    return (
      <div>
        <DataGrid
          keyExpr="id"
          ref={dataGridRef}
          dataSource={dataSource}
          showBorders={true}
          onRowUpdating={onRowUpdating}
          noDataText="brak danych"
          onEditorPreparing={onEditorPreparing}
          onEditorPrepared={onEditorPrepared}
          remoteOperations={remoteOperations}
          height={height}
          onCellPrepared={onCellPrepared}
          allowSorting={allowSorting}
          onOptionChanged={onOptionChanged}
        >
          <Editing
            {...{
              allowUpdating,
              allowDeleting,
              allowAdding,
              mode: "form",
              refreshMode: "reshape",
              form: {
                labelLocation: "top",
              },
              useIcons: true,
            }}
          />
          {isScrolling ? <Scrolling mode={"infinite"} /> : null}
          <FilterRow visible={isFilterRow} />
          <HeaderFilter visible searchMode={"equals"} />
          {isRowDragging ? (
            <RowDragging
              dropFeedbackMode={"push"}
              onReorder={(e) => onReorder && onReorder(e)}
              allowReordering={true}
              allowDropInsideItem={false}
              onDragStart={(e) => onDragStart && onDragStart(e)}
            />
          ) : null}
          {columns.map((column, index) => {
            return (
              <Column
                key={index}
                {...{
                  dataField: column.dataField,
                  headerFilter: column.headerFilter || [],
                  allowHiding: true,
                  allowEditing: true,
                  allowFiltering: column.allowFiltering,
                  allowSorting: column.allowSorting,
                  allowHeaderFiltering: column.allowHeaderFiltering || false,
                  caption: column.caption,
                  defaultFilterValue: column.defaultFilterValue,
                  filterValue: column.filterValue,
                  format: column.format,
                  inputType: column.inputType,
                  width: column.width,
                  alignment: column.alignment,
                  dataType: column.dataType,
                  cellRender: column.cellRender,
                  fixed: column.fixed || false,
                  allowFixing: column.fixed,
                  filterRow: column.filterRow,
                  fixedPosition: "left",
                  options: column.options,
                  cssClass: column.cssClass,
                  filterOperations:
                    column?.filterOperations ?? DEFAULT_FILTER_OPERATIONS,
                  editCellComponent: column.editCellComponent,
                  visible: column.visible,
                  validationRules: column.validationRules,
                  defaultFilterValues: column.defaultFilterValues,
                }}
              />
            );
          })}
        </DataGrid>
      </div>
    );
  }
);
