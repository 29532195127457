import { createAction } from './base';

const GetArchiveBip = createAction('GetArchiveBip');
const GetArchiveBipByClientId = createAction('GetArchiveBipByClientId');
const RemoveArchiveSite = createAction('RemoveArchiveSite');

function getArchiveBip() {
	return {
		type: GetArchiveBip.Request
	};
}
function getArchiveBipByClientId(clientId) {
	return {
		type: GetArchiveBipByClientId.Request,
		payload: clientId
	};
}

function removeArchiveSite(sitename = '') {
	return {
		type: RemoveArchiveSite.Request,
		payload: sitename
	};
}

export const ArchiveActionCreators = {
	getArchiveBip,
	getArchiveBipByClientId,
	removeArchiveSite
};

export const ArchiveActions = {
	GetArchiveBip,
	GetArchiveBipByClientId,
	RemoveArchiveSite
};
