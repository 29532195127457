import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';

import { DictionaryActions } from '../../actions/dictionary';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const isoCodeUrl = '/api/dictionaries/iso-codes';

function* createIsoCode(action) {
	try {
		let isoCode = yield call(createIsoCodeRequest, action.payload.isoCode);

		yield put({
			type: DictionaryActions.CreateIsoCodeAction.Success,
			payload: isoCode
		});

		if (action.payload.changeUrl) {
			yield put(replace(RouteUrls.Dictionary.isoCode.editFunc(isoCode.id)));
		}
		if (action.payload.withNotification) {
			toast.success('Nowy kod ISO został utworzony');
		}
	} catch (err) {
		yield put({
			type: DictionaryActions.CreateIsoCodeAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie tworzenia nowego kodu ISO');
	}
}

function createIsoCodeRequest(isoCode) {
	return axios.post(`${isoCodeUrl}`, isoCode).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* getIsoCode(action) {
	try {
		let isoCode = yield call(getIsoCodeRequest, action.payload);

		yield put({
			type: DictionaryActions.GetIsoCodeAction.Success,
			payload: isoCode
		});
	} catch (err) {
		yield put({
			type: DictionaryActions.GetIsoCodeAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie pobierania kodu ISO');
	}
}

function getIsoCodeRequest(isoCodeId) {
	return axios.get(`${isoCodeUrl}/${isoCodeId}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* getAllIsoCodes(action) {
	try {
		let isoCodes = yield call(getListRequest, {
			url: isoCodeUrl,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: DictionaryActions.GetAllIsoCodesAction.Success,
			payload: isoCodes
		});
	} catch (err) {
		yield put({
			type: DictionaryActions.GetAllIsoCodesAction.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy kodów ISO języka');
	}
}
function* getNonDefaultIsoCodes(action) {
	try {
		let isoCodes = yield call(getListRequest, {
			url: `${isoCodeUrl}/non-default`,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: DictionaryActions.GetNonDefaultIsoCodesAction.Success,
			payload: isoCodes
		});
	} catch (err) {
		yield put({
			type: DictionaryActions.GetNonDefaultIsoCodesAction.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy kodów ISO języka');
	}
}

function* updateIsoCode(action) {
	try {
		let isoCode = yield call(updateIsoCodeRequest, action.payload.id, action.payload);

		yield put({
			type: DictionaryActions.UpdateIsoCodeAction.Success,
			payload: isoCode
		});
		toast.success('Kod ISO został zaktualizowany');
	} catch (err) {
		yield put({
			type: DictionaryActions.UpdateIsoCodeAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie aktualizacji kodu ISO');
	}
}

function updateIsoCodeRequest(id, isoCode) {
	return axios.put(`${isoCodeUrl}/${id}`, isoCode).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* removeIsoCodes(action) {
	try {
		yield call(removeIsoCodesRequest, action.payload);

		yield put({
			type: DictionaryActions.RemoveIsoCodesAction.Success,
			payload: action.payload
		});
		toast.success('Kody ISO zostały usunięte');
	} catch (err) {
		yield put({
			type: DictionaryActions.RemoveIsoCodesAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie usuwania kodów ISO');
	}
}

function removeIsoCodesRequest(isoCodeIds) {
	const filterQuery = qs.stringify({ ids: isoCodeIds }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${isoCodeUrl}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchCreateIsoCode() {
	yield takeEvery(DictionaryActions.CreateIsoCodeAction.Request, createIsoCode);
}
function* watchGetIsoCode() {
	yield takeEvery(DictionaryActions.GetIsoCodeAction.Request, getIsoCode);
}
 function* watchGetAllIsoCodes() {
 	yield takeEvery(DictionaryActions.GetAllIsoCodesAction.Request, getAllIsoCodes);
 }
 function* watchGetNonDefaultIsoCodes() {
 	yield takeEvery(DictionaryActions.GetNonDefaultIsoCodesAction.Request, getNonDefaultIsoCodes);
 }
function* watchUpdateIsoCode() {
	yield takeEvery(DictionaryActions.UpdateIsoCodeAction.Request, updateIsoCode);
}
function* watchRemoveIsoCodes() {
	yield takeEvery(DictionaryActions.RemoveIsoCodesAction.Request, removeIsoCodes);
}

 export const IsoCodeSagas = [
	fork(watchCreateIsoCode),
	fork(watchGetIsoCode),
 	fork(watchGetAllIsoCodes),
 	fork(watchGetNonDefaultIsoCodes),
	fork(watchUpdateIsoCode),
	fork(watchRemoveIsoCodes)
 ];
