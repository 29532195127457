import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { Formik, Field } from 'formik';

import {
	Form,
	FormGroup,
	FormFeedback,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Label,
	Input,
	InputGroup,
	Button
} from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import Sticky from 'react-stickynode';
import { SketchPicker } from 'react-color';

import PageTitle from '@layout/AppMain/PageTitle';
import TagCard from '@components/Cards/TagCard';

import { SettingActionCreators } from '@actions/settings';
import { LoaderType, DefaultLanguage, LinkTargetType } from '@helpers/enums';
import { getActiveLanguagesSelector } from '@reselect/language';
import { BookmarkValidationSchema } from '@validators';
import { Switch } from '../../Shared/Switch';

import { checkedContrast, checkedUrl, handleImmutable, popover, cover } from '../../utils';

import { CONSTANTS } from '@constants';
import { DefaultFormikConfig } from '../../../../helpers/enums';

const {
	DISPLAY_NAME,
	TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS,
	SAVE,
	BACK,
	COMMON_INFORMATION,
	COLOR,
	DATA_LOADING,
	WCAG_CONTRAST_REQUIRED,
	OPEN_BOOKMARK_IN_NEW_WINDOW,
	SHOW_IN_MAIN_PAGE,
	ACTIVE_VER
} = CONSTANTS;

class BookmarkCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadedData: false,
			isShowOnMainPage: false,
			color: '',
			contrast: 0,
			bookmark: {}
		};
	}

	componentDidMount() {
		const { params: { id }, path } = this.props.match;

		this.handleLoadNewBookmarks(path);
		this.handleData();

		id && this.props.getBookmark(id);
	}

	componentDidUpdate(prevProps) {
		const { id: prevId } = prevProps.match.params;
		const { id } = this.props.match.params;

		prevId && id && prevId !== id && this.props.getBookmark(id);
		prevProps.bookmark.id !== this.props.bookmark.id && this.handleData();
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}

	handleData = () => {
		const { activeLanguage, bookmark } = this.props;

		const { newElementData } = handleImmutable(bookmark, activeLanguage);
		const { contrast } = checkedContrast(newElementData.color, '#f8f8f8', '#000000');

		this.setState({
			bookmark: { ...newElementData },
			isLoadedData: true,
			contrast
		});
	};

	handleLoadNewBookmarks = (path) => {
		const { isCreate } = checkedUrl(path, 'utworz-nowa');

		isCreate && this.setState({ isLoadedData: true });
	};

	handleTitle = (title) => this.setState({ bookmark: { ...this.state.bookmark, title } });

	handleDisplayText = (displayText) => this.setState({ bookmark: { ...this.state.bookmark, displayText } });

	handleName = (name) => this.setState({ bookmark: { ...this.state.bookmark, name } });

	handleShowOnMainPage = (showOnMainPage) => this.setState({ bookmark: { ...this.state.bookmark, showOnMainPage } });

	handleIsActive = (isActive) => this.setState({ bookmark: { ...this.state.bookmark, isActive } });

	handleIsActive = (isActive) => this.setState({ bookmark: { ...this.state.bookmark, isActive } });

	handleTarget = (target) => {
		const result = target === LinkTargetType.NewTab.value ? LinkTargetType.Self.value : LinkTargetType.NewTab.value;

		this.setState({ bookmark: { ...this.state.bookmark, target: result } });
	};

	handleTags = (tags) => this.setState({ bookmark: { ...this.state.bookmark, tags } });

	handleContrast = (color) => {
		const { contrast } = checkedContrast(color, '#f8f8f8', '#000000');

		this.setState({
			bookmark: {
				...this.state.bookmark,
				color,
				contrast
			},
			contrast
		});
	};

	render() {
		const { createBookmark, updateBookmark, bookmarkCardBlocking, cancel } = this.props;

		const { bookmark: initialValues, isLoadedData, contrast: sContrast } = this.state;

		if (!isLoadedData) return <div>{DATA_LOADING}</div>;

		return (
			<Formik
				{...DefaultFormikConfig}
				{...{ initialValues }}
				validationSchema={BookmarkValidationSchema}
				onSubmit={(values) => {
					if (values.id > 0) {
						updateBookmark(values);
					} else {
						createBookmark(values);
					}
					this.handleContrast(values.color);
					values.displayColorPicker = false;
				}}
				onReset={cancel}
			>
				{({
					errors,
					values,
					handleChange,
					isValid,
					setFieldValue,
					handleSubmit: onSubmit,
					handleReset: onReset
				}) => (
					<Fragment>
						<PageTitle heading="Zakładka" icon="pe-7s-ribbon icon-gradient bg-tempting-azure" />
						<BlockUi tag="div" blocking={bookmarkCardBlocking} loader={<Loader active type={LoaderType} />}>
							<Form noValidate method="post" {...{ onSubmit, onReset }}>
								<Sticky
									enabled={true}
									top=".app-header"
									innerZ="101"
									activeClass="sticky-active-class"
									className="mb-2"
								>
									<div className="d-flex justify-content-end">
										<Button
											className="btn-icon mr-2"
											color="primary"
											type="submit"
											disabled={!isValid}
										>
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{SAVE}
										</Button>
										<Button className="btn-icon mr-2" color="secondary" type="reset">
											<i className="pe-7s-back btn-icon-wrapper" />
											{BACK}
										</Button>
									</div>
								</Sticky>
								<Card className="mb-2">
									<CardHeader>
										<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
										{COMMON_INFORMATION}
									</CardHeader>
									<CardBody>
										<Row>
											<Col>
												<FormGroup>
													<Label for="displayText">{DISPLAY_NAME}</Label>
													<Input
														type="text"
														id="displayText"
														name="displayText"
														placeholder="Wpisz nazwę"
														value={values.displayText}
														onBlur={(e) => this.handleDisplayText(e.target.value)}
														onChange={handleChange}
														invalid={!!errors.displayText}
													/>
													<FormFeedback>{errors.displayText}</FormFeedback>
												</FormGroup>
											</Col>
											{
												<Col>
													<FormGroup>
														<Label for="color">{COLOR}</Label>
														<InputGroup>
															<Input
																type="text"
																id="color"
																name="color"
																placeholder="Wpisz nazwę"
																value={values.color}
																invalid={!!errors.color}
																onFocus={(_) =>
																	setFieldValue('displayColorPicker', true)}
															/>
															<div
																className="ml-2"
																style={{
																	flex: '0 1 5%',
																	width: '20px',
																	display: 'inline-block',
																	backgroundColor: values.color
																}}
															/>
														</InputGroup>
														{values.displayColorPicker && (
															<div style={popover}>
																<div
																	style={cover}
																	onClick={(_) => {
																		setFieldValue('displayColorPicker', false);
																		this.handleContrast(values.color);
																	}}
																/>
																<SketchPicker
																	color={values.color}
																	disableAlpha={true}
																	onChangeComplete={(color) =>
																		setFieldValue('color', color.hex)}
																/>
															</div>
														)}
														<Field
															name="contrast"
															type="number"
															style={{ display: 'none' }}
															values={values.contrast}
															className={
																'form-control' + (errors.contrast ? ' is-invalid' : '')
															}
															onChange={handleChange}
														/>
														{values.contrast < 7.1 || sContrast < 7.1 ? (
															<div className="is-invalid-contrast">
																{WCAG_CONTRAST_REQUIRED}
															</div>
														) : (
															''
														)}
														<FormFeedback>{errors.color}</FormFeedback>
													</FormGroup>
												</Col>
											}
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="title">{TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS}</Label>
													<Input
														type="text"
														id="title"
														name="title"
														value={values.title}
														onBlur={(e) => this.handleTitle(e.target.value)}
														onChange={handleChange}
														invalid={!!errors.title}
													/>
													<FormFeedback>{errors.title}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<Switch
													label={OPEN_BOOKMARK_IN_NEW_WINDOW}
													onChange={handleChange}
													onClick={() => this.handleTarget(values.target)}
													on={values.target === LinkTargetType.NewTab.value}
													off={values.target === LinkTargetType.Self.value}
												/>
											</Col>
											{!values.isSystem && (
												<Col>
													<Switch
														label={SHOW_IN_MAIN_PAGE}
														onChange={handleChange}
														onClick={() =>
															this.handleShowOnMainPage(!values.showOnMainPage)}
														on={values.showOnMainPage}
														off={!values.showOnMainPage}
													/>
												</Col>
											)}
											{!values.isSystem && (
												<Col>
													<Switch
														label={ACTIVE_VER}
														onChange={handleChange}
														onClick={() => this.handleIsActive(!values.isActive)}
														on={values.isActive}
														off={!values.isActive}
													/>
													<FormFeedback
														style={{ display: `${!!errors.isActive ? 'block' : 'none'}` }}
													>
														{errors.isActive}
													</FormFeedback>
												</Col>
											)}
										</Row>
									</CardBody>
								</Card>
								{!values.isSystem && (
									<TagCard
										initialCollapse={true}
										activeLanguage={values.language}
										tags={values.tags}
										onSave={(tags) => this.handleTags(tags)}
									/>
								)}
							</Form>
						</BlockUi>
					</Fragment>
				)}
			</Formik>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		bookmark: state.setting.bookmark || {
			id: 0,
			displayText: '',
			title: '',
			target: state.application.newTabOpenLinks ? LinkTargetType.NewTab.value : LinkTargetType.Self.value,
			isActive: false,
			isSystem: false,
			tags: [],
			language: DefaultLanguage,
			color: '#000000',
			showOnMainPage: false
		},
		activeLanguage: state.application.activeLanguage || DefaultLanguage,
		languages: getActiveLanguagesSelector(state),
		bookmarkCardBlocking: state.uiBlockState.bookmarkCardBlocking || false
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(SettingActionCreators, dispatch),
		cancel: () => dispatch(goBack()),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkCard);
