import React from 'react';
import { FormGroup, Label, Card, CardHeader, CardBody, Col, Row, Input } from 'reactstrap';

export const InputCard = ({ value = -1, title='' }) => (
	<Card className="mb-2">
		<CardHeader><i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />{title}</CardHeader>
		<CardBody>
			<Row>
				<Col>
					<FormGroup>
						<Label for="paramValue">{title}</Label>
							<Input
								type="text"
								id="paramValue"
								name="paramValue"
								value={value}
								disabled
							/>
					</FormGroup>
				</Col>
			</Row>
		</CardBody>
	</Card>
);
