import Immutable from 'seamless-immutable';
import mergers from 'seamless-immutable-mergers';
import isEmpty from 'lodash.isempty';
import map from 'lodash.map';
import find from 'lodash.find';
import filter from 'lodash.filter';
import { Setting } from '../stores';
import { SettingActions } from '../actions/settings';

const initialState = Immutable(Setting);

export function setting(state = initialState, action = {}) {
	switch (action.type) {
		case SettingActions.LoadLanguagesAction.Success: {
			return state.merge({
				languages: action.payload.items,
				totalLanguages: action.payload.totalItems
			});
		}
		case SettingActions.UpdateLanguageAction.Success:
		case SettingActions.UpdateLanguageStatusAction.Success:
		case SettingActions.CreateLanguage.Success: {
			return state.merge({
				language: {
					...action.payload
				}
			});
		}
		case SettingActions.UpdateCurrentLanguageState.Success:
		case SettingActions.GetLanguageAction.Success: {
			return state.merge({
				language: {
					...action.payload
				}
			});
		}
		case SettingActions.RemoveLanguageAction.Success: {
			if (isEmpty(action.payload)) {
				return state;
			}
			let languages = state.getIn([ 'languages' ]);
			let newLanguages = [];
			languages.reduce((prev, curr) => {
				if (!action.payload.includes(curr.id)) {
					prev.push(curr);
					return prev;
				}
				return prev;
			}, newLanguages);

			return state.merge({
				languages: newLanguages,
				totalLanguages: newLanguages.length
			});
		}
		case SettingActions.UnloadSettingAction.Success: {
			return state.merge({
				language: initialState.language,
				languages: initialState.languages,
				bookmark: initialState.bookmark,
				bookmarks: initialState.bookmarks,
				rssChannel: initialState.rssChannel,
				rssChannels: initialState.rssChannels,
				tile: initialState.tile,
				tiles: initialState.tiles,
				footer: initialState.footer,
				header: initialState.header,
				banner: initialState.banner,
				banners: initialState.banners,
				officeContacts: initialState.officeContacts,
				totalOfficeContacts: initialState.totalOfficeContacts,
				repositoryConfig: initialState.repositoryConfig,
				repositoryConfigs: initialState.repositoryConfigs,
				totalRepositoryConfigs: initialState.totalRepositoryConfigs
			});
		}
		case SettingActions.LoadStandardElementsAction.Request:
		case SettingActions.LoadMenuElementsAction.Request: {
			const standardElementMergeConfig = {
				merger: mergers.updatingByIdArrayMerger,
				mergerObjectIdentifier: 'standardElementId'
			};
			const menuElementMergeConfig = {
				merger: mergers.updatingByIdArrayMerger,
				mergerObjectIdentifier: 'menuElementId'
			};
			const standardElementsChanged = state.getIn([ 'language', 'standardElementsChanged' ]);
			const menuElementChanged = state.getIn([ 'language', 'menuElementsChanged' ]);
			const newStandardElementsChanged = Immutable({ items: standardElementsChanged }).merge(
				{
					items: filter(
						action.payload.actualLanguage.standardElementsTranslations.items,
						(menuElement) => menuElement.isChanged
					)
				},
				standardElementMergeConfig
			);
			const newMenuElementChanged = Immutable({ items: menuElementChanged }).merge(
				{
					items: filter(
						action.payload.actualLanguage.menuElementsTranslations.items,
						(menuElement) => menuElement.isChanged
					)
				},
				menuElementMergeConfig
			);

			return state.merge({
				language: {
					...action.payload.actualLanguage,
					standardElementsChanged: newStandardElementsChanged.items,
					menuElementsChanged: newMenuElementChanged.items
				}
			});
		}
		case SettingActions.LoadStandardElementsAction.Success: {
			const standardElementsChanged = state.getIn([ 'language', 'standardElementsChanged' ]);
			const mergedStandardElementItems = map(action.payload.items, (item) => {
				const changed = find(
					standardElementsChanged,
					(changedItem) => item.standardElementId === changedItem.standardElementId
				);
				return changed ? changed : item;
			});
			let newState = state.setIn([ 'language', 'standardElementsTranslations' ], {
				items: mergedStandardElementItems,
				totalItems: action.payload.totalItems
			});

			return newState;
		}
		case SettingActions.LoadMenuElementsAction.Success: {
			const menuElementsChanged = state.getIn([ 'language', 'menuElementsChanged' ]);
			const mergedMenuElementItems = map(action.payload.items, (item) => {
				const changed = find(
					menuElementsChanged,
					(changedItem) => item.menuElementId === changedItem.menuElementId
				);
				return changed ? changed : item;
			});
			let newState = state.setIn([ 'language', 'menuElementsTranslations' ], {
				items: mergedMenuElementItems,
				totalItems: action.payload.totalItems
			});

			return newState;
		}
		case SettingActions.GetAllSystemParameters.Success: {
			return state.merge({
				systemParameters: action.payload.items,
				totalSystemParameters: action.payload.totalItems
			});
		}
		case SettingActions.GetSystemParameter.Success: {
			return state.merge({
				systemParameter: action.payload
			});
		}
		case SettingActions.LoadBookmarks.Success: {
			return state.merge({
				bookmarks: action.payload.items,
				totalBookmarks: action.payload.totalItems
			});
		}
		case SettingActions.GetBookmark.Success:
		case SettingActions.UpdateBookmark.Success:
		case SettingActions.CreateBookmark.Success: {
			return state.merge({
				bookmark: action.payload
			});
		}
		case SettingActions.DeleteBookmarks.Success: {
			const bookmarks = Immutable(state).getIn([ 'bookmarks' ]);
			const newBookmarks = [];
			bookmarks.reduce((prev, curr) => {
				if (!action.payload.includes(curr.id)) {
					prev.push(curr);
					return prev;
				}
				return prev;
			}, newBookmarks);
			return state.setIn([ 'bookmarks' ], newBookmarks);
		}
		case SettingActions.LoadRssChannels.Success: {
			return state.merge({
				rssChannels: action.payload.items,
				totalRssChannels: action.payload.totalItems
			});
		}
		case SettingActions.GetRssChannel.Success:
		case SettingActions.UpdateRssChannel.Success:
		case SettingActions.CreateRssChannel.Success: {
			return state.merge({
				rssChannel: action.payload
			});
		}
		case SettingActions.DeleteRssChannels.Success: {
			const rssChannels = Immutable(state).getIn([ 'rssChannels' ]);
			const newRssChannels = [];
			rssChannels.reduce((prev, curr) => {
				if (!action.payload.includes(curr.id)) {
					prev.push(curr);
					return prev;
				}
				return prev;
			}, newRssChannels);
			return state.setIn([ 'rssChannels' ], newRssChannels);
		}
		case SettingActions.LoadTiles.Success: {
			return state.merge({
				tiles: action.payload.items,
				totalTiles: action.payload.totalItems
			});
		}
		case SettingActions.GetTile.Success:
		case SettingActions.UpdateTile.Success: {
			return state.merge({
				tile: action.payload
			});
		}
		case SettingActions.GetFooter.Success:
		case SettingActions.UpdateFooter.Success: {
			return state.merge({
				footer: action.payload
			});
		}
		case SettingActions.GetHeader.Success:
		case SettingActions.UpdateHeader.Success: {
			return state.merge({
				header: action.payload
			});
		}
		case SettingActions.LoadBanners.Success: {
			return state.merge({
				banners: action.payload.items,
				totalBanners: action.payload.totalItems
			});
		}
		case SettingActions.GetBanner.Success:
		case SettingActions.UpdateBanner.Success:
		case SettingActions.CreateBanner.Success: {
			return state.merge({
				banner: action.payload
			});
		}
		case SettingActions.DeleteBanners.Success: {
			const banners = Immutable(state).getIn([ 'banners' ]);
			const newBanners = [];
			banners.reduce((prev, curr) => {
				if (!action.payload.includes(curr.id)) {
					prev.push(curr);
					return prev;
				}
				return prev;
			}, newBanners);
			return state.setIn([ 'banners' ], newBanners);
		}

		case SettingActions.GetAppImages.Success:
		case SettingActions.UpdateAppImages.Success: {
			return state.merge({
				appImages: action.payload
			});
		}
		case SettingActions.GetOfficeContacts.Success:
		case SettingActions.UpdateOfficeContacts.Success: {
			return state.merge({
				officeContacts: action.payload.items,
				totalOfficeContacts: action.payload.totalItems
			});
		}
		case SettingActions.LoadRepositoryConfigs.Success: {
			return state.merge({
				repositoryConfigs: action.payload.items,
				totalRepositoryConfigs: action.payload.totalItems
			});
		}
		case SettingActions.GetRepositoryConfig.Success:
		case SettingActions.UpdateRepositoryConfig.Success:
		case SettingActions.CreateRepositoryConfig.Success: {
			return state.merge({
				repositoryConfig: action.payload
			});
		}
		case SettingActions.RemoveRepositoryConfigs.Success: {
			const repositoryConfigs = Immutable(state).getIn([ 'repositoryConfigs' ]);
			const newRepositoryConfigs = [];
			repositoryConfigs.reduce((prev, curr) => {
				if (!action.payload.includes(curr.id)) {
					prev.push(curr);
					return prev;
				}
				return prev;
			}, newRepositoryConfigs);
			return state.setIn([ 'repositoryConfigs' ], newRepositoryConfigs);
		}
		default: {
			return state;
		}
	}
}
