import React, { Component, Fragment } from "react";
import map from "lodash.map";

import { Card, CardHeader, CardBody, ListGroup, Collapse } from "reactstrap";
import RSC from "react-scrollbars-custom";
import { FieldArray } from "formik";

import HistoryVersionCardItem from "./HistoryVersionCardItem";

export default class HistoryVersionCard extends Component {
  static defaultProps = {
    versions: [],
    cardCollapse: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      cardCollapse: props.collapse,
    };
  }

  toggleCollapse = () => {
    this.setState({ cardCollapse: !this.state.cardCollapse });
  };

  render() {
    const { cardCollapse = false } = this.state;
    const { versions = [], currentVersion = 0, goTo = () => {} } = this.props;

    return (
      <Fragment>
        <Card className="mb-2" style={{ cursor: "pointer" }}>
          <CardHeader onClick={this.toggleCollapse}>
            <i className="header-icon pe-7s-refresh icon-gradient bg-malibu-beach" />
            Historia
            <div className="btn-actions-pane-right">
              {cardCollapse ? "Zwiń" : "Rozwiń"}
              {cardCollapse ? (
                <i className="pe-7s-angle-up" />
              ) : (
                <i className="pe-7s-angle-down" />
              )}
            </div>
          </CardHeader>

          <Collapse isOpen={cardCollapse}>
            <CardBody>
              <div className="scroll-area-md">
                <RSC>
                  <ListGroup flush>
                    <FieldArray id="versions" name="versions">
                      {({ replace }) =>
                        map(versions, (version, versionIndex) => (
                          <HistoryVersionCardItem
                            key={`version-${versionIndex}`}
                            id={`version-${versionIndex}`}
                            index={versionIndex}
                            {...version}
                            currentVersion={currentVersion}
                            changeVisibility={replace}
                            goTo={goTo}
                          />
                        ))
                      }
                    </FieldArray>
                  </ListGroup>
                </RSC>
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </Fragment>
    );
  }
}
