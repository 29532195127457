import { takeEvery, fork, call, put } from 'redux-saga/effects';
import axios from 'axios';

import { OfficeActions } from '../actions/office';
import { createErrorToast } from '../helpers/error-message';
import {toast} from 'react-toastify';

const OFFICE_URL = '/api/settings/offices';

function* getOffice(action) {
	try {
		const office = yield call(getOfficeRequest);
		yield put({
			type: OfficeActions.GetOffice.Success,
			payload: office
		});
	} catch (err) {
		yield put({
			type: OfficeActions.GetMenu.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany problem podczas pobierania danych jednostki');
	}
}

function getOfficeRequest() {
	return axios.get(OFFICE_URL).then((response) => response.data).catch((err) => {
		throw err;
	});
}


function* updateOffice(action) {
	try {
		const office = yield call(updateOfficeRequest, action.payload);
		yield put({
			type: OfficeActions.UpdateOffice.Success,
			payload: office
		});
		toast.success('Dane jednostki zostały zaktualizowane');
	} catch (err) {
		yield put({
			type: OfficeActions.UpdateOffice.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany problem podczas aktualizowania danych jednostki');
	}
}

function updateOfficeRequest(office) {
	return axios.put(OFFICE_URL, office).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchGetOffice() {
	yield takeEvery(OfficeActions.GetOffice.Request, getOffice);
}

function* watchUpdateOffice() {
	yield takeEvery(OfficeActions.UpdateOffice.Request, updateOffice);
}

export const OfficeSaga = [
	fork(watchGetOffice),
	fork(watchUpdateOffice)
];