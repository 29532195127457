import qs from "qs";

import axios from "axios";
import { getUri } from "../../helpers/api";

export const getQueryString = (queryParams) =>
  `?${qs.stringify(queryParams, { allowDots: true })}`;

export const sendData = ({
  method,
  url,
  config,
  onSuccess,
  body,
  onFinally,
}) => {
  return axios[method](`${getUri("")}${url}`, body, config)
    .then((res) => onSuccess(res))
    .catch((err) => Promise.reject(err))
    .finally(() => onFinally());
};
