import React from 'react';
import {
	FormGroup,
	FormFeedback,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Label,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	Button
} from 'reactstrap';
import { Formik } from 'formik';

import { FooterValidationSchema } from '../../../../validators';

import { CONSTANTS } from '@constants';
import { DefaultFormikConfig } from '../../../../helpers/enums';

const { CONFIRM, CANCEL, COMMON_INFORMATION, EDIT_FOOTER_ELEMENT, BIP_NAME, OFFICE_NAME } = CONSTANTS;

export default ({
	isOpen = false,
	footerElement = {},
	updateFooterElement = (element) => element,
	cancel = () => {}
}) => (
	<Formik
		{...DefaultFormikConfig}
		initialValues={footerElement}
		validationSchema={FooterValidationSchema}
		onSubmit={(values) => {
			updateFooterElement(values);
			cancel();
		}}
		onReset={cancel}
	>
		{({ errors, values, handleChange, isValid, dirty, handleSubmit, handleReset }) => (
			<Modal isOpen={isOpen} backdrop size="xl" toggle={handleReset}>
				<ModalHeader>
					<div className="d-flex justify-content-between">
						<div>{EDIT_FOOTER_ELEMENT}</div>
						<div>
							<Button
								className="btn-icon mr-2"
								color="alternate"
								type="button"
								onClick={handleSubmit}
								disabled={!isValid || !dirty}
							>
								<i className="pe-7s-check btn-icon-wrapper" />
								{CONFIRM}
							</Button>
							<Button className="btn-icon mr-2" color="secondary" type="button" onClick={handleReset}>
								<i className="pe-7s-back btn-icon-wrapper" />
								{CANCEL}
							</Button>
						</div>
					</div>
				</ModalHeader>
				<ModalBody>
					<Card className="mb-2">
						<CardHeader>
							<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
							{COMMON_INFORMATION}
						</CardHeader>
						<CardBody>
							<Row>
								<Col>
									<FormGroup>
										<Label for="bip">{BIP_NAME}</Label>
										<Input
											type="text"
											id="bip"
											name="content.bip"
											placeholder="Wpisz nazwę"
											value={values.content.bip}
											onChange={handleChange}
										/>
										{errors.content && (
											<FormFeedback style={{ display: 'block' }}>
												{errors.content.bip}
											</FormFeedback>
										)}
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label for="office">{OFFICE_NAME}</Label>
										<Input
											type="text"
											id="office"
											name="content.office"
											placeholder="Wpisz nazwę"
											value={values.content.office}
											onChange={handleChange}
										/>
										{errors.content && (
											<FormFeedback style={{ display: 'block' }}>
												{errors.content.office}
											</FormFeedback>
										)}
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</ModalBody>
			</Modal>
		)}
	</Formik>
);
