import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';

import { UserActions } from '../../actions/auth/user';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const USER_URL = '/api/auth/users';

function* loadUsers(action) {
	try {
		let users = yield call(getListRequest, {
			url: USER_URL,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: UserActions.LoadUsers.Success,
			payload: users
		});
	} catch (err) {
		yield put({
			type: UserActions.LoadUsers.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy użytkowników');
	}
}

function* getUser(action) {
	try {
		let user = yield call(getUserRequest, action.payload);

		yield put({
			type: UserActions.GetUser.Success,
			payload: user
		});
	} catch (err) {
		yield put({
			type: UserActions.GetUser.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych użytkownika');
	}
}

function getUserRequest(id) {
	return axios.get(`${USER_URL}/${id}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateUser(action) {
	try {
		let user = yield call(updateUserRequest, action.payload);

		yield put({
			type: UserActions.UpdateUser.Success,
			payload: user
		});
		toast.success('Użytkownik został zaktualizowany');
	} catch (err) {
		yield put({
			type: UserActions.UpdateUser.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji danych użytkownika');
	}
}
function updateUserRequest(user) {
	return axios.put(`${USER_URL}/${user.id}`, user).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* createUser(action) {
	try {
		let user = yield call(createUserRequest, action.payload);

		yield put({
			type: UserActions.CreateUser.Success,
			payload: user
		});
		yield put(replace(RouteUrls.Auth.user.editFunc(user.id)));
		toast.success('Użytkownik został utworzony');
	} catch (err) {
		yield put({
			type: UserActions.CreateUser.Failure,
			payload: action.payload
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas tworzenia użytkownika');
	}
}
function createUserRequest(user) {
	return axios.post(USER_URL, user).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* deleteUsers(action) {
	try {
		yield call(deleteUsersRequest, action.payload);

		yield put({
			type: UserActions.DeleteUsers.Success,
			payload: action.payload
		});
		toast.success('Użytkownicy zostali usunięci');
	} catch (err) {
		yield put({
			type: UserActions.DeleteUsers.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie usuwania użytkowników');
	}
}

function deleteUsersRequest(userIds) {
	const filterQuery = qs.stringify({ userIds: userIds }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${USER_URL}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* activeUser(action) {
	try {
		let user = yield call(activeUserRequest, action.payload);

		yield put({
			type: UserActions.ActiveUser.Success,
			payload: user
		});
		toast.success('Użytkownik został aktywowany');
	} catch (err) {
		yield put({
			type: UserActions.ActiveUser.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktywacji użytkownika');
	}
}
function activeUserRequest(id) {
	return axios.post(`${USER_URL}/${id}/isconfirmed`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* deactiveUser(action) {
	try {
		let user = yield call(deactiveUserRequest, action.payload);

		yield put({
			type: UserActions.DeactiveUser.Success,
			payload: user
		});
		toast.success('Użytkownik został dezaktywowany');
	} catch (err) {
		yield put({
			type: UserActions.DeactiveUser.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas dezaktywacji użytkownika');
	}
}
function deactiveUserRequest(id) {
	return axios.delete(`${USER_URL}/${id}/isconfirmed`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* changePassword(action) {
	try {
		yield call(changePasswordRequest, action.payload.identityUserId, action.payload.changePassword);

		yield put({
			type: UserActions.ChangePassword.Success
		});
		toast.success('Hasło zostało zmienione');
		yield put(replace(action.payload.changePassword.from));
	} catch (err) {
		yield put({
			type: UserActions.ChangePassword.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas zmiany hasła');
	}
}
function changePasswordRequest(identityUserId, changePassword) {
	return axios
		.put(`${USER_URL}/${identityUserId}/password`, changePassword)
		.then((response) => response.data)
		.catch((err) => {
			throw err;
		});
}

function* getPermissions() {
	try {
		const permissions = yield call(getPermissionsRequest);

		yield put({
			type: UserActions.GetPermissions.Success,
			payload: permissions
		});
	} catch (err) {
		yield put({
			type: UserActions.GetPermissions.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania uprawnień');
	}
}
function getPermissionsRequest() {
	return axios.get(`${USER_URL}/permissions`).then((response) => response.data).catch((err) => {
		throw err;
	});
}
function* getLoggedUserData() {
	try {
		const loggedUserData = yield call(getLoggedUserDataRequest);

		yield put({
			type: UserActions.GetLoggedUserData.Success,
			payload: loggedUserData
		});
	} catch (err) {
		yield put({
			type: UserActions.GetLoggedUserData.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych użytkownika');
	}
}
function getLoggedUserDataRequest() {
	return axios.get(`${USER_URL}/loggedUserData`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* resetPassword(action) {
	try {
		yield call(resetPasswordRequest, action.payload);

		yield put({
			type: UserActions.ResetPassword.Success
		});
		toast.success('Hasło zostało zresetowane');
	} catch (err) {
		yield put({
			type: UserActions.ResetPassword.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas resetu hasła');
	}
}
function resetPasswordRequest(identityUserId) {
	return axios.post(`${USER_URL}/${identityUserId}/password`).then((response) => response.data).catch((err) => {
		throw err;
	});
}
function* getUserExcludeCategories() {
	try {
		const excludeCategories = yield call(getUserExcludeCategoriesRequest);
		yield put({
			type: UserActions.GetUserExcludeCategories.Success,
			payload: excludeCategories
		});
	} catch (err) {
		yield put({
			type: UserActions.GetUserExcludeCategories.Failure
		})
	}
}

function getUserExcludeCategoriesRequest() {
	return axios.get(`${USER_URL}/excludeCategories`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchLoadUsers() {
	yield takeEvery(UserActions.LoadUsers.Request, loadUsers);
}
function* watchGetUser() {
	yield takeEvery(UserActions.GetUser.Request, getUser);
}
function* watchCreateUser() {
	yield takeEvery(UserActions.CreateUser.Request, createUser);
}
function* watchUpdateUser() {
	yield takeEvery(UserActions.UpdateUser.Request, updateUser);
}
function* watchDeleteUsers() {
	yield takeEvery(UserActions.DeleteUsers.Request, deleteUsers);
}
function* watchActiveUsers() {
	yield takeEvery(UserActions.ActiveUser.Request, activeUser);
}
function* watchDeactiveUsers() {
	yield takeEvery(UserActions.DeactiveUser.Request, deactiveUser);
}
function* watchChangePassword() {
	yield takeEvery(UserActions.ChangePassword.Request, changePassword);
}
function* watchGetPermissions() {
	yield takeEvery(UserActions.GetPermissions.Request, getPermissions);
}
function* watchGetLoggedUserData() {
	yield takeEvery(UserActions.GetLoggedUserData.Request, getLoggedUserData);
}
function* watchResetPassword() {
	yield takeEvery(UserActions.ResetPassword.Request, resetPassword);
}
function* watchGetUserExcludeCategories() {
	yield takeEvery(UserActions.GetUserExcludeCategories.Request, getUserExcludeCategories);
}

export const UserSagas = [
	fork(watchLoadUsers),
	fork(watchGetUser),
	fork(watchUpdateUser),
	fork(watchCreateUser),
	fork(watchDeleteUsers),
	fork(watchActiveUsers),
	fork(watchDeactiveUsers),
	fork(watchChangePassword),
	fork(watchGetPermissions),
	fork(watchGetLoggedUserData),
	fork(watchResetPassword),
	fork(watchGetUserExcludeCategories)
];
