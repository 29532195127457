import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import axios from 'axios';

import { SettingActions } from '../../actions/settings';
import { createErrorToast } from '../../helpers/error-message';

const HEADER_URL = '/api/settings/headers';

function* getHeader(action) {
	try {
		let header = yield call(getHeaderRequest, action.payload);

		yield put({
			type: SettingActions.GetHeader.Success,
			payload: header
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetHeader.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania nagłówka');
	}
}
function getHeaderRequest(isoCode) {
	return axios.get(`${HEADER_URL}/${isoCode}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateHeader(action) {
	try {
		let header = yield call(updateHeaderRequest, action.payload);

		yield put({
			type: SettingActions.UpdateHeader.Success,
			payload: header
		});
		toast.success('Nagłówek został zaktualizowany');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateHeader.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji nagłówka');
	}
}
function updateHeaderRequest(header) {
	return axios.put(`${HEADER_URL}/${header.id}`, header).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchGetHeader() {
	yield takeEvery(SettingActions.GetHeader.Request, getHeader);
}
function* watchUpdateHeader() {
	yield takeEvery(SettingActions.UpdateHeader.Request, updateHeader);
}

export const HeaderSagas = [ fork(watchGetHeader), fork(watchUpdateHeader) ];
