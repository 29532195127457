import { format, isValid } from 'date-fns';

export const getValidDate = (date) =>
    isValid(new Date(date)) ? format(new Date(date), 'yyyy-MM-dd') : null

export const getFormatDate = (date) => {
    const is_valid = isValid(new Date(date));
    if (is_valid) {
        const date_format = format(new Date(date), 'yyyy-MM-dd');
        const time_format = format(new Date(date), 'HH:mm:ss.SSSS');

        return `${date_format}T${time_format}`;
    }
    return null
}
