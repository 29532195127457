import React from "react";
import { FormGroup, Label, FormFeedback } from "reactstrap";

import cx from "classnames";

import { CONSTANTS } from "@constants";

const { YES, NO } = CONSTANTS;

export const Toggle = ({
  label,
  name,
  value,
  error,
  onClick,
  disabled,
  labelClassName,
  formGroupClassName,
}) => {
  return (
    <FormGroup className={`d-flex align-items-center ${formGroupClassName}`}>
      <Label for={name} className={`mr-2 ${labelClassName}`}>
        {label}
      </Label>
      <div
        className={`switch has-switch`}
        data-on-label="Tak"
        data-off-label="Nie"
        onClick={onClick}
        invalid={error}
        disabled={disabled}
        style={{
          pointerEvents: disabled ? "none" : "inherit",
        }}
      >
        <div
          className={cx("switch-animate", {
            "switch-on": value,
            "switch-off": !value,
          })}
        >
          <input type="checkbox" />
          <span className="switch-left">{YES}</span>
          <label>&nbsp;</label>
          <span className="switch-right">{NO}</span>
        </div>
      </div>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};
