import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import TagList from "./List";
import TagCard from "./Card";

import { RouteUrls } from "../../../helpers/routeUrls";

const Tags = () => (
  <Fragment>
    <Switch>
      <Route path={RouteUrls.Dictionary.tag.create} component={TagCard} />
      <Route
        path={`${RouteUrls.Dictionary.tag.edit}/:id`}
        component={TagCard}
      />
      <Route path="*" component={TagList} />
    </Switch>
  </Fragment>
);

export default Tags;
