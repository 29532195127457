import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';

import { SettingActions } from '../../actions/settings';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';
import qs from 'qs';

const REPOSITORY_GROUP_URL = '/api/settings/repository-groups';

function* loadRepositoryConfigs(action) {
	try {
		let repositoryConfigs = yield call(getListRequest, {
			url: REPOSITORY_GROUP_URL,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: SettingActions.LoadRepositoryConfigs.Success,
			payload: repositoryConfigs
		});
	} catch (err) {
		yield put({
			type: SettingActions.LoadRepositoryConfigs.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy konfiguracji grup plików');
	}
}

function* getRepositoryConfig(action) {
	try {
		let repositoryConfig = yield call(getRepositoryConfigRequest, action.payload);

		yield put({
			type: SettingActions.GetRepositoryConfig.Success,
			payload: repositoryConfig
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetRepositoryConfig.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych konfiguracji');
	}
}
function getRepositoryConfigRequest(id) {
	return axios.get(`${REPOSITORY_GROUP_URL}/${id}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateRepositoryConfig(action) {
	try {
		let repositoryConfig = yield call(updateRepositoryConfigRequest, action.payload);

		yield put({
			type: SettingActions.UpdateRepositoryConfig.Success,
			payload: repositoryConfig
		});
		toast.success('Konfiguracja grupy plików została zaktualizowana');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateRepositoryConfig.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji danych konfiguracji');
	}
}
function updateRepositoryConfigRequest(repositoryConfig) {
	return axios.put(`${REPOSITORY_GROUP_URL}/${repositoryConfig.id}`, repositoryConfig).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* createRepositoryConfig(action) {
	try {
		let repositoryConfig = yield call(createRepositoryConfigRequest, action.payload);

		yield put({
			type: SettingActions.CreateRepositoryConfig.Success,
			payload: repositoryConfig
		});
		yield put(replace(RouteUrls.Setting.repository.editFunc(repositoryConfig.id)));
		toast.success('Konfiguracja grupy plików została utworzona');
	} catch (err) {
		yield put({
			type: SettingActions.CreateRepositoryConfig.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas tworzenia nowej konfiguracji');
	}
}
function createRepositoryConfigRequest(repositoryConfig) {
	return axios.post(`${REPOSITORY_GROUP_URL}`, repositoryConfig).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* removeRepositoryConfigs(action) {
	try {
		yield call(removeRepositoryConfigsRequest, action.payload);

		yield put({
			type: SettingActions.RemoveRepositoryConfigs.Success,
			payload: action.payload
		});

		toast.success('Konfiguracje grup plików zostały usunięte');
	} catch (err) {
		yield put({
			type: SettingActions.RemoveRepositoryConfigs.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas usuwania konfiguracji');
	}
}
function removeRepositoryConfigsRequest(repositoryConfigIds) {
	const filterQuery = qs.stringify({ ids: repositoryConfigIds }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${REPOSITORY_GROUP_URL}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchLoadRepositoryConfigs() {
	yield takeEvery(SettingActions.LoadRepositoryConfigs.Request, loadRepositoryConfigs);
}
function* watchGetRepositoryConfig() {
	yield takeEvery(SettingActions.GetRepositoryConfig.Request, getRepositoryConfig);
}
function* watchUpdateRepositoryConfig() {
	yield takeEvery(SettingActions.UpdateRepositoryConfig.Request, updateRepositoryConfig);
}
function* watchCreateRepositoryConfig() {
	yield takeEvery(SettingActions.CreateRepositoryConfig.Request, createRepositoryConfig);
}
function* watchRemoveRepositoryConfigs() {
	yield takeEvery(SettingActions.RemoveRepositoryConfigs.Request, removeRepositoryConfigs);
}

export const RepositorySagas = [
	fork(watchLoadRepositoryConfigs), fork(watchGetRepositoryConfig),
	fork(watchUpdateRepositoryConfig), fork(watchCreateRepositoryConfig),
	fork(watchRemoveRepositoryConfigs),
];
