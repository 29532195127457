import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import isEqual from 'lodash.isequal';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { TileList } from './TileList';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, DefaultLanguage, PageSize } from '../../../../helpers/enums';
import { ApplicationActionCreators } from '../../../../actions/application';
import { SettingActionCreators } from '../../../../actions/settings';
import { FilterActionCreators } from '../../../../actions/filter';
import { TileSettingButton } from './TileSettings';
class TilesList extends Component {
	componentDidMount() {
		const { activeLanguage, loadTiles, tileFilters } = this.props;
		loadTiles(activeLanguage.isoCode, 0, PageSize.Rows20, tileFilters, []);
	}
	componentDidUpdate(prevProps) {
		const { activeLanguage, loadTiles, tileFilters } = this.props;
		if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
			loadTiles(activeLanguage.isoCode, 0, PageSize.Rows20, tileFilters, []);
		}
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}

	onRowClick = (row) => this.props.goTo(RouteUrls.Setting.tiles.editFunc(row.id));

	onReload = (page, size, filters, sortings) => {
		const { activeLanguage, loadTiles, tileFilters } = this.props;
		loadTiles(activeLanguage.isoCode, page, size, tileFilters, sortings);
	};

	clearFilters = () => {
		const { activeLanguage, loadTiles, clearFilters } = this.props;
		clearFilters([ 'tileFilters' ], []);
		loadTiles(activeLanguage.isoCode, 0, PageSize.Rows20, [], []);
	};

	render() {
		const {
			tiles,
			totalTiles,
			tileListBlocking,
			activeLanguage,
			languages,
			changeApplicationLanguage,
			tileFilters,
			changeFilters
		} = this.props;
		return (
			<Fragment>
				<PageTitle heading="Lista kafli" icon="pe-7s-settings icon-gradient bg-tempting-azure" />

				<BlockUi tag="div" blocking={tileListBlocking} loader={<Loader active type={LoaderType} />}>
					<TileList
						items={tiles}
						totalItems={totalTiles}
						onReload={this.onReload}
						onRowClick={this.onRowClick}
						internationalizable={true}
						language={activeLanguage}
						languages={languages}
						onChangeLanguage={changeApplicationLanguage}
						filters={tileFilters}
						onChangeFilters={(filters) => changeFilters([ 'tileFilters' ], filters)}
						onClearFilters={this.clearFilters}
						rightButtons={[ <TileSettingButton tiles={tiles} language={activeLanguage} /> ]}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		tileFilters: state.filter.tileFilters || [],
		tiles: state.setting.tiles || [],
		totalTiles: state.setting.totalTiles || 0,
		tileListBlocking: state.uiBlockState.tileListBlocking || false,
		activeLanguage: state.application.activeLanguage,
		languages: state.application.languages || [ DefaultLanguage ] //getActiveLanguagesSelector(state) || [ DefaultLanguage ]
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators(
			{ ...SettingActionCreators, ...ApplicationActionCreators, ...FilterActionCreators },
			dispatch
		),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TilesList);
