import React, { Component, Fragment } from "react";
import Dropzone from "react-dropzone";
import { Button, Progress } from "reactstrap";
import { SizeUnit } from "../helpers/enums";
import axios from "axios";
import { toast } from "react-toastify";
import { getFormatDate } from "../utils/date";

export default class UploadAttachment extends Component {
  constructor(props) {
    super(props);
    this.attachmentDropzone = null;
    this.state = { progressUpload: [], uploading: false };
  }

  handleFileUpload = (index, percentCompleted) => {
    this.setState((prevState) => {
      const newProgressUpload = [...prevState.progressUpload];
      newProgressUpload[index] = {
        ...prevState.progressUpload[index],
        value: percentCompleted,
      };
      return { progressUpload: newProgressUpload };
    });
  };

  handleFileUploadCompleted = (index) => {
    this.setState((prevState) => {
      const newProgressUpload = [...prevState.progressUpload];
      newProgressUpload[index] = {
        ...prevState.progressUpload[index],
        value: 0,
      };

      return {
        progressUpload: newProgressUpload,
        uploading: false,
      };
    });
  };

  uploadFile = async (files) => {
    const {
      baseUrl,
      onDropAccepted,
      newAttachments,
      maxNewAttachmentCount,
      publishedArticle,
    } = this.props;

    const progressUpload = Array.from(files).map((file) => ({
      name: file.path,
      value: 0,
    }));

    if (
      publishedArticle &&
      newAttachments.length + files.length > maxNewAttachmentCount
    ) {
      return toast.error(
        `Przekroczono dozwoloną ilość dodatkowych załączników. Dozwolona liczba wynosi: ${
          maxNewAttachmentCount - newAttachments.length
        }`
      );
    }

    this.setState({ progressUpload, uploading: true });

    Array.from(files).map(async (file, index) => {
      const formDataForFile = new FormData();
      formDataForFile.append("attachments", file);

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.handleFileUpload(index, percentCompleted);
        },
      };

      try {
        const response = await axios.post(
          `${baseUrl}`,
          formDataForFile,
          config
        );

        const uploadedFile = response.data[0];

        onDropAccepted({
          documentRepositoryId: uploadedFile.documentRepositoryId,
          documentRepositoryFileId: null,
          size: uploadedFile.size,
          filename: uploadedFile.filename,
          displayName: uploadedFile.filename,
          content: uploadedFile.content,
          contentType: uploadedFile.contentType,
          fileType: uploadedFile.fileType,
          crc32File: uploadedFile.crc32File,
          createdDate: getFormatDate(new Date()),
        });
      } catch (e) {
        toast.error("Wystąpił niespodziewany błąd podczas uploadu plik");
        return -1;
      } finally {
        this.handleFileUploadCompleted(index);
      }
    });
  };

  render() {
    const {
      disabled,
      maxSize,
      acceptFileType,
      additionalInfo,
      multiple = false,
      acceptExtensionFiles,
      onDrop,
      onDropRejected,
      onCancel,
    } = this.props;

    const { progressUpload, uploading } = this.state;

    return (
      <Fragment>
        <div className="dropzone-wrapper dropzone-wrapper-sm mb-2">
          <Dropzone
            ref={(ref) => (this.attachmentDropzone = ref)}
            onDrop={onDrop}
            onDropAccepted={this.uploadFile}
            onDropRejected={onDropRejected}
            onFileDialogCancel={onCancel}
            multiple={multiple}
            disabled={disabled || uploading}
            acceptedFiles={acceptFileType}
            accept={acceptExtensionFiles}
            maxSize={maxSize * SizeUnit.MB}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-content">
                  <div className="dropzone-content__text">
                    Przeciągnij tutaj plik <br />
                    lub
                  </div>
                  <div className="dropzone-content__button mb-3">
                    <Button color="primary" disabled={disabled || uploading}>
                      Wybierz plik
                    </Button>
                  </div>

                  <small>{additionalInfo}</small>
                </div>
              </div>
            )}
          </Dropzone>
        </div>

        {(progressUpload || []).map(({ name, value }) => {
          return (
            <>
              {value > 0 ? (
                <>
                  <span className="py-1">{name}</span>
                  <Progress className="mb-3" animated value={value} />
                </>
              ) : null}
            </>
          );
        })}
      </Fragment>
    );
  }
}

UploadAttachment.defaultProps = {
  disabled: false,
  maxSize: 10 * SizeUnit.MB,
  acceptFileType: "*",
  acceptExtensionFiles: "",
  additionalInfo: "",
  onDrop: Function.prototype,
  onDropAccepted: Function.prototype,
  onDropRejected: Function.prototype,
  onCancel: Function.prototype,
};
