import { all } from 'redux-saga/effects';

import { ApplicationSaga } from './applicationSaga';
import { ArchiveSaga } from './archiveSaga';
import { ArticleSaga } from './articleSaga';
import { AuthSaga } from './authSaga';
import { DictionarySaga } from './dictionarySaga';
import { FilterSaga } from './filterSaga';
import { MenuSaga } from './menuSaga';
import { OfficeSaga } from './officeSaga';
import { SearchSaga } from './searchSaga';
import { SettingSaga } from './settingSaga';

export function* rootSaga() {
	yield all([
		...ApplicationSaga,
		...ArchiveSaga,
		...ArticleSaga,
		...AuthSaga,
		...DictionarySaga,
		...FilterSaga,
		...MenuSaga,
		...OfficeSaga,
		...SearchSaga,
		...SettingSaga
	]);
}
