import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
	Form,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Input,
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	Label,
	FormFeedback,
	Col,
} from 'reactstrap';
import cx from 'classnames';
import { fileUploadError } from '@helpers/error-message';

import {
	LinkTargetType,
	DefaultFormikConfig,
	MultimediaType,
	ImageMimeType,
	FileExtensionTypes
} from '../../helpers/enums';
import * as yup from 'yup';
import UploadFile from '../UploadFile';

import { CONSTANTS } from '@constants';
const { CONFIRM, CANCEL } = CONSTANTS;

export const ArticleMultimediaValidationSchema = yup.object().shape({
	type: yup
		.number()
		.oneOf(
			[ MultimediaType.Video.value, MultimediaType.Audio.value ],
			`Typ odnośnika musi zawierać wartość: ${MultimediaType.Video.name}, ${MultimediaType.Audio.name}`
		),
	url: yup
		.string()
		.url('Adres URL musi zawierać prawidłowy format adresu. Musi składać się protokołu, adresu oraz domeny')
		.required('Adres URL jest wymagany'),
	displayText: yup.string().trim().required('Nazwa wyświetlana jest polem wymaganym'),
	title: yup.string().trim().required('Tekst pod czytniki dla osób słabowidzących jest polem wymaganym'),
	target: yup
		.number()
		.oneOf(
			[ LinkTargetType.Self.value, LinkTargetType.NewTab.value ],
			`Otwórz zakładkę w nowym oknie musi zawierać wartość: ${LinkTargetType.Self.name} lub ${LinkTargetType
				.NewTab.name}`
		)
});

const EMPTY_MULTIMEDIA = {
	id: 0,
	type: MultimediaType.Unknown.value,
	url: '',
	title: '',
	displayText: '',
	target: LinkTargetType.Self.value,
	thumbnailId: null,
	thumbnail: null
};

export const AddLinkMultimediaModal = ({
	isOpen = false,
	multimedia = EMPTY_MULTIMEDIA,
	maxFileSize = 10,
	onToggle = (event) => {},
	onSave = (multimedia) => {}
}) => {
	const [ currentMultimedia, setCurrentMultimedia ] = useState(multimedia);
	useEffect(
		() => {
			setCurrentMultimedia(multimedia);
			return () => {
				setCurrentMultimedia(EMPTY_MULTIMEDIA);
			};
		},
		[ multimedia ]
	);

	const handleCancel = (event) => {
		if (onToggle && typeof onToggle === 'function') {
			onToggle(event);
		}
	};

	const handleSave = (multimedia) => {
		if (onSave && typeof onSave === 'function') {
			onSave(multimedia);
		}

		if (onToggle && typeof onToggle === 'function') {
			onToggle();
		}
	};

	return (
		<Formik
			{...DefaultFormikConfig}
			validationSchema={ArticleMultimediaValidationSchema}
			initialValues={currentMultimedia}
			onSubmit={(values) => {
				setCurrentMultimedia(EMPTY_MULTIMEDIA);
				handleSave(values);
			}}
		>
			{({
				values,
				errors,
				isValid,
				dirty,
				handleChange,
				handleReset,
				handleSubmit,
				setFieldValue,
				setValues
			}) => (
				<Modal isOpen={isOpen} backdrop size="xl">
					<ModalHeader>
						<div className="d-flex justify-content-between">
							<div>Edycja multimedii</div>
							<div>
								<Button
									className="btn-icon mr-2"
									color="alternate"
									type="submit"
									disabled={!isValid}
									form="form-link"
								>
									<i className="pe-7s-check btn-icon-wrapper" />{CONFIRM}
								</Button>
								<Button
									className="btn-icon mr-2"
									color="secondary"
									type="button"
									onClick={handleCancel}
								>
									<i className="pe-7s-back btn-icon-wrapper" />{CANCEL}
								</Button>
							</div>
						</div>
					</ModalHeader>
					<ModalBody>
						<Form id="form-link" onSubmit={handleSubmit} onReset={handleReset}>
							<Card className="mb-2">
								<CardHeader>Dane ogólne</CardHeader>
								<CardBody>
									<FormGroup>
										<Label for="url">URL</Label>
										<Input
											type="text"
											id="url"
											name="url"
											value={values.url}
											onChange={handleChange}
											invalid={!!errors.url}
										/>
										<FormFeedback>{errors.url}</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="displayText">Nazwa do wyświetlenia</Label>
										<Input
											type="text"
											id="displayText"
											name="displayText"
											value={values.displayText}
											onChange={handleChange}
											invalid={!!errors.displayText}
										/>
										<FormFeedback>{errors.displayText}</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="title">Tekst pod czytniki dla osób słabowidzących</Label>
										<Input
											type="text"
											id="title"
											name="title"
											value={values.title}
											onChange={handleChange}
											invalid={!!errors.title}
										/>
										<FormFeedback>{errors.title}</FormFeedback>
									</FormGroup>

									<FormGroup>
										<Label for="title">
											{multimedia.type === MultimediaType.Video.value ? (
												'Grafika - miniatura wideo'
											) : (
												'Grafika - miniatura audio'
											)}
										</Label>
										{!values.thumbnailId && (
											<UploadFile
												disabled={false}
												multiple={false}
												maxSize={maxFileSize}
												acceptFileType={`${ImageMimeType.BMP}, ${ImageMimeType.PNG}, ${ImageMimeType.JPG}, ${ImageMimeType.GIF}`}
												acceptExtensionFiles={[
													FileExtensionTypes.BMP,
													FileExtensionTypes.PNG,
													FileExtensionTypes.JPG,
													FileExtensionTypes.JPEG,
													FileExtensionTypes.GIF
												]}
												additionalInfo={
													'Zalecany format zdjęcia powinien być w formacie 125x45 pikseli'
												}
												recommendedWidth={231}
												recommendedHeight={130}
												onDrop={Function.prototype}
												onDropAccepted={(file) =>
													setValues({
														...values,
														thumbnailId: file.id,
														thumbnail: file
													})}
												onDropRejected={(files) => fileUploadError(files, maxFileSize)}
												onCancel={Function.prototype}
											/>
										)}
										{values.thumbnailId && (
											<Col md="12">
												<div className="d-flex he-100 justify-content-center align-items-end">
													<img
														className={`image__content`}
														src={values.thumbnail.path}
														alt={`Grafika ${values.thumbnail.fileName}`}
													/>
													<Button
														size="sm"
														color="danger"
														onClick={(e) =>
															setValues({
																...values,
																thumbnailId: null,
																thumbnail: null
															})}
														title="Usuwa grafikę"
													>
														<i className="pe-7s-trash btn-icon-wrapper" />
													</Button>
												</div>
											</Col>
										)}
									</FormGroup>

									{false && (
										<FormGroup className="d-flex align-items-center">
											<Label for="target" className="mr-2">
												Otwórz odnośnik w nowym oknie
											</Label>
											<div
												className="switch has-switch mb-2 mr-2"
												data-on-label="Tak"
												data-off-label="Nie"
												onClick={(e) => {
													const val =
														values.target === LinkTargetType.NewTab.value
															? LinkTargetType.Self.value
															: LinkTargetType.NewTab.value;
													setFieldValue(`target`, val);
												}}
												invalid={!!errors.target}
											>
												<div
													className={cx('switch-animate', {
														'switch-on': values.target === LinkTargetType.NewTab.value,
														'switch-off': values.target === LinkTargetType.Self.value
													})}
												>
													<input type="checkbox" />
													<span className="switch-left">Tak</span>
													<label>&nbsp;</label>
													<span className="switch-right">Nie</span>
												</div>
											</div>
											<FormFeedback>{errors.target}</FormFeedback>
										</FormGroup>
									)}
								</CardBody>
							</Card>
						</Form>
					</ModalBody>
				</Modal>
			)}
		</Formik>
	);
};
