import React from 'react';
import { DragSource } from 'react-dnd';
import { DragDropType } from '../../helpers/enums';
import FooterLinkTreeViewNode from '../TreeViewNodes/FooterLinkTreeViewNode';

const source = {
	beginDrag(props) {
		const { node } = props;
		return {
			node: { ...node, children: [] }
		};
	},
	endDrag(props, monitor, component) {
		if (!monitor.didDrop()) {
			return;
		}
	}
};

const collect = (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	};
};

const FooterLinkSource = ({ node = { displayText: '' }, ...props }) => (
	<div style={{ height: '100%' }}>
		<FooterLinkTreeViewNode {...props} node={node} canDrop={false} />
	</div>
);

export default DragSource(DragDropType.FooterLink, source, collect)(FooterLinkSource);
