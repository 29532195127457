import { createAction } from '../base';

const LoadBanners = createAction('LoadBanners');
const GetBanner = createAction('GetBanner');
const CreateBanner = createAction('CreateBanner');
const UpdateBanner = createAction('UpdateBanner');
const DeleteBanners = createAction('DeleteBanners');

function loadBanners(lang = '', page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: LoadBanners.Request,
		payload: { lang, page, size, filters, sortings }
	};
}
function getBanner(id) {
	return {
		type: GetBanner.Request,
		payload: id
	};
}
function createBanner(banner) {
	return {
		type: CreateBanner.Request,
		payload: banner
	};
}
function updateBanner(banner) {
	return {
		type: UpdateBanner.Request,
		payload: banner
	};
}
function deleteBanners(bannerIds = []) {
	return {
		type: DeleteBanners.Request,
		payload: bannerIds
	};
}

export const BannerActions = {
	LoadBanners,
	GetBanner,
	CreateBanner,
	UpdateBanner,
	DeleteBanners
};

export const BannerActionCreators = {
	loadBanners,
	getBanner,
	createBanner,
	updateBanner,
	deleteBanners
};
