import { createAction } from '../base';

const LoadBookmarks = createAction('LoadBookmarks');
const GetBookmark = createAction('GetBookmark');
const CreateBookmark = createAction('CreateBookmark');
const UpdateBookmark = createAction('UpdateBookmark');
const DeleteBookmarks = createAction('DeleteBookmarks');

function loadBookmarks(lang = '', page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: LoadBookmarks.Request,
		payload: { lang, page, size, filters, sortings }
	};
}
function getBookmark(id) {
	return {
		type: GetBookmark.Request,
		payload: id
	};
}
function createBookmark(bookmark) {
	return {
		type: CreateBookmark.Request,
		payload: bookmark
	};
}
function updateBookmark(bookmark) {
	return {
		type: UpdateBookmark.Request,
		payload: bookmark
	};
}
function deleteBookmarks(bookmarkIds = []) {
	return {
		type: DeleteBookmarks.Request,
		payload: bookmarkIds
	};
}

export const BookmarkActions = {
	LoadBookmarks,
	GetBookmark,
	CreateBookmark,
	UpdateBookmark,
	DeleteBookmarks
};

export const BookmarkActionCreators = {
	loadBookmarks,
	getBookmark,
	createBookmark,
	updateBookmark,
	deleteBookmarks
};
