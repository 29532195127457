import { createAction } from './base';

const GetOffice = createAction('GetOffice');
const UpdateOffice = createAction('UpdateOffice');

const getOffice = () => ({
	type: GetOffice.Request
});

function updateOffice(office) {
	return {
		type: UpdateOffice.Request,
		payload: office
	};
}

export const OfficeActionCreators = {
	getOffice,
	updateOffice
};

export const OfficeActions = {
	GetOffice,
	UpdateOffice
};
