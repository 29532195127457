import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import { Form, Row, Col, Button } from "reactstrap";
import Sticky from "react-stickynode";
import Flag from "react-flagkit";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";

import map from "lodash.map";
import find from "lodash.find";
import filter from "lodash.filter";
import isEmpty from "lodash.isempty";
import orderBy from "lodash.orderby";
import Immutable from "seamless-immutable";

import { ArticleActionCreators } from "../../../../actions/article";
import { ApplicationActionCreators } from "../../../../actions/application";
import { DictionaryActionCreators } from "../../../../actions/dictionary";
import {
  LoaderType,
  ArticleStatus,
  DefaultLanguage,
  ArticleType,
  DefaultFormikConfig,
  PageSize,
} from "../../../../helpers/enums";
import { RouteUrls } from "../../../../helpers/routeUrls";
import { PermissionName } from "../../../../helpers/permissionNames";
import PageTitle from "../../../../Layout/AppMain/PageTitle";
import ConfirmModal from "../../../../components/ConfirmModal";
import CategoryCard from "../../../../components/Cards/CategoryCard";
import LinkCard from "../../../../components/Cards/LinkCard";
import TagCard from "../../../../components/Cards/TagCard";
import RssChannelCard from "../../../../components/Cards/RssChannelCard";
import { ArticleValidationSchema } from "../../../../validators";
import HistoryVersionCard from "../../../../components/Cards/HistoryVersionCard";
import { isArticleEditableForUser } from "../../../../reselect/article";
import { CONSTANTS } from "@constants";
import { UserActionCreators } from "../../../../actions/auth/user";
import { AVAILABLE_ISO_CODE } from "../../../../helpers/isoCode";
import { CommonCard } from "./Cards/CommonCard";
import { AttachmentCard } from "./Cards/AttachmentCard";
import { ChangeLogCard } from "./Cards/ChangeLogCard";
import { SourceInformationCard } from "./Cards/SourceInformationCard";
import { PublicationCard } from "./Cards/PublicationCard";
import { DetailsCard } from "./Cards/DetailsCard";
import { MultimediaCard } from "./Cards/MultimediaCard";
import isEqual from "lodash.isequal";
import { SettingActionCreators } from "../../../../actions/settings";

const { NEW_VERSION, PREVIEW, SAVE, BACK } = CONSTANTS;

class Article extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saveArticleModalOpen: false,
      articleStatuses: [
        {
          ...ArticleStatus.Draft,
          availableFor: [
            {
              status: ArticleStatus.Confirmed.value,
              permissions: [PermissionName.ConfirmArticles],
            },
            {
              status: ArticleStatus.ToPublish.value,
              permissions: [
                PermissionName.ConfirmArticles,
                PermissionName.PublishArticles,
              ],
            },
          ],
          className: "status-draft",
        },
        {
          ...ArticleStatus.Confirmed,
          availableFor: [
            {
              status: ArticleStatus.Draft.value,
              permissions: [PermissionName.ConfirmArticles],
            },
            {
              status: ArticleStatus.ToPublish.value,
              permissions: [PermissionName.PublishArticles],
            },
          ],
          className: "status-confirmed",
        },
        {
          ...ArticleStatus.ToPublish,
          availableFor: [
            {
              status: ArticleStatus.Confirmed.value,
              permissions: [PermissionName.PublishArticles],
            },
          ],
          className: "status-to-publish",
        },
        {
          ...ArticleStatus.Published,
          availableFor: [],
          className: "status-published",
        },
        {
          ...ArticleStatus.Withdrawed,
          availableFor: [
            {
              status: ArticleStatus.Published.value,
              permissions: [PermissionName.PublishArticles],
            },
          ],
          className: "status-withdrawed",
        },
        {
          ...ArticleStatus.Archived,
          availableFor: [
            {
              status: ArticleStatus.Withdrawed.value,
              permissions: [PermissionName.ArchiveArticles],
            },
            {
              status: ArticleStatus.Published.value,
              permissions: [PermissionName.ArchiveArticles],
            },
          ],
          className: "status-archived",
        },
      ],
      currentArticle: null,
    };
  }

  componentDidMount() {
    const {
      match,
      getArticle,
      getUserExcludeCategories,
      getAppParamDictionaryElements,
      activeLanguage,
      loadRssChannels,
    } = this.props;
    if (match.params.id) {
      getArticle(match.params.id);
    }
    getUserExcludeCategories();
    getAppParamDictionaryElements(activeLanguage.isoCode);
    loadRssChannels(activeLanguage.isoCode, 0, PageSize.All, [
      { columnName: "isActive", operation: "equal", value: "true" },
    ]);
  }

  componentDidUpdate(prevProps, prevState) {
    const { match: prevMatch } = prevProps;
    const { match, getArticle } = this.props;
    if (
      (match.params.id !== prevMatch.params.id && match.params.id > 0) ||
      (match.params.lang &&
        match.params.lang !== prevMatch.params.lang &&
        match.params.id)
    ) {
      getArticle(match.params.id);
    }

    if (!isEqual(prevState.currentArticle, this.props.article)) {
      this.setState({
        currentArticle: this.props.article,
      });
    }
  }

  componentWillUnmount() {
    const { clearArticle } = this.props;
    clearArticle();
  }

  toggleModalOpen = (name, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ [name]: !this.state[name] });
  };

  copyArticleToNewArticleTranslation = (article, language) => {
    const {
      copyArticleToNewArticleTranslation,
      getAppParamDictionaryElements,
    } = this.props;
    copyArticleToNewArticleTranslation(article, language);
    getAppParamDictionaryElements(language.isoCode);
  };

  createTranslationFlagButtons = (article) => {
    const { languages, goTo } = this.props;

    return (
      <div className="header-dots ml-0">
        {map(
          filter(
            languages,
            (language) => language.isoCode !== article.language.isoCode
          ),
          (language, langIndex) => {
            const existedArticleTranslation = find(
              orderBy(
                article.translations,
                ["languageId", "id"],
                ["asc", "desc"]
              ),
              (art) => art.languageId === language.id
            );
            return (
              <div
                key={`lang-ix-${langIndex}`}
                className="p-0 mr-2"
                onClick={(_) => {
                  if (existedArticleTranslation) {
                    goTo(
                      RouteUrls.Article.common.editFunc(
                        existedArticleTranslation.id,
                        language.isoCode
                      )
                    );
                    return;
                  }
                  this.copyArticleToNewArticleTranslation(article, language);
                  return;
                }}
              >
                <div className="icon-wrapper icon-wrapper-alt rounded-circle mr-2">
                  <div className="icon-wrapper-bg bg-focus" />
                  <div className="language-icon">
                    {AVAILABLE_ISO_CODE.includes(language.isoCode) && (
                      <Flag
                        className="mr-3 opacity-8"
                        country={language.isoCode}
                        size="40"
                      />
                    )}
                    {!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
                      language.isoCode !== "" &&
                      language.isoCodeFile !== null && (
                        <img
                          className={`mr-2 opacity-8`}
                          src={language.isoCodeFile.path}
                          alt={`Grafika ${language.isoCodeFile.fileName}`}
                        />
                      )}
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    );
  };

  createArticleNewVersion = () => {
    const { createArticleNewVersion, article } = this.props;

    if (
      createArticleNewVersion &&
      typeof createArticleNewVersion === "function"
    ) {
      createArticleNewVersion(article.id);
    }
  };

  setAvailableProvidingEntities = (selected = "") => {
    const { providingEntities } = this.props;

    return map(
      filter(
        providingEntities,
        (providingEntity) => providingEntity.name !== selected
      ),
      (providingEntity) => ({ name: providingEntity.name })
    );
  };

  handleChangeProvidingEntity = (setFieldValue, providingEntities) => {
    const { activeLanguage, createDictionaryEntry } = this.props;

    if (setFieldValue && typeof setFieldValue === "function") {
      if (!isEmpty(providingEntities) && providingEntities.length === 1) {
        setFieldValue("baseArticleProvidingEntity", providingEntities[0].name);
      } else {
        setFieldValue("baseArticleProvidingEntity", "");
      }
    }

    if (createDictionaryEntry && typeof createDictionaryEntry === "function") {
      const newProvidingEntity = find(
        providingEntities,
        (providingEntity) => providingEntity.customOption
      );
      if (newProvidingEntity) {
        createDictionaryEntry(
          {
            ...newProvidingEntity,
            id: 0,
            languageId: activeLanguage.id,
            languageIsoCode: activeLanguage.isoCode,
          },
          false,
          false
        );
      }
    }
  };

  checkCanAddNewProvidingEntity = (newOptions, { text, selected }) => {
    const isNewOptionsEmpty = isEmpty(newOptions);
    const isDuplicate = !isEmpty(filter(selected, { name: text }));

    return isNewOptionsEmpty && !isDuplicate;
  };

  handlePreview = ({ id, webAppAddress, languageCode }) => {
    this.props.getArticleHash({ id, webAppAddress, languageCode });
  };

  render() {
    const { saveArticleModalOpen, articleStatuses } = this.state;
    const {
      article,
      updateArticle,
      saveArticle,
      activeLanguage,
      articleCardBlocking,
      changeArticleStatus,
      newTabOpenLinks,
      userPermissions,
      goTo,
      isArticleEditable,
      excludeCategories,
      maxFileSize,
      isIntegratedDocRepoWithASW,
      rssChannels,
      publishedArticleLimitiations,
    } = this.props;

    const formikArticle = article
      ? Immutable(article).asMutable({ deep: true })
      : {
          title: "",
          type: -1,
          baseArticleType: ArticleType.Common.value,
          baseArticleProvidingEntity: "",
          baseArticleAuthorName: "",
          baseArticleAuthorEmail: "",
          baseArticleInformationCreated: "",
          language: activeLanguage,
          publishStart: undefined,
          publishEnd: undefined,
          status: ArticleStatus.Draft.value,
          changeLogEntries: [],
          shortDescription: "",
        };

    const currentValidateOnMount = !isEqual(this.state.currentArticle, article);

    formikArticle.baseArticleInformationCreated =
      formikArticle.baseArticleInformationCreated || undefined;
    formikArticle.publishStart = formikArticle.publishStart || undefined;
    formikArticle.publishEnd = formikArticle.publishEnd || undefined;

    return (
      <Formik
        {...DefaultFormikConfig}
        validateOnMount={currentValidateOnMount}
        initialValues={formikArticle}
        validationSchema={ArticleValidationSchema}
        onReset={() =>
          goTo(
            formikArticle.baseArticleType === ArticleType.Common.value
              ? RouteUrls.Article.common.list
              : RouteUrls.Article.system.list
          )
        }
        onSubmit={() => {
          this.toggleModalOpen("saveArticleModalOpen");
        }}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
          handleChange,
          handleReset: onReset,
          handleSubmit: onSubmit,
        }) => {
          const {
            id,
            status,
            language,
            attachments,
            tags,
            versions,
            currentVersion,
            categories,
            links,
            multimedia,
            baseArticleType,
            addedAttachments,
            countNewAttachmentsAfterPublish,
          } = values;

          const { categories: eCategories } = errors;

          const granted =
            isArticleEditable &&
            userPermissions.includes(PermissionName.CreateArticles);
          const articleOnConfirmedStatus =
            status >= ArticleStatus.Confirmed.value || !granted;

          const newAttachments = (attachments || []).filter(
            (attachment) => !attachment?.id
          );

          const publishedArticle = status === 40;
          const workingArticle = status === 10;

          const isAllowAddNewAttachments =
            publishedArticle &&
            !addedAttachments &&
            publishedArticleLimitiations.maxNewAttachmentCount -
              countNewAttachmentsAfterPublish >
              newAttachments.length;

          const articleOnCancelledOrArchiveStatus =
            status >= ArticleStatus.Withdrawed.value;

          return (
            <Fragment>
              <ConfirmModal
                open={saveArticleModalOpen}
                onSubmit={(userChangeText) => {
                  this.toggleModalOpen("saveArticleModalOpen");
                  id > 0
                    ? updateArticle({ ...values, userChangeText })
                    : saveArticle({ ...values, userChangeText });
                }}
                onCancel={(_) => this.toggleModalOpen("saveArticleModalOpen")}
                title="Zachowaj zmiany"
                content={`Czy zachować aktualne zmiany na artykule?`}
                input={dirty && id > 0}
              />
              <BlockUi
                tag="div"
                blocking={articleCardBlocking}
                loader={<Loader active type={LoaderType} />}
              >
                <PageTitle
                  heading="Kartoteka artykułu"
                  icon="pe-7s-copy-file icon-gradient bg-tempting-azure"
                  buttons={this.createTranslationFlagButtons(values)}
                />
                <Form {...{ onReset, onSubmit }}>
                  <Sticky
                    top=".app-header"
                    innerZ="101"
                    activeClass="sticky-active-class"
                    className="mb-2"
                  >
                    <div className="d-flex justify-content-end">
                      {article ? (
                        <Button
                          className="btn-icon mr-2"
                          color="info"
                          type="button"
                          onClick={() =>
                            this.handlePreview({
                              id: article.id,
                              webAppAddress: this.props.webAppAddress,
                              languageCode:
                                this.state.currentArticle?.language?.isoCode,
                            })
                          }
                          disabled={
                            dirty || values.language.status === 0 || !values.id
                          }
                        >
                          <i className="pe-7s-diskette btn-icon-wrapper" />
                          {PREVIEW}
                        </Button>
                      ) : null}
                      {status !== ArticleStatus.Archived.value && (
                        <Button
                          className="btn-icon mr-2"
                          color="warning"
                          type="button"
                          onClick={this.createArticleNewVersion}
                          disabled={
                            (status !== ArticleStatus.Published.value &&
                              status !== ArticleStatus.Withdrawed.value) ||
                            !isArticleEditable ||
                            !userPermissions.includes(
                              PermissionName.CreateArticles
                            )
                          }
                        >
                          <i className="pe-7s-magic-wand btn-icon-wrapper" />
                          {NEW_VERSION}
                        </Button>
                      )}
                      <Button
                        className="btn-icon mr-2"
                        color="primary"
                        type="submit"
                        onClick={this.onSave}
                        disabled={!isValid || !dirty}
                      >
                        <i className="pe-7s-diskette btn-icon-wrapper" />
                        {SAVE}
                      </Button>
                      <Button
                        className="btn-icon mr-2"
                        color="secondary"
                        type="reset"
                        onClick={this.onCancel}
                      >
                        <i className="pe-7s-back btn-icon-wrapper" />
                        {BACK}
                      </Button>
                    </div>
                  </Sticky>
                  <Row>
                    <Col md={8}>
                      <CommonCard
                        {...{
                          articleType: baseArticleType,
                          formikArticle,
                          articleStatuses,
                          userPermissions,
                          isArticleEditable,
                          changeArticleStatus,
                          publishedArticleLimitiations,
                          isValid,
                          dirty,
                          errors,
                          values,
                          setFieldValue,
                          handleChange,
                          maxLengthExtraContent:
                            publishedArticleLimitiations.maxLengthExtraContent,
                        }}
                      />
                      <AttachmentCard
                        {...{
                          articleType: baseArticleType,
                          disabled: articleOnConfirmedStatus,
                          items: attachments,
                          maxFileSize: maxFileSize,
                          onChange: handleChange,
                          isIntegratedDocRepoWithASW:
                            isIntegratedDocRepoWithASW,
                          setFieldValue,
                          isAllowAddNewAttachments,
                          addedAttachments,
                          publishedArticle,
                          workingArticle,
                          newAttachments,
                          maxNewAttachmentCount:
                            publishedArticleLimitiations.maxNewAttachmentCount,
                          countNewAttachmentsAfterPublish,
                        }}
                      />
                      {baseArticleType === ArticleType.RSS.value && (
                        <RssChannelCard
                          rssChannels={map(
                            rssChannels,
                            (rssChannel, rssChannelIndex) => ({
                              ...rssChannel,
                              index: rssChannelIndex,
                            })
                          )}
                        />
                      )}
                      <LinkCard
                        type={"article"}
                        articleType={baseArticleType}
                        articleTranslationId={id}
                        links={map(links, (link, linkIndex) => ({
                          ...link,
                          index: linkIndex,
                        }))}
                        newTabOpenLinks={newTabOpenLinks}
                        onSave={(links) => setFieldValue("links", links)}
                        disabled={
                          !granted ||
                          ![
                            ArticleStatus.Draft.value,
                            ArticleStatus.Published.value,
                          ].includes(status)
                        }
                        collapse={false}
                        categoryOptions={{
                          editable: true,
                          removeable: true,
                          showTargetMethod: true,
                        }}
                        articleOptions={{
                          editable: true,
                          removeable: true,
                          showTargetMethod: true,
                        }}
                        externalLinkOptions={{
                          editable: true,
                          removeable: true,
                          showTargetMethod: true,
                        }}
                      />
                      <MultimediaCard
                        {...{
                          items: map(
                            multimedia,
                            (multimediaItem, multimediaIndex) => ({
                              ...multimediaItem,
                              index: multimediaIndex,
                            })
                          ),
                          onlyReplace: false,
                          disabled: articleOnConfirmedStatus,
                          articleType: baseArticleType,
                          onSave: (multimedia) =>
                            setFieldValue("multimedia", multimedia),
                          onChange: (name, item) => {
                            setFieldValue(name, item);
                          },
                        }}
                      />
                      <ChangeLogCard
                        {...{
                          articleType: baseArticleType,
                          changeLogEntries: formikArticle.changeLogEntries,
                        }}
                      />
                    </Col>
                    <Col>
                      <SourceInformationCard
                        {...{
                          activeLanguage,
                          userPermissions,
                          providingEntities: this.props.providingEntities,
                          isArticleEditable,
                          createDictionaryEntry:
                            this.props.createDictionaryEntry,
                          isValid,
                          dirty,
                          values,
                          errors,
                          handleChange,
                          setFieldValue,
                        }}
                      />
                      <PublicationCard
                        {...{
                          isArticleEditable,
                          isValid,
                          dirty,
                          values,
                          errors,
                          handleChange,
                          setFieldValue,
                          activeLanguage,
                        }}
                      />
                      <CategoryCard
                        articleType={baseArticleType}
                        categories={categories}
                        excludeCategories={excludeCategories}
                        onSave={(categories) =>
                          setFieldValue("categories", [...categories])
                        }
                        onChange={setFieldValue}
                        disabled={articleOnCancelledOrArchiveStatus}
                        errors={eCategories}
                      />
                      <TagCard
                        activeLanguage={language}
                        tags={tags}
                        onSave={(tags) => setFieldValue("tags", tags)}
                        disabled={articleOnCancelledOrArchiveStatus}
                      />
                      <DetailsCard
                        {...{
                          values,
                        }}
                      />
                      <HistoryVersionCard
                        {...{
                          versions,
                          goTo,
                        }}
                        cardCollapse={false}
                        currentVersion={
                          currentVersion ? currentVersion.version : 0
                        }
                      />
                    </Col>
                  </Row>
                </Form>
              </BlockUi>
            </Fragment>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isArticleEditable: isArticleEditableForUser(state),
    excludeCategories: state.auth.excludeCategories || [],
    article: state.article.article,
    activeLanguage: state.application.activeLanguage,
    languages: state.application.languages || [DefaultLanguage], //getActiveLanguagesSelector(state) || [ DefaultLanguage ]
    articleCardBlocking: state.uiBlockState.articleCardBlocking || false,
    newTabOpenLinks: state.application.newTabOpenLinks,
    maxFileSize: state.application.maxFileSize,
    webAppAddress: state.application.webAppAddress || "",
    userPermissions: state.auth.userPermissions || [],
    providingEntities: state.application.dictionaryElements || [],
    isIntegratedDocRepoWithASW:
      state.application.isIntegratedDocRepoWithASW || false,
    rssChannels: state.setting.rssChannels,
    publishedArticleLimitiations:
      state.application.publishedArticleLimitiations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        ...ArticleActionCreators,
        ...DictionaryActionCreators,
        ...UserActionCreators,
        ...ApplicationActionCreators,
        ...SettingActionCreators,
      },
      dispatch
    ),
    cancel: () => dispatch(goBack()),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
