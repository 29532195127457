import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import * as serviceWorker from "./serviceWorker";
import configureStore, { history } from "./config/configureStore";
import { rootSaga } from "./sagas";
import HttpInterceptors from "./utils/http-interceptors";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import axios from "axios";
import { locale, loadMessages } from "devextreme/localization";

import "core-js";
import "./assets/base.scss";
import "./assets/custom.scss";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./assets/fonts/open-iconic/css/open-iconic-bootstrap.min.css";
import Authorized from "./components/Authorized";
import { getUri } from "./helpers/api";
import "devextreme/dist/css/dx.light.css";
import pl from "./translations/pl.json";

axios.defaults.baseURL = getUri("");

const store = configureStore();
store.runSaga(rootSaga);
const rootElement = document.getElementById("root");

loadMessages(pl);
locale("pl");

const renderApp = () => {
  ReactDOM.hydrate(
    <DragDropContextProvider backend={HTML5Backend}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Authorized store={store} />
          <ToastContainer />
        </ConnectedRouter>
      </Provider>
    </DragDropContextProvider>,
    rootElement
  );
};

HttpInterceptors.setup(store, history);
renderApp();

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    renderApp();
  });
}

serviceWorker.unregister();
