import React from 'react';
import isEmpty from 'lodash.isempty';
import lodashFilter from 'lodash.filter';
import find from 'lodash.find';
import map from 'lodash.map';

import { Typeahead } from 'react-bootstrap-typeahead';

import { FilterField } from './FilterField';

import { CONSTANTS } from '@constants';

const { EMPTY_DATA } = CONSTANTS;

export const StatusFilterField = ({
	columnName,
	availableValues,
	availableStatuses,
	filters,
	onChangeFilters,
	isOpen,
	isPopoverClicked,
	targetId
}) => {
	const statusValues = map(availableStatuses, (status) => status.value);

	return (
		<FilterField
			columnName={columnName}
			availableValues={availableValues}
			filters={filters}
			onChangeFilters={onChangeFilters}
			isOpen={isOpen}
			isPopoverClicked={isPopoverClicked}
			targetId={targetId}
		>
			{({ name, placeholder, disabled, filter, onChange }) => {
				const selected =
					filter && Array.isArray(filter.value)
						? map(
								lodashFilter(filter.value, (statusValue) => statusValues.includes(statusValue)),
								(status) => find(availableStatuses, { value: status })
							)
						: filter && filter.value
							? map(
									lodashFilter([ filter.value ], (statusValue) => statusValues.includes(statusValue)),
									(status) => find(availableStatuses, { value: status })
								)
							: [];

				return (
					<Typeahead
						id={`${name}-filter`}
						name={name}
						labelKey="name"
						options={availableStatuses}
						selected={selected}
						emptyLabel={EMPTY_DATA}
						clearButton
						multiple
						allowNew={false}
						placeholder={placeholder}
						onChange={(statuses) => {
							if (!isEmpty(statuses)) {
								onChange(map(statuses, (status) => status.value));
							} else {
								onChange(null);
							}
						}}
						disabled={disabled}
					/>
				);
			}}
		</FilterField>
	);
};
