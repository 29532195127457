import { createAction } from '../base';
import { DefaultLanguage } from '../../helpers/enums';

const GetFooter = createAction('GetFooter');
const UpdateFooter = createAction('UpdateFooter');

function getFooter(lang = DefaultLanguage.isoCode) {
	return {
		type: GetFooter.Request,
		payload: lang
	};
}

function updateFooter(lang = DefaultLanguage.isoCode, footer) {
	return {
		type: UpdateFooter.Request,
		payload: { lang, footer }
	};
}

export const FooterActions = {
	GetFooter,
	UpdateFooter
};

export const FooterActionCreators = {
	getFooter,
	updateFooter
};
