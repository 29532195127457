import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import isEqual from "lodash.isequal";

import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import qs from "qs";

import { ArticleList } from "./ArticleList";
import { ArticleActionCreators } from "../../../../actions/article";
import PageTitle from "../../../../Layout/AppMain/PageTitle";

import { RouteUrls } from "../../../../helpers/routeUrls";
import { ApplicationActionCreators } from "../../../../actions/application";
import {
  LoaderType,
  DefaultLanguage,
  PageSize,
  ArticleType,
  ArticleStatus,
} from "../../../../helpers/enums";
import { hideNoContentPage } from "../../../../reselect/article";
import { FilterActionCreators } from "../../../../actions/filter";

const SystemTypes = [
  ArticleType.SharingInformationTerms.value,
  ArticleType.ReusableInformationTerms.value,
  ArticleType.RSS.value,
  ArticleType.PersonalDataSecurityTerms.value,
  ArticleType.Instruction.value,
  ArticleType.ClientData.value,
  ArticleType.DigitalAvailability.value,
  ArticleType.TransmissionFromRadniInfo.value,
];
class Articles extends Component {
  constructor(props) {
    super(props);

    const { location = { search: "" } } = props;
    const statusSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
    const status =
      statusSearch && statusSearch.status
        ? parseInt(statusSearch.status)
        : null;
    this.state = {
      statusFilter: status,
      cardName: this.getCardName(status),
    };
  }

  componentDidMount() {
    this.onReload(0, PageSize.Rows20, [], []);
  }

  componentDidUpdate(prevProps) {
    const { activeLanguage, location = { search: "" } } = this.props;
    const statusSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
    const status =
      statusSearch && statusSearch.status
        ? parseInt(statusSearch.status)
        : null;

    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.setState(
        {
          statusFilter: status,
          cardName: this.getCardName(status),
        },
        () => this.onReload(0, PageSize.Rows20, [], [])
      );
    }

    if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
      this.onReload(0, PageSize.Rows20, [], []);
    }
  }

  onRowClick = (row) =>
    this.props.goTo(RouteUrls.Article.system.editFunc(row.id));

  onReload = (page, size, filters, sortings) => {
    const {
      getAllArticles,
      activeLanguage,
      articleFilters,
      extraArticleFilters,
    } = this.props;
    const { statusFilter } = this.state;

    getAllArticles(
      activeLanguage.isoCode,
      page,
      size,
      statusFilter ? articleFilters[statusFilter] : articleFilters["all"],
      sortings,
      SystemTypes,
      statusFilter
        ? extraArticleFilters[statusFilter].concat({
            columnName: "status",
            operation: "equal",
            value: [statusFilter],
          })
        : extraArticleFilters["all"]
    );
  };

  clearFilters = async () => {
    const { clearFilters } = this.props;
    const { statusFilter } = this.state;
    await clearFilters(
      ["systemArticleFilters", statusFilter ? statusFilter : "all"],
      []
    );
    await clearFilters(
      ["extraSystemArticleFilters", statusFilter ? statusFilter : "all"],
      statusFilter
        ? [
            {
              columnName: "status",
              operation: "contains",
              value: statusFilter,
            },
          ]
        : []
    );
    this.onReload(0, PageSize.Rows20, [], []);
  };

  getCardName = (status) => {
    switch (status) {
      case ArticleStatus.Draft.value: {
        return "Lista roboczych artykułów systemowych";
      }
      case ArticleStatus.Confirmed.value: {
        return "Lista zatwierdzonych artykułów systemowych";
      }
      case ArticleStatus.ToPublish.value: {
        return "Lista artykułów systemowych do publikacji";
      }
      case ArticleStatus.Published.value: {
        return "Lista opublikowanych artykułów systemowych";
      }
      case ArticleStatus.Withdrawed.value: {
        return "Lista wycofanych artykułów systemowych";
      }
      case ArticleStatus.Archived.value: {
        return "Lista zarchiwizowanych artykułów systemowych";
      }
      default: {
        return "Lista artykułów systemowych";
      }
    }
  };

  render() {
    const {
      articleFilters,
      extraArticleFilters,
      articles,
      totalArticles,
      activeLanguage,
      languages,
      changeApplicationLanguage,
      articleListBlocking,
      changeFilters,
      saveSelectedFilters,
      categorySelected,
    } = this.props;
    const { statusFilter, cardName } = this.state;

    return (
      <Fragment>
        <PageTitle
          heading={cardName}
          icon="pe-7s-copy-file icon-gradient bg-tempting-azure"
        />
        <BlockUi
          tag="div"
          blocking={articleListBlocking}
          loader={<Loader active type={LoaderType} />}
        >
          <ArticleList
            items={articles}
            totalItems={totalArticles}
            onReload={this.onReload}
            onRowClick={this.onRowClick}
            internationalizable={true}
            language={activeLanguage}
            languages={languages}
            onChangeLanguage={changeApplicationLanguage}
            filterable={true}
            drawerable={true}
            filters={articleFilters[statusFilter ? statusFilter : "all"]}
            extraFilters={
              extraArticleFilters[statusFilter ? statusFilter : "all"]
            }
            onChangeFilters={(filters) =>
              changeFilters(
                ["systemArticleFilters", statusFilter ? statusFilter : "all"],
                filters
              )
            }
            onClearFilters={this.clearFilters}
            onChangeExtraFilters={(filters) =>
              changeFilters(
                [
                  "extraSystemArticleFilters",
                  statusFilter ? statusFilter : "all",
                ],
                filters
              )
            }
            saveSelectedFilters={(categories) =>
              saveSelectedFilters(
                [
                  "extraSystemArticleCategories",
                  statusFilter ? statusFilter : "all",
                ],
                categories
              )
            }
            categorySelected={categorySelected}
            canChangeStatus={statusFilter === null}
          />
        </BlockUi>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    categorySelected: state.filter.extraSystemArticleCategories || [],
    articleFilters: state.filter.systemArticleFilters || [],
    extraArticleFilters: state.filter.extraSystemArticleFilters || [],
    articles: hideNoContentPage(state),
    totalArticles: state.article.totalArticles || 0,
    activeLanguage: state.application.activeLanguage,
    languages: state.application.languages || [DefaultLanguage], //getActiveLanguagesSelector(state) || [ DefaultLanguage ]
    articleListBlocking: state.uiBlockState.articleListBlocking || false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...bindActionCreators(
      {
        ...ArticleActionCreators,
        ...ApplicationActionCreators,
        ...FilterActionCreators,
      },
      dispatch
    ),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
