import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import {
  SortableTreeWithoutDndContext as SortableTree,
  toggleExpandedForAll,
  getTreeFromFlatData,
} from "react-sortable-tree";
import RSC from "react-scrollbars-custom";

import { DragDropType, LoaderType } from "../../helpers/enums";
import SearchTarget from "../DragAndDropTargets/SearchTarget";
import CategoryTarget from "../DragAndDropTargets/CategoryTarget";
import CategorySource from "../DragAndDropSources/CategorySource";

import Immutable from "seamless-immutable";
import isEqual from "lodash.isequal";

import { MenuActionCreators } from "@actions/menu";
import { SearchActionCreators } from "@actions/search";

import { combineArrays } from "@components/utils";
import { processTreeData } from "../../utils/menu";

import { CONSTANTS } from "@constants";
const { CONFIRM, CANCEL } = CONSTANTS;

class ChooseCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: Immutable(
        getTreeFromFlatData({
          flatData: props.treeData,
          getKey: (element) => element.elementId,
          getParentKey: (element) => element.parentElementId,
          rootKey: null,
        }),
        { deep: true }
      ),
      type: props.menuType,
      expanded: false,
      categories: props.categories,
    };
  }

  componentDidMount() {
    const { type } = this.state;
    const { getMenu } = this.props;

    getMenu(type, true, true);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.treeData, this.props.treeData)) {
      const newTreeData = this.state.expanded
        ? toggleExpandedForAll({
            treeData: this.props.treeData,
            expanded: this.state.expanded,
          })
        : this.props.treeData;
      this.setState({
        treeData: Immutable(
          getTreeFromFlatData({
            flatData: newTreeData,
            getKey: ({ elementId }) => elementId,
            getParentKey: ({ parentElementId }) => parentElementId,
            rootKey: null,
          }),
          { deep: true }
        ),
      });
    }
    if (!isEqual(prevProps.isOpen, this.props.isOpen) && this.props.isOpen) {
      const { type } = this.state;
      const { getMenu } = this.props;

      getMenu(type, true, true);
    }
    if (!isEqual(prevProps.menuType, this.props.menuType)) {
      this.setState({ type: this.props.menuType });
    }
    if (!isEqual(prevProps.categories, this.props.categories)) {
      this.setState({ categories: this.props.categories });
    }
  }

  updateTreeData = (treeData) =>
    this.setState({
      treeData: treeData,
    });

  searchCategories = (displayText, articles) => {
    const { type } = this.state;
    const { searchCategories } = this.props;
    if (searchCategories && typeof searchCategories === "function") {
      searchCategories(type, displayText, articles, true);
      if (displayText) {
        this.setState({ expanded: true });
      } else {
        this.setState({ expanded: false });
      }
    }
  };

  onAddItems = (categories) =>
    this.setState({
      categories: combineArrays(
        categories,
        this.state.categories,
        "categories"
      ),
    });

  onDeleteItems = (categories) =>
    this.setState({ categories: [...categories] });

  onCancel = (event) => {
    const { onToggle } = this.props;

    if (onToggle && typeof onToggle === "function") {
      this.setState({ expanded: false });
      onToggle(event);
    }

    this.setState({ categories: [...this.props.categories] });
  };

  onSave = (event) => {
    const { onToggle, onSave } = this.props;
    const { categories } = this.state;

    if (onSave && typeof onSave === "function") {
      onSave(categories);
    }

    if (onToggle && typeof onToggle === "function") {
      this.setState({ expanded: false });
      onToggle(event);
    }
  };

  render() {
    const { isOpen, menuCardBlocking, excludeCategories = [] } = this.props;
    const { treeData, categories } = this.state;
    const { onAddItems: onChangeItems, onDeleteItems } = this;

    return (
      <Modal isOpen={isOpen} backdrop size="xl">
        <ModalHeader>
          <div className="d-flex justify-content-between">
            <div>Wybierz kategorie</div>
            <div>
              <Button
                className="btn-icon mr-2"
                color="alternate"
                type="button"
                onClick={this.onSave}
              >
                <i className="pe-7s-check btn-icon-wrapper" />
                {CONFIRM}
              </Button>
              <Button
                className="btn-icon mr-2"
                color="secondary"
                type="button"
                onClick={this.onCancel}
              >
                <i className="pe-7s-back btn-icon-wrapper" />
                {CANCEL}
              </Button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={8}>
              <Card className="mb-2">
                <CardHeader>
                  <div className="widget-content p-0 d-flex flex1-1-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex0-0-3">
                        Wyszukaj kategorię
                      </div>
                      <div className="widget-content-right ml-3 flex1-1-7">
                        <SearchTarget
                          dropType={DragDropType.Article}
                          onChange={this.searchCategories}
                        />
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <BlockUi
                    tag="div"
                    blocking={menuCardBlocking}
                    loader={<Loader active type={LoaderType} />}
                  >
                    <div className="vh-100" style={{ overflow: "auto" }}>
                      <RSC>
                        <SortableTree
                          treeData={processTreeData(treeData)}
                          isVirtualized={false}
                          onChange={this.updateTreeData}
                          rowHeight={80}
                          dndType={DragDropType.Category}
                          shouldCopyOnOutsideDrop={true}
                          canDrop={() => false}
                          canDrag={({ node }) =>
                            !excludeCategories.includes(node.id)
                          }
                          generateNodeProps={({ node, path }) => {
                            return {
                              addItem: this.onAddItems,
                              items: categories,
                              displayText: (
                                <FormGroup className="mb-0">
                                  <Input
                                    className="border-0 fsize-0-8"
                                    style={{ backgroundColor: "#fff" }}
                                    value={node.displayText}
                                    title={node.displayText}
                                    disabled={true}
                                  />
                                </FormGroup>
                              ),
                            };
                          }}
                          nodeContentRenderer={CategorySource}
                        />
                      </RSC>
                    </div>
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-2">
                <CardHeader>
                  <div className="widget-content p-0 d-flex flex1-1-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        Wybrane kategorie
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <CategoryTarget
                    items={categories}
                    {...{ excludeCategories, onChangeItems, onDeleteItems }}
                    dropType={DragDropType.Category}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    treeData: state.menu.menu || [],
    article: state.article.article,
    activeLanguage: state.application.activeLanguage,
    menuCardBlocking: state.uiBlockState.menuCardBlocking || false,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...MenuActionCreators, ...SearchActionCreators },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ChooseCategory);
