import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';

import { LanguageList } from './LanguageList';
import { SettingActionCreators } from '../../../../actions/settings';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, PageSize } from '../../../../helpers/enums';
import { FilterActionCreators } from '../../../../actions/filter';

class LanguagesList extends Component {
	constructor(props) {
		super(props);
		this.onAdd = () => {
			this.props.goTo(RouteUrls.Setting.language.create);
		};
		this.onRowClick = (row) => this.props.goTo(RouteUrls.Setting.language.editFunc(row.id));
	}
	componentDidMount() {
		const { languageFilters } = this.props;
		this.props.loadLanguages(0, PageSize.Rows20, languageFilters, []);
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}

	onReload = (page = 0, size = 20, filters = [], sortings = []) => {
		const { loadLanguages, languageFilters } = this.props;
		loadLanguages(page, size, languageFilters, sortings);
	};

	clearFilters = () => {
		const { loadLanguages, clearFilters } = this.props;
		clearFilters(['languageFilters'], []);
		loadLanguages(0, PageSize.Rows20, [], []);
	};

	render() {
		const { languages, totalLanguages, languageListBlocking, languageFilters, changeFilters } = this.props;
		return (
			<Fragment>
				<PageTitle heading="Lista dostępnych języków" icon="pe-7s-settings icon-gradient bg-tempting-azure" />

				<BlockUi tag="div" blocking={languageListBlocking} loader={<Loader active type={LoaderType} />}>
					<LanguageList
						items={languages}
						totalItems={totalLanguages}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRowClick={this.onRowClick}
						filters={languageFilters}
						onChangeFilters={(filters) => changeFilters(['languageFilters'], filters)}
						onClearFilters={this.clearFilters}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		languageFilters: state.filter.languageFilters || [],
		languages: state.setting.languages || [],
		totalLanguages: state.setting.totalLanguages || 0,
		languageListBlocking: state.uiBlockState.languageListBlocking || false
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators({ ...SettingActionCreators, ...FilterActionCreators }, dispatch),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesList);
