import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import isEqual from 'lodash.isequal';

import { DictionaryEntryList } from './DictionaryEntryList';
import { DictionaryActionCreators } from '../../../../actions/dictionary';
import { ApplicationActionCreators } from '../../../../actions/application';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, DefaultLanguage, PageSize } from '../../../../helpers/enums';
import { FilterActionCreators } from '../../../../actions/filter';

class DictionaryEntries extends Component {
	componentDidMount() {
		const { activeLanguage, getAllDictionaryEntries, dictionaryEntryFilters } = this.props;
		getAllDictionaryEntries(activeLanguage.isoCode, 0, PageSize.Rows20, dictionaryEntryFilters, []);
	}

	componentDidUpdate(prevProps) {
		const { activeLanguage, getAllDictionaryEntries, dictionaryEntryFilters } = this.props;
		if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
			getAllDictionaryEntries(activeLanguage.isoCode, 0, PageSize.Rows20, dictionaryEntryFilters, []);
		}
	}

	componentWillUnmount() {
		this.props.unloadDictionary();
	}

	onAdd = () => {
		this.props.goTo(RouteUrls.Dictionary.dictionaryEntry.create);
	};

	onRemove = (removeIds) => this.props.removeDictionaryEntries(removeIds);

	onReload = (page = 0, size = 20, filters = [], sortings = []) => {
		const { activeLanguage, getAllDictionaryEntries, dictionaryEntryFilters } = this.props;
		getAllDictionaryEntries(activeLanguage.isoCode, page, size, dictionaryEntryFilters, sortings);
	};
	onRowClick = (row) => this.props.goTo(RouteUrls.Dictionary.dictionaryEntry.editFunc(row.id));

	clearFilters = () => {
		const { activeLanguage, getAllDictionaryEntries, clearFilters } = this.props;
		clearFilters(['dictionaryEntryFilters'], []);
		getAllDictionaryEntries(activeLanguage.isoCode, 0, PageSize.Rows20, [], []);
	};

	render() {
		const {
			dictionaryEntries,
			totalDictionaryEntries,
			dictionaryEntryListBlocking,
			languages,
			activeLanguage,
			changeApplicationLanguage,
			dictionaryEntryFilters,
			changeFilters
		} = this.props;
		return (
			<Fragment>
				<PageTitle heading="Podmioty udostępniające" icon="pe-7s-settings icon-gradient bg-tempting-azure" />
				<BlockUi tag="div" blocking={dictionaryEntryListBlocking} loader={<Loader active type={LoaderType} />}>
					<DictionaryEntryList
						items={dictionaryEntries}
						totalItems={totalDictionaryEntries}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRemove={this.onRemove}
						onRowClick={this.onRowClick}
						internationalizable={true}
						language={activeLanguage}
						languages={languages}
						onChangeLanguage={changeApplicationLanguage}
						filters={dictionaryEntryFilters}
						onChangeFilters={(filters) => changeFilters(['dictionaryEntryFilters'], filters)}
						onClearFilters={this.clearFilters}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		dictionaryEntryFilters: state.filter.dictionaryEntryFilters || [],
		dictionaryEntries: state.dictionary.dictionaryEntry.dictionaryEntries || [],
		totalDictionaryEntries: state.dictionary.dictionaryEntry.totalDictionaryEntries || 0,
		activeLanguage: state.application.activeLanguage,
		languages: state.application.languages || [ DefaultLanguage ], //getActiveLanguagesSelector(state) || [ DefaultLanguage ]
		dictionaryEntryListBlocking: state.uiBlockState.dictionaryEntryListBlocking || false
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators(
			{ ...DictionaryActionCreators, ...ApplicationActionCreators, ...FilterActionCreators },
			dispatch
		),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEntries);
