import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import Immutable from 'seamless-immutable';

const target = {
	drop(props, monitor, component) {
		const { id = 0, displayText = '', ...monitorItem } = monitor.getItem();
		const item = {
			id: 0,
			bookmarkId: id,
			displayText: displayText,
			...monitorItem
		};
		const isExists = component.state.items.findIndex(element => element.bookmarkId === item.bookmarkId) !== -1;

		if (isExists) {
			return {
				...monitorItem
			};
		}

		const newItemList = component.state.items.concat(item);
		component.setState(state => ({
			items: newItemList
		}));

		if (component.props.onChangeItems && typeof component.props.onChangeItems === 'function') {
			component.props.onChangeItems(newItemList);
		}
		return item;
	},

	canDrop(props, monitor) {
		if (monitor.didDrop()) {
			// If you want, you can check whether some nested
			// target already handled drop
			return;
		}
		return { moved: true };
	}
};

const collect = (connect, monitor) => {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	};
};

class BookmarkTarget extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: Immutable(props.bookmarks)
		};
	}

	componentDidUpdate(prevProps) {
		const { bookmarks } = this.props;

		if (!isEqual(prevProps.bookmarks, bookmarks)) {
			this.setState({
				items: [ ...bookmarks ]
			});
		}
	}

	clear = index => {
		const newItemList = this.state.items.slice(0, index).concat(this.state.items.slice(index + 1));
		this.setState({
			items: newItemList
		});
		if (this.props.onChangeItems && typeof this.props.onChangeItems === 'function') {
			this.props.onDeleteItems(newItemList);
		}
	};

	clearAll = () => {
		this.setState({ items: [] });
		if (this.props.onChangeItems && typeof this.props.onChangeItems === 'function') {
			this.props.onDeleteItems([]);
		}
	};

	render() {
		const { connectDropTarget } = this.props;
		const { items } = this.state;

		return connectDropTarget(
			<div className="has-aux" tabIndex="-1" style={{ position: 'relative' }}>
				<div className="rbt-input">
					<div
						className={`rbt-input-wrapper dropzone-wrapper dropzone-wrapper-lg category-boxtarget ${!isEmpty(
							items
						)
							? 'height--auto'
							: ''}`}
					>
						{isEmpty(items) && (
							<div>
								<div className="dropzone-content">
									<p className="p-1">Przeciągnij tutaj lub kliknij dwukrotnie na wybraną zakładkę.</p>
								</div>
							</div>
						)}
						{map(items, (item, itemIndex) => (
							<div
								key={`item-${item.id}-${itemIndex}`}
								tabIndex="0"
								className="rbt-token rbt-token-removeable"
							>
								{item.displayText}

								<button
									tabIndex="-1"
									aria-label="Usuń"
									className="close rbt-close rbt-token-remove-button"
									type="button"
									onClick={this.clear.bind(this, itemIndex)}
								>
									<span aria-hidden="true">×</span>
									<span className="sr-only">Usuń</span>
								</button>
							</div>
						))}
					</div>
					{!isEmpty(items) && (
						<div className="rbt-aux">
							<button
								aria-label="Usuń wszystkie"
								className="close rbt-close"
								type="button"
								onClick={this.clearAll}
							>
								<span aria-hidden="true">×</span>
								<span className="sr-only">Usuń wszystkie</span>
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default DropTarget(props => props.dropType, target, collect)(BookmarkTarget);
