import React from 'react';

import Grid from '../../../../components/Grid';
import { filterCell } from './BannerListHelpers';

const columns = [
	{ name: 'id', title: 'id' },
	{ name: 'name', title: 'Nazwa' },
	{ name: 'displayText', title: 'Nazwa wyświetlana zakładki' },
	{ name: 'title', title: 'Tekst pod czytniki dla osób słabowidzących' },
	{ name: 'isActive', title: 'Aktywna' }
];
const columnExtensions = [
	{ columnName: 'displayText', wordWrapEnabled: true },
	{
		columnName: 'isActive',
		width: '15%',
		align: 'center'
	}
];
const hiddenColumns = [ 'id' ];
const textColumns = [ 'name', 'displayText', 'title' ];
const boolColumns = [ 'isActive' ];
const currentPage = 0;

export const BannerList = ({ ...props }) => (
	<Grid
		items={props.items}
		totalItems={props.totalItems}
		columns={columns}
		columnExtensions={columnExtensions}
		hiddenColumns={hiddenColumns}
		textColumns={textColumns}
		boolColumns={boolColumns}
		currentPage={currentPage}
		filterCell={filterCell}
		onReload={props.onReload}
		onRemove={props.onRemove}
		onAdd={props.onAdd}
		onRowClick={props.onRowClick}
		internationalizable={props.internationalizable}
		language={props.language}
		languages={props.languages}
		onChangeLanguage={props.onChangeLanguage}
		filters={props.filters}
		onChangeFilters={props.onChangeFilters}
		onClearFilters={props.onClearFilters}
	/>
);
