import React from 'react';

import {
	setHours,
	setMinutes
} from 'date-fns';

export const TimeInput = ({date = undefined, invalid = false, value, onChangeDate, disabled, ...props}) => (
	<input
		type="time"
		value={value}
		onChange={(e) => {
			if (e.target.value) {
				const splitted = e.target.value.split(":");
				onChangeDate(setHours(setMinutes(date, splitted[1]), splitted[0]));
			}
		}}
		className={`${invalid ? 'is-invalid ' : ''}form-control`}
		disabled={disabled}
	/>
);