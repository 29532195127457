import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { Formik } from 'formik';
import Select from 'react-select';

import { Form, FormGroup, FormFeedback, Row, Col, Card, CardBody, CardHeader, Label, Input, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import Sticky from 'react-stickynode';

import PageTitle from '@layout/AppMain/PageTitle';

import { SettingActionCreators } from '@actions/settings';
import { LoaderType } from '@helpers/enums';

import { checkedUrl } from '../../utils';
import { dropdownErrorStyles } from './styles';

import { CONSTANTS } from '@constants';
import { DefaultFormikConfig, PageSize } from '../../../../helpers/enums';
import { getIcons } from '../../../../reselect/icon';
import { Typeahead } from 'react-bootstrap-typeahead';
import isEmpty from 'lodash.isempty';
import filter from 'lodash.filter';
import find from 'lodash.find';
import { RepositoryConfigValidationSchema } from '../../../../validators/repository';
import SeamlessImmutable from 'seamless-immutable';
import { getUsedExtensions } from '../../../../reselect/repositoryConfigs';

const { GROUP_NAME, DESCRIPTION, ICON_NAME, FILE_EXTENSIONS, SAVE, BACK, COMMON_INFORMATION, DATA_LOADING } = CONSTANTS;

class RepositoryCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadedData: false,
			selectedIcon: [],
			selectedExtensions: [],
			repositoryConfig: {}
		};
	}

	componentDidMount() {
		const { params: { id }, path } = this.props.match;

		this.handleLoadNewRepositoryConfigs(path);
		this.handleData();

		id && this.props.getRepositoryConfig(id);

		this.props.loadRepositoryConfigs(0, PageSize.All, [], []);
	}

	componentDidUpdate(prevProps) {
		const { id: prevId } = prevProps.match.params;
		const { id } = this.props.match.params;

		prevId && id && prevId !== id && this.props.getRepositoryConfig(id);
		prevProps.repositoryConfig.id !== this.props.repositoryConfig.id && this.handleData();
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}

	handleData = () => {
		const { icons, mimeTypes, repositoryConfig } = this.props;
		const selectedIcon = this.props.repositoryConfig.icon
			? filter(icons, (icon) => icon.id === this.props.repositoryConfig.icon)
			: [];
		const filteredExtensions = !isEmpty(this.props.repositoryConfig.extensions)
			? filter(this.props.repositoryConfig.extensions, (extension) =>
					mimeTypes.some((mimeType) => mimeType.extension === extension)
				)
			: [];

		const selectedExtensions = !isEmpty(filteredExtensions)
			? filteredExtensions.map((extension) => {
					const mimeType = find(mimeTypes, (mimeType) => mimeType.extension === extension);
					return {
						label: mimeType.extension,
						value: mimeType.extension,
						id: mimeType.extension
					};
				})
			: [];
		this.setState({
			repositoryConfig: { ...repositoryConfig },
			selectedIcon: selectedIcon,
			selectedExtensions: selectedExtensions,
			isLoadedData: true
		});
	};

	handleLoadNewRepositoryConfigs = (path) => {
		const { isCreate } = checkedUrl(path, 'utworz-nowa');

		isCreate && this.setState({ isLoadedData: true });
	};

	handleName = (name) => this.setState({ repositoryConfig: { ...this.state.repositoryConfig, name } });

	handleDescription = (description) =>
		this.setState({ repositoryConfig: { ...this.state.repositoryConfig, description } });

	handleIcon = (icons) =>
		this.setState({
			repositoryConfig: {
				...this.state.repositoryConfig,
				icon: !isEmpty(icons) ? icons[0].id : ''
			},
			selectedIcon: icons
		});

	handleExtensions = (extensions) =>
		this.setState({
			repositoryConfig: {
				...this.state.repositoryConfig,
				extensions: (extensions || []).map((el) => el.value)
			},
			selectedExtensions: (extensions || []).map((el) => ({
				...el,
				label: el.value
			}))
		});

	render() {
		const {
			icons,
			mimeTypes,
			usedExtensions,
			createRepositoryConfig,
			updateRepositoryConfig,
			repositoryCardBlocking,
			cancel
		} = this.props;

		const { selectedIcon, selectedExtensions, repositoryConfig: initialValues, isLoadedData } = this.state;

		if (!isLoadedData) return <div>{DATA_LOADING}</div>;

		return (
			<Formik
				{...DefaultFormikConfig}
				{...{ initialValues }}
				validationSchema={RepositoryConfigValidationSchema}
				onSubmit={(values) => {
					if (values.id > 0) {
						updateRepositoryConfig(values);
					} else {
						createRepositoryConfig(values);
					}
				}}
				onReset={cancel}
			>
				{({
					errors,
					values,
					touched,
					isValid,
					handleChange,
					handleBlur,
					setFieldValue,
					handleSubmit: onSubmit,
					handleReset: onReset
				}) => (
					<Fragment>
						<PageTitle
							heading="Grupy plików - konfiguracja"
							icon="pe-7s-ribbon icon-gradient bg-tempting-azure"
						/>
						<BlockUi
							tag="div"
							blocking={repositoryCardBlocking}
							loader={<Loader active type={LoaderType} />}
						>
							<Form noValidate method="post" {...{ onSubmit, onReset }}>
								<Sticky
									enabled={true}
									top=".app-header"
									innerZ="101"
									activeClass="sticky-active-class"
									className="mb-2"
								>
									<div className="d-flex justify-content-end">
										<Button
											className="btn-icon mr-2"
											color="primary"
											type="submit"
											disabled={!isValid}
										>
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{SAVE}
										</Button>
										<Button className="btn-icon mr-2" color="secondary" type="reset">
											<i className="pe-7s-back btn-icon-wrapper" />
											{BACK}
										</Button>
									</div>
								</Sticky>
								<Card className="mb-2">
									<CardHeader>
										<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
										{COMMON_INFORMATION}
									</CardHeader>
									<CardBody>
										<Row>
											<Col>
												<FormGroup>
													<Label for="name">{GROUP_NAME}</Label>
													<Input
														type="text"
														id="name"
														name="name"
														placeholder="Wpisz nazwę"
														value={values.name}
														onBlur={(e) => this.handleName(e.target.value)}
														onChange={handleChange}
														invalid={!!errors.name}
													/>
													<FormFeedback>{errors.name}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="description">{DESCRIPTION}</Label>
													<Input
														type="text"
														id="description"
														name="description"
														value={values.description}
														onBlur={(e) => this.handleDescription(e.target.value)}
														onChange={handleChange}
														invalid={!!errors.description}
													/>
													<FormFeedback>{errors.description}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="extensions">{FILE_EXTENSIONS}</Label>
													<Select
														isMulti
														{...{
															axis: 'xy',
															placeholder: 'Wprowadź',
															value: selectedExtensions,
															closeMenuOnSelect: true,
															distance: 4,
															noOptionsMessage: () => 'Brak opcji',
															styles: !!errors.extensions && dropdownErrorStyles,
															getHelperDimensions: ({ node }) =>
																node.getBoundingClientRect(),
															onChange: (e) => {
																this.handleExtensions(e);
															},
															options: SeamlessImmutable(mimeTypes || [])
																.asMutable()
																.sort((a, b) => {
																	if (a.description < b.description) {
																		return -1;
																	}
																	if (a.description > b.description) {
																		return 1;
																	}
																	return 0;
																})
																.filter(
																	(el) =>
																		!isEmpty(usedExtensions)
																			? !usedExtensions.some(
																					(ext) => ext === el.extension
																				)
																			: true
																)
																.map((el) => ({
																	label: `.${el.extension} - ${el.description}`,
																	value: el.extension,
																	id: el.extension
																})),
															name: 'extensions',
															menuPlacement: 'bottom',
															touched,
															autoBlur: true,
															onBlur: handleBlur,
															openMenuOnClick: true,
															invalid: !!errors.extensions
														}}
													/>
													<FormFeedback
														style={{ display: `${!!errors.extensions ? 'block' : 'none'}` }}
													>
														{errors.extensions}
													</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="icon">{ICON_NAME}</Label>
													<Typeahead
														id="icon"
														name="icon"
														clearButton
														selected={selectedIcon}
														labelKey="name"
														options={icons}
														placeholder="Wybierz.."
														onChange={(icons) => {
															if (!isEmpty(icons)) {
																setFieldValue('icon', icons[0].id);
																this.handleIcon(icons);
															} else {
																setFieldValue('icon', null);
																this.handleIcon([]);
															}
														}}
														isInvalid={!!errors.icon}
														renderMenuItemChildren={(option, props) => (
															<Fragment>
																<i
																	className={option.id}
																	style={{
																		height: '24px',
																		marginRight: '10px',
																		width: '24px'
																	}}
																/>
																<span>{option.name}</span>
															</Fragment>
														)}
													/>
													<FormFeedback
														style={{ display: `${!!errors.icon ? 'block' : 'none'}` }}
													>
														{errors.icon}
													</FormFeedback>
												</FormGroup>
											</Col>
											{values.icon && (
												<Col>
													<Fragment>
														<i
															className={values.icon}
															style={{
																fontSize: '60px'
															}}
														/>
													</Fragment>
												</Col>
											)}
										</Row>
									</CardBody>
								</Card>
							</Form>
						</BlockUi>
					</Fragment>
				)}
			</Formik>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		repositoryConfig: state.setting.repositoryConfig || {
			id: 0,
			name: '',
			description: '',
			icon: '',
			extensions: []
		},
		usedExtensions: getUsedExtensions(state),
		repositoryCardBlocking: state.uiBlockState.repositoryCardBlocking || false,
		icons: getIcons(state),
		mimeTypes: state.application.mimeTypes.items || []
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(SettingActionCreators, dispatch),
		cancel: () => dispatch(goBack()),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RepositoryCard);
