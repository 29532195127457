import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import DictionaryEntryList from "./List";
import DictionaryEntryCard from "./Card";

import { RouteUrls } from "../../../helpers/routeUrls";

const DictionaryEntries = () => (
  <Fragment>
    <Switch>
      <Route
        path={RouteUrls.Dictionary.dictionaryEntry.create}
        component={DictionaryEntryCard}
      />
      <Route
        path={`${RouteUrls.Dictionary.dictionaryEntry.edit}/:id`}
        component={DictionaryEntryCard}
      />
      <Route path="*" component={DictionaryEntryList} />
    </Switch>
  </Fragment>
);

export default DictionaryEntries;
