import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import isEmpty from "lodash.isempty";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  FormFeedback,
} from "reactstrap";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import qs from "qs";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

import Grid from "../Grid";

import { LoaderType, PageSize } from "../../helpers/enums";

import { CONSTANTS } from "@constants";
const { CANCEL } = CONSTANTS;

const columns = [
  { name: "id", title: "Identyfikator" },
  { name: "fileName", title: "Nazwa pliku" },
  { name: "createdDate", title: "Data utworzenia" },
  { name: "createdBy", title: "Utworzone przez" },
  { name: "extension", title: "Grupa" },
  { name: "size", title: "Rozmiar" },
];
const hiddenColumns = ["id"];

const columnExtensions = [
  {
    width: "35%",
    columnName: "fileName",
  },
  {
    columnName: "createdBy",
    align: "left",
  },
  {
    width: "10%",
    columnName: "extension",
    align: "center",
  },
  {
    columnName: "size",
    align: "right",
  },
];

const dateColumns = ["createdDate"];

export const RepositoryFileList = ({
  isOpen = false,
  repositoryGroups = [],
  onCancel = Function.prototype,
  onSelect = Function.prototype,
  onSelectValidation,
}) => {
  const [searchBlocking, setSearchBlocking] = useState(false);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState({
    items: [],
    totalItems: 0,
  });
  //   const [isClearSelection, setIsClearSelection] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(PageSize.Rows20);
  const IconFormatter = ({ row }) => {
    const { icon, name } = repositoryGroups.find((group) =>
      group.extensions.some((extension) => extension === row.extension)
    );
    return (
      <span title={name}>
        <i className={`${icon}`} />
      </span>
    );
  };
  const FilenameFormatter = ({ row }) => {
    return <span title={row.fileName}>{row.fileName}</span>;
  };
  const SizeFormatter = ({ row }) => {
    const GB = 1024 * 1024 * 1024;
    const MB = 1024 * 1024;
    const KB = 1024;

    if (row.size > GB) {
      return (
        <span>{`${parseFloat(
          (row.size / GB).toFixed(2)
        ).toLocaleString()} GB`}</span>
      );
    } else if (row.size > MB) {
      return (
        <span>{`${parseFloat(
          (row.size / MB).toFixed(2)
        ).toLocaleString()} MB`}</span>
      );
    } else {
      return (
        <span>{`${parseFloat(
          (row.size / KB).toFixed(2)
        ).toLocaleString()} KB`}</span>
      );
    }
  };
  const dataTypeProviders = [
    <DataTypeProvider
      for={["fileName"]}
      formatterComponent={FilenameFormatter}
    />,
    <DataTypeProvider for={["extension"]} formatterComponent={IconFormatter} />,
    <DataTypeProvider for={["size"]} formatterComponent={SizeFormatter} />,
  ];

  const handleCancel = () => onCancel();

  const isGroupSelected = (groupId) =>
    selectedGroupIds.some((id) => id === groupId);

  const handleSelectGroup = (groupId) => {
    if (!isGroupSelected(groupId)) {
      const newSelected = selectedGroupIds.concat([groupId]);
      setSelectedGroupIds(newSelected);
    } else {
      const newSelected = selectedGroupIds.filter(
        (selectedId) => selectedId !== groupId
      );
      setSelectedGroupIds(newSelected);
    }
  };

  const handleSearch = (
    page = null,
    size = null,
    filters = null,
    sortings = null
  ) => {
    let extensions = [];
    (selectedGroupIds || []).forEach((groupId) => {
      const group = repositoryGroups.find((el) => el.id === groupId);
      if (group) {
        extensions = extensions.concat(group.extensions);
      }
    });

    const filterQuery = qs.stringify(
      {
        title: searchText,
        page: page || currentPage,
        size: size || currentSize,
        filters: filters || [
          {
            columnName: "extension",
            operation: "in",
            value: extensions.join(";"),
          },
        ],
        sortings: sortings || [],
      },
      { addQueryPrefix: true, allowDots: true }
    );

    setSearchBlocking(true);
    axios
      .get(`/api/searches/files${filterQuery}`)
      .then((response) => {
        setSearchResult({
          items: response.data,
          totalItems: response.headers["x-total-count"],
        });
        setSearchBlocking(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setSearchBlocking(false);
      });
  };

  const handeSelect = (ids = []) => {
    const files = (searchResult.items || []).filter((file) =>
      ids.some((id) => id === file.id)
    );

    const isValid = onSelectValidation(files);

    if (isValid) {
      const files = (searchResult.items || []).filter((file) =>
        ids.some((id) => id === file.id)
      );
      if (onSelect && typeof onSelect === "function") {
        onSelect(files);
      }
      onCancel();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedGroupIds([]);
      setSearchText("");
      setSearchResult({ items: [], totalItems: 0 });
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} backdrop size="xl">
      <ModalHeader>
        <div className="d-flex justify-content-between">
          <div>Wybierz z repozytorium</div>
          <div>
            <Button
              className="btn-icon mr-2"
              color="secondary"
              type="button"
              onClick={(e) => handleCancel()}
            >
              <i className="pe-7s-back btn-icon-wrapper" />
              {CANCEL}
            </Button>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <BlockUi
          tag="div"
          blocking={searchBlocking}
          loader={<Loader active type={LoaderType} />}
        >
          <Card className="mb-2">
            <CardHeader>Wyszukiwanie</CardHeader>
            <CardBody>
              <Fragment>
                <FormGroup>
                  <Label>Szukaj w</Label>
                  <Row>
                    {repositoryGroups.map((group) => (
                      <Col md={3}>
                        <Card
                          className="mb-2"
                          onClick={(e) => handleSelectGroup(group.id)}
                        >
                          <CardBody
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              opacity: !isGroupSelected(group.id) ? 0.7 : 1,
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "15px",
                                right: "15px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <i
                                className="pe-7s-check"
                                style={{
                                  fontSize: "30px",
                                  color: !isGroupSelected(group.id)
                                    ? "#ccc"
                                    : "#12c328",
                                }}
                              />
                            </div>
                            <span
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                display: "block",
                                opacity: !isGroupSelected(group.id) ? 0.7 : 1,
                              }}
                            >
                              {group.name}
                            </span>
                            <i
                              className={group.icon}
                              style={{
                                fontSize: "100px",
                                opacity: !isGroupSelected(group.id) ? 0.7 : 1,
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <FormFeedback
                    style={{
                      display: !isEmpty(selectedGroupIds) ? "none" : "block",
                    }}
                  >
                    Wybierz grupę
                  </FormFeedback>
                </FormGroup>
                <Row>
                  <Col md={10}>
                    <FormGroup>
                      <Label>
                        Nazwa pliku{" "}
                        <i
                          className="pe-7s-help1"
                          title="Wyszukiwarka obsługuje operatory * i ?, które można zastosować przy braku znajomości pełnej nazwy pliku"
                        />
                      </Label>
                      <Input
                        type="text"
                        id="searchText"
                        name="searchText"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) =>
                          e.keyCode === 13 &&
                          searchText &&
                          !isEmpty(selectedGroupIds)
                            ? handleSearch()
                            : null
                        }
                        invalid={!searchText}
                      />
                      <FormFeedback>Uzupełnij pole</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="d-flex">
                    <Button
                      color="focus"
                      style={{ width: "100%" }}
                      disabled={isEmpty(selectedGroupIds) || !searchText}
                      onClick={(e) => handleSearch()}
                    >
                      Szukaj
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
          {!isEmpty(searchResult.items) && (
            <Card className="mb-2">
              <CardHeader>Lista plików</CardHeader>
              <CardBody>
                <Grid
                  items={searchResult.items}
                  totalItems={searchResult.totalItems}
                  dataTypeProviders={dataTypeProviders}
                  columns={columns}
                  hiddenColumns={hiddenColumns}
                  columnExtensions={columnExtensions}
                  dateColumns={dateColumns}
                  currentPage={currentPage}
                  selectable={true}
                  onSelect={(ids) => handeSelect(ids)}
                  addable={false}
                  removeable={false}
                  filterable={false}
                  onReload={(page, size, filters, sortings) =>
                    handleSearch(page, size, filters, sortings)
                  }
                  onChangeCurrentPage={(page) => setCurrentPage(page)}
                  onChangePageSize={(size) => setCurrentSize(size)}
                  //   isClearSelection={isClearSelection}
                  //   changeClearSelectionStatus={changeClearSelectionStatus}
                />
              </CardBody>
            </Card>
          )}
        </BlockUi>
      </ModalBody>
    </Modal>
  );
};
