import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import Immutable from 'seamless-immutable';
import { DragDropType } from '../../helpers/enums';
import { SortableTreeWithoutDndContext as SortableTree, getTreeFromFlatData } from 'react-sortable-tree';
import FooterLinkTreeViewNode from '../TreeViewNodes/FooterLinkTreeViewNode';

const target = {
	drop(props, monitor, component) {
		const monitorItem = monitor.getItem();
		const item = {
			...monitorItem.node,
			children: []
		};
		const isExists = component.state.items.findIndex((element) => element.key === item.key) !== -1;

		if (isExists) {
			return {
				...monitorItem
			};
		}

		const newItemList = component.state.items.concat(item);
		component.setState((state) => ({
			items: newItemList
		}));

		if (component.props.onChangeItems && typeof component.props.onChangeItems === 'function') {
			component.props.onChangeItems(newItemList);
		}
		return {
			node: item,
			path: monitorItem.path,
			treeId: monitorItem.treeId,
			treeIndex: monitorItem.treeIndex
		};
	},

	canDrop(props, monitor) {
		if (monitor.didDrop()) {
			// If you want, you can check whether some nested
			// target already handled drop
			return;
		}
		return { moved: true };
	}
};

const collect = (connect, monitor) => {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	};
};

class FooterLinkTarget extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: Immutable(
				getTreeFromFlatData({
					flatData: props.items,
					getKey: (element) => `${element.type}_${element.displayText}`,
					getParentKey: (element) => null,
					rootKey: null
				}),
				{ deep: true }
			)
		};
	}
	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.items, this.props.items)) {
			this.setState({
				items: Immutable(
					getTreeFromFlatData({
						flatData: this.props.items,
						getKey: (element) => `${element.type}_${element.displayText}`,
						getParentKey: (element) => null,
						rootKey: null
					}),
					{ deep: true }
				)
			});
		}
		if (!isEqual(prevProps.isOpen, this.props.isOpen) && this.props.isOpen) {
			this.getItems();
		}
		if (!isEqual(prevProps.listType, this.props.listType)) {
			this.setState({ type: this.props.listType });
		}
	}

	clear = (index) => {
		const newItemList = this.state.items.slice(0, index).concat(this.state.items.slice(index + 1));
		this.setState({
			items: newItemList
		});
		if (this.props.onChangeItems && typeof this.props.onChangeItems === 'function') {
			this.props.onChangeItems(newItemList);
		}
	};

	clearAll = () => {
		this.setState({ items: [] });
		if (this.props.onChangeItems && typeof this.props.onChangeItems === 'function') {
			this.props.onChangeItems([]);
		}
	};

	updateItems = (items) => {
		this.setState({
			items: items
		});

		if (this.props.onChangeItems && typeof this.props.onChangeItems === 'function') {
			this.props.onChangeItems(items);
		}
	};

	onMoveNode = ({ treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex }) => {
		this.setState({
			items: Immutable(treeData, { deep: true })
		});

		if (this.props.onChangeItems && typeof this.props.onChangeItems === 'function') {
			this.props.onChangeItems(treeData);
		}
	};

	canDrop = ({ node, prevPath, prevParent, prevTreeIndex, nextPath, nextParent, nextTreeIndex }) => {
		const canDrop =
			this.state.items.findIndex((element) => element.key === node.key) === -1 ||
			(prevTreeIndex !== null && prevTreeIndex !== undefined && prevTreeIndex !== nextTreeIndex);
		return canDrop;
	};

	render() {
		const { connectDropTarget } = this.props;
		const { items } = this.state;

		return connectDropTarget(
			<div className="has-aux" tabIndex="-1" style={{ position: 'relative' }}>
				<div className="rbt-input">
					<div
						className={`rbt-input-wrapper ${isEmpty(items)
							? 'dropzone-wrapper dropzone-wrapper-lg'
							: ''} category-boxtarget`}
						style={{ display: 'block', height: '100vh' }}
					>
						{isEmpty(items) && (
							<div>
								<div className="dropzone-content">
									<p className="p-1">Przeciągnij element i upuść ją w tym oknie.</p>
								</div>
							</div>
						)}
						{!isEmpty(items) && (
							<SortableTree
								className={'d-block'}
								treeData={items}
								onChange={this.updateItems}
								onMoveNode={this.onMoveNode}
								maxDepth={0}
								rowHeight={80}
								isVirtualized={false}
								canDrop={this.canDrop.bind(this)}
								dndType={DragDropType.FooterLink}
								shouldCopyOnOutsideDrop={true}
								nodeContentRenderer={FooterLinkTreeViewNode}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default DropTarget((props) => props.dropType, target, collect)(FooterLinkTarget);
