import React, { Component, Fragment } from 'react';
import { Button, ListGroupItem } from 'reactstrap';
import LinkExternal from '../Modals/LinkDetails';

export default class LinkCardItem extends Component {
	static defaultProps = {
		editable: true,
		removeable: true,
		link: { title: '' },
		onToggle: event => {},
		onSave: link => {}
	};

	state = {
		isOpen: false
	};
	toggleModalOpen = (name, event) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.setState({ [name]: !this.state[name] });
	};

	handleSaveLink = link => {
		const { onChange } = this.props;

		if (onChange && typeof onChange === 'function') {
			onChange(link.index, link);
		}
	};

	handleRemoveLink = () => {
		const { link, onRemove } = this.props;

		if (onRemove && typeof onRemove === 'function') {
			onRemove(link.index);
		}
	};

	render() {
		const { link, editable = true, removeable = true, showTargetMethod = true } = this.props;
		const { isOpen } = this.state;

		return (
			<Fragment>
				{!isOpen && (
					<ListGroupItem>
						<div className="widget-content p-0">
							<div className="widget-content-wrapper">
								<div title={link.type === 30 ? link.url : null} className="widget-content-left flex2">
									{link.displayText}
								</div>
								<div className="widget-content-right">
									{editable && (
										<Button
											className="border-0 btn-transition"
											outline
											color="success"
											title="Edytuj link"
											onClick={this.toggleModalOpen.bind(this, 'isOpen')}
										>
											<i className="lnr-pencil" />
										</Button>
									)}
									{removeable && (
										<Button
											className="border-0 btn-transition"
											outline
											color="danger"
											title="Usuń link"
											onClick={this.handleRemoveLink}
										>
											<i className="lnr-cross" />
										</Button>
									)}
								</div>
							</div>
						</div>
					</ListGroupItem>
				)}
				{isOpen && (
					<LinkExternal
						isOpen={isOpen}
						link={link}
						onSave={this.handleSaveLink}
						onToggle={this.toggleModalOpen.bind(this, 'isOpen')}
						showTargetMethod={showTargetMethod}
					/>
				)}
			</Fragment>
		);
	}
}
