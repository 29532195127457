import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import Office from "./Office/Card";
import OfficeManagers from "./OfficeManagers/Card";
import { RouteUrls } from "../../../helpers/routeUrls";

const Offices = () => (
  <Fragment>
    <Switch>
      <Route path={RouteUrls.Setting.offices.office.main} component={Office} />
      <Route
        path={RouteUrls.Setting.offices.officeManagers.list}
        component={OfficeManagers}
      />
    </Switch>
  </Fragment>
);

export default Offices;
