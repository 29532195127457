import React, { Fragment } from 'react'
import map from 'lodash.map';
import filter from 'lodash.filter';
import Flag from 'react-flagkit';
import { AVAILABLE_ISO_CODE } from '../../../../../helpers/isoCode';

export const LanguageFlag = ({ values: { language: { isoCode: vIsoCode }}, languages }) => (
    <Fragment>
        {map(filter(languages, ({ isoCode }) => isoCode === vIsoCode), (language, langIndex) => {
            if (language.isoCode !== '' &&  AVAILABLE_ISO_CODE.includes(language.isoCode)){
                return <Flag key={langIndex} className="ml-2 opacity-8" country={language.isoCode} size="24" />
            } else if  (language.isoCode !== '' &&  !AVAILABLE_ISO_CODE.includes(language.isoCode)){
                return <img
                className={`ml-2 opacity-8`}
                src={language.isoCodeFile.path}
                alt={`Grafika ${language.isoCodeFile.fileName}`}
            />
            } else {
                return <Flag key={langIndex} className="ml-2 opacity-8" country='PL' size="24" />
            }
        }
        )}
    </Fragment>
)

