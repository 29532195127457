import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';

import { DragDropType, LoaderType, DefaultLanguage } from '../../helpers/enums';
import SearchTarget from '../DragAndDropTargets/SearchTarget';
import BookmarkTarget from '../DragAndDropTargets/BookmarkTarget';

import isEqual from 'lodash.isequal';

import { SettingActionCreators } from '../../actions/settings';
import { SearchActionCreators } from '../../actions/search';
import { BookmarkList } from '../BookmarkList';

import { combineArrays } from '@components/utils';

import { CONSTANTS } from '@constants';
const { CONFIRM, CANCEL } = CONSTANTS;

class ChooseBookmark extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			isoCode: props.isoCode || DefaultLanguage.isoCode,
			bookmarks: props.bookmarks
		};
	}

	componentDidMount() {
		const { loadBookmarks } = this.props;
		const { isoCode } = this.state;
		loadBookmarks(isoCode);
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.isOpen, this.props.isOpen) && this.props.isOpen) {
			const { loadBookmarks } = this.props;
			const { isoCode } = this.state;
			loadBookmarks(isoCode);
		}
		if (!isEqual(prevProps.isoCode, this.props.isoCode)) {
			this.setState({ isoCode: this.props.isoCode });
			const { loadBookmarks, isoCode } = this.props;
			loadBookmarks(isoCode);
		}
		if (!isEqual(prevProps.bookmarks, this.props.bookmarks)) {
			this.setState({ bookmarks: this.props.bookmarks });
		}
	}

	searchBookmarks = (displayText, articles) => {
		const { type } = this.state;
		const { searchCategories } = this.props;
		if (searchCategories && typeof searchCategories === 'function') {
			searchCategories(type, displayText, articles);
			if (displayText) {
				this.setState({ expanded: true });
			} else {
				this.setState({ expanded: false });
			}
		}
	};

	onChangeItems = bookmarks =>
		this.setState({ bookmarks: combineArrays(bookmarks, this.state.bookmarks, 'bookmarks') });

	onDeleteItems = bookmarks => {
		this.setState({ bookmarks: [ ...bookmarks ] });
	};

	onCancel = event => {
		const { onToggle } = this.props;

		if (onToggle && typeof onToggle === 'function') {
			this.setState({ expanded: false });
			onToggle(event);
		}
	};

	onSave = event => {
		const { onToggle, onSave } = this.props;
		const { bookmarks } = this.state;

		if (onSave && typeof onSave === 'function') {
			onSave(bookmarks);
		}

		if (onToggle && typeof onToggle === 'function') {
			this.setState({ expanded: false });
			onToggle(event);
		}
	};

	render() {
		const { isOpen, bookmarkListBlocking, bookmarkList } = this.props;
		const { bookmarks } = this.state;
		const { onChangeItems, onDeleteItems } = this;

		return (
			<Modal isOpen={isOpen} backdrop size="xl">
				<ModalHeader>
					<div className="d-flex justify-content-between">
						<div>Wybierz zakładki</div>
						<div>
							<Button className="btn-icon mr-2" color="alternate" type="button" onClick={this.onSave}>
								<i className="pe-7s-check btn-icon-wrapper" />{CONFIRM}
							</Button>
							<Button className="btn-icon mr-2" color="secondary" type="button" onClick={this.onCancel}>
								<i className="pe-7s-back btn-icon-wrapper" />{CANCEL}
							</Button>
						</div>
					</div>
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col md={8}>
							<Card className="mb-2">
								<CardHeader>
									<div className="widget-content p-0 d-flex flex1-1-0">
										<div className="widget-content-wrapper">
											<div className="widget-content-left flex0-0-3">Wyszukaj zakładkę</div>
											<div className="widget-content-right ml-3 flex1-1-7">
												<SearchTarget
													dropType={DragDropType.Unknown}
													onChange={this.searchBookmarks}
													disabled={true}
												/>
											</div>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<BlockUi
										tag="div"
										blocking={bookmarkListBlocking}
										loader={<Loader active type={LoaderType} />}
									>
										<div className="vh-100">
											<BookmarkList
												bookmarks={bookmarkList}
												currentBookmarks={bookmarks}
												{...{ onChangeItems }}
											/>
										</div>
									</BlockUi>
								</CardBody>
							</Card>
						</Col>
						<Col>
							<Card className="mb-2">
								<CardHeader>
									<div className="widget-content p-0 d-flex flex1-1-0">
										<div className="widget-content-wrapper">
											<div className="widget-content-left">Wybrane zakładki</div>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<BookmarkTarget
										dropType={DragDropType.Bookmark}
										{...{ bookmarks, onChangeItems, onDeleteItems }}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		);
	}
}
const mapStateToProps = state => ({
	bookmarkList: state.setting.bookmarks || [],
	bookmarkListBlocking: state.uiBlockState.bookmarkListBlocking || false
});
const mapDispatchToProps = dispatch =>
	bindActionCreators({ ...SettingActionCreators, ...SearchActionCreators }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChooseBookmark);
