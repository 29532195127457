import axios from 'axios';
import qs from 'qs';

export const getListRequest = ({ url = '', page = 0, size = 20, filters = [], sortings = [], ...rest }) => {
	const filterQuery = qs.stringify(
		{ filters: filters, page: page, size: size, sortings: sortings, ...rest },
		{ addQueryPrefix: true, allowDots: true }
	);
	return axios
		.get(`${url}${filterQuery}`)
		.then((response) => ({
			items: response.data,
			totalItems: response.headers['x-total-count']
		}))
		.catch((err) => {
			throw err;
		});
};
