import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import HeaderCard from "./Card";

const Footers = ({ match }) => (
  <Fragment>
    <Switch>
      <Route path={match.url} component={HeaderCard} />
    </Switch>
  </Fragment>
);

export default Footers;
