import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Card, CardHeader, CardBody, Col, Row, Input } from 'reactstrap';

export const TileModeCard = ({ value, title, onChange, ...props }) => {
	const [ tileModeValue, setTileModeValue ] = useState(value || 0);

	useEffect(
		() => {
			setTileModeValue(value);
		},
		[ value ]
	);

	const handleValueChange = (e) => {
		setTileModeValue(e.target.value);

		if (onChange && typeof onChange === 'function') {
			onChange('value.value', e.target.value);
		}
	};

	return (
		<Card className="mb-2">
			<CardHeader>
				<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
				{title}
			</CardHeader>
			<CardBody>
				<Row>
					<Col>
						<FormGroup>
							<Label for="paramValue">{title}</Label>
							<Input
								type="select"
								id="paramValue"
								name="paramValue"
								value={tileModeValue}
								onChange={handleValueChange}
							>
								<option value="2">2</option>
								<option value="4">4</option>
							</Input>
						</FormGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};
