import { createAction } from '../base';

const GetAllSystemParameters = createAction('GetAllSystemParameters');
const GetSystemParameter = createAction('GetSystemParameter');
const UpdateSystemParameter = createAction('UpdateSystemParameter');

function getAllSystemParameters(page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: GetAllSystemParameters.Request,
		payload: { page, size, filters, sortings }
	};
}
function getSystemParameter(id) {
	return {
		type: GetSystemParameter.Request,
		payload: id
	};
}
function updateSystemParameter(systemParameter) {
	return {
		type: UpdateSystemParameter.Request,
		payload: systemParameter
	};
}

export const SystemParametersActions = {
	GetAllSystemParameters,
	GetSystemParameter,
	UpdateSystemParameter
};

export const SystemParametersActionCreators = {
	getAllSystemParameters,
	getSystemParameter,
	updateSystemParameter
};
