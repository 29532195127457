import Immutable from "seamless-immutable";
import { Application } from "../stores";
import { ApplicationActions } from "../actions/application";
import { SettingActions } from "../actions/settings";
import { DictionaryActions } from "../actions/dictionary";
import { SystemParametersActions } from "../actions/settings/system-parameters";
import find from "lodash.find";

import { createUserManager } from "redux-oidc";
import { OidcConfiguration } from "../helpers/ocid";
import { ArchiveActions } from "../actions/archive";

const initialState = Immutable(Application);

export function application(state = initialState, action = {}) {
  switch (action.type) {
    case "redux-oidc/USER_SIGNED_OUT":
    case "redux-oidc/SILENT_RENEW_ERROR": {
      const userManager = createUserManager({
        ...OidcConfiguration,
        ...state.serverIdentity,
        response_type: "code",
        scope: `openid profile offline_access ${state.serverIdentity.scope}`,
      });
      userManager.signoutRedirect();
      return state;
    }
    case ApplicationActions.GetApplicationParameters.Success: {
      const {
        languages,
        newTabOpenLinks = { value: false },
        serverIdentity = {
          address: "",
          client_id: "",
          scope: "",
        },
        dictionaryElements,
        sessionTimeout = 1,
        webAppAddress,
      } = action.payload;

      let activeLanguage = null;
      let storageLanguage = localStorage.getItem("language");

      if (!storageLanguage) {
        localStorage.setItem("language", "PL");
        storageLanguage = localStorage.getItem("language");
      }

      activeLanguage = find(
        languages,
        (language) => language.isoCode === storageLanguage
      );

      if (!activeLanguage) {
        activeLanguage = find(
          languages,
          (language) => language.isoCode === "PL"
        );
      }

      return state.merge({
        ...action.payload,
        sessionTimeout: sessionTimeout * 60 * 1000,
        activeLanguage: activeLanguage,
        newTabOpenLinks: newTabOpenLinks.value,
        serverIdentity: serverIdentity,
        dictionaryElements: dictionaryElements.items,
        webAppAddress,
      });
    }
    case ApplicationActions.GetAppParamLanguages.Success: {
      const { languages } = action.payload;

      let activeLanguage = null;
      let storageLanguage = localStorage.getItem("language");

      if (!storageLanguage) {
        localStorage.setItem("language", "PL");
        storageLanguage = localStorage.getItem("language");
      }

      activeLanguage = find(
        languages,
        (language) => language.isoCode === storageLanguage
      );

      if (!activeLanguage) {
        activeLanguage = find(
          languages,
          (language) => language.isoCode === "PL"
        );
      }

      return state.merge({
        ...action.payload,
        activeLanguage: activeLanguage,
      });
    }
    case ApplicationActions.GetAppParamDictionaryElements.Success: {
      return state.merge({
        dictionaryElements: action.payload.dictionaryElements.items,
      });
    }
    case ApplicationActions.ChangeApplicationLanguage.Success: {
      return state.merge({ activeLanguage: action.payload });
    }
    case SettingActions.UpdateLanguageStatusAction.Success: {
      const { id, status } = action.payload;
      const languageIndex = state.languages.findIndex(
        (language) => language.id === id
      );
      const language = state.getIn(["languages", [languageIndex]]);

      return state.setIn(["languages", [languageIndex]], {
        ...language,
        status,
      });
    }

    case SettingActions.CreateLanguage.Success: {
      return state.setIn(["languages"], state.languages.concat(action.payload));
    }

    case SystemParametersActions.UpdateSystemParameter.Success: {
      if (action.payload.keyName === "DefaultMethodOpenLinks") {
        return state.setIn(["newTabOpenLinks"], action.payload.value.value);
      }
      if (action.payload.keyName === "MaxFileSize") {
        return state.setIn(["maxFileSize"], action.payload.value.value);
      }
      if (action.payload.keyName === "SessionTimeout") {
        return state.merge({
          sessionTimeout: action.payload.value.value * 60 * 1000,
          sessionEnabled: action.payload.value.enabled,
        });
      }
      return state;
    }

    case DictionaryActions.CreateDictionaryEntryAction.Success: {
      const dictionaryElements = Immutable(state).getIn(["dictionaryElements"]);
      return state.setIn(
        ["dictionaryElements"],
        dictionaryElements.concat([action.payload])
      );
    }
    case DictionaryActions.RemoveDictionaryEntryAction.Success: {
      const dictionaryEntries = Immutable(state).getIn(["dictionaryElements"]);
      const newDictionaryEntries = [];
      dictionaryEntries.reduce((prev, curr) => {
        if (!action.payload.includes(curr.id)) {
          prev.push(curr);
          return prev;
        }
        return prev;
      }, newDictionaryEntries);
      return state.setIn(["dictionaryElements"], newDictionaryEntries);
    }
    case ApplicationActions.LastResetSessionTime.Success: {
      return state.merge({ lastResetSessionTime: new Date().getTime() });
    }
    case ArchiveActions.GetArchiveBip.Success: {
      return state.merge({ archiveBip: action.payload });
    }
    default: {
      return state;
    }
  }
}
