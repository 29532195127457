import * as yup from 'yup';

export const OfficeContactValidationSchema = yup.object().shape({
	fullName: yup.string().required('Imię i nazwisko jest wymagane').trim().max(80, 'Maksymalna liczba znaków wynosi 80').nullable(),
	jobPosition: yup.string().required('Stanowisko jest wymagane').max(150, 'Maksymalna liczba znaków wynosi 150').trim().nullable(),
	rawDescription: yup.string().max(1000, 'Maksymalna liczba znaków wynosi 1000').trim().nullable(),
});
export const OfficeContactListValidationSchema = yup.object().shape({
	contacts: yup.array().min(1).of(OfficeContactValidationSchema)
});
