import React, { Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Horizontal from "./Horizontal";
import Vertical from "./Vertical";
import { RouteUrls } from "../../helpers/routeUrls";
import { useSelector } from "react-redux";
import { getLayoutPermissions } from "../../reselect/permission";

const Settings = ({ match }) => {
  const permissions = useSelector((state) => getLayoutPermissions(state));

  return (
    <Fragment>
      {permissions.accessToMenus ? (
        <Switch>
          <Route path={RouteUrls.Menu.horizontal} component={Horizontal} />
          <Route path={RouteUrls.Menu.vertical} component={Vertical} />
        </Switch>
      ) : (
        <Redirect to={RouteUrls.App.main} />
      )}
    </Fragment>
  );
};

export default Settings;
