import React from "react";
import { Switch, Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Oidc } from "@axa-fr/react-oidc-redux";

import Logout from "../Pages/Logout";
import NoAccess from "../Pages/NoAccess";
import Main from "../Pages/Main";
import {
  NotAuthorizedPage,
  NotAuthenticatedPage,
  LoginCallback,
  SessionLostComponent,
} from "../Pages/Main/OidcCallback";
import { ApplicationActionCreators } from "../actions/application";
import { OidcConfiguration } from "../helpers/ocid";
import { RouteUrls } from "../helpers/routeUrls";
import { useEffect } from "react";

const Authorized = (props) => {
  const { store, serverIdentity, getApplicationParameters } = props;

  const language = localStorage.getItem("language");

  useEffect(() => {
    getApplicationParameters(language || "PL");
  }, [language, getApplicationParameters]);

  return serverIdentity.authority ? (
    <Oidc
      store={store}
      configuration={{
        ...OidcConfiguration,
        ...serverIdentity,
        response_type: "code",
        scope: `openid profile offline_access ${serverIdentity.scope}`,
      }}
      notAuthorized={NotAuthorizedPage}
      notAuthenticated={NotAuthenticatedPage}
      sessionLostComponent={SessionLostComponent}
      callbackComponentOverride={LoginCallback}
    >
      <Switch>
        <Route path={RouteUrls.App.noAccess} component={NoAccess} />
        <Route path={RouteUrls.App.logout} component={Logout} />
        <Route path={RouteUrls.App.main} component={Main} />
      </Switch>
    </Oidc>
  ) : (
    <div>&nbsp;</div>
  );
};

const mapStateToProp = (state) => ({
  serverIdentity: state.application.serverIdentity,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ApplicationActionCreators, dispatch);
};
export default connect(mapStateToProp, mapDispatchToProps)(Authorized);
