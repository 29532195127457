import { takeEvery, fork, put } from 'redux-saga/effects';
import { DictionaryActions } from '../actions/dictionary';
import { DictionaryEntrySagas } from './dictionary/dictionaryEntry';
import { TagSagas } from './dictionary/tag';
import { IsoCodeSagas } from './dictionary/isoCode';

function* watchUnloadDictionary() {
	yield takeEvery(DictionaryActions.UnloadDictionaryAction.Request, unloadDictionary);
}
function* unloadDictionary() {
	yield put({
		type: DictionaryActions.UnloadDictionaryAction.Success
	});
}
export const DictionarySaga = [ fork(watchUnloadDictionary), ...TagSagas, ...DictionaryEntrySagas, ...IsoCodeSagas ];
