import { createAction } from './base';

const SearchCategories = createAction('SearchCategories');
const SearchCategoriesByTitle = createAction('SearchCategoriesByTitle');
const SearchArticles = createAction('SearchArticles');

function searchCategories(menuType, title, articles = [], categoryOnly = false) {
	return {
		type: SearchCategories.Request,
		payload: { menuType, title, articles, categoryOnly }
	};
}
function searchCategoriesByTitle(title) {
	return {
		type: SearchCategoriesByTitle.Request,
		payload: title
	};
}

function searchArticles(title, categories = [], menuType = null) {
	return {
		type: SearchArticles.Request,
		payload: { title, categories, menuType }
	};
}

export const SearchActionCreators = {
	searchCategories,
	searchCategoriesByTitle,
	searchArticles
};

export const SearchActions = {
	SearchCategories,
	SearchCategoriesByTitle,
	SearchArticles
};
