import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';

import { IsoCodeList } from './IsoCodeList';
import { DictionaryActionCreators } from '../../../../actions/dictionary';
import { ApplicationActionCreators } from '../../../../actions/application';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType } from '../../../../helpers/enums';

class IsoCodes extends Component {
	componentDidMount() {
		this.props.getNonDefaultIsoCodes();
	}

	componentWillUnmount() {
		this.props.unloadDictionary();
	}

	onAdd = () => {
		this.props.goTo(RouteUrls.Dictionary.isoCode.create);
	};

	onRemove = (removeIds) => this.props.removeIsoCodes(removeIds);

	onReload = (page = 0, size = 20, filters = [], sortings = []) => {
		this.props.getNonDefaultIsoCodes(page, size, filters, sortings);
	};
	onRowClick = (row) => this.props.goTo(RouteUrls.Dictionary.isoCode.editFunc(row.id));

	render() {
		const { isoCodes, totalIsoCodes} = this.props;
		return (
			<Fragment>
				<PageTitle
					heading="Kody ISO języka"
					icon="pe-7s-settings icon-gradient bg-tempting-azure"
				/>
				<BlockUi tag="div" loader={<Loader active type={LoaderType} />}>
					<IsoCodeList
						items={isoCodes}
						totalItems={totalIsoCodes}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRemove={this.onRemove}
						onRowClick={this.onRowClick}
						internationalizable={true}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		isoCodes: state.dictionary.isoCode.isoCodes || [],
		totalIsoCodes: state.dictionary.isoCode.totalIsoCodes || 0,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators({ ...DictionaryActionCreators, ...ApplicationActionCreators }, dispatch),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IsoCodes);
