import React, { useState, useEffect, useRef } from 'react';
import {
	FormGroup,
	FormFeedback,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Label,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	Button
} from 'reactstrap';
import { Formik } from 'formik';
import 'react-image-crop/dist/ReactCrop.css';

import { OfficeContactValidationSchema } from '../../../../../validators';

import { CONSTANTS } from '@constants';
import {
	DefaultFormikConfig,
	FileExtensionTypes,
	ImageMimeType,
	UPLOAD_OFFICE_CONTACT_PHOTO_URL
} from '../../../../../helpers/enums';
import { TinyMCE } from '../../../../../components/TinyMCE';
import UploadFile from '../../../../../components/UploadFile';
import { fileUploadError } from '../../../../../helpers/error-message';
import { CropImageUpload } from '../../../../../components/CropImageUpload';
import isEmpty from 'lodash.isempty';

const { CONFIRM, CANCEL, COMMON_INFORMATION, EDIT_OFFICE_CONTACT, FULL_NAME, JOB_POSITION, DESCRIPTION } = CONSTANTS;

const RectangleSize = { width: 230, height: 230 };
const DefaultCropData = {
	unit: 'px',
	x: 0,
	y: 0,
	width: RectangleSize.width,
	height: RectangleSize.height
}

export default ({
	isOpen = false,
	officeContact = {},
	maxFileSize = 0,
	updateContact = (element) => element,
	cancel = () => {}
}) => {
	const [ cropData, setCropData ] = useState(
		officeContact && officeContact.officeContactPhoto && officeContact.officeContactPhoto.cropData
			? officeContact.officeContactPhoto.cropData
			: DefaultCropData
	);
	const [ photoUploaded, setPhotoUploaded ] = useState(officeContact && officeContact.officeContactPhoto);

	useEffect(
		() => {
			if (officeContact && officeContact.officeContactPhoto && officeContact.officeContactPhoto.cropData) {
				if (!isEmpty(officeContact.officeContactPhoto.cropData)) {
					setCropData(officeContact.officeContactPhoto.cropData)
				} else if (!isEmpty(cropData)) {
					setCropData(cropData)
				} else {
					setCropData(DefaultCropData)
				};
				setPhotoUploaded(officeContact && officeContact.officeContactPhoto);
			}
		},
		[ officeContact ]
	);

	return (
		<Formik
			{...DefaultFormikConfig}
			initialValues={officeContact}
			validationSchema={OfficeContactValidationSchema}
			onSubmit={(values) => {
				if (values.officeContactPhoto) {
					values.officeContactPhoto = {...values.officeContactPhoto, cropData};
				}
				updateContact(values);
				cancel();
			}}
			onReset={cancel}
		>
			{({
				errors,
				values,
				handleChange,
				isValid,
				dirty,
				handleSubmit,
				handleReset,
				setFieldValue,
				setValues
			}) => (
				<Modal isOpen={isOpen} backdrop size="xl" toggle={handleReset}>
					<ModalHeader>
						<div className="d-flex justify-content-between">
							<div>{EDIT_OFFICE_CONTACT}</div>
							<div>
								<Button
									className="btn-icon mr-2"
									color="alternate"
									type="button"
									onClick={handleSubmit}
									disabled={!isValid || !dirty}
								>
									<i className="pe-7s-check btn-icon-wrapper" />
									{CONFIRM}
								</Button>
								<Button className="btn-icon mr-2" color="secondary" type="button" onClick={handleReset}>
									<i className="pe-7s-back btn-icon-wrapper" />
									{CANCEL}
								</Button>
							</div>
						</div>
					</ModalHeader>
					<ModalBody>
						<Card className="mb-2">
							<CardHeader>
								<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
								{COMMON_INFORMATION}
							</CardHeader>
							<CardBody>
								<Row>
									<Col>
										<Row>
											<Col>
												<FormGroup>
													<Label for="fullName">{FULL_NAME}</Label>
													<Input
														type="text"
														id="fullName"
														name="fullName"
														placeholder="Wpisz nazwę"
														value={values.fullName}
														onChange={handleChange}
														invalid={!!errors.fullName}
													/>
													<FormFeedback>{errors.fullName}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="jobPosition">{JOB_POSITION}</Label>
													<Input
														type="text"
														id="jobPosition"
														name="jobPosition"
														placeholder="Wpisz nazwę stanowiska"
														value={values.jobPosition}
														onChange={handleChange}
														invalid={!!errors.jobPosition}
													/>
													<FormFeedback>{errors.jobPosition}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
									</Col>
									{!photoUploaded && (
										<Col>
											<Row>
												<Col>
													<UploadFile
														disabled={false}
														uploadUrl={UPLOAD_OFFICE_CONTACT_PHOTO_URL}
														canRemove={values.officeContactPhotoId !== null}
														maxFileSize={maxFileSize}
														acceptFileType={`${ImageMimeType.BMP}, ${ImageMimeType.PNG}, ${ImageMimeType.JPG}, ${ImageMimeType.GIF}`}
														acceptExtensionFiles={[
															FileExtensionTypes.BMP,
															FileExtensionTypes.PNG,
															FileExtensionTypes.JPG,
															FileExtensionTypes.JPEG,
															FileExtensionTypes.GIF
														]}
														additionalInfo={`Zalecany format zdjęcia powinien być w formacie ${RectangleSize.width}x${RectangleSize.height} pikseli`}
														recommendedWidth={400}
														recommendedHeight={400}
														onDrop={Function.prototype}
														onDropAccepted={(file) => {
															setCropData({
																unit: 'px',
																x: 0,
																y: 0,
																width: RectangleSize.width,
																height: RectangleSize.height
															});
															setValues({
																...values,
																officeContactPhotoId: file.id,
																officeContactPhoto: file
															});
														}}
														onDropRejected={(file) => fileUploadError(file, maxFileSize)}
														onCancel={Function.prototype}
													/>
												</Col>
											</Row>
										</Col>
									)}
								</Row>
								<Row>
									{values.officeContactPhoto && (
										<Col>
											<CropImageUpload
												previewSize={RectangleSize}
												previewStyle={{
													height: `${RectangleSize.height}px`,
													width: `${RectangleSize.width}px`,
													backgroundColor: 'grey'
												}}
												normalStyle={{
													minHeight: `${RectangleSize.height}px`,
													minWidth: `${RectangleSize.width}px`,
													backgroundColor: 'grey'
												}}
												cropData={cropData}
												path={values.officeContactPhoto.path}
												fileName={values.officeContactPhoto.fileName}
												onRemoveImage={() => {
													setPhotoUploaded(false);
													setValues({
														...values,
														officeContactPhotoId: null,
														officeContactPhoto: null
													})
												}}
												onCroppedImage={(cropData) => {
													setCropData(cropData);
													setFieldValue('officeContactPhoto', {
														...values.officeContactPhoto,
														cropData
													});
												}}
											/>
										</Col>
									)}
								</Row>
								<Row>
									<Col>
										<FormGroup>
											<Label for="description">{DESCRIPTION}</Label>
											<TinyMCE
												id="description"
												name="description"
												config={{
													height: 600,
													toolbar:
														'formatselect | bold italic strikethrough | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
													menu: {
														format: {
															title: 'Format',
															items:
																'bold italic underline strikethrough superscript subscript | formats blockformats fontformats fontsizes align | removeformat'
														}
													},
													image_advtab: false,
													paste_data_images: false,
													paste_as_text: true
													// paste_enable_default_filters: false,
													// paste_filter_drop: false
												}}
												plugins={
													'advlist autolink charmap directionality help importcss insertdatetime link lists paste print searchreplace textpattern visualblocks visualchars'
												}
												content={values.description}
												onContentChanged={(content) => {
													setFieldValue('description', content);
												}}
												onRawContentChanged={(content) => {
													setFieldValue('rawDescription', content);
												}}
											/>
											{errors.rawDescription && (
												<FormFeedback
													style={{ display: `${errors.rawDescription ? 'block' : 'none'}` }}
												>
													{errors.rawDescription}
												</FormFeedback>
											)}
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</ModalBody>
				</Modal>
			)}
		</Formik>
	);
};
