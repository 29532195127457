import React, { Component } from 'react';

export class HelpPage extends Component {
	constructor(props) {
		super(props);
		this.form = null;
	}

	submitForm = () => {
		const { login = '', password = '', redirectTo = '' } = this.props;
		var loginInput = document.createElement('input'); //prepare a new input DOM element
		loginInput.setAttribute('name', 'log'); //set the param name
		loginInput.setAttribute('value', login); //set the value
		loginInput.setAttribute('type', 'hidden'); //set the type, like "hidden" or other
		this.form.appendChild(loginInput);
		var passwordInput = document.createElement('input'); //prepare a new input DOM element
		passwordInput.setAttribute('name', 'pwd'); //set the param name
		passwordInput.setAttribute('value', password); //set the value
		passwordInput.setAttribute('type', 'hidden'); //set the type, like "hidden" or other
		this.form.appendChild(passwordInput);
		var redirectInput = document.createElement('input'); //prepare a new input DOM element
		redirectInput.setAttribute('name', 'redirect_to'); //set the param name
		redirectInput.setAttribute('value', redirectTo); //set the value
		redirectInput.setAttribute('type', 'hidden'); //set the type, like "hidden" or other
		this.form.appendChild(loginInput);
		this.form.appendChild(passwordInput);
		this.form.appendChild(redirectInput);
		this.form.submit();
		this.form.removeChild(redirectInput);
		this.form.removeChild(passwordInput);
		this.form.removeChild(loginInput);
	};
	render() {
		const { url = '' } = this.props;
		return (
			<form
				method="post"
				action={url}
				target="_blank"
				ref={(f) => {
					this.form = f;
				}}
			>
				<button type="button" className="btn btn-link" onClick={this.submitForm}>
					<i className="pe-7s-help1" style={{ fontSize: '42px' }} />
				</button>
			</form>
		);
	}
}
