import * as yup from "yup";
import { DefaultLanguage, LinkTargetType } from "../helpers/enums";

export const MenuElementValidationSchema = (otherLanguages) =>
  yup.object().shape({
    displayText: yup
      .string()
      .trim()
      .max(1000, "Nazwa może składać się maksymalnie z 1000 znaków")
      .required("Nazwa jest polem wymaganym"),
    title: yup
      .string()
      .trim()
      .nullable()
      .required(
        "Tekst pod czytniki dla osób słabowidzących jest polem wymaganym"
      ),
    url: yup
      .string()
      .url(
        "Adres URL musi zawierać prawidłowy format adresu. Musi składać się protokołu, adresu oraz domeny"
      )
      .nullable()
      .test(
        "url-required",
        "Istnieje rozbieżność pomiędzy adresem URL, a adresami URL w językach obcych (wymagane są wszystkie albo wcale)",
        function (val) {
          if (!val) {
            return otherLanguages
              .filter((el) => el.language?.isoCode !== DefaultLanguage.isoCode)
              .every((el) => {
                return !el.translatedUrl;
              });
          } else {
            return otherLanguages
              .filter((el) => el.language?.isoCode !== DefaultLanguage.isoCode)
              .every((el) => {
                return !!el.translatedUrl;
              });
          }
        }
      ),
    target: yup
      .number()
      .oneOf(
        [LinkTargetType.Self.value, LinkTargetType.NewTab.value],
        `Otwórz odnośnik w nowym oknie musi zawierać wartość: ${LinkTargetType.Self.name} lub ${LinkTargetType.NewTab.name}`
      ),
  });
