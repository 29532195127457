import uuid from "uuid/v4";

import { MenuType, LinkType, LinkTargetType } from "../../../helpers/enums";

export const createNewCategory = (parentElementId, isHorizontal) => ({
  type: isHorizontal ? MenuType.Horizontal : MenuType.Vertical,
  linkType: LinkType.Category.value,
  displayText: "",
  description: "",
  url: "",
  title: "",
  elementId: uuid(),
  parentElementId: parentElementId,
  previousElement: null,
  level: 0,
  children: [],
  expanded: false,
  target: LinkTargetType.Self.value,
  languages: [],
  canDelete: true,
  keepExpanded: false,
  hasExternalLinks: false,
  hasPublicArticles: false,
});
