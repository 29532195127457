import React from 'react';
import Grid from '../../../../components/Grid';

const columns = [
	{ name: 'id', title: 'id' },
	{ name: 'code', title: 'Kod ISO języka' },
	{ name: 'name', title: 'Nazwa języka' },
];
const columnExtensions = [
	{	columnName: 'code',	wordWrapEnabled: true	},
	{	columnName: 'name',	wordWrapEnabled: true	}
];
const hiddenColumns = [ 'id', 'isDefault' ];
const textColumns = [ 'name', 'code' ];

export const IsoCodeList = ({ ...props }) => (
	<Grid
		items={props.items}
		totalItems={props.totalItems}
		columns={columns}
		columnExtensions={columnExtensions}
		hiddenColumns={hiddenColumns}
		textColumns={textColumns}
		onReload={props.onReload}
		onRemove={props.onRemove}
		onAdd={props.onAdd}
		onRowClick={props.onRowClick}
		//internationalizable={props.internationalizable}
	/>
);
