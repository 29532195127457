import React from 'react';
import { InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { TableFilterRow } from '@devexpress/dx-react-grid-bootstrap4';

import { CONSTANTS } from '@constants';

const { SELECT, TEXT, AUCTION, WORKING, APPROVED, FOR_PUBLICATION, PUBLISHED, WITHDRAWN, ARCHIVE } = CONSTANTS;

export const ArticleTemplateFilterCell = ({ filter, onFilter }) => {
	return (
		<th>
			<InputGroup>
				<InputGroupAddon addonType="prepend">
					<button type="button" className="btn btn-outline-secondary">
						<svg className="d-block dx-g-bs4-filter-selector-icon" viewBox="0 0 32 32">
							<path d="M29.438 11.797v2.75h-26.922v-2.75h26.922zM29.438 17.406v2.75h-26.922v-2.75h26.922z" />
						</svg>
					</button>
				</InputGroupAddon>
				<Input
					type="select"
					className="form-control"
					onChange={(e) => onFilter(e.target.value ? { value: e.target.value, operation: 'equal' } : null)}
				>
					<option value="">{SELECT}</option>
					<option value="10">{TEXT}</option>
					<option value="20">{AUCTION}</option>
				</Input>
			</InputGroup>
		</th>
	);
};
export const ArticleStatusFilterCell = ({ filter, onFilter }) => {
	return (
		<th>
			{
				<InputGroup>
					<InputGroupAddon addonType="prepend">
						<button type="button" className="btn btn-outline-secondary">
							<svg className="d-block dx-g-bs4-filter-selector-icon" viewBox="0 0 32 32">
								<path d="M29.438 11.797v2.75h-26.922v-2.75h26.922zM29.438 17.406v2.75h-26.922v-2.75h26.922z" />
							</svg>
						</button>
					</InputGroupAddon>
					<Input
						type="select"
						className="form-control"
						onChange={(e) =>
							onFilter(e.target.value ? { value: e.target.value, operation: 'equal' } : null)}
					>
						<option value="">{SELECT}</option>
						<option value="10">{WORKING}</option>
						<option value="20">{APPROVED}</option>
						<option value="30">{FOR_PUBLICATION}</option>
						<option value="40">{PUBLISHED}</option>
						<option value="50">{WITHDRAWN}</option>
						<option value="60">{ARCHIVE}</option>
					</Input>
				</InputGroup>
			}
		</th>
	);
};

export const EmptyFilterCell = ({ filter, onFilter }) => <th />;

export const filterCell = (props) => {
	const { column = { name: '' }, filteringEnabled = false } = props;
	if ('type' === column.name && filteringEnabled) {
		return <ArticleTemplateFilterCell {...props} />;
	}
	// if ('baseArticleType' === column.name && filteringEnabled) {
	// 	return <BaseArticleTypeFilterCell {...props} />;
	// }
	if ([ 'status', 'version', 'languages', 'mainMenuElementDisplayText', 'baseArticleType' ].includes(column.name) && filteringEnabled) {
		return <EmptyFilterCell {...props} />;
	}
	return <TableFilterRow.Cell {...props} />;
};

export const systemFilterCell = (props) => {
	const { column = { name: '' }, filteringEnabled = false } = props;
	if ('type' === column.name && filteringEnabled) {
		return <ArticleTemplateFilterCell {...props} />;
	}
	// if ('baseArticleType' === column.name && filteringEnabled) {
	// 	return <SystemBaseArticleTypeFilterCell {...props} />;
	// }
	if ([ 'status', 'version', 'languages', 'mainMenuElementDisplayText', 'baseArticleType' ].includes(column.name) && filteringEnabled) {
		return <EmptyFilterCell {...props} />;
	}
	return <TableFilterRow.Cell {...props} />;
};
