import React, { Component } from 'react';
import { FormGroup, Label, Card, CardHeader, CardBody } from 'reactstrap';
import cx from 'classnames';

export class ToggleCheckboxCard extends Component {
	state = {
		isToggleOn: this.props.value
	};
	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({ isToggleOn: this.props.value });
		}
	}
	handleClick = () => {
		const { onChange } = this.props;

		this.setState({ isToggleOn: !this.state.isToggleOn }, () => {
			if (onChange && typeof onChange === 'function') {
				onChange('value.value', this.state.isToggleOn);
			}
		});
	};

	render() {
		const { title = '', labelValue = '' } = this.props;
		const { isToggleOn = false } = this.state;
		return (
			<Card className="mb-2">
				<CardHeader>
					<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
					{title}
				</CardHeader>
				<CardBody>
					<FormGroup className="d-flex align-items-center">
						<Label className="mr-2">{labelValue}</Label>
						<div
							className="switch has-switch mb-2 mr-2"
							data-on-label="Tak"
							data-off-label="Nie"
							onClick={this.handleClick}
						>
							<div
								className={cx('switch-animate', {
									'switch-on': isToggleOn,
									'switch-off': !isToggleOn
								})}
							>
								<input type="checkbox" />
								<span className="switch-left">Tak</span>
								<label>&nbsp;</label>
								<span className="switch-right">Nie</span>
							</div>
						</div>
					</FormGroup>
				</CardBody>
			</Card>
		);
	}
}
