import { ArticleSortOnCategoryType } from '../helpers/enums';

export const Menu = {
	menu: [],
	menuElementIds: [],
	searchedMenus: [],
	menuSortConfiguration: {
		articleSortOnCategoryType: ArticleSortOnCategoryType.PublishDateDescending.value,
		articles: []
	}
};
