import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { UserList } from './UserList';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, PageSize } from '../../../../helpers/enums';
import { UserActionCreators } from '../../../../actions/auth/user';
import { AuthActionCreators } from '../../../../actions/auth';
import { FilterActionCreators } from '../../../../actions/filter';

class UserListContainer extends Component {
	componentDidMount() {
		const { loadUsers, userFilters } = this.props;
		loadUsers(0, PageSize.Rows20, userFilters, []);
	}

	componentWillUnmount() {
		this.props.unloadAuth();
	}

	onAdd = () => {
		this.props.goTo(RouteUrls.Auth.user.create);
	};

	onRowClick = row => this.props.goTo(RouteUrls.Auth.user.editFunc(row.id));

	onReload = (page, size, filters, sortings) => {
		const { loadUsers } = this.props;
		loadUsers(page, size, filters, sortings);
	};

	onRemove = removeIds => this.props.deleteUsers(removeIds);

	clearFilters = () => {
		const { loadUsers, clearFilters } = this.props;
		clearFilters(['userFilters'], []);
		loadUsers(0, PageSize.Rows20, [], []);
	};

	render() {
		const { users, totalUsers, userListBlocking, isIntegratedWithASW, userFilters, changeFilters } = this.props;
		return (
			<Fragment>
				<PageTitle heading="Lista użytkowników" icon="pe-7s-users icon-gradient bg-tempting-azure" />

				<BlockUi tag="div" blocking={userListBlocking} loader={<Loader active type={LoaderType} />}>
					<UserList
						items={users}
						totalItems={totalUsers}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRemove={this.onRemove}
						onRowClick={this.onRowClick}
						addable={!isIntegratedWithASW}
						filters={userFilters}
						onChangeFilters={filters => changeFilters(['userFilters'], filters)}
						onClearFilters={this.clearFilters}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		userFilters: state.filter.userFilters || [],
		users: state.auth.users || [],
		totalUsers: state.auth.totalUsers || 0,
		userListBlocking: state.uiBlockState.userListBlocking || false,
		isIntegratedWithASW: state.application.isIntegratedWithASW || false
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators({ ...UserActionCreators, ...AuthActionCreators, ...FilterActionCreators }, dispatch),
		goTo: url => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);
