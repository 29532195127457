import { createAction } from '../base';

const CreateLanguage = createAction('CreateLanguage');
const GetLanguageAction = createAction('GetLanguageAction');
const LoadLanguagesAction = createAction('LoadLanguagesAction');
const RemoveLanguageAction = createAction('RemoveLanguageAction');
const UpdateLanguageAction = createAction('UpdateLanguageAction');
const UpdateLanguageStatusAction = createAction('UpdateLanguageStatusAction');
const LoadStandardElementsAction = createAction('LoadStandardElementsAction');
const UpdateStandardElementTranslationAction = createAction('UpdateStandardElementTranslationAction');
const LoadMenuElementsAction = createAction('LoadMenuElementsAction');
const UpdateCurrentLanguageState = createAction('UpdateCurrentLanguageState');

//languages
function createLanguage(language) {
	return {
		type: CreateLanguage.Request,
		payload: language
	};
}
function getLanguage(id) {
	return {
		type: GetLanguageAction.Request,
		payload: id
	};
}
function loadLanguages(page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: LoadLanguagesAction.Request,
		payload: { page, size, filters, sortings }
	};
}
function removeLanguage(ids = []) {
	return {
		type: RemoveLanguageAction.Request,
		payload: ids
	};
}
function updateLanguage(id, language) {
	return {
		type: UpdateLanguageAction.Request,
		payload: {
			id,
			language
		}
	};
}
function updateLanguageStatus(id, status, language) {
	return {
		type: UpdateLanguageStatusAction.Request,
		payload: {
			id,
			status,
			language
		}
	};
}
function loadStandardElements(languageId = 0, page = 0, size = 20, filters = [], sortings = [], actualLanguage = null) {
	return {
		type: LoadStandardElementsAction.Request,
		payload: { languageId, page, size, filters, sortings, actualLanguage }
	};
}
function updateStandardElementTranslation(id, elementTranslation) {
	return {
		type: UpdateStandardElementTranslationAction.Request,
		payload: {
			id,
			elementTranslation
		}
	};
}
function loadMenuElements(languageId = 0, page = 0, size = 20, filters = [], sortings = [], actualLanguage = null) {
	return {
		type: LoadMenuElementsAction.Request,
		payload: { languageId, page, size, filters, sortings, actualLanguage }
	};
}
function updateCurrentLanguageState(language) {
	return {
		type: UpdateCurrentLanguageState.Request,
		payload: language
	}
}

export const LanguageActions = {
	CreateLanguage,
	GetLanguageAction,
	LoadLanguagesAction,
	RemoveLanguageAction,
	UpdateLanguageAction,
	UpdateLanguageStatusAction,
	LoadStandardElementsAction,
	UpdateStandardElementTranslationAction,
	LoadMenuElementsAction,
	UpdateCurrentLanguageState
};

export const LanguageActionCreators = {
	createLanguage,
	getLanguage,
	loadLanguages,
	removeLanguage,
	updateLanguage,
	updateLanguageStatus,
	loadStandardElements,
	updateStandardElementTranslation,
	loadMenuElements,
	updateCurrentLanguageState
};
