import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';

import { SettingActions } from '../../actions/settings';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const BOOKMARK_URL = '/api/settings/bookmarks';

function* loadBookmarks(action) {
	try {
		let bookmarks = yield call(getListRequest, {
			url: BOOKMARK_URL,
			lang: action.payload.lang,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: SettingActions.LoadBookmarks.Success,
			payload: bookmarks
		});
	} catch (err) {
		yield put({
			type: SettingActions.LoadBookmarks.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy zakładek');
	}
}

function* getBookmark(action) {
	try {
		let bookmark = yield call(getBookmarkRequest, action.payload);

		yield put({
			type: SettingActions.GetBookmark.Success,
			payload: bookmark
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetBookmark.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych zakładki');
	}
}
function getBookmarkRequest(id) {
	return axios.get(`${BOOKMARK_URL}/${id}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateBookmark(action) {
	try {
		let bookmark = yield call(updateBookmarkRequest, action.payload);

		yield put({
			type: SettingActions.UpdateBookmark.Success,
			payload: bookmark
		});
		toast.success('Zakładka została zaktualizowana');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateBookmark.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji danych zakładki');
	}
}
function updateBookmarkRequest(bookmark) {
	return axios.put(`${BOOKMARK_URL}/${bookmark.id}`, bookmark).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* createBookmark(action) {
	try {
		let bookmark = yield call(createBookmarkRequest, action.payload);

		yield put({
			type: SettingActions.CreateBookmark.Success,
			payload: bookmark
		});
		yield put(replace(RouteUrls.Setting.bookmark.editFunc(bookmark.id)));
		toast.success('Zakładka została utworzona');
	} catch (err) {
		yield put({
			type: SettingActions.CreateBookmark.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas tworzenia zakładki');
	}
}
function createBookmarkRequest(bookmark) {
	return axios.post(BOOKMARK_URL, bookmark).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* deleteBookmarks(action) {
	try {
		yield call(deleteBookmarksRequest, action.payload);

		yield put({
			type: SettingActions.DeleteBookmarks.Success,
			payload: action.payload
		});
		toast.success('Zakładki zostały usunięte');
	} catch (err) {
		yield put({
			type: SettingActions.DeleteBookmarks.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie usuwania zakładek');
	}
}

function deleteBookmarksRequest(bookmarkIds) {
	const filterQuery = qs.stringify({ bookmarkIds: bookmarkIds }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${BOOKMARK_URL}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchLoadBookmarks() {
	yield takeEvery(SettingActions.LoadBookmarks.Request, loadBookmarks);
}
function* watchGetBookmark() {
	yield takeEvery(SettingActions.GetBookmark.Request, getBookmark);
}
function* watchCreateBookmark() {
	yield takeEvery(SettingActions.CreateBookmark.Request, createBookmark);
}
function* watchUpdateBookmark() {
	yield takeEvery(SettingActions.UpdateBookmark.Request, updateBookmark);
}
function* watchDeleteBookmarks() {
	yield takeEvery(SettingActions.DeleteBookmarks.Request, deleteBookmarks);
}

export const BookmarkSagas = [
	fork(watchLoadBookmarks),
	fork(watchGetBookmark),
	fork(watchUpdateBookmark),
	fork(watchCreateBookmark),
	fork(watchDeleteBookmarks)
];
