import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { RouteUrls } from "../../helpers/routeUrls";
import { getLayoutPermissions } from "../../reselect/permission";

import Roles from "./Roles";
import Users from "./Users";
import UserCard from "./Users/Card";

const Authorizations = ({ match }) => {
  const permissions = useSelector((state) => getLayoutPermissions(state));

  return (
    <Fragment>
      {permissions.accessToUsers || permissions.accessToRoles ? (
        <Switch>
          <Route path={RouteUrls.Auth.role.list} component={Roles} />
          <Route path={RouteUrls.Auth.user.list} component={Users} />
        </Switch>
      ) : (
        <Switch>
          <Route
            path={`${RouteUrls.Auth.user.edit}/:id`}
            component={UserCard}
          />
          <Redirect to={RouteUrls.App.main} />
        </Switch>
      )}
    </Fragment>
  );
};

export default Authorizations;
