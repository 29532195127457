import React, { Fragment, useState, useEffect } from 'react';
import { Button, ListGroupItem } from 'reactstrap';
import { AddLinkMultimediaModal } from '../../../../../components/Modals/AddLinkMultimediaModal';

export const MultimediaCardItem = ({
	key = '',
	editable = true,
	removeable = true,
	multimedia = { title: '' },
	maxFileSize = 10,
	onRemove = (index) => {},
	onChange = (index, multimedia) => {}
}) => {
	const [ isOpen, setIsOpen ] = useState(false);
	const [ currentMultimedia, setCurrentMultimedia ] = useState(multimedia);

	useEffect(() => {
		setCurrentMultimedia(multimedia);
	}, [multimedia]);

	const handleSaveLink = (multimedia) => {
		if (onChange && typeof onChange === 'function') {
			onChange(multimedia.index, multimedia);
		}
	};

	const handleRemoveLink = () => {
		if (onRemove && typeof onRemove === 'function') {
			onRemove(currentMultimedia.index);
		}
	};

	return (
		<Fragment>
			<ListGroupItem key={key}>
				<div className="widget-content p-0">
					<div className="widget-content-wrapper">
						<div
							title={currentMultimedia.type === 30 ? currentMultimedia.url : null}
							className="widget-content-left flex2"
						>
							{currentMultimedia.displayText}
						</div>
						<div className="widget-content-right">
							{editable && (
								<Button
									className="border-0 btn-transition"
									outline
									color="success"
									title="Edytuj link"
									onClick={(e) => {
										setIsOpen(!isOpen);
									}}
								>
									<i className="lnr-pencil" />
								</Button>
							)}
							{removeable && (
								<Button
									className="border-0 btn-transition"
									outline
									color="danger"
									title="Usuń link"
									onClick={(_) => handleRemoveLink()}
								>
									<i className="lnr-cross" />
								</Button>
							)}
						</div>
					</div>
				</div>
			</ListGroupItem>
			{isOpen && (
				<AddLinkMultimediaModal
					{...{
						isOpen,
						maxFileSize,
						multimedia: currentMultimedia,
						onToggle: (_) => setIsOpen(!isOpen),
						onSave: (multimedia) => handleSaveLink(multimedia)
					}}
				/>
			)}
		</Fragment>
	);
};
