import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Editor } from "@tinymce/tinymce-react";
import "tinymce";
import "tinymce/themes/silver";

import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/directionality";
// import 'tinymce/plugins/fullpage';
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/hr";
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/paste";
import "tinymce/plugins/preview";
import "tinymce/plugins/print";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/toc";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";

const ENTER_CODE = 13;

export class TinyMCE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.content || "",
    };
    this.fullConfig = {
      theme: "silver",
      height: 700,
      plugins:
        this.props.plugins ||
        "advlist anchor autolink charmap code codesample directionality fullscreen help hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable quickbars pagebreak paste print preview searchreplace table template textpattern toc visualblocks visualchars wordcount PastePreProcess",
      toolbar:
        this.props.toolbar ||
        "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
      image_advtab: true,
      paste_data_images: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({
        content: this.props.content,
      });
    }
  }

  render() {
    const {
      config,
      className,
      disabled,
      onContentChanged,
      onRawContentChanged,
      available_paste = true,
      multiline = true,
    } = this.props;
    const { content } = this.state;
    return (
      <Fragment>
        {disabled && (
          <div
            className={className}
            readOnly
            dangerouslySetInnerHTML={{ __html: this.props.content }}
          />
        )}
        {!disabled && (
          <Editor
            initialValue="<p></p>"
            init={{
              ...this.fullConfig,
              ...config,

              language: "pl",
              language_url: `${process.env.PUBLIC_URL}/langs/pl.js`,
              skin_url: `${process.env.PUBLIC_URL}/skins/ui/oxide`,
              theme_url: `${process.env.PUBLIC_URL}/themes/silver`,
              setup: function (editor) {
                !multiline &&
                  editor
                    .on("keydown", function (e) {
                      if (e.keyCode === ENTER_CODE) {
                        e.preventDefault();
                      }
                    })
                    .on("reset", function (e) {
                      e.preventDefault();
                      e.stopImmediatePropagation();
                    });
              },
            }}
            value={content}
            onPaste={(event) => {
              if (!available_paste) {
                event.preventDefault();
                event.stopImmediatePropagation();
              }
            }}
            onEditorChange={(content, editor) => {
              onContentChanged(content);
              onRawContentChanged(editor.getContent({ format: "text" }));
            }}
          />
        )}
      </Fragment>
    );
  }
}

TinyMCE.propTypes = {
  id: PropTypes.any,
  content: PropTypes.string,
  rawContent: PropTypes.string,
  config: PropTypes.object,
  plugins: PropTypes.string,
  toolbar: PropTypes.string,
  available_paste: PropTypes.bool,
  multiline: PropTypes.bool,
  onBlur: PropTypes.func,
  onContentChanged: PropTypes.func,
  onRawContentChanged: PropTypes.func,
};

TinyMCE.defaultProps = {
  id: undefined,
  content: "",
  rawContent: "",
  config: {
    height: 300,
    weight: 600,
  },
  disabled: false,
  available_paste: true,
  multiline: true,
  onBlur: Function.prototype,
  onContentChanged: Function.prototype,
  onRawContentChanged: Function.prototype,
};
