import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import RoleCard from "./Card";
import RoleList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const Users = ({ match }) => (
  <Fragment>
    <Switch>
      <Route path={RouteUrls.Auth.role.create} component={RoleCard} />
      <Route path={`${RouteUrls.Auth.role.edit}/:id`} component={RoleCard} />
      <Route path="*" component={RoleList} />
    </Switch>
  </Fragment>
);

export default Users;
