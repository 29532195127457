import * as yup from 'yup';
import { LinkTargetType } from '../helpers/enums';

export const BannerValidationSchema = yup.object().shape({
	name: yup.string().trim().required('Nazwa jest polem wymaganym'),
	displayText: yup.string().nullable(),
	title: yup.string().trim().required('Tekst pod czytniki dla osób słabowidzących jest polem wymaganym'),
	url: yup
		.string()
		.url('Adres URL musi zawierać prawidłowy format adresu. Musi składać się protokołu, adresu oraz domeny')
		.required('Adres URL jest wymagany'),
	target: yup
		.number()
		.oneOf(
			[ LinkTargetType.Self.value, LinkTargetType.NewTab.value ],
			`Otwórz zakładkę w nowym oknie musi zawierać wartość: ${LinkTargetType.Self.name} lub ${LinkTargetType
				.NewTab.name}`
		),
	isActive: yup.bool(),
	color: yup.string().trim().required('Kolor jest polem wymaganym'),
	file: yup.object().nullable()
});
