import React from 'react';
import { InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { TableFilterRow } from '@devexpress/dx-react-grid-bootstrap4';

import { CONSTANTS } from '@constants';

const { YES, NO, SELECT } = CONSTANTS;

const BoolFilterCell = ({ filter, onFilter, reload }) => {
	return (
		<th>
			<InputGroup>
				<InputGroupAddon addonType="prepend">
					<button type="button" className="btn btn-outline-secondary">
						<svg className="d-block dx-g-bs4-filter-selector-icon" viewBox="0 0 32 32">
							<path d="M29.438 11.797v2.75h-26.922v-2.75h26.922zM29.438 17.406v2.75h-26.922v-2.75h26.922z" />
						</svg>
					</button>
				</InputGroupAddon>
				<Input
					type="select"
					className="form-control"
					value={filter ? filter.value : ''}
					onChange={e => {
						onFilter(e.target.value ? { value: e.target.value, operation: 'equal' } : null);
						reload();
					}}
				>
					<option value="">{SELECT}</option>
					<option value="true">{YES}</option>
					<option value="false">{NO}</option>
				</Input>
			</InputGroup>
		</th>
	);
};
export const filterCell = (props, clicked) => {
	const { column = { name: '' }, filteringEnabled = false } = props;
	if ([ 'showOnMainPage', 'isActive' ].includes(column.name) && filteringEnabled) {
		return <BoolFilterCell {...props} reload={clicked} />;
	}
	return <TableFilterRow.Cell {...props} />;
};
