import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import isEqual from 'lodash.isequal';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { BannerList } from './BannerList';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, DefaultLanguage, PageSize } from '../../../../helpers/enums';
import { ApplicationActionCreators } from '../../../../actions/application';
import { SettingActionCreators } from '../../../../actions/settings';
import { FilterActionCreators } from '../../../../actions/filter';

class BannersList extends Component {
	componentDidMount() {
		const { activeLanguage, loadBanners, bannerFilters } = this.props;
		loadBanners(activeLanguage.isoCode, 0, PageSize.Rows20, bannerFilters, []);
	}

	componentDidUpdate(prevProps) {
		const { activeLanguage, loadBanners, bannerFilters } = this.props;
		if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
			loadBanners(activeLanguage.isoCode, 0, PageSize.Rows20, bannerFilters, []);
		}
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}

	onAdd = () => {
		this.props.goTo(RouteUrls.Setting.banners.create);
	};

	onRowClick = (row) => this.props.goTo(RouteUrls.Setting.banners.editFunc(row.id));

	onReload = (page, size, filters, sortings) => {
		const { activeLanguage, loadBanners, bannerFilters } = this.props;
		loadBanners(activeLanguage.isoCode, page, size, bannerFilters, sortings);
	};

	onRemove = (removeIds) => this.props.deleteBanners(removeIds);

	clearFilters = () => {
		const { activeLanguage, loadBanners, clearFilters } = this.props;
		clearFilters(['bannerFilters'], []);
		loadBanners(activeLanguage.isoCode, 0, PageSize.Rows20, [], []);
	};

	render() {
		const {
			banners,
			totalBanners,
			bannerListBlocking,
			activeLanguage,
			languages,
			changeApplicationLanguage,
			bannerFilters,
			changeFilters
		} = this.props;
		return (
			<Fragment>
				<PageTitle heading="Lista banerów" icon="pe-7s-settings icon-gradient bg-tempting-azure" />

				<BlockUi tag="div" blocking={bannerListBlocking} loader={<Loader active type={LoaderType} />}>
					<BannerList
						items={banners}
						totalItems={totalBanners}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRemove={this.onRemove}
						onRowClick={this.onRowClick}
						internationalizable={true}
						language={activeLanguage}
						languages={languages}
						onChangeLanguage={changeApplicationLanguage}
						filters={bannerFilters}
						onChangeFilters={(filters) => changeFilters(['bannerFilters'], filters)}
						onClearFilters={this.clearFilters}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		bannerFilters: state.filter.bannerFilters || [],
		banners: state.setting.banners || [],
		totalBanners: state.setting.totalBanners || 0,
		bannerListBlocking: state.uiBlockState.bannerListBlocking || false,
		activeLanguage: state.application.activeLanguage,
		languages: state.application.languages || [ DefaultLanguage ] //getActiveLanguagesSelector(state) || [ DefaultLanguage ]
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators(
			{ ...SettingActionCreators, ...ApplicationActionCreators, ...FilterActionCreators },
			dispatch
		),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BannersList);
