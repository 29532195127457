import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, Form } from "reactstrap";
import { Formik } from "formik";

import { SettingActionCreators } from "../../../../actions/settings";
import { ParameterEditorChooser } from "./ParameterEditorChooser";
import { DefaultFormikConfig } from "../../../../helpers/enums";

class EditParameterModal extends Component {
  componentDidMount() {
    const { getSystemParameter, parameterId } = this.props;
    if (parameterId !== null) {
      getSystemParameter(parameterId);
    }
  }
  componentDidUpdate(prevProps) {
    const { getSystemParameter, parameterId } = this.props;
    if (prevProps.parameterId !== parameterId && parameterId !== null) {
      getSystemParameter(parameterId);
    }
  }

  componentWillUnmount() {
    this.props.unloadSetting();
  }
  onSave = (parameter) => {
    const { onToggle, updateSystemParameter } = this.props;

    if (updateSystemParameter && typeof updateSystemParameter === "function") {
      updateSystemParameter(parameter);
    }

    if (onToggle && typeof onToggle === "function") {
      onToggle();
    }
  };

  render() {
    const { systemParameter, isOpen = false, onToggle = () => {} } = this.props;
    return (
      <Formik
        {...DefaultFormikConfig}
        initialValues={systemParameter}
        onSubmit={this.onSave}
      >
        {({
          values,
          dirty,
          isValid,
          setFieldValue,
          handleSubmit,
          handleReset,
        }) => {
          return (
            <Modal isOpen={isOpen} backdrop size="xl">
              <ModalHeader>
                <div className="d-flex justify-content-between">
                  <span>Ustawienia parametru</span>
                  <div className="d-flex">
                    <Button
                      className="btn-icon mr-2"
                      color="primary"
                      type="submit"
                      form="edit-parameter"
                      // disabled={!dirty}
                    >
                      <i className="pe-7s-diskette btn-icon-wrapper" />
                      Zapisz
                    </Button>
                    <Button
                      className="btn-icon mr-2"
                      color="secondary"
                      type="button"
                      onClick={onToggle}
                    >
                      <i className="pe-7s-back btn-icon-wrapper" />
                      Anuluj
                    </Button>
                  </div>
                </div>
              </ModalHeader>
              <ModalBody>
                <Form
                  id="edit-parameter"
                  noValidate
                  method="post"
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                >
                  <ParameterEditorChooser
                    editorType={systemParameter.editorType}
                    value={values.value}
                    displayName={systemParameter.displayName}
                    labelValue={systemParameter.labelValue}
                    onChange={setFieldValue}
                    dirty={dirty}
                  />
                </Form>
              </ModalBody>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    systemParameter: state.setting.systemParameter || [],
    systemParameterCardBlocking:
      state.uiBlockState.systemParameterCardBlocking || false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(SettingActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EditParameterModal);
