export const CONSTANTS = {
  SAVE: "Zapisz",
  CANCEL: "Anuluj",
  CLOSE: "Zamknij",
  BACK: "Wróć",
  CONFIRM: "Zatwierdź",
  VERSION: "Wersja",
  NEW_VERSION: "Nowa wersja",
  PREVIEW: "Podgląd",
  ARTICLE: "Artykuł",
  COMMON_INFORMATION: "Dane ogólne",
  CONTACT_INFORMATION: "Dane adresowe",
  SPECIFIC_INFOMATION: "Dane szczegółowe",
  NAME_ENTITY: "Nazwa jednostki/urzędu",
  INFORMATION: "Informacje",
  SELECT_PIECE_TEXT_AND_FORMAT:
    "Zaznacz fragment tekstu do sformatowania i wybierz jedną lub wiele opcji:",
  BOLD: "pogrubienie",
  ITALICS: "kursywa",
  STRESS: "podkreślenie",
  CROSS: "przekreślenie",
  LOADING: "Ładowanie danych",
  CODE: "Kod",
  NAME: "Nazwa",
  LOCAL_NAME: "Nazwa lokalna",
  URL: "Adres URL",
  TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS:
    "Tekst pod czytniki dla osób słabowidzących",
  OPEN_BANNER_IN_NEW_WINDOW: "Otwórz baner w nowym oknie",
  OPEN_BOOKMARK_IN_NEW_WINDOW: "Otwórz zakładkę w nowym oknie",
  YES: "Tak",
  NO: "Nie",
  DELETE: "Usuń",
  RETURN: "Przywróć",
  ACTIVE: "Aktywny",
  ACTIVE_VER: "Aktywna",
  COLOR: "Kolor",
  WCAG_CONTRAST_REQUIRED:
    "Kontrast musi być większy, aby być zgodny z WCAG 2.1",
  DISPLAY_NAME: "Nazwa wyświetlana",
  SELECT: "Wybierz...",
  SHOW_IN_MAIN_PAGE: "Pokaż na stronie głównej",
  CONTENT: "Treść",
  EXTRA_CONTENT: "Dodatkowa treść",
  EDIT_FOOTER_ELEMENT: "Edycja elementu stopki",
  SHOW_IN_FOOTER: "Pokaż w stopce",
  SOURCES: "Źródła",
  BIP_NAME: "Nazwa BIP",
  OFFICE_NAME: "Nazwa jednostki",
  OPEN_TILE_IN_NEW_WINDOW: "Otwórz kafel w nowym oknie",
  ICON_NAME: "Nazwa ikony",
  CHANNEL_DESCRIPTION: "Opis kanału",
  OPEN_CHANNEL_IN_NEW_WINDOW: "Otwórz kanał w nowym oknie",
  TITLE_ARTICLES: "Nazwa artykułu",
  TITLE_FROM_FOOTER_ARTICLES: "Nazwa w stopce",
  UPDATE_TITLE_IN_FOOTER:
    "Aktualizuj nazwę artykułu w stopce podczas publikacji",
  SHORT_DESCRIPTION_ARTICLE: "Skrócony opis artykułu",
  SHORT_INFORMATION: "wymagany do kanałów RSS, aktualności oraz wyszukiwania",
  SHEET_ARTICLE: "Szablon artykułu",
  GET_DATA_FROM_ALL_SOURCES: "Pobieraj dane ze wszystkich źródeł",
  ATTACHMENT: "Załączniki",
  SOURCE_INFORMATION: "Źródło informacji",
  PROVIDER: "Podmiot udostępniający",
  CHOOSED_PROVIDER: "Wybierz podmiot udostępniający",
  NEW_PROVIDER: "Nowy podmiot udostępniający:",
  EMPTY_DATA: "Brak danych",
  AUTHOR: "Autor informacji",
  E_MAIL: "E-mail",
  DATA_CREATED_INFORMATION: "Data powstania informacji źródłowej",
  PUBLICATION: "Publikacja",
  DATA_OF_PUBLICATION_FROM: "Data publikacji od",
  DATA_FROM_PUBLICATION_TO: "Data publikacji do",
  ARCHIVE_FROM_DATA: "Archiwizuj po dacie publikacji",
  DETAILS: "Szczegóły",
  DATE_CREATED: "Data utworzenia",
  DATE_ACCEPTED: "Data zatwierdzenia",
  DATE_PUBLISHED: "Data opublikowania",
  DATE_OF_WITHDRAWL: "Data wycofania",
  DATE_OF_ARCHIVE: "Data zarchiwizowania",
  ACTIVE_LANGUAGE: "Aktywuj język",
  DEACTIVE_LANGUAGE: "Dezaktywuj język",
  STATUS: "Status",
  ISO_CODE_SHOULD_CONTAINS_EXCEPT:
    "Kod ISO powinien składać się z 2 znaków, z wyjątkiem dla:",
  REGIONAL_LANGUAGE: "Języki regionalne",
  ENGLAND: "Anglia - GB-ENG",
  NORTHERN_IRELAND: "Irlandia Północna - GB-NIR",
  SCOTLAND: "Szkocja - GB-SCT",
  WALES: "Walia - GB-WLS",
  FLAG: "Flaga",
  DATE_FORMAT: "Format daty",
  FIXED_ELEMENTS: "Elementy stałe",
  TRANSLATIONS: "Tłumaczenia",
  MENU_ELEMENTS: "Elementy menu",
  LANGUAGE_TRANSLATION_DANGER:
    "Nie można aktywować języka, jeśli nie przetłumaczono wszystkich elementów.",
  LANGUAGE_TRANSLATION_WARNING:
    "Istnieją elementy, które wymagają przetłumaczenia",
  LANGUAGE_TRANSLATION_INFO: "Tylko zwalidowane wiersze zostaną zapisane",
  BUTTON: "Przycisk",
  LINK: "Link",
  LABEL: "Etykieta",
  MAX_NUMBER_OF_ELEMENTS_ON_FIRST_LEVEL:
    "Maksymalna liczba elementów na pierwszym poziomie",
  HORIZONTAL_MENU_CONFIGURATION: "Ustawienie menu poziomego",
  VERTICAL_MENU_CONFIGURATION: "Ustawienie menu pionowego",
  MAX_NUMBER_OF_COUNTERSINK_LEVELS: "Maksymalna liczba poziomów zagłębienia",
  ISO_CODE: "Kod ISO",
  MAX_NUMBER_OF_ELEMENTS: "Maksymalna liczba elementów na każdym poziomie",
  ROLE_DESCRIPTION: "Opis roli",
  PERMISSION_LIST: "Lista uprawnień",
  ROLE_NAME: "Nazwa roli",
  ACTIVATE_ACCOUNT: "Aktywuj konto",
  DEACTIVATE_ACCOUNT: "Dezaktywuj konto",
  RESET_PASSWORD: "Resetuj hasło",
  LOGIN: "Login",
  FIRST_NAME: "Imię",
  SURNAME: "Nazwisko",
  IDENTIFICATION_METHOD: "Sposób identyfikacji",
  BIRTHDAY_DATE: "Data urodzenia",
  PESEL: "PESEL",
  PESEL_ID: "Identyfikator (PESEL / Nr i seria dokumentu)",
  CONTACT_DETAILS: "Dane kontaktowe",
  TELEPHONE: "Telefon",
  PHONE: "Telefon komórkowy",
  EMAIL: "E-mail",
  ROLES_LIST: "Lista ról",
  CATEGORY_PERMISSION: "Uprawnienia do kategorii",
  HORIZONTAL_MENU: "Menu poziomowe",
  VERTICAL_MENU: "Menu pionowe",
  TEXT: "Tekst",
  AUCTION: "Przetarg",
  WORKING: "Roboczy",
  APPROVED: "Zatwierdzony",
  FOR_PUBLICATION: "Do publikacji",
  PUBLISHED: "Opublikowany",
  WITHDRAWN: "Wycofany",
  ARCHIVE: "Zarchiwizowany",
  ADD_CATEGORY: "Dodaj kategorię",
  ADD_SUBCATEGORY: "Dodaj podkategorię",
  EDIT_CATEGORY: "Edycja kategorii",
  OPEN_DESCRIPTION_IN_NEW_WINDOW: "Otwórz odnośnik w nowym oknie",
  DESCRIPTION: "Opis",
  TRANSLATION: "Tłumaczenie",
  SUMMARY_VALIDATION_ERRORS: "Podsumowanie błędów walidacji",
  MENU_CONFIGURATION: "Ustawienie menu",
  CREATE_CATEGORY: "Utwórz kategorię",
  CATEGORY_NEED_NAME: "Kategoria musi posiadać nazwę",
  CATEGORY_TOO_LONG_NAME: "Nazwa kategorii nie może przekraczać 1000 znaków",
  ADD_CATEGORY_UP: "Dodaj kategorię powyżej",
  ADD_CATEGORY_DOWN: "Dodaj kategorię poniżej",
  ARTICLE_LIST: "Lista artykułów",
  DATA_LOADING: "Ładowanie danych...",
  SORT_CONFIG_MENU_ELEMENTS: "Konfiguracja sortowania artykułów w kategorii",
  ARTICLE_SORT_ON_CATEGORY_TYPE: "Sposób sortowania artykułów",
  KIND_OF_ARTICLE: "Rodzaj artykułu",
  EDIT_OFFICE_CONTACT: "Edycja elementu",
  FULL_NAME: "Imię i nazwisko",
  JOB_POSITION: "Stanowisko",
  SITENAME: "Nazwa strony",
  SITENAME_FILE: "Plik z mapą strony BIP-a archiwalnego",
  SITEMAP_FILE_LOADED: "Aktualny BIP został połączony z BIP-em archiwalnym",
  CONNECT_WITH_ARCHIVE_BIP: "Połącz z archiwalnym BIPv1",
  REMOVE_SITE: "Usuń stronę",
  OPERATIONS: "Czynności",
  LINKS: "Odnośniki",
  CHANGE_LOG: "Historia zmian",
  MULTIMEDIA: "Multimedia",
  COUNTRY: "Kraj",
  PROVINCE: "Województwo",
  DISTRICT: "Powiat",
  COMMUNE: "Gmina",
  CITY: "Miejscowość",
  ZIP_CODE: "Kod pocztowy",
  ZIP_CODE_CITY: "Poczta",
  STREET: "Ulica",
  STREET_NUMBER: "Nr budynku",
  FLAT_NUMBER: "Nr lokalu",
  FAX: "Fax",
  NIP: "NIP",
  REGON: "REGON",
  OFFICE_BOX_EPUAP: "Adres skrytki ePUAP",
  RECEIVING_DOCUMENT_PLACE: "Miejsce składania dokumentów",
  WEB_SITE_WWW: "Strona WWW",
  JST_CODE_TERYT: "Identyfikator jednostki TERYT",
  ADMISSION_HOURS_INFORMATION: "Godziny urzędowania",
  BANK_ACCOUNT_NUMBER: "Numer konta bankowego",
  PEPPOL_NUMBER: "Numer PEPPOL",
  COMPATIBILITY_WITH_APP_COLOR: "Zgodny z wersją kolorystyczną BIP",
  GROUP_NAME: "Nazwa grupy",
  FILE_EXTENSIONS: "Rozszerzenia plikowe",
  ADD_EXTRA_CONTENT: "Dodaj dodatkową treść",
  EDIT_EXTRA_CONTENT: "Edytuj dodatkową treść",
  DISABLE_ADD_CATEGORY_MESSAGE:
    "Dodawanie dostępne jest tylko w języku polskim",
  DISABLE_DELETE_CATEGORY_MESSAGE:
    "Usuwanie dostępne jest tylko w języku polskim",
  DISABLE_SORT_CATEGORY_MESSAGE:
    "Sortowanie artykułów dostępne jest tylko w języku polskim",
  CATEGORY_URL_VLAIDATION_MESSAGE:
    "Kategoria posiada adres url i nie można w niej umieszczać kategorii",
  IS_NOT_PERMISSION_MESSAGE: "Brak uprawnień",
  CATEGORY_OR_SUBCATEGORY_HAS_URL:
    "Kategoria lub podkategoria posiada przypisany adres URL",
};
