import React from 'react'
import map from 'lodash.map';
import filter from 'lodash.filter';
import Flag from 'react-flagkit';
import { AVAILABLE_ISO_CODE } from '../../../../../helpers/isoCode';

export const TranslationFlagButtons = (values, languages, changeApplicationLanguage) => (
    <div className="header-dots ml-0">
        {map(
            filter(languages, language => language.isoCode !== values.language.isoCode),
            (language, langIndex) => (
                <div
                    key={`lang-ix-${langIndex}`}
                    className="p-0 mr-2"
                    onClick={(_) => changeApplicationLanguage(language)}
                >
                    <div className="icon-wrapper icon-wrapper-alt rounded-circle mr-2">
                        <div className="icon-wrapper-bg bg-focus" />
                        <div className="language-icon">
                        {
                            AVAILABLE_ISO_CODE.includes(language.isoCode) && <Flag
                                className="mr-3 opacity-8" country={language.isoCode} size="40"
                            />
                        }
                        {
                            !AVAILABLE_ISO_CODE.includes(language.isoCode) &&
                            language.isoCode !== '' && language.isoCodeFile !== null &&
                            <img
                                className={`mr-2 opacity-8`}
                                src={language.isoCodeFile.path}
                                alt={`Grafika ${language.isoCodeFile.fileName}`}
                            />
                        }
                        </div>
                    </div>
                </div>
            )
        )}
    </div>
);