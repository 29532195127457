import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import Immutable from 'seamless-immutable';

import { Formik } from 'formik';
import { Form, FormGroup, FormFeedback, Row, Col, Card, CardBody, CardHeader, Label, Input, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import Flag from 'react-flagkit';
import Sticky from 'react-stickynode';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { DictionaryActionCreators } from '../../../../actions/dictionary';
import { DictionaryEntryValidationSchema } from '../../../../validators';
import { DefaultFormikConfig, LoaderType } from '../../../../helpers/enums';
import { AVAILABLE_ISO_CODE } from '../../../../helpers/isoCode';

import { CONSTANTS } from '@constants';

const { SAVE, BACK, NAME, COMMON_INFORMATION } = CONSTANTS;

class DictionaryEntryCard extends Component {
	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getDictionaryEntry(id);
		}
	}

	componentDidUpdate(prevProps) {
		const { id: prevId } = prevProps.match.params;
		const { id } = this.props.match.params;
		if (prevId && id && prevId !== id) {
			this.props.getDictionaryEntry(id);
		}
	}
	componentWillUnmount() {
		this.props.unloadDictionary();
	}

	render() {
		const {
			dictionaryEntry,
			activeLanguage,
			dictionaryEntryCardBlocking,
			createDictionaryEntry,
			updateDictionaryEntry,
			cancel
		} = this.props;
		const newDictionaryEntry =
			dictionaryEntry && dictionaryEntry.id > 0
				? Immutable(dictionaryEntry).asMutable({ deep: true })
				: {
						id: 0,
						name: '',
						languageId: activeLanguage.id,
						languageIsoCode: activeLanguage.isoCode,
						languageIsoCodeFile: activeLanguage.isoCodeFile
					};

		return (
			<Fragment>
				<PageTitle heading="Podmiot udostępniający" icon="pe-7s-settings icon-gradient bg-tempting-azure" />
				<BlockUi tag="div" blocking={dictionaryEntryCardBlocking} loader={<Loader active type={LoaderType} />}>
					<Formik
						{...DefaultFormikConfig}
						initialValues={newDictionaryEntry}
						validationSchema={DictionaryEntryValidationSchema}
						onSubmit={(values) => {
							if (values.id > 0) {
								updateDictionaryEntry(values);
							} else {
								createDictionaryEntry(values);
							}
						}}
						onReset={cancel}
					>
						{({ errors, values, handleChange, handleSubmit, handleReset }) => (
							<Form onSubmit={handleSubmit} onReset={handleReset}>
								<Sticky
									top=".app-header"
									innerZ="101"
									activeClass="sticky-active-class"
									className="mb-2"
								>
									<div className="d-flex justify-content-end">
										<Button className="btn-icon mr-2" color="primary" type="submit">
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{SAVE}
										</Button>
										<Button className="btn-icon mr-2" color="secondary" type="reset">
											<i className="pe-7s-back btn-icon-wrapper" />
											{BACK}
										</Button>
									</div>
								</Sticky>
								<Card className="mb-2">
									<CardHeader>
										<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
										<span className="mr-2">{COMMON_INFORMATION}</span>
										{AVAILABLE_ISO_CODE.includes(values.languageIsoCode) && (
											<Flag
												country={
													values.languageIsoCode !== '' ? values.languageIsoCode : '_unknown'
												}
											/>
										)}
										{!AVAILABLE_ISO_CODE.includes(values.languageIsoCode) &&
										values.languageIsoCode !== '' &&
										values.languageIsoCodeFile !== null && (
											<img
												className={`mr-2 opacity-8`}
												src={values.languageIsoCodeFile.path}
												alt={`Grafika ${values.languageIsoCodeFile.fileName}`}
											/>
										)}
									</CardHeader>
									<CardBody>
										<Row>
											<Col>
												<FormGroup>
													<Label for="name">{NAME}</Label>
													<Input
														type="text"
														id="name"
														name="name"
														placeholder="Wpisz nazwę"
														value={values.name}
														onChange={handleChange}
														invalid={!!errors.name}
													/>
													<FormFeedback>{errors.name}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Form>
						)}
					</Formik>
				</BlockUi>
			</Fragment>
		);
	}
}

const mapStateToProps = (state, ownProp) => {
	return {
		dictionaryEntry: state.dictionary.dictionaryEntry.dictionaryEntry || {
			id: 0,
			name: '',
			languageId: 0,
			languageIsoCode: ''
		},
		activeLanguage: state.application.activeLanguage,
		dictionaryEntryCardBlocking: state.uiBlockState.dictionaryEntryCardBlocking || false
	};
};

const mapDispatchToProps = (dispatch, ownProp) => {
	return {
		...bindActionCreators(DictionaryActionCreators, dispatch),
		cancel: () => dispatch(goBack())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEntryCard);
