import { createAction } from '../base';

export const CreateTagAction = createAction('CreateTagAction');
export const GetTagAction = createAction('GetTagAction');
export const GetAllTagsAction = createAction('GetAllTagsAction');
export const UpdateTagAction = createAction('UpdateTagAction');
export const RemoveTagsAction = createAction('RemoveTagsAction');

function createTag(tag, changeUrl = true, withNotification = true) {
	return {
		type: CreateTagAction.Request,
		payload: { tag, changeUrl, withNotification }
	};
}
function getTag(id) {
	return {
		type: GetTagAction.Request,
		payload: id
	};
}

function getAllTags(lang = '', page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: GetAllTagsAction.Request,
		payload: { lang, page, size, filters, sortings }
	};
}

function updateTag(tag) {
	return {
		type: UpdateTagAction.Request,
		payload: tag
	};
}

function removeTags(ids = []) {
	return {
		type: RemoveTagsAction.Request,
		payload: ids
	};
}

export const TagActionCreators = {
	createTag,
	getTag,
	getAllTags,
	updateTag,
	removeTags
};

export const TagActions = {
	CreateTagAction,
	GetTagAction,
	GetAllTagsAction,
	UpdateTagAction,
	RemoveTagsAction
};
