import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import isEqual from 'lodash.isequal';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { RssChannelList } from './RssChannelList';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, DefaultLanguage, PageSize } from '../../../../helpers/enums';
import { ApplicationActionCreators } from '../../../../actions/application';
import { SettingActionCreators } from '../../../../actions/settings';
import { FilterActionCreators } from '../../../../actions/filter';

class RssChannelsList extends Component {
	componentDidMount() {
		const { activeLanguage, loadRssChannels, rssChannelFilters } = this.props;
		loadRssChannels(activeLanguage.isoCode, 0, PageSize.Rows20, rssChannelFilters, []);
	}
	componentDidUpdate(prevProps) {
		const { activeLanguage, loadRssChannels, rssChannelFilters } = this.props;
		if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
			loadRssChannels(activeLanguage.isoCode, 0, PageSize.Rows20, rssChannelFilters, []);
		}
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}
	onAdd = () => {
		this.props.goTo(RouteUrls.Setting.rssChannels.create);
	};

	onRowClick = (row) => this.props.goTo(RouteUrls.Setting.rssChannels.editFunc(row.id));

	onReload = (page, size, filters, sortings) => {
		const { activeLanguage, loadRssChannels, rssChannelFilters } = this.props;
		loadRssChannels(activeLanguage.isoCode, page, size, rssChannelFilters, sortings);
	};

	onRemove = (removeIds) => this.props.deleteRssChannels(removeIds);

	clearFilters = () => {
		const { activeLanguage, loadRssChannels, clearFilters } = this.props;
		clearFilters(['rssChannelFilters'], []);
		loadRssChannels(activeLanguage.isoCode, 0, PageSize.Rows20, [], []);
	};

	render() {
		const {
			rssChannels,
			totalRssChannels,
			rssChannelListBlocking,
			activeLanguage,
			languages,
			changeApplicationLanguage,
			rssChannelFilters,
			changeFilters
		} = this.props;
		return (
			<Fragment>
				<PageTitle heading="Kanały RSS" icon="ion-logo-rss icon-gradient bg-tempting-azure" />

				<BlockUi tag="div" blocking={rssChannelListBlocking} loader={<Loader active type={LoaderType} />}>
					<RssChannelList
						items={rssChannels}
						totalItems={totalRssChannels}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRemove={this.onRemove}
						onRowClick={this.onRowClick}
						internationalizable={true}
						language={activeLanguage}
						languages={languages}
						onChangeLanguage={changeApplicationLanguage}
						filters={rssChannelFilters}
						onChangeFilters={(filters) => changeFilters(['rssChannelFilters'], filters)}
						onClearFilters={this.clearFilters}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		rssChannelFilters: state.filter.rssChannelFilters || [],
		rssChannels: state.setting.rssChannels || [],
		totalRssChannels: state.setting.totalRssChannels || 0,
		rssChannelListBlocking: state.uiBlockState.rssChannelListBlocking || false,
		activeLanguage: state.application.activeLanguage,
		languages: state.application.languages || [ DefaultLanguage ] //getActiveLanguagesSelector(state) || [ DefaultLanguage ]
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators(
			{ ...SettingActionCreators, ...ApplicationActionCreators, ...FilterActionCreators },
			dispatch
		),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RssChannelsList);
