import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";

import {
  format as dateFnsFormat,
  startOfDay,
  endOfDay,
  isValid as dateFnsIsValid,
  isToday,
} from "date-fns";
import { pl } from "date-fns/locale";
import map from "lodash.map";
import find from "lodash.find";
import filter from "lodash.filter";
import isEmpty from "lodash.isempty";

import { ArticleStatus } from "../../../../../helpers/enums";
import { PermissionName } from "../../../../../helpers/permissionNames";
import { CONSTANTS } from "@constants";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "./../utils";
import { TimeInput } from "../../../../../components/DatepickerTimeInput";

const {
  PROVIDER,
  NEW_PROVIDER,
  CHOOSED_PROVIDER,
  EMPTY_DATA,
  E_MAIL,
  DATA_CREATED_INFORMATION,
  SOURCE_INFORMATION,
  AUTHOR,
} = CONSTANTS;

export const SourceInformationCard = ({
  activeLanguage = {},
  userPermissions = [],
  providingEntities = [],
  isArticleEditable = false,
  createDictionaryEntry = Function.prototype,

  isValid = false,
  dirty = false,
  values = {},
  errors = {},
  handleChange = Function.prototype,
  setFieldValue = Function.prototype,
}) => {
  const {
    status,
    baseArticleProvidingEntity = "",
    baseArticleAuthorName = "",
    baseArticleAuthorEmail = "",
    baseArticleInformationCreated = null,
  } = values;

  const {
    baseArticleProvidingEntity: eBaseArticleProvidingEntity,
    baseArticleAuthorName: eBaseArticleAuthorName,
    baseArticleAuthorEmail: eBaseArticleAuthorEmail,
    baseArticleInformationCreated: eBaseArticleInformationCreated,
  } = errors;

  const setAvailableProvidingEntities = (selected = "") =>
    map(
      filter(
        providingEntities,
        (providingEntity) => providingEntity.name !== selected
      ),
      (providingEntity) => ({
        name: providingEntity.name,
      })
    );

  const checkCanAddNewProvidingEntity = (newOptions, { text, selected }) => {
    const isNewOptionsEmpty = isEmpty(newOptions);
    const isDuplicate = !isEmpty(filter(selected, { name: text }));

    return isNewOptionsEmpty && !isDuplicate;
  };

  const handleChangeProvidingEntity = (setFieldValue, providingEntities) => {
    if (setFieldValue && typeof setFieldValue === "function") {
      if (!isEmpty(providingEntities) && providingEntities.length === 1) {
        setFieldValue("baseArticleProvidingEntity", providingEntities[0].name);
      } else {
        setFieldValue("baseArticleProvidingEntity", "");
      }
    }

    if (createDictionaryEntry && typeof createDictionaryEntry === "function") {
      const newProvidingEntity = find(
        providingEntities,
        (providingEntity) => providingEntity.customOption
      );
      if (newProvidingEntity) {
        createDictionaryEntry(
          {
            ...newProvidingEntity,
            id: 0,
            languageId: activeLanguage.id,
            languageIsoCode: activeLanguage.isoCode,
          },
          false,
          false
        );
      }
    }
  };

  const articleOnConfirmedStatus =
    status >= ArticleStatus.Confirmed.value ||
    !isArticleEditable ||
    !userPermissions.includes(PermissionName.CreateArticles);

  return (
    <Card className="mb-2">
      <CardHeader>
        <i className="header-icon pe-7s-info icon-gradient bg-malibu-beach" />
        {SOURCE_INFORMATION}
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <FormGroup>
              <Label for="baseArticleProvidingEntity">{PROVIDER}</Label>
              <Typeahead
                id="baseArticleProvidingEntity"
                labelKey="name"
                options={setAvailableProvidingEntities(
                  baseArticleProvidingEntity
                )}
                selected={
                  baseArticleProvidingEntity
                    ? [
                        {
                          name: baseArticleProvidingEntity,
                        },
                      ]
                    : []
                }
                placeholder={CHOOSED_PROVIDER}
                newSelectionPrefix={NEW_PROVIDER}
                emptyLabel={EMPTY_DATA}
                clearButton
                multiple={false}
                allowNew={checkCanAddNewProvidingEntity}
                onChange={handleChangeProvidingEntity.bind(this, setFieldValue)}
                isInvalid={
                  !!eBaseArticleProvidingEntity && !baseArticleProvidingEntity
                }
                disabled={articleOnConfirmedStatus}
              />
              {eBaseArticleProvidingEntity && !baseArticleProvidingEntity ? (
                <FormFeedback style={{ display: "block" }}>
                  {eBaseArticleProvidingEntity}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="baseArticleAuthorName">{AUTHOR}</Label>
              <Input
                type="text"
                id="baseArticleAuthorName"
                name="baseArticleAuthorName"
                value={baseArticleAuthorName}
                onChange={handleChange}
                invalid={!!eBaseArticleAuthorName && !baseArticleAuthorName}
                disabled={articleOnConfirmedStatus}
              />
              {eBaseArticleAuthorName && !baseArticleAuthorName ? (
                <FormFeedback>{eBaseArticleAuthorName}</FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="baseArticleAuthorEmail">{E_MAIL}</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                <Input
                  type="text"
                  id="baseArticleAuthorEmail"
                  name="baseArticleAuthorEmail"
                  value={baseArticleAuthorEmail}
                  onChange={handleChange}
                  invalid={!!eBaseArticleAuthorEmail && !baseArticleAuthorEmail}
                  disabled={articleOnConfirmedStatus}
                />
                {eBaseArticleAuthorEmail && !baseArticleAuthorEmail ? (
                  <FormFeedback>{eBaseArticleAuthorEmail}</FormFeedback>
                ) : null}
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="baseArticleInformationCreated">
                {DATA_CREATED_INFORMATION}
              </Label>
              <div className="d-flex">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                      <i className="pe-7s-date" />
                    </div>
                  </InputGroupAddon>
                  <DatePicker
                    className={`${
                      !!eBaseArticleInformationCreated &&
                      !baseArticleInformationCreated
                        ? "is-invalid "
                        : ""
                    }form-control`}
                    id="baseArticleInformationCreated-date"
                    name="baseArticleInformationCreated-date"
                    selected={
                      baseArticleInformationCreated
                        ? new Date(baseArticleInformationCreated)
                        : undefined
                    }
                    onChange={(date) => {
                      if (dateFnsIsValid(date)) {
                        isToday(date)
                          ? setFieldValue(
                              "baseArticleInformationCreated",
                              dateFnsFormat(new Date(), DATE_TIME_FORMAT)
                            )
                          : setFieldValue(
                              "baseArticleInformationCreated",
                              dateFnsFormat(startOfDay(date), DATE_TIME_FORMAT)
                            );
                      } else {
                        setFieldValue(
                          "baseArticleInformationCreated",
                          undefined
                        );
                      }
                    }}
                    maxDate={new Date()}
                    locale={pl}
                    dateFormat={DATE_FORMAT}
                    invalid={
                      !!eBaseArticleInformationCreated &&
                      !baseArticleInformationCreated
                    }
                    autoComplete="off"
                    disabled={articleOnConfirmedStatus}
                    clearButtonTitle="Wyczyść"
                    isClearable
                  />
                </InputGroup>
                <InputGroup className="ml-2">
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                      <i className="pe-7s-timer" />
                    </div>
                  </InputGroupAddon>
                  <DatePicker
                    className={`${
                      !!eBaseArticleInformationCreated &&
                      !baseArticleInformationCreated
                        ? "is-invalid "
                        : ""
                    }form-control`}
                    id="baseArticleInformationCreated-time"
                    name="baseArticleInformationCreated-time"
                    selected={
                      baseArticleInformationCreated
                        ? new Date(baseArticleInformationCreated)
                        : undefined
                    }
                    timeFormat={TIME_FORMAT}
                    dateFormat={TIME_FORMAT}
                    invalid={
                      !!eBaseArticleInformationCreated &&
                      !baseArticleInformationCreated
                    }
                    autoComplete="off"
                    disabled={articleOnConfirmedStatus}
                    customInput={
                      <TimeInput
                        invalid={
                          !!eBaseArticleInformationCreated &&
                          !baseArticleInformationCreated
                        }
                        date={
                          baseArticleInformationCreated
                            ? new Date(baseArticleInformationCreated)
                            : undefined
                        }
                        minTime={startOfDay(
                          new Date(baseArticleInformationCreated)
                        )}
                        maxTime={
                          isToday(new Date(baseArticleInformationCreated))
                            ? new Date()
                            : endOfDay(new Date(baseArticleInformationCreated))
                        }
                        onChangeDate={(date) => {
                          dateFnsIsValid(date)
                            ? setFieldValue(
                                "baseArticleInformationCreated",
                                dateFnsFormat(date, DATE_TIME_FORMAT)
                              )
                            : setFieldValue(
                                "baseArticleInformationCreated",
                                undefined
                              );
                        }}
                      />
                    }
                  />
                </InputGroup>
              </div>
              {eBaseArticleInformationCreated &&
              !baseArticleInformationCreated ? (
                <FormFeedback style={{ display: "block" }}>
                  {eBaseArticleInformationCreated}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
