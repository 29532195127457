export const DEFAULT_FILTER_OPERATIONS = [
  "contains",
  "notcontains",
  "startswith",
  "endswith",
  "=",
  "<>",
];

export const DEFAULT_GROUP_OPERATIONS = ["and", "or"];

export const OPERATIONS = {
  "=": "equal",
  "<>": "notEqual",
  "<": "less",
  "<=": "lessThanOrEqual",
  ">": "greater",
  ">=": "greaterThanOrEqual",
  contains: "contains",
  between: "between",
  startswith: "startsWith",
  endswith: "endsWith",
  notcontains: "notContains",
};
