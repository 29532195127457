import React, { useState, useEffect, Fragment } from 'react';
import RSC from 'react-scrollbars-custom';
import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Button,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Collapse
} from 'reactstrap';

import { AddLinkMultimediaModal } from '../../../../../components/Modals/AddLinkMultimediaModal';

import filter from 'lodash.filter';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames';
import { FieldArray } from 'formik';

import { MultimediaType, ArticleType, LinkTargetType } from '../../../../../helpers/enums';
import { CONSTANTS } from '@constants';
import MultimediaSortable from '../../../../../components/SortableList/MultimediaSortableContainer';

const { MULTIMEDIA } = CONSTANTS;
const HIDDEN_FOR_TYPES = [ ArticleType.TransmissionFromRadniInfo.value ];

const getMultimedia = (multimedia = [], multimediaType = MultimediaType.Unknown.value) =>
	filter(multimedia, { type: multimediaType });

export const MultimediaCard = ({
	items = [],
	onlyReplace = false,
	disabled = false,
	articleType = ArticleType.Common.value,
	maxFileSize = 10,
	onSave = Function.prototype,
	onChange = Function.prototype
}) => {
	const [ multimedia, setMultimedia ] = useState(items || []);
	const [ addVideoModal, setAddVideoModal ] = useState(false);
	const [ addAudioModal, setAddAudioModal ] = useState(false);
	const [ videoMultimedia, setVideoMultimedia ] = useState(getMultimedia(multimedia, MultimediaType.Video.value));
	const [ audioMultimedia, setAudioMultimedia ] = useState(getMultimedia(multimedia, MultimediaType.Audio.value));
	const [ activeTab, setActiveTab ] = useState(MultimediaType.Video.value);
	const [ collapse, setCollapse ] = useState(false);

	useEffect(
		() => {
			setMultimedia(items);
			setVideoMultimedia(getMultimedia(items, MultimediaType.Video.value));
			setAudioMultimedia(getMultimedia(items, MultimediaType.Audio.value));
		},
		[ items ]
	);

	const handleAddMultimedia = (multimedia) => {
		if (onSave && typeof onSave === 'function') {
			onSave([ multimedia ].concat(items));
		}
	};

	const multimediaOpts = {
		collapse: false,
		editable: true,
		removeable: true,
		showTargetMethod: true
	};

	return !HIDDEN_FOR_TYPES.includes(articleType) ? (
		<Fragment>
			<AddLinkMultimediaModal
				{...{
					isOpen: addVideoModal,
					maxFileSize: maxFileSize,
					multimedia: {
						id: 0,
						type: MultimediaType.Video.value,
						url: '',
						title: '',
						displayText: '',
						target: LinkTargetType.Self.value
					},
					onToggle: (_) => setAddVideoModal(!addVideoModal),
					onSave: (multimedia) => handleAddMultimedia(multimedia)
				}}
			/>
			<AddLinkMultimediaModal
				{...{
					isOpen: addAudioModal,
					maxFileSize: maxFileSize,
					multimedia: {
						id: 0,
						type: MultimediaType.Audio.value,
						url: '',
						title: '',
						displayText: '',
						target: LinkTargetType.Self.value
					},
					onToggle: (_) => setAddAudioModal(!addAudioModal),
					onSave: (multimedia) => handleAddMultimedia(multimedia)
				}}
			/>

			<Card className="mb-2">
				<CardHeader onClick={(_) => setCollapse(!collapse)} style={{ cursor: 'pointer', marginLeft: '5px' }}>
					<i className="header-icon pe-7s-film icon-gradient bg-malibu-beach" />
					{`${MULTIMEDIA} ${multimedia.length > 0 ? ` (${multimedia.length})` : ''}`}
					<div className="btn-actions-pane-right btn-actions-cursor--pointer">
						{collapse ? 'Zwiń' : 'Rozwiń'}
						{collapse ? <i className="pe-7s-angle-down" /> : <i className="pe-7s-angle-up" />}
					</div>
				</CardHeader>
				<Collapse isOpen={collapse}>
					<CardBody>
						<CardHeader>
							<Nav justified>
								<NavItem>
									<NavLink
										href="javascript:void(0);"
										className={classnames({
											active: activeTab === MultimediaType.Video.value
										})}
										onClick={() => {
											setActiveTab(MultimediaType.Video.value);
										}}
									>
										<div className="d-flex align-items-baseline justify-content-center">
											<CardTitle className="mb-0">
												{MultimediaType.Video.name}
												{` (${videoMultimedia.length})`}
											</CardTitle>
											{activeTab === MultimediaType.Video.value &&
											!disabled && (
												<Button
													type="button"
													className="mr-2 border-0 btn-transition"
													outline
													color="success"
													onClick={(_) => setAddVideoModal(!addVideoModal)}
												>
													<i className="pe-7s-plus btn-icon-wrapper" />
												</Button>
											)}
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										href="javascript:void(0);"
										className={classnames({
											active: activeTab === MultimediaType.Audio.value
										})}
										onClick={() => {
											setActiveTab(MultimediaType.Audio.value);
										}}
									>
										<div className="d-flex align-items-baseline justify-content-center">
											<CardTitle className="mb-0">
												{MultimediaType.Audio.name}
												{` (${audioMultimedia.length})`}
											</CardTitle>
											{activeTab === MultimediaType.Audio.value &&
											!disabled && (
												<Button
													type="button"
													className="mr-2 border-0 btn-transition"
													outline
													color="success"
													onClick={(_) => setAddAudioModal(!addAudioModal)}
												>
													<i className="pe-7s-plus btn-icon-wrapper" />
												</Button>
											)}
										</div>
									</NavLink>
								</NavItem>
							</Nav>
						</CardHeader>
						<TabContent activeTab={activeTab}>
							<TabPane tabId={MultimediaType.Video.value}>
								<div
									className={classnames({
										'scroll-area-md': !isEmpty(videoMultimedia)
									})}
								>
									<RSC>
										<FieldArray name="multimedia">
											{(arrayHelper) => (
												<MultimediaSortable
													{...{
														name: 'multimedia',
														disabled,
														editable: multimediaOpts.editable && !disabled,
														removeable: multimediaOpts.removeable && !disabled,
														items: videoMultimedia,
														prefixName: 'multimedia-video-item',
														maxFileSize,
														onChange: (name, items) =>
															onChange(name, audioMultimedia.concat(items)),
														onMove: (index, item) => {
															arrayHelper.replace(index, item);
															if (onlyReplace) {
																this.handleSaveCategory(item);
															}
														},
														onRemove: arrayHelper.remove
													}}
												/>
											)}
										</FieldArray>
									</RSC>
								</div>
							</TabPane>
							<TabPane tabId={MultimediaType.Audio.value}>
								<div
									className={classnames({
										'scroll-area-md': !isEmpty(audioMultimedia)
									})}
								>
									<RSC>
										<FieldArray name="multimedia">
											{(arrayHelper) => (
												<MultimediaSortable
													{...{
														name: 'multimedia',
														disabled,
														editable: multimediaOpts.editable && !disabled,
														removeable: multimediaOpts.removeable && !disabled,
														items: audioMultimedia,
														prefixName: 'multimedia-audio-item',
														maxFileSize,
														onChange: (name, items) =>
															onChange(name, videoMultimedia.concat(items)),
														onMove: (index, item) => {
															arrayHelper.replace(index, item);
															if (onlyReplace) {
																this.handleSaveCategory(item);
															}
														},
														onRemove: arrayHelper.remove
													}}
												/>
											)}
										</FieldArray>
									</RSC>
								</div>
							</TabPane>
						</TabContent>
					</CardBody>
				</Collapse>
			</Card>
		</Fragment>
	) : (
		<Fragment />
	);
};
