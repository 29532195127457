export const Filter = {
	// articleFilters: [],
	// extraArticleFilters: [],
	// systemArticleFilters: [],
	// extraSystemArticleFilters: [],
	articleFilters: {
		all: [],
		10: [],
		20: [],
		30: [],
		40: [],
		50: [],
		60: [],
	},
	extraArticleFilters: {
		all: [],
		10: [],
		20: [],
		30: [],
		40: [],
		50: [],
		60: [],
	},
	systemArticleFilters: {
		all: [],
		10: [],
		20: [],
		30: [],
		40: [],
		50: [],
		60: [],
	},
	extraSystemArticleFilters: {
		all: [],
		10: [],
		20: [],
		30: [],
		40: [],
		50: [],
		60: [],
	},
	userFilters: [],
	roleFilters: [],
	dictionaryEntryFilters: [],
	tagFilters: [],
	languageFilters: [],
	tileFilters: [],
	bookmarkFilters: [],
	bannerFilters: [],
	rssChannelFilters: [],
	systemParameterFilters: [],
	standardElementTranslationFilters: [],
	menuElementTranslationFilters: [],
	repositoryFilters: []
};
