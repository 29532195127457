import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import LanguageCard from "./Card";
import LanguageList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const Languages = () => (
  <Fragment>
    <Switch>
      <Route
        path={RouteUrls.Setting.language.create}
        component={LanguageCard}
      />
      <Route
        path={`${RouteUrls.Setting.language.edit}/:id`}
        component={LanguageCard}
      />
      <Route path="*" component={LanguageList} />
    </Switch>
  </Fragment>
);

export default Languages;
