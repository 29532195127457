import React, { useState, useRef, useMemo } from "react";

import qs from "qs";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardBody,
  Card,
  CardHeader,
} from "reactstrap";

import CustomStore from "devextreme/data/custom_store";

import { processFilters } from "../../../components/DxGrid/process-table-filters";

import { CONSTANTS } from "@constants";
import { DxTable } from "../../../components/DxGrid/DxGrid";

import { fetchData } from "../../../utils/api/fetch-data";
import { sendData } from "../../../utils/api/send-data";

import {
  ArticleSortOnCategoryType,
  ArticleStatus,
  DefaultLanguage,
} from "../../../helpers/enums";
import { Link } from "react-router-dom";
import { RouteUrls } from "../../../helpers/routeUrls";

import "../../../Pages/Menu/Horizontal/styles.css";

const {
  SORT_CONFIG_MENU_ELEMENTS,
  ARTICLE_SORT_ON_CATEGORY_TYPE,
  COMMON_INFORMATION,
  ARTICLE_LIST,
  CLOSE,
} = CONSTANTS;

export const ArticlesModal = ({ isOpen, onToggle, elementId, isoCode }) => {
  const [sortType, setSortType] = useState(ArticleSortOnCategoryType.User);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataGridRef = useRef(null);

  const isDefaultLanguage = DefaultLanguage.isoCode === isoCode;

  const checkIsDefaultFilters = () => {
    if (
      filters.length === 1 &&
      filters[0].columnName === "articleTranslationStatus" &&
      typeof filters[0].value === "string"
    ) {
      const currentStatusFilterArr = filters[0].value.split(";");
      const expectedValues =
        filters[0].operation === "in" ? ["10", "20", "30", "40"] : ["50", "60"];

      if (currentStatusFilterArr.length !== expectedValues.length) {
        return false;
      }

      return currentStatusFilterArr.every((statusValue) =>
        expectedValues.includes(statusValue)
      );
    }

    return false;
  };

  const isFiltersAllowDragging = checkIsDefaultFilters(filters);

  const isNotUserType = sortType !== ArticleSortOnCategoryType.User.value;

  const MENU_URL = "api/menus";

  const store = useMemo(
    () =>
      new CustomStore({
        key: "id",
        load: (loadOptions) => {
          const currentFilters = loadOptions?.filter
            ? processFilters(loadOptions?.filter)
            : [];

          setLoading(true);
          setFilters(currentFilters);

          const tableParams = qs.stringify(
            {
              lang: isoCode,
              skip: loadOptions.skip,
              take: loadOptions.take,
              filters: currentFilters,
            },
            { allowDots: true }
          );

          return fetchData({
            url: `${MENU_URL}/${elementId}/sort-configurations`,
            tableParams,
            onSuccess: ({ data }) => {
              setSortType(data.articleSortOnCategoryType);

              return {
                data: data?.articles?.items || [],
                totalCount: data?.articles?.totalItems || 0,
              };
            },
            onFinally: () => {
              setLoading(false);
            },
          });
        },
      }),
    [isoCode, elementId]
  );

  const updateArticleList = (articleSortOnCategoryType) => {
    setLoading(true);

    sendData({
      method: "put",
      body: { articleSortOnCategoryType },
      url: `${MENU_URL}/${elementId}/sort-configurations`,
      onSuccess: () => {
        // eslint-disable-next-line no-unused-expressions
        dataGridRef?.current?._instance.refresh();
      },
      onFinally: () => {
        setLoading(false);
      },
    });
  };

  const columns = [
    {
      dataField: "articleTranslationTitle",
      caption: "Nazwa artykułu",
      dataType: "text",
      allowHeaderFiltering: false,
      allowSorting: false,
    },
    {
      dataField: "articleTranslationStatus",
      caption: "Status",
      headerFilter: {
        dataSource: Object.values(ArticleStatus).map(({ name, value }) => ({
          text: name,
          value,
        })),
      },
      allowHeaderFiltering: true,
      allowFiltering: false,
      dataType: "number",
      width: "20%",
      defaultFilterValues: [10, 20, 30, 40],
      allowSorting: false,
      alignment: "left",
      cellRender: (e) => {
        const { articleTranslationStatus } = e.data;

        return (
          <div>
            {Object.values(ArticleStatus).find(
              (el) => el.value === articleTranslationStatus
            )?.name || ""}
          </div>
        );
      },
    },
    {
      dataField: "articleTranslationPublishedDate",
      caption: "Data publikacji",
      allowHeaderFiltering: false,
      dataType: "datetime",
      format: "yyyy-MM-dd HH:mm:ss",
      filterOperations: ["=", ">", "<", "between"],
      width: "20%",
      allowSorting: false,
      onFilterValueChange: (e) => console.log(e),
    },
    {
      caption: "",
      width: "50px",
      cellRender: (e) => {
        return (
          <button className="btn-icon settings-btn" title="Przejdź do artykułu">
            <Link to={RouteUrls.Article.common.editFunc(e.data.id)}>
              <i className={"pe-7s-next-2 m-0 text-warning settings-icon"} />
            </Link>
          </button>
        );
      },
    },
  ];

  const onReorder = (e) => {
    setLoading(true);

    const visibleRows = e.component.getVisibleRows();

    sendData({
      method: "put",
      body: {
        previousArticleId:
          e.toIndex === 0 ? null : visibleRows[e.toIndex].data.id,
      },
      url: `${MENU_URL}/${elementId}/articles/${e.itemData.id}`,
      onSuccess: () => {
        // eslint-disable-next-line no-unused-expressions
        dataGridRef?.current?._instance.refresh();
      },
      onFinally: () => {
        setLoading(false);
      },
    });
  };

  const onDragStart = (e) => {
    if (isNotUserType || !isFiltersAllowDragging || !isDefaultLanguage) {
      e.cancel = true;
    }
  };

  const onCellPrepared = (e) => {
    const dragIcons = e.cellElement.querySelectorAll(".dx-datagrid-drag-icon");

    if (isNotUserType || !isFiltersAllowDragging || !isDefaultLanguage) {
      dragIcons.forEach((el) => {
        el.parentNode.style.opacity = "0.3";
        el.parentNode.style.cursor = "not-allowed";
        el.parentNode.title = !isDefaultLanguage
          ? "Zmiana kolejności artykułów tylko na wersji polskiej"
          : isNotUserType
          ? `Ustaw sposób sortowania na "Użytkownika", aby przesunąć`
          : "Aby przesunąć ustaw domyślny zestaw filtrów";
      });
    }
  };

  return (
    <Modal isOpen={isOpen} backdrop size={"xl"}>
      <ModalHeader>
        <div className="d-flex justify-content-between">
          <span>{SORT_CONFIG_MENU_ELEMENTS}</span>
          <div className="d-flex">
            <Button
              className="btn-icon mr-2"
              color="secondary"
              type="button"
              onClick={() => onToggle()}
            >
              {CLOSE}
            </Button>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column">
          <Card className="mb-2 mr-1">
            <CardHeader>
              <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
              {COMMON_INFORMATION}
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="articleSortOnCategoryType">
                      {ARTICLE_SORT_ON_CATEGORY_TYPE}
                    </Label>
                    <Input
                      type="select"
                      id="articleSortOnCategoryType"
                      name="articleSortOnCategoryType"
                      placeholder="Wybierz sposób sortowania artykułów"
                      onChange={(e) => {
                        updateArticleList(+e.target.value);
                        setSortType(+e.target.value);
                      }}
                      value={sortType}
                      disabled={loading || !isDefaultLanguage}
                    >
                      {Object.values(ArticleSortOnCategoryType).map(
                        ({ name, value }) => (
                          <option key={value} value={value}>
                            {name}
                          </option>
                        )
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Card className="mb-2 mr-1 menuElement__card">
          <CardHeader>
            <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
            {ARTICLE_LIST}
          </CardHeader>
          <CardBody className="he-100">
            <DxTable
              {...{
                dataGridRef,
                keyExpr: "id",
                dataSource: store,
                columns,
                onReorder,
                isRowDragging: true,
                remoteOperations: true,
                isScrolling: true,
                height: 400,
                onCellPrepared,
                onDragStart,
              }}
            />
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};
