import Immutable from "seamless-immutable";

import { Auth } from "../stores";
import { UserActions } from "../actions/auth/user";
import { RoleActions } from "../actions/auth/role";
import { AuthActions } from "../actions/auth";
import remove from "lodash.remove";
import { format } from "date-fns";
import uuid from "uuid/v4";
import { IdentificationDocumentType, Sex } from "../helpers/enums";

const initialState = Immutable(Auth);

export function auth(state = initialState, action = {}) {
  switch (action.type) {
    case UserActions.GetUserExcludeCategories.Success: {
      return state.setIn(["excludeCategories"], action.payload);
    }
    case UserActions.SetCategoryPermissionUserData.Success: {
      const { sub, ids, checked } = action.payload.id;
      const { excludeCategories } = Immutable(state).getIn(["user"]);
      let newExcludeCategories = excludeCategories.asMutable();

      if (sub) {
        if (!checked) {
          remove(newExcludeCategories, (i) => ids.includes(i));
        } else {
          newExcludeCategories = [...newExcludeCategories, ...ids];
        }
      } else {
        if (newExcludeCategories.includes(ids)) {
          remove(newExcludeCategories, (i) => i === ids);
        } else {
          newExcludeCategories.push(ids);
        }
      }

      return state.setIn(["user", "excludeCategories"], newExcludeCategories);
    }
    case UserActions.LoadUsers.Success: {
      return state.merge({
        users: action.payload.items,
        totalUsers: action.payload.totalItems,
      });
    }
    case UserActions.GetUser.Success:
    case UserActions.CreateUser.Success:
    case UserActions.CreateUser.Failure:
    case UserActions.UpdateUser.Success:
    case UserActions.ActiveUser.Success:
    case UserActions.DeactiveUser.Success: {
      return state.merge({
        user: action.payload,
        excludeCategories:
          state.userId === action.payload.id
            ? action.payload.excludeCategories
            : state.excludeCategories,
      });
    }
    case UserActions.DeleteUsers.Success: {
      const users = Immutable(state).getIn(["users"]);
      const newUsers = [];
      users.reduce((prev, curr) => {
        if (!action.payload.includes(curr.id)) {
          prev.push(curr);
          return prev;
        }
        curr = Immutable(curr).merge({ isActive: false });
        prev.push(curr);
        return prev;
      }, newUsers);
      return state.setIn(["users"], newUsers);
    }
    case UserActions.GetPermissions.Success: {
      return state.merge({
        userPermissions: action.payload,
        fetchedPermissions: true,
      });
    }
    case UserActions.GetLoggedUserData.Request: {
      return state.merge({
        userId: 0,
        isActive: false,
        isAdmin: false,
        isSystem: false,
        excludeCategories: [],
      });
    }
    case UserActions.GetLoggedUserData.Success: {
      return state.merge({
        userId: action.payload.id,
        isActive: action.payload.isActive,
        isAdmin: action.payload.isAdmin,
        isSystem: action.payload.isSystem,
        excludeCategories: action.payload.excludeCategories,
      });
    }
    case AuthActions.UnloadAuth.Success: {
      return state.merge({
        role: null,
        roles: [],
        totalRoles: 0,
        user: {
          id: 0,
          identityUserId: uuid(),
          userName: "",
          firstname: "",
          surname: "",
          pesel: "",
          birthDate: format(new Date(1900, 0, 1), "yyyy-MM-dd HH:mm:ss"),
          identificationDocumentType: IdentificationDocumentType.Unknown.value,
          identificationDocumentNumber: "",
          sex: Sex.Unknown.value,
          phone: "",
          mobilePhone: "",
          fax: "",
          email: "",
          isActive: false,
          isConfirmed: false,
          confirmedDate: format(new Date(1900, 0, 1), "yyyy-MM-dd HH:mm:ss"),
          roles: [],
          excludeCategories: [],
        },
        users: [],
        totalUsers: 0,
      });
    }
    case RoleActions.LoadRoles.Success: {
      return state.merge({
        roles: action.payload.items,
        totalRoles: action.payload.totalItems,
      });
    }
    case RoleActions.GetRole.Success:
    case RoleActions.CreateRole.Success:
    case RoleActions.UpdateRole.Success: {
      return state.merge({
        role: action.payload,
      });
    }
    case RoleActions.DeleteRoles.Success: {
      const roles = Immutable(state).getIn(["roles"]);
      const newRoles = [];
      roles.reduce((prev, curr) => {
        if (!action.payload.includes(curr.id)) {
          prev.push(curr);
          return prev;
        }
        return prev;
      }, newRoles);
      return state.setIn(["roles"], newRoles);
    }
    default: {
      return state;
    }
  }
}
