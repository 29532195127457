import React, { useState, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";

import { createNewCategory } from "./helpers";

import { DetailButton } from "../../../components/DetailButton/DetailButton";
import { ArticlesModal } from "../ArticlesModal/ArticlesModal";

import MenuElementDetails from "../Modals/MenuElementDetails";

import { DefaultLanguage, MenuType } from "../../../helpers/enums";

import { CONSTANTS } from "@constants";
import { Grid } from "../Grid/index";
import { processChildrenTreeData } from "../../../utils/menu";

const {
  DISABLE_ADD_CATEGORY_MESSAGE,
  DISABLE_DELETE_CATEGORY_MESSAGE,
  ADD_CATEGORY,
  CATEGORY_URL_VLAIDATION_MESSAGE,
  IS_NOT_PERMISSION_MESSAGE,
  CATEGORY_OR_SUBCATEGORY_HAS_URL,
} = CONSTANTS;

const editColumn = (e, generateSettingsButtons) => {
  const currentLanguages = [...(e.data?.languages || [])]
    .sort((a, b) => a.languageId - b.languageId)
    .slice(0, 2);

  const otherLanguagesText = [...(e.data?.languages || [])].reduce(
    (acc, item, index, arr) => {
      if (item.languageIsoCode !== DefaultLanguage.isoCode) {
        if (index === arr.length - 1) {
          acc += `${item.languageName}`;
        } else {
          acc += `${item.languageName}, `;
        }
      }

      return acc;
    },
    ""
  );

  return (
    <div className="d-flex align-items-center">
      {generateSettingsButtons(e).map(
        ({
          title,
          iconClassName,
          onClick,
          disabled,
          showTooltip,
          tooltipMessage,
          btnClassName,
        }) => (
          <DetailButton
            key={title}
            {...{
              className: "btn-icon settings-btn",
              title,
              onClick,
              disabled,
              showTooltip,
              tooltipMessage,
              btnClassName,
            }}
          >
            <i className={iconClassName} />
          </DetailButton>
        )
      )}
      <div className="language-wrapper">
        {currentLanguages.map((el, index) => {
          if (e.data?.languages.length > 2 && index === 1) {
            return (
              <div
                key={el.languageIsoCode}
                className={"language-btn bg-dark"}
                title={otherLanguagesText}
              >
                <i className="pe-7s-more btn-icon-wrapper" />
              </div>
            );
          }

          return (
            <div
              key={el.languageIsoCode}
              className={"language-btn bg-dark"}
              title={el.languageName}
            >
              {el.languageIsoCode}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const MenuTable = ({
  menuItems,
  updateMenu,
  getMenu,
  getUserExcludeCategories,
  languages,
  type,
  excludeCategories,
  currentLang,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSortable, setIsOpenSortable] = useState(false);
  const [category, setCategory] = useState(null);
  const [otherLanguages, setOtherLanguages] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  const data = [...menuItems]
    .sort((a, b) => a.order - b.order)
    .map((item) => ({
      ...item,
      NEW_ID: item.elementId,
      Head_ID: item?.parentElementId || -1,
    }));

  const rootElements = data.filter((el) => !el.parentElementId);
  const isNotDefaultLanguage = currentLang.isoCode !== DefaultLanguage.isoCode;

  const isHorizontal = type === MenuType.Horizontal;

  const MAX_ROOT_ELEMENTS_LENGTH = 6;
  const MAX_ROOT_ELEMENTS_MESSAGE =
    "Osiągnieto maksymalna ilość kategorii głównych";

  const dataGridRef = useRef(null);

  const currentFilters = dataGridRef?.current?._instance.getCombinedFilter();

  const updateOtherLanguages = useCallback(
    (currentData) => {
      setOtherLanguages(currentData);
    },
    [setOtherLanguages]
  );

  const generateSettingsButtons = (e) => {
    const isNotPermission = excludeCategories.includes(e.data.id);
    const addBtnDisabled =
      isNotPermission || !!e.data?.url || isNotDefaultLanguage;
    const deleteBtnDisabled =
      isNotPermission || !e.data?.canDelete || isNotDefaultLanguage;

    return [
      {
        title: "Dodaj",
        iconClassName: "pe-7s-plus m-0 text-success settings-icon",
        onClick: () => handleAddRecord(e.data.NEW_ID),
        disabled: addBtnDisabled,
        showTooltip: addBtnDisabled,
        tooltipMessage: isNotDefaultLanguage
          ? DISABLE_ADD_CATEGORY_MESSAGE
          : isNotPermission
          ? IS_NOT_PERMISSION_MESSAGE
          : "Kategoria zawiera adres URL, więc nie może posiadać podkategorii",
        btnClassName: "settings-btn",
      },
      {
        title: "Edytuj",
        iconClassName: "pe-7s-settings m-0 text-warning settings-icon",
        onClick: () => handleEditRecord(e),
        btnClassName: "settings-btn",
        disabled: isNotPermission,
        showTooltip: isNotPermission,
        tooltipMessage: IS_NOT_PERMISSION_MESSAGE,
      },
      {
        title: "Usuń",
        iconClassName: "pe-7s-trash  m-0 p-0 text-danger settings-icon",
        btnClassName: "settings-btn",
        onClick: () => deleteRecord(e),
        disabled: deleteBtnDisabled,
        showTooltip: deleteBtnDisabled,
        tooltipMessage: isNotDefaultLanguage
          ? DISABLE_DELETE_CATEGORY_MESSAGE
          : isNotPermission
          ? IS_NOT_PERMISSION_MESSAGE
          : "Kategoria zawiera artykuły opublikowane lub inne kategorie które są linkiem zewnętrznym",
      },
      {
        title: "Sortuj artykuły",
        iconClassName:
          "lnr-sort-amount-asc fsize-2 m-0 p-0 text-dark settings-icon",
        onClick: () => handleOpenSortableModal(e),
        btnClassName: "settings-btn",
        disabled: isNotPermission || !!e.data.url,
        showTooltip: isNotPermission || !!e.data.url,
        tooltipMessage: !!e.data.url
          ? CATEGORY_OR_SUBCATEGORY_HAS_URL
          : IS_NOT_PERMISSION_MESSAGE,
      },
    ];
  };

  const columns = [
    {
      dataField: "displayText",
      caption: "Nazwa",
      dataType: "text",
      allowHeaderFiltering: false,
    },
    {
      dataField: "textPath",
      caption: "Tekst pod czytniki dla osób słabowidzących",
      dataType: "text",
      visible: false,
    },
    {
      dataField: "description",
      caption: "Opis",
      dataType: "text",
      visible: false,
    },
    {
      caption: "Ustawienia",
      width: 280,
      cellRender: (e) => editColumn(e, generateSettingsButtons),
    },
  ];

  const handleAddRecord = useCallback((parentId) => {
    const newCategory = createNewCategory(parentId, isHorizontal);

    setCategory(newCategory);
    setOtherLanguages([]);
    onToggle();
  }, []);

  const handleEditRecord = useCallback(({ data }) => {
    const record = {
      ...data,
      displayText: data.hasValue ? data.displayText : "",
    };

    const currentLanguages = [...data.languages].reduce((acc, el) => {
      acc.push({
        ...el,
        language: {
          name: el.languageName,
          id: el.languageId,
          isoCode: el.languageIsoCode,
          isoCodeFile: el?.languageIsoCodeFile || null,
        },
      });

      return acc;
    }, []);

    setCategory(record);
    setOtherLanguages(currentLanguages);
    onToggle();
  }, []);

  const handleOpenSortableModal = (e) => {
    setCategoryId(e.data.elementId);
    setIsOpenSortable((prev) => !prev);
  };

  const onToggleSortableModal = () => {
    setIsOpenSortable((prev) => !prev);
  };

  const deleteRecord = useCallback(
    ({ data }) => {
      updateMenu(type, { type: "remove", element: data }, currentLang.isoCode);
    },
    [type, updateMenu, currentLang.isoCode]
  );

  const onDragChange = useCallback((e) => {
    const visibleRows = e.component.getVisibleRows();
    const sourceNode = e.component.getNodeByKey(e.itemData.NEW_ID);

    let targetNode = visibleRows[e.toIndex].node;
    while (targetNode && targetNode.data) {
      if (targetNode.data.NEW_ID === sourceNode.data.NEW_ID) {
        e.cancel = true;
        break;
      }
      targetNode = targetNode.parent;
    }
  }, []);

  const onReorder = useCallback(
    (e) => {
      const visibleRows = e.component.getVisibleRows();

      let sourceData = e.itemData;
      let currentMenuItems = data;
      const sourceIndex = currentMenuItems.indexOf(sourceData);

      const body = {
        elementId: e.itemData.elementId,
      };

      if (e.dropInsideItem) {
        if (!!visibleRows[e.toIndex]?.data?.url) {
          toast.error(CATEGORY_URL_VLAIDATION_MESSAGE);
          return;
        }

        sourceData = { ...sourceData, Head_ID: visibleRows[e.toIndex].key };
        currentMenuItems = [
          ...currentMenuItems.slice(0, sourceIndex),
          sourceData,
          ...currentMenuItems.slice(sourceIndex + 1),
        ];

        body.previousId = null;
        body.parentId = visibleRows[e.toIndex].key;
      } else {
        const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
        let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

        if (targetData && e.component.isRowExpanded(targetData.NEW_ID)) {
          sourceData = { ...sourceData, Head_ID: targetData.NEW_ID };

          if (!!targetData?.url) {
            toast.error(CATEGORY_URL_VLAIDATION_MESSAGE);
            return;
          }

          body.parentId = targetData.NEW_ID < 0 ? null : targetData.NEW_ID;
          targetData = null;
        } else {
          const headId = targetData ? targetData.Head_ID : -1;
          const parent = data.find((el) => el.NEW_ID === headId);

          if (
            !parent &&
            rootElements.length >= 6 &&
            !rootElements.find((el) => el.NEW_ID === sourceData.NEW_ID) &&
            isHorizontal
          ) {
            toast.error(MAX_ROOT_ELEMENTS_MESSAGE);
            return;
          }

          if (parent && !!parent?.url) {
            toast.error(CATEGORY_URL_VLAIDATION_MESSAGE);
            return;
          }

          if (sourceData.Head_ID !== headId) {
            sourceData = { ...sourceData, Head_ID: headId };
          }

          body.parentId = headId < 0 ? null : headId;
        }

        currentMenuItems = [
          ...currentMenuItems.slice(0, sourceIndex),
          ...currentMenuItems.slice(sourceIndex + 1),
        ];

        const targetIndex =
          currentMenuItems.map((el) => el?.NEW_ID).indexOf(targetData?.NEW_ID) +
          1;

        body.previousId = targetIndex <= 0 ? null : targetData.elementId;

        currentMenuItems = [
          ...currentMenuItems.slice(0, targetIndex),
          sourceData,
          ...currentMenuItems.slice(targetIndex),
        ];
      }

      const { Head_ID, NEW_ID, order, ...res } = e.itemData;

      updateMenu(
        type,
        {
          type: "move",
          element: {
            ...res,
            parentElementId: body.parentId,
            previousElement: body.previousId,
          },
        },
        currentLang.isoCode
      );
    },
    [data, type, updateMenu, currentLang.isoCode]
  );

  const onToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onCellPrepared = (e) => {
    const dragIcons = e.cellElement.querySelectorAll(".dx-treelist-drag-icon");

    if (currentFilters || DefaultLanguage.isoCode !== currentLang.isoCode) {
      dragIcons.forEach((el) => {
        el.parentNode.style.opacity = "0.3";
        el.parentNode.style.cursor = "not-allowed";
        el.parentNode.title =
          DefaultLanguage.isoCode !== currentLang.isoCode
            ? "Ustaw język polski, aby przesunąć"
            : "Usuń filtry, aby przesunąć";
      });
    }
  };

  const onDragStart = useCallback(
    (e) => {
      if (currentFilters || DefaultLanguage.isoCode !== currentLang.isoCode) {
        e.cancel = true;
      }
    },
    [currentFilters, currentLang.isoCode]
  );

  const onEditorPreparing = (e) => {
    if (e.dataField === "displayText") {
      e.editorOptions.placeholder =
        "Wprowadź min 3 znaki, aby przefiltrować kolumnę";
    }

    if (e.parentType === "filterRow" && e.editorName === "dxTextBox") {
      var defaultValueChanged = e.editorOptions.onValueChanged;

      e.editorOptions.onValueChanged = function (filter) {
        if ((filter.value && filter.value.length >= 3) || !filter.value) {
          defaultValueChanged(filter);
        }
      };
    }
  };

  useEffect(() => {
    getMenu(type, false, false, currentLang.isoCode);
  }, [currentLang.isoCode]);

  useEffect(() => {
    getUserExcludeCategories();
  }, []);

  return (
    <div>
      <div className="mb-2 d-flex justify-content-end">
        <DetailButton
          {...{
            onClick: () => handleAddRecord(null),
            title: "Dodaj kategorię",
            btnClassName: "bg-success text-light border-0",
            disabled:
              (isHorizontal &&
                rootElements.length >= MAX_ROOT_ELEMENTS_LENGTH) ||
              isNotDefaultLanguage,
            showTooltip:
              (isHorizontal &&
                rootElements.length >= MAX_ROOT_ELEMENTS_LENGTH) ||
              isNotDefaultLanguage,
            tooltipMessage: isNotDefaultLanguage
              ? DISABLE_ADD_CATEGORY_MESSAGE
              : MAX_ROOT_ELEMENTS_MESSAGE,
          }}
        >
          <div className="d-flex d-flex align-items-center ">
            <i className="pe-7s-plus fa-lg mr-2" />
            {ADD_CATEGORY}
          </div>
        </DetailButton>
      </div>
      <Grid
        {...{
          keyExpr: "NEW_ID",
          parentIdExpr: "Head_ID",
          dataGridRef,
          data: processChildrenTreeData(data),
          onReorder,
          onDragChange,
          columns,
          onCellPrepared,
          onEditorPreparing,
          onDragStart,
        }}
      />
      {isOpen && category ? (
        <MenuElementDetails
          {...{
            isOpen,
            category,
            languages,
            onToggle,
            updateMenu,
            otherLanguages,
            updateOtherLanguages,
            type,
            currentLang,
          }}
        />
      ) : null}
      <ArticlesModal
        {...{
          isOpen: isOpenSortable,
          elementId: categoryId,
          onToggle: onToggleSortableModal,
          isoCode: currentLang.isoCode,
        }}
      />
    </div>
  );
};
