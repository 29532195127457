const uriName = window.location.hostname.split('.')[0];
const restUri = window.location.hostname.split('.').slice(1).join('.');
const apiPort = process.env.REACT_APP_API_PORT || window.location.port
const identityApiPort = process.env.REACT_APP_IDENTITY_API_PORT || window.location.port

export const isDeveloping = process.env.NODE_ENV !== 'production';

export const getUri = (apiType) => `https://${uriName}-api.${restUri}:${apiPort}/${apiType}`;

export const getIdentityUri = (apiType) =>
  `https://${uriName}-identity.${restUri}:${identityApiPort}/${apiType}`;