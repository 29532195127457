export function getErrorForToastr(err) {
  let title =
    err && err.response && err.response.data ? err.response.data.title : null;
  let message = "";
  if (
    err &&
    err.response &&
    err.response.data &&
    err.response.data.errors &&
    err.response.data.errors.length > 0
  ) {
    err.response.data.errors.forEach(
      (item) => (message += `${item.message} <br />`)
    );
  }
  if (
    err &&
    err.response &&
    err.response.data &&
    err.response.data.errors &&
    err.response.data.errors.message &&
    err.response.data.errors.message.length > 0
  ) {
    err.response.data.errors.message.forEach(
      (item) => (message += `${item} <br />`)
    );
  }

  return { title, message };
}
export const isCheckAccessModuleRequest = (response) =>
  response && response.headers
    ? response.headers["x-checkaccesstomodule"]
    : false;
