export const UIBlockingState = {
	menuCardBlocking: false,
	appImagesCardBlocking: false,
	articleCardBlocking: false,
	articleListBlocking: false,
	bannerCardBlocking: false,
	bannerListBlocking: false,
	bookmarkCardBlocking: false,
	bookmarkListBlocking: false,
	dictionaryEntryCardBlocking: false,
	dictionaryEntryListBlocking: false,
	footerCardBlocking: false,
	footerListBlocking: false,
	footerLinkListBlocking: false,
	headerCardBlocking: false,
	languageCardBlocking: false,
	languageListBlocking: false,
	rssChannelCardBlocking: false,
	rssChannelListBlocking: false,
	roleCardBlocking: false,
	roleListBlocking: false,
	systemParameterListBlocking: false,
	systemParameterCardBlocking: false,
	tagCardBlocking: false,
	tagListBlocking: false,
	tileCardBlocking: false,
	tileListBlocking: false,
	userCardBlocking: false,
	userListBlocking: false,
	officeContactBlocking: false,
	archiveBipBlocking: false,
	officeCardBlocking: false,
	changePasswordBlocking: false
};
