import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import cx from 'classnames';

import { CONSTANTS } from '@constants';

const { YES, NO } = CONSTANTS;

export const Switch = ({ on, off, onClick, onChange, label, disabled = false }) => (
	<FormGroup className="d-flex align-items-center">
		<Label className="mr-2">{label}</Label>
		<div
			className={`switch has-switch mb-2 mr-2${disabled ? ' div--disabled' : ''}`}
			data-on-label="Tak"
			data-off-label="Nie"
			{...{ onChange, onClick }}
		>
			<div
				className={cx('switch-animate', {
					'switch-on': on,
					'switch-off': off
				})}
			>
				<input type="checkbox" disabled={disabled} />
				<span className="switch-left">{YES}</span>
				<label>&nbsp;</label>
				<span className="switch-right">{NO}</span>
			</div>
		</div>
	</FormGroup>
);
