import React, { Component, Fragment } from 'react';
import { Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';

import LogoCard from '../Modals/LogoCard';
import EditorialCard from '../Modals/EditorialCard';
import EditorialAddressCard from '../Modals/EditorialAddressCard';
import InformationCard from '../Modals/InformationCard';
import UpdateCard from '../Modals/UpdateCard';

import { FooterType } from '../../../../helpers/enums';

export default class FooterElementCard extends Component {
	state = {
		showDetails: false
	};
	toggle = () => this.setState({ showDetails: !this.state.showDetails });

	getTypeName = (value) => {
		switch (value) {
			case FooterType.Logo.value:
				return FooterType.Logo.name;
			case FooterType.EditorialOffice.value:
				return FooterType.EditorialOffice.name;
			case FooterType.EditorialOfficeAddress.value:
				return FooterType.EditorialOfficeAddress.name;
			case FooterType.Information.value:
				return FooterType.Information.name;
			case FooterType.Updates.value:
				return FooterType.Updates.name;
			default:
				return '';
		}
	};
	getIcon = (value) => {
		switch (value) {
			case FooterType.Logo.value:
				return <i className="header-icon pe-7s-photo icon-gradient bg-malibu-beach" />;
			case FooterType.EditorialOffice.value:
				return <i className="header-icon pe-7s-users icon-gradient bg-malibu-beach" />;
			case FooterType.EditorialOfficeAddress.value:
				return <i className="header-icon pe-7s-id icon-gradient bg-malibu-beach" />;
			case FooterType.Information.value:
				return <i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach" />;
			case FooterType.Updates.value:
				return <i className="header-icon pe-7s-cloud icon-gradient bg-malibu-beach" />;
			default:
				return <span />;
		}
	};

	render() {
		const {
			footerElement = { type: FooterType.Logo.value, displayText: '' },
			disabled = false,
			index = 0,
			onChange = (footerElement) => {}
		} = this.props;
		const { showDetails } = this.state;

		return (
			<Fragment>
				{footerElement.type === FooterType.Logo.value && (
					<LogoCard
						isOpen={showDetails}
						footerElement={footerElement}
						updateFooterElement={(values) => onChange(index, values)}
						cancel={this.toggle}
					/>
				)}
				{footerElement.type === FooterType.EditorialOffice.value && (
					<EditorialCard
						isOpen={showDetails}
						footerElement={footerElement}
						updateFooterElement={(values) => onChange(index, values)}
						cancel={this.toggle}
					/>
				)}
				{footerElement.type === FooterType.EditorialOfficeAddress.value && (
					<EditorialAddressCard
						isOpen={showDetails}
						footerElement={footerElement}
						updateFooterElement={(values) => onChange(index, values)}
						cancel={this.toggle}
					/>
				)}
				{footerElement.type === FooterType.Information.value && (
					<InformationCard
						isOpen={showDetails}
						footerElement={footerElement}
						updateFooterElement={(values) => onChange(index, values)}
						cancel={this.toggle}
					/>
				)}
				{footerElement.type === FooterType.Updates.value && (
					<UpdateCard
						isOpen={showDetails}
						footerElement={footerElement}
						updateFooterElement={(values) => onChange(index, values)}
						cancel={this.toggle}
					/>
				)}

				<Card className={`mb-3 card-hover-shadow card-border`} outline>
					<CardHeader>
						{this.getIcon(footerElement.type)}
						{this.getTypeName(footerElement.type)}
						<div className="btn-actions-pane-right actions-icon-btn">
							<Button
								className="mr-2 btn-icon btn-icon-only"
								color="link"
								outline
								title="Edytuj element stopki"
								type="button"
								disabled={disabled}
								onClick={this.toggle}
							>
								<i className="lnr-pencil btn-icon-wrapper"> </i>
							</Button>
						</div>
					</CardHeader>
					<CardBody>
						<ListGroup flush>
							<ListGroupItem>
								<div className="widget-content p-0">
									<div className="widget-content-wrapper">
										{footerElement.type === FooterType.Logo.value && (
											<div className="widget-content-left">
												<div className="widget-heading">Opis</div>
												<div className="widget-subheading">Element konfiguracji z herbem</div>
											</div>
										)}
										{footerElement.type !== FooterType.Logo.value && (
											<div className="widget-content-left">
												<div className="widget-heading">Nazwa wyświetlana</div>
												<div className="widget-subheading">{footerElement.displayText}</div>
											</div>
										)}
									</div>
								</div>
							</ListGroupItem>
						</ListGroup>
					</CardBody>
				</Card>
			</Fragment>
		);
	}
}
