import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';

import { SettingActions } from '../../actions/settings';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const languageUrl = '/api/settings/language';
const standardElementUrl = '/api/settings/standard-element';
const standardElementTranslationUrl = '/api/settings/standard-element-translation';

function* createLanguage(action) {
	try {
		let language = yield call(createLanguageRequest, action.payload);

		yield put({
			type: SettingActions.CreateLanguage.Success,
			payload: language
		});

		yield put(replace(RouteUrls.Setting.language.editFunc(language.id)));
		toast.success('Nowa wersja językowa została utworzona');
	} catch (err) {
		yield put({
			type: SettingActions.CreateLanguage.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie tworzenia nowej wersji językowej');
	}
}
function createLanguageRequest(language) {
	return axios.post(`${languageUrl}`, language).then((response) => response.data).catch((err) => {
		throw err;
	});
}
function* getLanguage(action) {
	try {
		let language = yield call(getLanguageRequest, action.payload);

		yield put({
			type: SettingActions.GetLanguageAction.Success,
			payload: language
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetLanguageAction.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych wersji językowej');
	}
}

function getLanguageRequest(id) {
	return axios.get(`${languageUrl}/${id}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}
function* loadLanguages(action) {
	try {
		let languages = yield call(getListRequest, {
			url: languageUrl,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: SettingActions.LoadLanguagesAction.Success,
			payload: languages
		});
	} catch (err) {
		yield put({
			type: SettingActions.LoadLanguagesAction.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy wersji językowych');
	}
}
function* updateLanguage(action) {
	try {
		let language = yield call(updateLanguageRequest, action.payload.id, action.payload.language);

		yield put({
			type: SettingActions.UpdateLanguageAction.Success,
			payload: language
		});
		toast.success('Dane wersji językowej zostały zaktualizowane');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateLanguageAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie aktualizacji danych wersji językowej');
	}
}

function updateLanguageRequest(id, language) {
	return axios.put(`${languageUrl}/${id}`, language).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateLanguageStatus(action) {
	try {
		let language = yield call(
			updateLanguageStatusRequest,
			action.payload.id,
			action.payload.status,
			action.payload.language
		);

		yield put({
			type: SettingActions.UpdateLanguageStatusAction.Success,
			payload: language
		});
		toast.success('Status wersji językowej został zmieniony');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateLanguageStatusAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie aktualizacji statusu wersji językowej');
	}
}

function updateLanguageStatusRequest(id, status, language) {
	return axios
		.put(`${languageUrl}/${id}/status/${status}`, language)
		.then((response) => response.data)
		.catch((err) => {
			throw err;
		});
}

function* removeLanguage(action) {
	try {
		yield call(removeLanguageRequest, action.payload);
		yield put({
			type: SettingActions.RemoveLanguageAction.Success,
			payload: action.payload
		});
		toast.success('Wersje językowe zostały usunięte');
	} catch (err) {
		yield put({
			type: SettingActions.RemoveLanguageAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie usuwania wersji językowej');
	}
}
function removeLanguageRequest(languagesIds) {
	const query = qs.stringify({ languagesIds: languagesIds }, { addQueryPrefix: false, allowDots: true });
	return axios.delete(`${languageUrl}?${query}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}
function* loadStandardElements(action) {
	try {
		let url =
			action.payload.languageId > 0
				? `${languageUrl}/${action.payload.languageId}/standard-element-translations`
				: standardElementUrl;
		let standardElements = yield call(getListRequest, {
			url,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: SettingActions.LoadStandardElementsAction.Success,
			payload: standardElements
		});
	} catch (err) {
		yield put({
			type: SettingActions.LoadStandardElementsAction.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania słownika elementów systemowych');
	}
}
function* updateStandardElementTranslation(action) {
	try {
		let elementTranslation = yield call(
			updateStandardElementTranslationRequest,
			action.payload.id,
			action.payload.elementTranslation
		);

		yield put({
			type: SettingActions.UpdateStandardElementTranslationAction.Success,
			payload: elementTranslation
		});
		toast.success('Dane tłumaczenia zostały zaktualizowane');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateStandardElementTranslationAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie aktualizacji danych tłumaczenia');
	}
}
function updateStandardElementTranslationRequest(id, elementTranslation) {
	return axios
		.put(`${standardElementTranslationUrl}/${id}`, elementTranslation)
		.then((response) => response.data)
		.catch((err) => {
			throw err;
		});
}
function* loadMenuElements(action) {
	try {
		let url = `${languageUrl}/${action.payload.languageId}/menu-element-translations`;
		let menuElements = yield call(getListRequest, {
			url,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: SettingActions.LoadMenuElementsAction.Success,
			payload: menuElements
		});
	} catch (err) {
		yield put({
			type: SettingActions.LoadMenuElementsAction.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania słownika elementów systemowych');
	}
}

function* updateCurrentLanguageState(action) {
	yield put({
		type: SettingActions.UpdateCurrentLanguageState.Success,
		payload: action.payload
	});
}

function* watchCreateLanguage() {
	yield takeEvery(SettingActions.CreateLanguage.Request, createLanguage);
}
function* watchGetLanguage() {
	yield takeEvery(SettingActions.GetLanguageAction.Request, getLanguage);
}
function* watchLoadLanguages() {
	yield takeEvery(SettingActions.LoadLanguagesAction.Request, loadLanguages);
}
function* watchRemoveLanguage() {
	yield takeEvery(SettingActions.RemoveLanguageAction.Request, removeLanguage);
}
function* watchUpdateLanguage() {
	yield takeEvery(SettingActions.UpdateLanguageAction.Request, updateLanguage);
}
function* watchUpdateLanguageStatus() {
	yield takeEvery(SettingActions.UpdateLanguageStatusAction.Request, updateLanguageStatus);
}
function* watchLoadStandardElements() {
	yield takeEvery(SettingActions.LoadStandardElementsAction.Request, loadStandardElements);
}
function* watchUpdateStandardElementTranslation() {
	yield takeEvery(SettingActions.UpdateStandardElementTranslationAction.Request, updateStandardElementTranslation);
}
function* watchLoadMenuElements() {
	yield takeEvery(SettingActions.LoadMenuElementsAction.Request, loadMenuElements);
}
function* watchUpdateCurrentLanguageState() {
	yield takeEvery(SettingActions.UpdateCurrentLanguageState.Request, updateCurrentLanguageState);
}

export const LanguageSagas = [
	fork(watchCreateLanguage),
	fork(watchGetLanguage),
	fork(watchLoadLanguages),
	fork(watchRemoveLanguage),
	fork(watchUpdateLanguage),
	fork(watchUpdateLanguageStatus),
	fork(watchLoadStandardElements),
	fork(watchUpdateStandardElementTranslation),
	fork(watchLoadMenuElements),
	fork(watchUpdateCurrentLanguageState)
];
