import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';

import { SettingActions } from '../../actions/settings';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const RSS_CHANNEL_URL = '/api/settings/rss-channels';

function* loadRssChannels(action) {
	try {
		let rssChannels = yield call(getListRequest, {
			url: RSS_CHANNEL_URL,
			lang: action.payload.lang,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: SettingActions.LoadRssChannels.Success,
			payload: rssChannels
		});
	} catch (err) {
		yield put({
			type: SettingActions.LoadRssChannels.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy kanałów RSS');
	}
}

function* getRssChannel(action) {
	try {
		let rssChannel = yield call(getRssChannelRequest, action.payload);

		yield put({
			type: SettingActions.GetRssChannel.Success,
			payload: rssChannel
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetRssChannel.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych kanału RSS');
	}
}
function getRssChannelRequest(id) {
	return axios.get(`${RSS_CHANNEL_URL}/${id}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateRssChannel(action) {
	try {
		let rssChannel = yield call(updateRssChannelRequest, action.payload);

		yield put({
			type: SettingActions.UpdateRssChannel.Success,
			payload: rssChannel
		});
		toast.success('Kanał RSS został zaktualizowany');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateRssChannel.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji danych kanału RSS');
	}
}
function updateRssChannelRequest(rssChannel) {
	return axios
		.put(`${RSS_CHANNEL_URL}/${rssChannel.id}`, rssChannel)
		.then((response) => response.data)
		.catch((err) => {
			throw err;
		});
}

function* createRssChannel(action) {
	try {
		let rssChannel = yield call(createRssChannelRequest, action.payload);

		yield put({
			type: SettingActions.CreateRssChannel.Success,
			payload: rssChannel
		});
		yield put(replace(RouteUrls.Setting.rssChannels.editFunc(rssChannel.id)));
		toast.success('Kanał RSS został utworzony');
	} catch (err) {
		yield put({
			type: SettingActions.CreateRssChannel.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas tworzenia kanału RSS');
	}
}
function createRssChannelRequest(rssChannel) {
	return axios.post(RSS_CHANNEL_URL, rssChannel).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* deleteRssChannels(action) {
	try {
		yield call(deleteRssChannelsRequest, action.payload);

		yield put({
			type: SettingActions.DeleteRssChannels.Success,
			payload: action.payload
		});
		toast.success('Kanły RSS zostały usunięte');
	} catch (err) {
		yield put({
			type: SettingActions.DeleteRssChannels.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie usuwania kanałów RSS');
	}
}

function deleteRssChannelsRequest(rssChannelIds) {
	const filterQuery = qs.stringify({ rssChannelIds: rssChannelIds }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${RSS_CHANNEL_URL}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchLoadRssChannels() {
	yield takeEvery(SettingActions.LoadRssChannels.Request, loadRssChannels);
}
function* watchGetRssChannel() {
	yield takeEvery(SettingActions.GetRssChannel.Request, getRssChannel);
}
function* watchCreateRssChannel() {
	yield takeEvery(SettingActions.CreateRssChannel.Request, createRssChannel);
}
function* watchUpdateRssChannel() {
	yield takeEvery(SettingActions.UpdateRssChannel.Request, updateRssChannel);
}
function* watchDeleteRssChannels() {
	yield takeEvery(SettingActions.DeleteRssChannels.Request, deleteRssChannels);
}

export const RssChannelSagas = [
	fork(watchLoadRssChannels),
	fork(watchGetRssChannel),
	fork(watchCreateRssChannel),
	fork(watchUpdateRssChannel),
	fork(watchDeleteRssChannels)
];
