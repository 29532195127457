import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { bindActionCreators } from "redux";
import isEqual from "lodash.isequal";
import isEmpty from "lodash.isempty";
import map from "lodash.map";
import filter from "lodash.filter";
import find from "lodash.find";
import Immutable from "seamless-immutable";

import { Card, CardHeader, CardBody, Collapse } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { DictionaryActionCreators } from "../../actions/dictionary";

class TagCard extends Component {
  static defaultProps = {
    tags: [],
    availableTags: [],
    disabled: false,
    onSave: (tags) => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      cardCollapse: props.initialCollapse || false,
      tags: props.tags || [],
      availableTags:
        Immutable(props.availableTags).asMutable({ deep: true }) || [],
    };
  }

  componentDidMount() {
    const { activeLanguage, getAllTags } = this.props;
    getAllTags(activeLanguage.isoCode, 0, 0);
  }

  componentDidUpdate(prevProps) {
    const { activeLanguage, getAllTags } = this.props;

    if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
      getAllTags(activeLanguage.isoCode, 0, 0);
    }

    if (!isEqual(prevProps.tags, this.props.tags)) {
      this.setState({
        tags: this.props.tags,
        availableTags: this.setAvailableTags(),
      });
    }
    if (!isEqual(prevProps.availableTags, this.props.availableTags)) {
      this.setState({
        availableTags: this.setAvailableTags(),
      });
    }
  }

  setAvailableTags = () => {
    const { tags, availableTags } = this.props;
    const tagNames = map(tags, (tag) => tag.name);
    const availableTagNames = map(
      Immutable(availableTags).asMutable({ deep: true }),
      (tag) => tag.name
    );

    return map(
      filter(availableTagNames, (tagName) => !tagNames.includes(tagName)),
      (tag) => ({ name: tag })
    );
  };

  toggleCollapse = () => {
    this.setState({ cardCollapse: !this.state.cardCollapse });
  };

  handleSave = (tags) => {
    const { activeLanguage, onSave, createTag } = this.props;
    if (onSave && typeof onSave === "function") {
      if (!isEmpty(tags)) {
        onSave(map(tags, (tag) => ({ name: tag.name })));
      } else {
        onSave(tags);
      }
    }

    if (createTag && typeof createTag === "function") {
      const newTag = find(tags, (tag) => tag.customOption);
      if (newTag) {
        createTag(
          {
            ...newTag,
            id: 0,
            languageId: activeLanguage.id,
            languageIsoCode: activeLanguage.isoCode,
          },
          false,
          false
        );
      }
    }
  };
  checkCanAddNew = (newOptions, { text, selected }) => {
    const isNewOptionsEmpty = isEmpty(newOptions);
    const isDuplicate = !isEmpty(filter(selected, { name: text }));

    return isNewOptionsEmpty && !isDuplicate;
  };
  render() {
    const { disabled } = this.props;
    const { cardCollapse, tags, availableTags } = this.state;
    return (
      <Fragment>
        <Card className="mb-2">
          <CardHeader
            onClick={this.toggleCollapse}
            className="tag-card__header"
          >
            <i className="header-icon pe-7s-ribbon icon-gradient bg-malibu-beach" />
            Tagi
            <div className="btn-actions-pane-right btn-actions-cursor--pointer">
              {cardCollapse ? "Zwiń" : "Rozwiń"}
              {cardCollapse ? (
                <i className="pe-7s-angle-up" />
              ) : (
                <i className="pe-7s-angle-down" />
              )}
            </div>
          </CardHeader>
          <Collapse isOpen={cardCollapse} className="tag-card__items">
            <CardBody>
              <Typeahead
                id="tags"
                labelKey="name"
                options={availableTags}
                selected={tags}
                placeholder="Wybierz tag"
                newSelectionPrefix={"Nowy tag:"}
                emptyLabel={"Brak danych"}
                clearButton
                multiple
                disabled={disabled}
                allowNew={this.checkCanAddNew}
                onChange={this.handleSave}
              />
            </CardBody>
          </Collapse>
        </Card>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    availableTags: state.dictionary.tag.tags || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(DictionaryActionCreators, dispatch),
    cancel: () => dispatch(goBack()),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagCard);
