import React, { Component, Fragment } from "react";
import { Col } from "reactstrap";
import { connect } from "react-redux";

class Dashboard extends Component {
  render() {
    const { userPermissions, isActive, fetchedPermissions } = this.props;

    return (
      <Fragment>
        {(userPermissions.length === 0 || !isActive) && fetchedPermissions && (
          <div className="h-100 bg-heavy-rain bg-animation">
            <div className="d-flex h-100 justify-content-center align-items-center">
              <Col md="6" className="mx-auto app-login-box">
                <div className="mx-auto mb-3" />
                <div className="modal-dialog w-100">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="h5 modal-title">
                        Zalogowany użytkownik nie posiada uprawnień lub jego
                        konto jest nieaktywne!
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userPermissions: state.auth.userPermissions || [],
    isActive: state.auth.isActive || false,
  };
};
export default connect(mapStateToProps)(Dashboard);
