import React from 'react';
import map from 'lodash.map';
import filter from 'lodash.filter';
import BookmarkSource from './DragAndDropSources/BookmarkSource';

export const xxxx = ({ bookmarks = [], excludeBookmarks = [] }) =>
	map(filter(bookmarks, bookmark => !excludeBookmarks.includes(bookmark.id)), (bookmark, bookmarkIndex) => (
		<BookmarkSource {...bookmark} key={`article-${bookmark.id}-${bookmarkIndex}`} />
	));

export const BookmarkList = ({ bookmarks = [], excludeBookmarks = [], currentBookmarks = [], onChangeItems }) => (
	<div className="rst__tree" style={{ height: '100%', position: 'relative' }}>
		<div style={{ overflow: 'visible', height: '0px', width: '0px' }}>
			<div
				aria-label="grid"
				aria-readonly="true"
				className="ReactVirtualized__Grid ReactVirtualized__List rst__virtualScrollOverride"
				role="grid"
				tabindex="0"
				style={{
					boxSizing: 'border-box',
					direction: 'ltr',
					height: '937px',
					position: 'relative',
					width: '687px',
					willChange: 'transform',
					overflow: 'hidden'
				}}
			>
				{map(
					filter(bookmarks, bookmark => !excludeBookmarks.includes(bookmark.id)),
					(bookmark, bookmarkIndex) => (
						<BookmarkSource
							{...{ onChangeItems, ...bookmark }}
							key={`article-${bookmark.id}-${bookmarkIndex}`}
							bookmarks={currentBookmarks}
						/>
					)
				)}
			</div>
		</div>
	</div>
);
