import React from 'react';
import Grid from '../../../../components/Grid';

const columns = [
	{ name: 'id', title: 'id' },
	{ name: 'name', title: 'Nazwa języka' },
	{ name: 'localName', title: 'Lokalna nazwa języka' },
	{ name: 'isoCode', title: 'Kod ISO języka' },
	{ name: 'dateFormat', title: 'Format daty' },
];
const columnExtensions = [
	{	columnName: 'name',	wordWrapEnabled: true	}
];
const hiddenColumns = [ 'id' ];
const textColumns = [ 'name', 'localName', 'isoCode', 'dateFormat' ];
const boolColumns = [];
const boolFilterOperations = [];
const dateColumns = [];
const dateFilterOperations = [];
const currentPage = 0;

export const LanguageList = ({ ...props }) => (
	<Grid addText="Nowa"
		items={props.items}
		totalItems={props.totalItems}
		columns={columns}
		columnExtensions={columnExtensions}
		hiddenColumns={hiddenColumns}
		textColumns={textColumns}
		boolColumns={boolColumns}
		boolFilterOperations={boolFilterOperations}
		dateColumns={dateColumns}
		dateFilterOperations={dateFilterOperations}

		currentPage={currentPage}
		removeable={false}


		onReload={props.onReload}
		onRemove={props.onRemove}
		onAdd={props.onAdd}
		onRowClick={props.onRowClick}
		filters={props.filters}
		onChangeFilters={props.onChangeFilters}
		onClearFilters={props.onClearFilters}
	/>
);
