import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { Input, FormGroup } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

export default class ConfirmModal extends Component {
	state = {
		changeText: ''
	};

	componentDidUpdate(prevProps) {
		if (prevProps.open !== this.props.open && this.props.open) {
			this.setState({ changeText: '' });
		}
	}

	handleChangeText = (event) => {
		this.setState({ changeText: event.target.value });
	};

	render() {
		const {
			open = false,
			input = false,
			title = '',
			content = '',
			onCancel = Function.prototype,
			onSubmit = Function.prototype
		} = this.props;
		const { changeText } = this.state;
		return (
			<SweetAlert
				show={open}
				warning
				showCancel
				confirmBtnText="Tak"
				confirmBtnBsStyle="success"
				cancelBtnText="Nie"
				cancelBtnBsStyle="danger"
				title={title}
				onConfirm={() => (input ? onSubmit(changeText) : onSubmit())}
				onCancel={onCancel}
				focusCancelBtn
				reverseButtons={true}
				closeOnClickOutside={false}
				style={{
					marginLeft: 'auto',
					marginRight: 'auto',
					marginTop: '-15%',
					marginBottom: '-15%',
					position: 'fixed'
				}}
			>
				<Fragment>
					{content}
					{input && (
						<FormGroup className="mt-2">
							<Input
								type="textarea"
								rows={5}
								id="change-text-causes"
								name="changeText"
								placeholder="Wprowadź treść zmian"
								defaultValue="aktualizacja treści"
								value={changeText}
								onChange={this.handleChangeText}
							/>
						</FormGroup>
					)}
				</Fragment>
			</SweetAlert>
		);
	}
}

ConfirmModal.propTypes = {
	open: PropTypes.bool,
	input: PropTypes.bool,
	title: PropTypes.string,
	content: PropTypes.string,
	onCancel: PropTypes.func,
	onSubmit: PropTypes.func
};
