import { takeEvery, fork, put } from 'redux-saga/effects';
import { AppImagesSagas } from './setting/app-images';
import { BannerSagas } from './setting/banner';
import { BookmarkSagas } from './setting/bookmark';
import { FooterSagas } from './setting/footer';
import { HeaderSagas } from './setting/header';
import { LanguageSagas } from './setting/language';
import { SystemParameterSagas } from './setting/system-parameters';
import { SettingActions } from '../actions/settings';
import { RssChannelSagas } from './setting/rss-channel';
import { TileSagas } from './setting/tile';
import { OfficeContactSagas } from './setting/office-contacts';
import { RepositorySagas } from './setting/repository';

function* watchUnloadSetting() {
	yield takeEvery(SettingActions.UnloadSettingAction.Request, unloadSetting);
}

function* unloadSetting() {
	yield put({
		type: SettingActions.UnloadSettingAction.Success
	});
}

export const SettingSaga = [
	fork(watchUnloadSetting),
	...AppImagesSagas,
	...BannerSagas,
	...BookmarkSagas,
	...FooterSagas,
	...HeaderSagas,
	...LanguageSagas,
	...RssChannelSagas,
	...SystemParameterSagas,
	...TileSagas,
	...OfficeContactSagas,
	...RepositorySagas
];
