import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import Flag from "react-flagkit";
import isEmpty from "lodash.isempty";

import {
  ArticleTemplate,
  ArticleStatus,
  ArticleType,
} from "../../../../../helpers/enums";
import { PermissionName } from "../../../../../helpers/permissionNames";
import ChooseArticleStatus from "./../Choose-article-status";
import { TinyMCE } from "../../../../../components/TinyMCE";
import { CONSTANTS } from "@constants";
import { AVAILABLE_ISO_CODE } from "../../../../../helpers/isoCode";
import { ArticleModalContent } from "./ArticleModalContent";

const {
  ARTICLE,
  CONTENT,
  COMMON_INFORMATION,
  TITLE_ARTICLES,
  TITLE_FROM_FOOTER_ARTICLES,
  UPDATE_TITLE_IN_FOOTER,
  SHORT_INFORMATION,
  SHORT_DESCRIPTION_ARTICLE,
  SHEET_ARTICLE,
  EDIT_EXTRA_CONTENT,
  ADD_EXTRA_CONTENT,
} = CONSTANTS;
const HIDDEN_FOR_TYPES = [
  ArticleType.TransmissionFromRadniInfo.value,
  ArticleType.ClientData.value,
];

export const CommonCard = ({
  articleType = ArticleType.Common.value,
  formikArticle = {},
  articleStatuses = [],
  userPermissions = [],
  isArticleEditable = false,
  changeArticleStatus = Function.prototype,
  isValid = false,
  dirty = false,
  values = {},
  errors = {},
  handleChange = Function.prototype,
  setFieldValue = Function.prototype,
  maxLengthExtraContent,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    id,
    status,
    language,
    title,
    titleFromFooter,
    updateTitleInFooter,
    isExistsIntoFooterInformation,
    shortDescription,
    translations,
    content,
    extraContent,
    baseArticleType,
    baseArticleTemplate,
  } = values;

  const publishedArticleValue = 40;
  const extraContentButtonText = values?.extraContent
    ? EDIT_EXTRA_CONTENT
    : ADD_EXTRA_CONTENT;

  const {
    title: eTitle,
    titleFromFooter: eTitleFromFooter,
    baseArticleTemplate: eBaseArticleTemplate,
    shortDescription: eShortDescription,
  } = errors;

  const articleOnConfirmedStatus =
    status >= ArticleStatus.Confirmed.value ||
    !isArticleEditable ||
    !userPermissions.includes(PermissionName.CreateArticles);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Card className="mb-2">
      <CardHeader>
        <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
        {ARTICLE}
        {AVAILABLE_ISO_CODE.includes(language.isoCode) && (
          <Flag country={language.isoCode} className="ml-2 opacity-8" />
        )}
        {!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
          language.isoCode !== "" &&
          language.isoCodeFile !== null && (
            <img
              className={`ml-2 opacity-8`}
              src={language.isoCodeFile.path}
              alt={`Grafika ${language.isoCodeFile.fileName}`}
            />
          )}
      </CardHeader>
      <CardBody>
        <Row className="article__status">
          <Col style={{ flexGrow: 0, flexBasis: "15%" }}>
            <CardTitle>{COMMON_INFORMATION}</CardTitle>
          </Col>
          <Col>
            <ChooseArticleStatus
              statuses={articleStatuses}
              actualStatus={status}
              onChange={(status, userChangeText) => {
                changeArticleStatus({ ...values, userChangeText }, status);
              }}
              disabled={!isValid || !isArticleEditable}
              isDirty={dirty}
              userPermissions={userPermissions}
              articleId={id}
              isSystem={baseArticleType !== ArticleType.Common.value}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="title">{TITLE_ARTICLES}</Label>
              <Input
                type="text"
                id="title"
                name="title"
                value={title}
                onChange={handleChange}
                invalid={!!eTitle && !title}
                disabled={articleOnConfirmedStatus}
              />
              <FormFeedback>{eTitle}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {baseArticleType !== ArticleType.Common.value &&
          userPermissions.includes(PermissionName.ManageSettings) &&
          (formikArticle.title !== title ||
            formikArticle.updateTitleInFooter !== updateTitleInFooter) &&
          isExistsIntoFooterInformation && (
            <Row>
              <Col>
                <FormGroup check className="mb-2">
                  <Label check>
                    <Input
                      id={`updateTitleInFooter`}
                      type="checkbox"
                      name={`updateTitleInFooter`}
                      value={updateTitleInFooter}
                      checked={updateTitleInFooter}
                      onChange={handleChange}
                      title={UPDATE_TITLE_IN_FOOTER}
                    />
                    {UPDATE_TITLE_IN_FOOTER}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          )}
        {baseArticleType !== ArticleType.Common.value &&
          userPermissions.includes(PermissionName.ManageSettings) &&
          isExistsIntoFooterInformation && (
            <Row>
              <Col>
                <FormGroup>
                  <Label for="titleFromFooter">
                    {TITLE_FROM_FOOTER_ARTICLES}
                  </Label>
                  <Input
                    type="text"
                    id="titleFromFooter"
                    name="titleFromFooter"
                    value={titleFromFooter}
                    onChange={handleChange}
                    invalid={!!eTitleFromFooter && !titleFromFooter}
                    disabled={articleOnConfirmedStatus}
                    readOnly
                  />
                  <FormFeedback>{eTitleFromFooter}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          )}
        <Row>
          <Col>
            <FormGroup>
              <Label for="shortDescription">{SHORT_DESCRIPTION_ARTICLE}</Label>
              <div>
                <small>({SHORT_INFORMATION})</small>
              </div>
              <Input
                type="textarea"
                rows={3}
                id="shortDescription"
                name="shortDescription"
                value={shortDescription}
                onChange={handleChange}
                invalid={!!eShortDescription}
                disabled={articleOnConfirmedStatus}
              />
              <FormFeedback>{eShortDescription}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {false && (
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="baseArticleTemplate">{SHEET_ARTICLE}</Label>
                <Input
                  type="select"
                  id="baseArticleTemplate"
                  name="baseArticleTemplate"
                  value={baseArticleTemplate}
                  onChange={handleChange}
                  invalid={!!eBaseArticleTemplate}
                  disabled={!isEmpty(translations) && translations.length > 1}
                >
                  <option
                    key={`type-${ArticleTemplate.NotSetted.value}`}
                    value={ArticleTemplate.NotSetted.value}
                  >
                    {ArticleTemplate.NotSetted.name}
                  </option>
                  <option
                    key={`type-${ArticleTemplate.Text.value}`}
                    value={ArticleTemplate.Text.value}
                  >
                    {ArticleTemplate.Text.name}
                  </option>
                  <option
                    key={`type-${ArticleTemplate.Tender.value}`}
                    value={ArticleTemplate.Tender.value}
                  >
                    {ArticleTemplate.Tender.name}
                  </option>
                </Input>
                <FormFeedback />
              </FormGroup>
            </Col>
          </Row>
        )}
        {!HIDDEN_FOR_TYPES.includes(articleType) && (
          <>
            {!values.addedExtraContent && status === publishedArticleValue ? (
              <div className="d-flex justify-content-end">
                <Button
                  type="button"
                  title={extraContentButtonText}
                  onClick={() => toggle()}
                  color={"success"}
                >
                  {extraContentButtonText}
                </Button>
              </div>
            ) : null}
            <CardTitle>{CONTENT}</CardTitle>
            <Row>
              <Col>
                <div className="article-content">
                  <TinyMCE
                    id="content"
                    name="content"
                    config={{ height: 600 }}
                    plugins={
                      "advlist code anchor autolink charmap codesample directionality fullpage fullscreen help hr image imagetools importcss insertdatetime link lists nonbreaking noneditable quickbars pagebreak paste print preview searchreplace table textpattern toc visualblocks visualchars wordcount"
                    }
                    content={content}
                    onContentChanged={(content) => {
                      setFieldValue("content", content);
                    }}
                    disabled={articleOnConfirmedStatus}
                  />
                  {values?.extraContent?.length ? (
                    <TinyMCE
                      id="extraContent"
                      name="extraContent"
                      config={{ height: 600 }}
                      content={extraContent}
                      disabled={true}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
      <ArticleModalContent
        {...{
          isOpen,
          toggle,
          extraContent,
          setFieldValue,
          maxLengthExtraContent,
        }}
      />
    </Card>
  );
};
