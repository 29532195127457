import React from 'react';
import { Col, FormGroup, Label, Input, Card, CardHeader, CardBody, CustomInput } from 'reactstrap';
import { MenuConfiguration } from '../../../../../helpers/enums';

export const MenuConfigurationCard = ({
	maxElements = MenuConfiguration.Infinity,
	maxLevels = MenuConfiguration.Infinity,
	title = ''
}) => (
	<Card className="mb-2">
		<CardHeader>
			<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
			{title}
		</CardHeader>
		<CardBody>
			<FormGroup row>
				<Label for="maxElements" sm={6}>
					Maksymalna liczba elementów na pierwszym poziomie
				</Label>
				<Col sm={2} className="pr-1">
					<Input
						type="number"
						id="maxElements"
						name="maxElements"
						value={maxElements === MenuConfiguration.Infinity ? '' : maxElements}
						disabled
					/>
				</Col>
				<Col sm={2} className="p-0 d-flex align-items-center">
					<CustomInput
						type="checkbox"
						id="maxElements-checkbox"
						label="bez ograniczeń"
						checked={maxElements === MenuConfiguration.Infinity}
						inline
						disabled
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for="maxLevels" sm={6}>
					Maksymalna liczba poziomów zagłębienia
				</Label>
				<Col sm={2} className="pr-1">
					<Input
						type="number"
						id="maxLevels"
						name="maxLevels"
						value={maxLevels === MenuConfiguration.Infinity ? '' : maxLevels}
						disabled
					/>
				</Col>
				<Col sm={2} className="p-0 d-flex align-items-center">
					<CustomInput
						type="checkbox"
						id="maxLevels-checkbox"
						label="bez ograniczeń"
						checked={maxLevels === MenuConfiguration.Infinity}
						inline
						disabled
					/>
				</Col>
			</FormGroup>
		</CardBody>
	</Card>
);
