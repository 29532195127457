import { createAction } from '../base';

const LoadRssChannels = createAction('LoadRssChannels');
const GetRssChannel = createAction('GetRssChannel');
const CreateRssChannel = createAction('CreateRssChannel');
const UpdateRssChannel = createAction('UpdateRssChannel');
const DeleteRssChannels = createAction('DeleteRssChannels');

function loadRssChannels(lang = '', page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: LoadRssChannels.Request,
		payload: { lang, page, size, filters, sortings }
	};
}
function getRssChannel(id) {
	return {
		type: GetRssChannel.Request,
		payload: id
	};
}
function createRssChannel(rssChannel) {
	return {
		type: CreateRssChannel.Request,
		payload: rssChannel
	};
}
function updateRssChannel(rssChannel) {
	return {
		type: UpdateRssChannel.Request,
		payload: rssChannel
	};
}
function deleteRssChannels(rssChannelIds = []) {
	return {
		type: DeleteRssChannels.Request,
		payload: rssChannelIds
	};
}

export const RssChannelActions = {
	LoadRssChannels,
	GetRssChannel,
	CreateRssChannel,
	UpdateRssChannel,
	DeleteRssChannels
};

export const RssChannelActionCreators = {
	loadRssChannels,
	getRssChannel,
	createRssChannel,
	updateRssChannel,
	deleteRssChannels
};
