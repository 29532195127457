import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';

import { DictionaryActions } from '../../actions/dictionary';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const DICTIONARY_ENTRY_URL = '/api/dictionaries/dictionary-entries';

function* createDictionaryEntry(action) {
	try {
		let dictionaryEntry = yield call(createDictionaryEntryRequest, action.payload.dictionaryEntry);

		yield put({
			type: DictionaryActions.CreateDictionaryEntryAction.Success,
			payload: dictionaryEntry
		});

		if (action.payload.changeUrl) {
			yield put(replace(RouteUrls.Dictionary.dictionaryEntry.editFunc(dictionaryEntry.id)));
		}
		if (action.payload.withNotification) {
			toast.success('Nowy element został utworzony');
		}
	} catch (err) {
		yield put({
			type: DictionaryActions.CreateDictionaryEntryAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie tworzenia nowego elementu');
	}
}

function createDictionaryEntryRequest(dictionaryEntry) {
	return axios.post(`${DICTIONARY_ENTRY_URL}`, dictionaryEntry).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* getDictionaryEntry(action) {
	try {
		let dictionaryEntry = yield call(getDictionaryEntryRequest, action.payload);

		yield put({
			type: DictionaryActions.GetDictionaryEntryAction.Success,
			payload: dictionaryEntry
		});
	} catch (err) {
		yield put({
			type: DictionaryActions.GetDictionaryEntryAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie pobierania elementu');
	}
}

function getDictionaryEntryRequest(dictionaryEntryId) {
	return axios.get(`${DICTIONARY_ENTRY_URL}/${dictionaryEntryId}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* getAllDictionaryEntries(action) {
	try {
		let dictionaryEntries = yield call(getListRequest, {
			url: DICTIONARY_ENTRY_URL,
			lang: action.payload.lang,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: DictionaryActions.GetAllDictionaryEntriesAction.Success,
			payload: dictionaryEntries
		});
	} catch (err) {
		yield put({
			type: DictionaryActions.GetAllDictionaryEntriesAction.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy elementów');
	}
}

function* updateDictionaryEntry(action) {
	try {
		let dictionaryEntry = yield call(updateDictionaryEntryRequest, action.payload.id, action.payload);

		yield put({
			type: DictionaryActions.UpdateDictionaryEntryAction.Success,
			payload: dictionaryEntry
		});
		toast.success('Element został zaktualizowany');
	} catch (err) {
		yield put({
			type: DictionaryActions.UpdateDictionaryEntryAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie aktualizacji elementu');
	}
}

function updateDictionaryEntryRequest(id, dictionaryEntry) {
	return axios
		.put(`${DICTIONARY_ENTRY_URL}/${id}`, dictionaryEntry)
		.then((response) => response.data)
		.catch((err) => {
			throw err;
		});
}

function* removeDictionaryEntries(action) {
	try {
		yield call(removeDictionaryEntriesRequest, action.payload);

		yield put({
			type: DictionaryActions.RemoveDictionaryEntryAction.Success,
			payload: action.payload
		});
		toast.success('Elementy zostały usunięte');
	} catch (err) {
		yield put({
			type: DictionaryActions.RemoveDictionaryEntryAction.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie usuwania elementów');
	}
}

function removeDictionaryEntriesRequest(dictionaryEntryIds) {
	const filterQuery = qs.stringify({ ids: dictionaryEntryIds }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${DICTIONARY_ENTRY_URL}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchCreateDictionaryEntry() {
	yield takeEvery(DictionaryActions.CreateDictionaryEntryAction.Request, createDictionaryEntry);
}
function* watchGetDictionaryEntry() {
	yield takeEvery(DictionaryActions.GetDictionaryEntryAction.Request, getDictionaryEntry);
}
function* watchGetAllDictionaryEntries() {
	yield takeEvery(DictionaryActions.GetAllDictionaryEntriesAction.Request, getAllDictionaryEntries);
}
function* watchUpdateDictionaryEntry() {
	yield takeEvery(DictionaryActions.UpdateDictionaryEntryAction.Request, updateDictionaryEntry);
}
function* watchRemoveDictionaryEntries() {
	yield takeEvery(DictionaryActions.RemoveDictionaryEntryAction.Request, removeDictionaryEntries);
}

export const DictionaryEntrySagas = [
	fork(watchCreateDictionaryEntry),
	fork(watchGetDictionaryEntry),
	fork(watchGetAllDictionaryEntries),
	fork(watchUpdateDictionaryEntry),
	fork(watchRemoveDictionaryEntries)
];
