import React from 'react';
import { withRouter } from 'react-router-dom';
import { DragSource } from 'react-dnd';
import { DragDropType, ArticleStatus, ArticleImage } from '../../helpers/enums';
import { RouteUrls } from '../../helpers/routeUrls';

import { checkTitleLength, validTitle } from '@utils/article-title';

import { addArticles } from '@components/utils';

const source = {
	beginDrag(props) {
		return {
			node: { id: props.id, title: props.title, displayText: props.title, url: props.url, slug: props.slug }
		};
	},
	endDrag(props, monitor, component) {
		if (!monitor.didDrop()) {
			return;
		}
	}
};

const collect = (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	};
};

const getStatusText = val => {
	switch (val) {
		case ArticleStatus.Draft.value:
			return ArticleStatus.Draft.name;
		case ArticleStatus.Confirmed.value:
			return ArticleStatus.Confirmed.name;
		case ArticleStatus.ToPublish.value:
			return ArticleStatus.ToPublish.name;
		case ArticleStatus.Published.value:
			return ArticleStatus.Published.name;
		default:
			return '';
	}
};

const getImageType = (val, isDragging) => {
	switch (val) {
		case ArticleStatus.Draft.value:
			return `rst__moveHandle ${isDragging ? 'rst__moveHandle--grabbing' : ''} ${ArticleImage.Draft}`;
		case ArticleStatus.Confirmed.value:
			return `rst__moveHandle ${isDragging ? 'rst__moveHandle--grabbing' : ''} ${ArticleImage.Confirmed}`;
		case ArticleStatus.ToPublish.value:
			return `rst__moveHandle ${isDragging ? 'rst__moveHandle--grabbing' : ''} ${ArticleImage.ToPublish}`;
		case ArticleStatus.Published.value:
			return `rst__moveHandle ${isDragging ? 'rst__moveHandle--grabbing' : ''} ${ArticleImage.Published}`;
		case ArticleStatus.Withdrawed.value:
			return `rst__moveHandle ${isDragging ? 'rst__moveHandle--grabbing' : ''} ${ArticleImage.Withdrawed}`;
		case ArticleStatus.Archived.value:
			return `rst__moveHandle ${isDragging ? 'rst__moveHandle--grabbing' : ''} ${ArticleImage.Archived}`;
		default:
			return '';
	}
};

const ArticleSource = ({
	id,
	version,
	title,
	isDragging,
	connectDragSource,
	showButtons = false,
	status = ArticleStatus.Draft.value,
	slug,
	url,
	onChangeItems,
	searchCategory,
	type,
	items,
	articlesMenu,
	...props
}) => {
	return connectDragSource(
		<div
			className="ReactVirtualized__Grid__innerScrollContainer"
			role="rowgroup"
			style={{
				width: 'auto',
				height: '80px',
				maxHeight: '80px',
				overflow: 'hidden',
				position: 'relative'
			}}
		>
			<div
				className="rst__node"
				style={{ height: '80px', left: '0px', position: 'absolute', top: '0px', width: '100%' }}
			>
				<div className="rst__lineBlock rst__lineHalfHorizontalRight" style={{ width: '44px' }} />
				<div className="rst__nodeContent">
					<div className="" draggable="true" style={{ height: '100%' }}>
						<div className="rst__rowWrapper">
							<div className="rst__row" style={{ opacity: 1 }}>
								<div
									className={getImageType(status, isDragging)}
									onDoubleClick={() =>
										addArticles(
											type,
											id,
											title,
											url,
											slug,
											items,
											onChangeItems,
											articlesMenu,
											searchCategory
										)}
								/>
								<div className="rst__rowContents">
									<div className="rst__rowLabel">
										<span
											className="rst__rowTitleWithSubtitle"
											title={checkTitleLength(title) ? title : null}
										>
											{checkTitleLength(title) ? validTitle(title) : title} (wer. {version})
										</span>
										<span className="rst__rowSubtitle">{getStatusText(status)}</span>
									</div>
									{showButtons && (
										<div className="rst__rowToolbar">
											<div className="rst__buttons mr-1">
												<div className="rst__toolbarButton">
													<button
														type="button"
														className="border-0 btn-transition btn btn-outline-succes"
														onClick={() =>
															props.history.push(RouteUrls.Article.common.editFunc(id))}
														title="Przejdź do edycji artykułu"
													>
														<i className="pe-7s-next-2 fsize-2 btn-icon-wrapper"> </i>
													</button>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default withRouter(DragSource(DragDropType.Article, source, collect)(ArticleSource));
