import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware, { END } from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import Immutable from 'seamless-immutable';
import reducers from '../reducers';
import { reducer as oidc } from '@axa-fr/react-oidc-redux';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
export const history = createBrowserHistory({ basename: baseUrl });

export default function configureStore(preloadedState = Immutable({})) {
	// Build middleware. These are functions that can process the actions before they reach the store.
	const windowIfDefined = typeof window === 'undefined' ? null : window;
	// If devTools is installed, connect to it
	const devToolsExtension = process.env.NODE_ENV !== 'production' ? windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ : null;
	const sagaMiddleware = createSagaMiddleware();
	const createStoreWithMiddleware = compose(
		applyMiddleware(sagaMiddleware, routerMiddleware(history)),
		devToolsExtension ? devToolsExtension() : (next) => next
	)(createStore);

	// Combine all reducers and instantiate the app-wide store instance
	const allReducers = buildRootReducer({ ...reducers, oidc }, history);
	const store = createStoreWithMiddleware(allReducers, preloadedState);
	store.runSaga = sagaMiddleware.run;
	store.close = () => store.dispatch(END);

	// Hot reloading
	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers', () => {
			const newReducers = require('../reducers');
			store.replaceReducer(buildRootReducer(newReducers, history));
		});
	}

	return store;
}

const buildRootReducer = (anyReducers, history) => {
	return combineReducers({ router: connectRouter(history), ...anyReducers });
};
