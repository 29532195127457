import * as yup from 'yup';

function equalTo(ref, msg) {
	return this.test({
		name: 'equalTo',
		exclusive: false,
		message: msg || '${path} musi być identyczne jak ${reference}',
		params: {
			reference: ref.path
		},
		test: function(value) {
			return value === this.resolve(ref);
		}
	});
}

yup.addMethod(yup.string, 'equalTo', equalTo);

export function peselValidator(pesel) {
	if (pesel) {
		var reg = /^[0-9]{11}$/;
		if (reg.test(pesel) === false) {
			return false;
		}

		var digits = ('' + pesel).split('');
		if (parseInt(pesel.substring(4, 6)) > 31) {
			return false
		}

		var checksum =
			(1 * parseInt(digits[0]) +
				3 * parseInt(digits[1]) +
				7 * parseInt(digits[2]) +
				9 * parseInt(digits[3]) +
				1 * parseInt(digits[4]) +
				3 * parseInt(digits[5]) +
				7 * parseInt(digits[6]) +
				9 * parseInt(digits[7]) +
				1 * parseInt(digits[8]) +
				3 * parseInt(digits[9]) +
				1 * parseInt(digits[10]));

		return checksum % 10 === 0;
	}
}

export function nipValidator(nip) {
	if (nip) {
		var nipWithoutDashes = nip.replace(/-/g, '');
		var reg = /^[0-9]{10}$/;
		if (reg.test(nipWithoutDashes) === false) {
			return false;
		}
		var digits = ('' + nipWithoutDashes).split('');
		var checksum =
			(6 * parseInt(digits[0]) +
				5 * parseInt(digits[1]) +
				7 * parseInt(digits[2]) +
				2 * parseInt(digits[3]) +
				3 * parseInt(digits[4]) +
				4 * parseInt(digits[5]) +
				5 * parseInt(digits[6]) +
				6 * parseInt(digits[7]) +
				7 * parseInt(digits[8])) %
			11;

		return parseInt(digits[9]) === checksum;
	}
	return false;
}

export function regon9Validator(regon) {
	if (regon) {
		var reg = /^[0-9]{9}$/;
		if (!reg.test(regon)) {
			return false;
		}
		var digits = ('' + regon).split('');
		var checksum =
			(8 * parseInt(digits[0]) +
				9 * parseInt(digits[1]) +
				2 * parseInt(digits[2]) +
				3 * parseInt(digits[3]) +
				4 * parseInt(digits[4]) +
				5 * parseInt(digits[5]) +
				6 * parseInt(digits[6]) +
				7 * parseInt(digits[7])) %
			11;
		if (checksum === 10) checksum = 0;

		return parseInt(digits[8]) === checksum;
	}
	return false;
}
export function regon14Validator(regon) {
	if (regon) {
	  var reg = /^[0-9]{14}$/;
	  if (!reg.test(regon)) {
		return false;
	  }
	  var digits = ('' + regon).split('');
	  var checksum =
		(2 * parseInt(digits[0]) +
		  4 * parseInt(digits[1]) +
		  8 * parseInt(digits[2]) +
		  5 * parseInt(digits[3]) +
		  0 * parseInt(digits[4]) +
		  9 * parseInt(digits[5]) +
		  7 * parseInt(digits[6]) +
		  3 * parseInt(digits[7]) +
		  6 * parseInt(digits[8]) +
		  1 * parseInt(digits[9]) +
		  2 * parseInt(digits[10]) +
		  4 * parseInt(digits[11]) +
		  8 * parseInt(digits[12])) %
		11;

	  if (checksum === 10) checksum = 0;

	  return parseInt(digits[13]) === checksum;
	}
	return false;
  }