import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { Formik } from "formik";
import Immutable from "seamless-immutable";
import { format } from "date-fns";
import isEmpty from "lodash.isempty";
import filter from "lodash.filter";
import uuid from "uuid/v4";
import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";

import classnames from "classnames";
import TreeViewNodeNotDragable from "../../../../components/TreeViewNodes/TreeViewNodeNotDragable";
import {
  DefaultFormikConfig,
  DragDropType,
  MenuConfiguration,
} from "../../../../helpers/enums";
import RSC from "react-scrollbars-custom";
import {
  Form,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Label,
  Input,
  InputGroup,
  Button,
  InputGroupAddon,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { pl } from "date-fns/locale";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import Sticky from "react-stickynode";
import isEqual from "lodash.isequal";
import PageTitle from "../../../../Layout/AppMain/PageTitle";
import { UserActionCreators } from "../../../../actions/auth/user";
import { RoleActionCreators } from "../../../../actions/auth/role";
import { AuthActionCreators } from "../../../../actions/auth";
import { MenuActionCreators } from "../../../../actions/menu";
import { LoaderType, Sex, PageSize, MenuType } from "../../../../helpers/enums";
import { getSex, getBirthDate } from "../../../../utils/user-data";
import { IdentificationDocumentType } from "../../../../helpers/enums";
import { UserValidationSchema } from "../../../../validators/user";
import { Typeahead } from "react-bootstrap-typeahead";
import remove from "lodash.remove";
import ConfirmModal from "../../../../components/ConfirmModal";
import { getAdminRoleId } from "../../../../reselect/role";
import { getTreeFromFlatData } from "../../../../utils/tree-data-utils";

import { CONSTANTS } from "@constants";
import { PermissionName } from "../../../../helpers/permissionNames";

const {
  SAVE,
  BACK,
  ACTIVATE_ACCOUNT,
  COMMON_INFORMATION,
  DEACTIVATE_ACCOUNT,
  RESET_PASSWORD,
  LOGIN,
  FIRST_NAME,
  SURNAME,
  IDENTIFICATION_METHOD,
  BIRTHDAY_DATE,
  PESEL,
  PESEL_ID,
  CONTACT_DETAILS,
  TELEPHONE,
  PHONE,
  EMAIL,
  ROLES_LIST,
  CATEGORY_PERMISSION,
  HORIZONTAL_MENU,
  VERTICAL_MENU,
} = CONSTANTS;

registerLocale("pl", pl);
setDefaultLocale("pl");

class UserCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: getTreeFromFlatData({
        flatData: props.treeData,
        getKey: (element) => element.elementId,
        getParentKey: (element) => element.parentElementId,
        rootKey: null,
      }),
      categoryActiveTab: 1,
      showConfirmModal: false,
      currentUser: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { isAdmin } = this.props;
    if (id) {
      this.props.getUser(id);
    }
    if (parseInt(id) || !id) {
      this.props.loadRoles(0, PageSize.All);
    }

    if (isAdmin) {
      this.props.getMenu(MenuType.Horizontal);
      this.props.getMenuElementIds();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { id: prevId } = prevProps.match.params;
    const { id } = this.props.match.params;
    const { isAdmin } = this.props;
    if (prevId && id && prevId !== id) {
      this.props.getUser(id);
    }

    if (!isEqual(prevProps.treeData, this.props.treeData)) {
      this.setState({
        treeData: getTreeFromFlatData({
          flatData: this.props.treeData,
          getKey: (element) => element.elementId,
          getParentKey: (element) => element.parentElementId,
          rootKey: null,
        }),
      });
    }
    if (prevProps.isAdmin !== isAdmin) {
      this.props.getMenu(MenuType.Horizontal);
      this.props.getMenuElementIds();
    }

    if (!isEqual(prevState.currentUser, this.props.user)) {
      this.setState({
        currentUser: this.props.user,
      });
    }
  }

  componentWillUnmount() {
    this.props.unloadAuth();
  }

  toggleModalOpen = (name, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ [name]: !this.state[name] });
  };

  handleOnChangeLogin = (setValues, oldValues, login) =>
    setValues({
      ...oldValues,
      userName: login,
      identificationDocumentNumber: login,
    });

  handleOnChangePESEL = (setValues, oldValues, pesel) => {
    let newValues = { ...oldValues };
    if (!isEmpty(pesel) && pesel.length === 11) {
      newValues = {
        ...newValues,
        birthDate: format(new Date(getBirthDate(pesel)), "yyyy-MM-dd HH:mm:ss"),
        sex: getSex(pesel),
      };
    }
    newValues.pesel = pesel;
    setValues(newValues);
  };

  handleOnChangeBirthDate = (setFieldValue, date) =>
    setFieldValue("birthDate", format(new Date(date), "yyyy-MM-dd HH:mm:ss"));

  handleOnIdentificationDocumentType = (
    setValues,
    user,
    identificationDocumentType
  ) => {
    if (identificationDocumentType === IdentificationDocumentType.PESEL.value) {
      if (!isEmpty(user.pesel) && user.pesel.length !== 11) {
        setValues({
          ...user,
          identificationDocumentType: IdentificationDocumentType.PESEL.value,
          sex: Sex.Unknown.value,
          identificationDocumentNumber: user.pesel,
        });
      } else if (!isEmpty(user.pesel) && user.pesel.length === 11) {
        setValues({
          ...user,
          identificationDocumentType: IdentificationDocumentType.PESEL.value,
          sex: getSex(user.pesel),
          birthDate: format(
            new Date(getBirthDate(user.pesel)),
            "yyyy-MM-dd HH:mm:ss"
          ),
          identificationDocumentNumber: user.pesel,
        });
      } else {
        setValues({
          ...user,
          identificationDocumentType: IdentificationDocumentType.PESEL.value,
          identificationDocumentNumber: user.pesel,
        });
      }
    } else if (
      identificationDocumentType === IdentificationDocumentType.Unknown.value
    ) {
      setValues({
        ...user,
        identificationDocumentType: identificationDocumentType,
        identificationDocumentNumber: user.userName,
      });
    } else {
      setValues({
        ...user,
        identificationDocumentType: identificationDocumentType,
        identificationDocumentNumber: "",
      });
    }
  };

  updateTreeData = (treeData) => {
    this.props.updateMenuData(treeData);
    this.setState({
      treeData: treeData,
    });
  };

  changeLinkActiveTab = (activeTab) => {
    if (activeTab === 1) {
      this.props.getMenu(MenuType.Horizontal);
    } else if (activeTab === 2) {
      this.props.getMenu(MenuType.Vertical);
    }
    this.setState({
      categoryActiveTab: activeTab,
    });
  };

  handleChangeChecked = (params, excludeCategories, setFieldValue) => {
    const { sub, ids, checked } = params;
    let newExcludeCategories = JSON.parse(excludeCategories);

    if (sub) {
      if (!checked) {
        remove(newExcludeCategories, (i) => ids.includes(i));
      } else {
        newExcludeCategories = [...newExcludeCategories, ...ids];
      }
    } else {
      if (newExcludeCategories.includes(ids)) {
        remove(newExcludeCategories, (i) => i === ids);
      } else {
        newExcludeCategories.push(ids);
      }
    }

    return setFieldValue(
      "excludeCategories",
      JSON.stringify(newExcludeCategories)
    );
  };

  render() {
    // !!! zamiana excludeCategories z tablicy int na string spowodowana jest przez Formik'a, który ma płytkie porównanie !!!!
    const {
      user,
      createUser,
      updateUser,
      activeUser,
      deactiveUser,
      resetPassword,
      availableRoles,
      userCardBlocking,
      cancel,
      verticalMenuConfiguration,
      horizontalMenuConfiguration,
      menuCardBlocking,
      isAdmin,
      adminRoleId,
      menuElementIds,
      isIntegratedWithASW,
      permissions,
      currentUserId,
    } = this.props;

    const { treeData, categoryActiveTab, showConfirmModal } = this.state;
    const newUser = Immutable({
      ...user,
      excludeCategories: JSON.stringify(user.excludeCategories),
    }).asMutable({
      deep: true,
    });

    const accessToUsers = permissions.some(
      (permission) => permission.displayName === PermissionName.ManageUsers
    );

    const currentValidateOnMount = !isEqual(
      this.state.currentUser,
      this.props.user
    );

    return (
      <Fragment>
        <Formik
          {...DefaultFormikConfig}
          validateOnMount={currentValidateOnMount}
          initialValues={newUser}
          validationSchema={UserValidationSchema}
          onSubmit={(values) => {
            if (values.id > 0) {
              updateUser({
                ...values,
                excludeCategories: JSON.parse(values.excludeCategories),
              });
            } else {
              createUser({
                ...values,
                excludeCategories: JSON.parse(values.excludeCategories),
              });
            }
          }}
          onReset={cancel}
        >
          {({
            errors,
            values,
            handleChange,
            isValid,
            dirty,
            setFieldValue,
            setValues,
            handleSubmit,
            handleReset,
          }) => (
            <Fragment>
              <PageTitle
                heading="Użytkownik"
                icon="pe-7s-users icon-gradient bg-tempting-azure"
              />
              <ConfirmModal
                open={showConfirmModal}
                onSubmit={(_) => {
                  this.toggleModalOpen("showConfirmModal");
                  resetPassword(values.identityUserId);
                }}
                onCancel={(_) => this.toggleModalOpen("showConfirmModal")}
                title="Reset hasła"
                content={`Czy na pewno zresetować hasło użytkownika?`}
              />
              <BlockUi
                tag="div"
                blocking={userCardBlocking}
                loader={<Loader active type={LoaderType} />}
              >
                <Form
                  noValidate
                  method="post"
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                >
                  <Sticky
                    enabled={true}
                    top=".app-header"
                    innerZ="101"
                    activeClass="sticky-active-class"
                    className="mb-2"
                  >
                    <div className="d-flex justify-content-end">
                      {!isIntegratedWithASW &&
                        values.isConfirmed &&
                        !values.isActive &&
                        parseInt(currentUserId) !== values.id &&
                        accessToUsers && (
                          <Button
                            className="btn-icon mr-2"
                            color="success"
                            type="button"
                            disabled={!isValid}
                            onClick={(_) => activeUser(values.id)}
                          >
                            <i className="pe-7s-check btn-icon-wrapper" />
                            {ACTIVATE_ACCOUNT}
                          </Button>
                        )}
                      {!isIntegratedWithASW &&
                        values.isConfirmed &&
                        values.isActive &&
                        parseInt(currentUserId) !== values.id &&
                        accessToUsers && (
                          <Button
                            className="btn-icon mr-2"
                            color="danger"
                            type="button"
                            // disabled={!isValid}
                            onClick={(_) => deactiveUser(values.id)}
                          >
                            <i className="pe-7s-close-circle btn-icon-wrapper" />
                            {DEACTIVATE_ACCOUNT}
                          </Button>
                        )}
                      {!isIntegratedWithASW &&
                        values.isConfirmed &&
                        values.isActive &&
                        parseInt(currentUserId) !== values.id &&
                        accessToUsers && (
                          <Button
                            className="btn-icon mr-2"
                            color="warning"
                            type="button"
                            // disabled={!isValid}
                            onClick={(_) =>
                              this.toggleModalOpen("showConfirmModal")
                            }
                          >
                            <i className="pe-7s-refresh btn-icon-wrapper" />
                            {RESET_PASSWORD}
                          </Button>
                        )}
                      {accessToUsers ? (
                        <Button
                          className="btn-icon mr-2"
                          color="primary"
                          type="submit"
                          disabled={
                            !isValid ||
                            !dirty ||
                            (parseInt(currentUserId) === values.id && !isAdmin)
                          }
                        >
                          <i className="pe-7s-diskette btn-icon-wrapper" />
                          {SAVE}
                        </Button>
                      ) : null}
                      <Button
                        className="btn-icon mr-2"
                        color="secondary"
                        type="reset"
                      >
                        <i className="pe-7s-back btn-icon-wrapper" />
                        {BACK}
                      </Button>
                    </div>
                  </Sticky>
                  <Fragment>
                    <Card className="mb-3">
                      <CardHeader>
                        <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
                        {COMMON_INFORMATION}
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="userName">{LOGIN}</Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="userName"
                                name="userName"
                                placeholder="Wpisz login"
                                value={values.userName}
                                onChange={(e) =>
                                  this.handleOnChangeLogin(
                                    setValues,
                                    values,
                                    e.target.value
                                  )
                                }
                                invalid={!!errors.userName}
                                disabled={values.id > 0 || !accessToUsers}
                              />
                              <FormFeedback>{errors.userName}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="firstname">{FIRST_NAME}</Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="firstname"
                                name="firstname"
                                placeholder="Wpisz imię"
                                value={values.firstname}
                                onChange={handleChange}
                                invalid={!!errors.firstname}
                                disabled={isIntegratedWithASW || !accessToUsers}
                              />
                              <FormFeedback>{errors.firstname}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="surname">{SURNAME}</Label>
                              <Input
                                type="text"
                                id="surname"
                                name="surname"
                                placeholder="Wpisz nazwisko"
                                value={values.surname}
                                onChange={handleChange}
                                invalid={!!errors.surname}
                                disabled={isIntegratedWithASW || !accessToUsers}
                              />
                              <FormFeedback>{errors.surname}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="identificationDocumentType">
                                {IDENTIFICATION_METHOD}
                              </Label>
                              <Input
                                type="select"
                                id="identificationDocumentType"
                                name="identificationDocumentType"
                                placeholder="Wybierz rodzaj dokumentu tożsamości"
                                value={values.identificationDocumentType}
                                onChange={(e) =>
                                  this.handleOnIdentificationDocumentType(
                                    setValues,
                                    values,
                                    parseInt(e.target.value)
                                  )
                                }
                                invalid={!!errors.identificationDocumentType}
                                disabled={isIntegratedWithASW || !accessToUsers}
                              >
                                <option
                                  value={
                                    IdentificationDocumentType.Unknown.value
                                  }
                                >
                                  {IdentificationDocumentType.Unknown.name}
                                </option>
                                <option
                                  value={IdentificationDocumentType.PESEL.value}
                                >
                                  {IdentificationDocumentType.PESEL.name}
                                </option>
                                <option
                                  value={IdentificationDocumentType.ID.value}
                                >
                                  {IdentificationDocumentType.ID.name}
                                </option>
                                <option
                                  value={
                                    IdentificationDocumentType.Passport.value
                                  }
                                >
                                  {IdentificationDocumentType.Passport.name}
                                </option>
                                <option
                                  value={
                                    IdentificationDocumentType.PoleCard.value
                                  }
                                >
                                  {IdentificationDocumentType.PoleCard.name}
                                </option>
                                <option
                                  value={
                                    IdentificationDocumentType.MilitaryBook
                                      .value
                                  }
                                >
                                  {IdentificationDocumentType.MilitaryBook.name}
                                </option>
                                <option
                                  value={
                                    IdentificationDocumentType.SeamansBook.value
                                  }
                                >
                                  {IdentificationDocumentType.SeamansBook.name}
                                </option>
                              </Input>
                              <FormFeedback>
                                {errors.identificationDocumentType}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="birthDate">{BIRTHDAY_DATE}</Label>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <div className="input-group-text">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </div>
                                </InputGroupAddon>
                                <DatePicker
                                  id="birthDate"
                                  name="birthDate"
                                  locale="pl"
                                  dateFormat="yyyy-MM-dd"
                                  selected={
                                    values.birthDate
                                      ? new Date(values.birthDate)
                                      : undefined
                                  }
                                  className="form-control"
                                  onChange={(date) =>
                                    this.handleOnChangeBirthDate(
                                      setFieldValue,
                                      date
                                    )
                                  }
                                  invalid={!!errors.birthDate}
                                  disabled={
                                    !isEmpty(values.pesel) ||
                                    isIntegratedWithASW ||
                                    !accessToUsers
                                  }
                                />
                                <FormFeedback>{errors.birthDate}</FormFeedback>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="pesel">{PESEL}</Label>
                              <Input
                                type="text"
                                id="pesel"
                                name="pesel"
                                placeholder="Wpisz PESEL"
                                value={values.pesel}
                                onChange={(e) =>
                                  this.handleOnChangePESEL(
                                    setValues,
                                    values,
                                    e.target.value
                                  )
                                }
                                invalid={!!errors.pesel}
                                disabled={isIntegratedWithASW || !accessToUsers}
                              />
                              <FormFeedback>{errors.pesel}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            {values.identificationDocumentType !==
                              IdentificationDocumentType.PESEL.value &&
                              values.identificationDocumentType !==
                                IdentificationDocumentType.Unknown.value && (
                                <FormGroup>
                                  <Label for="identificationDocumentNumber">
                                    {PESEL_ID}
                                  </Label>
                                  <Input
                                    type="text"
                                    id="identificationDocumentNumber"
                                    name="identificationDocumentNumber"
                                    placeholder="Wpisz numer dokumentu tożsamości"
                                    value={values.identificationDocumentNumber}
                                    onChange={handleChange}
                                    invalid={
                                      !!errors.identificationDocumentNumber
                                    }
                                    disabled={
                                      values.identificationDocumentType ===
                                        IdentificationDocumentType.PESEL
                                          .value ||
                                      isIntegratedWithASW ||
                                      !accessToUsers
                                    }
                                  />
                                  <FormFeedback>
                                    {errors.identificationDocumentNumber}
                                  </FormFeedback>
                                </FormGroup>
                              )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <CustomInput
                                type="radio"
                                id="male-sex"
                                name="sex"
                                value={Sex.Male.value}
                                checked={
                                  parseInt(values.sex) === Sex.Male.value
                                }
                                onChange={handleChange}
                                inline
                                label={Sex.Male.name}
                                disabled={
                                  !isEmpty(values.pesel) ||
                                  isIntegratedWithASW ||
                                  !accessToUsers
                                }
                                invalid={!!errors.sex}
                              />
                              <CustomInput
                                type="radio"
                                id="female-sex"
                                name="sex"
                                value={Sex.Female.value}
                                checked={
                                  parseInt(values.sex) === Sex.Female.value
                                }
                                onChange={handleChange}
                                inline
                                label={Sex.Female.name}
                                disabled={
                                  !isEmpty(values.pesel) ||
                                  isIntegratedWithASW ||
                                  !accessToUsers
                                }
                                invalid={!!errors.sex}
                              />
                              <FormFeedback>{errors.sex}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mb-3">
                      <CardHeader>
                        <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />{" "}
                        {CONTACT_DETAILS}
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="phone">{TELEPHONE}</Label>
                              <Input
                                type="text"
                                id="phone"
                                name="phone"
                                placeholder="Wpisz nr telefonu"
                                value={values.phone || undefined}
                                onChange={handleChange}
                                invalid={!!errors.phone}
                                disabled={isIntegratedWithASW || !accessToUsers}
                              />
                              <FormFeedback>{errors.phone}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="mobilePhone">{PHONE}</Label>
                              <Input
                                type="text"
                                id="mobilePhone"
                                name="mobilePhone"
                                placeholder="Wpisz nr telefonu komórkowego"
                                value={values.mobilePhone}
                                onChange={handleChange}
                                invalid={!!errors.mobilePhone}
                                disabled={isIntegratedWithASW || !accessToUsers}
                              />
                              <FormFeedback>{errors.mobilePhone}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="email">{EMAIL}</Label>
                              <Input
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Wpisz e-mail"
                                value={values.email}
                                onChange={handleChange}
                                invalid={!!errors.email}
                                disabled={isIntegratedWithASW || !accessToUsers}
                              />
                              <FormFeedback>{errors.email}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="fax" className="d-none">
                                Fax
                              </Label>
                              <Input
                                className="d-none"
                                type="text"
                                id="fax"
                                name="fax"
                                placeholder="Wpisz nr faxu"
                                value={values.fax || undefined}
                                onChange={handleChange}
                                invalid={!!errors.fax}
                                disabled={isIntegratedWithASW || !accessToUsers}
                              />
                              <FormFeedback>{errors.fax}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    {(values.id === 0 ||
                      parseInt(currentUserId) !== values.id) && (
                      <Card className="mb-3">
                        <CardHeader>
                          <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
                          {ROLES_LIST}
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col>
                              <Typeahead
                                id="roles"
                                labelKey="name"
                                options={availableRoles}
                                selected={values.roles}
                                placeholder="Wybierz role"
                                emptyLabel={"Brak rol"}
                                disabled={!accessToUsers}
                                clearButton
                                multiple
                                onChange={(roles) => {
                                  if (
                                    !isEmpty(
                                      filter(
                                        values.roles,
                                        (role) => role.id === adminRoleId
                                      )
                                    ) &&
                                    isEmpty(
                                      filter(
                                        roles,
                                        (role) => role.id === adminRoleId
                                      )
                                    )
                                  ) {
                                    setValues({
                                      ...values,
                                      ...{
                                        roles: roles,
                                        excludeCategories:
                                          JSON.stringify(menuElementIds),
                                      },
                                    });
                                  } else {
                                    setFieldValue("roles", roles);
                                  }
                                }}
                              />
                              {errors.roles && (
                                <FormFeedback
                                  type="invalid"
                                  style={{ display: "block" }}
                                >
                                  {errors.roles}
                                </FormFeedback>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                    {isAdmin &&
                      isEmpty(
                        filter(values.roles, (role) => role.id === adminRoleId)
                      ) && (
                        <Card className="mb-2">
                          <CardHeader>
                            <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
                            {CATEGORY_PERMISSION}
                          </CardHeader>
                          <CardBody
                            className={`${
                              isEmpty(treeData) ? "d-flex  " : ""
                            }justify-content-center align-items-center`}
                          >
                            <CardHeader>
                              <Nav justified>
                                <NavItem>
                                  <NavLink
                                    href="javascript:void(0);"
                                    className={classnames({
                                      active: categoryActiveTab === 1,
                                    })}
                                    onClick={() => {
                                      this.changeLinkActiveTab(1);
                                    }}
                                  >
                                    <div className="d-flex align-items-baseline justify-content-center">
                                      <div className="mb-0">
                                        {HORIZONTAL_MENU}
                                      </div>
                                    </div>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    href="javascript:void(0);"
                                    className={classnames({
                                      active: categoryActiveTab === 2,
                                    })}
                                    onClick={() => {
                                      this.changeLinkActiveTab(2);
                                    }}
                                  >
                                    <div className="d-flex align-items-baseline justify-content-center">
                                      <div className="mb-0">
                                        {VERTICAL_MENU}
                                      </div>
                                    </div>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </CardHeader>
                            <BlockUi
                              tag="div"
                              blocking={menuCardBlocking}
                              loader={<Loader active type={LoaderType} />}
                            >
                              <div className="mt-4">
                                <TabContent activeTab={categoryActiveTab}>
                                  <TabPane tabId={1}>
                                    {!isEmpty(treeData) && (
                                      <div
                                        style={{
                                          height: "calc(100vh - 459px)",
                                        }}
                                      >
                                        <RSC>
                                          <SortableTree
                                            treeData={treeData}
                                            rowHeight={80}
                                            isVirtualized={false}
                                            maxDepth={
                                              horizontalMenuConfiguration.maxLevels +
                                              1
                                            }
                                            onChange={this.updateTreeData}
                                            onMoveNode={Function.prototype}
                                            dndType={DragDropType.Category}
                                            shouldCopyOnOutsideDrop={true}
                                            canDrop={false}
                                            generateNodeProps={({
                                              node,
                                              path,
                                              treeIndex,
                                            }) => {
                                              return {
                                                displayText: (
                                                  <FormGroup className="mb-0">
                                                    <Input
                                                      type="textarea"
                                                      rows="2"
                                                      className="border-0 fsize-0-8"
                                                      style={{
                                                        backgroundColor: "#fff",
                                                        maxHeight: "51px",
                                                        resize: "none",
                                                        overflow: "hidden",
                                                      }}
                                                      value={node.displayText}
                                                      title={node.displayText}
                                                      disabled={true}
                                                    />
                                                  </FormGroup>
                                                ),
                                                toggleNode: this.toggleNode,
                                                selectedPath: [],
                                                excludeCategories: JSON.parse(
                                                  values.excludeCategories
                                                ),
                                                onChangeChecked: (params) =>
                                                  this.handleChangeChecked(
                                                    params,
                                                    values.excludeCategories,
                                                    setFieldValue
                                                  ),
                                              };
                                            }}
                                            nodeContentRenderer={(props) => (
                                              <TreeViewNodeNotDragable
                                                {...props}
                                              />
                                            )}
                                          />
                                        </RSC>
                                      </div>
                                    )}
                                  </TabPane>
                                  <TabPane tabId={2}>
                                    {!isEmpty(treeData) && (
                                      <div
                                        style={{
                                          height: "calc(100vh - 459px)",
                                        }}
                                      >
                                        <RSC>
                                          <SortableTree
                                            treeData={treeData}
                                            rowHeight={80}
                                            isVirtualized={false}
                                            maxDepth={
                                              verticalMenuConfiguration.maxLevels +
                                              1
                                            }
                                            onChange={this.updateTreeData}
                                            onMoveNode={Function.prototype}
                                            dndType={DragDropType.Category}
                                            shouldCopyOnOutsideDrop={true}
                                            canDrop={false}
                                            generateNodeProps={({
                                              node,
                                              path,
                                              treeIndex,
                                            }) => {
                                              return {
                                                displayText: (
                                                  <FormGroup className="mb-0">
                                                    <Input
                                                      type="textarea"
                                                      rows="2"
                                                      className="border-0 fsize-0-8"
                                                      style={{
                                                        backgroundColor: "#fff",
                                                        maxHeight: "51px",
                                                        resize: "none",
                                                        overflow: "hidden",
                                                      }}
                                                      value={node.displayText}
                                                      title={node.displayText}
                                                      disabled={true}
                                                    />
                                                  </FormGroup>
                                                ),
                                                toggleNode: this.toggleNode,
                                                selectedPath: [],
                                                excludeCategories: JSON.parse(
                                                  values.excludeCategories
                                                ),
                                                onChangeChecked: (params) =>
                                                  this.handleChangeChecked(
                                                    params,
                                                    values.excludeCategories,
                                                    setFieldValue
                                                  ),
                                              };
                                            }}
                                            nodeContentRenderer={(props) => (
                                              <TreeViewNodeNotDragable
                                                {...props}
                                              />
                                            )}
                                          />
                                        </RSC>
                                      </div>
                                    )}
                                  </TabPane>
                                </TabContent>
                              </div>
                            </BlockUi>
                          </CardBody>
                        </Card>
                      )}
                  </Fragment>
                </Form>
              </BlockUi>
            </Fragment>
          )}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProp) => {
  return {
    permissions: state.application.permissions,
    menuCardBlocking: state.uiBlockState.menuCardBlocking,
    currentUserId: state.auth.userId,
    user: state.auth.user || {
      id: 0,
      identityUserId: uuid(),
      userName: "",
      firstname: "",
      surname: "",
      pesel: "",
      birthDate: format(new Date(1900, 0, 1), "yyyy-MM-dd HH:mm:ss"),
      identificationDocumentType: IdentificationDocumentType.Unknown.value,
      identificationDocumentNumber: "",
      sex: Sex.Unknown.value,
      phone: "",
      mobilePhone: "",
      fax: "",
      email: "",
      isActive: false,
      isConfirmed: false,
      confirmedDate: format(new Date(1900, 0, 1), "yyyy-MM-dd HH:mm:ss"),
      roles: [],
      excludeCategories: [],
    },
    verticalMenuConfiguration: state.application.menuConfiguration.vertical || {
      maxElements: 0,
      maxLevels: MenuConfiguration.Infinity,
    },
    horizontalMenuConfiguration: state.application.menuConfiguration
      .horizontal || {
      maxElements: 0,
      maxLevels: MenuConfiguration.Infinity,
    },
    treeData: state.menu.menu, //? addChecked(state.menu.menu, excludeCategories) : [],
    userCardBlocking: state.uiBlockState.userCardBlocking || false,
    availableRoles: state.auth.roles || [],
    isAdmin: state.auth.isAdmin || false,
    adminRoleId: getAdminRoleId(state),
    menuElementIds: state.menu.menuElementIds,
    isIntegratedWithASW: state.application.isIntegratedWithASW || false,
  };
};

const mapDispatchToProps = (dispatch, ownProp) => {
  return {
    ...bindActionCreators(
      {
        ...UserActionCreators,
        ...AuthActionCreators,
        ...RoleActionCreators,
        ...MenuActionCreators,
      },
      dispatch
    ),
    cancel: () => dispatch(goBack()),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
