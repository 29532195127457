import React, { memo } from "react";
import TreeList, {
  Column,
  FilterRow,
  RowDragging,
  Scrolling,
  HeaderFilter,
} from "devextreme-react/tree-list";

import "./styles.css";

export const TreeDxGrid = memo(
  ({
    keyExpr = "NEW_ID",
    parentIdExpr = "Head_ID",
    data,
    onReorder,
    onDragChange,
    columns,
    dataGridRef,
    onCellPrepared,
    onEditorPreparing,
    onDragStart,
    allowSorting,
  }) => {
    return (
      <TreeList
        ref={dataGridRef}
        dataSource={data}
        rootValue={-1}
        keyExpr={keyExpr}
        parentIdExpr={parentIdExpr}
        showRowLines={true}
        showBorders={true}
        columnAutoWidth={true}
        remoteOperations={{
          filtering: false,
          grouping: false,
          sorting: false,
        }}
        filterMode="fullBranch"
        noDataText="brak danych"
        expandNodesOnFilterin={true}
        onCellPrepared={onCellPrepared}
        onEditorPreparing={onEditorPreparing}
        allowSorting={allowSorting}
      >
        <FilterRow visible />
        <HeaderFilter visible />
        <Scrolling mode="standard" />
        <RowDragging
          onDragChange={onDragChange}
          onReorder={onReorder}
          allowReordering={true}
          allowDropInsideItem={true}
          onDragStart={(e) => onDragStart && onDragStart(e)}
        />
        {columns.map((column, index) => (
          <Column
            key={index}
            {...{
              dataField: column.dataField,
              allowHiding: true,
              allowEditing: true,
              allowFiltering: column.allowFiltering,
              allowHeaderFiltering: column.allowHeaderFiltering,
              caption: column.caption,
              format: column.format,
              inputType: column.inputType,
              width: column.width,
              alignment: column.alignment,
              dataType: column.dataType,
              cellRender: column.cellRender,
              fixed: column.fixed || false,
              allowFixing: column.fixed,
              fixedPosition: "left",
              options: column.options,
              cssClass: "custom",
              editCellComponent: column.editCellComponent,
              visible: column.visible,
              filterOperations: column.filterOperations || ["contains"],
              headerFilter: column.headerFilter,
              filterRow: column.filterRow,
            }}
          />
        ))}
      </TreeList>
    );
  }
);
