import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import { Formik } from 'formik';
import UploadFile from '../../../../../components/UploadFile';
import { DefaultFormikConfig, FileExtensionTypes, ImageData, ImageMimeType } from '../../../../../helpers/enums';
import { fileUploadError } from '../../../../../helpers/error-message';

import { CONSTANTS } from '@constants';
import { Switch } from '../../../Shared/Switch';
import { CropImageUpload } from '../../../../../components/CropImageUpload';

const { CONFIRM, CANCEL, COMMON_INFORMATION } = CONSTANTS;

export default ({
	title = 'Edycja grafiki',
	imageType = 0,
	maxFileSize = 10,
	isOpen = false,
	image = {},
	recommendedWidth = 256,
	recommendedHeight = 144,
	cropperSizes = { height: recommendedHeight, width: recommendedWidth },
	showUseImage = false,
	useImage = false,
	useGradient = false,
	changeUseImage = (useImage) => useImage,
	changeUseGradient = (useGradient) => useGradient,
	updateImage = (image) => image,
	cancel = () => {}
}) => {
	const [ rectangleSize, setRectangleSize ] = useState({ width: recommendedWidth, height: recommendedHeight });
	const dispatch = useDispatch();
	const [ cropData, setCropData ] = useState(
		image && image.cropData
			? image.cropData
			: {
					unit: 'px',
					x: 0,
					y: 0,
					width: rectangleSize.width,
					height: rectangleSize.height
				}
	);

	useEffect(
		() => {
			if (image && image.cropData) {
				setCropData(image.cropData);
			}
		},
		[ image ]
	);

	return (
		<Formik
			{...DefaultFormikConfig}
			initialValues={{ image: image, useImage, useGradient }}
			onSubmit={(values) => {
				if (
					(values.useImage && imageType === ImageData.background.id) ||
					imageType !== ImageData.background.id
				) {
					updateImage(values.image);
				} else {
					updateImage(null);
				}
				cancel();
			}}
			onReset={cancel}
		>
			{({ errors, values, setFieldValue, isValid, dirty, handleSubmit, handleReset }) => (
				<Modal
					isOpen={isOpen}
					backdrop
					size="xl"
					toggle={handleReset}
					style={{ minWidth: imageType !== ImageData.background.id ? 'initial' : '1438px' }}
				>
					<ModalHeader>
						<div className="d-flex justify-content-between">
							<div>{title}</div>
							<div>
								<Button
									className="btn-icon mr-2"
									color="alternate"
									onClick={() => {
										handleSubmit();
									}}
								>
									<i className="pe-7s-check btn-icon-wrapper" />
									{CONFIRM}
								</Button>
								<Button className="btn-icon mr-2" color="secondary" type="button" onClick={handleReset}>
									<i className="pe-7s-back btn-icon-wrapper" />
									{CANCEL}
								</Button>
							</div>
						</div>
					</ModalHeader>
					<ModalBody>
						<Card className="mb-2">
							<CardHeader>
								<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
								{COMMON_INFORMATION}
							</CardHeader>
							<CardBody>
								{imageType === ImageData.background.id && (
									<Fragment>
										{showUseImage && (
											<Switch
												label={`Używaj zdjęcia`}
												onClick={(e) => {
													changeUseImage('useImage', !values.useImage);
													setFieldValue('useImage', !values.useImage);
													if (!values.useImage === true) {
														changeUseGradient('useGradient', false);
														setFieldValue('useGradient', false);
													}
												}}
												on={values.useImage}
												off={!values.useImage}
											/>
										)}
										<Switch
											label={`Używaj gradientu`}
											onClick={(e) => {
												changeUseGradient('useGradient', !values.useGradient);
												setFieldValue('useGradient', !values.useGradient);
												if (!values.useGradient === true) {
													changeUseGradient('useImage', false);
													setFieldValue('useImage', false);
												}
											}}
											on={values.useGradient}
											off={!values.useGradient}
											// disabled={values.useImage}
										/>
									</Fragment>
								)}
								{values.useImage && (
									<Fragment>
										<Row>
											{!values.image && (
												<Col>
													<UploadFile
														disabled={false}
														uploadUrl={'/api/files/crop'}
														maxSize={maxFileSize}
														acceptFileType={`${ImageMimeType.BMP}, ${ImageMimeType.PNG}, ${ImageMimeType.JPG}, ${ImageMimeType.GIF}`}
														acceptExtensionFiles={[
															FileExtensionTypes.BMP,
															FileExtensionTypes.PNG,
															FileExtensionTypes.JPG,
															FileExtensionTypes.JPEG,
															FileExtensionTypes.GIF
														]}
														additionalInfo={`Zalecany format zdjęcia powinien być w formacie ${rectangleSize.width}x${rectangleSize.height} pikseli`}
														recommendedWidth={rectangleSize.width}
														recommendedHeight={rectangleSize.height}
														onDrop={Function.prototype}
														onDropAccepted={(file) => {
															setFieldValue('image', {
																...file,
																cropData: {
																	unit: 'px',
																	x: 0,
																	y: 0,
																	width: rectangleSize.width,
																	height: rectangleSize.height
																}
															});
															setCropData({
																unit: 'px',
																x: 0,
																y: 0,
																width: rectangleSize.width,
																height: rectangleSize.height
															});
														}}
														onDropRejected={(file) => {
															fileUploadError(file, maxFileSize);
														}}
														onCancel={Function.prototype}
													/>
												</Col>
											)}
										</Row>
										<Row>
											{values.image && (
												<Col>
													<CropImageUpload
														previewSize={rectangleSize}
														previewStyle={{
															height: `${rectangleSize.height}px`,
															width: `${rectangleSize.width}px`,
															backgroundColor: 'grey'
														}}
														normalStyle={{
															minHeight: `${rectangleSize.height}px`,
															minWidth: `${rectangleSize.width}px`,
															backgroundColor: 'grey'
														}}
														cropData={cropData}
														path={values.image.path}
														fileName={values.image.fileName}
														onRemoveImage={() => {
															setCropData({
																unit: 'px',
																x: 0,
																y: 0,
																width: rectangleSize.width,
																height: rectangleSize.height
															});
															setFieldValue('image', null);
														}}
														onCroppedImage={(cropData) => {
															setCropData(cropData);
															setFieldValue('image', { ...values.image, cropData });
														}}
													/>
												</Col>
											)}
										</Row>
									</Fragment>
								)}
							</CardBody>
						</Card>
					</ModalBody>
				</Modal>
			)}
		</Formik>
	);
};
