import { PageSize } from '../../helpers/enums';
import { createAction } from '../base';

export const CreateIsoCodeAction = createAction('CreateIsoCodeAction');
export const GetIsoCodeAction = createAction('GetIsoCodeAction');
export const GetAllIsoCodesAction = createAction('GetAllIsoCodesAction');
export const GetNonDefaultIsoCodesAction = createAction('GetNonDefaultIsoCodesAction');
export const UpdateIsoCodeAction = createAction('UpdateIsoCodeAction');
export const RemoveIsoCodesAction = createAction('RemoveIsoCodesAction');

function createIsoCode(isoCode, changeUrl = true, withNotification = true) {
	return {
		type: CreateIsoCodeAction.Request,
		payload: { isoCode, changeUrl, withNotification }
	};
}
function getIsoCode(id) {
	return {
		type: GetIsoCodeAction.Request,
		payload: id
	};
}

function getAllIsoCodes(page = 0, size = PageSize.All, filters = [], sortings = []) {
	return {
		type: GetAllIsoCodesAction.Request,
		payload: { page, size, filters, sortings }
	};
}
function getNonDefaultIsoCodes(page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: GetNonDefaultIsoCodesAction.Request,
		payload: { page, size, filters, sortings }
	};
}

function updateIsoCode(isoCode) {
	return {
		type: UpdateIsoCodeAction.Request,
		payload: isoCode
	};
}

function removeIsoCodes(ids = []) {
	return {
		type: RemoveIsoCodesAction.Request,
		payload: ids
	};
}

export const IsoCodeActionCreators = {
	createIsoCode,
	getIsoCode,
	getAllIsoCodes,
	getNonDefaultIsoCodes,
	updateIsoCode,
	removeIsoCodes
};

export const IsoCodeActions = {
	CreateIsoCodeAction,
	GetIsoCodeAction,
	GetAllIsoCodesAction,
	GetNonDefaultIsoCodesAction,
	UpdateIsoCodeAction,
	RemoveIsoCodesAction
};
