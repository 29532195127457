import React from 'react';

import Grid from '../../../../components/Grid';
import { filterCell } from './RssChannelListHelpers';

const columns = [
	{ name: 'id', title: 'id' },
	{ name: 'displayText', title: 'Nazwa wyświetlana kanału RSS' },
	{ name: 'title', title: 'Tekst pod czytniki dla osób słabowidzących' },
	{ name: 'isActive', title: 'Aktywna' }
];
const columnExtensions = [
	{ columnName: 'displayText', wordWrapEnabled: true },
	{
		columnName: 'isActive',
		width: '15%',
		align: 'center'
	}
];
const hiddenColumns = [ 'id' ];
const textColumns = [ 'displayText' ];
const boolColumns = ['isActive'];
const boolFilterOperations = ['equal', 'notEqual'];
const currentPage = 0;

export const RssChannelList = ({ ...props }) => (
	<Grid
		items={props.items}
		totalItems={props.totalItems}
		columns={columns}
		columnExtensions={columnExtensions}
		hiddenColumns={hiddenColumns}
		textColumns={textColumns}
		boolColumns={boolColumns}
		boolFilterOperations={boolFilterOperations}
		// dateColumns={dateColumns}
		// dateFilterOperations={dateFilterOperations}
		currentPage={currentPage}
		filterCell={filterCell}
		// excludeItemIdFromSelection={map(filter(props.items, { isSystem: true }), (item) => item.id)}
		onReload={props.onReload}
		onRemove={props.onRemove}
		onAdd={props.onAdd}
		onRowClick={props.onRowClick}
		// dataTypeProviders={dataTypeProviders}
		internationalizable={props.internationalizable}
		language={props.language}
		languages={props.languages}
		onChangeLanguage={props.onChangeLanguage}
		filters={props.filters}
		onChangeFilters={props.onChangeFilters}
		onClearFilters={props.onClearFilters}
	/>
);
