import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { Formik } from "formik";
import Immutable from "seamless-immutable";
import cx from "classnames";

import {
  Form,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Label,
  Input,
  Button,
} from "reactstrap";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import Sticky from "react-stickynode";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import PageTitle from "../../../../Layout/AppMain/PageTitle";

import { SettingActionCreators } from "../../../../actions/settings";
import {
  LoaderType,
  DefaultLanguage,
  LinkTargetType,
  LinkType,
  ImageMimeType,
  ArticleStatus,
  DefaultFormikConfig,
  FileExtensionTypes,
} from "../../../../helpers/enums";
import { getActiveLanguagesSelector } from "../../../../reselect/language";
import { TileValidationSchema } from "../../../../validators";
import { TileIconNames } from "../../../../helpers/enums";
import LinkCard from "../../../../components/Cards/LinkCard";
import UploadFile from "../../../../components/UploadFile";
import { fileUploadError } from "../../../../helpers/error-message";

import { CONSTANTS } from "@constants";
import { CropImageUpload } from "../../../../components/CropImageUpload";

const {
  YES,
  NO,
  SELECT,
  SAVE,
  BACK,
  COMMON_INFORMATION,
  DISPLAY_NAME,
  TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS,
  OPEN_TILE_IN_NEW_WINDOW,
  ICON_NAME,
} = CONSTANTS;

const RectangleSize = { width: 200, height: 150 };

const TCard = ({
  tile,
  newTabOpenLinks,
  maxFileSize,
  activeLanguage,
  updateTile,
  tileCardBlocking,
  getTile,
  cancel,
  unloadSetting,
  match,
}) => {
  const [cropData, setCropData] = useState(
    tile && tile.file && tile.file.cropData
      ? tile.file.cropData
      : {
          unit: "px",
          x: 0,
          y: 0,
          width: RectangleSize.width,
          height: RectangleSize.height,
        }
  );
  const [tileId, setTileId] = useState(null);
  const [currentTile, setCurrentTile] = useState(
    tile
      ? Immutable({
          ...tile,
          language: { ...activeLanguage },
        }).asMutable({ deep: true })
      : null
  );

  useEffect(() => {
    const { id } = match.params;
    if (id && id !== tileId) {
      setTileId(id);
      getTile(id);
    }
    return () => {
      unloadSetting();
    };
  }, [getTile, match, tileId, unloadSetting]);

  useEffect(() => {
    if (tile && tile.id) {
      setCurrentTile(
        Immutable({
          ...tile,
          language: { ...activeLanguage },
        }).asMutable({ deep: true })
      );
    }

    if (tile && tile.file && tile.file.cropData) {
      setCropData(tile.file.cropData);
    }
  }, [tile, activeLanguage]);

  return (
    <Formik
      {...DefaultFormikConfig}
      initialValues={currentTile}
      validationSchema={TileValidationSchema}
      onSubmit={(values) => updateTile(values)}
      onReset={cancel}
    >
      {({
        errors,
        values,
        handleChange,
        isValid,
        dirty,
        setFieldValue,
        setValues,
        handleSubmit,
        handleReset,
      }) => {
        return (
          <Fragment>
            <PageTitle
              heading="Ustawienia kafla"
              icon="pe-7s-ribbon icon-gradient bg-tempting-azure"
            />
            <BlockUi
              tag="div"
              blocking={tileCardBlocking}
              loader={<Loader active type={LoaderType} />}
            >
              <Form
                noValidate
                method="post"
                onSubmit={handleSubmit}
                onReset={handleReset}
              >
                <Sticky
                  enabled={true}
                  top=".app-header"
                  innerZ="101"
                  activeClass="sticky-active-class"
                  className="mb-2"
                >
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn-icon mr-2"
                      color="primary"
                      type="submit"
                      disabled={!isValid || !dirty}
                    >
                      <i className="pe-7s-diskette btn-icon-wrapper" />
                      {SAVE}
                    </Button>
                    <Button
                      className="btn-icon mr-2"
                      color="secondary"
                      type="reset"
                    >
                      <i className="pe-7s-back btn-icon-wrapper" />
                      {BACK}
                    </Button>
                  </div>
                </Sticky>
                <Card className="mb-2">
                  <CardHeader>
                    <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
                    {COMMON_INFORMATION}
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="displayText">{DISPLAY_NAME}</Label>
                          <Input
                            type="text"
                            id="displayText"
                            name="displayText"
                            placeholder="Wpisz nazwę"
                            value={values.displayText}
                            onChange={handleChange}
                            invalid={!!errors.displayText}
                          />
                          <FormFeedback>{errors.displayText}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="title">
                            {TEXT_FOR_THE_VISUALLY_IMPAIRED_READERS}
                          </Label>
                          <Input
                            type="text"
                            id="title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            invalid={!!errors.title}
                          />
                          <FormFeedback>{errors.title}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="d-flex align-items-center">
                          <Label className="mr-2">
                            {OPEN_TILE_IN_NEW_WINDOW}
                          </Label>
                          <div
                            className="switch has-switch mb-2 mr-2"
                            data-on-label="Tak"
                            data-off-label="Nie"
                            onClick={(_) =>
                              setFieldValue(
                                "target",
                                values.target === LinkTargetType.NewTab.value
                                  ? LinkTargetType.Self.value
                                  : LinkTargetType.NewTab.value
                              )
                            }
                          >
                            <div
                              className={cx("switch-animate", {
                                "switch-on":
                                  values.target === LinkTargetType.NewTab.value,
                                "switch-off":
                                  values.target === LinkTargetType.Self.value,
                              })}
                            >
                              <input type="checkbox" />
                              <span className="switch-left">{YES}</span>
                              <label>&nbsp;</label>
                              <span className="switch-right">{NO}</span>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="iconName">{ICON_NAME}</Label>
                          <Input
                            type="select"
                            id="iconName"
                            name="iconName"
                            value={values.iconName}
                            onChange={handleChange}
                            invalid={!!errors.iconName}
                          >
                            <option key={`iconName-0`} value={""}>
                              {SELECT}
                            </option>
                            <option
                              key={`iconName-${TileIconNames.Act.value}`}
                              value={TileIconNames.Act.value}
                            >
                              {TileIconNames.Act.name}
                            </option>
                            <option
                              key={`iconName-${TileIconNames.Comment.value}`}
                              value={TileIconNames.Comment.value}
                            >
                              {TileIconNames.Comment.name}
                            </option>
                            <option
                              key={`iconName-${TileIconNames.Document.value}`}
                              value={TileIconNames.Document.value}
                            >
                              {TileIconNames.Document.name}
                            </option>
                            <option
                              key={`iconName-${TileIconNames.Time.value}`}
                              value={TileIconNames.Time.value}
                            >
                              {TileIconNames.Time.name}
                            </option>
                          </Input>
                          <FormFeedback>{errors.iconName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <img
                          src={`/images/${values.iconName}.png`}
                          alt={`${values.iconName}`}
                        />
                      </Col>
                      {!values.file && (
                        <Col>
                          <UploadFile
                            disabled={false}
                            uploadUrl={"/api/files/crop"}
                            canRemove={values.file !== null}
                            maxFileSize={maxFileSize}
                            acceptFileType={`${ImageMimeType.BMP}, ${ImageMimeType.PNG}, ${ImageMimeType.JPG}, ${ImageMimeType.GIF} , ${ImageMimeType.SVG}`}
                            acceptExtensionFiles={[
                              FileExtensionTypes.BMP,
                              FileExtensionTypes.PNG,
                              FileExtensionTypes.JPG,
                              FileExtensionTypes.JPEG,
                              FileExtensionTypes.GIF,
                              FileExtensionTypes.SVG,
                            ]}
                            additionalInfo={`Zalecany format zdjęcia powinien być w formacie ${RectangleSize.width}x${RectangleSize.height} pikseli`}
                            recommendedWidth={RectangleSize.width}
                            recommendedHeight={RectangleSize.height}
                            onDrop={Function.prototype}
                            onDropAccepted={(file) => {
                              setFieldValue("file", {
                                ...file,
                                cropData: {
                                  unit: "px",
                                  x: 0,
                                  y: 0,
                                  width: RectangleSize.width,
                                  height: RectangleSize.height,
                                },
                              });
                              setCropData({
                                unit: "px",
                                x: 0,
                                y: 0,
                                width: RectangleSize.width,
                                height: RectangleSize.height,
                              });
                            }}
                            onDropRejected={(file) =>
                              fileUploadError(file, maxFileSize)
                            }
                            onCancel={Function.prototype}
                          />
                        </Col>
                      )}
                    </Row>

                    <Row>
                      {values.file && (
                        <Col>
                          <CropImageUpload
                            previewSize={RectangleSize}
                            previewStyle={{
                              height: `${RectangleSize.height}px`,
                              width: `${RectangleSize.width}px`,
                              backgroundColor: "grey",
                            }}
                            normalStyle={{
                              minHeight: `${RectangleSize.height}px`,
                              minWidth: `${RectangleSize.width}px`,
                              backgroundColor: "grey",
                            }}
                            cropData={cropData}
                            path={values.file.path}
                            fileName={values.file.fileName}
                            onRemoveImage={() => setFieldValue("file", null)}
                            onCroppedImage={(cropData) => {
                              setCropData(cropData);
                              setFieldValue("file", {
                                ...values.file,
                                cropData,
                              });
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
                <LinkCard
                  type={"tile"}
                  maxLinks={1}
                  cardTitle={"Źródło"}
                  links={[
                    {
                      index: 0,
                      id: values.linkType === LinkType.Article.value
                        ? values.articleTranslation.id
                        : values.linkType === LinkType.Category.value
                        ? values.menuElement.id
                        : values.id,
                      linkToArticleTranslationId: values.linkType === LinkType.Article.value
                        ? values.articleTranslation.id
                        : null,
                      linkToMenuElementId: values.linkType === LinkType.Category.value
                        ? values.menuElement.id
                        : null,
                      slug: values.linkType === LinkType.Article.value
                          ? values.articleTranslation.slug
                          : values.linkType === LinkType.Category.value
                          ? values.menuElement.slug
                          : values.slug,
                      url: values.linkType === LinkType.Article.value
                          ? values.articleTranslation.url
                          : values.linkType === LinkType.Category.value
                          ? values.menuElement.url
                          : values.url,
                      type: values.linkType,
                      displayText:
                        values.linkType === LinkType.Article.value
                          ? values.articleTranslation.displayText
                          : values.linkType === LinkType.Category.value
                          ? values.menuElement.displayText
                          : values.displayText,
                      title:
                        values.linkType === LinkType.Article.value
                          ? values.articleTranslation.title
                          : values.linkType === LinkType.Category.value
                          ? values.menuElement.title
                          : values.title,
                      target: values.target
                    },
                  ]}
                  collapse={false}
                  onlyReplace={true}
                  categoryOptions={{
                    editable: false,
                    removeable: false,
                    showTargetMethod: false,
                  }}
                  articleOptions={{
                    editable: false,
                    removeable: false,
                    showTargetMethod: false,
                  }}
                  externalLinkOptions={{
                    editable: true,
                    removeable: false,
                    showTargetMethod: false,
                  }}
                  newTabOpenLinks={newTabOpenLinks}
                  defaultArticleFilter={[
                    {
                      columnName: "status",
                      operation: "equal",
                      value: ArticleStatus.Published.value,
                    },
                  ]}
                  onSave={(links) => {
                    const link = links[0];
                    if (link) {
                      if (link.type === LinkType.External.value) {
                        setFieldValue("linkType", link.type);
                        setFieldValue("displayText", link.displayText);
                        setFieldValue("title", link.title);
                        setFieldValue("target", link.target);
                        setFieldValue("url", link.url);
                        setFieldValue("slug", null);
                        setFieldValue("menuElement", null);
                        setFieldValue("articleTranslation", null);
                      } else if (link.type === LinkType.Article.value) {
                        setFieldValue("linkType", link.type);
                        setFieldValue("url", "");
                        setFieldValue("menuElement", null);
                        setFieldValue("articleTranslation", {
                          ...link,
                          id: link.linkToArticleTranslationId,
                        });
                      } else {
                        setFieldValue("linkType", link.type);
                        setFieldValue("menuElement", {
                          ...link,
                          id: link.linkToMenuElementId,
                        });
                        setFieldValue("url", "");
                        setFieldValue("articleTranslation", null);
                      }
                    }
                  }}
                />
              </Form>
            </BlockUi>
          </Fragment>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state, ownProp) => {
  return {
    tile: state.setting.tile || {
      id: 0,
      displayText: "",
      title: "",
      url: "",
      slug: "",
      file: null,
      iconName: "",
      target: state.application.newTabOpenLinks
        ? LinkTargetType.NewTab.value
        : LinkTargetType.Self.value,
      language: DefaultLanguage,
    },
    activeLanguage: state.application.activeLanguage || DefaultLanguage,
    languages: getActiveLanguagesSelector(state),
    tileCardBlocking: state.uiBlockState.tileCardBlocking || false,
    newTabOpenLinks: state.application.newTabOpenLinks,
    maxFileSize: state.application.maxFileSize,
  };
};

const mapDispatchToProps = (dispatch, ownProp) => {
  return {
    ...bindActionCreators(SettingActionCreators, dispatch),
    cancel: () => dispatch(goBack()),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TCard);
