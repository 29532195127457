import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { RouteUrls } from "../../helpers/routeUrls";

import { getLayoutPermissions } from "../../reselect/permission";

import ArchiveBIPManageCard from "./Card";

const ArchiveBIP = ({ match }) => {
  const permissions = useSelector((state) => getLayoutPermissions(state));

  return permissions.accessToSettings ? (
    <Fragment>
      <Switch>
        <Route
          path={RouteUrls.ArchiveBIP.manage}
          component={ArchiveBIPManageCard}
        />
      </Switch>
    </Fragment>
  ) : (
    <Redirect to={RouteUrls.App.main} />
  );
};
export default ArchiveBIP;
