import React, { Component } from "react";
import { Formik } from "formik";
import {
  Form,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  FormFeedback,
} from "reactstrap";
import cx from "classnames";

import {
  LinkType,
  LinkTargetType,
  DefaultFormikConfig,
} from "../../helpers/enums";
import { ArticleLinkValidationSchema } from "../../validators/article-link";
import { CONSTANTS } from "@constants";
const { CONFIRM, CANCEL } = CONSTANTS;

export default class LinkDetails extends Component {
  static defaultProps = {
    isOpen: false,
    link: {
      title: "",
      displayText: "",
      target: LinkTargetType.NewTab.value,
      url: "",
      type: LinkType.External.value,
      id: 0,
    },
    onToggle: (event) => {},
    onSave: (link) => {},
  };

  onCancel = (event) => {
    const { onToggle } = this.props;

    if (onToggle && typeof onToggle === "function") {
      onToggle(event);
    }
  };

  onSave = (link) => {
    const { onToggle, onSave } = this.props;

    if (onSave && typeof onSave === "function") {
      onSave(link);
    }

    if (onToggle && typeof onToggle === "function") {
      onToggle();
    }
  };

  render() {
    const { link, isOpen, showTargetMethod = true } = this.props;

    return (
      <Formik
        {...DefaultFormikConfig}
        validationSchema={ArticleLinkValidationSchema}
        initialValues={link}
        onSubmit={this.onSave}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          handleChange,
          handleReset,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal isOpen={isOpen} backdrop size="xl">
            <ModalHeader>
              <div className="d-flex justify-content-between">
                <div>Edycja odnośnika</div>
                <div>
                  <Button
                    className="btn-icon mr-2"
                    color="alternate"
                    type="submit"
                    disabled={!isValid}
                    form="form-link"
                  >
                    <i className="pe-7s-check btn-icon-wrapper" />
                    {CONFIRM}
                  </Button>
                  <Button
                    className="btn-icon mr-2"
                    color="secondary"
                    type="button"
                    onClick={this.onCancel}
                  >
                    <i className="pe-7s-back btn-icon-wrapper" />
                    {CANCEL}
                  </Button>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <Form
                id="form-link"
                onSubmit={handleSubmit}
                onReset={handleReset}
              >
                <Card className="mb-2">
                  <CardHeader>Dane ogólne</CardHeader>
                  <CardBody>
                    {values.type === LinkType.External.value && (
                      <FormGroup>
                        <Label for="url">URL</Label>
                        <Input
                          type="text"
                          id="url"
                          name="url"
                          value={values.url}
                          onChange={handleChange}
                          invalid={!!errors.url}
                          disabled={link.type !== LinkType.External.value}
                        />
                        <FormFeedback>{errors.url}</FormFeedback>
                      </FormGroup>
                    )}
                    <FormGroup>
                      <Label for="displayText">Nazwa do wyświetlenia</Label>
                      <Input
                        type="text"
                        id="displayText"
                        name="displayText"
                        value={values.displayText}
                        onChange={handleChange}
                        invalid={!!errors.displayText}
                      />
                      <FormFeedback>{errors.displayText}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="title">
                        Tekst pod czytniki dla osób słabowidzących
                      </Label>
                      <Input
                        type="text"
                        id="title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        invalid={!!errors.title}
                      />
                      <FormFeedback>{errors.title}</FormFeedback>
                    </FormGroup>

                    {showTargetMethod && (
                      <FormGroup className="d-flex align-items-center">
                        <Label for="target" className="mr-2">
                          Otwórz odnośnik w nowym oknie
                        </Label>
                        <div
                          className="switch has-switch mb-2 mr-2"
                          data-on-label="Tak"
                          data-off-label="Nie"
                          onClick={(e) => {
                            const val =
                              values.target === LinkTargetType.NewTab.value
                                ? LinkTargetType.Self.value
                                : LinkTargetType.NewTab.value;
                            setFieldValue(`target`, val);
                          }}
                          invalid={!!errors.target}
                        >
                          <div
                            className={cx("switch-animate", {
                              "switch-on":
                                values.target === LinkTargetType.NewTab.value,
                              "switch-off":
                                values.target === LinkTargetType.Self.value,
                            })}
                          >
                            <input type="checkbox" />
                            <span className="switch-left">Tak</span>
                            <label>&nbsp;</label>
                            <span className="switch-right">Nie</span>
                          </div>
                        </div>
                        <FormFeedback>{errors.target}</FormFeedback>
                      </FormGroup>
                    )}
                  </CardBody>
                </Card>
              </Form>
            </ModalBody>
          </Modal>
        )}
      </Formik>
    );
  }
}
