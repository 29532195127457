import React from 'react';

import Grid from '../../../../components/Grid';
import { filterCell } from './RepositoryConfigListHelpers';

const columns = [
	{ name: 'id', title: 'id' },
	{ name: 'name', title: 'Nazwa grupy' },
	{ name: 'description', title: 'Opis' }
];
const columnExtensions = [ { columnName: 'description', wordWrapEnabled: true } ];
const hiddenColumns = [ 'id' ];
const textColumns = ['name', 'description'];
const currentPage = 0;

export const RepositoryConfigList = ({ ...props }) => (
	<Grid
		items={props.items}
		totalItems={props.totalItems}
		columns={columns}
		columnExtensions={columnExtensions}
		hiddenColumns={hiddenColumns}
		textColumns={textColumns}
		currentPage={currentPage}
		filterCell={filterCell}
		removeable={true}
		addable={true}
		filterable={true}
		sortable={true}
		pagination={true}
		onReload={props.onReload}
		onRemove={props.onRemove}
		onAdd={props.onAdd}
		onRowClick={props.onRowClick}
		// internationalizable={props.internationalizable}
		// language={props.language}
		// languages={props.languages}
		// rightButtons={props.rightButtons}
		// onChangeLanguage={props.onChangeLanguage}
		filters={props.filters}
		onChangeFilters={props.onChangeFilters}
		onClearFilters={props.onClearFilters}
	/>
);
