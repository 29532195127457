import React, { useEffect } from 'react';
import { Formik } from 'formik';
import Immutable from 'seamless-immutable';
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Input,
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	Label,
	Row,
	Col,
	FormFeedback
} from 'reactstrap';
import find from 'lodash.find';
import * as yup from 'yup';

import { ConfigurationId, DefaultFormikConfig, DefaultLanguage } from '../../helpers/enums';
import { CONSTANTS } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { SystemParametersActionCreators } from '../../actions/settings/system-parameters';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Select from 'react-select';
import { components } from 'react-select';
import { dropdownErrorStyles } from './TileSettingsStyles';
import forEach from 'lodash.foreach';

const { SAVE, CANCEL } = CONSTANTS;

const EMPTY_TILE_CONFIG = {
	languageId: DefaultLanguage.id,
	value: 4,
	tiles: []
};

export const TileSettingModal = ({
	isOpen = false,
	items = [],
	language = DefaultLanguage,
	onToggle = Function.prototype
}) => {
	const dispatch = useDispatch();
	const systemParameterConfig = useSelector((state) => state.setting.systemParameter || {});
	const onSubmit = (values) => {
		dispatch(
			SystemParametersActionCreators.updateSystemParameter({
				id: ConfigurationId.TileMode,
				...values,
				languageId: language.id
			})
		);
		onToggle();
	};

	useEffect(() => {
		if (isOpen) {
			dispatch(SystemParametersActionCreators.getSystemParameter(ConfigurationId.TileMode));
		}
	}, [isOpen]);

	const { value } = systemParameterConfig;
	const immutableSystemParameter = value ? find(value.value, { languageId: language.id }) : null;
	const systemParameter = immutableSystemParameter
		? Immutable(immutableSystemParameter).asMutable({ deep: true })
		: EMPTY_TILE_CONFIG;

	forEach(systemParameter.tiles, tile => {
		const item = find(items, {id: tile.id});
		if (item) {
			tile.label = item.displayText;
		}
	})

	const SortableMultiValue = SortableElement((props) => {
		const onMouseDown = (e) => {
			e.preventDefault();
			e.stopPropagation();
		};
		const innerProps = { onMouseDown };

		return (
			<div
				style={{
					zIndex: 1051,
					visibility: 'visible',
					opacity: 1
				}}
			>
				<components.MultiValue {...props} innerProps={innerProps} />
			</div>
		);
	});

	const SortableSelect = SortableContainer(Select);

	return (
		<Formik
			{...DefaultFormikConfig}
			initialValues={{ ...systemParameter, languageId: language.id, title: systemParameterConfig.labelValue }}
			validate={async (values) => {
				const validationSchema = yup.object().shape({
					value: yup.number().required(),
					tiles: yup
						.array()
						.min(values.value, `Liczba kafli musi wynosić ${values.value}`)
						.max(values.value, `Liczba kafli musi wynosić ${values.value}`)
				});
				try {
					await validationSchema.validate(values);
				} catch (ex) {
					return { [ex.path]: ex.message };
				}
			}}
			onSubmit={(values) => {
				const updatedSystemParameter = { ...systemParameterConfig, value: values };
				onSubmit(updatedSystemParameter);
			}}
			onReset={() => onToggle()}
		>
			{({
				values,
				isValid,
				errors,
				touched,
				handleChange,
				handleReset,
				handleSubmit,
				handleBlur,
				setValues,
				setFieldValue
			}) => {
				const arrayMove = (array, from, to) => {
					array = array.slice();
					array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
					return array;
				};

				const onSortEnd = ({ oldIndex, newIndex, value, valueKey }) => {
					const newValue = arrayMove(value, oldIndex, newIndex);
					setValues({
						...values,
						[valueKey]: newValue
					});
				};
				return (
					<Modal isOpen={isOpen} backdrop size="xl">
						<ModalHeader>
							<div className="d-flex justify-content-between">
								<div>Ustawienia kafli</div>
								<div>
									<Button
										className="btn-icon mr-2"
										color="primary"
										type="submit"
										disabled={!isValid}
										form="form-link"
										onClick={handleSubmit}
									>
										<i className="pe-7s-diskette btn-icon-wrapper" />
										{SAVE}
									</Button>
									<Button
										className="btn-icon mr-2"
										color="secondary"
										type="button"
										onClick={handleReset}
									>
										<i className="pe-7s-back btn-icon-wrapper" />
										{CANCEL}
									</Button>
								</div>
							</div>
						</ModalHeader>
						<ModalBody>
							<Card className="mb-2">
								<CardHeader>
									<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
									{values.title}
								</CardHeader>
								<CardBody>
									<Row>
										<Col>
											<FormGroup>
												<Label for="value">{values.title}</Label>
												<Input
													type="select"
													id="value"
													name="value"
													value={values.value}
													onChange={handleChange}
												>
													<option value="2">2</option>
													<option value="4">4</option>
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormGroup>
												<Label for="tiles">Lista kafli</Label>
												<SortableSelect
													isMulti
													{...{
														axis: 'xy',
														placeholder: 'Wprowadź',
														value: values.tiles,
														closeMenuOnSelect: true,
														distance: 4,
														noOptionsMessage: () => 'Brak opcji',
														styles: !!errors.tiles && dropdownErrorStyles,
														getHelperDimensions: ({ node }) => node.getBoundingClientRect(),
														onChange: (e) => {
															const newElements = e.map((el) => ({
																label: el.label,
																value: parseInt(el.id),
																id: parseInt(el.id)
															}));
															setFieldValue('tiles', newElements);
														},
														options: (items || []).map((el) => ({
															label: el.displayText,
															value: parseInt(el.id),
															id: parseInt(el.id)
														})),
														name: 'tiles',
														menuPlacement: 'bottom',
														touched,
														autoBlur: true,
														onBlur: handleBlur,
														openMenuOnClick: true,
														invalid: !!errors.tiles,
														components: {
															MultiValue: SortableMultiValue
														},
														onSortEnd: (props) => {
															return onSortEnd({
																...props,
																value: values.tiles,
																valueKey: 'tiles'
															});
														}
													}}
												/>
												<FormFeedback
													style={{ display: `${!!errors.tiles ? 'block' : 'none'}` }}
												>
													{errors.tiles}
												</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</ModalBody>
					</Modal>
				);
			}}
		</Formik>
	);
};
