import React, { Component, Fragment } from 'react';
import { InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { TableFilterRow, TableEditRow } from '@devexpress/dx-react-grid-bootstrap4';
import Grid from '../../../../components/Grid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import filter from 'lodash.filter';

import { CONSTANTS } from '@constants';

const { SELECT, BUTTON, LINK, LABEL } = CONSTANTS;

const columns = [
	{
		name: 'standardElementAreaTypeName',
		title: 'Obszar'
	},
	{
		name: 'standardElementGroupName',
		title: 'Typ elementu'
	},
	{
		name: 'standardElementName',
		title: 'Nazwa elementu'
	},
	{ name: 'translation', title: 'Nazwa wyświetlana' },
	{
		name: 'translationDescription',
		title: 'Tekst pod czytniki dla osób słabowidzących'
	},
	{
		name: 'validationStatus',
		title: 'Status walidacji'
	}
];
const columnsExtensions = [
	{
		columnName: 'standardElementAreaTypeName',
		width: 'auto',
		sortingEnabled: false
	},
	{
		columnName: 'standardElementGroupName',
		width: 'auto'
	},
	{
		columnName: 'standardElementName',
		width: 'auto'
	},
	{
		columnName: 'translation',
		width: 'auto'
	},
	{
		columnName: 'translationDescription',
		width: 'auto'
	},
	{
		columnName: 'validationStatus',
		width: '10%',
		align: 'center'
	}
];
const filtersColumnExtensions = [
	{
		columnName: 'standardElementAreaTypeName',
		wordWrapEnabled: false,
		filteringEnabled: true
	},
	{
		columnName: 'standardElementGroupName',
		wordWrapEnabled: false,
		filteringEnabled: true
	},
	{
		columnName: 'standardElementName',
		wordWrapEnabled: false,
		filteringEnabled: true
	},
	{
		columnName: 'translation',
		wordWrapEnabled: true,
		filteringEnabled: true
	},
	{
		columnName: 'translationDescription',
		wordWrapEnabled: true,
		filteringEnabled: true
	},
	{
		columnName: 'validationStatus',
		wordWrapEnabled: false,
		filteringEnabled: false
	}
];
const textColumns = [ 'standardElementGroupName', 'standardElementName', 'translation', 'translationDescription' ];
const groupingColumns = [ { columnName: 'standardElementAreaTypeName' } ];
const groupingColumnExtensions = [
	{ columnName: 'standardElementAreaTypeName', groupingEnabled: false },
	{ columnName: 'standardElementGroupName', groupingEnabled: false },
	{ columnName: 'standardElementName', groupingEnabled: false },
	{ columnName: 'translation', groupingEnabled: false },
	{ columnName: 'translationDescription', groupingEnabled: false },
	{ columnName: 'validationStatus', groupingEnabled: false }
];

const groupingCell = (props) => {
	const { items, row, ...restProps } = props;
	const errors = filter(items, (item) => item[row.groupedBy] === row.value && item.errors);
	return (
		<span>
			<Fragment>
				<strong>{row.key}</strong>{' '}
				{errors.length > 0 && restProps.hasValidation && <i className="pe-7s-exlamation fg-danger" />}
				{errors.length <= 0 && restProps.hasValidation && <i className="pe-7s-check fg-success" />}
			</Fragment>
		</span>
	);
};
const editingStateColumnExtensions = [
	{
		columnName: 'standardElementAreaTypeName',
		editingEnabled: false
	},
	{
		columnName: 'standardElementGroupName',
		editingEnabled: false
	},
	{
		columnName: 'standardElementName',
		editingEnabled: false
	},
	{
		columnName: 'validationStatus',
		editingEnabled: false
	}
];
export const GroupNameFilterCell = ({ filter, onFilter }) => {
	return (
		<th>
			<InputGroup>
				<InputGroupAddon addonType="prepend">
					<button type="button" className="btn btn-outline-secondary">
						<svg className="d-block dx-g-bs4-filter-selector-icon" viewBox="0 0 32 32">
							<path d="M29.438 11.797v2.75h-26.922v-2.75h26.922zM29.438 17.406v2.75h-26.922v-2.75h26.922z" />
						</svg>
					</button>
				</InputGroupAddon>
				<Input
					type="select"
					value={filter ? filter.value : ''}
					onChange={(e) => onFilter(e.target.value ? { value: e.target.value, operation: 'equal' } : null)}
				>
					<option value="">{SELECT}</option>
					<option value="10">{BUTTON}</option>
					<option value="20">{LINK}</option>
					<option value="30">{LABEL}</option>
				</Input>
			</InputGroup>
		</th>
	);
};

const ValidationFormatter = ({ row }) => (
	<Fragment>
		{row.errors ? (
			<Button
				type="button"
				className="btn-icon-only border-0"
				outline
				color="danger"
				title={`${row.errors.translation || ''}\n${row.errors.translationDescription || ''}`}
			>
				<i className="pe-7s-exlamation btn-icon-wrapper" />
			</Button>
		) : (
			<Button type="button" className="btn-icon-only border-0" outline color="success">
				<i className="pe-7s-check btn-icon-wrapper" />
			</Button>
		)}
	</Fragment>
);

const filterCell = (props) => {
	const { column = { name: '' }, filteringEnabled = false } = props;
	if ('standardElementGroupName' === column.name && filteringEnabled) {
		return <GroupNameFilterCell {...props} />;
	}
	return <TableFilterRow.Cell {...props} />;
};

const dataTypeProviders = [
	<DataTypeProvider for={[ 'standardElementGroupName' ]} availableFilterOperations={[ 'equal', 'notEqual' ]} />,
	<DataTypeProvider for={[ 'validationStatus' ]} formatterComponent={ValidationFormatter} />
];
class EditCellComponent extends Component {
	render() {
		const { row, column } = this.props;
		if (column.name === 'translation' && !row.isTranslationEditable) {
			return <TableEditRow.Cell {...this.props} editingEnabled={false} />;
		}
		if (column.name === 'translationDescription' && !row.isTranslationDescriptionEditable) {
			return <TableEditRow.Cell {...this.props} editingEnabled={false} />;
		}
		return <TableEditRow.Cell {...this.props} />;
	}
}

export const StandardElementTranslationList = ({ items, totalItems, onReload, onEdit, ...props }) => (
	<Grid
		items={items}
		totalItems={totalItems}
		dataTypeProviders={dataTypeProviders}
		validationRow={true}
		grouping={groupingColumns}
		groupingColumnExtensions={groupingColumnExtensions}
		groupingCell={groupingCell}
		currentPage={0}
		pageSize={0}
		pageSizes={[ 0 ]}
		columns={columns}
		columnExtensions={columnsExtensions}
		filtersColumnExtensions={filtersColumnExtensions}
		editingStateColumnExtensions={editingStateColumnExtensions}
		textColumns={textColumns}
		filterCell={filterCell}
		removeable={false}
		addable={false}
		filterable={true}
		onReload={onReload}
		inlineEditable={true}
		onEditInlineItem={onEdit}
		editCellComponent={EditCellComponent}
		filters={props.filters}
		onChangeFilters={props.onChangeFilters}
		onClearFilters={props.onClearFilters}
	/>
);
