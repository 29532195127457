import { createSelector } from 'reselect';
import { SystemRoleName } from '../helpers/roleNames';

const UnknownRoleId = -1;
const systemRoles = (store) => store.application.systemRoles || [];

export const getAdminRoleId = createSelector([ systemRoles ], (roles) => {
	const adminRole = roles.find((role) => role.name === SystemRoleName.Admin && role.isSystem);

	return adminRole ? adminRole.id : UnknownRoleId;
});
