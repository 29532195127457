import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import cx from "classnames";

import { MenuList } from "./AppMenuItems";

export const AppMenuItem = ({ item, isVisible = true, isRoot }) => {
  const {
    label,
    icon,
    to,
    permissions,
    children,
    isExternalLink,
    openInTheNewTab,
  } = item;

  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const { pathname } = location;

  const isPermission = permissions?.length ? permissions[0] : true;

  const toggle = (e) => {
    if (isPermission) {
      e.stopPropagation();
      setIsOpen((prev) => !prev);
    }
  };

  function removeQueryString(pathname) {
    if (!pathname) return;

    const questionMarkIndex = pathname.indexOf("?");
    if (questionMarkIndex === -1) {
      return pathname;
    }
    return pathname.substring(0, questionMarkIndex);
  }

  const destinationPathname = removeQueryString(to);

  return (
    <li className={"metismenu-item"} onClick={(e) => toggle(e)}>
      {isExternalLink ? (
        <a
          {...{
            href: to,
            className: cx("metismenu-link", {
              "metismenu-link--visible": isVisible,
              "metismenu-link--active": pathname === destinationPathname,
              "metismenu-link--disabled": !isPermission,
            }),
            target: openInTheNewTab ? "_blank" : "_self",
          }}
        >
          {label}
        </a>
      ) : (
        <Link
          to={!!children.length ? pathname : to}
          className={cx("metismenu-link", {
            "metismenu-link--visible": isVisible,
            "metismenu-link--active": pathname === destinationPathname,
            "metismenu-link--disabled": !isPermission,
          })}
        >
          <i className={`${icon} metismenu-icon`} />
          <span
            className={cx({
              "metismenu-label": !!icon && !isRoot,
            })}
          >
            {label}
          </span>
          {!children.length && isPermission ? null : (
            <i
              className={cx(
                `${
                  !isPermission
                    ? "pe-7s-lock"
                    : isOpen
                    ? "pe-7s-angle-up"
                    : "pe-7s-angle-down"
                } caret-left  metismenu-link__icon metismenu-link__icon--right`,
                {
                  "metismenu-link__icon--lock": !isPermission,
                }
              )}
            />
          )}
        </Link>
      )}

      {!!children.length ? (
        <MenuList {...{ items: children, isRoot: false, isOpen }} />
      ) : null}
    </li>
  );
};
