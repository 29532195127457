import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { RouteUrls } from "../../helpers/routeUrls";
import { getLayoutPermissions } from "../../reselect/permission";

import CommonArticles from "./Common";
import SystemArticles from "./SystemArticles";

const Articles = () => {
  const permissions = useSelector((state) => getLayoutPermissions(state));

  return (
    <Fragment>
      {permissions.accessToArticles ? (
        <Switch>
          <Route
            path={RouteUrls.Article.common.list}
            component={CommonArticles}
          />
          <Route
            path={RouteUrls.Article.common.draft.list}
            component={CommonArticles}
          />
          <Route
            path={RouteUrls.Article.common.confirmed.list}
            component={CommonArticles}
          />
          <Route
            path={RouteUrls.Article.common.topublish.list}
            component={CommonArticles}
          />
          <Route
            path={RouteUrls.Article.common.published.list}
            component={CommonArticles}
          />
          <Route
            path={RouteUrls.Article.common.withdrawed.list}
            component={CommonArticles}
          />
          <Route
            path={RouteUrls.Article.common.archived.list}
            component={CommonArticles}
          />

          <Route
            path={RouteUrls.Article.system.list}
            component={SystemArticles}
          />
          <Route
            path={RouteUrls.Article.system.draft.list}
            component={SystemArticles}
          />
          <Route
            path={RouteUrls.Article.system.confirmed.list}
            component={SystemArticles}
          />
          <Route
            path={RouteUrls.Article.system.topublish.list}
            component={SystemArticles}
          />
          <Route
            path={RouteUrls.Article.system.published.list}
            component={SystemArticles}
          />
          <Route
            path={RouteUrls.Article.system.withdrawed.list}
            component={SystemArticles}
          />
          <Route
            path={RouteUrls.Article.system.archived.list}
            component={SystemArticles}
          />
        </Switch>
      ) : (
        <Redirect to={RouteUrls.App.main} />
      )}
    </Fragment>
  );
};

export default Articles;
