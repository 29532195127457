import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import axios from 'axios';

import { SettingActions } from '../../actions/settings';
import { createErrorToast } from '../../helpers/error-message';

const APP_IMAGES_URL = '/api/settings/app-images';
const APP_CROPPED_IMAGE_URL = '/api/files/image/';

function* getAppImages() {
	try {
		let appImages = yield call(getFooterRequest);

		yield put({
			type: SettingActions.GetAppImages.Success,
			payload: appImages
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetAppImages.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania grafik');
	}
}
function getFooterRequest() {
	return axios.get(APP_IMAGES_URL).then(response => response.data).catch(err => {
		throw err;
	});
}

function* updateCroppedImage(action) {
	try {
		yield call(updateCroppedImageRequest, action.payload);
		yield put({
			type: SettingActions.UpdateCroppedImage.Success,
			imageType: action.payload.imageType
		});
		if (action.payload.length === 1) {
			toast.success('Grafika została przycięta');
		} else {
			toast.success('Grafiki zostały przycięte');
		}
	} catch (err) {
		yield put({
			type: SettingActions.UpdateCroppedImage.Failure
		});
		if (action.payload.length === 1) {
			createErrorToast(err, 'Wystąpił niespodziewany błąd podczas przycinania grafiki');
		} else {
			createErrorToast(err, 'Wystąpił niespodziewany błąd podczas przycinania grafik');
		}
	}
}

function* updateAppImages(action) {
	try {
		let appImages = yield call(updateAppImagesRequest, action.payload);

		yield put({
			type: SettingActions.UpdateAppImages.Success,
			payload: appImages
		});
		toast.success('Wszystkie grafiki zostały zaktualizowane');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateAppImages.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji grafik');
	}
}

function updateCroppedImageRequest(action) {
	const imagesData = action;

	return axios
		.put(APP_CROPPED_IMAGE_URL, imagesData)
		.then(response => {
			return response.data;
		})
		.catch(err => {
			throw err;
		});
}

function updateAppImagesRequest(appImages) {
	return axios.put(APP_IMAGES_URL, appImages).then(response => response.data).catch(err => {
		throw err;
	});
}

function* watchGetAppImages() {
	yield takeEvery(SettingActions.GetAppImages.Request, getAppImages);
}
function* watchUpdateAppImages() {
	yield takeEvery(SettingActions.UpdateAppImages.Request, updateAppImages);
}
function* watchUpdateCroppedImage() {
	yield takeEvery(SettingActions.UpdateCroppedImage.Request, updateCroppedImage);
}

export const AppImagesSagas = [ fork(watchGetAppImages), fork(watchUpdateAppImages), fork(watchUpdateCroppedImage) ];
