import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import BannerCard from "./Card";
import BannerList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const Banners = () => (
  <Fragment>
    <Switch>
      <Route path={RouteUrls.Setting.banners.create} component={BannerCard} />
      <Route
        path={`${RouteUrls.Setting.banners.edit}/:id`}
        component={BannerCard}
      />
      <Route path="*" component={BannerList} />
    </Switch>
  </Fragment>
);

export default Banners;
