export const dropdownDescriptionStyles = (isError) => ({
    control: (base) => ({
      ...base,
      borderColor: isError ? "#dc3545" : base.borderColor,
      minHeight: 285,
      alignItems: "flex-start",
      "&:hover": {
        boxShadow: isError ? "0 0 0 0.2rem rgb(220 53 69 / 25%)" : base.boxShadow,
      },
    }),
    container: (base) => ({
      ...base,
      flex: 1,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      minHeight: 285,
    }),
    valueContainer: (base) => ({
      ...base,
      zIndex: 0,
    }),
  });

  export const dropdownErrorStyles = {
    control: (base) => ({
      ...base,
      borderColor: "#dc3545",
      "&:hover": {
        boxShadow: "0 0 0 0.2rem rgb(220 53 69 / 25%)",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      zIndex: 0,
    }),
  };
