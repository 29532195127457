import React, { Component } from 'react';
import { InputGroup } from 'reactstrap';
import findIndex from 'lodash.findindex';
import isEmpty from 'lodash.isempty';
import find from 'lodash.find';

import { FilterSelector } from './FilterSelector';

import { getMessage } from './utils';

export class FilterField extends Component {
	constructor(props) {
		super(props);

		this.state = {
			operation: !isEmpty(props.availableValues) ? props.availableValues[0] : '',
			value: ''
		};

		this.handleChangeOperation = (operation) => {
			const { filters, onChangeFilters, columnName, disabled } = this.props;
			const newFilters = !isEmpty(filters) ? filters : [];
			const filterIndex = findIndex(newFilters, { columnName: columnName });

			if (disabled) {
				return;
			}
			if (filterIndex !== -1) {
				onChangeFilters(
					newFilters.set(filterIndex, {
						columnName: columnName,
						operation: operation,
						value: this.state.value
					})
				);
			} else {
				onChangeFilters(
					newFilters.concat([
						{
							columnName: columnName,
							operation: operation,
							value: this.state.value
						}
					])
				);
			}
			this.setState({ operation: operation });
		};

		this.handleChangeValue = (value) => {
			const { filters, onChangeFilters, columnName } = this.props;
			const newFilters = !isEmpty(filters) ? filters : [];
			const filterIndex = findIndex(newFilters, { columnName: columnName });

			if (filterIndex !== -1 && value) {
				onChangeFilters(
					newFilters.set(filterIndex, {
						columnName: columnName,
						operation: this.state.operation,
						value: value
					})
				);
			} else if (filterIndex !== -1 && !value) {
				const changedFilters = newFilters.slice(0, filterIndex).concat(newFilters.slice(filterIndex + 1));
				onChangeFilters(!isEmpty(changedFilters) ? changedFilters : []);
			} else {
				onChangeFilters(
					newFilters.concat([
						{
							columnName: columnName,
							operation: this.state.operation,
							value: value
						}
					])
				);
			}
			this.setState({ value: value });
		};
	}
	render() {
		const { filters, availableValues, disabled, columnName, isOpen, isPopoverClicked, targetId } = this.props;
		const { operation } = this.state;
		const filter = !isEmpty(filters) ? find(filters, { columnName: columnName }) : null;
		const filterSelectorValue = filter ? filter.operation : operation;

		return (
			<InputGroup>
				<FilterSelector
					id={columnName}
					availableValues={availableValues}
					disabled={disabled || availableValues.length === 1}
					value={filterSelectorValue}
					onChange={this.handleChangeOperation}
					isOpen={isOpen}
					isPopoverClicked={isPopoverClicked}
					targetId={targetId}
				/>
				{/* {optionComponent} */}
				{
					// <InputComponent name={columnName} disabled={disabled} placeholder={getMessage('filterPlaceholder')} onChange={this.handleChangeValue} />
				}
				{this.props.children({
					name: columnName,
					disabled: disabled,
					placeholder: getMessage('filterPlaceholder'),
					onChange: this.handleChangeValue,
					filter: filter
				})}
			</InputGroup>
		);
	}
}
