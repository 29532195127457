import React, { Component, Fragment } from "react";
import { Button, Label, ListGroupItem } from "reactstrap";

import { format as dateFnsFormat, isValid as dateFnsIsValid } from "date-fns";
import { pl } from "date-fns/locale";
import { RouteUrls } from "../../helpers/routeUrls";
import { ArticleStatus } from "../../helpers/enums";

const DateTimeFormat = "yyyy-MM-dd HH:mm:ss";

export default class LinkCardItem extends Component {
  static defaultProps = {
    isHidden: false,
  };

  hideVersion = () => {
    const { index, changeVisibility = (index) => {} } = this.props;

    if (changeVisibility && typeof changeVisibility === "function") {
      changeVisibility(index, {
        ...this.props,
        isHidden: !this.props.isHidden,
      });
    }
  };

  preview = () => {
    const { articleTranslationId, goTo = () => {} } = this.props;
    if (goTo && typeof goTo === "function") {
      goTo(RouteUrls.Article.common.previewFunc(articleTranslationId));
    }
  };

  render() {
    const {
      isHidden = false,
      currentVersion = 0,
      version = 0,
      createdDate = new Date(),
      status = 0,
    } = this.props;

    const isPublished = status === ArticleStatus.Published.value;

    return (
      <Fragment>
        <ListGroupItem className="pl-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left flex2">
                <Label md={12} lg={4} className="pl-0">
                  Wersja {version}
                </Label>
                <Label className="md-no-padding" md={12} lg={8}>
                  {dateFnsIsValid(new Date(createdDate))
                    ? dateFnsFormat(new Date(createdDate), DateTimeFormat, {
                        locale: pl,
                      })
                    : ""}
                </Label>
              </div>
              <div className="d-flex">
                <div>
                  <Button
                    className="border-0 btn-transition"
                    outline
                    color="link"
                    title="Podgląd wersji"
                    onClick={this.preview}
                    style={{ cursor: "pointer" }}
                    disabled={currentVersion === version}
                  >
                    <i className="pe-7s-back-2" />
                  </Button>
                </div>
                <div style={{ cursor: isPublished ? "auto" : "pointer" }}>
                  <Button
                    className={`border-0 btn-transition ${
                      isPublished ? "invisible" : ""
                    }`}
                    outline
                    color={isHidden ? "light" : "success"}
                    title={`${isHidden ? "Pokaż wersję" : "Ukryj wersję"}`}
                    onClick={this.hideVersion}
                    disabled={isPublished}
                  >
                    <i className="pe-7s-look" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ListGroupItem>
      </Fragment>
    );
  }
}
