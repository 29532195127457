import { takeEvery, fork, put, call } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';

import { SearchActions } from '../actions/search';
import { MenuType } from '../helpers/enums';
import { createErrorToast } from '../helpers/error-message';

const SearchUrl = '/api/searches';

export function* searchCategories(action) {
	try {
		let categories = yield call(
			searchCategoriesRequest,
			action.payload.menuType,
			action.payload.title,
			action.payload.articles,
			action.payload.categoryOnly
		);

		yield put({
			type: SearchActions.SearchCategories.Success,
			payload: { categories, title: action.payload.title }
		});
	} catch (err) {
		yield put({
			type: SearchActions.SearchCategories.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas wyszukiwania kategorii');
	}
}

function searchCategoriesRequest(menuType = MenuType.Vertical, title, articles = [], categoryOnly = false) {
	const filterQuery = qs.stringify(
		{ menuType: menuType, title: title, articles: articles, categoryOnly: categoryOnly },
		{ addQueryPrefix: true, allowDots: true }
	);
	return axios
		.get(`${SearchUrl}/categories${filterQuery}`)
		.then((response) => ({
			items: response.data,
			totalItems: response.headers['x-total-count']
		}))
		.catch((err) => {
			throw err;
		});
}

export function* searchArticles(action) {
	try {
		let articles = yield call(
			searchArticlesRequest,
			action.payload.title,
			action.payload.categories,
			action.payload.menuType
		);

		yield put({
			type: SearchActions.SearchArticles.Success,
			payload: articles
		});
	} catch (err) {
		yield put({
			type: SearchActions.SearchArticles.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas wyszukiwania artykułów');
	}
}

function searchArticlesRequest(title, categories = [], menuType = null) {
	const filterQuery = qs.stringify(
		{ title: title, menuElements: categories, menuType },
		{ addQueryPrefix: true, allowDots: true }
	);
	return axios
		.get(`${SearchUrl}/articles${filterQuery}`)
		.then((response) => ({
			items: response.data,
			totalItems: response.headers['x-total-count']
		}))
		.catch((err) => {
			throw err;
		});
}

function* searchCategoriesByTitle(action) {
	try {
		let categories = yield call(searchCategoriesByTitleRequest, action.payload);

		yield put({
			type: SearchActions.SearchCategoriesByTitle.Success,
			payload: categories
		});
	} catch (err) {
		yield put({
			type: SearchActions.SearchCategoriesByTitle.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas wyszukiwania kategorii');
	}
}

function searchCategoriesByTitleRequest(title) {
	return axios
		.get(`${SearchUrl}/categories/title/${title}`)
		.then((response) => ({
			items: response.data,
			totalItems: response.headers['x-total-count']
		}))
		.catch((err) => {
			throw err;
		});
}

function* watchSearchCategories() {
	yield takeEvery(SearchActions.SearchCategories.Request, searchCategories);
}

function* watchSearchArticles() {
	yield takeEvery(SearchActions.SearchArticles.Request, searchArticles);
}

function* watchSearchCategoriesByTitle() {
	yield takeEvery(SearchActions.SearchCategoriesByTitle.Request, searchCategoriesByTitle);
}

export const SearchSaga = [
	fork(watchSearchCategories),
	fork(watchSearchArticles),
	fork(watchSearchCategoriesByTitle)
];
