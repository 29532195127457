export const checkTitleLength = (title) => {
	if (title.length > 40) {
		return true;
	}

	return false;
};

export const validTitle = (title) => {
	return title.substring(0, 40).trim() + '...';
};
