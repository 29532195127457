import React, { useState, Fragment } from "react";
import { Button } from "reactstrap";

import ConfirmModal from "./ConfirmModal";
import { useDownloadData } from "../hooks/use-download-data";

export const FILTER_STRING_PATTERN = /<[^>]+>/g;

export const SingleAttachmentPreview = ({
  attachmentName,
  attachmentId,
  isRemovable,
  removeAttachment,
}) => {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const [download] = useDownloadData({
    baseUrl: `api/files/${attachmentId}`,
    fileName: attachmentName,
  });

  return (
    <Fragment>
      <div className="p-2 border">
        <div className="position-relative d-flex align-items-center flex-column">
          <i className={"pe-7s-file"} style={{ fontSize: 50 }} />
          <div>
            <button
              {...{
                type: "button",
                className: "mb-2 mr-2 btn btn-link",
                onClick: () => {
                  download({ attachmentName });
                },
              }}
            >
              {attachmentName}
            </button>
          </div>
          {isRemovable ? (
            <Button
              {...{
                className: "btn-icon btn btn-danger position-absolute",
                style: { top: 0, right: 0 },
                onClick: toggle,
              }}
            >
              <i className={"pe-7s-trash"} />
            </Button>
          ) : null}
        </div>
      </div>
      <ConfirmModal
        {...{
          open,
          input: false,
          title: "Potwierdzenie usunięcia instrukcji",
          content: "Czy na pewno chcesz usunąć instrukcję?",
          onCancel: () => {
            toggle();
          },
          onSubmit: () => {
            removeAttachment();

            toggle();
          },
        }}
      />
    </Fragment>
  );
};
