import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableSelection,
  TableSummaryRow,
  TableGroupRow,
  TableInlineCellEditing,
  GroupingPanel,
  Toolbar,
  TableEditRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  DataTypeProvider,
  SortingState,
  TableColumnVisibility,
  PagingState,
  CustomPaging,
  FilteringState,
  SelectionState,
  IntegratedSelection,
  SummaryState,
  IntegratedSummary,
  CustomSummary,
  EditingState,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-grid";
import {
  Button,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Flag from "react-flagkit";
import city2 from "../assets/utils/images/dropdown-header/city2.jpg";
import map from "lodash.map";
import filter from "lodash.filter";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import { format } from "date-fns";

import { PublishedButton } from "./PublishedButton";

import { checkboxColumnFormatter } from "../helpers/formatters/react-bootstrap-table/CheckboxColumn";
import { dateColumnWithSecondsFormatter } from "../helpers/formatters/react-bootstrap-table/DateColumn";
import { getValidDate } from "../utils/date";
import ConfirmModal from "./ConfirmModal";
import { DefaultLanguage } from "../helpers/enums";
import { AVAILABLE_ISO_CODE } from "../helpers/isoCode";

const ZeroCurrency = 0;
const DateTimeFormatter = ({ value }) => dateColumnWithSecondsFormatter(value);
const BooleanFormatter = ({ value }) => checkboxColumnFormatter(value);
const CurrencyFormatter = ({ value }) =>
  value
    ? value.toLocaleString("pl-PL", { style: "currency", currency: "PLN" })
    : ZeroCurrency.toLocaleString("pl-PL", {
        style: "currency",
        currency: "PLN",
      });

const FilterIcon = ({ type }) => {
  switch (type) {
    case "date": {
      return <span className="d-block oi oi-calendar" />;
    }
    default: {
      return <TableFilterRow.Icon type={type} />;
    }
  }
};

const DateEditor = ({ value, onValueChange }) => {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;

    if (targetValue.trim() === "") {
      onValueChange();
      return;
    }

    let validDate = getValidDate(targetValue);

    if (!validDate) {
      return;
    }

    const dateValue = formatDate(validDate);

    onValueChange(dateValue);
  };

  const formatDate = (date) => format(new Date(date), "yyyy-MM-dd");

  return (
    <input
      className="form-control text-right"
      type="date"
      placeholder="Filtruj..."
      value={value === undefined ? "" : value}
      onChange={handleChange}
    />
  );
};

const IntEditor = (props) => (
  <input
    type="text"
    className="form-control"
    {...props}
    placeholder="Filtruj..."
    onChange={(e) => {
      e.preventDefault();
      e.stopPropagation();
      const newValue = parseInt(e.target.value);
      if (newValue) {
        props.onValueChange(newValue);
      } else if (e.target.value === "") {
        props.onValueChange("");
      } else {
        props.onValueChange(0);
      }
    }}
  />
);

const BoolFilterCell = ({ filter, onFilter }) => {
  return (
    <th>
      <select
        className="form-control"
        value={filter ? filter.value : ""}
        onChange={(e) =>
          onFilter(
            e.target.value
              ? { value: e.target.value, operation: "equal" }
              : null
          )
        }
      >
        <option value="">Wybierz...</option>
        <option value="true">Tak</option>
        <option value="false">Nie</option>
      </select>
    </th>
  );
};

const GroupCellContent = (props) => {
  const { items, row, ...restProps } = props;
  const errors = filter(
    items,
    (item) => item[row.groupedBy] === row.value && item.errors
  );
  return (
    <TableGroupRow.Content {...restProps}>
      <Fragment>
        {row.key}{" "}
        {errors.length > 0 && restProps.hasValidation && (
          <i className="pe-7s-exlamation fg-danger" />
        )}
        {errors.length <= 0 && restProps.hasValidation && (
          <i className="pe-7s-check fg-success" />
        )}
      </Fragment>
    </TableGroupRow.Content>
  );
};

const DefaultEditCellComponent = (props) => <TableEditRow.Cell {...props} />;

const PageSize = 20;
const PageSizes = [20, 50, 100, 200, 0];

const CustomCell = (props) => (
  <Table.Cell className="table-cell-custom" {...props} />
);

class SputnikGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: props.columns,
      columnExtensions: props.columnExtensions,
      hiddenColumns: props.hiddenColumns,
      textColumns: props.textColumns,
      textFilterOperations: isEmpty(props.textFilterOperations)
        ? [
            "contains",
            "notContains",
            "startsWith",
            "endsWith",
            "equal",
            "notEqual",
          ]
        : props.textFilterOperations,
      boolColumns: props.boolColumns,
      boolFilterOperations: props.boolFilterOperations,
      intColumns: props.intColumns,
      intFilterOperations: props.intFilterOperations,
      currencyColumns: props.currencyColumns,
      currencyFilterOperations: props.currencyFilterOperations,
      dateColumns: props.dateColumns,
      dateFilterOperations: props.dateFilterOperations,
      sorting: props.sorting,
      currentPage: props.currentPage,
      pageSize: props.pageSize >= 0 ? props.pageSize : PageSize,
      pageSizes: props.pageSizes || PageSizes,
      filters: props.filters,
      filtersColumnExtensions: props.filtersColumnExtensions,
      selection: [],
      selectionRows: [],
      excludeItemIdFromSelection: props.excludeItemIdFromSelection,
      summaryTotalItems: props.summaryTotalItems,
      deletingState: false,
      selectable: props.selectable,
      selectableItems: props.selectableItems,
      removeable: props.removeable,
      addable: props.addable,
      sortable: props.sortable,
      pagination: props.pagination,
      validationRow: props.validationRow || false,
      filterable: props.filterable,
      exportable: props.exportable,
      inlineEditable: props.inlineEditable,
      internationalizable: props.internationalizable || false,
      grouping: props.grouping,
      groupingColumnExtensions: props.groupingColumnExtensions,
      groupingCell: props.groupingCell || GroupCellContent,
      expandedGroups: props.expandedGroups || undefined,
      EditCellComponent: props.editCellComponent || DefaultEditCellComponent,
      items: props.items,
      selectClickHandler: false,
    };

    const selfProps = props;
    this.filterCell = (props) => {
      if (selfProps.filterCell) {
        return selfProps.filterCell(props, this.selectClickHandler);
      }
      const { column } = props;
      if (
        !isEmpty(selfProps.boolColumns) &&
        selfProps.boolColumns.includes(column.name)
      ) {
        return <BoolFilterCell {...props} />;
      }
      return <TableFilterRow.Cell {...props} />;
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        prevProps.excludeItemIdFromSelection,
        this.props.excludeItemIdFromSelection
      )
    ) {
      this.setState({
        excludeItemIdFromSelection: this.props.excludeItemIdFromSelection,
      });
    }
    if (!isEqual(prevProps.items, this.props.items)) {
      this.setState({
        items: this.props.items,
      });
    }
    if (!isEqual(prevProps.currentPage, this.props.currentPage)) {
      this.setState({
        currentPage: this.props.currentPage,
      });
    }
    if (!isEqual(prevProps.pageSize, this.props.pageSize)) {
      this.setState({
        pageSize: this.props.pageSize,
      });
    }
    if (!isEqual(prevProps.filters, this.props.filters)) {
      if (this.state.selectClickHandler) {
        this.handleFilter();
      }
      this.setState({
        filters: this.props.filters,
        selectClickHandler: false,
      });
    }
    if (!isEqual(prevProps.expandedGroups, this.props.expandedGroups)) {
      this.setState({
        expandedGroups: this.props.expandedGroups,
      });
    }

    // if (!isEqual(prevProps.isClearSelection, this.props.isClearSelection)) {
    //   this.clearSelection();
    // }
  }

  selectClickHandler = () => {
    this.setState({ selectClickHandler: true });
  };

  changeSorting = (sorting) => {
    const { currentPage, pageSize, filters } = this.state;
    this.setState({ sorting: sorting });

    const { onReload } = this.props;
    if (onReload && typeof onReload === "function") {
      onReload(currentPage, pageSize, filters, sorting);
    }
  };
  changePageSize = (pageSize) => {
    this.setState({
      pageSize: pageSize,
      currentPage: 0,
      selection: [],
    });
    const { filters, sorting } = this.state;
    const { onReload } = this.props;
    if (onReload && typeof onReload === "function") {
      onReload(0, pageSize, filters, sorting);
    }
    const { onChangePageSize } = this.props;
    if (onChangePageSize && typeof onChangePageSize === "function") {
      onChangePageSize(pageSize);
    }
  };
  changeCurrentPage = (currentPage) => {
    const { pageSize, filters, sorting } = this.state;

    this.setState({
      currentPage: currentPage,
      selection: [],
    });
    const { onReload } = this.props;
    if (onReload && typeof onReload === "function") {
      onReload(currentPage, pageSize, filters, sorting);
    }

    const { onChangeCurrentPage } = this.props;
    if (onChangeCurrentPage && typeof onChangeCurrentPage === "function") {
      onChangeCurrentPage(currentPage);
    }
  };
  changeFilters = (filters) => {
    this.setState({
      filters: filters,
      selection: [],
    });
    const { onChangeFilters } = this.props;
    if (onChangeFilters && typeof onChangeFilters === "function") {
      onChangeFilters(filters);
    }
  };
  changeSelection = (selection) => {
    const currentSelectedRows = selection.map((el) => this.state.items[el]);

    this.setState({ selection: selection, selectionRows: currentSelectedRows });
  };
  handleFilter = () => {
    const { pageSize, filters, sorting } = this.state;
    const { onReload, onChangeCurrentPage } = this.props;
    if (onReload && typeof onReload === "function") {
      onReload(0, pageSize, filters, sorting);
    }
    if (onChangeCurrentPage && typeof onChangeCurrentPage === "function") {
      onChangeCurrentPage(0);
    }
    this.setState({ currentPage: 0 });
  };
  clearFilters = () => {
    const { onClearFilters } = this.props;
    if (onClearFilters && typeof onClearFilters === "function") {
      onClearFilters();
    }
  };

  handlePressEnterFilter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      const { pageSize, filters, sorting } = this.state;

      const { onReload, onChangeCurrentPage } = this.props;
      if (onReload && typeof onReload === "function") {
        onReload(0, pageSize, filters, sorting);
      }
      if (onChangeCurrentPage && typeof onChangeCurrentPage === "function") {
        onChangeCurrentPage(0);
      }
      this.setState({ currentPage: 0 });
    }
  };

  getCurrentIds = () => {
    let selectedIds = [];

    if (this.state.selection.length > 0) {
      this.state.selection.forEach((id) => {
        let item = this.props.items.find((item, index) => index === id);
        if (!this.state.excludeItemIdFromSelection.includes(item.id)) {
          selectedIds.push(item.id);
        }
      });
    }

    return selectedIds;
  };

  confirmArticles = () => {
    const { onConfirm } = this.props;

    const ids = this.getCurrentIds();

    if (ids.length) {
      onConfirm(ids);

      this.setState({
        selection: [],
      });
    }
  };

  publishArticles = (values) => {
    const { onPublish } = this.props;

    const ids = this.getCurrentIds();

    if (ids.length) {
      onPublish({ ids, values });

      this.setState({
        selection: [],
      });
    }
  };

  handleRemoveItems = () => {
    const { onRemove } = this.props;

    const ids = this.getCurrentIds();

    if (ids.length) {
      if (onRemove && typeof onRemove === "function") {
        onRemove(ids);
      }

      this.setState({
        selection: [],
        deletingState: false,
      });
    }
  };

  handleSelectItems = () => {
    const { onSelect } = this.props;

    const ids = this.getCurrentIds();

    if (ids.length > 0) {
      if (onSelect && typeof onSelect === "function") {
        onSelect(ids);
      }

      this.setState({
        selection: [],
      });
    }
  };

  deleteCloseModal = () =>
    this.setState({ deletingState: !this.state.deletingState });

  selectRowCell = ({ ...props }) => {
    const { excludeItemIdFromSelection } = this.props;

    if (!excludeItemIdFromSelection.includes(props.row.id)) {
      return <TableSelection.Cell {...props} />;
    }
    return (
      <td className="text-center align-middle">
        <input
          className="d-inline-block dx-g-bs4-cursor-pointer"
          type="checkbox"
          disabled
        />
      </td>
    );
  };

  handleExport = () => {
    const { currentPage, pageSize, filters } = this.state;
    const { onExport } = this.props;
    if (onExport && typeof onExport === "function") {
      onExport(currentPage, pageSize, filters);
    }
  };

  changeLanguage = (language) => {
    this.props.onChangeLanguage(language);
  };

  getTotalSummaryValues() {
    const { rows, summaryTotalItems } = this.state;
    const { totalItems = 0 } = this.props;
    return summaryTotalItems.map((summary) => {
      const { columnName, type } = summary;
      if (type === "count") {
        return totalItems;
      }
      return IntegratedSummary.defaultCalculator(
        type,
        rows,
        (row) => row[columnName]
      );
    });
  }
  generateTableRow(props) {
    const { row, ...restProps } = props;
    const { onRowClick } = this.props;
    const { inlineEditable } = this.state;

    return inlineEditable ? (
      <Table.Row {...restProps} />
    ) : (
      <Table.Row {...restProps} onClick={(e) => onRowClick(row)} />
    );
  }
  commitChanges = ({ added, changed, deleted }) => {
    const { items } = this.state;
    let changedRows;
    if (changed) {
      changedRows = items.map((row, index) => {
        if (changed[index]) {
          const { onEditInlineItem } = this.props;
          if (onEditInlineItem && typeof onEditInlineItem === "function") {
            this.props.onEditInlineItem(index, { ...row, ...changed[index] });
          }
          return { ...row, ...changed[index] };
        } else {
          return row;
        }
      });
    }
    this.setState({ items: changedRows });
  };

  onExpandedGroupsChange = (e) => {
    this.setState({ expandedGroups: e });
  };

  render() {
    const {
      columns,
      columnExtensions,
      hiddenColumns,
      dateColumns,
      dateFilterOperations,
      boolColumns,
      boolFilterOperations,
      textColumns,
      textFilterOperations,
      intColumns,
      intFilterOperations,
      currencyColumns,
      currencyFilterOperations,
      sorting,
      filters,
      filtersColumnExtensions,
      currentPage,
      pageSize,
      pageSizes,
      summaryTotalItems,
      selection,
      selectionRows,
      deletingState,
      selectable,
      addable,
      filterable,
      sortable,
      exportable,
      pagination,
      internationalizable,
      inlineEditable,
      grouping,
      groupingColumnExtensions,
      groupingCell,
      expandedGroups,
      EditCellComponent,
      items,
      validationRow,
    } = this.state;
    const {
      totalItems = 0,
      selectableItems,
      onAdd,
      removeable,
      dataTypeProviders,
      editingStateColumnExtensions,
      language,
      languages,
      addText,
      tableGroupRowClassName,
      drawerable = false,
      changeDrawerState,
      rightButtons = [],
      confirmable,
      publishable,
    } = this.props;

    return (
      <Fragment>
        <ConfirmModal
          open={deletingState}
          content={"Czy na pewno usunąć zaznaczone elementy?"}
          onToggle={this.deleteCloseModal}
          onCancel={this.deleteCloseModal}
          onSubmit={this.handleRemoveItems}
        />
        <div className="mb-2 d-flex">
          <div className="d-flex">
            {addable && (
              <Button
                className="mb-2 mr-2 btn-icon"
                color="success"
                onClick={onAdd}
              >
                <i className="pe-7s-plus btn-icon-wrapper"> </i>
                {addText}
              </Button>
            )}
            {confirmable && (
              <Button
                className="mb-2 mr-2 btn-icon"
                color="info"
                onClick={() => this.confirmArticles()}
                disabled={selection.length === 0}
              >
                <i className="pe-7s-like2 btn-icon-wrapper"> </i>
                Zatwierdź
              </Button>
            )}
            {publishable && (
              <PublishedButton
                {...{
                  submit: this.publishArticles,
                  disabled:
                    selection.length === 0 ||
                    selectionRows.some(
                      ({ mainMenuElementDisplayText }) =>
                        !mainMenuElementDisplayText
                    ),
                }}
              />
            )}
            {removeable && (
              <Button
                className="mb-2 mr-2 btn-icon"
                color="danger"
                disabled={selection.length === 0}
                onClick={this.deleteCloseModal}
              >
                <i className="pe-7s-trash btn-icon-wrapper"> </i>
                Usuń
              </Button>
            )}
            {selectable && (
              <Button
                className="mb-2 mr-2 btn-icon"
                color="focus"
                disabled={selection.length === 0}
                onClick={this.handleSelectItems}
              >
                <i className="pe-7s-check btn-icon-wrapper"> </i>
                Wybierz
              </Button>
            )}
            {filterable && (
              <Fragment>
                <Button
                  className="mb-2 mr-2 btn-icon"
                  color="info"
                  onClick={this.handleFilter}
                >
                  <i className="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                  Odśwież
                </Button>
                <Button
                  className="mb-2 mr-2 btn-icon"
                  color="dark"
                  onClick={this.clearFilters}
                >
                  <i className="pe-7s-close-circle btn-icon-wrapper"> </i>
                  Wyczyść filtry
                </Button>
              </Fragment>
            )}
            {exportable && (
              <Button
                className="mb-2 mr-2 btn-icon"
                color="warning"
                onClick={this.handleExport}
              >
                <i className="pe-7s-download btn-icon-wrapper"> </i>
                Export
              </Button>
            )}
          </div>
          <div className="header-dots">
            {rightButtons}
            {drawerable && (
              <div className="dropdown">
                <Button
                  className="rounded-circle p-0 mr-2"
                  id="DrawerMenu"
                  color="link"
                  onClick={() => changeDrawerState()}
                >
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle bg-warning">
                    <div className="icon-wrapper-bg bg-focus" />
                    <FontAwesomeIcon
                      icon={faFilter}
                      color="#573a04"
                      fixedWidth={false}
                      size="2x"
                    />
                  </div>
                </Button>
              </div>
            )}
            {internationalizable && (
              <UncontrolledDropdown>
                <DropdownToggle className="p-0 mr-2" color="link">
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-focus" />
                    <div className="language-icon">
                      {AVAILABLE_ISO_CODE.includes(language.isoCode) && (
                        <Flag
                          className="mr-3 opacity-8"
                          country={language.isoCode}
                          size="40"
                        />
                      )}
                      {!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
                        language.isoCode !== "" &&
                        language.isoCodeFile !== null && (
                          <img
                            className={`mr-2 opacity-8`}
                            src={language.isoCodeFile.path}
                            alt={`Grafika ${language.isoCodeFile.fileName}`}
                          />
                        )}
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                      <div
                        className="menu-header-image opacity-05"
                        style={{
                          backgroundImage: "url(" + city2 + ")",
                        }}
                      />
                      <div className="menu-header-content text-center text-white">
                        <h6 className="menu-header-subtitle mt-0">
                          Wybierz język
                        </h6>
                      </div>
                    </div>
                  </div>
                  <DropdownItem header>Aktualnie wybrany</DropdownItem>
                  <DropdownItem active>
                    {AVAILABLE_ISO_CODE.includes(language.isoCode) && (
                      <Flag
                        className="mr-3 opacity-8"
                        country={language.isoCode}
                      />
                    )}
                    {!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
                      language.isoCode !== "" &&
                      language.isoCodeFile !== null && (
                        <img
                          className={`mr-2 opacity-8`}
                          src={language.isoCodeFile.path}
                          alt={`Grafika ${language.isoCodeFile.fileName}`}
                        />
                      )}
                    {language.name}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem header>Dostępne języki</DropdownItem>
                  {map(
                    filter(languages, (lang) => lang.id !== language.id),
                    (language, langIndex) => (
                      <DropdownItem
                        key={`langIndex-${langIndex}`}
                        onClick={(e) => this.changeLanguage(language)}
                      >
                        {AVAILABLE_ISO_CODE.includes(language.isoCode) && (
                          <Flag
                            className="mr-3 opacity-8"
                            country={language.isoCode}
                          />
                        )}
                        {!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
                          language.isoCode !== "" &&
                          language.isoCodeFile !== null && (
                            <img
                              className={`mr-2 opacity-8`}
                              src={language.isoCodeFile.path}
                              alt={`Grafika ${language.isoCodeFile.fileName}`}
                            />
                          )}
                        {language.name}
                      </DropdownItem>
                    )
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>
        </div>
        <Card
          className="mb-2"
          onKeyDown={(event) => this.handlePressEnterFilter(event)}
        >
          <CardBody>
            <Grid key="id" columns={columns} rows={items}>
              <DataTypeProvider
                for={boolColumns}
                formatterComponent={BooleanFormatter}
                availableFilterOperations={boolFilterOperations}
              />
              <DataTypeProvider
                for={dateColumns}
                formatterComponent={DateTimeFormatter}
                availableFilterOperations={dateFilterOperations}
                editorComponent={DateEditor}
              />
              <DataTypeProvider
                for={textColumns}
                availableFilterOperations={textFilterOperations}
              />
              <DataTypeProvider
                for={intColumns}
                availableFilterOperations={intFilterOperations}
                editorComponent={IntEditor}
              />
              <DataTypeProvider
                for={currencyColumns}
                formatterComponent={CurrencyFormatter}
                availableFilterOperations={currencyFilterOperations}
              />
              {map(dataTypeProviders, (element) => element)}
              <SummaryState totalItems={summaryTotalItems} />
              {filterable && (
                <FilteringState
                  filters={filters}
                  onFiltersChange={this.changeFilters}
                  columnExtensions={filtersColumnExtensions}
                />
              )}
              {sortable && (
                <SortingState
                  sorting={sorting}
                  onSortingChange={this.changeSorting}
                  columnExtensions={columnExtensions}
                />
              )}
              {(removeable || selectable || selectableItems) && (
                <SelectionState
                  selection={selection}
                  onSelectionChange={this.changeSelection}
                />
              )}
              {grouping && (
                <GroupingState
                  defaultGrouping={grouping}
                  defaultExpandedGroups={expandedGroups}
                  expandedGroups={expandedGroups}
                  columnExtensions={groupingColumnExtensions}
                  onExpandedGroupsChange={this.onExpandedGroupsChange}
                />
              )}
              {grouping && <IntegratedGrouping />}
              {pagination && (
                <PagingState
                  defaultPageSize={pageSize}
                  defaultCurrentPage={currentPage}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onCurrentPageChange={this.changeCurrentPage}
                  onPageSizeChange={this.changePageSize}
                />
              )}
              <CustomSummary totalValues={this.getTotalSummaryValues()} />
              {(removeable || selectable || selectableItems) && (
                <IntegratedSelection />
              )}
              {pagination && <CustomPaging totalCount={parseInt(totalItems)} />}
              <Table
                columnExtensions={columnExtensions}
                cellComponent={CustomCell}
                rowComponent={(props) => this.generateTableRow(props)}
                messages={{
                  noData: "Brak danych",
                }}
              />
              <TableHeaderRow showSortingControls={sortable} />
              <TableColumnVisibility defaultHiddenColumnNames={hiddenColumns} />
              <TableSummaryRow
                messages={{
                  count: "Razem",
                }}
              />
              {filterable && (
                <TableFilterRow
                  showFilterSelector
                  iconComponent={FilterIcon}
                  cellComponent={this.filterCell}
                  messages={{
                    filterPlaceholder: "Filtruj..",
                    contains: "Zawiera",
                    notContains: "Nie zawiera",
                    startsWith: "Rozpoczyna od",
                    endsWith: "Kończy na",
                    equal: "Równe",
                    notEqual: "Różne",
                    greaterThan: "Większe",
                    greaterThanOrEqual: "Większe lub równe",
                    lessThan: "Mniejsze",
                    lessThanOrEqual: "Mniejsze lub równe",
                    date: "Dnia",
                  }}
                />
              )}
              {(removeable || selectable || selectableItems) && (
                <TableSelection
                  showSelectAll
                  showSelectionColumn
                  cellComponent={this.selectRowCell}
                />
              )}
              {pagination && (
                <PagingPanel
                  pageSizes={pageSizes}
                  messages={{ showAll: "Wszystkie" }}
                  className="d-block"
                />
              )}
              {inlineEditable && (
                <EditingState
                  onCommitChanges={this.commitChanges}
                  defaultEditingRowIds={[0]}
                  columnExtensions={editingStateColumnExtensions}
                />
              )}
              {inlineEditable && (
                <TableInlineCellEditing
                  startEditAction="doubleClick"
                  cellComponent={EditCellComponent}
                />
              )}
              {grouping && (
                <TableGroupRow
                  rowComponent={(props) => (
                    <TableGroupRow.Row
                      className={tableGroupRowClassName}
                      {...props}
                    />
                  )}
                  contentComponent={(props) => {
                    const newProps = {
                      ...props,
                      items: items,
                      hasValidation: validationRow,
                    };
                    return <Fragment>{groupingCell(newProps)}</Fragment>;
                  }}
                />
              )}
              {false && grouping && <Toolbar />}
              {false && grouping && <GroupingPanel showSortingControls />}
            </Grid>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

SputnikGrid.propTypes = {
  addText: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, title: PropTypes.string })
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  columnExtensions: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      wordWrapEnabled: PropTypes.bool,
    })
  ),
  filtersColumnExtensions: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      wordWrapEnabled: PropTypes.bool,
    })
  ),
  excludeItemIdFromSelection: PropTypes.arrayOf(PropTypes.number),
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  textColumns: PropTypes.arrayOf(PropTypes.string),
  textFilterOperations: PropTypes.arrayOf(PropTypes.string),
  boolColumns: PropTypes.arrayOf(PropTypes.string),
  boolFilterOperations: PropTypes.arrayOf(PropTypes.string),
  intColumns: PropTypes.arrayOf(PropTypes.string),
  intFilterOperations: PropTypes.arrayOf(PropTypes.string),
  currencyColumns: PropTypes.arrayOf(PropTypes.string),
  currencyFilterOperations: PropTypes.arrayOf(PropTypes.string),
  dateColumns: PropTypes.arrayOf(PropTypes.string),
  dateFilterOperations: PropTypes.arrayOf(PropTypes.string),
  sorting: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.string,
    })
  ),
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  summaryTotalItems: PropTypes.arrayOf(
    PropTypes.shape({ columnName: PropTypes.string, type: PropTypes.bool })
  ),
  totalItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataTypeProviders: PropTypes.arrayOf(PropTypes.node),
  selectable: PropTypes.bool,
  selectableItems: PropTypes.bool,
  removeable: PropTypes.bool,
  addable: PropTypes.bool,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  pagination: PropTypes.bool,
  emptyItem: PropTypes.shape({
    id: PropTypes.number,
  }),
  inlineEditable: PropTypes.bool,
  editingStateColumnExtensions: PropTypes.arrayOf(PropTypes.any),
  internationalizable: PropTypes.bool,
  language: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.object),
  groupContentValidation: PropTypes.func,
  onReload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  onChangeLanguage: PropTypes.func,
  filterCell: PropTypes.func,
  onEditInlineItem: PropTypes.func,
  tableGroupRowClassName: PropTypes.string,
  drawerable: PropTypes.bool,
  changeDrawerState: PropTypes.func,
  rightButtons: PropTypes.arrayOf(PropTypes.element),
};
SputnikGrid.defaultProps = {
  addText: "Nowy",
  columns: [],
  items: [],
  columnExtensions: [],
  hiddenColumns: [],
  textColumns: [],
  textFilterOperations: [],
  boolColumns: [],
  boolFilterOperations: [],
  intColumns: [],
  intFilterOperations: [],
  currencyColumns: [],
  currencyFilterOperations: [],
  dateColumns: [],
  dateFilterOperations: [],
  sorting: [],
  currentPage: 0,
  filtersColumnExtensions: [],
  selection: [],
  summaryTotalItems: [],
  totalItems: 0,
  selectable: false,
  selectableItems: false,
  removeable: true,
  addable: true,
  filterable: true,
  sortable: true,
  exportable: false,
  pagination: true,
  dataTypeProviders: [],
  excludeItemIdFromSelection: [],
  inlineEditable: false,
  editingStateColumnExtensions: [],
  internationalizable: false,
  language: { name: DefaultLanguage.name, isoCode: DefaultLanguage.isoCode },
  languages: [],
  rightButtons: [],
  onReload: Function.prototype,
  onRemove: Function.prototype,
  onAdd: Function.prototype,
  onRowClick: Function.prototype,
  onExport: Function.prototype,
  onChangeLanguage: (lang) => {},
  // filterCell: Function.prototype,
  onEditInlineItem: (index, row) => {},
  groupContentValidation: (row, column) => {},
  onChangePageSize: (pageSize) => {},
  onChangeCurrentPage: (currentPage) => {},
  tableGroupRowClassName: "",
  drawerable: false,
  changeDrawerState: (_) => {},
};
export default SputnikGrid;
