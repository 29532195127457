import { createAction } from '../base';

const GetAppImages = createAction('GetAppImages');
const UpdateAppImages = createAction('UpdateAppImages');
const UpdateCroppedImage = createAction('UpdateCroppedImage');

function getAppImages() {
	return {
		type: GetAppImages.Request
	};
}

function updateAppImages(appImages) {
	return {
		type: UpdateAppImages.Request,
		payload: appImages
	};
}

function updateCroppedImage(appImage) {
	return {
		type: UpdateCroppedImage.Request,
		payload: appImage
	};
}

export const AppImagesActions = {
	GetAppImages,
	UpdateAppImages,
	UpdateCroppedImage
};

export const AppImagesActionCreators = {
	getAppImages,
	updateAppImages,
	updateCroppedImage
};
