import { createSelector } from 'reselect';
import { LanguageStatus } from '../helpers/enums';
import filter from 'lodash.filter';
import orderBy from 'lodash.orderby';

const stateLanguages = (state) => state.application.languages || [];

export const getActiveLanguagesSelector = createSelector([ stateLanguages ], (languages) => {
	return orderBy(
		filter(languages, (language) => language.status === LanguageStatus.Active),
		[ 'status', 'id' ],
		[ 'desc', 'asc' ]
	);
});
