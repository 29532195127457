import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import Immutable from 'seamless-immutable';

import { Formik } from 'formik';
import { Form, FormGroup, FormFeedback, Row, Col, Card, CardBody, CardHeader, Label, Input, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import Sticky from 'react-stickynode';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { DictionaryActionCreators } from '../../../../actions/dictionary';
import { IsoCodeValidationSchema } from '../../../../validators';
import { LoaderType, ImageMimeType, DefaultFormikConfig, FileExtensionTypes } from '../../../../helpers/enums';
import UploadFile from '../../../../components/UploadFile';
import { fileUploadError } from '../../../../helpers/error-message';

import { CONSTANTS } from '@constants';

const { SAVE, BACK, NAME, CODE, COMMON_INFORMATION } = CONSTANTS;

class IsoCodeCard extends Component {
	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getIsoCode(id);
		}
	}

	componentDidUpdate(prevProps) {
		const { id: prevId } = prevProps.match.params;
		const { id } = this.props.match.params;
		if (prevId && id && prevId !== id) {
			this.props.getIsoCode(id);
		}
	}
	componentWillUnmount() {
		this.props.unloadDictionary();
	}

	render() {
		const { isoCode, createIsoCode, updateIsoCode, cancel, maxFileSize } = this.props;
		const newIsoCode =
			isoCode && isoCode.id > 0
				? Immutable(isoCode).asMutable({ deep: true })
				: {
						id: 0,
						name: '',
						code: '',
						isDefault: false
					};

		return (
			<Fragment>
				<PageTitle heading="Kod ISO języka" icon="pe-7s-settings icon-gradient bg-tempting-azure" />
				<BlockUi tag="div" loader={<Loader active type={LoaderType} />}>
					<Formik
						{...DefaultFormikConfig}
						initialValues={newIsoCode}
						validationSchema={IsoCodeValidationSchema}
						onSubmit={(values) => {
							if (values.id > 0) {
								updateIsoCode(values);
							} else {
								createIsoCode(values);
							}
						}}
						onReset={cancel}
					>
						{({ errors, values, handleChange, handleSubmit, handleReset, setFieldValue }) => (
							<Form onSubmit={handleSubmit} onReset={handleReset}>
								<Sticky
									top=".app-header"
									innerZ="101"
									activeClass="sticky-active-class"
									className="mb-2"
								>
									<div className="d-flex justify-content-end">
										<Button className="btn-icon mr-2" color="primary" type="submit">
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{SAVE}
										</Button>
										<Button className="btn-icon mr-2" color="secondary" type="reset">
											<i className="pe-7s-back btn-icon-wrapper" />
											{BACK}
										</Button>
									</div>
								</Sticky>
								<Card className="mb-2">
									<CardHeader>
										<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
										<span className="mr-2">{COMMON_INFORMATION}</span>
									</CardHeader>
									<CardBody>
										<Row>
											<Col>
												<FormGroup>
													<Label for="code">{CODE}</Label>
													<Input
														type="text"
														id="code"
														name="code"
														placeholder="Wpisz kod"
														value={values.code}
														invalid={!!errors.code}
														onChange={(e) => {
															let value = e.target.value || '';
															value = value.toUpperCase().trim();
															setFieldValue('code', value);
														}}
													/>
													<FormFeedback>{errors.code}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="name">{NAME}</Label>
													<Input
														type="text"
														id="name"
														name="name"
														placeholder="Wpisz nazwę"
														value={values.name}
														onChange={handleChange}
														invalid={!!errors.name}
													/>
													<FormFeedback>{errors.name}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<UploadFile
													disabled={false}
													canRemove={values.file !== null}
													maxFileSize={maxFileSize}
													acceptFileType={`${ImageMimeType.BMP}, ${ImageMimeType.PNG}, ${ImageMimeType.JPG}, ${ImageMimeType.GIF}`}
													acceptExtensionFiles={[
														FileExtensionTypes.BMP,
														FileExtensionTypes.PNG,
														FileExtensionTypes.JPG,
														FileExtensionTypes.JPEG,
														FileExtensionTypes.GIF
													]}
													additionalInfo={
														'Zalecany format zdjęcia powinien być w formacie 32x32 pikseli'
													}
													recommendedWidth={32}
													recommendedHeight={32}
													onDrop={Function.prototype}
													onDropAccepted={(file) => setFieldValue('file', file)}
													onDropRejected={(file) => fileUploadError(file, maxFileSize)}
													onCancel={Function.prototype}
													isIsoCode={true}
												/>
											</Col>
											{values.file && (
												<Col>
													<div className="d-flex he-100 justify-content-center align-items-center">
														<img
															className={`image__content`}
															src={values.file.path}
															alt={`Grafika ${values.file.fileName}`}
														/>
														<Button
															size="sm"
															color="danger"
															onClick={(e) => setFieldValue('file', null)}
															title="Usuwa grafikę"
														>
															<i className="pe-7s-trash btn-icon-wrapper" />
														</Button>
													</div>
												</Col>
											)}
										</Row>
										{errors.file && (
											<FormFeedback style={{ display: 'block' }}>{errors.file}</FormFeedback>
										)}
									</CardBody>
								</Card>
							</Form>
						)}
					</Formik>
				</BlockUi>
			</Fragment>
		);
	}
}

const mapStateToProps = (state, ownProp) => {
	return {
		isoCode: state.dictionary.isoCode.isoCode || {
			id: 0,
			name: '',
			code: '',
			isDefault: false
		},
		maxFileSize: state.application.maxFileSize
	};
};

const mapDispatchToProps = (dispatch, ownProp) => {
	return {
		...bindActionCreators(
			{
				...DictionaryActionCreators
			},
			dispatch
		),
		cancel: () => dispatch(goBack())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IsoCodeCard);
