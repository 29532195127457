import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { RouteUrls } from "../../helpers/routeUrls";
import { ApplicationActionCreators } from "../../actions/application";
import { UserActionCreators } from "../../actions/auth/user";

import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";
import AppFooter from "../AppFooter";

import Articles from "../../Pages/Articles";
import Authorizations from "../../Pages/Authorizations";
import ChangePassword from "../../Pages/ChangePassword";
import Dashboard from "../../Pages/Dashboards/Card/index";
import Dictionaries from "../../Pages/Dictionaries";
import Menu from "../../Pages/Menu";
import Settings from "../../Pages/Settings";
import ArchiveBIP from "../../Pages/ArchiveBIP";

const AppMain = ({ getPermissions, getLoggedUserData }) => {
  useEffect(() => {
    getPermissions();
    getLoggedUserData();
  }, [getLoggedUserData, getPermissions]);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Switch>
              <Route path={RouteUrls.Auth.main} component={Authorizations} />
              <Route path={RouteUrls.Menu.main} component={Menu} />
              <Route path={RouteUrls.Setting.main} component={Settings} />
              <Route
                path={RouteUrls.App.changePassword}
                component={ChangePassword}
              />
              <Route path={RouteUrls.Article.main} component={Articles} />
              <Route
                path={RouteUrls.Dictionary.main}
                component={Dictionaries}
              />
              <Route path={RouteUrls.ArchiveBIP.main} component={ArchiveBIP} />
              <Route exact path={RouteUrls.App.main} component={Dashboard} />
              <Redirect to={RouteUrls.App.main} />
            </Switch>
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...ApplicationActionCreators, ...UserActionCreators },
    dispatch
  );
};

export default withRouter(connect(undefined, mapDispatchToProps)(AppMain));
