import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import axios from 'axios';

import { SettingActions } from '../../actions/settings';
import { createErrorToast } from '../../helpers/error-message';

const FOOTER_URL = '/api/settings/footers';

function* getFooter(action) {
	try {
		let footer = yield call(getFooterRequest, action.payload);

		yield put({
			type: SettingActions.GetFooter.Success,
			payload: footer
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetFooter.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania stopki');
	}
}
function getFooterRequest(lang) {
	return axios.get(`${FOOTER_URL}/${lang}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateFooter(action) {
	try {
		let footer = yield call(updateFooterRequest, action.payload.lang, action.payload.footer);

		yield put({
			type: SettingActions.UpdateFooter.Success,
			payload: footer
		});
		toast.success('Stopka została zaktualizowana');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateFooter.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji danych stopki');
	}
}
function updateFooterRequest(lang, footer) {
	return axios.put(`${FOOTER_URL}/${lang}`, footer).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchGetFooter() {
	yield takeEvery(SettingActions.GetFooter.Request, getFooter);
}
function* watchUpdateFooter() {
	yield takeEvery(SettingActions.UpdateFooter.Request, updateFooter);
}

export const FooterSagas = [ fork(watchGetFooter), fork(watchUpdateFooter) ];
