import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";

import Nav from "../AppNav/VerticalNavWrapper";

import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import RSC from "react-scrollbars-custom";
import HeaderLogo from "../AppLogo";

import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { getLayoutPermissions } from "../../reselect/permission";
import { LoaderType } from "../../helpers/enums";

class AppSidebar extends Component {
  state = {};

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    let {
      backgroundColor,
      enableBackgroundImage,
      enableSidebarShadow,
      backgroundImage,
      backgroundImageOpacity,
      permissions,
      isActive,
    } = this.props;

    return (
      <Fragment>
        <div
          className="sidebar-mobile-overlay"
          onClick={this.toggleMobileSidebar}
        />
        <ReactCSSTransitionGroup
          component="div"
          className={cx("app-sidebar", backgroundColor, {
            "sidebar-shadow": enableSidebarShadow,
          })}
          transitionName="SidebarAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <HeaderLogo />
          <RSC>
            <div
              className="app-sidebar__inner"
              style={{
                overflowX: "hidden",
                paddingBottom: 120,
              }}
            >
              <BlockUi
                tag="div"
                blocking={!isActive}
                loader={<Loader active type={LoaderType} />}
              >
                <Nav {...{ permissions }} />
              </BlockUi>
            </div>
          </RSC>
          <div
            className={cx("app-sidebar-bg", backgroundImageOpacity)}
            style={{
              backgroundImage: enableBackgroundImage
                ? "url(" + backgroundImage + ")"
                : null,
              zIndex: "-1",
            }}
          />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
  permissions: getLayoutPermissions(state),
  isActive: state.auth.isActive,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
