import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';
import { Input } from 'reactstrap';

import { getCombinedArticlesCategories } from '@components/utils';

const target = {
	drop(props, monitor, component) {
		const monitorItem = monitor.getItem();
		const item = {
			id: monitorItem.node.id | 0,
			displayText: monitorItem.node.displayText
		};
		const isExists = component.state.items.findIndex((element) => element.id === item.id) !== -1;

		if (isExists) {
			return {
				...monitorItem
			};
		}

		const newItemList = component.state.items.concat(item);
		component.setState((state) => ({
			displayText: '',
			items: newItemList
		}));

		if (component.props.onChange && typeof component.props.onChange === 'function') {
			component.props.onChange('', newItemList);
		}
		return {
			node: item,
			path: monitorItem.path,
			treeId: monitorItem.treeId,
			treeIndex: monitorItem.treeIndex
		};
	},
	canDrop(props, monitor) {
		if (monitor.didDrop()) {
			// If you want, you can check whether some nested
			// target already handled drop
			return;
		}
		return { moved: true };
	}
};

const collect = (connect, monitor) => {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	};
};

class SearchTarget extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayText: '',
			items: getCombinedArticlesCategories(props.articles, props.categories)
		};
	}

	componentDidUpdate(prevProps) {
		const { articles, categories } = this.props;
		if (!isEqual(prevProps.articles, articles) || !isEqual(prevProps.categories, categories)) {
			this.setState({
				items: getCombinedArticlesCategories(articles, categories)
			});
		}
	}

	clear = (index) => {
		const newItemList = this.state.items.slice(0, index).concat(this.state.items.slice(index + 1));
		const { displayText } = this.state;
		this.setState({
			items: newItemList
		});
		if (this.props.onChange && typeof this.props.onChange === 'function') {
			this.props.onChange(displayText, newItemList);
		}
	};

	clearAll = () => {
		const { displayText } = this.state;
		this.setState({ items: [] });
		if (this.props.onChange && typeof this.props.onChange === 'function') {
			this.props.onChange(displayText, []);
		}
	};

	handleChangeDisplayText = debounce((displayText) => {
		this.setState({
			displayText: displayText
		});
		this.props.onChange(displayText, []);
	}, 500);

	render() {
		const { connectDropTarget, disabled = false } = this.props;
		const { items } = this.state;

		return connectDropTarget(
			<div className="has-aux flex1-1-0" tabIndex="-1">
				<div className="form-control rbt-input p-0" style={{ overflow: 'auto' }}>
					<div
						className="rbt-input-wrapper he-100"
						style={{ padding: isEmpty(items) ? 'inherit' : '0.375rem 0.75rem' }}
					>
						{map(items, (item, itemIndex) => (
							<div
								key={`item-${item.id}-${itemIndex}`}
								tabIndex="0"
								className="rbt-token rbt-token-removeable"
							>
								{item.displayText}
								<button
									tabIndex="-1"
									aria-label="Usuń"
									className="close rbt-close rbt-token-remove-button"
									type="button"
									onClick={this.clear.bind(this, itemIndex)}
								>
									<span aria-hidden="true">×</span>
									<span className="sr-only">Usuń</span>
								</button>
							</div>
						))}
						{isEmpty(items) && (
							<Input
								type="text"
								className="rbt-input-main border-0 h-auto"
								onChange={(e) => {
									this.handleChangeDisplayText(e.target.value);
								}}
								disabled={disabled}
							/>
						)}
					</div>
					{!isEmpty(items) && (
						<div className="rbt-aux">
							<button
								aria-label="Usuń wszystkie"
								className="close rbt-close"
								type="button"
								onClick={this.clearAll}
								disabled={disabled}
							>
								<span aria-hidden="true">×</span>
								<span className="sr-only">Usuń wszystkie</span>
							</button>
						</div>
					)}
					{isEmpty(items) && (
						<div className="rbt-aux">
							<button aria-label="Wyszukaj" className="close rbt-close" type="button" disabled={disabled}>
								<span aria-hidden="true">
									<i className="pe-7s-search" />
								</span>
								<span className="sr-only">Wyszukaj</span>
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default DropTarget((props) => props.dropType, target, collect)(SearchTarget);
