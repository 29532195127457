import { createAction } from './base';

const ChangeFilters = createAction('ChangeFilters');
const ClearFilters = createAction('ClearFilters');
const SaveSelectedFilters = createAction('SaveSelectedFilters');

function changeFilters(propName = [ 'all' ], filters = []) {
	return {
		type: ChangeFilters.Request,
		payload: { propName, filters }
	};
}

function clearFilters(propName = [ 'all' ], filters = []) {
	return {
		type: ClearFilters.Request,
		payload: { propName, filters }
	};
}

function saveSelectedFilters(propName = [ 'all' ], filters = []) {
	return {
		type: SaveSelectedFilters.Request,
		payload: { propName, filters }
	};
}
export const FilterActionCreators = {
	changeFilters,
	clearFilters,
	saveSelectedFilters
};

export const FilterActions = {
	ChangeFilters,
	ClearFilters,
	SaveSelectedFilters
};
