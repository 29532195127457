import { createAction } from "./base";
import { ArticleType } from "../helpers/enums";

const GetAllArticles = createAction("GetAllArticles");
const GetArticle = createAction("GetArticle");
const UpdateArticle = createAction("UpdateArticle");
const SaveArticle = createAction("SaveArticle");
const DeleteArticles = createAction("DeleteArticles");
const ConfirmArticles = createAction("ConfirmArticles");
const PublishArticles = createAction("PublishArticles");
const ClearArticle = createAction("ClearArticle");
const CopyArticleToNewArticleTranslation = createAction(
  "CopyArticleToNewArticleTranslation"
);
const ChangeArticleTranslationStatus = createAction(
  "ChangeArticleTranslationStatus"
);
const CreateArticleNewVersion = createAction("CreateArticleNewVersion");
const ChangeVersionVisibility = createAction("ChangeVersionVisibility");
const GetArticleHash = createAction("GetArticleHash");

function getAllArticles(
  lang = "",
  page = 0,
  size = 20,
  filters = [],
  sortings = [],
  types = [ArticleType.Common],
  extraFilters = [],
  menuType = null
) {
  return {
    type: GetAllArticles.Request,
    payload: {
      lang,
      page,
      size,
      filters,
      sortings,
      types,
      extraFilters,
      menuType,
    },
  };
}

function getArticle(id) {
  return {
    type: GetArticle.Request,
    payload: id,
  };
}

function updateArticle(article) {
  return {
    type: UpdateArticle.Request,
    payload: article,
  };
}

function saveArticle(article) {
  return {
    type: SaveArticle.Request,
    payload: article,
  };
}

function deleteArticles(articleIds = []) {
  return {
    type: DeleteArticles.Request,
    payload: articleIds,
  };
}

function confirmArticles(articleIds = []) {
  return {
    type: ConfirmArticles.Request,
    payload: articleIds,
  };
}

function publishArticles(values) {
  return {
    type: PublishArticles.Request,
    payload: values,
  };
}

function clearArticle() {
  return {
    type: ClearArticle.Request,
  };
}

function copyArticleToNewArticleTranslation(article, language) {
  return {
    type: CopyArticleToNewArticleTranslation.Request,
    payload: { article, language },
  };
}

function changeArticleStatus(article, newStatus) {
  return {
    type: ChangeArticleTranslationStatus.Request,
    payload: { article, newStatus },
  };
}

function createArticleNewVersion(id) {
  return {
    type: CreateArticleNewVersion.Request,
    payload: id,
  };
}

function changeVersionVisibility(articleId, articleVersionId) {
  return {
    type: ChangeVersionVisibility.Request,
    payload: { articleId, articleVersionId },
  };
}

function getArticleHash({ id, webAppAddress, languageCode }) {
  return {
    type: GetArticleHash.Request,
    payload: { id, webAppAddress, languageCode },
  };
}

export const ArticleActionCreators = {
  getAllArticles,
  getArticle,
  updateArticle,
  saveArticle,
  clearArticle,
  copyArticleToNewArticleTranslation,
  changeArticleStatus,
  createArticleNewVersion,
  changeVersionVisibility,
  deleteArticles,
  getArticleHash,
  confirmArticles,
  publishArticles,
};

export const ArticleActions = {
  GetAllArticles,
  GetArticle,
  UpdateArticle,
  SaveArticle,
  ClearArticle,
  CopyArticleToNewArticleTranslation,
  ChangeArticleTranslationStatus,
  CreateArticleNewVersion,
  ChangeVersionVisibility,
  DeleteArticles,
  GetArticleHash,
  ConfirmArticles,
  PublishArticles,
};
