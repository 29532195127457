export const Office = {
	office: {
		officeName: '',
		jstCodeTeryt: '',
		regon: '',
		nip: '',
		phone: '',
		fax: '',
		email: '',
		webSiteWWW: '',
		officeBoxEpuap: '',
		reveivingDocumentPlace: '',
		country: '',
		province: '',
		district: '',
		commune: '',
		city: '',
		street: '',
		streetNumber: '',
		flat: '',
		zipCode: ''
	}
};
