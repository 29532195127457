import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { RoleList } from './RoleList';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, PageSize } from '../../../../helpers/enums';
import { RoleActionCreators } from '../../../../actions/auth/role';
import { AuthActionCreators } from '../../../../actions/auth';
import { FilterActionCreators } from '../../../../actions/filter';

class RoleListContainer extends Component {
	componentDidMount() {
		const { loadRoles, roleFilters } = this.props;
		loadRoles(0, PageSize.Rows20, roleFilters, []);
	}

	componentWillUnmount() {
		this.props.unloadAuth();
	}

	onAdd = () => {
		this.props.goTo(RouteUrls.Auth.role.create);
	};

	onRowClick = (row) => this.props.goTo(RouteUrls.Auth.role.editFunc(row.id));

	onReload = (page, size, filters, sortings) => {
		const { loadRoles, roleFilters } = this.props;
		loadRoles(page, size, roleFilters, sortings);
	};

	onRemove = (removeIds) => this.props.deleteRoles(removeIds);

	clearFilters = () => {
		const { loadRoles, clearFilters } = this.props;
		clearFilters(['roleFilters'], []);
		loadRoles(0, PageSize.Rows20, [], []);
	};

	render() {
		const { roles, totalRoles, roleListBlocking, roleFilters, changeFilters } = this.props;
		return (
			<Fragment>
				<PageTitle heading="Lista ról" icon="pe-7s-plugin icon-gradient bg-tempting-azure" />

				<BlockUi tag="div" blocking={roleListBlocking} loader={<Loader active type={LoaderType} />}>
					<RoleList
						items={roles}
						totalItems={totalRoles}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRemove={this.onRemove}
						onRowClick={this.onRowClick}
						filters={roleFilters}
						onChangeFilters={(filters) => changeFilters(['roleFilters'], filters)}
						onClearFilters={this.clearFilters}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		roleFilters: state.filter.roleFilters || [],
		roles: state.auth.roles || [],
		totalRoles: state.auth.totalRoles || 0,
		roleListBlocking: state.uiBlockState.roleListBlocking || false
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators({ ...RoleActionCreators, ...AuthActionCreators, ...FilterActionCreators }, dispatch),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleListContainer);
