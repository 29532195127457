import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import RepositoryConfigCard from "./Card";
import RepositoryConfigList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const RepositoryConfigs = ({ match }) => (
  <Fragment>
    <Switch>
      <Route
        path={RouteUrls.Setting.repository.create}
        component={RepositoryConfigCard}
      />
      <Route
        path={`${RouteUrls.Setting.repository.edit}/:id`}
        component={RepositoryConfigCard}
      />
      <Route path="*" component={RepositoryConfigList} />
    </Switch>
  </Fragment>
);

export default RepositoryConfigs;
