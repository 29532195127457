import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import axios from 'axios';

import { SettingActions } from '../../actions/settings';
import { createErrorToast } from '../../helpers/error-message';

const FOOTER_URL = '/api/settings/office-contacts';

function* getOfficeContacts(action) {
	try {
		let officeContacts = yield call(getOfficeContactsRequest, action.payload);

		yield put({
			type: SettingActions.GetOfficeContacts.Success,
			payload: officeContacts
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetOfficeContacts.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy kierownictwa urzędu');
	}
}
function getOfficeContactsRequest(lang) {
	return axios.get(`${FOOTER_URL}/${lang}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateOfficeContacts(action) {
	try {
		let officeContacts = yield call(updateOfficeContactsRequest, action.payload.lang, action.payload.officeContacts);

		yield put({
			type: SettingActions.UpdateOfficeContacts.Success,
			payload: officeContacts
		});
		toast.success('Lista kierownictwa urzędu została zaktualizowana');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateOfficeContacts.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji listy kierownictwa urzędu');
	}
}
function updateOfficeContactsRequest(lang, officeContacts) {
	return axios.put(`${FOOTER_URL}/${lang}`, officeContacts).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchGetOfficeContacts() {
	yield takeEvery(SettingActions.GetOfficeContacts.Request, getOfficeContacts);
}
function* watchUpdateOfficeContacts() {
	yield takeEvery(SettingActions.UpdateOfficeContacts.Request, updateOfficeContacts);
}

export const OfficeContactSagas = [ fork(watchGetOfficeContacts), fork(watchUpdateOfficeContacts) ];