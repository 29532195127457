import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import IsoCodeList from "./List";
import IsoCodeCard from "./Card";
import { RouteUrls } from "../../../helpers/routeUrls";

const Tags = () => (
  <Fragment>
    <Switch>
      <Route
        path={RouteUrls.Dictionary.isoCode.create}
        component={IsoCodeCard}
      />
      <Route
        path={`${RouteUrls.Dictionary.isoCode.edit}/:id`}
        component={IsoCodeCard}
      />
      <Route path="*" component={IsoCodeList} />
    </Switch>
  </Fragment>
);

export default Tags;
