import { takeEvery, fork, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { ArchiveActions } from '../actions/archive';
import qs from 'qs';
import {toast} from 'react-toastify';
import {createErrorToast} from '../helpers/error-message';

const ArchiveUrl = '/api/archive';
const FileArchiveUrl = '/api/files/archive-sitemap/'

function* watchGetArchiveBip() {
	yield takeEvery(ArchiveActions.GetArchiveBip.Request, getArchiveBip);
}

function* getArchiveBip() {
	try {
		const archiveBip = yield call(getArchiveBipRequest);
		yield put({
			type: ArchiveActions.GetArchiveBip.Success,
			payload: archiveBip
		});
	} catch (err) {
		yield put({
			type: ArchiveActions.GetArchiveBip.Failure
		});
	}
}

function getArchiveBipRequest() {
	return axios.get(`${ArchiveUrl}/config`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchRemoveArchiveSite() {
	yield takeEvery(ArchiveActions.RemoveArchiveSite.Request, removeArchiveSite);
}

function* removeArchiveSite(action) {
	try {
		yield call(removeArchiveSiteRequest, action.payload);
		yield put({
			type: ArchiveActions.RemoveArchiveSite.Success
		});
		toast.success('Usuwanie stron wykonało się prawidłowo');
	} catch (err) {
		yield put({
			type: ArchiveActions.RemoveArchiveSite.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas usuwania stron archiwalnych');
	}
}

function removeArchiveSiteRequest(sitename) {
	const filterQuery = qs.stringify({ sitename: sitename }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${ArchiveUrl}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchGetArchiveBipByClientId() {
	yield takeEvery(ArchiveActions.GetArchiveBipByClientId.Request, getArchiveBipByClientId);
}

function* getArchiveBipByClientId(action) {
	try {
		yield call(getArchiveBipByClientIdRequest, action.payload);
		yield put({
			type: ArchiveActions.GetArchiveBipByClientId.Success
		});
		yield call(getArchiveBip);
		toast.success('Łączenie z archiwalnym BIP-em powiodło się');
	} catch (err) {
		yield put({
			type: ArchiveActions.GetArchiveBipByClientId.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas łączenia z archiwalnym BIP-em');
	}
}

function getArchiveBipByClientIdRequest(clientId) {
	return axios.post(`${FileArchiveUrl}${clientId}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

export const ArchiveSaga = [ fork(watchGetArchiveBip), fork(watchRemoveArchiveSite), fork(watchGetArchiveBipByClientId) ];
