import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import RssChannelCard from "./Card";
import RssChannelList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const Bookmarks = () => (
  <Fragment>
    <Switch>
      <Route
        path={RouteUrls.Setting.rssChannels.create}
        component={RssChannelCard}
      />
      <Route
        path={`${RouteUrls.Setting.rssChannels.edit}/:id`}
        component={RssChannelCard}
      />
      <Route path="*" component={RssChannelList} />
    </Switch>
  </Fragment>
);

export default Bookmarks;
