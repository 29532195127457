import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";

import AppMain from "../../Layout/AppMain";
import { ApplicationActionCreators } from "../../actions/application";
import { LoginCallback } from "./OidcCallback";
import { OidcSecure } from "@axa-fr/react-oidc-redux";

const Main = (props) => {
  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    isDemoVersion,
  } = props;

  return (
    <OidcSecure authenticating={LoginCallback} isEnabled={true}>
      {isDemoVersion && (
        <div className="bg-danger text-center text-white demo-bar">
          <strong>STRONA DEMONSTRACYJNA</strong>
        </div>
      )}
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "mt-5": isDemoVersion }
              )}
            >
              <AppMain />
            </div>
          </Fragment>
        )}
      />
    </OidcSecure>
  );
};

const mapStateToProp = (state) => ({
  isDemoVersion: state.application.isDemoVersion || false,
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  // return bindActionCreators(UserActionCreators, dispatch);
  return bindActionCreators(ApplicationActionCreators, dispatch);
};
export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));
