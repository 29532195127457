export const Dictionary = {
	tag: {
		tag: null,
        tags: [],
        totalTags: 0
	},
	dictionaryEntry: {
		dictionaryEntry: null,
        dictionaryEntries: [],
        totalDictionaryEntries: 0
	},
	isoCode: {
		isoCode: null,
        isoCodes: [],
        totalIsoCodes: 0
	},
};