import React from 'react';

const CheckboxColumn = (props) => {
    return (
        <input type='checkbox' checked={props.active} readOnly/>
    )
}

export function checkboxColumnFormatter(cell, row) {
    return <CheckboxColumn active={cell} />
}