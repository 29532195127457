import React, { Fragment } from 'react';

class HeaderDots extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false
		};
	}

	render() {
		return (
			<Fragment>
				<div className="header-dots">
				</div>
			</Fragment>
		);
	}
}

export default HeaderDots;
