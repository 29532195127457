import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import ApplicationImages from "./Card";

const Footers = ({ match }) => (
  <Fragment>
    <Route path={match.url} component={ApplicationImages} />
  </Fragment>
);

export default Footers;
