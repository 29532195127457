import * as yup from 'yup';
import { MenuConfiguration } from '../helpers/enums';

export const MenuConfigurationValidationSchema = yup.object().shape({
	horizontal: yup
		.object()
		.shape({
			maxElements: yup
				.number()
				.required('Maksymalna liczba elementów na poziomie pierwszym musi posiadać wartość')
				.min(
					MenuConfiguration.Infinity,
					`Maksymalna liczba elementów na poziomie pierwszym nie może mieć wartości mniejszej od ${MenuConfiguration.Infinity} (nieograniczona)`
				),
			maxLevels: yup
				.number()
				.required('Maksymalna liczba zagłębień musi posiadać wartość')
				.min(
					MenuConfiguration.Infinity,
					`Maksymalna liczba zagłębień nie może mieć wartości mniejszej od ${MenuConfiguration.Infinity} (nieograniczona)`
				)
		})
		.required('Konfiguracja menu poziomego musi zostać określona'),
	vertical: yup
		.object()
		.shape({
			maxElements: yup
				.number()
				.required('Maksymalna liczba elementów na poziomie pierwszym musi posiadać wartość')
				.min(
					MenuConfiguration.Infinity,
					`Maksymalna liczba elementów na poziomie pierwszym nie może mieć wartości mniejszej od ${MenuConfiguration.Infinity} (nieograniczona)`
				),
			maxLevels: yup
				.number()
				.required('Maksymalna liczba zagłębień musi posiadać wartość')
				.min(
					MenuConfiguration.Infinity,
					`Maksymalna liczba zagłębień nie może mieć wartości mniejszej od ${MenuConfiguration.Infinity} (nieograniczona)`
				)
		})
		.required('Konfiguracja menu pionowego musi zostać określona')
});
