import React, { Fragment, useState } from "react";
import Drawer from "react-motion-drawer";
import RSC from "react-scrollbars-custom";
import { FormGroup, Label, Input, Button } from "reactstrap";

import Grid from "../../../../components/Grid";
import { StatusFilterField } from "../../../../components/ExtendedFilters/StatusFilterField";
import { FilterField } from "../../../../components/ExtendedFilters/FilterField";
import { TagFilterField } from "../../../../components/ExtendedFilters/TagFilterField";
import { CategoryFilterField } from "../../../../components/ExtendedFilters/CategoryFilterField";

import { systemFilterCell } from "../../Helpers/ArticleListHelpers";
import {
  columns,
  columnExtensions,
  hiddenColumns,
  textColumns,
  boolColumns,
  boolFilterOperations,
  dateColumns,
  dateFilterOperations,
  currentPage,
  dataTypeProviders,
} from "../../Helpers/list";
import { ArticleStatus, PageSize } from "../../../../helpers/enums";
const availableStatuses = [
  {
    name: ArticleStatus.Draft.name,
    value: ArticleStatus.Draft.value,
  },
  {
    name: ArticleStatus.Confirmed.name,
    value: ArticleStatus.Confirmed.value,
  },
  {
    name: ArticleStatus.ToPublish.name,
    value: ArticleStatus.ToPublish.value,
  },
  {
    name: ArticleStatus.Published.name,
    value: ArticleStatus.Published.value,
  },
  {
    name: ArticleStatus.Withdrawed.name,
    value: ArticleStatus.Withdrawed.value,
  },
  {
    name: ArticleStatus.Archived.name,
    value: ArticleStatus.Archived.value,
  },
];

export const ArticleList = ({ ...props }) => {
  const [openRight, setOpenRight] = useState(false);
  const [isPopoverClicked, setIsPopoverClicked] = useState(false);
  const [targetId, setTargetId] = useState(null);

  const drawerClicked = (event) => {
    const target = event.target.closest("button");
    if (target) {
      setTargetId(target.id);
      setIsPopoverClicked(true);
    } else {
      setIsPopoverClicked(false);
    }
  };

  if (!openRight && isPopoverClicked) {
    setIsPopoverClicked(false);
  }
  return (
    <Fragment>
      <Drawer
        right
        className="drawer-content-wrapper drawer-content-wrapper--below-header p-0"
        width={450}
        open={openRight}
        onChange={(_) => setOpenRight(!openRight)}
        noTouchOpen={true}
        noTouchClose={true}
      >
        <RSC onClick={(event) => drawerClicked(event)}>
          <h3 className="drawer-heading">Wyszukiwanie zaawansowane</h3>
          <div className="drawer-section">
            {props.canChangeStatus && (
              <FormGroup>
                <Label for="status">Status</Label>
                <StatusFilterField
                  columnName="status"
                  availableValues={["contains", "notContains"]}
                  availableStatuses={availableStatuses}
                  filters={props.extraFilters}
                  onChangeFilters={props.onChangeExtraFilters}
                  isOpen={openRight}
                  isPopoverClicked={isPopoverClicked}
                  targetId={targetId}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="version">Wersja</Label>
              <FilterField
                columnName="version"
                availableValues={["equal", "notEqual"]}
                filters={props.extraFilters}
                onChangeFilters={props.onChangeExtraFilters}
                isOpen={openRight}
                isPopoverClicked={isPopoverClicked}
                targetId={targetId}
              >
                {({ name, placeholder, disabled, filter, onChange }) => (
                  <Input
                    type="number"
                    name={name}
                    step="1"
                    min="0"
                    placeholder={placeholder}
                    onChange={(e) => {
                      onChange(e.target.valueAsNumber);
                    }}
                    disabled={disabled}
                    defaultValue={filter && filter.value}
                  />
                )}
              </FilterField>
            </FormGroup>
            <FormGroup>
              <Label for="tag">Tagi</Label>
              <TagFilterField
                columnName="tags"
                availableValues={["contains", "notContains"]}
                filters={props.extraFilters}
                onChangeFilters={props.onChangeExtraFilters}
                isOpen={openRight}
                isPopoverClicked={isPopoverClicked}
                targetId={targetId}
              />
            </FormGroup>
            <FormGroup>
              <Label for="tag">Kategoria</Label>
              <CategoryFilterField
                columnName="categories"
                availableValues={["contains", "notContains"]}
                filters={props.extraFilters}
                onChangeFilters={props.onChangeExtraFilters}
                isOpen={openRight}
                isPopoverClicked={isPopoverClicked}
                targetId={targetId}
                saveSelected={props.saveSelectedFilters}
                categorySelected={props.categorySelected}
              />
            </FormGroup>
            <Button
              className="mb-2 mr-2 btn-icon"
              color="info"
              onClick={(_) => {
                props.onReload(0, PageSize.Rows20, [], []);
                setOpenRight(!openRight);
              }}
            >
              <i className="pe-7s-refresh-2 btn-icon-wrapper"> </i>
              Filtruj
            </Button>
          </div>
        </RSC>
      </Drawer>
      <Grid
        items={props.items}
        totalItems={props.totalItems}
        columns={columns}
        columnExtensions={columnExtensions}
        hiddenColumns={hiddenColumns}
        textColumns={textColumns}
        boolColumns={boolColumns}
        boolFilterOperations={boolFilterOperations}
        dateColumns={dateColumns}
        dateFilterOperations={dateFilterOperations}
        currentPage={currentPage}
        filterCell={systemFilterCell}
        onReload={props.onReload}
        addable={false}
        removeable={false}
        onRowClick={props.onRowClick}
        dataTypeProviders={dataTypeProviders}
        internationalizable={props.internationalizable}
        language={props.language}
        languages={props.languages}
        onChangeLanguage={props.onChangeLanguage}
        drawerable={props.drawerable && props.filterable}
        changeDrawerState={(_) => setOpenRight(!openRight)}
        filters={props.filters}
        onChangeFilters={props.onChangeFilters}
        onClearFilters={props.onClearFilters}
      />
    </Fragment>
  );
};
