import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import isEqual from 'lodash.isequal';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { RepositoryConfigList } from './RepositoryConfigList';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, PageSize } from '../../../../helpers/enums';
import { ApplicationActionCreators } from '../../../../actions/application';
import { SettingActionCreators } from '../../../../actions/settings';
import {FilterActionCreators} from '../../../../actions/filter';

class RepositoryList extends Component {
	componentDidMount() {
		const { loadRepositoryConfigs, repositoryFilters } = this.props;
		loadRepositoryConfigs(0, PageSize.Rows20, repositoryFilters, []);
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}

	onRowClick = (row) => this.props.goTo(RouteUrls.Setting.repository.editFunc(row.id));

	onReload = (page, size, filters, sortings) => {
		const { loadRepositoryConfigs, repositoryFilters } = this.props;
		loadRepositoryConfigs(page, size, repositoryFilters, sortings);
	};

	clearFilters = () => {
		const {  loadRepositoryConfigs, clearFilters } = this.props;
		clearFilters([ 'repositoryFilters' ], []);
		loadRepositoryConfigs(0, PageSize.Rows20, [], []);
	};

	onRemove = (removeIds) => this.props.removeRepositoryConfigs(removeIds);

	onAdd = () => this.props.goTo(RouteUrls.Setting.repository.create);

	render() {
		const {
			repositoryConfigs,
			totalRepositoryConfigs,
			repositoryListBlocking,
			repositoryFilters,
			changeFilters
		} = this.props;
		return (
			<Fragment>
				<PageTitle heading="Lista grup plików" icon="pe-7s-settings icon-gradient bg-tempting-azure" />

				<BlockUi tag="div" blocking={repositoryListBlocking} loader={<Loader active type={LoaderType} />}>
					<RepositoryConfigList
						items={repositoryConfigs}
						totalItems={totalRepositoryConfigs}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRemove={this.onRemove}
						onRowClick={this.onRowClick}
						internationalizable={true}
						// language={activeLanguage}
						// languages={languages}
						// onChangeLanguage={changeApplicationLanguage}
						filters={repositoryFilters}
						onChangeFilters={(filters) => changeFilters([ 'repositoryFilters' ], filters)}
						onClearFilters={this.clearFilters}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		repositoryFilters: state.filter.repositoryFilters || [],
		repositoryConfigs: state.setting.repositoryConfigs || [],
		totalRepositoryConfigs: state.setting.totalRepositoryConfigs || 0,
		repositoryListBlocking: state.uiBlockState.repositoryListBlocking || false,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators(
			{ ...SettingActionCreators, ...ApplicationActionCreators, ...FilterActionCreators },
			dispatch
		),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RepositoryList);
