import uuid from "uuid/v4";
import { format } from "date-fns";

import { IdentificationDocumentType, Sex } from "../helpers/enums";

export const Auth = {
  isActive: false,
  isAdmin: false,
  isSystem: false,
  excludeCategories: [],
  role: null,
  roles: [],
  totalRoles: 0,
  user: {
    id: 0,
    identityUserId: uuid(),
    userName: "",
    firstname: "",
    surname: "",
    pesel: "",
    birthDate: format(new Date(1900, 0, 1), "yyyy-MM-dd HH:mm:ss"),
    identificationDocumentType: IdentificationDocumentType.Unknown.value,
    identificationDocumentNumber: "",
    sex: Sex.Unknown.value,
    phone: "",
    mobilePhone: "",
    fax: "",
    email: "",
    isActive: false,
    isConfirmed: false,
    confirmedDate: format(new Date(1900, 0, 1), "yyyy-MM-dd HH:mm:ss"),
    roles: [],
    excludeCategories: [],
  },
  userPermissions: [],
  fetchedPermissions: false,
  users: [],
  totalUsers: 0,
};
