import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import ArticleCard from "./Card/Article";
import ArticleList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const CommonArticles = () => (
  <Fragment>
    <Switch>
      <Route
        path={`${RouteUrls.Article.common.create}/:lang?`}
        component={ArticleCard}
      />
      <Route
        path={`${RouteUrls.Article.common.edit}/:id/:lang?`}
        component={ArticleCard}
      />
      <Route
        path={`${RouteUrls.Article.common.preview}/:id/:lang?`}
        component={ArticleCard}
      />
      <Route path="*" component={ArticleList} />
    </Switch>
  </Fragment>
);

export default CommonArticles;
