import * as Tag from './tag';
import * as IsoCode from './isoCode';
import * as DictionaryEntry from './dictionaryElement';
import { createAction } from '../base';

const UnloadDictionaryAction = createAction('UnloadDictionaryAction');

function unloadDictionary() {
	return {
		type: UnloadDictionaryAction.Request
	};
}

export const DictionaryActionCreators = { 
	unloadDictionary, 
	...Tag.TagActionCreators, 
	...DictionaryEntry.DictionaryEntryActionCreators,
	...IsoCode.IsoCodeActionCreators 
};
export const DictionaryActions        = { 
	UnloadDictionaryAction, 
	...Tag.TagActions, 
	...DictionaryEntry.DictionaryEntryActions,
	...IsoCode.IsoCodeActions 
};
