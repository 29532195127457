import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from '../../utils/classnames';
import isEqual from 'lodash.isequal';
import { connect } from 'react-redux';
import { UserActionCreators } from '../../actions/auth/user';
import findindex from 'lodash.findindex';
import ConfirmModal from '../ConfirmModal';

class TreeViewNodeNotDragable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			checked: props.checked,
			isModalOpen: false
		};
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.checked, this.props.checked)) {
			this.setState({
				checked: this.props.checked
			});
		}
	}

	toggleNode = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (this.props.toggleNode && typeof this.props.toggleNode === 'function') {
			this.props.toggleNode(this.props.path);
		}
	};

	openModal() {
		this.setState({
			isModalOpen: true
		});
	}

	getIds(el, ids) {
		let all = ids;

		for (let i = 0; i < el.children.length; i++) {
			all = this.getIds(el.children[i], all);
		}

		all.push(el.id);

		return all;
	}

	changeChecked = (sub, e) => {
		const { onChangeChecked = Function.prototype } = this.props;

		this.setState(
			{
				isModalOpen: false
			},
			() => {
				onChangeChecked({
					sub,
					checked: this.state.checked,
					ids: sub ? this.getIds(this.props.node, []) : this.props.node.id
				});
			}
		);
	};

	checkIfExistsDifStateSub(node, checked) {
		const { excludeCategories } = this.props;
		const found =
			node.children.length > 0 &&
			findindex(node.children, (c) => {
				const index = findindex(excludeCategories, (ec) => ec === c.id);

				return checked ? index === -1 : index > -1;
			}) > -1;
		const subFound =
			node.children.length > 0 && findindex(node.children, (c) => this.checkIfExistsDifStateSub(c, checked)) > -1;

		return found || subFound;
	}

	handleChecked = (node) => {
		const { checked } = this.state;

		node.children.length > 0 && this.checkIfExistsDifStateSub(node, checked)
			? this.openModal()
			: this.changeChecked(false);
	};

	render() {
		const {
			scaffoldBlockPxWidth,
			toggleChildrenVisibility,
			connectDragPreview,
			connectDragSource,
			connectDropTarget,
			isDragging,
			canDrop,
			canDrag,
			node,
			title,
			displayText,
			subtitle,
			languages,
			draggedNode,
			path,
			treeIndex,
			isSearchMatch,
			isSearchFocus,
			buttons,
			className,
			style,
			didDrop,
			treeId,
			isOver, // Not needed, but preserved for other renderers
			parentNode, // Needed for dndManager
			rowDirection,
			...otherProps
		} = this.props;
		const { checked, isModalOpen } = this.state;
		const nodeTitle = displayText || node.displayText;
		const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;

		const isLandingPadActive = !didDrop && isDragging;

		let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
		if (rowDirection === 'rtl') {
			buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
		}

		const checkboxProps = {};

		if (checked && this.checkIfExistsDifStateSub(node, checked)) {
			checkboxProps.indeterminate = true;
		}

		return (
			<Fragment>
				<ConfirmModal
					open={isModalOpen}
					onSubmit={this.changeChecked.bind(this, true)}
					onCancel={this.changeChecked.bind(this, false)}
					title="Potwierdzenie zmiany uprawnień"
					content={`Czy ${checked ? 'odebrać' : 'nadać'} uprawnienia podkategoriom?`}
				/>
				<div
					style={{ height: '100%' }}
					{...otherProps}
					key={node.id}
					className={isEqual(otherProps.selectedPath, path) ? 'rst--toggle' : ''}
					onClick={this.toggleNode}
				>
					{toggleChildrenVisibility &&
					node.children &&
					(node.children.length > 0 || typeof node.children === 'function') && (
						<div>
							<button
								type="button"
								aria-label={node.expanded ? 'Collapse' : 'Expand'}
								className={classnames(
									node.expanded ? 'rst__collapseButton' : 'rst__expandButton',
									rowDirectionClass
								)}
								style={buttonStyle}
								onClick={() =>
									toggleChildrenVisibility({
										node,
										path,
										treeIndex
									})}
							/>
							{node.expanded && (
								<div
									style={{ width: scaffoldBlockPxWidth }}
									className={classnames('rst__lineChildren', rowDirectionClass)}
								/>
							)}
						</div>
					)}

					<div className={classnames('rst__rowWrapper', rowDirectionClass)}>
						<div
							className={classnames(
								'rst__row',
								isLandingPadActive && 'rst__rowLandingPad',
								isLandingPadActive && !canDrop && 'rst__rowCancelPad',
								isSearchMatch && 'rst__rowSearchMatch',
								isSearchFocus && 'rst__rowSearchFocus',
								rowDirectionClass,
								className
							)}
							style={style}
						>
							<div className="rst__checkbox rst__checkbox-input">
								<input
									key={`${checked ? 'un' : ''}checked__${node.id}`}
									id={`checkbox_${node.id}`}
									type="checkbox"
									checked={checked}
								/>
								<label
									for={`checkbox_${node.id}`}
									onClick={this.handleChecked.bind(this, node)}
									className="rst__checkbox-sign"
								/>
							</div>
							<div className={classnames('rst__rowContents', rowDirectionClass)}>
								<div
									style={{ width: '100%' }}
									className={classnames('rst__rowLabel', rowDirectionClass)}
								>
									<span className="rst__labelCont">
										<span className="rst__labelText">
											{typeof nodeTitle === 'function' ? (
												nodeTitle({
													node,
													path,
													treeIndex
												})
											) : (
												nodeTitle
											)}
										</span>
										{this.checkIfExistsDifStateSub(node, true) && (
											<div className="rst__labelIcon">
												<svg
													enable-background="new 0 0 24 24"
													viewBox="0 0 24 24"
													fill="black"
													width="18px"
													height="18px"
												>
													<title>
														Kategoria zawiera przynajmniej jedną podkategorię z nadanymi
														uprawnieniami
													</title>
													<rect fill="none" height="24" width="24" />
													<path d="M14,10H2v2h12V10z M14,6H2v2h12V6z M2,16h8v-2H2V16z M21.5,11.5L23,13l-6.99,7l-4.51-4.5L13,14l3.01,3L21.5,11.5z" />
												</svg>
											</div>
										)}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

TreeViewNodeNotDragable.defaultProps = {
	isSearchMatch: false,
	isSearchFocus: false,
	canDrag: false,
	toggleChildrenVisibility: null,
	buttons: [],
	className: '',
	style: {},
	parentNode: null,
	draggedNode: null,
	canDrop: false,
	title: null,
	checked: false,
	displayText: null,
	rowDirection: 'ltr'
};

TreeViewNodeNotDragable.propTypes = {
	node: PropTypes.shape({}).isRequired,
	title: PropTypes.oneOfType([ PropTypes.func, PropTypes.node ]),
	displayText: PropTypes.oneOfType([ PropTypes.func, PropTypes.node ]),
	path: PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])).isRequired,
	treeIndex: PropTypes.number.isRequired,
	treeId: PropTypes.string.isRequired,
	isSearchMatch: PropTypes.bool,
	isSearchFocus: PropTypes.bool,
	canDrag: PropTypes.bool,
	scaffoldBlockPxWidth: PropTypes.number.isRequired,
	toggleChildrenVisibility: PropTypes.func,
	buttons: PropTypes.arrayOf(PropTypes.node),
	className: PropTypes.string,
	style: PropTypes.shape({}),

	// Drag and drop API functions
	// Drag source
	connectDragPreview: PropTypes.func.isRequired,
	connectDragSource: PropTypes.func.isRequired,
	parentNode: PropTypes.shape({}), // Needed for dndManager
	isDragging: PropTypes.bool.isRequired,
	didDrop: PropTypes.bool.isRequired,
	draggedNode: PropTypes.shape({}),
	// Drop target
	isOver: PropTypes.bool.isRequired,
	canDrop: PropTypes.bool,

	// rtl support
	rowDirection: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
	const excludeCategories = ownProps.excludeCategories || [];

	return {
		checked: !excludeCategories.includes(ownProps.node.id),
		excludeCategories,
		...ownProps
	};
};

const mapDispatchToProps = (dispatch, ownProp) => {
	return {
		setCategoryPermissionUserData: (id) => dispatch(UserActionCreators.setCategoryPermissionUserData(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeViewNodeNotDragable);
