import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { Formik } from "formik";
import classnames from "classnames";
import {
  Form,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  Input,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import Immutable from "seamless-immutable";
import Flag from "react-flagkit";
import Sticky from "react-stickynode";
import filter from "lodash.filter";
import isEmpty from "lodash.isempty";
import map from "lodash.map";

import { SettingActionCreators } from "../../../../actions/settings";
import { DictionaryActionCreators } from "../../../../actions/dictionary";
import { ApplicationActionCreators } from "../../../../actions/application";
import { LanguageValidationSchema } from "../../../../validators";
import PageTitle from "../../../../Layout/AppMain/PageTitle";
import {
  LoaderType,
  DefaultLanguage,
  LanguageStatus,
  PageSize,
  PopoverPlacement,
  DateFormats,
  DefaultFormikConfig,
} from "../../../../helpers/enums";
import { StandardElementTranslationList } from "./StandardElementTranslationList";
import { MenuElementTranslationList } from "./MenuElementTranslationList";
import { AVAILABLE_ISO_CODE } from "../../../../helpers/isoCode";

import { CONSTANTS } from "@constants";
import { FilterActionCreators } from "../../../../actions/filter";

const {
  NAME,
  COMMON_INFORMATION,
  ACTIVE_LANGUAGE,
  DEACTIVE_LANGUAGE,
  LOCAL_NAME,
  STATUS,
  INFORMATION,
  ISO_CODE_SHOULD_CONTAINS_EXCEPT,
  ENGLAND,
  REGIONAL_LANGUAGE,
  NORTHERN_IRELAND,
  SCOTLAND,
  WALES,
  FLAG,
  DATE_FORMAT,
  TRANSLATIONS,
  FIXED_ELEMENTS,
  MENU_ELEMENTS,
  LANGUAGE_TRANSLATION_DANGER,
  LANGUAGE_TRANSLATION_WARNING,
  LANGUAGE_TRANSLATION_INFO,
  ISO_CODE,
  BACK,
} = CONSTANTS;

const Tab = {
  TextElements: 1,
  MenuElements: 2,
};

const mergeItemsWithErrors = (items, errors) =>
  map(items, (item, i) => {
    return { ...item, errors: errors && errors.items ? errors.items[i] : null };
  });

class LanguageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translationTabId: Tab.TextElements,
      menuElementListCurrentPage: 0,
      menuElementListPageSize: PageSize.Rows20,
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.getLanguage(id);
    }
    this.props.getAllIsoCodes();
  }

  componentDidUpdate(prevProps) {
    const { id: prevId } = prevProps.match.params;
    const { id } = this.props.match.params;
    if (prevId && id && prevId !== id) {
      this.props.getLanguage(id);
    }
  }

  componentWillUnmount() {
    this.props.unloadSetting();
    this.props.getAppParamLanguages();
  }

  changeTab = (tabId, currentLanguage) => {
    this.props.updateCurrentLanguageState(currentLanguage);
    this.setState({
      translationTabId: tabId,
    });
  };

  onStandardElementsReload = (page, size, filters, sortings, values) => {
    const { language, loadStandardElements } = this.props;

    const newLanguage = Immutable(language).asMutable({ deep: true });
    loadStandardElements(newLanguage.id, page, size, filters, sortings, values);
  };

  onMenuElementsReload = (page, size, filters, sortings, values) => {
    const { language, loadMenuElements } = this.props;

    const newLanguage = Immutable(language).asMutable({ deep: true });
    loadMenuElements(newLanguage.id, page, size, filters, sortings, values);
  };

  onMenuElementChangeCurrentPage = (currentPage) =>
    this.setState({ menuElementListCurrentPage: currentPage });
  onMenuElementChangePageSize = (pageSize) =>
    this.setState({ menuElementListPageSize: pageSize });

  clearUpStandardElements = (standardElementsTranslationItems) =>
    filter(standardElementsTranslationItems, (translation) => {
      if (
        (translation.isTranslationRequired &&
          isEmpty(translation.translation)) ||
        (translation.isTranslationDescriptionRequired &&
          isEmpty(translation.translationDescription))
      ) {
        return false;
      }
      return true;
    });

  clearUpMenuElements = (menuElementsTranslationItems) =>
    filter(menuElementsTranslationItems, (translation) => {
      if (
        isEmpty(translation.translatedDisplayText) ||
        isEmpty(translation.translatedTitle) ||
        (!isEmpty(translation.baseUrl) && isEmpty(translation.translatedUrl))
      ) {
        return false;
      }
      return true;
    });

  clearStandardElementFilters = (values) => {
    const { language, loadStandardElements, clearFilters } = this.props;

    const newLanguage = Immutable(language).asMutable({ deep: true });
    clearFilters(["standardElementTranslationFilters"], []);
    loadStandardElements(newLanguage.id, 0, PageSize.All, [], [], values);
  };

  clearMenuElementFilters = (values) => {
    const { language, loadMenuElements, clearFilters } = this.props;

    const newLanguage = Immutable(language).asMutable({ deep: true });
    clearFilters(["menuElementTranslationFilters"], []);
    loadMenuElements(newLanguage.id, 0, PageSize.Rows20, [], [], values);
  };

  render() {
    const {
      language,
      createLanguage,
      updateLanguage,
      updateLanguageStatus,
      languageCardBlocking,
      cancel,
      availableIsoCode,
      standardElementTranslationFilters,
      menuElementTranslationFilters,
      changeFilters,
    } = this.props;
    const {
      translationTabId,
      menuElementListPageSize,
      menuElementListCurrentPage,
    } = this.state;
    const newLanguage = Immutable(language).asMutable({
      deep: true,
    });
    const disabled = newLanguage.isoCode === DefaultLanguage.isoCode;

    const availableIsoCodeArray = Immutable(availableIsoCode)
      .asMutable({
        deep: true,
      })
      .map((row) => `${row.code} ${row.name}`);

    return (
      <Formik
        {...DefaultFormikConfig}
        initialValues={newLanguage}
        validationSchema={LanguageValidationSchema}
        onReset={cancel}
      >
        {({
          errors,
          values,
          handleChange,
          isValid,
          dirty,
          setFieldValue,
          handleReset: onReset,
        }) => {
          const {
            menuElementsChanged = [],
            menuElementsTranslations = [],
            ...restErrors
          } = errors;

          return (
            <Form
              noValidate
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
                // values.standardElementsTranslations.items = this.clearUpStandardElements(
                // 	values.standardElementsTranslations.items
                // );
                values.standardElementsChanged = this.clearUpStandardElements(
                  values.standardElementsTranslations.items
                );
                values.menuElementsTranslations.items =
                  this.clearUpMenuElements(
                    values.menuElementsTranslations.items
                  );
                values.menuElementsChanged = this.clearUpMenuElements(
                  values.menuElementsChanged
                );
                if (values.id) {
                  updateLanguage(values.id, values);
                  this.onMenuElementChangeCurrentPage(0);
                  this.clearStandardElementFilters(values);
                  this.clearMenuElementFilters(values);
                } else {
                  createLanguage(values);
                }
              }}
              {...{ onReset }}
            >
              <PageTitle
                heading="Dane wersji językowej"
                icon="pe-7s-loop icon-gradient bg-tempting-azure"
              />

              <BlockUi
                tag="div"
                blocking={languageCardBlocking}
                loader={<Loader active type={LoaderType} />}
              >
                <Sticky
                  enabled={true}
                  top=".app-header"
                  innerZ="101"
                  activeClass="sticky-active-class"
                  className="mb-2"
                >
                  <div className="btn-actions-pane-right menu-actions">
                    <Button
                      type="submit"
                      className="border-0 btn-transition btn-icon mr-2"
                      color="primary"
                    >
                      <i className="pe-7s-diskette btn-icon-wrapper"> </i>
                      {values.id > 0 ? "Zapisz" : "Utwórz"}
                    </Button>
                    {!values.isSystem && values.id > 0 && (
                      <Fragment>
                        <Button
                          type="button"
                          className="border-0 btn-transition btn-icon mr-2"
                          color="success"
                          disabled={
                            newLanguage.status === LanguageStatus.Active ||
                            (!isEmpty(restErrors) && !isValid)
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            values.standardElementsChanged =
                              this.clearUpStandardElements(
                                values.standardElementsTranslations.items
                              );
                            values.menuElementsTranslations.items =
                              this.clearUpMenuElements(
                                values.menuElementsTranslations.items
                              );
                            values.menuElementsChanged =
                              this.clearUpMenuElements(
                                values.menuElementsChanged
                              );
                            updateLanguageStatus(
                              values.id,
                              LanguageStatus.Active,
                              values
                            );
                          }}
                        >
                          <i className="pe-7s-diskette btn-icon-wrapper"> </i>
                          {ACTIVE_LANGUAGE}
                        </Button>
                        <Button
                          type="button"
                          className="border-0 btn-transition btn-icon mr-2"
                          color="danger"
                          disabled={
                            newLanguage.status !== LanguageStatus.Active
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            values.standardElementsChanged =
                              this.clearUpStandardElements(
                                values.standardElementsTranslations.items
                              );
                            values.menuElementsTranslations.items =
                              this.clearUpMenuElements(
                                values.menuElementsTranslations.items
                              );
                            values.menuElementsChanged =
                              this.clearUpMenuElements(
                                values.menuElementsChanged
                              );
                            updateLanguageStatus(
                              values.id,
                              LanguageStatus.Inactive,
                              values
                            );
                          }}
                        >
                          <i className="pe-7s-diskette btn-icon-wrapper"> </i>
                          {DEACTIVE_LANGUAGE}
                        </Button>
                        <Button
                          className="border-0 btn-transition btn-icon mr-2"
                          color="secondary"
                          type="reset"
                          style={{ float: "right" }}
                        >
                          <i className="pe-7s-back btn-icon-wrapper" />
                          {BACK}
                        </Button>
                      </Fragment>
                    )}
                  </div>
                </Sticky>
                <Card className="mb-2">
                  <CardHeader>
                    <i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
                    {COMMON_INFORMATION}
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="name">{NAME}</Label>
                          <Input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Wpisz nazwę"
                            value={values.name}
                            onChange={handleChange}
                            invalid={!!errors.name}
                            disabled={disabled}
                          />
                          <FormFeedback>{errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="localName">{LOCAL_NAME}</Label>
                          <Input
                            type="text"
                            id="localName"
                            name="localName"
                            placeholder="Wpisz nazwę lokalną"
                            value={values.localName}
                            onChange={handleChange}
                            invalid={!!errors.localName}
                            disabled={disabled}
                          />
                          <FormFeedback>{errors.localName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="statusName">{STATUS}</Label>
                          <Input
                            type="text"
                            id="statusName"
                            name="statusName"
                            value={values.statusName}
                            onChange={handleChange}
                            invalid={!!errors.statusName}
                            disabled={true}
                          />
                          <FormFeedback>{errors.statusName}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="isoCode">{ISO_CODE}</Label>
                          <Button
                            type="button"
                            color="link"
                            className="btn-icon btn-icon-only"
                            title="Informacje dot. kodów ISO"
                            id="popover-isocode-info"
                          >
                            <i
                              className="pe-7s-info btn-icon-wrapper"
                              style={{ verticalAlign: "baseline" }}
                            />
                          </Button>
                          <Typeahead
                            id="isoCode"
                            options={availableIsoCodeArray}
                            selected={[values.isoCode]}
                            placeholder="Wybierz kod ISO"
                            emptyLabel={"Brak kodu ISO"}
                            disabled={disabled}
                            allowNew={false}
                            onChange={(isoCodes) => {
                              if (isoCodes.length === 1) {
                                setFieldValue(
                                  "isoCode",
                                  isoCodes[0].split(" ")[0]
                                );
                              }
                            }}
                            onInputChange={(isoCode) =>
                              setFieldValue("isoCode", isoCode.split(" ")[0])
                            }
                            isInvalid={!!errors.isoCode}
                            paginationText={"Wczytaj więcej danych.."}
                          />
                          {errors.isoCode && (
                            <FormFeedback style={{ display: "block" }}>
                              {errors.isoCode}
                            </FormFeedback>
                          )}
                        </FormGroup>
                        <UncontrolledPopover
                          placement={PopoverPlacement.Right}
                          trigger="legacy"
                          fade={false}
                          target={"popover-isocode-info"}
                        >
                          <PopoverHeader>{INFORMATION}</PopoverHeader>
                          <PopoverBody>
                            {ISO_CODE_SHOULD_CONTAINS_EXCEPT}
                            <ul>
                              <li>{REGIONAL_LANGUAGE}</li>
                              <li>{ENGLAND}</li>
                              <li>{NORTHERN_IRELAND}</li>
                              <li>{SCOTLAND}</li>
                              <li>{WALES}</li>
                            </ul>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </Col>
                      <Col>
                        <Label for="flag">{FLAG}</Label>
                        <br />
                        {AVAILABLE_ISO_CODE.includes(values.isoCode) && (
                          <Flag
                            country={values.isoCode}
                            size={32}
                            alt="Flaga"
                          />
                        )}
                        {!AVAILABLE_ISO_CODE.includes(values.isoCode) &&
                          values.isoCode !== "" &&
                          availableIsoCode.find(
                            (row) => row.code === values.isoCode
                          ) != null && (
                            <img
                              className={`mr-2 opacity-8`}
                              src={
                                availableIsoCode.find(
                                  (row) => row.code === values.isoCode
                                ).file.path
                              }
                              alt={`Grafika ${
                                availableIsoCode.find(
                                  (row) => row.code === values.isoCode
                                ).file.fileName
                              }`}
                            />
                          )}
                      </Col>

                      <Col>
                        <FormGroup>
                          <Label for="dateFormat">{DATE_FORMAT}</Label>
                          <Typeahead
                            id="dateFormat"
                            options={DateFormats}
                            placeholder="Wpisz format daty"
                            selected={
                              values.dateFormat ? [values.dateFormat] : []
                            }
                            multiple={false}
                            allowNew={false}
                            onChange={(dateFormats) => {
                              if (
                                !isEmpty(dateFormats) &&
                                dateFormats.length === 1
                              ) {
                                setFieldValue("dateFormat", dateFormats[0]);
                              } else {
                                setFieldValue("dateFormat", "");
                              }
                            }}
                            isInvalid={!!errors.dateFormat}
                          />
                          {errors.dateFormat && (
                            <FormFeedback style={{ display: "block" }}>
                              {errors.dateFormat}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {((values.standardElementsTranslations &&
                  !isEmpty(values.standardElementsTranslations.items)) ||
                  (values.menuElementsTranslations &&
                    !isEmpty(values.menuElementsTranslations.items))) && (
                  <Card className="mb-2" tabs>
                    <CardHeader className="card-header-tab">
                      <CardTitle>{TRANSLATIONS}</CardTitle>
                      <Nav>
                        {values.standardElementsTranslations &&
                          !isEmpty(
                            values.standardElementsTranslations.items
                          ) && (
                            <NavItem>
                              <NavLink
                                href="javascript:void(0);"
                                className={classnames({
                                  active: translationTabId === Tab.TextElements,
                                })}
                                onClick={() => {
                                  this.changeTab(Tab.TextElements, values);
                                }}
                              >
                                {FIXED_ELEMENTS}
                              </NavLink>
                            </NavItem>
                          )}
                        {values.menuElementsTranslations &&
                          !isEmpty(values.menuElementsTranslations.items) && (
                            <NavItem>
                              <NavLink
                                href="javascript:void(0);"
                                className={classnames({
                                  active: translationTabId === Tab.MenuElements,
                                })}
                                onClick={() => {
                                  this.changeTab(Tab.MenuElements, values);
                                }}
                              >
                                {MENU_ELEMENTS}
                              </NavLink>
                            </NavItem>
                          )}
                      </Nav>
                    </CardHeader>
                    <CardBody>
                      <TabContent activeTab={translationTabId}>
                        <TabPane
                          key={`tab-pane-${Tab.TextElements}`}
                          tabId={Tab.TextElements}
                        >
                          {(!isEmpty(errors.standardElementsTranslations) ||
                            !isEmpty(errors.standardElementsChanged)) &&
                            values.status === LanguageStatus.Inactive && (
                              <Alert color="danger">
                                {LANGUAGE_TRANSLATION_DANGER}
                              </Alert>
                            )}
                          {(!isEmpty(errors.standardElementsTranslations) ||
                            !isEmpty(errors.standardElementsChanged)) &&
                            values.status === LanguageStatus.Active && (
                              <Alert color="warning">
                                {LANGUAGE_TRANSLATION_WARNING}
                              </Alert>
                            )}
                          {(!isEmpty(errors.standardElementsTranslations) ||
                            !isEmpty(errors.standardElementsChanged)) && (
                            <Alert color="info">
                              {LANGUAGE_TRANSLATION_INFO}
                            </Alert>
                          )}
                          <div className="language-edit-table">
                            <StandardElementTranslationList
                              items={mergeItemsWithErrors(
                                values.standardElementsTranslations.items,
                                errors.standardElementsTranslations
                              )}
                              totalItems={
                                values.standardElementsTranslations.totalItems
                              }
                              onReload={(page, size, filters, sortings) =>
                                this.onStandardElementsReload(
                                  page,
                                  size,
                                  filters,
                                  sortings,
                                  values
                                )
                              }
                              onEdit={(index, item) => {
                                item.translation = item.translation.trimStart();
                                item.translationDescription =
                                  item.translationDescription.trimStart();
                                setFieldValue(
                                  `standardElementsTranslations.items.${index}`,
                                  { ...item, isChanged: true }
                                );
                                setTimeout(() => {
                                  const standardElementChangedIndex =
                                    values.standardElementsChanged.findIndex(
                                      (o) => o.id === item.id
                                    );
                                  if (standardElementChangedIndex !== -1) {
                                    setFieldValue(
                                      `standardElementsChanged.${standardElementChangedIndex}`,
                                      { ...item, isChanged: true }
                                    );
                                  } else {
                                    values.standardElementsChanged.push({
                                      ...item,
                                      isChanged: true,
                                    });
                                    setFieldValue(
                                      "standardElementsChanged",
                                      values.standardElementsChanged
                                    );
                                  }
                                }, 10);
                              }}
                              filters={standardElementTranslationFilters}
                              onChangeFilters={(filters) =>
                                changeFilters(
                                  ["standardElementTranslationFilters"],
                                  filters
                                )
                              }
                              onClearFilters={() =>
                                this.clearStandardElementFilters(values)
                              }
                            />
                          </div>
                        </TabPane>
                        <TabPane
                          key={`tab-pane-${Tab.MenuElements}`}
                          tabId={Tab.MenuElements}
                        >
                          <div className="language-edit-table">
                            <MenuElementTranslationList
                              items={mergeItemsWithErrors(
                                values.menuElementsTranslations.items,
                                errors.menuElementsTranslations
                              )}
                              totalItems={
                                values.menuElementsTranslations.totalItems
                              }
                              currentPage={menuElementListCurrentPage}
                              pageSize={menuElementListPageSize}
                              onChangeCurrentPage={
                                this.onMenuElementChangeCurrentPage
                              }
                              onChangePageSize={
                                this.onMenuElementChangePageSize
                              }
                              onReload={(page, size, filters, sortings) =>
                                this.onMenuElementsReload(
                                  page,
                                  size,
                                  filters,
                                  sortings,
                                  values
                                )
                              }
                              onEdit={(index, item) => {
                                setFieldValue(
                                  `menuElementsTranslations.items.${index}`,
                                  {
                                    ...item,
                                    isChanged: true,
                                  }
                                );
                                setTimeout(() => {
                                  const menuElementsChangedIndex =
                                    values.menuElementsChanged.findIndex(
                                      (o) => o.id === item.id
                                    );
                                  if (menuElementsChangedIndex !== -1) {
                                    setFieldValue(
                                      `menuElementsChanged.${menuElementsChangedIndex}`,
                                      { ...item, isChanged: true }
                                    );
                                  } else {
                                    values.menuElementsChanged.push({
                                      ...item,
                                      isChanged: true,
                                    });
                                    setFieldValue(
                                      "menuElementsChanged",
                                      values.menuElementsChanged
                                    );
                                  }
                                }, 10);
                              }}
                              filters={menuElementTranslationFilters}
                              onChangeFilters={(filters) =>
                                changeFilters(
                                  ["menuElementTranslationFilters"],
                                  filters
                                )
                              }
                              onClearFilters={() =>
                                this.clearMenuElementFilters(values)
                              }
                            />
                          </div>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                )}
              </BlockUi>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state, ownProp) => {
  return {
    language: state.setting.language || {
      id: 0,
      name: "",
      localName: "",
      isoCode: "",
      dateFormat: "",
      standardElementsTranslations: {
        totalItems: 0,
        items: [],
      },
      menuElementsTranslations: {
        totalItems: 0,
        items: [],
      },
    },
    languageCardBlocking: state.uiBlockState.languageCardBlocking || false,
    availableIsoCode: state.dictionary.isoCode.isoCodes || [],
    standardElementTranslationFilters:
      state.filter.standardElementTranslationFilters || [],
    menuElementTranslationFilters:
      state.filter.menuElementTranslationFilters || [],
  };
};

const mapDispatchToProps = (dispatch, ownProp) => {
  return {
    ...bindActionCreators(
      {
        ...SettingActionCreators,
        ...DictionaryActionCreators,
        ...FilterActionCreators,
        ...ApplicationActionCreators,
      },
      dispatch
    ),
    cancel: () => dispatch(goBack()),
    goTo: (url) => dispatch(push(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageCard);
