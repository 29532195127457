import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";

import { TinyMCE } from "../../../../../components/TinyMCE";

import { CONSTANTS } from "@constants";

export const ArticleModalContent = ({
  isOpen,
  toggle,
  setFieldValue,
  extraContent,
  maxLengthExtraContent,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (isOpen) {
      setValue(extraContent);
    }
  }, [extraContent, isOpen]);

  const { CONFIRM, CANCEL, EXTRA_CONTENT } = CONSTANTS;

  const isMaxLengthError =
    value?.replace(/<[^>]+>/g, "").length > maxLengthExtraContent;

  return (
    <Modal isOpen={isOpen} backdrop size="xl">
      <ModalHeader>
        <div className="d-flex justify-content-between">
          <span className="d-block">{EXTRA_CONTENT}</span>
          <div>
            <Button
              className="btn-icon mr-2"
              color="alternate"
              type="submit"
              disabled={isMaxLengthError}
              form="form-link"
              onClick={() => {
                if (!isMaxLengthError) {
                  setFieldValue("extraContent", value);
                  toggle();
                }
              }}
            >
              <i className="pe-7s-check btn-icon-wrapper" />
              {CONFIRM}
            </Button>
            <Button
              className="btn-icon mr-2"
              color="secondary"
              type="button"
              onClick={() => toggle()}
            >
              <i className="pe-7s-back btn-icon-wrapper" />
              {CANCEL}
            </Button>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div className={isMaxLengthError && "border border-danger"}>
              <TinyMCE
                id="extraContent"
                name="extraContent"
                config={{
                  plugins: "",
                  height: 120,
                  toolbar: "bold italic underline strikethrough forecolor",
                  menubar: false,
                }}
                available_paste={false}
                multiline={false}
                content={extraContent}
                onContentChanged={(extraContent) => {
                  setValue(extraContent);
                }}
                disabled={false}
              />
            </div>
            {isMaxLengthError ? (
              <FormFeedback className={isMaxLengthError && "d-block"}>
                Maksymalna dozwolona liczba znaków wynosi{" "}
                {maxLengthExtraContent}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
