import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import isEqual from 'lodash.isequal';

import { TagList } from './TagList';
import { DictionaryActionCreators } from '../../../../actions/dictionary';
import { ApplicationActionCreators } from '../../../../actions/application';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, DefaultLanguage, PageSize } from '../../../../helpers/enums';
import { FilterActionCreators } from '../../../../actions/filter';

class Tags extends Component {
	componentDidMount() {
		const { activeLanguage, getAllTags, tagFilters } = this.props;
		getAllTags(activeLanguage.isoCode, 0, PageSize.Rows20, tagFilters, []);
	}

	componentDidUpdate(prevProps) {
		const { activeLanguage, getAllTags, tagFilters } = this.props;
		if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
			getAllTags(activeLanguage.isoCode, 0, PageSize.Rows20, tagFilters, []);
		}
	}

	componentWillUnmount() {
		this.props.unloadDictionary();
	}

	onAdd = () => {
		this.props.goTo(RouteUrls.Dictionary.tag.create);
	};

	onRemove = (removeIds) => this.props.removeTags(removeIds);

	onReload = (page = 0, size = 20, filters = [], sortings = []) => {
		const { activeLanguage, getAllTags, tagFilters } = this.props;
		getAllTags(activeLanguage.isoCode, page, size, tagFilters, sortings);
	};
	onRowClick = (row) => this.props.goTo(RouteUrls.Dictionary.tag.editFunc(row.id));

	clearFilters = () => {
		const { activeLanguage, getAllTags, clearFilters } = this.props;
		clearFilters(['tagFilters'], []);
		getAllTags(activeLanguage.isoCode, 0, PageSize.Rows20, [], []);
	};

	render() {
		const {
			tags,
			totalTags,
			tagListBlocking,
			languages,
			activeLanguage,
			changeApplicationLanguage,
			tagFilters,
			changeFilters
		} = this.props;
		return (
			<Fragment>
				<PageTitle heading="Tagi" icon="pe-7s-settings icon-gradient bg-tempting-azure" />
				<BlockUi tag="div" blocking={tagListBlocking} loader={<Loader active type={LoaderType} />}>
					<TagList
						items={tags}
						totalItems={totalTags}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRemove={this.onRemove}
						onRowClick={this.onRowClick}
						internationalizable={true}
						language={activeLanguage}
						languages={languages}
						onChangeLanguage={changeApplicationLanguage}
						filters={tagFilters}
						onChangeFilters={(filters) => changeFilters(['tagFilters'], filters)}
						onClearFilters={this.clearFilters}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		tagFilters: state.filter.tagFilters || [],
		tags: state.dictionary.tag.tags || [],
		totalTags: state.dictionary.tag.totalTags || 0,
		activeLanguage: state.application.activeLanguage,
		languages: state.application.languages || [ DefaultLanguage ], //getActiveLanguagesSelector(state) || [ DefaultLanguage ]
		tagListBlocking: state.uiBlockState.tagListBlocking || false
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators(
			{ ...DictionaryActionCreators, ...ApplicationActionCreators, ...FilterActionCreators },
			dispatch
		),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
