import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import axios from 'axios';

import { SettingActions } from '../../actions/settings';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const TILE_URL = '/api/settings/tiles';

function* loadTiles(action) {
	try {
		let tiles = yield call(getListRequest, {
			url: TILE_URL,
			lang: action.payload.lang,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: SettingActions.LoadTiles.Success,
			payload: tiles
		});
	} catch (err) {
		yield put({
			type: SettingActions.LoadTiles.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy kafli');
	}
}

function* getTile(action) {
	try {
		let tile = yield call(getTileRequest, action.payload);

		yield put({
			type: SettingActions.GetTile.Success,
			payload: tile
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetTile.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych kafla');
	}
}
function getTileRequest(id) {
	return axios.get(`${TILE_URL}/${id}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateTile(action) {
	try {
		let tile = yield call(updateTileRequest, action.payload);

		yield put({
			type: SettingActions.UpdateTile.Success,
			payload: tile
		});
		toast.success('Kafel został zaktualizowany');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateTile.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji danych kafla');
	}
}
function updateTileRequest(tile) {
	return axios.put(`${TILE_URL}/${tile.id}`, tile).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchLoadTiles() {
	yield takeEvery(SettingActions.LoadTiles.Request, loadTiles);
}
function* watchGetTile() {
	yield takeEvery(SettingActions.GetTile.Request, getTile);
}
function* watchUpdateTile() {
	yield takeEvery(SettingActions.UpdateTile.Request, updateTile);
}

export const TileSagas = [ fork(watchLoadTiles), fork(watchGetTile), fork(watchUpdateTile) ];
