import React, { useState } from 'react';

import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Collapse } from 'reactstrap';
import RSC from 'react-scrollbars-custom';

import map from 'lodash.map';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { CONSTANTS } from '@constants';
import { ArticleType } from '../../../../../helpers/enums';
import {Fragment} from 'react';

const DateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

const { CHANGE_LOG } = CONSTANTS;
const HIDDEN_FOR_TYPES = [ ArticleType.TransmissionFromRadniInfo.value ];

export const ChangeLogCard = ({ articleType = ArticleType.Common.value, changeLogEntries = [] }) => {
	const [ cardCollapse, setCardCollapse ] = useState({ all: true });

	const collapseItem = (name = '') => {
		const collapse = cardCollapse && cardCollapse[name] ? cardCollapse[name] || false : false;
		setCardCollapse({ ...cardCollapse, [name]: !collapse });
	};

	return !HIDDEN_FOR_TYPES.includes(articleType) ? (
		<Card className="mb-2">
			<CardHeader onClick={(_) => collapseItem('all')} style={{ cursor: 'pointer' }}>
				<i className="header-icon pe-7s-date icon-gradient bg-malibu-beach" />
				{CHANGE_LOG}
				<div className="btn-actions-pane-right btn-actions-cursor--pointer">
					{cardCollapse['all'] ? 'Zwiń' : 'Rozwiń'}
					{cardCollapse['all'] ? <i className="pe-7s-angle-up" /> : <i className="pe-7s-angle-down" />}
				</div>
			</CardHeader>
			<Collapse isOpen={cardCollapse['all']}>
				<CardBody>
					<div className="scroll-area-md">
						<RSC>
							<ListGroup flush>
								{map(changeLogEntries, (changeLog, changeLogIndex) => {
									const itemName = `change-log-${changeLogIndex}`;
									const open =
										cardCollapse && cardCollapse[itemName]
											? cardCollapse[itemName] || false
											: false;
									return (
										<ListGroupItem
											key={itemName}
											id={itemName}
											onClick={(_) => collapseItem(itemName)}
										>
											<div className="widget-content p-0">
												<div className="widget-content-wrapper">
													<div className="widget-content-left mr-2">
														<i
															className={`pe-7s-angle-right ${open
																? 'animate-rotate-45'
																: ''}`}
														/>
													</div>
													<div className="widget-content-left mr-2">
														<div className="widget-heading">
															{format(new Date(changeLog.createdDate), DateTimeFormat, {
																locale: pl
															})}
														</div>
													</div>
													<div className="widget-content-left">
														<div className="widget-subheading">{changeLog.description}</div>
													</div>
												</div>
											</div>
											<Collapse isOpen={open}>
												<ListGroup>
													{map(changeLog.details, (detail, detailIndex) => (
														<ListGroupItem
															className="border-0 pt-1 pb-1"
															key={`change-log-${changeLogIndex}-detail-${detailIndex}`}
														>
															<div className="widget-content p-0">
																<div className="widget-content-wrapper">
																	<div className="widget-content-left">
																		<div className="widget-subheading">
																			- {detail.description}
																		</div>
																	</div>
																</div>
															</div>
														</ListGroupItem>
													))}
												</ListGroup>
											</Collapse>
										</ListGroupItem>
									);
								})}
							</ListGroup>
						</RSC>
					</div>
				</CardBody>
			</Collapse>
		</Card>
	) : (
		<Fragment />
	);
};
