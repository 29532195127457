import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { createUserManager } from "redux-oidc";
import { OidcConfiguration } from "../../helpers/ocid";

export default () => {
  const { user } = useSelector((state) => state.oidc);
  const authorityServerAddress = useSelector(
    (state) => state.application.serverIdentity.authority
  );
  const clientId = useSelector(
    (state) => state.application.serverIdentity.client_id
  );

  const logoutHandler = async () => {
    const logoutManager = createUserManager({
      ...OidcConfiguration,
      authority: authorityServerAddress,
      client_id: clientId,
      scope: "openid profile offline_access bip-api",
    });
    await logoutManager.signoutRedirect({ id_token: user.id_token });
  };

  return (
    <Fragment>
      <div className={"page-header-shared"}>
        {
          // <a className={'link-mainpage'} href={'/'}>
          // 	<div className={'page-header-left'}>
          // 		<div>
          // 			<img
          // 				src={`${authorityServerAddress}/left-arrow.png`}
          // 				className={'page-header-left-arrow'}
          // 				alt="strzałka powrotu"
          // 			/>
          // 		</div>
          // 	</div>
          // </a>
          <div className={"page-header-left"}>
            <div
              onClick={(e) => {
                e.preventDefault();
                logoutHandler();
              }}
            >
              <div className={"page-header-left-text"}>Wyloguj</div>
            </div>
          </div>
        }
        <div className={"page-header-right"}>
          {/* <img src={`${authorityServerAddress}/lub-logo.png`} className={'page-header-logo'} alt="logo" /> */}
        </div>
      </div>
      <div className="warning-container">
        <div className="warning-container__header">
          <h3 className="header">Nie posiadasz wystarczających uprawnień</h3>

          <p className="icon pe-7s-lock" />
        </div>
      </div>
    </Fragment>
  );
};
