import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash.isempty';
import lodashFilter from 'lodash.filter';
import find from 'lodash.find';
import map from 'lodash.map';
import debounce from 'lodash.debounce';

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';

import { SearchActionCreators } from '../../actions/search';
import { ApplicationActionCreators } from '../../actions/application';
import { FilterField } from './FilterField';

import { CONSTANTS } from '@constants';

const { EMPTY_DATA } = CONSTANTS;

class CategoryFilter extends Component {
	searchCategories = debounce((searchText) => {
		const { searchCategoriesByTitle } = this.props;
		searchCategoriesByTitle(searchText);
	});

	handleChange = (categories, onChange) => {
		const { saveSelected } = this.props;
		if (!isEmpty(categories)) {
			saveSelected(categories);
			onChange(map(categories, (category) => category.id));
		} else {
			saveSelected([]);
			onChange(null);
		}
	};
	render() {
		const {
			columnName,
			availableValues,
			availableCategories,
			isSearching,
			filters,
			onChangeFilters,
			isOpen,
			isPopoverClicked,
			targetId,
			categorySelected
		} = this.props;
		const categoryValues = map(categorySelected.all, (category) => category.id);
		return (
			<FilterField
				columnName={columnName}
				availableValues={availableValues}
				filters={filters}
				onChangeFilters={onChangeFilters}
				isOpen={isOpen}
				isPopoverClicked={isPopoverClicked}
				targetId={targetId}
			>
				{({ name, placeholder, disabled, filter, onChange }) => {
					const selected =
						filter && Array.isArray(filter.value)
							? map(
									lodashFilter(filter.value, (categoryValue) => categoryValues.includes(categoryValue)),
									(id) => find(categorySelected.all, { id: id })
								)
							: filter && filter.value
								? map(
										lodashFilter([ filter.value ], (categoryValue) =>
											categoryValues.includes(categoryValue)
										),
										(id) => find(categorySelected.all, { id: id })
									)
								: [];
					return (
						<AsyncTypeahead
							id={`${name}-filter`}
							name={name}
							labelKey="displayText"
							options={availableCategories}
							isLoading={isSearching}
							onSearch={this.searchCategories}
							searchText={'Trwa wyszukiwanie...'}
							minLength={3}
							selected={selected}
							emptyLabel={EMPTY_DATA}
							clearButton
							multiple
							allowNew={false}
							placeholder={placeholder}
							onChange={(categories) => this.handleChange(categories, onChange)}
							disabled={disabled}
							renderMenuItemChildren={(option, childProps, childIndex) => (
								<div className="d-flex flex-column">
									<div>
										<Highlighter search={childProps.text}>{option.displayText}</Highlighter>
									</div>
									<div>
										<small>{option.textPath}</small>
									</div>
								</div>
							)}
						/>
					);
				}}
			</FilterField>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		isSearching: state.uiBlockState.menuCardBlocking || false,
		availableCategories: state.menu.searchedMenus || []
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators({ ...SearchActionCreators, ...ApplicationActionCreators }, dispatch)
	};
};

export const CategoryFilterField = connect(mapStateToProps, mapDispatchToProps)(CategoryFilter);
