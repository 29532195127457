import { takeEvery, fork, put, call } from 'redux-saga/effects';
import axios from 'axios';

import { SettingActions } from '../../actions/settings';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const systemParametersUrl = '/api/settings/system-parameters';

function* getAllSystemParameters(action) {
	try {
		let systemParameters = yield call(getListRequest, {
			url: systemParametersUrl,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: SettingActions.GetAllSystemParameters.Success,
			payload: systemParameters
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetAllSystemParameters.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy parameterów systemowych');
	}
}

function* getSystemParameter(action) {
	try {
		let systemParameter = yield call(getSystemParameterRequest, action.payload);

		yield put({
			type: SettingActions.GetSystemParameter.Success,
			payload: systemParameter
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetSystemParameter.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych parametru systemowego');
	}
}

function getSystemParameterRequest(id) {
	return axios.get(`${systemParametersUrl}/${id}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateSystemParameter(action) {
	try {
		let systemParameter = yield call(updateSystemParameterRequest, action.payload);

		yield put({
			type: SettingActions.UpdateSystemParameter.Success,
			payload: systemParameter
		});
	} catch (err) {
		yield put({
			type: SettingActions.UpdateSystemParameter.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji danych parametru systemowego');
	}
}

function updateSystemParameterRequest(parameter) {
	return axios
		.put(`${systemParametersUrl}/${parameter.id}`, parameter)
		.then((response) => response.data)
		.catch((err) => {
			throw err;
		});
}

function* watchGetAllSystemParameters() {
	yield takeEvery(SettingActions.GetAllSystemParameters.Request, getAllSystemParameters);
}
function* watchGetSystemParameter() {
	yield takeEvery(SettingActions.GetSystemParameter.Request, getSystemParameter);
}
function* watchUpdateSystemParameter() {
	yield takeEvery(SettingActions.UpdateSystemParameter.Request, updateSystemParameter);
}

export const SystemParameterSagas = [
	fork(watchGetAllSystemParameters),
	fork(watchGetSystemParameter),
	fork(watchUpdateSystemParameter)
];
