import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import DictionaryEntries from './DictionaryEntries';
import Tags from './Tags';
import IsoCodes from './IsoCodes';
import { RouteUrls } from '../../helpers/routeUrls';
import { useSelector } from 'react-redux';
import { getLayoutPermissions } from '../../reselect/permission';

export default ({ match }) => {
	const permissions = useSelector((state) => getLayoutPermissions(state));

	return (
		<Fragment>
			{permissions.accessToDictionaries ? (
				<Switch>
					<Route path={RouteUrls.Dictionary.dictionaryEntry.list} component={DictionaryEntries} />
					<Route path={RouteUrls.Dictionary.tag.list} component={Tags} />
					<Route path={RouteUrls.Dictionary.isoCode.list} component={IsoCodes} />
				</Switch>
			) : (
				<Redirect to={RouteUrls.App.main} />
			)}
		</Fragment>
	);
};
