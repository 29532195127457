import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
	Form,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Input,
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	Label,
	FormFeedback,
} from 'reactstrap';
import { format as dateFnsFormat, endOfDay, isValid as dateFnsIsValid, addMinutes, startOfDay } from 'date-fns';
import ReactDatePicker from 'react-datepicker';

import { DayOfWeeks, DefaultFormikConfig, MultimediaType } from '../../helpers/enums';
import * as yup from 'yup';
import { CONSTANTS } from '@constants';
import { TimeInput } from '../DatepickerTimeInput';

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'HH:mm';
export const TIME_INTERVAL = 15;
const { CONFIRM, CANCEL } = CONSTANTS;

export const ArticleMultimediaValidationSchema = yup.object().shape({
	dayOfWeek: yup
		.number()
		.oneOf(
			[ MultimediaType.Video.value, MultimediaType.Audio.value ],
			`Typ odnośnika musi zawierać wartość: ${MultimediaType.Video.name}, ${MultimediaType.Audio.name}`
		),
	timeFrom: yup
		.string()
		.url('Adres URL musi zawierać prawidłowy format adresu. Musi składać się protokołu, adresu oraz domeny')
		.required('Adres URL jest wymagany'),
	timeTo: yup.string().trim().required('Nazwa wyświetlana jest polem wymaganym')
});

const EMPTY_ADMISSION_HOUR = {
	id: 0,
	dayOfWeek: -1,
	timeFrom: '2021-01-01T00:00:00',
	timeTo: '2021-01-01T00:00:00'
};

export const AddAdmissionHourModal = ({
	isOpen = false,
	admissionHour = EMPTY_ADMISSION_HOUR,
	onToggle = (event) => {},
	onSave = (multimedia) => {}
}) => {
	const [ currentAdmissionHour, setCurrentAdmissionHour ] = useState(admissionHour);
	useEffect(
		() => {
			setCurrentAdmissionHour(admissionHour);
			return () => {
				setCurrentAdmissionHour(EMPTY_ADMISSION_HOUR);
			};
		},
		[ admissionHour ]
	);

	const handleCancel = (event) => {
		if (onToggle && typeof onToggle === 'function') {
			onToggle(event);
		}
	};

	const handleSave = (admissionHour) => {
		if (onSave && typeof onSave === 'function') {
			onSave({ ...admissionHour, dayOfWeek: parseInt(admissionHour.dayOfWeek) });
		}

		if (onToggle && typeof onToggle === 'function') {
			onToggle();
		}
	};

	return (
		<Formik
			{...DefaultFormikConfig}
			initialValues={currentAdmissionHour}
			onSubmit={(values) => {
				handleSave(values);
				setCurrentAdmissionHour(EMPTY_ADMISSION_HOUR);
			}}
		>
			{({
				values,
				errors,
				isValid,
				dirty,
				touched,
				handleChange,
				handleBlur,
				handleReset,
				handleSubmit,
				setFieldValue,
				setValues
			}) => (
				<Modal isOpen={isOpen} backdrop size="xl">
					<ModalHeader>
						<div className="d-flex justify-content-between">
							<div>Edycja godzin urzędowania</div>
							<div>
								<Button
									className="btn-icon mr-2"
									color="alternate"
									type="submit"
									disabled={!isValid}
									form="form-link"
								>
									<i className="pe-7s-check btn-icon-wrapper" />
									{CONFIRM}
								</Button>
								<Button
									className="btn-icon mr-2"
									color="secondary"
									type="button"
									onClick={handleCancel}
								>
									<i className="pe-7s-back btn-icon-wrapper" />
									{CANCEL}
								</Button>
							</div>
						</div>
					</ModalHeader>
					<ModalBody>
						<Form id="form-link" onSubmit={handleSubmit} onReset={handleReset}>
							<Card className="mb-2">
								<CardHeader>Dane ogólne</CardHeader>
								<CardBody>
									<FormGroup>
										<Label for="dayOfWeek">Dzień tygodnia</Label>
										<Input
											type="select"
											id="dayOfWeek"
											name="dayOfWeek"
											value={values.dayOfWeek}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={!!errors.dayOfWeek && !!touched.dayOfWeek}
										>
											<option value={-1}>Wybierz...</option>
											<option value={DayOfWeeks.Monday.value}>{DayOfWeeks.Monday.name}</option>
											<option value={DayOfWeeks.Tuesday.value}>{DayOfWeeks.Tuesday.name}</option>
											<option value={DayOfWeeks.Wednesday.value}>
												{DayOfWeeks.Wednesday.name}
											</option>
											<option value={DayOfWeeks.Thursday.value}>
												{DayOfWeeks.Thursday.name}
											</option>
											<option value={DayOfWeeks.Friday.value}>{DayOfWeeks.Friday.name}</option>
											<option value={DayOfWeeks.Saturday.value}>
												{DayOfWeeks.Saturday.name}
											</option>
											<option value={DayOfWeeks.Sunday.value}>{DayOfWeeks.Sunday.name}</option>
										</Input>
										<FormFeedback>{errors.dayOfWeek}</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="timeFrom">Godzina od</Label>
										<ReactDatePicker
											className="form-control"
											id="timeFrom"
											name="timeFrom"
											selected={values.timeFrom ? new Date(values.timeFrom) : undefined}
											onChange={(date) => {
												dateFnsIsValid(date)
													? setFieldValue('timeFrom', dateFnsFormat(date, DATE_TIME_FORMAT))
													: setFieldValue('timeFrom', undefined);
											}}
											timeFormat={TIME_FORMAT}
											dateFormat={TIME_FORMAT}
											invalid={!!errors.timeFrom}
											autoComplete="off"
											customInput={
												<TimeInput
													invalid={!!errors.timeFrom}
													date={values.timeFrom ? new Date(values.timeFrom) : undefined}
													minTime={startOfDay(new Date(values.timeFrom))}
													maxTime={addMinutes(new Date(values.timeTo), -1)}
													onChangeDate={(date) => {
														dateFnsIsValid(date)
															? setFieldValue(
																	'timeFrom',
																	dateFnsFormat(date, DATE_TIME_FORMAT)
																)
															: setFieldValue('timeFrom', undefined);
													}}
												/>
											}
										/>
										<FormFeedback>{errors.timeFrom}</FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label for="timeTo">Godzina do</Label>
										<ReactDatePicker
											className="form-control"
											id="timeTo"
											name="timeTo"
											selected={values.timeTo ? new Date(values.timeTo) : undefined}
											timeFormat={TIME_FORMAT}
											dateFormat={TIME_FORMAT}
											invalid={!!errors.timeTo}
											autoComplete="off"
											customInput={
												<TimeInput
													invalid={!!errors.timeTo}
													date={values.timeTo ? new Date(values.timeTo) : undefined}
													minTime={addMinutes(new Date(values.timeFrom), 1)}
													maxTime={endOfDay(new Date())}
													onChangeDate={(date) => {
														dateFnsIsValid(date)
															? setFieldValue('timeTo', dateFnsFormat(date, DATE_TIME_FORMAT))
															: setFieldValue('timeTo', undefined);
													}}
												/>
											}
										/>
										<FormFeedback>{errors.timeTo}</FormFeedback>
									</FormGroup>
								</CardBody>
							</Card>
						</Form>
					</ModalBody>
				</Modal>
			)}
		</Formik>
	);
};
