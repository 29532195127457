import { createAction } from '../base';
import { PageSize } from '../../helpers/enums';

export const CreateDictionaryEntryAction = createAction('CreateDictionaryEntryAction');
export const GetDictionaryEntryAction = createAction('GetDictionaryEntryAction');
export const GetAllDictionaryEntriesAction = createAction('GetAllDictionaryEntriesAction');
export const UpdateDictionaryEntryAction = createAction('UpdateDictionaryEntryAction');
export const RemoveDictionaryEntryAction = createAction('RemoveDictionaryEntryAction');

function createDictionaryEntry(dictionaryEntry, changeUrl = true, withNotification = true) {
	return {
		type: CreateDictionaryEntryAction.Request,
		payload: { dictionaryEntry, changeUrl, withNotification }
	};
}
function getDictionaryEntry(id) {
	return {
		type: GetDictionaryEntryAction.Request,
		payload: id
	};
}

function getAllDictionaryEntries(lang = '', page = 0, size = PageSize.All, filters = [], sortings = []) {
	return {
		type: GetAllDictionaryEntriesAction.Request,
		payload: { lang, page, size, filters, sortings }
	};
}

function updateDictionaryEntry(dictionaryEntry) {
	return {
		type: UpdateDictionaryEntryAction.Request,
		payload: dictionaryEntry
	};
}

function removeDictionaryEntries(ids = []) {
	return {
		type: RemoveDictionaryEntryAction.Request,
		payload: ids
	};
}

export const DictionaryEntryActionCreators = {
	createDictionaryEntry,
	getDictionaryEntry,
	getAllDictionaryEntries,
	updateDictionaryEntry,
	removeDictionaryEntries
};

export const DictionaryEntryActions = {
	CreateDictionaryEntryAction,
	GetDictionaryEntryAction,
	GetAllDictionaryEntriesAction,
	UpdateDictionaryEntryAction,
	RemoveDictionaryEntryAction
};
