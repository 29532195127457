import { takeEvery, fork, put } from 'redux-saga/effects';
import { RoleSagas } from './auth/role';
import { UserSagas } from './auth/user';
import { AuthActions } from '../actions/auth';

function* unloadAuth() {
	yield put({
		type: AuthActions.UnloadAuth.Success
	});
}

function* watchUnloadAuth() {
	yield takeEvery(AuthActions.UnloadAuth.Request, unloadAuth);
}

export const AuthSaga = [ ...RoleSagas, ...UserSagas, fork(watchUnloadAuth) ];
