import { FilterActions } from '../actions/filter';
import { Filter } from '../stores';
import Immutable from 'seamless-immutable';

const initialState = Immutable(Filter);

export function filter(state = initialState, action) {
	switch (action.type) {
		case FilterActions.ChangeFilters.Success: {
			return state.setIn([ ...action.payload.propName ], action.payload.filters);
		}
		case FilterActions.ClearFilters.Success: {
			return state.setIn([ ...action.payload.propName ], action.payload.filters);
		}
		case FilterActions.SaveSelectedFilters.Success: {
			return state.setIn([ ...action.payload.propName ], action.payload.filters);
		}
		default: {
			return state;
		}
	}
}
