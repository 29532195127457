import React, { Component, Fragment } from "react";
import map from "lodash.map";
import filter from "lodash.filter";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import find from "lodash.find";
import findIndex from "lodash.findindex";
import classnames from "classnames";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormFeedback,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import RSC from "react-scrollbars-custom";

import ChooseCategory from "../Modals/ChooseCategory";
import { MenuType } from "../../helpers/enums";

const filterCategories = (categories, activeTab) =>
  filter(
    map(categories, (category, categoryIndex) => ({
      ...category,
      categoryIndex,
    })),
    {
      menuElementType: activeTab,
    }
  );
export default class CategoryCard extends Component {
  static defaultProps = {
    excludeCategories: [],
    categories: [],
    onSave: (categories) => {},
    onChange: (fieldName, value) => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      addCategoryModalOpen: false,
      categoryActiveTab: MenuType.Horizontal,
      activeCategories: filterCategories(props.categories, MenuType.Horizontal),
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.categories, this.props.categories)) {
      this.setState({
        activeCategories: filterCategories(
          this.props.categories,
          this.state.categoryActiveTab
        ),
      });
    }
  }

  toggleModalOpen = (name, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ [name]: !this.state[name] });
  };

  changeCategoryActiveTab = (activeTab) => {
    const { categories } = this.props;
    this.setState({
      categoryActiveTab: activeTab,
      activeCategories: filterCategories(categories, activeTab),
    });
  };

  handleSave = (selectedCategories) => {
    const { categories, onSave } = this.props;
    const { categoryActiveTab } = this.state;
    const mainElement = find(categories, { isMainMenuElement: true });

    if (onSave && typeof onSave === "function") {
      const otherCategories = filter(
        categories,
        (category) => category.menuElementType !== categoryActiveTab
      );
      const mainElementSelectedCategoryIndex = findIndex(selectedCategories, {
        menuElementId: mainElement?.menuElementId || -1,
      });
      const mainElementIntoOtherCategoryIndex = findIndex(otherCategories, {
        menuElementId: mainElement?.menuElementId || -1,
      });

      if (!isEmpty(selectedCategories)) {
        selectedCategories = map(selectedCategories, (category) => ({
          ...category,
          isMainMenuElement: false,
        }));
      }
      if (mainElementSelectedCategoryIndex !== -1) {
        selectedCategories[
          mainElementSelectedCategoryIndex
        ].isMainMenuElement = true;
      }
      if (mainElementIntoOtherCategoryIndex !== -1) {
        otherCategories[
          mainElementIntoOtherCategoryIndex
        ].isMainMenuElement = true;
      }
      if (
        mainElementSelectedCategoryIndex === -1 &&
        mainElementIntoOtherCategoryIndex === -1 &&
        !isEmpty(selectedCategories)
      ) {
        selectedCategories[0].isMainMenuElement = true;
      }

      onSave(otherCategories.concat(selectedCategories));
    }
  };

  handleChangeMainCategory = (mainCategory) => {
    const { categories, onSave } = this.props;
    onSave(
      map(categories, (category) => {
        if (category.menuElementId === mainCategory.menuElementId) {
          return { ...mainCategory, isMainMenuElement: true };
        }
        return { ...category, isMainMenuElement: false };
      })
    );
  };

  render() {
    const { activeCategories, addCategoryModalOpen, categoryActiveTab } =
      this.state;
    const { disabled = false, errors, excludeCategories = [] } = this.props;

    return (
      <Fragment>
        <ChooseCategory
          isOpen={addCategoryModalOpen}
          categories={activeCategories}
          excludeCategories={excludeCategories}
          menuType={categoryActiveTab}
          onToggle={this.toggleModalOpen.bind(this, "addCategoryModalOpen")}
          onSave={this.handleSave}
        />
        <Card className="mb-2">
          <CardHeader className="height-auto">
            <i className="header-icon pe-7s-albums icon-gradient bg-malibu-beach" />
            <Nav justified>
              <NavItem>
                <NavLink
                  href="javascript:void(0);"
                  className={classnames({
                    active: categoryActiveTab === MenuType.Horizontal,
                  })}
                  onClick={() => {
                    this.changeCategoryActiveTab(MenuType.Horizontal);
                  }}
                >
                  <div className="d-flex align-items-baseline justify-content-center">
                    <CardTitle className="mb-0">Menu poziome</CardTitle>
                    {categoryActiveTab === MenuType.Horizontal && !disabled && (
                      <Button
                        type="button"
                        className="mr-2 border-0 btn-transition"
                        outline
                        color="success"
                        onClick={this.toggleModalOpen.bind(
                          this,
                          "addCategoryModalOpen"
                        )}
                      >
                        <i className="pe-7s-plus btn-icon-wrapper" />
                      </Button>
                    )}
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="javascript:void(0);"
                  className={classnames({
                    active: categoryActiveTab === MenuType.Vertical,
                  })}
                  onClick={() => {
                    this.changeCategoryActiveTab(MenuType.Vertical);
                  }}
                >
                  <div className="d-flex align-items-baseline justify-content-center">
                    <CardTitle className="mb-0">Menu pionowe</CardTitle>
                    {categoryActiveTab === MenuType.Vertical && !disabled && (
                      <Button
                        type="button"
                        className="mr-2 border-0 btn-transition"
                        outline
                        color="success"
                        onClick={this.toggleModalOpen.bind(
                          this,
                          "addCategoryModalOpen"
                        )}
                      >
                        <i className="pe-7s-plus btn-icon-wrapper" />
                      </Button>
                    )}
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            {errors && (
              <FormFeedback style={{ display: "block" }}>{errors}</FormFeedback>
            )}
            <TabContent activeTab={categoryActiveTab}>
              <TabPane tabId={MenuType.Horizontal}>
                <div
                  className={classnames({
                    "scroll-area-xs": !isEmpty(activeCategories),
                  })}
                >
                  <RSC>
                    <ListGroup flush>
                      {map(
                        filter(activeCategories, {
                          menuElementIsHidden: false,
                        }),
                        (category) => (
                          <ListGroupItem
                            key={`menu-subjective-item-${category.categoryIndex}`}
                          >
                            <FormGroup check>
                              <Input
                                id={`categories-horizontal.${category.categoryIndex}.isMainMenuElement`}
                                type="checkbox"
                                name={`categories.${category.categoryIndex}.isMainMenuElement`}
                                value={category.isMainMenuElement}
                                checked={category.isMainMenuElement}
                                onChange={(_) =>
                                  this.handleChangeMainCategory(category)
                                }
                                title="Zaznacz jeśli kategoria ma być kategorią główną"
                              />
                              <Label check>
                                {category.menuElementDisplayText}
                              </Label>
                            </FormGroup>
                          </ListGroupItem>
                        )
                      )}
                    </ListGroup>
                  </RSC>
                </div>
              </TabPane>
              <TabPane tabId={MenuType.Vertical}>
                <div
                  className={classnames({
                    "scroll-area-xs": !isEmpty(activeCategories),
                  })}
                >
                  <RSC>
                    <ListGroup flush>
                      {map(
                        filter(activeCategories, {
                          menuElementIsHidden: false,
                        }),
                        (category) => (
                          <ListGroupItem
                            key={`menu-objective-item-${category.categoryIndex}`}
                          >
                            <FormGroup check>
                              <Input
                                id={`categories-vertical.${category.categoryIndex}.isMainMenuElement`}
                                type="checkbox"
                                name={`categories.${category.categoryIndex}.isMainMenuElement`}
                                value={category.isMainMenuElement}
                                checked={category.isMainMenuElement}
                                onChange={(_) =>
                                  this.handleChangeMainCategory(category)
                                }
                                title="Zaznacz jeśli kategoria ma być kategorią główną"
                              />
                              <Label check>
                                {category.menuElementDisplayText}
                              </Label>
                            </FormGroup>
                          </ListGroupItem>
                        )
                      )}
                    </ListGroup>
                  </RSC>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}
