import axios from "axios";
import ExEnv from "exenv";
import { toast as toastr } from "react-toastify";
import { getErrorForToastr, isCheckAccessModuleRequest } from "./error-adapter";

export default {
  setup: (store, history) => {
    axios.interceptors.request.use(
      (request) => {
        if (!ExEnv.canUseDOM) {
          return request;
        }

        const state = store.getState();
        const {
          oidc: { user = { access_token: "" } },
        } = state;
        const access_token = user && user.access_token ? user.access_token : "";

        if (access_token) {
          request.headers["Authorization"] = `Bearer ${access_token}`;
        }
        return request;
      },
      (error) => error
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { message = null } = getErrorForToastr(error);
        const isCheckAccess = isCheckAccessModuleRequest(error.response);
        if (error.response.status === 403 && isCheckAccess) {
          history.push("/brak-dostepu");
        }
        if (error.response.status === 401 && !message && !isCheckAccess) {
          store.dispatch(toastr.error("Polecenie wymaga zalogowania"));
        }
        if (error.response.status === 403 && !message && !isCheckAccess) {
          store.dispatch(toastr.error("Brak uprawnień do wykonania polecania"));
        }
        throw error;
      }
    );
  },
};
