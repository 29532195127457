import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import isEqual from 'lodash.isequal';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import { BookmarkList } from './BookmarkList';

import { RouteUrls } from '../../../../helpers/routeUrls';
import { LoaderType, DefaultLanguage, PageSize } from '../../../../helpers/enums';
import { ApplicationActionCreators } from '../../../../actions/application';
import { SettingActionCreators } from '../../../../actions/settings';
import { FilterActionCreators } from '../../../../actions/filter';

class BookmarksList extends Component {
	componentDidMount() {
		const { activeLanguage, loadBookmarks, bookmarkFilters } = this.props;
		loadBookmarks(activeLanguage.isoCode, 0, PageSize.Rows20, bookmarkFilters, []);
	}
	componentDidUpdate(prevProps) {
		const { activeLanguage, loadBookmarks, bookmarkFilters } = this.props;
		if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
			loadBookmarks(activeLanguage.isoCode, 0, PageSize.Rows20, bookmarkFilters, []);
		}
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}
	onAdd = () => {
		this.props.goTo(RouteUrls.Setting.bookmark.create);
	};

	onRowClick = (row) => this.props.goTo(RouteUrls.Setting.bookmark.editFunc(row.id));

	onReload = (page, size, filters, sortings) => {
		const { activeLanguage, loadBookmarks, bookmarkFilters } = this.props;
		loadBookmarks(activeLanguage.isoCode, page, size, bookmarkFilters, sortings);
	};

	onRemove = (removeIds) => this.props.deleteBookmarks(removeIds);

	clearFilters = () => {
		const { activeLanguage, loadBookmarks, clearFilters } = this.props;
		clearFilters(['bookmarkFilters'], []);
		loadBookmarks(activeLanguage.isoCode, 0, PageSize.Rows20, [], []);
	};

	render() {
		const {
			bookmarks,
			totalBookmarks,
			bookmarkListBlocking,
			activeLanguage,
			languages,
			changeApplicationLanguage,
			bookmarkFilters,
			changeFilters
		} = this.props;
		return (
			<Fragment>
				<PageTitle heading="Lista zakładek" icon="pe-7s-settings icon-gradient bg-tempting-azure" />

				<BlockUi tag="div" blocking={bookmarkListBlocking} loader={<Loader active type={LoaderType} />}>
					<BookmarkList
						items={bookmarks}
						totalItems={totalBookmarks}
						onReload={this.onReload}
						onAdd={this.onAdd}
						onRemove={this.onRemove}
						onRowClick={this.onRowClick}
						internationalizable={true}
						language={activeLanguage}
						languages={languages}
						onChangeLanguage={changeApplicationLanguage}
						filters={bookmarkFilters}
						onChangeFilters={(filters) => changeFilters(['bookmarkFilters'], filters)}
						onClearFilters={this.clearFilters}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		bookmarkFilters: state.filter.bookmarkFilters || [],
		bookmarks: state.setting.bookmarks || [],
		totalBookmarks: state.setting.totalBookmarks || 0,
		bookmarkListBlocking: state.uiBlockState.bookmarkListBlocking || false,
		activeLanguage: state.application.activeLanguage,
		languages: state.application.languages || [ DefaultLanguage ] //getActiveLanguagesSelector(state) || [ DefaultLanguage ]
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators(
			{ ...SettingActionCreators, ...ApplicationActionCreators, ...FilterActionCreators },
			dispatch
		),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookmarksList);
