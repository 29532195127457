import React from 'react';
import {
	FormGroup,
	FormFeedback,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Label,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	Button
} from 'reactstrap';
import { Formik } from 'formik';
import { TinyMCE } from '../../../../components/TinyMCE';

import { FooterValidationSchema } from '../../../../validators';

import { CONSTANTS } from '@constants';
import { DefaultFormikConfig } from '../../../../helpers/enums';

const { CONFIRM, CANCEL, COMMON_INFORMATION, DISPLAY_NAME, CONTENT, EDIT_FOOTER_ELEMENT } = CONSTANTS;

export default ({
	isOpen = false,
	footerElement = {},
	updateFooterElement = (element) => element,
	cancel = () => {}
}) => (
	<Formik
		{...DefaultFormikConfig}
		initialValues={footerElement}
		validationSchema={FooterValidationSchema}
		onSubmit={(values) => {
			updateFooterElement(values);
			cancel();
		}}
		onReset={cancel}
	>
		{({ errors, values, handleChange, setFieldValue, isValid, dirty, handleSubmit, handleReset }) => (
			<Modal isOpen={isOpen} backdrop size="xl" toggle={handleReset}>
				<ModalHeader>
					<div className="d-flex justify-content-between">
						<div>{EDIT_FOOTER_ELEMENT}</div>
						<div>
							<Button
								className="btn-icon mr-2"
								color="alternate"
								type="button"
								onClick={handleSubmit}
								disabled={!isValid || !dirty}
							>
								<i className="pe-7s-check btn-icon-wrapper" />
								{CONFIRM}
							</Button>
							<Button className="btn-icon mr-2" color="secondary" type="button" onClick={handleReset}>
								<i className="pe-7s-back btn-icon-wrapper" />
								{CANCEL}
							</Button>
						</div>
					</div>
				</ModalHeader>
				<ModalBody>
					<Card className="mb-2">
						<CardHeader>
							<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
							{COMMON_INFORMATION}
						</CardHeader>
						<CardBody>
							<Row>
								<Col>
									<FormGroup>
										<Label for="displayText">{DISPLAY_NAME}</Label>
										<Input
											type="text"
											id="displayText"
											name="displayText"
											placeholder="Wpisz nazwę"
											value={values.displayText}
											onChange={handleChange}
											invalid={!!errors.displayText}
										/>
										<FormFeedback>{errors.displayText}</FormFeedback>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label for="bip">{CONTENT}</Label>
										{errors.content && (
											<FormFeedback style={{ display: 'block' }}>
												{errors.content.content}
											</FormFeedback>
										)}
										<TinyMCE
											id="content"
											name="content"
											config={{
												height: 600,
												toolbar:
													'formatselect | bold italic strikethrough | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
												menu: {
													format: {
														title: 'Format',
														items:
															'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | removeformat'
													}
												}
											}}
											plugins={
												'advlist anchor autolink charmap codesample directionality fullpage fullscreen help hr image imagetools importcss insertdatetime link lists nonbreaking noneditable quickbars pagebreak paste print preview searchreplace table textpattern toc visualblocks visualchars wordcount'
											}
											content={values.content.content}
											onContentChanged={(content) => {
												setFieldValue('content.content', content);
											}}
										/>
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</ModalBody>
			</Modal>
		)}
	</Formik>
);
