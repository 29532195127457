import * as yup from 'yup';

export const RepositoryConfigValidationSchema = yup.object().shape({
	name: yup
		.string()
		.trim()
		.max(100, 'Maksymalna długość nazwy nie może przekroczyć 100 znaków')
		.required('Nazwa grupy jest polem wymaganym')
		.nullable(),
	description: yup.string().trim().max(2000, 'Maksymalna długość opisu nie może przekroczyć 2000 znaków').nullable(),
	extensions: yup
		.array()
		.required('Rozszerzenia plików jest polem wymaganym')
		.min(1, 'Rozszerzenia plików wymagają jakiegokolwiek rozszerzenia'),
	icon: yup.string().required('Ikona jest polem wymaganym').nullable()
});
