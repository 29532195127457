import React from "react";

import cx from "classnames";

import { AppMenuItem } from "./AppMenuItem";

export const MenuList = ({ items, isRoot, isOpen }) => (
  <ul
    className={cx("metismenu-container", {
      metismenu: isRoot,
      "metismenu-container--visible": isOpen && !isRoot,
    })}
  >
    {items
      .filter((el) => !el.isHidden)
      .map((item) => (
        <AppMenuItem {...{ item, isVisible: isOpen, isRoot }} key={item.id} />
      ))}
  </ul>
);
