export const combineArrays = (firstArray, secondArray, type) => {
	const set = new Set();
	return [ ...firstArray, ...secondArray ].filter(item => {
		if (type == 'categories') {
			if (!set.has(item.menuElementId)) {
				set.add(item.menuElementId);
				return true;
			}
		} else {
			if (!set.has(item.bookmarkId)) {
				set.add(item.bookmarkId);
				return true;
			}
		}
		return false;
	}, set);
};

export const addCategories = (type, max, node, items, addItem) => {
	if (!type) {
		if (max && max === items.length) {
			return;
		}

		const item = {
			id: 0,
			menuElementId: node.id | 0,
			menuElementType: node.type,
			menuElementDisplayText: node.displayText,
			menuElementTitle: node.title,
			menuElementTextPath: node.textPath,
			menuElementUrl: node.url,
			menuElementSlug: node.slug,
			menuElementIsHidden: node.isHidden,
			menuElementIsSystem: node.isSystem
		};

		const isExists = items.findIndex(({ menuElementId }) => menuElementId === item.menuElementId) !== -1;
		if (!isExists) {
			const newItemList = items.concat(item);
			addItem(newItemList);
		}
	}
};

export const addBookmarks = (id, displayText, props, bookmarks, onChangeItems) => {
	const item = {
		id: 0,
		bookmarkId: id,
		displayText: displayText,
		...props
	};
	const isExists = bookmarks.findIndex(element => element.bookmarkId === item.bookmarkId) !== -1;
	if (!isExists) {
		const newItemList = bookmarks.concat(item);
		onChangeItems(newItemList);
	}
};

export const addArticles = (type, id, title, url, slug, articles, onChangeItems, articlesMenu, searchCategory) => {
	if (type && type === 'tile') {
		const item = {
			id: 0,
			articleId: id | 0,
			title: title,
			url: url,
			slug: slug
		};
		if (articles.length === 0) {
			const isExists = articles.findIndex(element => element.articleId === item.articleId) !== -1;
			if (!isExists) {
				const newItemList = articles.concat(item);
				onChangeItems(newItemList);
			}
		}
	}

	if (type && type === 'article') {
		const item = {
			id: 0,
			articleId: id | 0,
			title: title,
			url: url,
			slug: slug
		};
		if (articles) {
			const isExists = articles.findIndex(element => element.articleId === item.articleId) !== -1;
			if (!isExists) {
				const newItemList = articles.concat(item);
				onChangeItems(newItemList);
			}
		}
	}

	if (type && type === 'menu') {
		const item = {
			id: id | 0,
			displayText: title
		};

		if (articlesMenu) {
			const isExists = articlesMenu.findIndex(element => element.id === item.id) !== -1;
			if (!isExists) {
				const newItemList = articlesMenu.concat(item);
				searchCategory('', newItemList);
			}
		} else {
			const newItemList = articlesMenu.concat(item);
			searchCategory('', newItemList);
		}
	}
};

export const getCombinedArticlesCategories = (articles, categories) => {
	return articles && categories
		? articles.concat(categories)
		: !articles && !categories ? [] : !articles ? categories : articles;
};

export const getMenuArticles = (type, node, name, categories, searchArticles) => {
	if (type && type === 'menu') {
		const item = {
			id: node.id | 0,
			displayText: name
		};

		const isExists = categories.findIndex(element => element.id === item.id) !== -1;
		if (!isExists) {
			const newItemList = categories.concat(item);
			searchArticles('', newItemList);
		}
	}
};
