import {createAction} from '../base';

import * as Roles from './role';
import * as Users from './user';

const UnloadAuth = createAction('UnloadAuth');

function unloadAuth() {
	return {
		type: UnloadAuth.Request
	}
}

export const AuthActions = {
	...Roles.RoleActions,
	...Users.UserActions,
	UnloadAuth: UnloadAuth
};

export const AuthActionCreators = {
	...Roles.RoleActionCreators,
	...Users.UserActionCreators,
	unloadAuth: unloadAuth
};
