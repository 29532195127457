import React, { Component } from 'react';
import { FormGroup, Row, Col, Label, Card, CardHeader, CardBody } from 'reactstrap';
import map from 'lodash.map';
import isEqual from 'lodash.isequal';

export class ThemeColorPicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: props.selected
		};
	}
	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.selected, this.props.selected) && this.props.selected) {
			this.setState({ selected: this.props.selected });
		}
	}
	handleClick = (color) => {
		const { onChange } = this.props;
		if (onChange && typeof onChange === 'function') {
			onChange('value', color);
		}
		this.setState({ selected: color });
	};

	render() {
		const { selected } = this.state;
		const { colors = [ { value: 'standard', color: '#0077b3' } ], title = '', label = '' } = this.props;
		return (
			<Card className="mb-2">
				<CardHeader>
					<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
					{title}
				</CardHeader>
				<CardBody>
					<Row>
						<Col>
							<Label className="mr-2">{label}</Label>
							<FormGroup className="d-flex align-items-center">
								{map(colors, (color) => (
									<div
										style={{
											backgroundColor: color.color,
											minWidth: '80px',
											minHeight: '80px',
											position: 'relative',
											margin: '5px'
										}}
										onClick={this.handleClick.bind(this, color)}
									>
										{color.color === selected.color && (
											<i
												className="pe-7s-check"
												style={{
													color: '#FFF',
													fontSize: '20px',
													position: 'absolute',
													top: '1px',
													right: '1px'
												}}
											/>
										)}
									</div>
								))}
							</FormGroup>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}
