import { createAction } from '../base';
import {DefaultLanguage} from '../../helpers/enums';

const GetHeader = createAction('GetHeader');
const UpdateHeader = createAction('UpdateHeader');

function getHeader(isoCode = DefaultLanguage.isoCode) {
	return {
		type: GetHeader.Request,
		payload: isoCode
	};
}

function updateHeader(header) {
	return {
		type: UpdateHeader.Request,
		payload: header
	};
}

export const HeaderActions = {
	GetHeader,
	UpdateHeader
};

export const HeaderActionCreators = {
	getHeader,
	updateHeader
};
