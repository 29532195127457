import { createSelector } from 'reselect';
import { PermissionName } from '../helpers/permissionNames';

const getPermissions = (store) => store.auth.userPermissions || [];
const isAdmin = (store) => store.auth.isAdmin || false;

export const getLayoutPermissions = createSelector([ getPermissions, isAdmin ], (permissions, isAdmin) => {
	return {
		accessToMenus: [ PermissionName.ManageMenus ].some((item) => permissions.includes(item)),
		accessToArticles: [
			PermissionName.CreateArticles,
			PermissionName.ConfirmArticles,
			PermissionName.PublishArticles,
			PermissionName.ArchiveArticles
		].some((item) => permissions.includes(item)),
		accessToUsers: [ PermissionName.ManageUsers ].some((item) => permissions.includes(item)),
		accessToRoles: isAdmin,
		accessToDictionaries: [ PermissionName.ManageSettings ].some((item) => permissions.includes(item)),
		accessToSettings: [ PermissionName.ManageSettings ].some((item) => permissions.includes(item))
	};
});
