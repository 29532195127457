import isContrastChecker from 'is-contrast-checker';
import Immutable from 'seamless-immutable';

export const popover = {
	position: 'absolute',
	zIndex: '2'
};

export const cover = {
	position: 'fixed',
	top: '0px',
	right: '0px',
	bottom: '0px',
	left: '0px'
};

export const handleImmutable = (elementDate, activeLanguage) => {
	const newElementData = Immutable({
		...elementDate,
		language: { ...activeLanguage },
		displayColorPicker: false
	}).asMutable({ deep: true });

	return { newElementData };
};

export const checkedContrast = (color, secondColor, secondDefault) => {
	const colors = {
		choosedColor: color || secondDefault,
		secondColor
	};

	const result = isContrastChecker(colors);

	const contrast = result[1].combinations.map(combination => combination.contrast)[0];

	return { contrast };
};

export const checkedUrl = (url, piecePath) => ({ isCreate: url.includes(piecePath) ? true : false });
