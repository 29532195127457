import React, { Fragment } from "react";
import Grid from "../../../../components/Grid";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { Button } from "reactstrap";
import filter from "lodash.filter";

const columns = [
  {
    name: "groupTypeName",
    title: "Grupa",
  },
  {
    name: "displayName",
    title: "Nazwa parametru",
  },
];
const columnsExtensions = [
  {
    columnName: "groupTypeName",
    width: "auto",
  },
  {
    columnName: "displayName",
    width: "auto",
  },
];
const filtersColumnExtensions = [
  {
    columnName: "groupTypeName",
    wordWrapEnabled: false,
    filteringEnabled: false,
  },
  {
    columnName: "displayName",
    wordWrapEnabled: false,
    filteringEnabled: true,
  },
];
const textColumns = ["displayName"];
const groupingColumns = [{ columnName: "groupTypeName" }];
const groupingColumnExtensions = [
  { columnName: "groupTypeName", groupingEnabled: false },
  { columnName: "displayName", groupingEnabled: false },
];
const groupingCell = (props) => {
  const { items, row, ...restProps } = props;
  const errors = filter(
    items,
    (item) => item[row.groupedBy] === row.value && item.errors
  );
  return (
    <span>
      <Fragment>
        <strong>{row.key}</strong>{" "}
        {errors.length > 0 && restProps.hasValidation && (
          <i className="pe-7s-exlamation fg-danger" />
        )}
        {errors.length <= 0 && restProps.hasValidation && (
          <i className="pe-7s-check fg-success" />
        )}
      </Fragment>
    </span>
  );
};
export const SystemParameterList = ({
  items,
  totalItems,
  onReload,
  onRowClick,
  ...props
}) => {
  const ActionFormatter = ({ row }) => (
    <Fragment>
      <Button
        type="button"
        className="btn-icon-only border-0"
        outline
        color="success"
        onClick={(_) => onRowClick(row)}
      >
        <i className="pe-7s-note btn-icon-wrapper" />
      </Button>
    </Fragment>
  );
  const LinkFormatter = ({ row }) => (
    <div style={{ cursor: "pointer" }} onClick={(_) => onRowClick(row)}>
      {row.displayName}
    </div>
  );
  const dataTypeProviders = [
    <DataTypeProvider for={["action"]} formatterComponent={ActionFormatter} />,
    <DataTypeProvider
      for={["displayName"]}
      formatterComponent={LinkFormatter}
    />,
  ];

  return (
    <Grid
      items={items}
      totalItems={totalItems}
      dataTypeProviders={dataTypeProviders}
      grouping={groupingColumns}
      expandedGroups={[...new Set(items.map((el) => el.groupTypeName))]}
      groupingColumnExtensions={groupingColumnExtensions}
      groupingCell={groupingCell}
      columns={columns}
      columnExtensions={columnsExtensions}
      filtersColumnExtensions={filtersColumnExtensions}
      textColumns={textColumns}
      removeable={false}
      addable={false}
      filterable={true}
      onReload={onReload}
      filters={props.filters}
      onChangeFilters={props.onChangeFilters}
      onClearFilters={props.onClearFilters}
    />
  );
};
