import * as yup from 'yup';
import { LinkTargetType } from '../helpers/enums';
import isEmpty from 'lodash.isempty';

export const RssChannelValidationSchema = yup.object().shape({
	displayText: yup.string().trim().required('Nazwa wyświetlana jest polem wymaganym'),
	title: yup.string().trim().required('Tekst pod czytniki dla osób słabowidzących jest polem wymaganym'),
	description: yup.string().trim().required('Opis kanału jest polem wymaganym'),
	target: yup
		.number()
		.oneOf(
			[ LinkTargetType.Self.value, LinkTargetType.NewTab.value ],
			`Otwórz zakładkę w nowym oknie musi zawierać wartość: ${LinkTargetType.Self.name} lub ${LinkTargetType
				.NewTab.name}`
		),
	isActive: yup
		.bool()
		.test('Any array has any item', 'Kanał RSS nie wskazuje żadnego źródła danych', function(isActive) {
			if (!isActive) {
				return true;
			}
			const isHorizontalMenuElementsEmpty = isEmpty(this.parent.horizontalMenuElements);
			const isVerticalMenuElementsEmpty = isEmpty(this.parent.verticalMenuElements);
			const isBookmarksElementsEmpty = isEmpty(this.parent.bookmarksElements);
			const isSetFromAllSources = this.parent.fromAllSources;

			return !isHorizontalMenuElementsEmpty || !isVerticalMenuElementsEmpty || !isBookmarksElementsEmpty || isSetFromAllSources;
		}),
	horizontalMenuElements: yup.array(),
	verticalMenuElements: yup.array(),
	bookmarksElements: yup.array()
});
