import { createAction } from '../base';

const LoadRoles = createAction('LoadRoles');
const GetRole = createAction('GetRole');
const CreateRole = createAction('CreateRole');
const UpdateRole = createAction('UpdateRole');
const DeleteRoles = createAction('DeleteRoles');

function loadRoles(page = 0, size = 20, filters = [], sortings = []) {
	return {
		type: LoadRoles.Request,
		payload: { page, size, filters, sortings }
	};
}
function getRole(id) {
	return {
		type: GetRole.Request,
		payload: id
	};
}
function createRole(role) {
	return {
		type: CreateRole.Request,
		payload: role
	};
}
function updateRole(role) {
	return {
		type: UpdateRole.Request,
		payload: role
	};
}
function deleteRoles(roleIds = []) {
	return {
		type: DeleteRoles.Request,
		payload: roleIds
	};
}

export const RoleActions = {
	LoadRoles,
	GetRole,
	CreateRole,
	UpdateRole,
	DeleteRoles
};

export const RoleActionCreators = {
	loadRoles,
	getRole,
	createRole,
	updateRole,
	deleteRoles
};
