import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import BookmarkCard from "./Card";
import BookmarkList from "./List";
import { RouteUrls } from "../../../helpers/routeUrls";

const Bookmarks = ({ match }) => (
  <Fragment>
    <Switch>
      <Route
        path={RouteUrls.Setting.bookmark.create}
        component={BookmarkCard}
      />
      <Route
        path={`${RouteUrls.Setting.bookmark.edit}/:id`}
        component={BookmarkCard}
      />
      <Route path="*" component={BookmarkList} />
    </Switch>
  </Fragment>
);

export default Bookmarks;
