import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';

import { SettingActions } from '../../actions/settings';
import { RouteUrls } from '../../helpers/routeUrls';
import { getListRequest } from '../baseRequest';
import { createErrorToast } from '../../helpers/error-message';

const BANNER_URL = '/api/settings/banners';

function* loadBanners(action) {
	try {
		let banners = yield call(getListRequest, {
			url: BANNER_URL,
			lang: action.payload.lang,
			page: action.payload.page,
			size: action.payload.size,
			filters: action.payload.filters,
			sortings: action.payload.sortings
		});

		yield put({
			type: SettingActions.LoadBanners.Success,
			payload: banners
		});
	} catch (err) {
		yield put({
			type: SettingActions.LoadBanners.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania listy banerów');
	}
}

function* getBanner(action) {
	try {
		let banner = yield call(getBannerRequest, action.payload);

		yield put({
			type: SettingActions.GetBanner.Success,
			payload: banner
		});
	} catch (err) {
		yield put({
			type: SettingActions.GetBanner.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas pobierania danych baneru');
	}
}
function getBannerRequest(id) {
	return axios.get(`${BANNER_URL}/${id}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* updateBanner(action) {
	try {
		let banner = yield call(updateBannerRequest, action.payload);

		yield put({
			type: SettingActions.UpdateBanner.Success,
			payload: banner
		});
		toast.success('Baner został zaktualizowany');
	} catch (err) {
		yield put({
			type: SettingActions.UpdateBanner.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas aktualizacji danych baneru');
	}
}
function updateBannerRequest(banner) {
	return axios.put(`${BANNER_URL}/${banner.id}`, banner).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* createBanner(action) {
	try {
		let banner = yield call(createBannerRequest, action.payload);

		yield put({
			type: SettingActions.CreateBanner.Success,
			payload: banner
		});
		yield put(replace(RouteUrls.Setting.banners.editFunc(banner.id)));
		toast.success('Baner został utworzony');
	} catch (err) {
		yield put({
			type: SettingActions.CreateBanner.Failure
		});
		createErrorToast(err, 'Wystąpił niespodziewany błąd podczas tworzenia baneru');
	}
}
function createBannerRequest(banner) {
	return axios.post(BANNER_URL, banner).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* deleteBanners(action) {
	try {
		yield call(deleteBannersRequest, action.payload);

		yield put({
			type: SettingActions.DeleteBanners.Success,
			payload: action.payload
		});
		toast.success('Banery zostały usunięte');
	} catch (err) {
		yield put({
			type: SettingActions.DeleteBanners.Failure
		});
		createErrorToast(err, 'Wystąpił błąd w trakcie usuwania banerów');
	}
}

function deleteBannersRequest(bannerIds) {
	const filterQuery = qs.stringify({ bannerIds: bannerIds }, { addQueryPrefix: true, allowDots: true });
	return axios.delete(`${BANNER_URL}${filterQuery}`).then((response) => response.data).catch((err) => {
		throw err;
	});
}

function* watchLoadBanners() {
	yield takeEvery(SettingActions.LoadBanners.Request, loadBanners);
}
function* watchGetBanner() {
	yield takeEvery(SettingActions.GetBanner.Request, getBanner);
}
function* watchCreateBanner() {
	yield takeEvery(SettingActions.CreateBanner.Request, createBanner);
}
function* watchUpdateBanner() {
	yield takeEvery(SettingActions.UpdateBanner.Request, updateBanner);
}
function* watchDeleteBanners() {
	yield takeEvery(SettingActions.DeleteBanners.Request, deleteBanners);
}

export const BannerSagas = [
	fork(watchLoadBanners),
	fork(watchGetBanner),
	fork(watchUpdateBanner),
	fork(watchCreateBanner),
	fork(watchDeleteBanners)
];
