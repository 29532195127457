import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { FilterActions } from '../actions/filter';

export function* changeFilters(action) {
	try {
		yield put({
			type: FilterActions.ChangeFilters.Success,
			payload: action.payload
		});
	} catch (err) {
		yield put({
			type: FilterActions.ChangeFilters.Failure
		});
	}
}

export function* clearFilters(action) {
	try {
		yield put({
			type: FilterActions.ClearFilters.Success,
			payload: action.payload
		});
	} catch (err) {
		yield put({
			type: FilterActions.ClearFilters.Failure
		});
	}
}

export function* saveSelectedFilters(action) {
	try {
		yield put({
			type: FilterActions.SaveSelectedFilters.Success,
			payload: action.payload
		});
	} catch (err) {
		yield put({
			type: FilterActions.SaveSelectedFilters.Failure
		});
	}
}

function* watchChangeFilters() {
	yield takeEvery(FilterActions.ChangeFilters.Request, changeFilters);
}

function* watchClearFilters() {
	yield takeEvery(FilterActions.ClearFilters.Request, clearFilters);
}

function* watchSaveSelectedFilters() {
	yield takeEvery(FilterActions.SaveSelectedFilters.Request, saveSelectedFilters);
}

export const FilterSaga = [ fork(watchChangeFilters), fork(watchClearFilters), fork(watchSaveSelectedFilters) ];
