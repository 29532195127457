import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, Formik } from 'formik';
import {
	Form,
	FormGroup,
	FormFeedback,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Label,
	Input,
	Button,
	ListGroup
} from 'reactstrap';
import InputMask from 'react-input-mask';
import BlockUi from 'react-block-ui';
import Sticky from 'react-stickynode';
import { Loader } from 'react-loaders';
import * as yup from 'yup';
import map from 'lodash.map';

import { CONSTANTS } from '@constants';
import PageTitle from '../../../../../Layout/AppMain/PageTitle';
import { LoaderType, DefaultFormikConfig } from '@helpers/enums';
import { OfficeActionCreators } from '../../../../../actions/office';
import { nipValidator, regon14Validator, regon9Validator } from '../../../../../validators/standard-validators';
import { AdmissionHourItem } from './AdmissionHourItem';
import { AddAdmissionHourModal } from '../../../../../components/Modals/AddAdmissionHourModal';

const {
	SAVE,
	CANCEL,
	COMMON_INFORMATION,
	OFFICE_NAME,
	JST_CODE_TERYT,
	REGON,
	NIP,
	CONTACT_DETAILS,
	TELEPHONE,
	FAX,
	E_MAIL,
	WEB_SITE_WWW,
	OFFICE_BOX_EPUAP,
	RECEIVING_DOCUMENT_PLACE,
	CONTACT_INFORMATION,
	COUNTRY,
	PROVINCE,
	DISTRICT,
	COMMUNE,
	CITY,
	ZIP_CODE,
	ZIP_CODE_CITY,
	STREET,
	STREET_NUMBER,
	FLAT_NUMBER,
	ADMISSION_HOURS_INFORMATION,
	PEPPOL_NUMBER,
	BANK_ACCOUNT_NUMBER
} = CONSTANTS;

const EMPTY_OFFICE = {
	officeName: '',
	jstCodeTeryt: '',
	peppolNumber: '',
	bankAccountNumber: '',
	regon: '',
	nip: '',
	phone: '',
	fax: '',
	email: '',
	webSiteWWW: '',
	officeBoxEpuap: '',
	receivingDocumentPlace: '',
	country: '',
	province: '',
	district: '',
	commune: '',
	city: '',
	street: '',
	streetNumber: '',
	flat: '',
	zipCode: ''
};

export const officeDetailsValidationSchema = yup.object().shape({
	name: yup.string().required('Pole wymagane').max(200, 'Maksymalnie 200 znaków'),
	jstCodeTeryt: yup
		.string()
		.nullable()
		.matches(/^\d+$/, 'Tylko cyfry')
		.max(7, 'Maksymalnie 7 znaków'),
	regon: yup
		.string()
		.required('Pole wymagane')
		.nullable()
		.test('regon-validator', 'Nieprawidłowy numer REGON', (value) => {
			if (value && value !== '') {
				return regon9Validator(value) || regon14Validator(value);
			}
			return true;
		}),
	nip: yup.string().required('Pole wymagane').nullable().test('nip-validator', 'Nieprawidłowy numer NIP', (value) => {
		if (value && value !== '') {
			return nipValidator(value);
		}
		return true;
	}),
	phone: yup.string().nullable().test('length', 'Uzupełnij prawidłowo pole', (val) => {
		if (!val) {
			return true;
		}
		return val.toString().replace(/[^\d]/g, '').length === 11;
	}),
	fax: yup.string().nullable().test('length', 'Uzupełnij prawidłowo pole', (val) => {
		if (!val) return true;
		return val.toString().replace(/[^\d]/g, '').length === 11;
	}),
	email: yup.string().email('Wpisz poprawny email'),
	webSiteWWW: yup.string().nullable().url('Niepoprawny format strony internetowej'),
	country: yup.string().nullable(),
	province: yup.object().nullable(),
	district: yup.object().nullable(),
	commune: yup.object().nullable(),
	city: yup.object().nullable(),
	street: yup.string(),
	zipCode: yup.string().nullable().matches(/^[0-9]{2}-[0-9]{3}$/, 'Niepoprawny format kodu pocztowego'),
	zipCodeCity: yup.string().nullable().matches(/^[A-Ża-ż\s\-]+$/, 'Wpisz nazwę miasta'),
	peppolNumber: yup.string().nullable().test('PeppolValidation', 'Nieprawidłowy numer PEPPOL', function(value) {
		if (value && value !== '') {
			if (value.length === 10) {
				return nipValidator(value);
			}

			return value && value !== '' && value.length === 13;
		}
		return true;
	}),
	bankAccountNumber: yup.string().nullable().max(26, 'Numer konta bankowego nie może przekraczać 26 znaków')
});

const integratedWithASWValidationSchema = yup.object().shape({
	peppolNumber: yup.string().nullable().test('PeppolValidation', 'Nieprawidłowy numer PEPPOL', function(value) {
		if (value && value !== '') {
			if (value.length === 10) {
				return nipValidator(value);
			}

			return value && value !== '' && value.length === 13;
		}
		return true;
	}),
	bankAccountNumber: yup.string().nullable().max(26, 'Numer konta bankowego nie może przekraczać 26 znaków')
});

const useOfficeCard = () => {
	const officeCardBlocking = useSelector((state) => state.uiBlockState.officeCardBlocking || false);
	const office = useSelector((state) => state.office.office || EMPTY_OFFICE);
	const isIntegratedWithASW = useSelector((state) => state.application.isIntegratedWithASW || false);
	const [ addAdmissionHourModal, setAddAdmissionHourModal ] = useState(false);

	const dispatch = useDispatch();

	const updateContact = (office) => dispatch(OfficeActionCreators.updateOffice(office));

	const fetchOffice = () => {
		dispatch(OfficeActionCreators.getOffice());
	};

	useEffect(() => fetchOffice(), []);

	return {
		officeCardBlocking,
		office,
		updateContact,
		addAdmissionHourModal,
		setAddAdmissionHourModal,
		isIntegratedWithASW
	};
};

const OfficeCard = (props) => {
	const {
		officeCardBlocking,
		office,
		updateContact,
		addAdmissionHourModal,
		setAddAdmissionHourModal,
		isIntegratedWithASW,
		disabled = false
	} = useOfficeCard();
	return (
		<Formik
			{...DefaultFormikConfig}
			initialValues={office}
			validationSchema={!isIntegratedWithASW ? officeDetailsValidationSchema : integratedWithASWValidationSchema}
			onSubmit={(values) => updateContact(values)}
		>
			{({ errors, values, touched, handleChange, handleBlur, isValid, dirty, handleSubmit, handleReset }) => {
				return (
					<Fragment>
						<PageTitle heading="Dane jednostki" icon="pe-7s-ribbon icon-gradient bg-tempting-azure" />
						<BlockUi tag="div" blocking={officeCardBlocking} loader={<Loader active type={LoaderType} />}>
							<Form noValidate method="post" onSubmit={handleSubmit} onReset={handleReset}>
								<Sticky
									enabled={true}
									top=".app-header"
									innerZ="101"
									activeClass="sticky-active-class"
									className="mb-2"
								>
									<div className="d-flex justify-content-end">
										<Button
											className="btn-icon mr-2"
											color="primary"
											type="submit"
											disabled={!isValid || !dirty}
										>
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{SAVE}
										</Button>
										<Button className="btn-icon mr-2" color="secondary" type="reset">
											<i className="pe-7s-back btn-icon-wrapper" />
											{CANCEL}
										</Button>
									</div>
								</Sticky>
								<Card className="mb-2">
									<CardHeader>
										<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
										{COMMON_INFORMATION}
									</CardHeader>
									<CardBody>
										<Row>
											<Col>
												<FormGroup>
													<Label for="name">{OFFICE_NAME} *</Label>
													<Input
														id="name"
														value={values.name}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.name && !!touched.name}
														invalid={!!errors.name}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.name}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="jstCodeTeryt">{JST_CODE_TERYT}</Label>
													<Input
														id="jstCodeTeryt"
														value={values.jstCodeTeryt}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.jstCodeTeryt && !!touched.jstCodeTeryt}
														invalid={!!errors.jstCodeTeryt}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.jstCodeTeryt}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="regon">{REGON} *</Label>
													<Input
														id="regon"
														value={values.regon}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.regon && !!touched.regon}
														invalid={!!errors.regon}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.regon}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="nip">{NIP} *</Label>
													<Input
														id="nip"
														value={values.nip}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.nip && !!touched.nip}
														invalid={!!errors.nip}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.nip}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={4}>
												<FormGroup>
													<Label
														for="peppolNumber"
														title="Numer PEPPOL może być 10 znakowy (numer NIP) lub 13 znakowy (numer GLN)"
													>
														{PEPPOL_NUMBER}
													</Label>
													<Input
														id="peppolNumber"
														value={values.peppolNumber}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.peppolNumber && !!touched.peppolNumber}
														invalid={!!errors.peppolNumber}
													/>
													<FormFeedback>{errors.peppolNumber}</FormFeedback>
												</FormGroup>
											</Col>
											<Col md={4}>
												<FormGroup>
													<Label for="bankAccountNumber">{BANK_ACCOUNT_NUMBER}</Label>
													<Input
														id="bankAccountNumber"
														value={values.bankAccountNumber}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.bankAccountNumber && !!touched.bankAccountNumber}
														invalid={!!errors.bankAccountNumber}
													/>
													<FormFeedback>{errors.bankAccountNumber}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card className="mb-2">
									<CardHeader>
										<i className="header-icon pe-7s-id icon-gradient bg-malibu-beach" />
										{CONTACT_DETAILS}
									</CardHeader>
									<CardBody>
										<Row>
											<Col>
												<FormGroup>
													<Label for="phone">{TELEPHONE}</Label>
													<InputMask
														id="phone"
														className="form-control"
														value={values.phone}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.phone && !!touched.phone}
														invalid={!!errors.phone}
														mask={'+48 99 999 99 99'}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.phone}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="fax">{FAX}</Label>
													<InputMask
														id="fax"
														className="form-control"
														value={values.fax}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.fax && !!touched.fax}
														invalid={!!errors.fax}
														mask={'+48 99 999 99 99'}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.fax}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="email">{E_MAIL}</Label>
													<Input
														id="email"
														value={values.email}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.email && !!touched.email}
														invalid={!!errors.email}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.email}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="webSiteWWW">{WEB_SITE_WWW}</Label>
													<Input
														id="webSiteWWW"
														value={values.webSiteWWW}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.webSiteWWW && !!touched.webSiteWWW}
														invalid={!!errors.webSiteWWW}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.webSiteWWW}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="officeBoxEpuap">{OFFICE_BOX_EPUAP}</Label>
													<Input
														id="officeBoxEpuap"
														value={values.officeBoxEpuap}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.officeBoxEpuap && !!touched.officeBoxEpuap}
														invalid={!!errors.officeBoxEpuap}
													/>
													<FormFeedback>{errors.officeBoxEpuap}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="receivingDocumentPlace">
														{RECEIVING_DOCUMENT_PLACE}
													</Label>
													<Input
														id="receivingDocumentPlace"
														value={values.receivingDocumentPlace}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={
														// 	!!errors.receivingDocumentPlace &&
														// 	!!touched.receivingDocumentPlace
														// }
														invalid={!!errors.receivingDocumentPlace}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.receivingDocumentPlace}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card className="mb-2">
									<CardHeader>
										<i className="header-icon pe-7s-map-2 icon-gradient bg-malibu-beach" />
										{CONTACT_INFORMATION}
									</CardHeader>
									<CardBody>
										<Row>
											<Col>
												<FormGroup>
													<Label for="country">{COUNTRY}</Label>
													<Input
														id="country"
														value={values.country}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.country && !!touched.country}
														invalid={!!errors.country}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.country}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="province">{PROVINCE}</Label>
													<Input
														id="province"
														value={values.province}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.province && !!touched.province}
														invalid={!!errors.province}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.province}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="district">{DISTRICT}</Label>
													<Input
														id="district"
														value={values.district}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.district && !!touched.district}
														invalid={!!errors.district}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.district}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="commune">{COMMUNE}</Label>
													<Input
														id="commune"
														value={values.commune}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.commune && !!touched.commune}
														invalid={!!errors.commune}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.commune}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="city">{CITY}</Label>
													<Input
														id="city"
														value={values.city}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.city && !!touched.city}
														invalid={!!errors.city}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.city}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="street">{STREET}</Label>
													<Input
														id="street"
														value={values.street}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.street && !!touched.street}
														invalid={!!errors.street}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.street}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="streetNumber">{STREET_NUMBER}</Label>
													<Input
														id="streetNumber"
														value={values.streetNumber}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.streetNumber && !!touched.streetNumber}
														invalid={!!errors.streetNumber}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.streetNumber}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="flat">{FLAT_NUMBER}</Label>
													<Input
														id="flat"
														value={values.flat}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.flat && !!touched.flat}
														invalid={!!errors.flat}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.flat}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup>
													<Label for="zipCode">{ZIP_CODE}</Label>
													<InputMask
														id="zipCode"
														className="form-control"
														value={values.zipCode}
														onChange={handleChange}
														onBlur={handleBlur}
														mask={'99-999'}
														// invalid={!!errors.zipCode && !!touched.zipCode}
														invalid={!!errors.zipCode}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.zipCode}</FormFeedback>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<Label for="zipCodeCity">{ZIP_CODE_CITY}</Label>
													<Input
														id="zipCodeCity"
														value={values.zipCodeCity}
														onChange={handleChange}
														onBlur={handleBlur}
														// invalid={!!errors.zipCodeCity && !!touched.zipCodeCity}
														invalid={!!errors.zipCodeCity}
														disabled={isIntegratedWithASW}
													/>
													<FormFeedback>{errors.zipCodeCity}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card className="mb-2">
									<CardHeader>
										<i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach" />
										{ADMISSION_HOURS_INFORMATION}

										{!isIntegratedWithASW && (
											<Button
												type="button"
												className="mr-2 border-0 btn-transition"
												outline
												color="success"
												onClick={(_) => setAddAdmissionHourModal(!addAdmissionHourModal)}
												disabled={disabled}
											>
												<i className="pe-7s-plus btn-icon-wrapper" />
											</Button>
										)}
									</CardHeader>
									<CardBody>
										<ListGroup flush>
											<FieldArray name="admissionHours">
												{(arrayHelper) => (
													<Fragment>
														<AddAdmissionHourModal
															{...{
																isOpen: addAdmissionHourModal,
																admissionHour: {
																	id: 0,
																	dayOfWeek: -1,
																	timeFrom: '2021-01-01T00:00:00',
																	timeTo: '2021-01-01T00:00:00'
																},
																onToggle: (_) =>
																	setAddAdmissionHourModal(!addAdmissionHourModal),
																onSave: (admissionHour) =>
																	arrayHelper.push(admissionHour)
															}}
														/>
														{map(
															values.admissionHours,
															(admissionHour, admissionHourIndex) => (
																<AdmissionHourItem
																	{...{
																		key: `admission-hour-${admissionHourIndex}`,
																		index: admissionHourIndex,
																		admissionHour,
																		onChange: (index, item) => {
																			arrayHelper.replace(index, item);
																		},
																		onRemove: arrayHelper.remove,
																		isIntegratedWithASW: isIntegratedWithASW
																	}}
																/>
															)
														)}
													</Fragment>
												)}
											</FieldArray>
										</ListGroup>
									</CardBody>
								</Card>
							</Form>
						</BlockUi>
					</Fragment>
				);
			}}
		</Formik>
	);
};

export default OfficeCard;
