import React, { Component } from 'react';
import map from 'lodash.map';

import { Card, CardHeader, CardBody, ListGroup, Collapse } from 'reactstrap';
import RSC from 'react-scrollbars-custom';
import Ionicon from 'react-ionicons';

import LinkCardItem from './LinkCardItem';
import classNames from 'classnames';
import isEmpty from 'lodash.isempty';

export default class RssChannelCard extends Component {
	static defaultProps = {
		rssChannels: []
	};
	state = {
		cardCollapse: false
	};

	toggleCollapse = () => {
		this.setState({ cardCollapse: !this.state.cardCollapse });
	};

	render() {
		const { cardCollapse } = this.state;
		const { rssChannels } = this.props;

		return (
			<Card className="mb-2">
				<CardHeader onClick={this.toggleCollapse}>
					<Ionicon className="header-icon opacity-2" icon="logo-rss" color="#00f2fe" />Aktywne kanały RSS
					<div className="btn-actions-pane-right btn-actions-cursor--pointer">
						{cardCollapse ? 'Zwiń' : 'Rozwiń'}
						{cardCollapse ? <i className="pe-7s-angle-up" /> : <i className="pe-7s-angle-down" />}
					</div>
				</CardHeader>
				<Collapse isOpen={cardCollapse}>
					<CardBody>
						<div
							className={classNames({
								'scroll-area-sm': !isEmpty(rssChannels)
							})}
						>
							<RSC>
								<ListGroup flush>
									{map(rssChannels, (rssChannel, rssChannelIndex) => (
										<LinkCardItem
											link={rssChannel}
											key={`rss-channel-item-${rssChannelIndex}`}
											removeable={false}
											editable={false}
										/>
									))}
								</ListGroup>
							</RSC>
						</div>
					</CardBody>
				</Collapse>
			</Card>
		);
	}
}
