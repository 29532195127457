import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import Immutable from 'seamless-immutable';

import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import Sticky from 'react-stickynode';
import { Card, CardHeader, CardBody, Row, Col, Button, Form } from 'reactstrap';

import PageTitle from '../../../../Layout/AppMain/PageTitle';
import ApplicationImageCard from './ApplicationImageCard';

import { LoaderType, ApplicationImageCardType, DefaultFormikConfig } from '../../../../helpers/enums';
import { ApplicationActionCreators } from '../../../../actions/application';
import { SettingActionCreators } from '../../../../actions/settings';
import { Formik } from 'formik';

import { CropperSizes, ImageData } from '../../../../helpers/enums';

import { CONSTANTS } from '@constants';

const { SAVE, CANCEL, COMMON_INFORMATION } = CONSTANTS;

class ApplicationImagesCard extends Component {
	constructor(props) {
		super(props);
		this.appImages = null;
	}

	componentDidMount() {
		const { getAppImages } = this.props;
		getAppImages();
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}

	render() {
		const { appImages, maxFileSize, appImagesCardBlocking, updateAppImages } = this.props;
		const { useImage, useGradient, showUseImage } = appImages;
		if (!this.appImages || !this.appImages.id) {
			this.appImages = Immutable(appImages).asMutable({ deep: true });
		}

		return (
			<Formik
				{...DefaultFormikConfig}
				initialValues={this.appImages}
				onSubmit={(values) => updateAppImages(values)}
			>
				{({ values, setFieldValue, handleSubmit: onSubmit, handleReset: onReset }) => (
					<Fragment>
						<PageTitle
							heading="Ustawienia grafik aplikacji"
							icon="pe-7s-settings icon-gradient bg-tempting-azure"
						/>
						<BlockUi
							tag="div"
							blocking={appImagesCardBlocking}
							loader={<Loader active type={LoaderType} />}
						>
							<Form noValidate method="post" {...{ onSubmit, onReset }}>
								<Sticky
									enabled={true}
									top=".app-header"
									innerZ="101"
									activeClass="sticky-active-class"
									className="mb-2"
								>
									<div className="d-flex justify-content-end">
										<Button className="btn-icon mr-2" color="primary" type="submit">
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{SAVE}
										</Button>
										<Button className="btn-icon mr-2" color="secondary" type="reset">
											<i className="pe-7s-back btn-icon-wrapper" />
											{CANCEL}
										</Button>
									</div>
								</Sticky>
								<Card className="mb-3">
									<CardHeader>{COMMON_INFORMATION}</CardHeader>
									<CardBody>
										<Row>
											<Col md={4}>
												<ApplicationImageCard
													name="Małe logo"
													title="Edycja małego loga"
													tooltip="Edytuj małe logo"
													imageType={ImageData.smallLogo.id}
													cardType={ApplicationImageCardType.Single}
													recommendedWidth={ImageData.smallLogo.width}
													recommendedHeight={ImageData.smallLogo.height}
													showUseImage={showUseImage}
													useImage={useImage}
													useGradient={useGradient}
													maxFileSize={maxFileSize}
													element={values.smallLogo}
													onChange={(element) => setFieldValue('smallLogo', element)}
													changeUseImage={(name, value) => setFieldValue(name, value)}
													changeUseGradient={(name, value) => setFieldValue(name, value)}
												/>
											</Col>
											<Col md={4}>
												<ApplicationImageCard
													name="Duże logo"
													title="Edycja dużego loga"
													tooltip="Edytuj duże logo"
													imageType={ImageData.bigLogo.id}
													cardType={ApplicationImageCardType.Single}
													recommendedWidth={ImageData.bigLogo.width}
													recommendedHeight={ImageData.bigLogo.height}
													showUseImage={showUseImage}
													useImage={useImage}
													useGradient={useGradient}
													maxFileSize={maxFileSize}
													element={values.bigLogo}
													onChange={(element) => setFieldValue('bigLogo', element)}
													changeUseImage={(name, value) => setFieldValue(name, value)}
													changeUseGradient={(name, value) => setFieldValue(name, value)}
												/>
											</Col>
											<Col md={4}>
												<ApplicationImageCard
													name="Nagłówek"
													title="Edycja nagłówka"
													tooltip="Edytuj nagłówek"
													imageType={ImageData.background.id}
													cardType={ApplicationImageCardType.Single}
													recommendedWidth={ImageData.background.width}
													recommendedHeight={ImageData.background.height}
													showUseImage={showUseImage}
													useImage={useImage}
													useGradient={useGradient}
													maxFileSize={maxFileSize}
													element={values.background}
													onChange={(element) => setFieldValue('background', element)}
													changeUseImage={(name, value) => setFieldValue(name, value)}
													changeUseGradient={(name, value) => setFieldValue(name, value)}
												/>
											</Col>
											<Col md={4}>
												<ApplicationImageCard
													name="Grafiki projektu unijnego"
													title="Edycja grafiki projektu unijnego"
													tooltip="Edytuj grafiki projektu unijnego"
													imageType={ImageData.euCollectionImages.id}
													cardType={ApplicationImageCardType.Multi}
													recommendedWidth={ImageData.euCollectionImages.width}
													recommendedHeight={ImageData.euCollectionImages.height}
													cropperSizes={{
														width: CropperSizes.euCollectionImages.width,
														height: CropperSizes.euCollectionImages.height
													}}
													showUseImage={showUseImage}
													useImage={useImage}
													useGradient={useGradient}
													maxFileSize={maxFileSize}
													element={values.euCollectionImages}
													onChange={(element) => setFieldValue('euCollectionImages', element)}
													changeUseImage={(name, value) => setFieldValue(name, value)}
													changeUseGradient={(name, value) => setFieldValue(name, value)}
												/>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Form>
						</BlockUi>
					</Fragment>
				)}
			</Formik>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		appImages: state.setting.appImages || {
			bigLogo: null,
			smallLogo: null,
			background: null,
			euCollectionImages: [],
			showUseImage: false,
			useImage: false,
			useGradient: false
		},
		appImagesCardBlocking: state.uiBlockState.appImagesCardBlocking || false,
		maxFileSize: state.application.maxFileSize
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators({ ...SettingActionCreators, ...ApplicationActionCreators }, dispatch),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationImagesCard);
