import Immutable from "seamless-immutable";

import { Article } from "../stores";
import { ArticleActions } from "../actions/article";
import { SearchActions } from "../actions/search";
import { ArticleStatus } from "../helpers/enums";

const initialState = Immutable(Article);

export function article(state = initialState, action = {}) {
  switch (action.type) {
    case SearchActions.SearchArticles.Success:
    case ArticleActions.GetAllArticles.Success:
    case ArticleActions.GetAllArticles.Failure: {
      return state.merge({
        articles: action.payload.items,
        totalArticles: action.payload.totalItems,
      });
    }
    case ArticleActions.UpdateArticle.Request: {
      return state.merge({
        article: action.payload,
      });
    }
    case ArticleActions.GetArticle.Success:
    case ArticleActions.SaveArticle.Success:
    case ArticleActions.UpdateArticle.Success:
    case ArticleActions.ChangeArticleTranslationStatus.Success:
    case ArticleActions.CreateArticleNewVersion.Success: {
      return state.merge({
        article: action.payload,
      });
    }
    case ArticleActions.ClearArticle.Success: {
      return state.setIn(["article"], null);
    }
    case ArticleActions.CopyArticleToNewArticleTranslation.Success: {
      const { article, language } = action.payload;

      return state.merge({
        article: {
          title: article.title,
          shortDescription: article.shortDescription,
          baseArticleType: article.baseArticleType,
          baseArticleTemplate: article.baseArticleTemplate,
          baseArticleId: article.baseArticleId,
          baseArticleProvidingEntity: article.baseArticleProvidingEntity,
          baseArticleAuthorName: article.baseArticleAuthorName,
          baseArticleAuthorEmail: article.baseArticleAuthorEmail,
          baseArticleInformationCreated: article.baseArticleInformationCreated,
          categories: article.categories,
          translations: article.translations,
          language: language,
          status: ArticleStatus.Draft.value,
        },
      });
    }
    case ArticleActions.DeleteArticles.Success: {
      const articles = Immutable(state).getIn(["articles"]);
      const newArticles = [];
      articles.reduce((prev, curr) => {
        if (!action.payload.includes(curr.id)) {
          prev.push(curr);
          return prev;
        }
        return prev;
      }, newArticles);
      return state.setIn(["articles"], newArticles);
    }
    case ArticleActions.ConfirmArticles.Success: {
      const articles = Immutable(state).getIn(["articles"]);
      const newArticles = [];

      articles.reduce((prev, curr) => {
        if (!action.payload.includes(curr.id)) {
          prev.push(curr);
          return prev;
        }
        return prev;
      }, newArticles);
      return state.setIn(["articles"], newArticles);
    }
    case ArticleActions.PublishArticles.Success: {
      const articles = Immutable(state).getIn(["articles"]);
      const newArticles = [];

      articles.reduce((prev, curr) => {
        if (!action.payload.ids.includes(curr.id)) {
          prev.push(curr);
          return prev;
        }
        return prev;
      }, newArticles);
      return state.setIn(["articles"], newArticles);
    }
    default: {
      return state;
    }
  }
}
