import * as yup from 'yup';

export const RoleValidationSchema = yup.object().shape({
	name: yup
		.string()
		.required('Nazwa jest polem wymaganym')
		.max(100, 'Nazwa może zawierać maksymalnie 100 znaków'),
	description: yup
		.string()
		.required('Opis jest polem wymaganym')
        .max(500, 'Opis może zawierać maksymalnie 500 znaków'),
    isSystem: yup.bool(),
    permissions: yup.array().of(yup.object().shape({
        id: yup.number().required('Identyfikator uprawnienia jest polem wymaganym'),
        displayName: yup.string().required('Nazwa uprawnienia jest polem wymaganym'),
        description: yup.string().required('Opis uprawnienia jest polem wymaganym')
    })).min(1, 'Rola musi posiadać przynajmniej jedno uprawnienie')
});