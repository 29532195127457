import React, { Fragment, useState } from 'react';
import { DefaultLanguage } from '../../../../helpers/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { TileSettingModal } from '../../../../components/Modals/TileSettings';

export const TileSettingButton = ({ tiles = [], language = DefaultLanguage }) => {
	const [ showSettings, setShowSettings ] = useState(false);

	return (
		<Fragment>
			<TileSettingModal
				{...{
					isOpen: showSettings,
					items: tiles,
					language: language,
					onToggle: () => setShowSettings(!showSettings)
				}}
			/>
			<Button
				className="rounded-circle p-0 mr-2"
				id="tileSettings"
				color="link"
				onClick={() => setShowSettings(!showSettings)}
			>
				<div className="icon-wrapper icon-wrapper-alt rounded-circle bg-warning">
					<div className="icon-wrapper-bg bg-focus" />

					<FontAwesomeIcon icon={faCog} color="#573a04" fixedWidth={false} size="2x" />
				</div>
			</Button>
		</Fragment>
	);
};
