import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import isEqual from "lodash.isequal";

import {
  DragDropType,
  DefaultLanguage,
  INFINITY,
  ArticleStatus,
} from "../../helpers/enums";
import { MenuActionCreators } from "../../actions/menu";
import { SearchActionCreators } from "../../actions/search";

import SearchTarget from "../DragAndDropTargets/SearchTarget";
import ArticleTarget from "../DragAndDropTargets/ArticleTarget";
import ArticleList from "../ArticleList";

import { CONSTANTS } from "@constants";
const { CONFIRM, CANCEL } = CONSTANTS;

class LinkArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      articles: props.articles,
    };
  }

  componentDidMount() {
    const { searchArticles } = this.props;
    searchArticles('', null);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.articles, this.props.articles)) {
      this.setState({ articles: this.props.articles });
    }
  }

  searchArticles = (title, categories) => {
    const { searchArticles } = this.props;

    if (searchArticles && typeof searchArticles === "function") {
      searchArticles(title, categories);
    }
  };

  onChangeItems = (articles) => this.setState({ articles: [...articles] });

  onDeleteItems = (articles) => this.setState({ articles: [...articles] });

  onCancel = (event) => {
    const { onToggle } = this.props;

    if (onToggle && typeof onToggle === "function") {
      this.setState({ expanded: false });
      onToggle(event);
    }
  };

  onSave = (event) => {
    const { onToggle, onSave } = this.props;
    const { articles } = this.state;

    if (onSave && typeof onSave === "function") {
      onSave(articles);
    }

    if (onToggle && typeof onToggle === "function") {
      this.setState({ expanded: false });
      onToggle(event);
    }
  };

  render() {
    const {
      type,
      isOpen,
      articleIsoCodeLanguage,
      excludeArticles,
      maxLinks = INFINITY,
      defaultFilter = [
        {
          columnName: "status",
          operation: "lessThanOrEqual",
          value: ArticleStatus.Published.value,
        },
      ],
    } = this.props;
    const { articles } = this.state;
    const { onChangeItems, onDeleteItems, searchArticles, onSave, onCancel } =
      this;

    return (
      <Modal isOpen={isOpen} backdrop size="xl">
        <ModalHeader>
          <div className="d-flex justify-content-between">
            <div>Wybierz artykuły</div>
            <div>
              <Button
                className="btn-icon mr-2"
                color="alternate"
                type="button"
                onClick={onSave}
              >
                <i className="pe-7s-check btn-icon-wrapper" />
                {CONFIRM}
              </Button>
              <Button
                className="btn-icon mr-2"
                color="secondary"
                type="button"
                onClick={onCancel}
              >
                <i className="pe-7s-back btn-icon-wrapper" />
                {CANCEL}
              </Button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={8}>
              <Card className="mb-2">
                <CardHeader>
                  <div className="widget-content p-0 d-flex flex1-1-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex0-0-3">
                        Wyszukaj artykuł
                      </div>
                      <div className="widget-content-right ml-3 flex1-1-7">
                        <SearchTarget
                          dropType={DragDropType.Category}
                          onChange={searchArticles}
                        />
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="vh-100" style={{ overflow: "auto" }}>
                    <ArticleList
                      items={articles}
                      articles={articles}
                      {...{
                        onChangeItems,
                        defaultFilter,
                        excludeArticles,
                        type,
                      }}
                      langIsoCode={articleIsoCodeLanguage}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="mb-2">
                <CardHeader>
                  <div className="widget-content p-0 d-flex flex1-1-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        Wybrane artykuły
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <ArticleTarget
                    items={articles}
                    dropType={
                      maxLinks !== INFINITY && articles.length >= maxLinks
                        ? DragDropType.Unknown
                        : DragDropType.Article
                    }
                    {...{ onChangeItems, onDeleteItems }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  articleIsoCodeLanguage:
    state.article.article && state.article.article.language
      ? state.article.article.language.isoCode
      : DefaultLanguage.isoCode,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...MenuActionCreators, ...SearchActionCreators },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(LinkArticle);
