import { DefaultLanguage } from "../helpers/enums";

export const processChildrenTreeData = (children) =>
  children.map((child) => ({
    ...child,
    displayText: child.displayText
      ? child.displayText
      : `Brak tłumaczenia - (${
          child.languages.find(
            (el) => el.languageIsoCode === DefaultLanguage.isoCode
          )?.translatedDisplayText
        })`,
    hasValue: !!child.displayText,
    children: processChildrenTreeData(child.children),
  }));

export const processTreeData = (treeData) =>
  treeData.map((item) => ({
    ...item,
    displayText: item.displayText
      ? item.displayText
      : `Brak tłumaczenia - (${
          item.languages.find(
            (el) => el.languageIsoCode === DefaultLanguage.isoCode
          )?.translatedDisplayText
        })`,
    hasValue: !!item.displayText,
    children: processChildrenTreeData(item.children),
  }));
