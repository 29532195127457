import { MenuConfiguration } from '../helpers/enums';

export const Setting = {
	menuConfiguration: {
		horizontal: {
			maxElements: 6,
			maxLevels: 1
		},
		vertical: {
			maxElements: MenuConfiguration.Infinity,
			maxLevels: 4
		}
	},
	languages: [],
	language: {
		id: 0,
		name: '',
		localName: '',
		isoCode: '',
		dateFormat: '',
		statusName: '',
		standardElementsTranslations: {
			items: []
		},
		menuElementsTranslations: {
			items: []
		}
	},
	systemParameters: [],
	systemParameter: null,
	totalSystemParameters: 0,
	bookmark: null,
	bookmarks: [],
	totalBookmarks: 0,
	rssChannel: null,
	rssChannels: [],
	totalRssChannels: 0,
	tile: null,
	tiles: [],
	totalTiles: 0,
	footer: null,
	header: null,
	banner: null,
	banners: [],
	totalBanners: 0,
	appImages: null,
	officeContacts: [],
	totalOfficeContacts: 0,
	repositoryConfig: null,
	repositoryConfigs: [],
	totalRepositoryConfigs: 0,
};
