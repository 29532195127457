import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Card, CardHeader, CardBody, Col, Row, Input } from 'reactstrap';
import cx from 'classnames';
import { TinyMCE } from '../../../../../components/TinyMCE';

export const CookieConfiguration = ({ isEnabled = false, desc = '', onChange, ...props }) => {
	const [ enabled, setEnabled ] = useState(isEnabled);
	const [ description, setDescription ] = useState(desc);

	useEffect(
		() => {
			setEnabled(isEnabled);
			setDescription(desc);
		},
		[ isEnabled, desc ]
	);

	const handleEnabledChange = (e) => {
		if (onChange && typeof onChange === 'function') {
			onChange('value.isEnabled', !enabled);
			setEnabled(!enabled);
		}
	};

	const handleDescriptionChange = (description) => {
		if (onChange && typeof onChange === 'function') {
			onChange('value.description', description);
			setDescription(description);
		}
	};

	return (
		<Card className="mb-2">
			<CardHeader>
				<i className="header-icon pe-7s-file icon-gradient bg-malibu-beach" />
				{props.title}
			</CardHeader>
			<CardBody>
				<Row>
					<Col>
						<FormGroup className="d-flex align-items-center">
							<Label className="mr-2">Pokaż informacje o cookies</Label>
							<div
								className="switch has-switch mb-2 mr-2"
								data-on-label="Tak"
								data-off-label="Nie"
								onClick={handleEnabledChange}
							>
								<div
									className={cx('switch-animate', {
										'switch-on': enabled,
										'switch-off': !enabled
									})}
								>
									<input type="checkbox" />
									<span className="switch-left">Tak</span>
									<label>&nbsp;</label>
									<span className="switch-right">Nie</span>
								</div>
							</div>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label for="description">Opis z informacją o cookies</Label>
							<TinyMCE
								config={{
									plugins: '',
									height: 600,
									toolbar: 'bold italic underline strikethrough forecolor',
									menubar: false
								}}
								content={description}
								available_paste={false}
								onContentChanged={handleDescriptionChange}
								disabled={!enabled}
							/>
						</FormGroup>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};
