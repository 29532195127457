import * as yup from "yup";
import { LinkTargetType, LinkType } from "../helpers/enums";

export const ArticleLinkValidationSchema = yup.object().shape({
  type: yup
    .number()
    .oneOf(
      [
        LinkType.Article.value,
        LinkType.Category.value,
        LinkType.External.value,
      ],
      `Typ odnośnika musi zawierać wartość: ${LinkType.Article.name}, ${LinkType.Category.name} lub  ${LinkType.External.name}`
    ),
  url: yup.string().when(["type"], {
    is: LinkType.External.value,
    then: yup
      .string()
      .nullable()
      .url(
        "Adres URL musi zawierać prawidłowy format adresu. Musi składać się protokołu, adresu oraz domeny"
      )
      .required("Adres URL jest wymagany"),
    otherwise: yup.string().nullable(),
  }),
  displayText: yup
    .string()
    .trim()
    .required("Nazwa wyświetlana jest polem wymaganym"),
  title: yup
    .string()
    .trim()
    .required(
      "Tekst pod czytniki dla osób słabowidzących jest polem wymaganym"
    ),
  target: yup
    .number()
    .oneOf(
      [LinkTargetType.Self.value, LinkTargetType.NewTab.value],
      `Otwórz zakładkę w nowym oknie musi zawierać wartość: ${LinkTargetType.Self.name} lub ${LinkTargetType.NewTab.name}`
    ),
});
