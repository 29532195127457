import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import Sticky from 'react-stickynode';
import { Card, CardHeader, CardBody, Row, Col, Button, Form } from 'reactstrap';
import { FieldArray, Formik } from 'formik';
import Flag from 'react-flagkit';

import PageTitle from '../../../../../Layout/AppMain/PageTitle';
import OfficeElementCard from './OfficeElementCard';

import Immutable from 'seamless-immutable';
import map from 'lodash.map';
import filter from 'lodash.filter';
import isEqual from 'lodash.isequal';
import { LoaderType, DefaultLanguage, DefaultFormikConfig } from '../../../../../helpers/enums';
import { ApplicationActionCreators } from '../../../../../actions/application';
import { SettingActionCreators } from '../../../../../actions/settings';
import { AVAILABLE_ISO_CODE } from '../../../../../helpers/isoCode';

import { CONSTANTS } from '@constants';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { OfficeContactListValidationSchema } from '../../../../../validators';
import { LanguageFlag } from '../../../Headers/Card/LanguageFlag';

const { SAVE, CANCEL, SPECIFIC_INFOMATION } = CONSTANTS;

const OfficeContactSortableElement = SortableElement(
	({ key, index, item, isInvalid, maxFileSize, onChange, onRemove }) => (
		<OfficeElementCard
			key={key}
			index={index}
			isInvalid={isInvalid}
			maxFileSize={maxFileSize}
			officeContact={item}
			onChange={onChange}
			onRemove={onRemove}
		/>
	)
);

const OfficeContactSortableContainer = SortableContainer(
	({ officeContacts, errors, maxFileSize, onChange, onRemove, onAdd, activeLanguageId }) => (
		<Row className="menu">
			{map(officeContacts, (officeContact, officeContactIndex) => (
				<Col md={3}>
					<OfficeContactSortableElement
						key={`foo-element-card-${officeContactIndex}`}
						index={officeContactIndex}
						item={officeContact}
						onChange={onChange}
						onRemove={onRemove}
						isInvalid={errors && errors.contacts && !!errors.contacts[officeContactIndex]}
						maxFileSize={maxFileSize}
					/>
				</Col>
			))}
			<Col md={3}>
				<Card
					className={`mb-3 card-hover-shadow card-border menu__card`}
					style={{ minHeight: '220px', maxHeight: '220px', flex: '0 1 auto' }}
					outline
				>
					<CardBody className={'d-flex justify-content-center align-items-center'}>
						<Button
							type="button"
							className="border-0 btn-transition btn-icon d-flex justify-content-center align-items-center menu__button"
							outline
							color="success"
							onClick={(_) =>
								onAdd({
									id: 0,
									fullName: '',
									description: '',
									jobPosition: '',
									languageId: activeLanguageId,
									officeContactPhotoId: null,
									order: 0,
									isSystem: false
								})}
							title="Dodaj kolejnego pracownika"
						>
							<i className="pe-7s-plus button__icon"> </i>
						</Button>
					</CardBody>
				</Card>
			</Col>
		</Row>
	)
);
class OfficeManagerCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentOfficeContacts: null
		};
	}
	componentDidMount() {
		const { activeLanguage, getOfficeContacts } = this.props;
		getOfficeContacts(activeLanguage.isoCode);
	}
	componentDidUpdate(prevProps, prevState) {
		const { activeLanguage, getOfficeContacts, officeContacts } = this.props;
		if (!isEqual(prevProps.activeLanguage, activeLanguage)) {
			getOfficeContacts(activeLanguage.isoCode);
		}

		if (!isEqual(prevState.currentOfficeContacts, officeContacts)) {
			this.setState({ currentOfficeContacts: officeContacts });
		}
	}

	componentWillUnmount() {
		this.props.unloadSetting();
	}

	createTranslationFlagButtons = () => {
		const { languages, changeApplicationLanguage, activeLanguage } = this.props;

		return (
			<div className="header-dots ml-0">
				{map(
					filter(languages, (language) => language.isoCode !== activeLanguage.isoCode),
					(language, langIndex) => (
						<div
							key={`lang-ix-${langIndex}`}
							className="p-0 mr-2"
							onClick={(_) => changeApplicationLanguage(language)}
						>
							<div className="icon-wrapper icon-wrapper-alt rounded-circle mr-2">
								<div className="icon-wrapper-bg bg-focus" />
								<div className="language-icon">
									{AVAILABLE_ISO_CODE.includes(language.isoCode) && (
										<Flag className="mr-3 opacity-8" country={language.isoCode} size="40" />
									)}
									{!AVAILABLE_ISO_CODE.includes(language.isoCode) &&
									language.isoCode !== '' &&
									language.isoCodeFile !== null && (
										<img
											className={`mr-2 opacity-8`}
											src={language.isoCodeFile.path}
											alt={`Grafika ${language.isoCodeFile.fileName}`}
										/>
									)}
								</div>
							</div>
						</div>
					)
				)}
			</div>
		);
	};

	onSortEnd = ({ newIndex, oldIndex, ...props }, items, onChange) => {
		const newItems = arrayMove(items, oldIndex, newIndex);
		onChange('contacts', newItems);
	};

	render() {
		const { currentOfficeContacts } = this.state;
		const {
			officeContacts,
			activeLanguage,
			officeContactBlocking,
			updateOfficeContacts,
			languages,
			maxFileSize
		} = this.props;
		const newElements = { contacts: Immutable(officeContacts).asMutable({ deep: true }) };

		const currentValidateOnMount = !isEqual(currentOfficeContacts, officeContacts);

		return (
			<Formik
				{...DefaultFormikConfig}
				initialValues={newElements}
				validateOnMount={currentValidateOnMount}
				validationSchema={OfficeContactListValidationSchema}
				onSubmit={(values) => updateOfficeContacts(activeLanguage.isoCode, values.contacts)}
			>
				{({
					errors,
					values,
					isValid,
					dirty,
					handleSubmit: onSubmit,
					handleReset: onReset,
					setFieldValue,
					validateForm
				}) => (
					<Fragment>
						<PageTitle
							heading="Kierownictwo jednostki"
							icon="pe-7s-settings icon-gradient bg-tempting-azure"
							buttons={this.createTranslationFlagButtons(values)}
						/>
						<BlockUi
							tag="div"
							blocking={officeContactBlocking}
							loader={<Loader active type={LoaderType} />}
						>
							<Form noValidate method="post" {...{ onSubmit, onReset }}>
								<Sticky
									enabled={true}
									top=".app-header"
									innerZ="101"
									activeClass="sticky-active-class"
									className="mb-2"
								>
									<div className="d-flex justify-content-end">
										<Button
											className="btn-icon mr-2"
											color="primary"
											type="submit"
											disabled={!isValid || !dirty}
										>
											<i className="pe-7s-diskette btn-icon-wrapper" />
											{SAVE}
										</Button>
										<Button className="btn-icon mr-2" color="secondary" type="reset">
											<i className="pe-7s-back btn-icon-wrapper" />
											{CANCEL}
										</Button>
									</div>
								</Sticky>

								<Card className="mb-3">
									<CardHeader>
										{SPECIFIC_INFOMATION}

										<LanguageFlag {...{ values: { language: activeLanguage }, languages }} />
									</CardHeader>
									<CardBody>
										<FieldArray id="contacts" name="contacts">
											{(props) => (
												<OfficeContactSortableContainer
													axis="xy"
													helperClass="sortableHelper"
													officeContacts={values.contacts}
													onChange={props.replace}
													onRemove={props.remove}
													onAdd={(e) => {
														props.push(e);
														setTimeout(() => validateForm(), 50);
													}}
													activeLanguageId={activeLanguage.id}
													maxFileSize={maxFileSize}
													errors={errors}
													onSortEnd={(e) => {
														const newItems = arrayMove(
															values.contacts,
															e.oldIndex,
															e.newIndex
														);
														setFieldValue('contacts', newItems);
													}}
												/>
											)}
										</FieldArray>
									</CardBody>
								</Card>
							</Form>
						</BlockUi>
					</Fragment>
				)}
			</Formik>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		officeContacts: state.setting.officeContacts || [],
		officeContactBlocking: state.uiBlockState.officeContactBlocking || false,
		activeLanguage: state.application.activeLanguage,
		languages: state.application.languages || [ DefaultLanguage ],
		maxFileSize: state.application.maxFileSize
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		...bindActionCreators({ ...SettingActionCreators, ...ApplicationActionCreators }, dispatch),
		goTo: (url) => dispatch(push(url))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OfficeManagerCard);
