import Immutable from 'seamless-immutable';

import { Office } from '../stores';
import { OfficeActions } from '../actions/office';

const initialState = Immutable(Office);

export function office(state = initialState, action = {}) {
	switch (action.type) {
		case OfficeActions.GetOffice.Success: {
			return state.merge({
				office: action.payload
			});
		}
		case OfficeActions.UpdateOffice.Success: {
			return state.merge({
				office: action.payload
			});
		}
		default: {
			return state;
		}
	}
}
