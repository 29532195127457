import React, { Component, Fragment } from 'react';
import { TableEditRow } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Button } from 'reactstrap';
import filter from 'lodash.filter';

import Grid from '../../../../components/Grid';

import isEmpty from 'lodash.isempty';

const columns = [
	{
		name: 'menuTypeName',
		title: 'Typ menu'
	},
	{
		name: 'baseName',
		title: 'Nazwa bazowa'
	},
	{
		name: 'translatedDisplayText',
		title: 'Nazwa wyświetlana'
	},
	{
		name: 'translatedTitle',
		title: 'Tekst pod czytniki dla osób słabowidzących'
	},
	{
		name: 'translatedUrl',
		title: 'Adres URL'
	},
	{
		name: 'validationStatus',
		title: 'Status walidacji'
	}
];
const columnsExtensions = [
	{
		columnName: 'menuTypeName',
		width: 'auto'
	},
	{
		columnName: 'baseName',
		width: 'auto'
	},
	{
		columnName: 'translatedDisplayText',
		width: 'auto'
	},
	{
		columnName: 'translatedTitle',
		width: 'auto'
	},
	{
		columnName: 'translatedUrl',
		width: 'auto'
	},
	{
		columnName: 'validationStatus',
		width: '10%',
		align: 'center'
	}
];
const filtersColumnExtensions = [
	{
		columnName: 'menuTypeName',
		wordWrapEnabled: false,
		filteringEnabled: false
	},
	{
		columnName: 'baseName',
		wordWrapEnabled: false,
		filteringEnabled: false
	},
	{
		columnName: 'translatedDisplayText',
		wordWrapEnabled: false,
		filteringEnabled: true
	},
	{
		columnName: 'translatedTitle',
		wordWrapEnabled: false,
		filteringEnabled: true
	},
	{
		columnName: 'translatedUrl',
		wordWrapEnabled: false,
		filteringEnabled: true
	},
	{
		columnName: 'validationStatus',
		wordWrapEnabled: false,
		filteringEnabled: false
	}
];
const textColumns = [ 'baseName', 'translatedDisplayText', 'translatedTitle', 'translatedUrl' ];
const groupingColumns = [ { columnName: 'menuTypeName' } ];
const groupingColumnExtensions = [
	{ columnName: 'menuTypeName', groupingEnabled: false },
	{ columnName: 'baseName', groupingEnabled: false },
	{ columnName: 'translatedDisplayText', groupingEnabled: false },
	{ columnName: 'translatedTitle', groupingEnabled: false },
	{ columnName: 'translatedUrl', groupingEnabled: false },
	{ columnName: 'validationStatus', groupingEnabled: false }
];
const groupingCell = (props) => {
	const { items, row, ...restProps } = props;
	const errors = filter(items, (item) => item[row.groupedBy] === row.value && item.errors);

	return (
		<span>
			<Fragment>
				<strong>{row.key}</strong>{' '}
				{errors.length > 0 && restProps.hasValidation && <i className="pe-7s-exlamation fg-danger" />}
				{errors.length <= 0 && restProps.hasValidation && <i className="pe-7s-check fg-success" />}
			</Fragment>
		</span>
	);
};
const editingStateColumnExtensions = [
	{
		columnName: 'baseName',
		editingEnabled: false
	},
	{
		columnName: 'validationStatus',
		editingEnabled: false
	}
];

const ValidationFormatter = ({ row }) => (
	<Fragment>
		{row.errors ? (
			<Button
				type="button"
				className="btn-icon-only border-0"
				outline
				color="danger"
				title={`${row.errors.translatedDisplayText || ''}\n${row.errors.translatedTitle || ''}\n${row.errors
					.translatedUrl || ''}`}
			>
				<i className="pe-7s-exlamation btn-icon-wrapper" />
			</Button>
		) : (
			<Button type="button" className="btn-icon-only border-0" outline color="success">
				<i className="pe-7s-check btn-icon-wrapper" />
			</Button>
		)}
	</Fragment>
);

const dataTypeProviders = [
	<DataTypeProvider for={[ 'validationStatus' ]} formatterComponent={ValidationFormatter} />
];

class EditCellComponent extends Component {
	render() {
		const { row, column } = this.props;
		if (column.name === 'translatedUrl' && isEmpty(row.baseUrl)) {
			return <TableEditRow.Cell {...this.props} editingEnabled={false} />;
		}
		return <TableEditRow.Cell {...this.props} />;
	}
}

export const MenuElementTranslationList = ({
	items,
	totalItems,
	currentPage,
	pageSize,
	onReload,
	onChangePageSize,
	onChangeCurrentPage,
	onEdit,
	...props
}) => {
	return (
		<Grid
			items={items}
			totalItems={totalItems}
			dataTypeProviders={dataTypeProviders}
			validationRow={true}
			grouping={groupingColumns}
			groupingColumnExtensions={groupingColumnExtensions}
			groupingCell={groupingCell}
			expandedGroups={[ ...new Set(items.map((el) => el.menuTypeName)) ]}
			columns={columns}
			columnExtensions={columnsExtensions}
			filtersColumnExtensions={filtersColumnExtensions}
			editingStateColumnExtensions={editingStateColumnExtensions}
			textColumns={textColumns}
			removeable={false}
			addable={false}
			filterable={true}
			onReload={onReload}
			inlineEditable={true}
			onEditInlineItem={onEdit}
			editCellComponent={EditCellComponent}
			currentPage={currentPage}
			pageSize={pageSize}
			onChangePageSize={onChangePageSize}
			onChangeCurrentPage={onChangeCurrentPage}
			filters={props.filters}
			onChangeFilters={props.onChangeFilters}
			onClearFilters={props.onClearFilters}
		/>
	);
};
